import { connect } from 'react-redux'
import { withRouter } from "react-router";
import UserComplianceDocumentsExpired from '../components/venue/userComplianceDocuments/expired/UserComplianceDocumentsExpired';
import { 
    userComplianceDocumentsExpiringRequest,
    userComplianceDocumentsExpiringExportRequest
 } from '../common/actions/userComplianceDocument';

const mapStateToProps = (state, props) => {
    return {
        securityFirmId: state.user.details.userSession.user.securityFirmId,
        paging: state.userComplianceDocument.expiring.paging,
        filter: state.userComplianceDocument.expiring.filter,
        isLoading: state.userComplianceDocument.expiring.isLoading,
        userComplianceDocuments: state.userComplianceDocument.expiring.userComplianceDocuments
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (pageSize, page, filter, venueId, securityFirmId) => {
            dispatch(userComplianceDocumentsExpiringRequest(page, pageSize, filter, venueId, securityFirmId))
        },
        onExport: (venueId, securityFirmId) => {
            dispatch(userComplianceDocumentsExpiringExportRequest(venueId, securityFirmId))
        }
    }
}

const SecurityFirmUserComplianceDocumentsExpiredContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(UserComplianceDocumentsExpired))

export default SecurityFirmUserComplianceDocumentsExpiredContainer