import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PageTitle from '../../common/PageTitle';
import PagedList from '../../common/PagedList';
import Loading from '../../common/Loading';
import TableContainer from '../../layout/TableContainer';
import View from '../../common/View';
import VenueQuickActionsContainer from '../../../containers/VenueQuickActionsContainer';
import DefaultFab from '../../common/DefaultFab';
import SubTitle from '../../common/SubTitle';
import LayoutForm from '../../layout/LayoutForm';
import TextAreaControlGroup from '../../controls/TextAreaControlGroup';
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';
import FullPageDialog from '../../layout/FullPageDialog';
import Time24HourSelectorControlGroup from '../../controls/Time24HourSelectorControlGroup';
import TransparentButton from '../../controls/TransparentButton';

export interface IIncidentViewFacialRecognitionProps {
    facialRecognitionNotification?: AusComplyDtos.FacialRecognitionNotification;
    classes: any;
    theme: any;
}

interface IIncidentViewFacialRecognitionState {
    show: boolean;
}

class IncidentViewFacialRecognition extends Component<IIncidentViewFacialRecognitionProps, IIncidentViewFacialRecognitionState> {
    constructor(props: IIncidentViewFacialRecognitionProps) {
        super(props)

        this.state = {
            show: false
        };
        this.onShow = this.onShow.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    onShow(show: boolean) {
        this.setState({show});
    }

    render() {
        if (!this.props.facialRecognitionNotification)        
        {
            return (<></>);
        }

        let recievedNotification = this.props.facialRecognitionNotification.recievedNotification;
        let facialRecognitionNotification = (
                <LayoutForm>
                    <ControlGroup text="Created At">
                        <TextDisplay>{this.props.facialRecognitionNotification.dateEntered}</TextDisplay>
                    </ControlGroup>
                    <ControlGroup text="Event Type">
                        <TextDisplay>{this.props.facialRecognitionNotification.recievedNotification.event_type}</TextDisplay>
                    </ControlGroup>
                    {(recievedNotification && recievedNotification.camera_data) && (
                        <>
                            <SubTitle text={"Camera Data"} />
                            <ControlGroup text="Camera Description">
                                <TextDisplay>{recievedNotification.camera_data.camera_description}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="Camera ID">
                                <TextDisplay>{recievedNotification.camera_data.camera_id}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="Stream ID">
                                <TextDisplay>{recievedNotification.camera_data.stream_id}</TextDisplay>
                            </ControlGroup>
                        </>
                    )}
                    {(recievedNotification && recievedNotification.frame_data) && (
                        <>
                            <SubTitle text={"Frame Data"} />
                            <ControlGroup text="UTC Time Recorded">
                                <TextDisplay>{recievedNotification.frame_data.utc_time_recorded}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="UTC Time Zone">
                                <TextDisplay>{recievedNotification.frame_data.utc_time_zone}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="Frame ID">
                                <TextDisplay>{recievedNotification.frame_data.frame_id}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="Bounded Box">
                                {recievedNotification.frame_data.bounding_box && (
                                    <TextDisplay>x1: {recievedNotification.frame_data.bounding_box.x1} y1: {recievedNotification.frame_data.bounding_box.y1} x2: {recievedNotification.frame_data.bounding_box.x2} y2: {recievedNotification.frame_data.bounding_box.y2}</TextDisplay>
                                )}
                            </ControlGroup>
                        </>
                    )}
                    {(recievedNotification && recievedNotification.appearance_data) && (
                        <>
                            <SubTitle text={"Appearance Data"} />
                            <ControlGroup text="Appearance ID">
                                <TextDisplay>{recievedNotification.appearance_data.appearance_id}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="UTC Time Started">
                                <TextDisplay>{recievedNotification.appearance_data.utc_time_started}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="First frame ID">
                                <TextDisplay>{recievedNotification.appearance_data.first_frame_id}</TextDisplay>
                            </ControlGroup>
                        </>
                    )}
                    {(recievedNotification && recievedNotification.crop_data) && (
                        <>
                            <SubTitle text={"Crop Data"} />
                            <ControlGroup text="Face Crop Image">
                                {(recievedNotification.crop_data.face_crop_img) && (
                                    <img src={`data:image/jpeg;base64,${recievedNotification.crop_data.face_crop_img}`} alt="photo" />
                                )}
                            </ControlGroup>
                            <ControlGroup text="Face Score">
                                <TextDisplay>{recievedNotification.crop_data.face_score}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="Pitch">
                                <TextDisplay>{recievedNotification.crop_data.pitch}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="Yaw">
                                <TextDisplay>{recievedNotification.crop_data.yaw}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="Masked Score">
                                <TextDisplay>{recievedNotification.crop_data.masked_score}</TextDisplay>
                            </ControlGroup>
                        </>
                    )}
                    {(recievedNotification && recievedNotification.face_features_data) && (
                        <>
                            <SubTitle text={"Face Features Data"} />
                            <ControlGroup text="Mask Outcome">
                                <TextDisplay>{recievedNotification.face_features_data.mask_outcome}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="Agre Group Outcome">
                                <TextDisplay>{recievedNotification.face_features_data.age_group_outcome}</TextDisplay>
                            </ControlGroup>
                        </>
                    )}
                    {(recievedNotification && recievedNotification.match_data) && (
                        <>
                            <SubTitle text={"Match Data"} />
                            <ControlGroup text="UTC Time matched">
                                <TextDisplay>{recievedNotification.match_data.utc_time_matched}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="POI ID">
                                <TextDisplay>{recievedNotification.match_data.poi_id}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="POI Confidence">
                                <TextDisplay>{recievedNotification.match_data.poi_confidence}</TextDisplay>
                            </ControlGroup>
                            <ControlGroup text="POI Display Image">
                                {(recievedNotification.match_data.poi_display_img) && (
                                    <img src={`data:image/jpeg;base64,${recievedNotification.match_data.poi_display_img}`} alt="photo" />
                                )}
                                {(!recievedNotification.match_data.poi_display_img) && (
                                    <TextDisplay>Empty</TextDisplay>
                                 )}
                            </ControlGroup>
                            <ControlGroup text="POI Display Name">
                                <TextDisplay>{recievedNotification.match_data.poi_display_name}</TextDisplay>
                            </ControlGroup>
                        </>
                    )}
                    {(recievedNotification && recievedNotification.match_data && recievedNotification.match_data.watchlists) && (
                        <>
                            <SubTitle text={"Watchlists"} />
                            {recievedNotification.match_data.watchlists.map((item, index) => (<View key={"watchlist-" + item.watchlist_id}>
                                <ControlGroup text="Watchlist ID">
                                    <TextDisplay>{item.watchlist_id}</TextDisplay>
                                </ControlGroup>
                                <ControlGroup text="Watchlist Type">
                                    <TextDisplay>{item.watchlist_type}</TextDisplay>
                                </ControlGroup>
                                <ControlGroup text="match Outcome">
                                    <TextDisplay>{item.match_outcome}</TextDisplay>
                                </ControlGroup>
                                <ControlGroup text="Display Name">
                                    <TextDisplay>{item.display_name}</TextDisplay>
                                </ControlGroup>
                                <ControlGroup text="Display Colour">
                                    <TextDisplay style={{ color: item.display_color }}>{item.display_color}</TextDisplay>
                                </ControlGroup>
                                <ControlGroup text=" ">
                                    <hr />
                                </ControlGroup>
                            </View>))}
                        </>
                    )}

                    <TextAreaControlGroup text={"Raw Json Data"} defaultValue={this.props.facialRecognitionNotification.jsonString} />
                </LayoutForm>
            );

        return (
            <>
                <ControlGroup text={" "}>
                    <TransparentButton text={"Facial Recognition Information"} onClick={() => this.onShow(true)}/>  
                </ControlGroup>
                <FullPageDialog open={this.state.show} title="Facial Recognition" onDismissed={() => this.onShow(false)}>
                    {facialRecognitionNotification}
                </FullPageDialog>
            </>
        );
    }
}

export default withStyles(styles, { withTheme: true })(IncidentViewFacialRecognition);