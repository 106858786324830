import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import VenueContainer from '../../containers/VenueContainer';
import VenueUsersContainer from '../../containers/VenueUsersContainer';
import VenueCurrentReportingPeriod from '../venue/VenueCurrentReportingPeriod';
import VenueUserContainer from '../../containers/VenueUserContainer';
import VenueEditContainer from '../../containers/VenueEditContainer';
import VenueReportingPeriodsContainer from '../../containers/VenueReportingPeriodsContainer';
import VenueLocationsContainer from '../../containers/VenueLocationsContainer';
import VenueIncidentCategoryTypesContainer from '../../containers/VenueIncidentCategoryTypesContainer';
import VenueUserComplianceDocumentsExpiredContainer from '../../containers/VenueUserComplianceDocumentsExpiredContainer';
import VenueUserComplianceDocumentsMissingContainer from '../../containers/VenueUserComplianceDocumentsMissingContainer';
import VenueContactsContainer from '../../containers/VenueContactsContainer';
import VenueSubscriptionContainer from '../../containers/VenueSubscriptionContainer';
import VenueDocumentsContainer from '../../containers/VenueDocumentsContainer';
import VenueSystemLogContainer from '../../containers/VenueSystemLogContainer';
import VenueSecurityFirmAssociationsContainer from '../../containers/VenueSecurityFirmAssociationsContainer';
import VenueChecklistTemplatesContainer from '../../containers/VenueChecklistTemplatesContainer';
import CheckListTemplateContainer from '../../containers/CheckListTemplateContainer';
import VenueRoleTypesContainer from '../../containers/VenueRoleTypesContainer';
import VenueBroadcastNotificationContainer from '../../containers/VenueBroadcastNotificationContainer';
import VenueBroadcastNotificationsContainer from '../../containers/VenueBroadcastNotificationsContainer';
import VenueFacialRecognitionNotificationsContainer from '../../containers/VenueFacialRecognitionNotificationsContainer';
import IncidentTypeFlagNotificationsContainer from '../../containers/IncidentFlagTypeNotificationsContainer';
import VenueFacialRecognitionNotificationEventsContainer from '../../containers/VenueFacialRecognitionNotificationEventsContainer';
import VenueFacialRecognitionNotificationCamerasContainer from '../../containers/VenueFacialRecognitionNotificationCamerasContainer';
import VenueIncidentActionTemplatesContainer from '../../containers/VenueIncidentActionTemplatesContainer';
import VenueIncidentActionTemplateContainer from '../../containers/VenueIncidentActionTemplateContainer';
import VenueGroupsContainer from '../../containers/VenueGroupsContainer';
import FacialRecognitionWatchlistPoisContainer from '../../containers/FacialRecognitionWatchlistPoisContainer';
import FacilityCellsContainer from '../../containers/FacilityCellsContainer';
import CorrectionalFacilityRiskStatusContainer from '../../containers/CorrectionalFacilityRiskStatusContainer';
import VenueFacialRecognitionNotificationsWatchlistsContainer from '../../containers/VenueFacialRecognitionNotificationsWatchlistsContainer'
import VenueFacialRecognitionNotificationsEventsContainer from '../../containers/VenueFacialRecognitionNotificationsEventsContainer';
import VenueFacialRecognitionNotificationsEnrolContainer from '../../containers/VenueFacialRecognitionNotificationsEnrolContainer';
import VenueDirectoryContainer from '../../containers/VenueDirectoryContainer';
import VenueSchedulesContainer from '../../containers/VenueSchedulesContainer';
import VenueScheduleContainer from '../../containers/VenueScheduleContainer';
import VenueScheduleLogsContainer from '../../containers/VenueScheduleLogsContainer';
import VenueRadioChannelsContainer from '../../containers/VenueRadioChannelsContainer';
import VenueFunctionalAreasContainer from '../../containers/VenueFunctionalAreasContainer';
import VenueSubLocationsContainer from '../../containers/VenueSubLocationsContainer';
import VenueCashTraxEventsContainer from '../../containers/VenueCashTraxEventsContainer';
import VenueCashTraxEventMessageAuditsContainer from '../../containers/VenueCashTraxEventMessageAuditsContainer';
import VenuePlayTraxEventsContainer from '../../containers/VenuePlayTraxEventsContainer';
import VenuePlayTraxEventMessageAuditsContainer from '../../containers/VenuePlayTraxEventMessageAuditsContainer';
import VenueEventsContainer from '../../containers/VenueEventsContainer';
import VenueFacialRecognitionPOIsContainer from '../../containers/VenueFacialRecognitionPOIsContainer';


export default class VenueRoute extends Component<any, any> {

    render() {
        return (
            <Switch>
                <Route path={`${this.props.match.url}/users`} component={VenueUsersContainer} />
                <Route path={`${this.props.match.url}/edit`} component={VenueEditContainer} />
                <Route path={`${this.props.match.url}/roles`} component={VenueRoleTypesContainer} />
                <Route path={`${this.props.match.url}/user/:userId`} component={VenueUserContainer} />
                <Route path={`${this.props.match.url}/checklistTemplates/:checklistTemplateId`} component={CheckListTemplateContainer} />
                <Route path={`${this.props.match.url}/checklistTemplates`} component={VenueChecklistTemplatesContainer} />
                <Route path={`${this.props.match.url}/venuereportingperiods`} component={VenueReportingPeriodsContainer} />
                <Route path={`${this.props.match.url}/currentreportingperiod`} component={VenueCurrentReportingPeriod} />
                <Route path={`${this.props.match.url}/directory`} component={VenueDirectoryContainer} />
                <Route path={`${this.props.match.url}/documents`} component={VenueDocumentsContainer} />
                <Route path={`${this.props.match.url}/contacts`} component={VenueContactsContainer} />
                <Route path={`${this.props.match.url}/groups`} component={VenueGroupsContainer} />
                <Route path={`${this.props.match.url}/associations`} component={VenueSecurityFirmAssociationsContainer} />
                <Route path={`${this.props.match.url}/subscription`} component={VenueSubscriptionContainer} />
                <Route path={`${this.props.match.url}/locations`} component={VenueLocationsContainer} />
                <Route path={`${this.props.match.url}/cells`} component={FacilityCellsContainer} />
                <Route path={`${this.props.match.url}/incidentcategorytypes`} component={VenueIncidentCategoryTypesContainer} />
                <Route path={`${this.props.match.url}/expiringcompliancedocuments`} component={VenueUserComplianceDocumentsExpiredContainer} />
                <Route path={`${this.props.match.url}/expiringcompliancedocument/:userId`} component={VenueUserContainer} />
                <Route path={`${this.props.match.url}/missingcompliancedocuments`} component={VenueUserComplianceDocumentsMissingContainer} />
                <Route path={`${this.props.match.url}/missingcompliancedocument/:userId`} component={VenueUserContainer} />
                <Route path={`${this.props.match.url}/systemlog`} component={VenueSystemLogContainer} />
                <Route path={`${this.props.match.url}/broadcastnotifications/:broadcastnotificationId`} component={VenueBroadcastNotificationContainer} />
                <Route path={`${this.props.match.url}/broadcastnotifications`} component={VenueBroadcastNotificationsContainer} />
                <Route path={`${this.props.match.url}/incidentflags`} component={IncidentTypeFlagNotificationsContainer} />
                <Route path={`${this.props.match.url}/facialRecognitionNotification/watchlistevents`} component={VenueFacialRecognitionNotificationEventsContainer} />
                <Route path={`${this.props.match.url}/facialRecognitionNotification/events`} component={VenueFacialRecognitionNotificationsEventsContainer} />
                <Route path={`${this.props.match.url}/facialRecognitionNotification/watchlists`} component={VenueFacialRecognitionNotificationsWatchlistsContainer} />
                <Route path={`${this.props.match.url}/facialRecognitionNotification/cameras`} component={VenueFacialRecognitionNotificationCamerasContainer} />
                <Route path={`${this.props.match.url}/facialRecognitionNotification/enrol`} component={VenueFacialRecognitionNotificationsEnrolContainer} />
                <Route path={`${this.props.match.url}/facialRecognitionNotification/pois`} component={VenueFacialRecognitionPOIsContainer} />
                <Route path={`${this.props.match.url}/facialRecognitionNotification`} component={VenueFacialRecognitionNotificationsContainer} />
                <Route path={`${this.props.match.url}/incidentActionTemplates/:incidentActionTemplateId`} component={VenueIncidentActionTemplateContainer} />
                <Route path={`${this.props.match.url}/incidentActionTemplates`} component={VenueIncidentActionTemplatesContainer} />
                <Route path={`${this.props.match.url}/riskstatuses`} component={CorrectionalFacilityRiskStatusContainer} />
                <Route path={`${this.props.match.url}/schedules/:scheduleId/logs`} component={VenueScheduleLogsContainer} />
                <Route path={`${this.props.match.url}/schedules/:scheduleId/:copyScheduleId`} component={VenueScheduleContainer} />
                <Route path={`${this.props.match.url}/schedules`} component={VenueSchedulesContainer} />
                <Route path={`${this.props.match.url}/radioChannels`} component={VenueRadioChannelsContainer} />
                <Route path={`${this.props.match.url}/functionalAreas`} component={VenueFunctionalAreasContainer} />
                <Route path={`${this.props.match.url}/subLocations`} component={VenueSubLocationsContainer} />
                <Route path={`${this.props.match.url}/cashtraxevents/audits`} component={VenueCashTraxEventMessageAuditsContainer} />
                <Route path={`${this.props.match.url}/cashtraxevents`} component={VenueCashTraxEventsContainer} />
                <Route path={`${this.props.match.url}/playtraxevents/audits`} component={VenuePlayTraxEventMessageAuditsContainer} />
                <Route path={`${this.props.match.url}/playtraxevents`} component={VenuePlayTraxEventsContainer} />
                <Route path={`${this.props.match.url}/events`} component={VenueEventsContainer} />
                <Route path={`${this.props.match.url}`} component={VenueContainer} />
                <Redirect to={`${this.props.match.url}`} />
            </Switch>
        );
    }
}