import { connect } from 'react-redux';
import IncidentDisplay from '../components/incidents/IncidentDisplay';
import { displayIncidentLoad } from '../common/actions/displayIncident';
import { withRouter } from "react-router";
import { permissionsLogic } from '../common/logic/permissionsLogic';

const mapStateToProps = (state, props) => {
    return {
        incidentid: props.incidentId,
        incident: state.displayIncident.incident,
        isLoading: state.displayIncident.isLoading,
        canViewUniqueIncidentID: permissionsLogic.hasPermissionForState(state,"IncidentUniqueIDView"),
        canShowVersions: permissionsLogic.hasAnyPermissionForState(state, ["IncidentSecurityFirmVersionsView", "IncidentVenueVersionsView"])
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoadIncident: (incidentId) => {
            dispatch(displayIncidentLoad(incidentId))
        }
    }
}

const IncidentDisplayContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(IncidentDisplay))

export default IncidentDisplayContainer