import React, { Component } from 'react';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import SwipeContainer from '../../layout/SwipeContainer';
import LayoutForm from '../../layout/LayoutForm';
import TextControlGroup from '../../controls/TextControlGroup';
import SelectControlGroup from '../../controls/SelectControlGroup';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import SimpleDateControlGroup from '../../controls/SimpleDateControlGroup';
import ControlGroup from '../../controls/ControlGroup';
import Saving from '../../common/Saving';


export interface IRegisterUserDetailsProps {
    user: AusComplyDTOs.User;
    errors: any[];
    genders: AusComplyDTOs.Gender[];
    isCheckingSled: boolean;
    onUpdate: Function;
    onSwipedRight?: Function;
    onSwipedLeft?: Function;
}

export default class RegisterUserDetails extends Component<IRegisterUserDetailsProps, any> {
    constructor(props: IRegisterUserDetailsProps) {
        super(props)
        this.state = {

        }
        this.onChange = this.onChange.bind(this);
        this.fieldError = this.fieldError.bind(this);
    }

    onChange(fieldname, value) {
        let user = { ...this.props.user };
        user[fieldname] = value;
        this.props.onUpdate(user);
    }

    fieldError(fieldName) {
        if (this.props.errors && Array.isArray(this.props.errors)) {
            let error = this.props.errors.find(f => f.field === fieldName);
            if (error) {
                return error.error;
            }
        }
        return "";
    }

    render() {
        if (this.props.isCheckingSled) {
            return <CardContainer
                title={"Details"}>
                <CardContent>
                    <LayoutForm>
                        <ControlGroup text={" "} labelAbove={true}>
                            <Saving message={"Checking License"} />
                        </ControlGroup>
                    </LayoutForm>
                </CardContent>
            </CardContainer>
        }

        return (
            <SwipeContainer onSwipedLeft={this.props.onSwipedLeft} onSwipedRight={this.props.onSwipedRight}>
                <CardContainer
                    title={"Details"}>
                    <CardContent>
                        <LayoutForm>
                            <TextControlGroup
                                text={"First Name"}
                                defaultValue={this.props.user.givenName}
                                error={this.fieldError("givenName")}
                                readonly={!this.props.user.canEditGivenName}
                                onBlur={(value) => this.onChange("givenName", value)} />
                            <TextControlGroup
                                text={"Last Name"}
                                defaultValue={this.props.user.surname}
                                error={this.fieldError("surname")}
                                readonly={!this.props.user.canEditSurname}
                                onBlur={(value) => this.onChange("surname", value)} />
                            <TextControlGroup
                                text={"Nickname"}
                                error={this.fieldError("nickname")}
                                defaultValue={this.props.user.nickname}
                                onBlur={(value) => this.onChange("nickname", value)} />
                            <SelectControlGroup
                                text="Gender"
                                error={this.fieldError("genderId")}
                                onChange={(value) => this.onChange("genderId", value)}
                                defaultValue={this.props.user.genderId}>
                                <option value={0} />
                                {this.props.genders.map((item, index) => (
                                    <option key={item.genderId} value={item.genderId}>{item.name}</option>
                                ))}
                            </SelectControlGroup>
                            {/* <SimpleDateControlGroup
                                text={"DOB"}
                                error={this.fieldError("dateOfBirth")}
                                date={this.props.user.dateOfBirth}
                                onChange={(value) => this.onChange("dateOfBirth", value)}
                            />*/}
                            <TextControlGroup
                                text={"Email Address"}
                                error={this.fieldError("email")}
                                defaultValue={this.props.user.email}
                                onBlur={(value) => this.onChange("email", value)} />
                            <TextControlGroup
                                text={"Mobile"}
                                error={this.fieldError("mobileNo")}
                                defaultValue={this.props.user.mobileNo}
                                onBlur={(value) => this.onChange("mobileNo", value)} />
                        </LayoutForm>
                    </CardContent>
                </CardContainer>
            </SwipeContainer>
        );
    }
}