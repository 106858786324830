import React from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface IIncidentHeaderProps {
    classes: any;
    theme: any;
    canApprove: boolean;
    canEdit: boolean;
    canView: boolean;
    canRemove?: boolean;
    showEvents: boolean;
    canViewUniqueIncidentID: boolean;
}

class IncidentHeader extends React.Component<IIncidentHeaderProps, any> {

    render() {
        const { classes } = this.props;

        if (this.props.canViewUniqueIncidentID) {
            return this.props.showEvents ? (
                <tr>
                    <th style={{width: '10px'}}></th>
                    <th className={classes.tableHr} style={{width: '180px'}}>ID</th>
                    <th className={classes.tableHr} style={{width: '120px'}}>Unique ID</th>
                    <th className={classes.tableHr} style={{width: '180px'}}>Time</th>
                    <th className={classes.tableHr} style={{width: '100px'}}>Type</th>
                    <th className={classes.tableHr} style={{width: '180px'}}>Categories</th>
                    <th className={classes.tableHr}>Narrative</th>
                    <th className={classes.tableHr} style={{width: '120px'}}>Event</th>
                    <th className={classes.tableHr} style={{width: '140px'}}>Locations</th>
                    <th className={classes.tableHr} style={{width: '120px'}}>Created By</th>
                    <th className={classes.tableHr} style={{width: '120px'}}>Last Modified By</th>
                    {this.props.canApprove && (
                        <th className={classes.tableHr} style={{ width: '80px', textAlign: 'center' }}>Approve</th>
                    )}
                    <th className={classes.tableHr} style={{width: '40px'}}></th>
                    {this.props.canRemove && (
                        <th className={classes.tableHr} style={{ width: '35px', textAlign: 'center' }}></th>
                    )}
                </tr>
            ) : (
                <tr>
                    <th style={{width: '10px'}}></th>
                    <th className={classes.tableHr} style={{width: '180px'}}>ID</th>
                    <th className={classes.tableHr} style={{width: '120px'}}>Unique ID</th>
                    <th className={classes.tableHr} style={{width: '180px'}}>Time</th>
                    <th className={classes.tableHr} style={{width: '100px'}}>Type</th>
                    <th className={classes.tableHr} style={{width: '180px'}}>Categories</th>
                    <th className={classes.tableHr}>Narrative</th>
                    <th className={classes.tableHr} style={{width: '140px'}}>Locations</th>
                    <th className={classes.tableHr} style={{width: '120px'}}>Created By</th>
                    <th className={classes.tableHr} style={{width: '120px'}}>Last Modified By</th>
                    {this.props.canApprove && (
                        <th className={classes.tableHr} style={{ width: '80px', textAlign: 'center' }}>Approve</th>
                    )}
                    <th className={classes.tableHr} style={{width: '40px'}}></th>
                    {this.props.canRemove && (
                        <th className={classes.tableHr} style={{ width: '35px', textAlign: 'center' }}></th>
                    )}
                </tr>
            );
        }
        
        return this.props.showEvents ? (
            <tr>
                <th style={{width: '10px'}}></th>
                <th className={classes.tableHr} style={{width: '180px'}}>ID</th>
                <th className={classes.tableHr} style={{width: '180px'}}>Time</th>
                <th className={classes.tableHr} style={{width: '100px'}}>Type</th>
                <th className={classes.tableHr} style={{width: '180px'}}>Categories</th>
                <th className={classes.tableHr}>Narrative</th>
                <th className={classes.tableHr} style={{width: '120px'}}>Event</th>
                <th className={classes.tableHr} style={{width: '140px'}}>Locations</th>
                <th className={classes.tableHr} style={{width: '120px'}}>Created By</th>
                <th className={classes.tableHr} style={{width: '120px'}}>Last Modified By</th>
                {this.props.canApprove && (
                    <th className={classes.tableHr} style={{ width: '80px', textAlign: 'center' }}>Approve</th>
                )}
                <th className={classes.tableHr} style={{width: '40px'}}></th>
                {this.props.canRemove && (
                    <th className={classes.tableHr} style={{ width: '35px', textAlign: 'center' }}></th>
                )}
            </tr>
        ) : (
            <tr>
                <th style={{width: '10px'}}></th>
                <th className={classes.tableHr} style={{width: '180px'}}>ID</th>
                <th className={classes.tableHr} style={{width: '180px'}}>Time</th>
                <th className={classes.tableHr} style={{width: '100px'}}>Type</th>
                <th className={classes.tableHr} style={{width: '180px'}}>Categories</th>
                <th className={classes.tableHr}>Narrative</th>
                <th className={classes.tableHr} style={{width: '140px'}}>Locations</th>
                <th className={classes.tableHr} style={{width: '120px'}}>Created By</th>
                <th className={classes.tableHr} style={{width: '120px'}}>Last Modified By</th>
                {this.props.canApprove && (
                    <th className={classes.tableHr} style={{ width: '80px', textAlign: 'center' }}>Approve</th>
                )}
                <th className={classes.tableHr} style={{width: '40px'}}></th>
                {this.props.canRemove && (
                    <th className={classes.tableHr} style={{ width: '35px', textAlign: 'center' }}></th>
                )}
            </tr>
        );
    }
}
export default withStyles(styles, { withTheme: true })(IncidentHeader);