import { connect } from 'react-redux'
import { withRouter } from "react-router";
import POIPagedIncidents from '../components/facialRecognition/facialRecognitionPOI/POIPagedIncidents'
import { 
    facialRecognitionPoiIncidentsRequest
 } from '../common/actions/facialRecognitionPOI';

const mapStateToProps = (state, props) => {
    let isLoading = false;
    let incidents = [];
    let paging = {page: 1, pageSize: 1};
    let record= props.poiGuid && props.poiGuid != '' ? 
        state.facialRecognitionPOI.poiIncidents.find(f => f.poiGuid == props.poiGuid)
        : state.facialRecognitionPOI.poiIncidents.find(f => f.facialRecognitionPOIIncidentId == props.facialRecognitionPOIIncidentId);
    if(record) {
        isLoading = record.isLoading;
        if (record.poiIncidents && record.poiIncidents.incidents)
        {
            incidents = record.poiIncidents.incidents.data;
            paging = record.poiIncidents.incidents.paging;
        }
    }

    return {
        facialRecognitionPOIIncidentId: props.facialRecognitionPOIIncidentId,
        poiGuid: props.poiGuid,
        forVenueId: props.forVenueId,
        facialRecognitionWatchlistId: props.facialRecognitionWatchlistId,
        detailed: props.detailed,
        summary: props.summary,
        isLoading: isLoading,
        paging: paging,
        incidents: incidents,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (facialRecognitionPOIIncidentId, poiGuid, paging, forVenueId, facialRecognitionWatchlistId) => {
            dispatch(facialRecognitionPoiIncidentsRequest(facialRecognitionPOIIncidentId, poiGuid, paging, forVenueId, facialRecognitionWatchlistId))
        }
    }
}

const POIPagedIncidentsContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(POIPagedIncidents))

export default POIPagedIncidentsContainer