import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { permissionsLogic } from '../common/logic/permissionsLogic';
import FacialRecognitionWatchlists from '../components/facialRecognition/facialRecognitionWatchlists/FacialRecognitionWatchlists'
import * as facialRecognitionWatchlistActions from '../common/actions/facialRecognitionWatchlist';

const mapStateToProps = (state, props) => {
    return {
        venueId: props.venueId,
        groupId: props.groupId,
        venueMode: props.venueMode,
        groupMode: props.groupMode,
        groupName: props.groupName,
        backButton: props.backButton,
        tabs: props.tabs,
        adminMode: props.adminMode,
        thumbnails: state.facialRecognitionWatchlists.facialRecognitionWatchlistPois.thumbnails,
        isLoading: state.facialRecognitionWatchlists.facialRecognitionWatchlists.isLoading,
        isSaving: state.facialRecognitionWatchlists.facialRecognitionWatchlist.isSaving,
        facialRecognitionWatchlists: state.facialRecognitionWatchlists.facialRecognitionWatchlists.facialRecognitionWatchlists,
        facialRecognitionWatchlist: state.facialRecognitionWatchlists.facialRecognitionWatchlist.facialRecognitionWatchlist,
        facialRecognitionWatchlistId: state.facialRecognitionWatchlists.facialRecognitionWatchlist.facialRecognitionWatchlistId,
        isLoadingItem: state.facialRecognitionWatchlists.facialRecognitionWatchlist.isLoading,
        facialRecognitionWatchlistTypes: state.facialRecognitionWatchlists.facialRecognitionWatchlist.facialRecognitionWatchlistTypes,
        facialRecognitionServers: state.facialRecognitionWatchlists.facialRecognitionWatchlist.facialRecognitionServers,
        canMigrate: permissionsLogic.isLevelSix(state),
        canMaintainGroupWatchlists: permissionsLogic.hasGroupPermissionForState(state, props.groupId, 'MaintainGroupWatchlists')
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (venueId, groupId) => {
            dispatch(facialRecognitionWatchlistActions.facialRecognitionWatchlistsRequest(venueId, groupId))
        },
        onSync: (venueId, groupId) => {
            dispatch(facialRecognitionWatchlistActions.facialRecognitionWatchlistSyncRequest(venueId, groupId))
        },
        onLoadItem: (facialRecognitionWatchlistId) => {
            dispatch(facialRecognitionWatchlistActions.facialRecognitionWatchlistRequest(facialRecognitionWatchlistId))
        },
        onCreate: (venueId, groupId) => {
            dispatch(facialRecognitionWatchlistActions.facialRecognitionWatchlistCreate(venueId, groupId))
        },
        onResetItem: () => {
            dispatch(facialRecognitionWatchlistActions.facialRecognitionWatchlistReset())
        },
        onUpdate: (facialRecognitionWatchlist) => {
            dispatch(facialRecognitionWatchlistActions.facialRecognitionWatchlistSet(facialRecognitionWatchlist))
        },
        onSave: () => {
            dispatch(facialRecognitionWatchlistActions.facialRecognitionWatchlistSaveRequest())
        },
        onCancel: () => {
            dispatch(facialRecognitionWatchlistActions.facialRecognitionWatchlistReset())
        },
        onLoadPois: (pageSize, filter) => {
            dispatch(facialRecognitionWatchlistActions.facialRecognitionWatchlistPoisRequest(1, pageSize, filter))
        },
        onMigrate: (venueId, facialRecognitionWatchlistId) => {
            dispatch(facialRecognitionWatchlistActions.facialRecognitionWatchlistNewMigrationRequest(venueId, facialRecognitionWatchlistId))
        },
        onProcess: () => {
            dispatch(facialRecognitionWatchlistActions.facialRecognitionWatchlistMigrationProcessRequest())
        },
        onMove: (venueId, facialRecognitionWatchlistId) => {
            dispatch(facialRecognitionWatchlistActions.facialRecognitionWatchlistNewMoveRequest(venueId, facialRecognitionWatchlistId))
        },
    }
}

const FacialRecognitionWatchlistsContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(FacialRecognitionWatchlists))

export default FacialRecognitionWatchlistsContainer