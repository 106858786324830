/* Options:
Date: 2025-03-21 13:01:41
Version: 5.60
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: http://localhost:64385/api

//GlobalNamespace: 
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IPost
{
}

export interface IGet
{
}

export interface IPut
{
}

export interface IDelete
{
}

export enum ngtEntityType
{
    None = 'None',
    Global = 'Global',
    User = 'User',
    Venue = 'Venue',
    SecurityFirm = 'SecurityFirm',
    Incident = 'Incident',
    Checklist = 'Checklist',
    ChecklistTemplate = 'ChecklistTemplate',
    Group = 'Group',
    Watchlist = 'Watchlist',
    WatchlistEvent = 'WatchlistEvent',
    Notification = 'Notification',
    VenueSubscription = 'VenueSubscription',
    SecurityFirmSubscription = 'SecurityFirmSubscription',
    Notebook = 'Notebook',
    RoublerRoster = 'RoublerRoster',
    CashPlayTrax = 'CashPlayTrax',
    CashTrax = 'CashTrax',
    PlayTrax = 'PlayTrax',
    IncidentFlag = 'IncidentFlag',
    IncidentCategoryType = 'IncidentCategoryType',
    Roller = 'Roller',
    Attachment = 'Attachment',
}

export class BaseStatelessRequest
{
    public venueId: number;
    public securityFirmId: number;
    public venueEventId: number;

    public constructor(init?: Partial<BaseStatelessRequest>) { (Object as any).assign(this, init); }
}

export class NamedValue
{
    public id: number;
    public parentId: number;
    public name: string;
    public rank: number;
    public selected: boolean;
    public obsolete: boolean;

    public constructor(init?: Partial<NamedValue>) { (Object as any).assign(this, init); }
}

export enum ngtExternalApiConnectionType
{
    OAuth2 = 'OAuth2',
    IdSecret = 'IdSecret',
}

export class ExternalApiConnection
{
    public externalApiConnectionId: number;
    public groupId: number;
    public venueId: number;
    public securityFirmId: number;
    public entityType: ngtEntityType;
    public clientID: string;
    public clientSecret: string;
    public sandpit: boolean;
    public token: string;
    public tokenExpiryUtc: string;
    public refreshToken: string;
    public lastError: string;
    public parentId: number;
    public parentName: string;
    public loginUrl: string;
    public display: string;
    public isConnected: boolean;
    public availableParents: NamedValue[];
    // @Ignore()
    public connectionType: ngtExternalApiConnectionType;

    public lastErrorShort: string;
    public canConnectToSandpit: boolean;

    public constructor(init?: Partial<ExternalApiConnection>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1, EmitDefaultValue=false)
    public errorCode: string;

    // @DataMember(Order=2, EmitDefaultValue=false)
    public fieldName: string;

    // @DataMember(Order=3, EmitDefaultValue=false)
    public message: string;

    // @DataMember(Order=4, EmitDefaultValue=false)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class EntityMapping
{
    public id: number;
    public entityId: number;
    public entityDisplay: string;
    public externalId: number;
    public externalDisplay: string;
    public modified: boolean;
    public obsolete: boolean;
    public externalApiConnectionId: number;

    public constructor(init?: Partial<EntityMapping>) { (Object as any).assign(this, init); }
}

export class RoublerEmployeeMapping
{
    public error: string;
    public mappings: EntityMapping[];
    public suggested: EntityMapping[];
    public users: NamedValue[];
    public employees: NamedValue[];
    public externalApiConnectionId: number;
    public isConnectionValid: boolean;
    public groupId: number;
    public venueId: number;
    public securityFirmId: number;

    public constructor(init?: Partial<RoublerEmployeeMapping>) { (Object as any).assign(this, init); }
}

export class BookingInclude
{
    public externalId: string;
    public tickets: boolean;

    public constructor(init?: Partial<BookingInclude>) { (Object as any).assign(this, init); }
}

export class BookingFilter
{
    public channels: number[];
    public productTypes: number[];
    public productIds: number[];
    public productCategory: string;

    public constructor(init?: Partial<BookingFilter>) { (Object as any).assign(this, init); }
}

export class Booking
{
    public events: number[];
    public include: BookingInclude;
    public filter: BookingFilter;

    public constructor(init?: Partial<Booking>) { (Object as any).assign(this, init); }
}

export class Redemption
{

    public constructor(init?: Partial<Redemption>) { (Object as any).assign(this, init); }
}

export class Customer
{

    public constructor(init?: Partial<Customer>) { (Object as any).assign(this, init); }
}

export class BulkExport
{

    public constructor(init?: Partial<BulkExport>) { (Object as any).assign(this, init); }
}

export class WebHooks
{
    public booking: Booking;
    public redemption: Redemption;
    public customer: Customer;
    public bulkExport: BulkExport;

    public constructor(init?: Partial<WebHooks>) { (Object as any).assign(this, init); }
}

export enum ngtVisibility
{
    Private = 'Private',
    Public = 'Public',
}

export enum ngtSyncStatus
{
    Pending = 'Pending',
    PendingUpdate = 'PendingUpdate',
    Processing = 'Processing',
    Complete = 'Complete',
    Error = 'Error',
    PendingMigration = 'PendingMigration',
    Migrating = 'Migrating',
    ErrorMigration = 'ErrorMigration',
    Migrated = 'Migrated',
}

export enum ngtServerType
{
    OnPremise = 'OnPremise',
    Cloud = 'Cloud',
    Edge = 'Edge',
}

export class FacialRecognitionServer
{
    public facialRecognitionServerId: number;
    public venueId: number;
    public name: string;
    public venueName: string;
    public serverType: ngtServerType;
    public username: string;
    public password: string;
    public newPassword: string;
    public eventUsername: string;
    public eventPassword: string;
    public eventNewPassword: string;
    public websocketUsername: string;
    public websocketPassword: string;
    public websocketNewPassword: string;
    public endpointPOI: string;
    public endpointCameras: string;
    public endpointHistory: string;
    public endpointUsers: string;
    public endpointEvents: string;
    public endpointWebsocket: string;
    public endpointController: string;
    public endpointFramesStorage: string;
    public apiVersion: string;
    public appearanceMode: boolean;
    public apiVersionDisplay: string;
    public advancedConfiguration: boolean;
    public verified: boolean;
    public verificationError: string;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public linkedServers: FacialRecognitionServer[];
    public linkedServerWarning: string;

    public constructor(init?: Partial<FacialRecognitionServer>) { (Object as any).assign(this, init); }
}

export enum ngtRegisterUserTo
{
    Venue = 'Venue',
    Security = 'Security',
    None = 'None',
}

export enum ngtRegisterUserAs
{
    Staff = 'Staff',
    Other = 'Other',
    RSAMarshal = 'RSAMarshal',
}

export enum ngtSLEDStatus
{
    Unchecked = 'Unchecked',
    Failed = 'Failed',
    Expired = 'Expired',
    Valid = 'Valid',
    Bypass = 'Bypass',
    Surrendered = 'Surrendered',
    Suspended = 'Suspended',
    Cancelled = 'Cancelled',
    MultiCheckInvalid = 'MultiCheckInvalid',
}

export class SimpleDate
{
    public isEmpty: boolean;
    public year: number;
    public month: number;
    public day: number;
    public hour: number;
    public minute: number;
    public date: string;
    public display: string;

    public constructor(init?: Partial<SimpleDate>) { (Object as any).assign(this, init); }
}

export enum ngtUserNotificationPreference
{
    None = 'None',
    Email = 'Email',
    Sms = 'Sms',
    InApp = 'InApp',
}

export class UserRoleRequest
{
    public notificationID: number;
    public type: string;
    public name: string;
    public requestDate: string;
    public dateFormatted: string;
    public status: string;

    public constructor(init?: Partial<UserRoleRequest>) { (Object as any).assign(this, init); }
}

export class UserRoleSummary implements IUserDisplay
{
    public userRoleId: number;
    public userId: number;
    public givenName: string;
    public surname: string;
    public nickname: string;
    public mobileNo: string;
    public rsaNumber: string;
    public licenseNumber: string;
    public userRoleTypeId: number;
    public isGroup: boolean;
    public isVenue: boolean;
    public isSecurity: boolean;
    public userRoleType: string;
    public accessLevel: number;
    public groupId: number;
    public group: string;
    public venueId: number;
    public venue: string;
    public securityFirmId: number;
    public securityFirm: string;
    public userRoleStatusId: number;
    public userRoleStatus: string;
    public isTemporary: boolean;
    public expiryDate: string;
    public expiryDateUtc: string;
    public hasAcceptedTerms: boolean;
    public bypassSLEDCheck: boolean;
    public adminNotes: string;
    public enteredBy: number;
    public dateEntered: string;
    public lastModifiedBy: number;
    public dateModified: string;
    public editUserAllowed: boolean;
    public editAllowed: boolean;
    public deleteAllowed: boolean;
    public viewUserAllowed: boolean;
    public canViewDetails: boolean;
    public canViewBasic: boolean;
    public userObsolete: boolean;
    public userRoleObsolete: boolean;
    public isLoggedIn: boolean;
    public device: string;
    public isCustom: boolean;
    public defaultUserRoleTypeId: number;
    public licenceNumber: string;
    public userOrUserRoleObsolete: boolean;
    public userVenueSecurityFirm: string;
    public display: string;
    public shortDisplay: string;

    public constructor(init?: Partial<UserRoleSummary>) { (Object as any).assign(this, init); }
}

export class UserOtherDocument
{
    public userDocumentId: number;
    public userOtherDocumentId: number;
    public userId: number;
    public extension: string;
    public originalFileName: string;
    public canvasRescaled: boolean;
    public obsolete: boolean;
    public enteredBy: number;
    public mimeType: string;
    public filePath: string;
    public filePreviewPath: string;
    public filePreviewText: string;
    public fileDisplayPath: string;
    public isImage: boolean;
    public documentTypeId: number;
    public originalDocumentTypeId: number;
    public documentType: string;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public lastModifiedBy: number;

    public constructor(init?: Partial<UserOtherDocument>) { (Object as any).assign(this, init); }
}

export class UserComplianceDocument
{
    public userDocumentId: number;
    public userComplianceDocumentId: number;
    public userId: number;
    public extension: string;
    public originalFileName: string;
    public canvasRescaled: boolean;
    public obsolete: boolean;
    public enteredBy: number;
    public mimeType: string;
    public filePath: string;
    public filePreviewPath: string;
    public filePreviewText: string;
    public fileDisplayPath: string;
    public isImage: boolean;
    public documentTypeId: number;
    public originalDocumentTypeId: number;
    public documentType: string;
    public documentTypeIsRequired: boolean;
    public licenceNumber: string;
    public dateExpiry: string;
    public dateOfExpiry: SimpleDate;
    public hasExpired: boolean;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public lastModifiedBy: number;

    public constructor(init?: Partial<UserComplianceDocument>) { (Object as any).assign(this, init); }
}

export class User implements IUserDisplay
{
    public userId: number;
    public registerUserTo: ngtRegisterUserTo;
    public registerUserAs: ngtRegisterUserAs;
    public isVenueStaff: boolean;
    public isSecurityStaff: boolean;
    public canEditIsVenueStaff: boolean;
    public canEditIsSecurityStaff: boolean;
    public givenName: string;
    public canEditGivenName: boolean;
    public surname: string;
    public canEditSurname: boolean;
    public nickname: string;
    public genderId: number;
    public gender: string;
    public email: string;
    public dateLastSledCheck: string;
    public dateLastSledCheckUtc: string;
    public dateVerifiedUtc: string;
    public dateMobileNoVerifiedUtc: string;
    public isMobileNoVerified: boolean;
    public dateLastSledCheckDisplay: string;
    public sledStatus: ngtSLEDStatus;
    public sledStatusDisplay: string;
    public sledChecked: boolean;
    public sledValid: boolean;
    public sledFailed: boolean;
    public sledEnabled: boolean;
    public canCheckSled: boolean;
    public stateLicensedId: number;
    public stateLicensed: string;
    public sledExpiryDate: string;
    public sledExpiryDateDisplay: string;
    public licenceNumber: string;
    public originalLicenceNumber: string;
    public licenceNumberChecked: string;
    public canEditLicenceNumber: boolean;
    public rsaNumber: string;
    public dob: string;
    public dateOfBirth: SimpleDate;
    public address: string;
    public suburb: string;
    public postcode: string;
    public mobileNo: string;
    public stateId: number;
    public userNotificationPreference: ngtUserNotificationPreference;
    public smsNotification: boolean;
    public emailNotification: boolean;
    public globalSmsBroadcastNotification: boolean;
    public globalEmailBroadcastNotification: boolean;
    public venueSecurityFirmSmsBroadcastNotification: boolean;
    public venueSecurityFirmEmailBroadcastNotification: boolean;
    public inAppNotification: boolean;
    public state: string;
    public emergencyContactName: string;
    public emergencyContactNumber: string;
    public userRoleRequests: UserRoleRequest[];
    public userRoleSummaries: UserRoleSummary[];
    public userOtherDocuments: UserOtherDocument[];
    public userComplianceDocuments: UserComplianceDocument[];
    public hasAcceptedTerms: boolean;
    public requiresUpdate: boolean;
    public newPassword: string;
    public newPasswordConfirm: string;
    public displayName: string;
    public isLoggedIn: boolean;
    public isVerified: boolean;
    public canEdit: boolean;
    public canEditDocuments: boolean;
    public canViewDetails: boolean;
    public canAddVenueRoles: boolean;
    public canAddSecurityRoles: boolean;
    public canViewBasic: boolean;
    public obsolete: boolean;

    public constructor(init?: Partial<User>) { (Object as any).assign(this, init); }
}

export class FacialRecognitionWatchlistType
{
    public facialRecognitionWatchlistTypeId: number;
    public name: string;
    public rank: number;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public enteredByUser: User;
    public lastModifiedByUser: User;

    public constructor(init?: Partial<FacialRecognitionWatchlistType>) { (Object as any).assign(this, init); }
}

export class FacialRecognitionWatchlist
{
    public facialRecognitionWatchlistId: number;
    public venueId: number;
    public groupId: number;
    public watchlistGuid: string;
    public displayName: string;
    public displayColor: string;
    public watchlistType: string;
    public facialRecognitionWatchlistTypeId: number;
    public rank: number;
    public isAdministrator: boolean;
    public visibility: ngtVisibility;
    public syncStatus: ngtSyncStatus;
    public syncError: string;
    public hideFromVenues: boolean;
    public obsolete: boolean;
    public facialRecognitionServerId: number;
    public canMigrateToGroup: boolean;
    public facialRecognitionServer: FacialRecognitionServer;
    public facialRecognitionWatchlistType: FacialRecognitionWatchlistType;

    public constructor(init?: Partial<FacialRecognitionWatchlist>) { (Object as any).assign(this, init); }
}

export class RollerConfiguration
{
    public webhookId: number;
    public enabled: boolean;
    public webHooks: WebHooks;
    public externalApiConnectionId: number;
    public facialRecognitionWatchlistId: number;
    public facialRecognitionWatchlists: FacialRecognitionWatchlist[];

    public constructor(init?: Partial<RollerConfiguration>) { (Object as any).assign(this, init); }
}

export class SimpleFilter
{
    public search: string;
    public showObsolete: boolean;
    public forGroupId: number;
    public forVenueId: number;
    public forSecurityFirmId: number;
    public entityType: ngtEntityType;
    public entityId: number;
    public display: string;

    public constructor(init?: Partial<SimpleFilter>) { (Object as any).assign(this, init); }
}

export class ExternalApiConnectionWebhookMessage
{
    public externalApiConnectionWebhookMessageId: number;
    public externalApiConnectionId: number;
    public groupId: number;
    public venueId: number;
    public securityFirmId: number;
    public createdEntityType: ngtEntityType;
    public createdEntityId: number;
    public apiMessage: string;
    public rawMessage: string;
    public rawMessageTruncated: boolean;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public display: string;
    public rawDisplay: string;
    public dateEnteredDisplay: string;

    public constructor(init?: Partial<ExternalApiConnectionWebhookMessage>) { (Object as any).assign(this, init); }
}

export class Paging
{
    public pageSize: number;
    public page: number;
    public pages: number;
    public total: number;
    public unFilteredTotal: number;

    public constructor(init?: Partial<Paging>) { (Object as any).assign(this, init); }
}

export class CorrectionalFacilityInMateNote
{
    public correctionalFacilityInMateNoteId: number;
    public correctionalFacilityInMateId: number;
    public important: boolean;
    public note: string;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;

    public constructor(init?: Partial<CorrectionalFacilityInMateNote>) { (Object as any).assign(this, init); }
}

export class Attachment
{
    public attachmentId: number;
    public fileName: string;
    public fileType: string;
    public data: string;
    public obsolete: boolean;
    public isMigrated: boolean;
    public storageFileName: string;
    public dateEntered: string;
    public dateModified: string;
    public enteredByUserId: number;
    public lastModifiedByUserId: number;
    public isImage: boolean;
    public mimeType: string;
    public filePreviewPath: string;
    public filePreviewText: string;
    public fileDisplayPath: string;

    public constructor(init?: Partial<Attachment>) { (Object as any).assign(this, init); }
}

export class CorrectionalFacilityInMateAttachment
{
    public correctionalFacilityInMateAttachmentId: number;
    public venueId: number;
    public correctionalFacilityInMateId: number;
    public attachmentId: number;
    public isPrimaryPhoto: boolean;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public attachment: Attachment;

    public constructor(init?: Partial<CorrectionalFacilityInMateAttachment>) { (Object as any).assign(this, init); }
}

export class IndustryCategorySelection
{
    public industryCategoryId: number;
    public incidentTypeIndustryCategoryId: number;
    public incidentFlagTypeIndustryCategoryId: number;
    public parentId: number;
    public name: string;
    public rank: number;
    public obsolete: boolean;
    public selected: boolean;

    public constructor(init?: Partial<IndustryCategorySelection>) { (Object as any).assign(this, init); }
}

export class SubLocation
{
    public subLocationId: number;
    public groupId: number;
    public venueId: number;
    public name: string;
    public usageCount: number;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public enteredByUser: User;
    public lastModifiedByUser: User;

    public constructor(init?: Partial<SubLocation>) { (Object as any).assign(this, init); }
}

export class VenueLocationSubLocation
{
    public venueLocationSubLocationId: number;
    public subLocationId: number;
    public venueLocationId: number;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public subLocation: SubLocation;
    public venueLocation: VenueLocation;
    public enteredByUser: User;
    public lastModifiedByUser: User;

    public constructor(init?: Partial<VenueLocationSubLocation>) { (Object as any).assign(this, init); }
}

export class VenueLocation
{
    public venueLocationId: number;
    public venueId: number;
    public venueName: string;
    public name: string;
    public code: string;
    public rank: number;
    public usageCount: number;
    public obsolete: boolean;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public editAllowed: boolean;
    public deleteAllowed: boolean;
    public industryCategorySelections: IndustryCategorySelection[];
    public industryCategoriesDisplay: string;
    public facialRecognitionCameraSummary: string;
    public venueLocationSubLocations: VenueLocationSubLocation[];
    public selectedSubLocationsDisplay: string;
    public selectedSubLocations: number[];
    public subLocations: SubLocation[];

    public constructor(init?: Partial<VenueLocation>) { (Object as any).assign(this, init); }
}

export class IncidentCorrectionalFacilityInMateVenueLocation
{
    public incidentCorrectionalFacilityInMateVenueLocationId: number;
    public correctionalFacilityInMateVenueLocationId: number;
    public correctionalFacilityInMateId: number;
    public correctionalFacilityRiskStatusId: number;
    public incidentID: number;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public correctionalFacilityInMateVenueLocation: CorrectionalFacilityInMateVenueLocation;
    public correctionalFacilityInMate: CorrectionalFacilityInMate;
    public enteredByUser: User;
    public lastModifiedByUser: User;

    public constructor(init?: Partial<IncidentCorrectionalFacilityInMateVenueLocation>) { (Object as any).assign(this, init); }
}

export class CorrectionalFacilityInMateVenueLocation
{
    public correctionalFacilityInMateVenueLocationId: number;
    public venueId: number;
    public correctionalFacilityInMateId: number;
    public venueLocationId: number;
    public subLocation: string;
    public correctionalFacilityRiskStatusId: number;
    public inspectionInterval: number;
    public inspectionIntervalReason: string;
    public arrivalDateTimeUtc: string;
    public departureDateTimeUtc: string;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public lastInspectionDateUtc: string;
    public nextInspectionDateUtc: string;
    public nextInspectionDateDisplay: string;
    public isNextInspectionOverdue: boolean;
    public venueLocation: VenueLocation;
    public incidentCorrectionalFacilityInMateVenueLocations: IncidentCorrectionalFacilityInMateVenueLocation[];

    public constructor(init?: Partial<CorrectionalFacilityInMateVenueLocation>) { (Object as any).assign(this, init); }
}

export class Gender
{
    public genderId: number;
    public name: string;

    public constructor(init?: Partial<Gender>) { (Object as any).assign(this, init); }
}

export class Nationality
{
    public nationalityId: number;
    public venueId: number;
    public name: string;
    public isDefault: boolean;
    public rank: number;
    public obsolete: boolean;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public enteredBy: number;
    public dateModified: string;
    public dateModifiedUtc: string;
    public lastModifiedBy: number;

    public constructor(init?: Partial<Nationality>) { (Object as any).assign(this, init); }
}

export class File
{
    public fileId: number;
    public originalName: string;
    public serverPath: string;
    public path: string;
    public fullDirectoryPath: string;
    public fileName: string;
    public exists: boolean;
    public isTemporary: boolean;
    public isImage: boolean;
    public isWider: boolean;
    public originalObsolete: boolean;
    public obsolete: boolean;
    public documentTypeId: number;
    public attachmentId: number;
    public expiryDate: SimpleDate;
    public poiGuid: string;
    public appearanceGuid: string;
    public storageFileName: string;
    public canvasRescaled: boolean;
    public extension: string;
    public mimeType: string;
    public filePreviewPath: string;
    public fileDisplayPath: string;
    public filePreviewText: string;
    public visibleToVenue: boolean;

    public constructor(init?: Partial<File>) { (Object as any).assign(this, init); }
}

export class CorrectionalFacilityInMate
{
    public correctionalFacilityInMateId: number;
    public venueId: number;
    public cniNumber: string;
    public masterIndexNumber: string;
    public firstName: string;
    public lastName: string;
    public genderId: number;
    public nationalityId: number;
    public dateOfBirth: string;
    public dobDate: SimpleDate;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public correctionalFacilityInMateNotes: CorrectionalFacilityInMateNote[];
    public correctionalFacilityInMateAttachments: CorrectionalFacilityInMateAttachment[];
    public correctionalFacilityInMateVenueLocations: CorrectionalFacilityInMateVenueLocation[];
    public incidentCorrectionalFacilityInMateVenueLocations: IncidentCorrectionalFacilityInMateVenueLocation[];
    public gender: Gender;
    public nationality: Nationality;
    public photo: File;
    public age: string;

    public constructor(init?: Partial<CorrectionalFacilityInMate>) { (Object as any).assign(this, init); }
}

export class IncidentCategoryTypeSelection
{
    public incidentCategoryTypeId: number;
    public parentId: number;
    public name: string;
    public rank: number;
    public incidentTypes: number[];
    public obsolete: boolean;
    public selected: boolean;

    public constructor(init?: Partial<IncidentCategoryTypeSelection>) { (Object as any).assign(this, init); }
}

export class IncidentType
{
    public incidentTypeId: number;
    public name: string;
    public legislated: boolean;
    public obsolete: boolean;
    public rank: number;
    public featureId: number;
    public apiOnlyCreation: boolean;
    public isRGO: boolean;
    public excludeFromSnapShotIfZero: boolean;
    public entryPage: string;
    public editPage: string;
    public color: string;
    public setIncidentDateOnComplete: boolean;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public industryCategories: number[];
    public industryCategorySelections: IndustryCategorySelection[];
    public industryCategoriesDisplay: string;
    public incidentCategoryTypes: number[];
    public incidentCategoryTypeSelections: IncidentCategoryTypeSelection[];
    public incidentCategoryTypesDisplay: string;
    public venueIds: number[];

    public constructor(init?: Partial<IncidentType>) { (Object as any).assign(this, init); }
}

export class IncidentTypeSelection
{
    public incidentTypeId: number;
    public parentId: number;
    public name: string;
    public rank: number;
    public obsolete: boolean;
    public selected: boolean;
    public legislated: boolean;
    public isIdCheck: boolean;
    public hasEntryPage: boolean;
    public isRGO: boolean;
    public defaultSelection: boolean;

    public constructor(init?: Partial<IncidentTypeSelection>) { (Object as any).assign(this, init); }
}

export enum ngtNotificationTriggerParentType
{
    ChecklistItemValueSubmit = 'ChecklistItemValueSubmit',
    WatchlistEvent = 'WatchlistEvent',
    ExternalEvent = 'ExternalEvent',
    ChecklistSubmit = 'ChecklistSubmit',
    IncidentFlagType = 'IncidentFlagType',
    IncidentCategoryType = 'IncidentCategoryType',
}

export enum ngtUserPermission
{
    AddIncident = 'AddIncident',
    EditIncident = 'EditIncident',
    ViewReadOnlyIncident = 'ViewReadOnlyIncident',
    ApproveIncident = 'ApproveIncident',
    EditIncidents = 'EditIncidents',
    ViewIncidents = 'ViewIncidents',
    RecommendDeletionIncident = 'RecommendDeletionIncident',
    ViewDraftIncidents = 'ViewDraftIncidents',
    EditDraftIncidents = 'EditDraftIncidents',
    AddL1User = 'AddL1User',
    EditL1User = 'EditL1User',
    SuspendL1User = 'SuspendL1User',
    AddL1UserAccess = 'AddL1UserAccess',
    EditL1UserAccess = 'EditL1UserAccess',
    RemoveL1UserAccess = 'RemoveL1UserAccess',
    AddTempL1User = 'AddTempL1User',
    EditTempL1User = 'EditTempL1User',
    SuspendTempL1User = 'SuspendTempL1User',
    AddTempL1UserAccess = 'AddTempL1UserAccess',
    EditTempL1UserAccess = 'EditTempL1UserAccess',
    RemoveTempL1UserAccess = 'RemoveTempL1UserAccess',
    AddTempL2User = 'AddTempL2User',
    EditTempL2User = 'EditTempL2User',
    SuspendTempL2User = 'SuspendTempL2User',
    AddTempL2UserAccess = 'AddTempL2UserAccess',
    EditTempL2UserAccess = 'EditTempL2UserAccess',
    RemoveTempL2UserAccess = 'RemoveTempL2UserAccess',
    ViewLoggedInUsers = 'ViewLoggedInUsers',
    ApproveUnapprovedSecurityFirmIncident = 'ApproveUnapprovedSecurityFirmIncident',
    TransferIncidentToAnotherRegister = 'TransferIncidentToAnotherRegister',
    AddL2User = 'AddL2User',
    EditL2User = 'EditL2User',
    SuspendL2User = 'SuspendL2User',
    AddL2UserAccess = 'AddL2UserAccess',
    EditL2UserAccess = 'EditL2UserAccess',
    RemoveL2UserAccess = 'RemoveL2UserAccess',
    AddTempL3User = 'AddTempL3User',
    EditTempL3User = 'EditTempL3User',
    SuspendTempL3User = 'SuspendTempL3User',
    AddTempL3UserAccess = 'AddTempL3UserAccess',
    EditTempL3UserAccess = 'EditTempL3UserAccess',
    RemoveTempL3UserAccess = 'RemoveTempL3UserAccess',
    DeleteIncidents = 'DeleteIncidents',
    AddAssocSecurityFirmAndVenue = 'AddAssocSecurityFirmAndVenue',
    RemoveAssocSecurityFirmAndVenue = 'RemoveAssocSecurityFirmAndVenue',
    AddAssocSecurityFirmAndSecurityFirm = 'AddAssocSecurityFirmAndSecurityFirm',
    RemoveAssocSecurityFirmAndSecurityFirm = 'RemoveAssocSecurityFirmAndSecurityFirm',
    NoNightlyCode = 'NoNightlyCode',
    OverrideSLEDValidation = 'OverrideSLEDValidation',
    ViewReportingPeriods = 'ViewReportingPeriods',
    ManageVenue = 'ManageVenue',
    EditSecurityFirm = 'EditSecurityFirm',
    AddL3User = 'AddL3User',
    EditL3User = 'EditL3User',
    SuspendL3User = 'SuspendL3User',
    AddL3UserAccess = 'AddL3UserAccess',
    EditL3UserAccess = 'EditL3UserAccess',
    RemoveL3UserAccess = 'RemoveL3UserAccess',
    AddL4User = 'AddL4User',
    EditL4User = 'EditL4User',
    SuspendL4User = 'SuspendL4User',
    AddL4UserAccess = 'AddL4UserAccess',
    EditL4UserAccess = 'EditL4UserAccess',
    RemoveL4UserAccess = 'RemoveL4UserAccess',
    AddTempL4User = 'AddTempL4User',
    EditTempL4User = 'EditTempL4User',
    SuspendTempL4User = 'SuspendTempL4User',
    AddTempL4UserAccess = 'AddTempL4UserAccess',
    EditTempL4UserAccess = 'EditTempL4UserAccess',
    RemoveTempL4UserAccess = 'RemoveTempL4UserAccess',
    DeleteAssocSecurityFirmAndVenue = 'DeleteAssocSecurityFirmAndVenue',
    DeleteAssocSecurityFirmAndSecurityFirm = 'DeleteAssocSecurityFirmAndSecurityFirm',
    ManageReportingPeriods = 'ManageReportingPeriods',
    GeneratePINCode = 'GeneratePINCode',
    AddIncidentCategory = 'AddIncidentCategory',
    EditIncidentCategory = 'EditIncidentCategory',
    AddVenueBypassPayment = 'AddVenueBypassPayment',
    SuspendVenue = 'SuspendVenue',
    AddSecurityFirmBypassPayment = 'AddSecurityFirmBypassPayment',
    SuspendSecurityFirm = 'SuspendSecurityFirm',
    AddUser = 'AddUser',
    EditUser = 'EditUser',
    SuspendUser = 'SuspendUser',
    AddAssocSecurityFirmAndUser = 'AddAssocSecurityFirmAndUser',
    DeleteAssocVenueAndUser = 'DeleteAssocVenueAndUser',
    DelIncidentCategory = 'DelIncidentCategory',
    ManageSecurityFirmUserComplianceDocuments = 'ManageSecurityFirmUserComplianceDocuments',
    ChecklistTemplateCategorySearchVenues = 'ChecklistTemplateCategorySearchVenues',
    ChecklistTemplateCategorySearchSecurityFirms = 'ChecklistTemplateCategorySearchSecurityFirms',
    ChecklistTemplateCategorySearchIndustryCategories = 'ChecklistTemplateCategorySearchIndustryCategories',
    ChecklistTemplateCategorySearchStates = 'ChecklistTemplateCategorySearchStates',
    ChecklistTemplateCategoryArchive = 'ChecklistTemplateCategoryArchive',
    ChecklistTemplateCategoryCreate = 'ChecklistTemplateCategoryCreate',
    ChecklistTemplateCategoryEdit = 'ChecklistTemplateCategoryEdit',
    ChecklistTemplateCategoryEditVenues = 'ChecklistTemplateCategoryEditVenues',
    ChecklistTemplateCategoryEditSecurityFirms = 'ChecklistTemplateCategoryEditSecurityFirms',
    ChecklistTemplateCategoryEditIndustryCategories = 'ChecklistTemplateCategoryEditIndustryCategories',
    ChecklistTemplateCategoryEditStates = 'ChecklistTemplateCategoryEditStates',
    ChecklistTemplateSearchVenues = 'ChecklistTemplateSearchVenues',
    ChecklistTemplateSearchSecurityFirms = 'ChecklistTemplateSearchSecurityFirms',
    ChecklistTemplateSearchIndustryCategories = 'ChecklistTemplateSearchIndustryCategories',
    ChecklistTemplateSearchStates = 'ChecklistTemplateSearchStates',
    ChecklistTemplateArchive = 'ChecklistTemplateArchive',
    ChecklistTemplateCreate = 'ChecklistTemplateCreate',
    ChecklistTemplateEdit = 'ChecklistTemplateEdit',
    ChecklistTemplateEditVenues = 'ChecklistTemplateEditVenues',
    ChecklistTemplateEditSecurityFirms = 'ChecklistTemplateEditSecurityFirms',
    ChecklistTemplateEditIndustryCategories = 'ChecklistTemplateEditIndustryCategories',
    ChecklistTemplateEditStates = 'ChecklistTemplateEditStates',
    ChecklistTemplateEditCategories = 'ChecklistTemplateEditCategories',
    ChecklistTemplateSearchGlobal = 'ChecklistTemplateSearchGlobal',
    ChecklistTemplateCategorySearchGlobal = 'ChecklistTemplateCategorySearchGlobal',
    ChecklistSearchGlobal = 'ChecklistSearchGlobal',
    ChecklistApprove = 'ChecklistApprove',
    ChecklistCreate = 'ChecklistCreate',
    ChecklistEdit = 'ChecklistEdit',
    ChecklistArchive = 'ChecklistArchive',
    ChecklistTemplateClone = 'ChecklistTemplateClone',
    ChecklistTemplateCategoryListView = 'ChecklistTemplateCategoryListView',
    ChecklistTemplateListView = 'ChecklistTemplateListView',
    ChecklistListView = 'ChecklistListView',
    ChecklistTemplateCategoryView = 'ChecklistTemplateCategoryView',
    ChecklistTemplateView = 'ChecklistTemplateView',
    ChecklistView = 'ChecklistView',
    ChecklistClose = 'ChecklistClose',
    ChecklistTemplateCategorySearch = 'ChecklistTemplateCategorySearch',
    ChecklistTemplateCategorySearchUnrestricted = 'ChecklistTemplateCategorySearchUnrestricted',
    ChecklistTemplateSearch = 'ChecklistTemplateSearch',
    ChecklistTemplateSearchUnrestricted = 'ChecklistTemplateSearchUnrestricted',
    ChecklistSearch = 'ChecklistSearch',
    ChecklistSearchUnrestricted = 'ChecklistSearchUnrestricted',
    ChecklistReject = 'ChecklistReject',
    ChecklistTemplateEditGlobal = 'ChecklistTemplateEditGlobal',
    ChecklistSearchEnteredBy = 'ChecklistSearchEnteredBy',
    EditIncidentCategoryTypeIndustryCategory = 'EditIncidentCategoryTypeIndustryCategory',
    EditVenueIndustryCategory = 'EditVenueIndustryCategory',
    ShowIncidentCategoryTypeIndustryCategory = 'ShowIncidentCategoryTypeIndustryCategory',
    CreateLegislatedIncidentTypeIncidentCategories = 'CreateLegislatedIncidentTypeIncidentCategories',
    ChecklistTemplateArchiveGlobal = 'ChecklistTemplateArchiveGlobal',
    ExportSecurityFirms = 'ExportSecurityFirms',
    ExportVenues = 'ExportVenues',
    SignOnRegisterView = 'SignOnRegisterView',
    SignOnOffRegisterView = 'SignOnOffRegisterView',
    IncidentVenueVersionsView = 'IncidentVenueVersionsView',
    IncidentSecurityFirmVersionsView = 'IncidentSecurityFirmVersionsView',
    IncidentSummaryReport = 'IncidentSummaryReport',
    IncidentSummaryAndDetailsReport = 'IncidentSummaryAndDetailsReport',
    IncidentSummaryAndDetailedVersionReport = 'IncidentSummaryAndDetailedVersionReport',
    ViewIncidentFilterReportingPeriod = 'ViewIncidentFilterReportingPeriod',
    ViewIncidentFilterAdvanced = 'ViewIncidentFilterAdvanced',
    ChecklistPrint = 'ChecklistPrint',
    SignOnToRegister = 'SignOnToRegister',
    ViewUserRoles = 'ViewUserRoles',
    ManageSecurityFirm = 'ManageSecurityFirm',
    ViewEvent = 'ViewEvent',
    ManageEvent = 'ManageEvent',
    ManageVenueUserComplianceDocuments = 'ManageVenueUserComplianceDocuments',
    VenueContactEdit = 'VenueContactEdit',
    SecurityFirmContactEdit = 'SecurityFirmContactEdit',
    ViewVenue = 'ViewVenue',
    ManageVenueSubscription = 'ManageVenueSubscription',
    ManageSecurityFirmSubscription = 'ManageSecurityFirmSubscription',
    AdminDashboard = 'AdminDashboard',
    Dashboard = 'Dashboard',
    ViewRoleType = 'ViewRoleType',
    EditRoleType = 'EditRoleType',
    DeleteRoleType = 'DeleteRoleType',
    ManageL0UserAccessRoleTypes = 'ManageL0UserAccessRoleTypes',
    AddL0UserAccess = 'AddL0UserAccess',
    EditL0UserAccess = 'EditL0UserAccess',
    RemoveL0UserAccess = 'RemoveL0UserAccess',
    AddTempL0UserAccess = 'AddTempL0UserAccess',
    EditTempL0UserAccess = 'EditTempL0UserAccess',
    RemoveTempL0UserAccess = 'RemoveTempL0UserAccess',
    MaintainUserRoleNotification = 'MaintainUserRoleNotification',
    CreateBroadcastNotification = 'CreateBroadcastNotification',
    MaintainFacialRecognitionNotification = 'MaintainFacialRecognitionNotification',
    MaintainIncidentActions = 'MaintainIncidentActions',
    EditIncidentActions = 'EditIncidentActions',
    MaintainGroupIncidentActions = 'MaintainGroupIncidentActions',
    MaintainGlobalIncidentActions = 'MaintainGlobalIncidentActions',
    ViewGroup = 'ViewGroup',
    MaintainGroup = 'MaintainGroup',
    MaintainGroupVenues = 'MaintainGroupVenues',
    MaintainGroupManagers = 'MaintainGroupManagers',
    MaintainGroupRoles = 'MaintainGroupRoles',
    ViewGroupDashboard = 'ViewGroupDashboard',
    ViewGroupDashboardFilter = 'ViewGroupDashboardFilter',
    CreateGroup = 'CreateGroup',
    AdminMaintainGroup = 'AdminMaintainGroup',
    RegisterFacialRecognitionPOI = 'RegisterFacialRecognitionPOI',
    EditSecurityFirmMasterLicence = 'EditSecurityFirmMasterLicence',
    MaintainGroupIndustries = 'MaintainGroupIndustries',
    MaintainGroupFeatures = 'MaintainGroupFeatures',
    ManageVenueCameras = 'ManageVenueCameras',
    ManageVenueWatchlists = 'ManageVenueWatchlists',
    ManageVenueEvents = 'ManageVenueEvents',
    ManageVenueEnrolments = 'ManageVenueEnrolments',
    CorrectionalFacilityManage = 'CorrectionalFacilityManage',
    CorrectionalFacilityInmateView = 'CorrectionalFacilityInmateView',
    CorrectionalFacilityInmateEdit = 'CorrectionalFacilityInmateEdit',
    CreateGlobalBroadcastNotification = 'CreateGlobalBroadcastNotification',
    ViewVenueUserDetails = 'ViewVenueUserDetails',
    ViewVenueUserBasic = 'ViewVenueUserBasic',
    ViewSecurityFirmUserDetails = 'ViewSecurityFirmUserDetails',
    ViewSecurityFirmUserBasic = 'ViewSecurityFirmUserBasic',
    ExemptFromMissingDocuments = 'ExemptFromMissingDocuments',
    ManageVenueSchedule = 'ManageVenueSchedule',
    MaintainGroupWatchlists = 'MaintainGroupWatchlists',
    MaintainGroupEvents = 'MaintainGroupEvents',
    ManageSecurityFirmSchedule = 'ManageSecurityFirmSchedule',
    OCRImageProcessing = 'OCRImageProcessing',
    RadioChannels = 'RadioChannels',
    FunctionalAreas = 'FunctionalAreas',
    SubLocations = 'SubLocations',
    RadioChannelsEdit = 'RadioChannelsEdit',
    FunctionalAreasEdit = 'FunctionalAreasEdit',
    SubLocationsEdit = 'SubLocationsEdit',
    CommsLogsView = 'CommsLogsView',
    CommsLogsEdit = 'CommsLogsEdit',
    CommsLogsAdd = 'CommsLogsAdd',
    Notebook = 'Notebook',
    RoublerRoster = 'RoublerRoster',
    RoublerRosterMapping = 'RoublerRosterMapping',
    SignOnRegisterTypes = 'SignOnRegisterTypes',
    CashPlayTraxManager = 'CashPlayTraxManager',
    MaintainGroupChecklistTemplates = 'MaintainGroupChecklistTemplates',
    CashTraxIncidentView = 'CashTraxIncidentView',
    CashTraxIncidentEdit = 'CashTraxIncidentEdit',
    PlayTraxIncidentView = 'PlayTraxIncidentView',
    PlayTraxIncidentEdit = 'PlayTraxIncidentEdit',
    ManageRollerIntegration = 'ManageRollerIntegration',
    IncidentUniqueIDView = 'IncidentUniqueIDView',
    IncidentUniqueIDCreate = 'IncidentUniqueIDCreate',
    ViewIncidentsCurrentReportingPeriod = 'ViewIncidentsCurrentReportingPeriod',
    ViewIncidentsByReportingPeriod = 'ViewIncidentsByReportingPeriod',
    ViewIncidentHistory = 'ViewIncidentHistory',
    ViewIncidentList = 'ViewIncidentList',
    ViewSecurityFirmDocuments = 'ViewSecurityFirmDocuments',
    ViewIncidentUnder18Address = 'ViewIncidentUnder18Address',
    ViewUserDuplicateMobiles = 'ViewUserDuplicateMobiles',
    ViewUserRolesAdmin = 'ViewUserRolesAdmin',
    ViewUserProfilesAdmin = 'ViewUserProfilesAdmin',
    EditWebsiteProperties = 'EditWebsiteProperties',
    ViewVenuesAdmin = 'ViewVenuesAdmin',
    EditVenuesAdmin = 'EditVenuesAdmin',
    ViewSecurityFirmsAdmin = 'ViewSecurityFirmsAdmin',
    EditSecurityFirmsAdmin = 'EditSecurityFirmsAdmin',
    ViewReferenceData = 'ViewReferenceData',
    EditReferenceData = 'EditReferenceData',
}

export class VenueLocationSelection
{
    public venueLocationSelectionId: number;
    public parentId: number;
    public name: string;
    public rank: number;
    public obsolete: boolean;
    public selected: boolean;

    public constructor(init?: Partial<VenueLocationSelection>) { (Object as any).assign(this, init); }
}

export class IndustryCategory
{
    public industryCategoryId: number;
    public name: string;
    public rank: number;
    public activityMonitorTimeout: number;
    public activityNotificationTimeout: number;
    public obsolete: boolean;
    public incidentCategoryTypes: number[];
    public incidentCategoryTypeSelections: IncidentCategoryTypeSelection[];
    public incidentCategoryTypesDisplay: string;
    public venueLocationSelections: VenueLocationSelection[];
    public venueLocationSelectionsDisplay: string;

    public constructor(init?: Partial<IndustryCategory>) { (Object as any).assign(this, init); }
}

export class UserRoleType
{
    public userRoleTypeId: number;
    public venue: boolean;
    public security: boolean;
    public group: boolean;
    public venueId: number;
    public securityFirmId: number;
    public groupId: number;
    public name: string;
    public nameAlias: string;
    public accessLevel: number;
    public stateId: number;
    public stateName: string;
    public defaultRole: boolean;
    public canBeAssigned: boolean;
    public obsolete: boolean;
    public allowPermanent: boolean;
    public allowTemporary: boolean;
    public isGlobalRole: boolean;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public userRoleTypePermissions: ngtUserPermission[];
    public userRoleTypeIndustryCategories: IndustryCategory[];
    public nameOrAlias: string;

    public constructor(init?: Partial<UserRoleType>) { (Object as any).assign(this, init); }
}

export class NotificationTrigger
{
    public notificationTriggerId: number;
    public entityType: ngtEntityType;
    public entityId: number;
    public parentId: number;
    public notificationTriggerParentType: ngtNotificationTriggerParentType;
    public venueId: number;
    public securityFirmId: number;
    public groupId: number;
    public summary: string;
    public filter: string;
    public notify: boolean;
    public onlySignedOn: boolean;
    public includeAssociatedUsers: boolean;
    public attachReport: boolean;
    public escalate: boolean;
    public escalateAfterMinutes: number;
    public escalateOnlySignedOn: boolean;
    public escalateIncludeAssociatedUsers: boolean;
    public selectedUserRoleTypesDisplay: string;
    public selectedUserRoleTypes: number[];
    public userRoleTypes: UserRoleType[];
    public selectedFallbackUserRoleTypesDisplay: string;
    public selectedFallbackUserRoleTypes: number[];
    public fallbackUserRoleTypes: UserRoleType[];
    public selectedEscalateUserRoleTypesDisplay: string;
    public selectedEscalateUserRoleTypes: number[];
    public escalateUserRoleTypes: UserRoleType[];
    public selectedEscalateFallbackUserRoleTypesDisplay: string;
    public selectedEscalateFallbackUserRoleTypes: number[];
    public escalateFallbackUserRoleTypes: UserRoleType[];
    public isVenue: boolean;
    public isSecurity: boolean;
    public isGroup: boolean;

    public constructor(init?: Partial<NotificationTrigger>) { (Object as any).assign(this, init); }
}

export class IncidentCategoryType
{
    public incidentCategoryTypeId: number;
    public incidentTypeId: number;
    public venueId: number;
    public venueName: string;
    public securityFirmId: number;
    public securityFirmName: string;
    public code: string;
    public name: string;
    public rank: number;
    public usageCount: number;
    public obsolete: boolean;
    public hidden: boolean;
    public lastModifiedBy: number;
    public enteredBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public narrativeRequired: boolean;
    public legislated: boolean;
    public allowBulkApproval: boolean;
    public regulatedWalkthrough: boolean;
    public requiresPersonDescription: boolean;
    public requiresPersonDob: boolean;
    public autoApprove: boolean;
    public contactTracing: boolean;
    public apiOnlyCreation: boolean;
    public stateId: number;
    public stateName: string;
    public isInUse: boolean;
    public industryCategories: number[];
    public incidentTypes: number[];
    public incidentType: IncidentType;
    public display: string;
    public isOtherTextRequired: boolean;
    public isSeriousOtherTextRequired: boolean;
    public industryCategorySelections: IndustryCategorySelection[];
    public industryCategoriesDisplay: string;
    public incidentTypeSelections: IncidentTypeSelection[];
    public incidentTypesDisplay: string;
    public isGlobal: boolean;
    public isSpecificForVenueOrSecurityFirm: boolean;
    public notificationTrigger: NotificationTrigger;
    public hasIncidentCategoryTypeNotification: boolean;
    public editAllowed: boolean;
    public deleteAllowed: boolean;
    public unDeleteAllowed: boolean;
    public hideAllowed: boolean;
    public unHideAllowed: boolean;

    public constructor(init?: Partial<IncidentCategoryType>) { (Object as any).assign(this, init); }
}

export class IncidentCategory
{
    public incidentCategoryId: number;
    public incidentId: number;
    public incidentCategoryTypeId: number;
    public incidentCategoryType: IncidentCategoryType;

    public constructor(init?: Partial<IncidentCategory>) { (Object as any).assign(this, init); }
}

export class IncidentDocument
{
    public incidentDocumentId: number;
    public incidentId: number;
    public extension: string;
    public originalFilename: string;
    public canvasRescaled: boolean;
    public enteredBy: number;
    public obsolete: boolean;
    public mimeType: string;
    public filePath: string;
    public filePreviewPath: string;
    public filePreviewText: string;
    public fileDisplayPath: string;
    public isWider: boolean;
    public isImage: boolean;
    public hasChanged: boolean;
    public imageText: string;
    public storageFileName: string;
    public poiGuid: string;
    public appearanceGuid: string;
    public facialRecognitionCameraId: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;

    public constructor(init?: Partial<IncidentDocument>) { (Object as any).assign(this, init); }
}

export class State
{
    public stateId: number;
    public name: string;
    public timezone: string;
    public displayName: string;
    public rank: number;
    public timeZone: string;
    public obsolete: boolean;

    public constructor(init?: Partial<State>) { (Object as any).assign(this, init); }
}

export class Country
{
    public countryId: number;
    public name: string;
    public countryCode: string;
    public rank: number;
    public obsolete: boolean;
    public dateEntered: string;
    public dateEnteredUtc: string;

    public constructor(init?: Partial<Country>) { (Object as any).assign(this, init); }
}

export class IncidentPatronInvolvement
{
    public incidentPatronInvolvementId: number;
    public name: string;

    public constructor(init?: Partial<IncidentPatronInvolvement>) { (Object as any).assign(this, init); }
}

export class IncidentPatronAgeRange
{
    public incidentPatronAgeRangeId: number;
    public name: string;

    public constructor(init?: Partial<IncidentPatronAgeRange>) { (Object as any).assign(this, init); }
}

export class IncidentPatronHeight
{
    public incidentPatronHeightId: number;
    public name: string;

    public constructor(init?: Partial<IncidentPatronHeight>) { (Object as any).assign(this, init); }
}

export class IncidentPatronWeight
{
    public incidentPatronWeightId: number;
    public name: string;

    public constructor(init?: Partial<IncidentPatronWeight>) { (Object as any).assign(this, init); }
}

export class IncidentPatronBuild
{
    public incidentPatronBuildId: number;
    public name: string;

    public constructor(init?: Partial<IncidentPatronBuild>) { (Object as any).assign(this, init); }
}

export class IncidentPatronRacialAppearance
{
    public incidentPatronRacialAppearanceId: number;
    public name: string;
    public shortname: string;

    public constructor(init?: Partial<IncidentPatronRacialAppearance>) { (Object as any).assign(this, init); }
}

export class IncidentPatronHairColour
{
    public incidentPatronHairColourId: number;
    public name: string;

    public constructor(init?: Partial<IncidentPatronHairColour>) { (Object as any).assign(this, init); }
}

export class IncidentPatronFacialHair
{
    public incidentPatronFacialHairId: number;
    public name: string;

    public constructor(init?: Partial<IncidentPatronFacialHair>) { (Object as any).assign(this, init); }
}

export class IncidentPatronEyeColour
{
    public incidentPatronEyeColourId: number;
    public name: string;

    public constructor(init?: Partial<IncidentPatronEyeColour>) { (Object as any).assign(this, init); }
}

export class IncidentPatronComplexion
{
    public incidentPatronComplexionId: number;
    public name: string;

    public constructor(init?: Partial<IncidentPatronComplexion>) { (Object as any).assign(this, init); }
}

export class IncidentPatronClothing
{
    public incidentPatronClothingId: number;
    public name: string;

    public constructor(init?: Partial<IncidentPatronClothing>) { (Object as any).assign(this, init); }
}

export class IncidentPatronClothingColour
{
    public incidentPatronClothingColourId: number;
    public name: string;

    public constructor(init?: Partial<IncidentPatronClothingColour>) { (Object as any).assign(this, init); }
}

export class IncidentPatronPhoto
{
    public incidentPatronPhotoId: number;
    public incidentPatronId: number;
    public extension: string;
    public originalFileName: string;
    public canvasRescaled: boolean;
    public obsolete: boolean;
    public enteredBy: number;
    public mimeType: string;
    public filePath: string;
    public filePreviewPath: string;
    public filePreviewText: string;
    public fileDisplayPath: string;
    public isWider: boolean;
    public isImage: boolean;
    public hasChanged: boolean;
    public imageText: string;
    public storageFileName: string;
    public poiGuid: string;
    public appearanceGuid: string;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public lastModifiedBy: number;

    public constructor(init?: Partial<IncidentPatronPhoto>) { (Object as any).assign(this, init); }
}

export class IncidentPatron
{
    public incidentPatronId: number;
    public incidentId: number;
    public clothing: string;
    public hairColourOtherSpec: string;
    public notes: string;
    public name: string;
    public phone: string;
    public email: string;
    public dob: string;
    public dobDate: SimpleDate;
    public address: string;
    public suburb: string;
    public postcode: string;
    public stateId: number;
    public state: State;
    public stateDisplayName: string;
    public countryId: number;
    public country: Country;
    public countryName: string;
    public incidentPatronInvolvementId: number;
    public incidentPatronInvolvement: IncidentPatronInvolvement;
    public genderId: number;
    public gender: Gender;
    public incidentPatronAgeRangeId: number;
    public incidentPatronAgeRange: IncidentPatronAgeRange;
    public incidentPatronHeightId: number;
    public incidentPatronHeight: IncidentPatronHeight;
    public incidentPatronWeightId: number;
    public incidentPatronWeight: IncidentPatronWeight;
    public incidentPatronBuildId: number;
    public incidentPatronBuild: IncidentPatronBuild;
    public incidentPatronRacialAppearanceId: number;
    public incidentPatronRacialAppearance: IncidentPatronRacialAppearance;
    public incidentPatronHairColourId: number;
    public incidentPatronHairColour: IncidentPatronHairColour;
    public incidentPatronFacialHairId: number;
    public incidentPatronFacialHair: IncidentPatronFacialHair;
    public incidentPatronEyeColourId: number;
    public incidentPatronEyeColour: IncidentPatronEyeColour;
    public incidentPatronComplexionId: number;
    public incidentPatronComplexion: IncidentPatronComplexion;
    public incidentPatronClothingTopId: number;
    public incidentPatronClothingTop: IncidentPatronClothing;
    public incidentPatronClothingTopColourId: number;
    public incidentPatronClothingTopColour: IncidentPatronClothingColour;
    public incidentPatronClothingBottomId: number;
    public incidentPatronClothingBottom: IncidentPatronClothing;
    public incidentPatronClothingBottomColourId: number;
    public incidentPatronClothingBottomColour: IncidentPatronClothingColour;
    public enteredBy: number;
    public obsolete: boolean;
    public poiGuid: string;
    public facialRecognitionCameraId: number;
    public isUnderAgeLocked: boolean;
    public sourceFacialRecognitionEvent: boolean;
    public incidentPatronPhotos: IncidentPatronPhoto[];
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public lastModifiedBy: number;
    public hasChanged: boolean;

    public constructor(init?: Partial<IncidentPatron>) { (Object as any).assign(this, init); }
}

export class IncidentLocation
{
    public incidentLocationId: number;
    public incidentId: number;
    public venueLocationId: number;
    public venueLocation: VenueLocation;

    public constructor(init?: Partial<IncidentLocation>) { (Object as any).assign(this, init); }
}

export class IncidentFlagType
{
    public incidentFlagTypeId: number;
    public name: string;
    public additionalField: boolean;
    public fieldLabel: string;
    public obsolete: boolean;
    public rank: number;
    public parentIncidentFlagTypeId: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public industryCategorySelections: IndustryCategorySelection[];
    public industryCategoriesDisplay: string;
    public notificationTrigger: NotificationTrigger;
    public notificationUserRoleTypesDisplay: string;

    public constructor(init?: Partial<IncidentFlagType>) { (Object as any).assign(this, init); }
}

export class IncidentFlag
{
    public incidentFlagId: number;
    public incidentId: number;
    // @References(typeof(IncidentFlagType))
    public incidentFlagTypeId: number;

    public fieldValue: string;
    public incidentFlagType: IncidentFlagType;

    public constructor(init?: Partial<IncidentFlag>) { (Object as any).assign(this, init); }
}

export class LocalDate
{
    public isEmpty: boolean;
    public timezoneId: number;
    public gmtOffset: number;
    public year: number;
    public month: number;
    public day: number;
    public hour: number;
    public minute: number;
    public utcDate: string;
    public displayLocal: string;
    public displayLocalDate: string;

    public constructor(init?: Partial<LocalDate>) { (Object as any).assign(this, init); }
}

export class UserRoleStatus
{
    public userRoleStatusId: number;
    public name: string;
    public rank: number;
    public obsolete: boolean;
    public dateEntered: string;
    public dateEnteredUtc: string;

    public constructor(init?: Partial<UserRoleStatus>) { (Object as any).assign(this, init); }
}

export class UserRole
{
    public userRoleId: number;
    public userId: number;
    public userRoleTypeId: number;
    public venueID: number;
    public venue: string;
    public securityFirmID: number;
    public securityFirm: string;
    public userRoleStatusId: number;
    public obsolete: boolean;
    public enteredBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public lastModifiedBy: number;
    public dateModified: string;
    public dateModifiedUtc: string;
    public expiryDate: string;
    public expiryDateUtc: string;
    public expiry: LocalDate;
    public temporary: boolean;
    public suspendedByAdmin: boolean;
    public bypassSLEDCheck: boolean;
    public adminNotes: string;
    public user: User;
    public userRoleStatus: UserRoleStatus;
    public userRoleType: UserRoleType;
    public editUserAllowed: boolean;
    public editAllowed: boolean;
    public deleteAllowed: boolean;
    public viewUserAllowed: boolean;
    public userObsolete: boolean;
    public userRoleObsolete: boolean;
    public isLoggedIn: boolean;
    public isVenue: boolean;
    public isSecurity: boolean;

    public constructor(init?: Partial<UserRole>) { (Object as any).assign(this, init); }
}

export class IncidentUser
{
    public incidentUserId: number;
    public incidentId: number;
    public userId: number;
    public userRoleId: number;
    public display: string;
    public user: User;
    public userRole: UserRole;

    public constructor(init?: Partial<IncidentUser>) { (Object as any).assign(this, init); }
}

export class IncidentNotifiedUserRole
{
    public incidentNotifiedUserRoleId: number;
    public incidentId: number;
    public userId: number;
    public userRoleId: number;
    public obsolete: boolean;
    public display: string;

    public constructor(init?: Partial<IncidentNotifiedUserRole>) { (Object as any).assign(this, init); }
}

export class IncidentDate
{
    public isEmpty: boolean;
    public timezoneId: number;
    public gmtOffset: number;
    public year: number;
    public month: number;
    public day: number;
    public hour: number;
    public minute: number;
    public utcDate: string;

    public constructor(init?: Partial<IncidentDate>) { (Object as any).assign(this, init); }
}

export class IncidentImage
{
    public incidentDocumentId: number;
    public incidentId: number;
    public incidentPatronPhotoId: number;
    public incidentPatronId: number;
    public extension: string;
    public canvasRescaled: boolean;
    public obsolete: boolean;
    public mimeType: string;
    public filePath: string;
    public filePreviewPath: string;
    public filePreviewText: string;
    public fileDisplayPath: string;
    public isWider: boolean;
    public isImage: boolean;
    public imageText: string;
    public poiGuid: string;
    public appearanceGuid: string;
    public originalFilename: string;
    public facialRecognitionCameraId: number;

    public constructor(init?: Partial<IncidentImage>) { (Object as any).assign(this, init); }
}

export class RelatedIncident
{
    public incidentId: number;
    public incidentSerialNumber: string;
    public details: string;

    public constructor(init?: Partial<RelatedIncident>) { (Object as any).assign(this, init); }
}

export class CameraData
{
    public camera_id: string;
    public stream_id: string;
    public camera_description: string;

    public constructor(init?: Partial<CameraData>) { (Object as any).assign(this, init); }
}

export class BoundingBox
{
    public x1: number;
    public y1: number;
    public x2: number;
    public y2: number;

    public constructor(init?: Partial<BoundingBox>) { (Object as any).assign(this, init); }
}

export class FrameData
{
    public utc_time_recorded: string;
    public utc_time_zone: number;
    public frame_id: string;
    public bounding_box: BoundingBox;

    public constructor(init?: Partial<FrameData>) { (Object as any).assign(this, init); }
}

export class CropData
{
    public face_crop_img: string;
    public face_score: number;
    public pitch: number;
    public yaw: number;
    public masked_score: string;

    public constructor(init?: Partial<CropData>) { (Object as any).assign(this, init); }
}

export class WatchLists
{
    public watchlist_id: string;
    public watchlist_type: string;
    public match_outcome: string;
    public display_name: string;
    public display_color: string;

    public constructor(init?: Partial<WatchLists>) { (Object as any).assign(this, init); }
}

export class MatchData
{
    public utc_time_matched: string;
    public poi_id: string;
    public poi_confidence: string;
    public poi_display_img: string;
    public poi_display_name: string;
    public watchlists: WatchLists[];

    public constructor(init?: Partial<MatchData>) { (Object as any).assign(this, init); }
}

export class FaceFeaturesData
{
    public gender_outcome: string;
    public age_group_outcome: string;
    public mask_outcome: string;

    public constructor(init?: Partial<FaceFeaturesData>) { (Object as any).assign(this, init); }
}

export class AppearanceData
{
    public appearance_id: string;
    public utc_time_started: string;
    public first_frame_id: number;
    public timeStarted: string;

    public constructor(init?: Partial<AppearanceData>) { (Object as any).assign(this, init); }
}

export class RecievedNotification
{
    public event_id: string;
    public event_type: string;
    public camera_data: CameraData;
    public frame_data: FrameData;
    public crop_data: CropData;
    public match_data: MatchData;
    public face_features_data: FaceFeaturesData;
    public appearance_data: AppearanceData;
    public face_crop_img_name: string;
    public trigger: number;
    public ausComplyUtcTimeStamp: string;

    public constructor(init?: Partial<RecievedNotification>) { (Object as any).assign(this, init); }
}

export class FacialRecognitionCameraWatchlist
{
    public facialRecognitionCameraWatchlistId: number;
    public facialRecognitionCameraId: number;
    public facialRecognitionCamera: FacialRecognitionCamera;
    public facialRecognitionWatchlistId: number;
    public facialRecognitionWatchlist: FacialRecognitionWatchlist;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public enteredByUser: User;
    public lastModifiedByUser: User;

    public constructor(init?: Partial<FacialRecognitionCameraWatchlist>) { (Object as any).assign(this, init); }
}

export enum ngtFacialRecognitionEventType
{
    Watchlist = 'Watchlist',
    Age = 'Age',
}

export class FacialRecognitionEventCamera
{
    public facialRecognitionEventCameraId: number;
    public facialRecognitionEventId: number;
    public facialRecognitionCameraId: number;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public facialRecognitionCamera: FacialRecognitionCamera;

    public constructor(init?: Partial<FacialRecognitionEventCamera>) { (Object as any).assign(this, init); }
}

export class FacialRecognitionEventIncidentCategoryType
{
    public facialRecognitionEventIncidentCategoryTypeId: number;
    public facialRecognitionEventId: number;
    public incidentCategoryTypeId: number;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public incidentCategoryType: IncidentCategoryType;

    public constructor(init?: Partial<FacialRecognitionEventIncidentCategoryType>) { (Object as any).assign(this, init); }
}

export class FacialRecognitionEventIncidentFlagType
{
    public facialRecognitionEventIncidentFlagTypeId: number;
    public facialRecognitionEventId: number;
    public incidentFlagTypeId: number;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public incidentFlagType: IncidentFlagType;

    public constructor(init?: Partial<FacialRecognitionEventIncidentFlagType>) { (Object as any).assign(this, init); }
}

export class FacialRecognitionEvent
{
    public facialRecognitionEventId: number;
    public facialRecognitionWatchlistId: number;
    public venueId: number;
    public groupId: number;
    public notificationRefreshRate: number;
    public anyCamera: boolean;
    public attachedCameraIsObsolete: boolean;
    public createIncident: boolean;
    public processRank: number;
    public stopProcessing: boolean;
    public incidentTypeId: number;
    public facialRecognitionEventType: ngtFacialRecognitionEventType;
    public ageFrom: number;
    public ageTo: number;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public incidentType: IncidentType;
    public facialRecognitionWatchlist: FacialRecognitionWatchlist;
    public facialRecognitionEventCameras: FacialRecognitionEventCamera[];
    public facialRecognitionEventIncidentCategoryTypes: FacialRecognitionEventIncidentCategoryType[];
    public facialRecognitionEventIncidentFlagTypes: FacialRecognitionEventIncidentFlagType[];
    public notificationTrigger: NotificationTrigger;
    public watchlistDisplay: string;
    public incidentTypeDisplay: string;
    public cameraDisplay: string;
    public incidentCategoryTypesDisplay: string;
    public incidentFlagTypesDisplay: string;
    public eventNotificationUserRoleTypesDisplay: string;

    public constructor(init?: Partial<FacialRecognitionEvent>) { (Object as any).assign(this, init); }
}

export enum ngtCameraStatus
{
    Unknown = 'Unknown',
    Running = 'Running',
    Stopped = 'Stopped',
}

export class FacialRecognitionCamera
{
    public facialRecognitionCameraId: number;
    public venueId: number;
    public cameraGuid: string;
    public displayName: string;
    public venueLocationId: number;
    public venueLocation: VenueLocation;
    public syncStatus: ngtSyncStatus;
    public syncError: string;
    public obsolete: boolean;
    public facialRecognitionServerId: number;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public facialRecognitionCameraWatchlists: FacialRecognitionCameraWatchlist[];
    public facialRecognitionEvents: FacialRecognitionEvent[];
    public watchlists: string;
    public eventWatchlists: string;
    public watchlistGuids: string[];
    public facialRecognitionServer: FacialRecognitionServer;
    public cameraStatus: ngtCameraStatus;
    public cameraStatusDescription: string;
    public cameraConfiguration: string;

    public constructor(init?: Partial<FacialRecognitionCamera>) { (Object as any).assign(this, init); }
}

export class FacialRecognitionNotificationWatchlist
{
    public facialRecognitionNotificationWatchlistId: number;
    public facialRecognitionNotificationId: number;
    public facialRecognitionWatchlistId: number;
    public obsolete: boolean;
    public facialRecognitionWatchlist: FacialRecognitionWatchlist;

    public constructor(init?: Partial<FacialRecognitionNotificationWatchlist>) { (Object as any).assign(this, init); }
}

export class IncidentIdAndSerialNumber
{
    public incidentId: number;
    public incidentSerialNumber: string;

    public constructor(init?: Partial<IncidentIdAndSerialNumber>) { (Object as any).assign(this, init); }
}

export class FacialRecognitionNotification
{
    public facialRecognitionNotificationId: number;
    public venueId: number;
    public eventType: string;
    public isMatch: boolean;
    public matchOutcomes: string;
    public ageFrom: number;
    public ageTo: number;
    public camera: string;
    public watchLists: string;
    public facialRecognitionCameraId: number;
    public obsolete: boolean;
    public jsonString: string;
    public recievedNotification: RecievedNotification;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredDisplay: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public enteredByUser: User;
    public lastModifiedByUser: User;
    public userRoleTypes: number[];
    public loggedInOnly: boolean;
    public hasIncidents: boolean;
    public facialRecognitionCamera: FacialRecognitionCamera;
    public facialRecognitionNotificationWatchlists: FacialRecognitionNotificationWatchlist[];
    public incidents: IncidentIdAndSerialNumber[];

    public constructor(init?: Partial<FacialRecognitionNotification>) { (Object as any).assign(this, init); }
}

export enum ChecklistStatuses
{
    InProgress = 'InProgress',
    Approved = 'Approved',
    Closed = 'Closed',
    Submitted = 'Submitted',
    Rejected = 'Rejected',
}

export class ChecklistComment
{
    public checklistCommentId: number;
    public checklistId: number;
    public comment: string;
    public obsolete: boolean;
    public dateEntered: string;
    public dateModified: string;
    public enteredByUserId: number;
    public lastModifiedByUserId: number;

    public constructor(init?: Partial<ChecklistComment>) { (Object as any).assign(this, init); }
}

export enum ChecklistItemType
{
    Group = 'Group',
    TextBox = 'TextBox',
    Question = 'Question',
    MultipleChoice = 'MultipleChoice',
    Checkbox = 'Checkbox',
    RadioButton = 'RadioButton',
    DateTime = 'DateTime',
    GPS = 'GPS',
    Information = 'Information',
    StaffSingleSelection = 'StaffSingleSelection',
    StaffMultipleSelection = 'StaffMultipleSelection',
    Selection = 'Selection',
}

export class ChecklistTemplateItemOption
{
    public checklistTemplateItemOptionId: number;
    public checklistTemplateItemId: number;
    public name: string;
    public value: string;
    public rank: number;
    public obsolete: boolean;
    public dateEntered: string;
    public dateModified: string;
    public temporaryId: number;
    public enteredByUserId: number;
    public lastModifiedByUserId: number;

    public constructor(init?: Partial<ChecklistTemplateItemOption>) { (Object as any).assign(this, init); }
}

export enum ngtOperator
{
    Equal = 'Equal',
    EqualAny = 'EqualAny',
    NotEquals = 'NotEquals',
    NotEqualAny = 'NotEqualAny',
    GreaterThan = 'GreaterThan',
    GreatOrEqualThan = 'GreatOrEqualThan',
    LessThan = 'LessThan',
    LessOrEqualThan = 'LessOrEqualThan',
}

export class ChecklistTemplateItemFilter
{
    public checklistTemplateItemFilterId: number;
    public checklistTemplateItemId: number;
    public parentChecklistTemplateItemId: number;
    public operator: ngtOperator;
    public value: string;
    public obsolete: boolean;
    public dateEntered: string;
    public dateModified: string;
    public enteredByUserId: number;
    public lastModifiedByUserId: number;

    public constructor(init?: Partial<ChecklistTemplateItemFilter>) { (Object as any).assign(this, init); }
}

export class ChecklistTemplateItemNotificationUserRoleType
{
    public checklistTemplateItemNotificationUserRoleTypeId: number;
    public checklistTemplateItemId: number;
    public userRoleTypeId: number;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public checklistTemplateItem: ChecklistTemplateItem;
    public userRoleType: UserRoleType;
    public enteredByUser: User;
    public lastModifiedByUser: User;

    public constructor(init?: Partial<ChecklistTemplateItemNotificationUserRoleType>) { (Object as any).assign(this, init); }
}

export class UserRoleTypeSelection
{
    public userRoleTypeId: number;
    public venue: boolean;
    public security: boolean;
    public name: string;
    public isVenueSpecific: boolean;
    public isSecuritySpecific: boolean;
    public parentName: string;
    public accessLevel: number;
    public selected: boolean;

    public constructor(init?: Partial<UserRoleTypeSelection>) { (Object as any).assign(this, init); }
}

export class ChecklistTemplateItem
{
    public checklistTemplateItemId: number;
    public checklistTemplateId: number;
    public name: string;
    public description: string;
    public checklistItemType: ChecklistItemType;
    public mandatory: boolean;
    public enableInstanceAttachments: boolean;
    public rank: number;
    public onCreate: boolean;
    public onEdit: boolean;
    public obsolete: boolean;
    public parentChecklistTemplateItemId: number;
    public level: number;
    public temporaryId: number;
    public temporaryParentId: number;
    public groupedItems: ChecklistTemplateItem[];
    public checklistTemplateItemOptions: ChecklistTemplateItemOption[];
    public checklistTemplateItemFilters: ChecklistTemplateItemFilter[];
    public attachment: Attachment;
    public attachmentId: number;
    public file: File;
    public requiredAttachmentCount: number;
    public notifyWhen: number;
    public notifyWhenValue: string;
    public notificationTrigger: NotificationTrigger;
    public onlySignedOn: boolean;
    public includeAssociatedUsers: boolean;
    public checklistTemplateItemNotificationUserRoleTypes: ChecklistTemplateItemNotificationUserRoleType[];
    public userRoleTypeSelections: UserRoleTypeSelection[];
    public userRoleTypesDisplay: string;
    // @Ignore()
    public hasChanged: boolean;

    // @Ignore()
    public primaryRank: number;

    // @Ignore()
    public secondaryRank: number;

    public constructor(init?: Partial<ChecklistTemplateItem>) { (Object as any).assign(this, init); }
}

export class ChecklistItem
{
    public checklistItemId: number;
    public checklistId: number;
    public checklistTemplateItem: ChecklistTemplateItem;
    public checklistTemplateItemId: number;
    public value: string;
    public obsolete: boolean;
    public dateEntered: string;
    public dateModified: string;
    public enteredByUserId: number;
    public lastModifiedByUserId: number;
    public attachments: Attachment[];
    public files: File[];
    public readonly: boolean;
    // @Ignore()
    public display: string;

    // @Ignore()
    public show: boolean;

    // @Ignore()
    public selectedValues: string[];

    public constructor(init?: Partial<ChecklistItem>) { (Object as any).assign(this, init); }
}

export class CheckListDisplayItem
{
    public offset: number;
    public item: ChecklistItem;
    public template: ChecklistTemplateItem;
    public isTemplateMissing: boolean;
    public isOnIncident: boolean;
    public show: boolean;
    public isRequired: boolean;

    public constructor(init?: Partial<CheckListDisplayItem>) { (Object as any).assign(this, init); }
}

export class Checklist
{
    public checklistId: number;
    public checklistTemplateId: number;
    public name: string;
    public checklistStatus: ChecklistStatuses;
    public obsolete: boolean;
    public dateEntered: string;
    public dateModified: string;
    public enteredByUserId: number;
    public enteredByUserDisplayName: string;
    public lastModifiedByUserId: number;
    public venueId: number;
    public securityFirmId: number;
    public venueEventId: number;
    public scheduleId: number;
    public checklistComments: ChecklistComment[];
    public checklistItems: ChecklistItem[];
    public checkListDisplayItems: CheckListDisplayItem[];
    public isCopy: boolean;
    public attachmentsChanged: boolean;

    public constructor(init?: Partial<Checklist>) { (Object as any).assign(this, init); }
}

export class ChecklistUserSelection
{
    public userId: number;
    public display: string;
    public isLoggedIn: boolean;
    public isAssociated: boolean;
    public obsolete: boolean;

    public constructor(init?: Partial<ChecklistUserSelection>) { (Object as any).assign(this, init); }
}

export class IncidentRadioChannel
{
    public incidentRadioChannelId: number;
    public incidentId: number;
    public radioChannelId: number;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;

    public constructor(init?: Partial<IncidentRadioChannel>) { (Object as any).assign(this, init); }
}

export class IncidentFunctionalArea
{
    public incidentFunctionalAreaId: number;
    public incidentId: number;
    public functionalAreaId: number;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;

    public constructor(init?: Partial<IncidentFunctionalArea>) { (Object as any).assign(this, init); }
}

export class IncidentSubLocation
{
    public incidentSubLocationId: number;
    public incidentId: number;
    public subLocationId: number;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;

    public constructor(init?: Partial<IncidentSubLocation>) { (Object as any).assign(this, init); }
}

export class IncidentCategoryTypeDocument
{
    public incidentCategoryTypeDocumentId: number;
    public incidentCategoryTypeId: number;
    public name: string;
    public description: string;
    public obsolete: boolean;
    public templateName: string;

    public constructor(init?: Partial<IncidentCategoryTypeDocument>) { (Object as any).assign(this, init); }
}

export class Incident
{
    public incidentId: number;
    public incidentTypeId: number;
    public originalIncidentTypeId: number;
    public editPage: string;
    public incidentType: string;
    public sequentialIncidentId: number;
    public sequentialSubIncidentId: number;
    public incidentSerialNumber: string;
    public uniqueID: string;
    public uniqueIDNumber: number;
    public uniqueIDPrefix: string;
    public venueId: number;
    public venue: string;
    public venueEventId: number;
    public venueEvent: string;
    public securityFirmId: number;
    public securityFirm: string;
    public reportingPeriodId: number;
    public isCurrentReportingPeriodConfirm: boolean;
    public isCurrentReportingPeriod: boolean;
    public modificationCount: number;
    public other: string;
    public seriousOther: string;
    public register: boolean;
    public incidentDateTime: string;
    public incidentDateTimeString: string;
    public incidentDateTimeShortString: string;
    public incidentDateString: string;
    public incidentDateStringTz: string;
    public incidentDateTimeTz: number;
    public dateOfIncident: string;
    public timeOfIncident: string;
    public timezoneOfIncident: string;
    public summary: string;
    public regulatedWalkthroughSummary: string;
    public snapshotSummary: string;
    public numberInvolved: number;
    public involvedDescription: string;
    public originalIncidentId: number;
    public parentIncidentId: number;
    public parentIncidentName: string;
    public completedDate: string;
    public completedDateUtc: string;
    public completedDateString: string;
    public completed: boolean;
    public approvedComment: string;
    public approved: boolean;
    public statusDisplay: string;
    public storageFolder: string;
    public incidentStatusDisplay: string;
    public approvedBy: number;
    public recommendObsolete: boolean;
    public enteredBy: number;
    public enteredByUser: string;
    public commencementDateUtc: string;
    public commencementDateString: string;
    public createdDateString: string;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateEnteredString: string;
    public dateEnteredAndTime: string;
    public dateEnteredAndTimezone: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public dateModifiedString: string;
    public lastModifiedBy: number;
    public lastModifiedByUser: string;
    public lastModifiedByMaxAccessLevel: number;
    public latitude: number;
    public longitude: number;
    public geolocationDisplay: string;
    public obsolete: boolean;
    public locationFound: boolean;
    public incidentCategory: IncidentCategory[];
    public incidentCategories: number[];
    public categories: string;
    public incidentDocuments: IncidentDocument[];
    public incidentPatrons: IncidentPatron[];
    public incidentLocations: number[];
    public incidentLocation: IncidentLocation[];
    public locations: string;
    public incidentPersonnel: number[];
    public incidentFlags: IncidentFlag[];
    public incidentUsers: IncidentUser[];
    public incidentNotifiedUserRoles: IncidentNotifiedUserRole[];
    public hasAttachment: boolean;
    public hasRequiredActions: boolean;
    public incidentDate: IncidentDate;
    public incidentImage: IncidentImage;
    public hasAttachmentsYesNo: string;
    public editAllowed: boolean;
    public viewAllowed: boolean;
    public allowBulkApproval: boolean;
    public canApprove: boolean;
    public wasCompleted: boolean;
    public selected: boolean;
    public disableAutoApproval: boolean;
    public disableAutoVersioning: boolean;
    public disableValidations: boolean;
    public sourceFacialRecognitionEvent: boolean;
    public forceAutoApproval: boolean;
    public hasLiveFeed: boolean;
    public facialRecognitionPOIIncidents: number[];
    public poiGuids: string[];
    public details: string;
    public relatedIncidents: RelatedIncident[];
    public linkedIncidents: Incident[];
    public facialRecognitionNotification: FacialRecognitionNotification;
    public checklists: Checklist[];
    public checklistUserSelections: ChecklistUserSelection[];
    public incidentRadioChannels: IncidentRadioChannel[];
    public selectedRadioChannelsDisplay: string;
    public selectedRadioChannels: number[];
    public incidentFunctionalAreas: IncidentFunctionalArea[];
    public selectedFunctionalAreasDisplay: string;
    public selectedFunctionalAreas: number[];
    public incidentSubLocations: IncidentSubLocation[];
    public selectedSubLocationsDisplay: string;
    public selectedSubLocations: number[];
    public selectedSubLocationNames: string[];
    public incidentCategoryTypeDocuments: IncidentCategoryTypeDocument[];
    public accessViaVenueId: number;
    public accessViaVenue: string;
    public externalEventData: string;

    public constructor(init?: Partial<Incident>) { (Object as any).assign(this, init); }
}

export class CorrectionalFacilityInMateCell
{
    public correctionalFacilityInMate: CorrectionalFacilityInMate;
    public correctionalFacilityInMateVenueLocation: CorrectionalFacilityInMateVenueLocation;
    public notes: string;
    public noteIsImportant: boolean;
    public correctionalFacilityInMateNotes: CorrectionalFacilityInMateNote[];
    public nextInspectionIncident: Incident;
    public inspections: Incident[];
    public venueLocation: VenueLocation;

    public constructor(init?: Partial<CorrectionalFacilityInMateCell>) { (Object as any).assign(this, init); }
}

export class CorrectionalFacilityRiskStatus
{
    public correctionalFacilityRiskStatusId: number;
    public venueId: number;
    public name: string;
    public inspectionFrequency: number;
    public rank: number;
    public obsolete: boolean;
    public enteredBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public lastModifiedBy: number;
    public dateModified: string;
    public dateModifiedUtc: string;

    public constructor(init?: Partial<CorrectionalFacilityRiskStatus>) { (Object as any).assign(this, init); }
}

export class ExternalEventMessageAudit
{
    public externalEventMessageAuditId: number;
    public entityType: ngtEntityType;
    public groupId: number;
    public venueId: number;
    public securityFirmId: number;
    public externalEventId: number;
    public createdEntityType: ngtEntityType;
    public createdEntityId: number;
    public message: string;
    public rawMessage: string;
    public rawMessageTruncated: boolean;
    public obsolete: boolean;
    public enteredBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public display: string;
    public dateEnteredDisplay: string;
    public createdEntityTypeDisplay: string;
    public createdEntityDisplay: string;

    public constructor(init?: Partial<ExternalEventMessageAudit>) { (Object as any).assign(this, init); }
}

export class ChecklistTemplate implements IObsolete, ITimestamped
{
    public checklistTemplateId: number;
    public name: string;
    public description: string;
    public obsolete: boolean;
    public groupId: number;
    // @Ignore()
    public isObsolete: boolean;

    public enabledVenues: boolean;
    public enabledSecurityFirms: boolean;
    public dateEntered: string;
    public dateModified: string;
    public enteredByUserId: number;
    public lastModifiedByUserId: number;
    public parentChecklistTemplateId: number;
    public instanceCount: number;
    public checklistTemplateItems: ChecklistTemplateItem[];
    public checklistTemplateCategories: number[];
    public industryCategories: number[];
    public states: number[];
    public venues: number[];
    public securityFirms: number[];
    public isGlobal: boolean;
    public notificationTriggers: NotificationTrigger[];
    public defaultNotificationTrigger: NotificationTrigger;
    // @Ignore()
    public checklistUserSelections: ChecklistUserSelection[];

    // @Ignore()
    public notificationTriggerOnSubmit: NotificationTrigger;

    public constructor(init?: Partial<ChecklistTemplate>) { (Object as any).assign(this, init); }
}

export class ExternalEventIncidentCategoryType implements IChangeTracking
{
    public externalEventIncidentCategoryTypeId: number;
    public externalEventId: number;
    public incidentCategoryTypeId: number;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public incidentCategoryType: IncidentCategoryType;

    public constructor(init?: Partial<ExternalEventIncidentCategoryType>) { (Object as any).assign(this, init); }
}

export class ExternalEventVenueLocation implements IChangeTracking
{
    public externalEventVenueLocationId: number;
    public externalEventId: number;
    public venueLocationId: number;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public venueLocation: VenueLocation;

    public constructor(init?: Partial<ExternalEventVenueLocation>) { (Object as any).assign(this, init); }
}

export class ExternalEventIncidentFlagType implements IChangeTracking
{
    public externalEventIncidentFlagTypeId: number;
    public externalEventId: number;
    public incidentFlagTypeId: number;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public incidentFlagType: IncidentFlagType;

    public constructor(init?: Partial<ExternalEventIncidentFlagType>) { (Object as any).assign(this, init); }
}

export class ExternalEventFilter implements IChangeTracking
{
    public externalEventFilterId: number;
    public externalEventId: number;
    public fieldName: string;
    public operator: ngtOperator;
    public values: string;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;

    public constructor(init?: Partial<ExternalEventFilter>) { (Object as any).assign(this, init); }
}

export class IncidentTypeCategoryTypes
{
    public incidentTypeId: number;
    public incidentCategoryTypes: IncidentCategoryType[];

    public constructor(init?: Partial<IncidentTypeCategoryTypes>) { (Object as any).assign(this, init); }
}

export class ChecklistTemplateSummary
{
    public checklistTemplateId: number;
    public name: string;
    public description: string;
    public itemCount: number;
    public instanceCount: number;
    public instanceCountForLast30Days: number[];
    public isGlobal: boolean;
    public isGroup: boolean;
    public obsolete: boolean;
    public groupId: number;

    public constructor(init?: Partial<ChecklistTemplateSummary>) { (Object as any).assign(this, init); }
}

export class ExternalEvent
{
    public externalEventId: number;
    public messageTemplate: string;
    public entityType: ngtEntityType;
    public groupId: number;
    public venueId: number;
    public securityFirmId: number;
    public createEntityType: ngtEntityType;
    public incidentTypeId: number;
    public checklistTemplateId: number;
    public notificationRefreshRate: number;
    public rank: number;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public incidentType: IncidentType;
    public checklistTemplate: ChecklistTemplate;
    public externalEventIncidentCategoryTypes: ExternalEventIncidentCategoryType[];
    public externalEventVenueLocations: ExternalEventVenueLocation[];
    public externalEventIncidentFlagTypes: ExternalEventIncidentFlagType[];
    public externalEventFilters: ExternalEventFilter[];
    public display: string;
    public entityTypes: ngtEntityType[];
    public incidentTypes: IncidentType[];
    public incidentTypeCategoryTypes: IncidentTypeCategoryTypes[];
    public venueLocations: VenueLocation[];
    public incidentFlagTypes: IncidentFlagType[];
    public userRoleTypes: UserRoleType[];
    public checklistTemplates: ChecklistTemplateSummary[];
    public notificationTrigger: NotificationTrigger;

    public constructor(init?: Partial<ExternalEvent>) { (Object as any).assign(this, init); }
}

export class ExternalEventMessage
{
    public externalEventMessageAuditId: number;
    public entityType: ngtEntityType;
    public values: { [index: string]: string; };
    public rawMessage: string;
    public message: string;

    public constructor(init?: Partial<ExternalEventMessage>) { (Object as any).assign(this, init); }
}

export class MultiSelectOption
{
    public id: number;
    public parentId: number;
    public code: string;
    public isCommon: boolean;
    public name: string;
    public selected: boolean;
    public info: string;
    public locked: boolean;
    public obsolete: boolean;

    public constructor(init?: Partial<MultiSelectOption>) { (Object as any).assign(this, init); }
}

export class VenueSelection
{
    public venueId: number;
    public name: string;
    public industryCategoryID: number;
    public selected: boolean;
    public isActive: boolean;
    public error: string;

    public constructor(init?: Partial<VenueSelection>) { (Object as any).assign(this, init); }
}

export class NotificationTriggerUserSummary
{
    public userId: number;
    public userDisplay: string;
    public roles: string;
    public groups: string;
    public isOnline: boolean;

    public constructor(init?: Partial<NotificationTriggerUserSummary>) { (Object as any).assign(this, init); }
}

export class NotificationTriggerUsersSummary
{
    public showOnlineOnly: boolean;
    public showFallbackUserRole: boolean;
    public userRoles: NotificationTriggerUserSummary[];
    public fallbackUserRoles: NotificationTriggerUserSummary[];
    public escalate: boolean;
    public escalateShowOnlineOnly: boolean;
    public escalateShowFallbackUserRole: boolean;
    public escalateUserRoles: NotificationTriggerUserSummary[];
    public escalateFallbackUserRoles: NotificationTriggerUserSummary[];

    public constructor(init?: Partial<NotificationTriggerUsersSummary>) { (Object as any).assign(this, init); }
}

export class SignOnRegisterType
{
    public signOnRegisterTypeId: number;
    public groupId: number;
    public venueId: number;
    public name: string;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public enteredByUser: User;
    public lastModifiedByUser: User;
    public signOnRegisterTypeIndustryCategories: SignOnRegisterTypeIndustryCategory[];
    public selectedSignOnRegisterTypeIndustryCategoriesDisplay: string;
    public selectedSignOnRegisterTypeIndustryCategories: number[];
    public industryCategories: IndustryCategory[];

    public constructor(init?: Partial<SignOnRegisterType>) { (Object as any).assign(this, init); }
}

export class SignOnRegisterTypeIndustryCategory
{
    public signOnRegisterTypeIndustryCategoryId: number;
    public signOnRegisterTypeId: number;
    public industryCategoryId: number;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public signOnRegisterTypeIndustryCategorys: SignOnRegisterTypeIndustryCategory[];
    public signOnRegisterType: SignOnRegisterType;
    public industryCategory: IndustryCategory;
    public enteredByUser: User;
    public lastModifiedByUser: User;

    public constructor(init?: Partial<SignOnRegisterTypeIndustryCategory>) { (Object as any).assign(this, init); }
}

export class FunctionalArea
{
    public functionalAreaId: number;
    public groupId: number;
    public venueId: number;
    public name: string;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public radioChannelFunctionalAreas: RadioChannelFunctionalArea[];
    public enteredByUser: User;
    public lastModifiedByUser: User;

    public constructor(init?: Partial<FunctionalArea>) { (Object as any).assign(this, init); }
}

export class RadioChannel
{
    public radioChannelId: number;
    public groupId: number;
    public venueId: number;
    public name: string;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public enteredByUser: User;
    public lastModifiedByUser: User;
    public radioChannelFunctionalAreas: RadioChannelFunctionalArea[];
    public selectedFunctionalAreasDisplay: string;
    public selectedFunctionalAreas: number[];
    public functionalAreas: FunctionalArea[];

    public constructor(init?: Partial<RadioChannel>) { (Object as any).assign(this, init); }
}

export class RadioChannelFunctionalArea
{
    public radioChannelFunctionalAreaId: number;
    public radioChannelId: number;
    public functionalAreaId: number;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public radioChannel: RadioChannel;
    public functionalArea: FunctionalArea;
    public enteredByUser: User;
    public lastModifiedByUser: User;

    public constructor(init?: Partial<RadioChannelFunctionalArea>) { (Object as any).assign(this, init); }
}

export class Note
{
    public noteId: number;
    public entityType: ngtEntityType;
    public entityId: number;
    public replacedNoteId: number;
    public venueId: number;
    public securityFirmId: number;
    public text: string;
    public obsolete: boolean;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public enteredBy: number;
    public lastModifiedBy: number;
    public canEdit: boolean;
    public createdDisplay: string;
    public locationDisplay: string;

    public constructor(init?: Partial<Note>) { (Object as any).assign(this, init); }
}

export class RosterFilter
{
    public from: SimpleDate;
    public to: SimpleDate;
    public sources: NamedValue[];
    public display: string;

    public constructor(init?: Partial<RosterFilter>) { (Object as any).assign(this, init); }
}

export enum ngtRosterItemType
{
    Shift = 'Shift',
    Timesheet = 'Timesheet',
    Leave = 'Leave',
    PendingLeave = 'PendingLeave',
}

export class TimeZone
{
    public timezoneId: string;
    public timezoneAbbreviation: string;
    public timezoneName: string;
    public defaultTimezone: boolean;

    public constructor(init?: Partial<TimeZone>) { (Object as any).assign(this, init); }
}

export class TimeZoneDate
{
    public isEmpty: boolean;
    public is15MinuteIncrements: boolean;
    public timeZone: string;
    public timeZones: TimeZone[];
    public year: number;
    public month: number;
    public day: number;
    public hour: number;
    public minute: number;
    public display: string;
    public displayShortDate: string;
    public timeZoneDisplay: string;

    public constructor(init?: Partial<TimeZoneDate>) { (Object as any).assign(this, init); }
}

export class RosterItem
{
    public id: number;
    public rosterType: ngtRosterItemType;
    public userId: number;
    public parentId: number;
    public startDate: string;
    public endDate: string;
    public notes: string;
    public description: string;
    public isCurrentSelection: boolean;
    public timeZoneDateStart: TimeZoneDate;
    public timeZoneDateEnd: TimeZoneDate;
    public hours: number;
    public minutes: number;

    public constructor(init?: Partial<RosterItem>) { (Object as any).assign(this, init); }
}

export class Roster
{
    public id: number;
    public inThePast: boolean;
    public date: string;
    public dateDisplay: string;
    public rosterItems: RosterItem[];

    public constructor(init?: Partial<Roster>) { (Object as any).assign(this, init); }
}

export class LeaveRequest
{
    public id: number;
    public employeeId: number;
    public companyId: number;
    public leaveTypeId: number;
    public companies: NamedValue[];
    public leaveTypes: NamedValue[];
    public fromDate: TimeZoneDate;
    public toDate: TimeZoneDate;
    public notes: string;

    public constructor(init?: Partial<LeaveRequest>) { (Object as any).assign(this, init); }
}

export enum ngtPOIRegistrationStatus
{
    Empty = 'Empty',
    FacesScanned = 'FacesScanned',
    FaceSelected = 'FaceSelected',
    FaceSearched = 'FaceSearched',
    POISelected = 'POISelected',
    Complete = 'Complete',
}

export enum ngtEnrolExpiryType
{
    Never = 'Never',
    Hours = 'Hours',
    Days = 'Days',
    Age = 'Age',
    Date = 'Date',
}

export class RegisterWatchlist
{
    public facialRecognitionWatchlist: FacialRecognitionWatchlist;
    public enrolExpiryType: ngtEnrolExpiryType;
    public expiry: number;
    public notes: string;
    public timeZoneDate: TimeZoneDate;

    public constructor(init?: Partial<RegisterWatchlist>) { (Object as any).assign(this, init); }
}

export class ScannedFace
{
    public image: string;
    public detectionScore: number;
    public boxSize: number;

    public constructor(init?: Partial<ScannedFace>) { (Object as any).assign(this, init); }
}

export class PoiFace
{
    public confidence: number;
    public faceId: string;
    public faceScore: number;
    public image: string;
    public poiId: string;

    public constructor(init?: Partial<PoiFace>) { (Object as any).assign(this, init); }
}

export class PoiWatchlist
{
    public watchlistId: string;
    public displayColor: string;
    public displayName: string;
    public watchlistType: string;

    public constructor(init?: Partial<PoiWatchlist>) { (Object as any).assign(this, init); }
}

export class Poi
{
    public image: string;
    public displayName: string;
    public notes: string;
    public id: string;
    public confidence: number;
    public faces: PoiFace[];
    public watchlists: PoiWatchlist[];

    public constructor(init?: Partial<Poi>) { (Object as any).assign(this, init); }
}

export class POIRegistration
{
    public status: ngtPOIRegistrationStatus;
    public incidentId: number;
    public incidentSerialNumber: string;
    public venueId: number;
    public venueName: string;
    public groupId: number;
    public groupName: string;
    public otherFacialRecognitionWatchlistId: number;
    public watchlists: FacialRecognitionWatchlist[];
    public registerWatchlists: RegisterWatchlist[];
    public incidentPatronPhotos: IncidentPatronPhoto[];
    public incidentDocuments: IncidentDocument[];
    public file: File;
    public incidentPatronPhoto: IncidentPatronPhoto;
    public incidentDocument: IncidentDocument;
    public displayName: string;
    public scannedFaces: ScannedFace[];
    public scannedFace: ScannedFace;
    public pois: Poi[];
    public poiGuid: string;
    public poi: Poi;
    public defaultName: string;
    public name: string;
    public defaultNotes: string;
    public notes: string;
    public setAsPrimaryImage: boolean;
    public timeZoneDate: TimeZoneDate;

    public constructor(init?: Partial<POIRegistration>) { (Object as any).assign(this, init); }
}

export class IncidentPOISummary
{
    public incident: string;
    public venueName: string;
    public incidentId: number;
    public incidentSerialNumber: string;
    public incidentTypeId: number;
    public incidentType: string;
    public incidentDateDisplay: string;
    public incidentSubmitted: string;
    public incidentDateOnlyDisplay: string;
    public createdBy: string;
    public watchlist: string;
    public expires: string;
    public obsolete: boolean;
    public notes: string;
    public readOnly: boolean;

    public constructor(init?: Partial<IncidentPOISummary>) { (Object as any).assign(this, init); }
}

export class IncidentPOIPoiFace
{
    public faceId: string;
    public image: string;

    public constructor(init?: Partial<IncidentPOIPoiFace>) { (Object as any).assign(this, init); }
}

export class POIIncidents
{
    public facialRecognitionPOIIncidentId: number;
    public poiGUID: string;
    public displayName: string;
    public notes: string;
    public venueId: number;
    public image: string;
    public expires: string;
    public obsolete: boolean;
    public watchlist: string;
    public incidentSummaries: IncidentPOISummary[];
    public incidents: PagedList<IncidentPOISummary>;
    public faces: IncidentPOIPoiFace[];

    public constructor(init?: Partial<POIIncidents>) { (Object as any).assign(this, init); }
}

export class PoiIdScan
{
    public poiGuid: string;
    public incidentPOISummary: IncidentPOISummary;
    public idScan: IncidentDocument;

    public constructor(init?: Partial<PoiIdScan>) { (Object as any).assign(this, init); }
}

export class IncidentPOINote
{
    public facialRecognitionPOIIncidentId: number;
    public venueId: number;
    public venue: string;
    public note: string;
    public timeZoneDate: TimeZoneDate;

    public constructor(init?: Partial<IncidentPOINote>) { (Object as any).assign(this, init); }
}

export class POIScanResult
{
    public poi: Poi;
    public poiIncidents: POIIncidents;
    public poiNotes: IncidentPOINote[];

    public constructor(init?: Partial<POIScanResult>) { (Object as any).assign(this, init); }
}

export class POIScan
{
    public incidentId: number;
    public venueId: number;
    public incidentPatronPhotos: IncidentPatronPhoto[];
    public incidentDocuments: IncidentDocument[];
    public file: File;
    public incidentPatronPhoto: IncidentPatronPhoto;
    public incidentDocument: IncidentDocument;
    public poiScanResults: POIScanResult[];
    public error: string;

    public constructor(init?: Partial<POIScan>) { (Object as any).assign(this, init); }
}

export class FacialRecognitionPOIIncident
{
    public facialRecognitionPOIIncidentId: number;
    public venueId: number;
    public groupId: number;
    public incidentId: number;
    public facialRecognitionWatchlistId: number;
    public watchlist: string;
    public facialRecognitionServerId: number;
    public server: string;
    public facialRecognitionAutomaticEnrolmentId: number;
    public poiGuid: string;
    public expiresdUtc: string;
    public timeZone: string;
    public fullName: string;
    public notes: string;
    public expiryDisplay: string;
    public retryAfterUtc: string;
    public failedWatchlistRemoval: boolean;
    public failedWatchlistRemovalError: string;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;

    public constructor(init?: Partial<FacialRecognitionPOIIncident>) { (Object as any).assign(this, init); }
}

export enum ngtScheduleEventOption
{
    VenueOnly = 'VenueOnly',
    AllEventsOnly = 'AllEventsOnly',
    SelectedEventsOnly = 'SelectedEventsOnly',
    VenueAndAllEvents = 'VenueAndAllEvents',
    VenueAndSelectedEvents = 'VenueAndSelectedEvents',
}

export enum ngtSchedulePattern
{
    Daily = 'Daily',
    Weekly = 'Weekly',
    Monthly = 'Monthly',
    Yearly = 'Yearly',
}

export enum ngtDailySchedule
{
    Days = 'Days',
    WeekDays = 'WeekDays',
}

export enum ngtMonthlySchedule
{
    Day = 'Day',
    DayOccuranceInMonth = 'DayOccuranceInMonth',
}

export enum ngtMonthlyDayOccurance
{
    First = 'First',
    Second = 'Second',
    Third = 'Third',
    Fourth = 'Fourth',
    Last = 'Last',
}

export enum ngtMonthlyDayOccuranceWeekday
{
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday',
    Sunday = 'Sunday',
}

export enum ngtYearlySchedule
{
    MonthDay = 'MonthDay',
    MonthOccuranceInYear = 'MonthOccuranceInYear',
}

export enum ngtRangeOption
{
    Ends = 'Ends',
    EndsAfter = 'EndsAfter',
    NoEnd = 'NoEnd',
}

export class ScheduleIncidentCategoryType implements IChangeTracking
{
    public scheduleIncidentCategoryTypeId: number;
    public scheduleId: number;
    public incidentCategoryTypeId: number;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public incidentCategoryType: IncidentCategoryType;
    public enteredByUser: User;
    public lastModifiedByUser: User;

    public constructor(init?: Partial<ScheduleIncidentCategoryType>) { (Object as any).assign(this, init); }
}

export class VenueFacialRecognitionNotificationUserRoleType
{
    public venueFacialRecognitionNotificationUserRoleTypeId: number;
    public venueId: number;
    public userRoleTypeId: number;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public venue: Venue;
    public userRoleType: UserRoleType;
    public enteredByUser: User;
    public lastModifiedByUser: User;

    public constructor(init?: Partial<VenueFacialRecognitionNotificationUserRoleType>) { (Object as any).assign(this, init); }
}

export class GroupIndustryCategory
{
    public groupIndustryCategoryId: number;
    public groupId: number;
    public industryCategoryId: number;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public group: Group;
    public industryCategory: IndustryCategory;

    public constructor(init?: Partial<GroupIndustryCategory>) { (Object as any).assign(this, init); }
}

export enum ngtNotificationType
{
    None = 'None',
    UserRequestsVenueAccess = 'UserRequestsVenueAccess',
    UserRequestsSecurityFirmAccess = 'UserRequestsSecurityFirmAccess',
    L1UserGrantedVenueAccess = 'L1UserGrantedVenueAccess',
    L1UserGrantedSecurityFirmAccess = 'L1UserGrantedSecurityFirmAccess',
    L2UserGrantedVenueAccess = 'L2UserGrantedVenueAccess',
    L2UserGrantedSecurityFirmAccess = 'L2UserGrantedSecurityFirmAccess',
    L3UserGrantedVenueAccess = 'L3UserGrantedVenueAccess',
    L3UserGrantedSecurityFirmAccess = 'L3UserGrantedSecurityFirmAccess',
    L4UserGrantedVenueAccess = 'L4UserGrantedVenueAccess',
    L4UserGrantedSecurityFirmAccess = 'L4UserGrantedSecurityFirmAccess',
    TempL2UserGrantedVenueAccess = 'TempL2UserGrantedVenueAccess',
    TempL2UserGrantedSecurityFirmAccess = 'TempL2UserGrantedSecurityFirmAccess',
    SuspendL1VenueUser = 'SuspendL1VenueUser',
    SuspendL1SecurityFirmUser = 'SuspendL1SecurityFirmUser',
    RecommendVenueIncidentDeletion = 'RecommendVenueIncidentDeletion',
    RecommendSecurityFirmIncidentDeletion = 'RecommendSecurityFirmIncidentDeletion',
    TempL3UserGrantedVenueAccess = 'TempL3UserGrantedVenueAccess',
    TempL3UserGrantedSecurityFirmAccess = 'TempL3UserGrantedSecurityFirmAccess',
    TempL4UserGrantedVenueAccess = 'TempL4UserGrantedVenueAccess',
    TempL4UserGrantedSecurityFirmAccess = 'TempL4UserGrantedSecurityFirmAccess',
    SuspendL2VenueUser = 'SuspendL2VenueUser',
    SuspendL2SecurityFirmUser = 'SuspendL2SecurityFirmUser',
    ConfirmL1VenueUserSuspension = 'ConfirmL1VenueUserSuspension',
    ConfirmL1SecurityFirmUserSuspension = 'ConfirmL1SecurityFirmUserSuspension',
    SuspendL3VenueUser = 'SuspendL3VenueUser',
    SuspendL3SecurityFirmUser = 'SuspendL3SecurityFirmUser',
    SuspendL4VenueUser = 'SuspendL4VenueUser',
    SuspendL4SecurityFirmUser = 'SuspendL4SecurityFirmUser',
    ConfirmVenueIncidentDeletion = 'ConfirmVenueIncidentDeletion',
    ConfirmSecurityFirmIncidentDeletion = 'ConfirmSecurityFirmIncidentDeletion',
    AddSecurityFirmToVenueAssociation = 'AddSecurityFirmToVenueAssociation',
    AddSecurityFirmToSecurityFirmAssociation = 'AddSecurityFirmToSecurityFirmAssociation',
    RemovalOfSecurityFirmToVenueAssociation = 'RemovalOfSecurityFirmToVenueAssociation',
    RemovalOfSecurityFirmToSecurityFirmAssociation = 'RemovalOfSecurityFirmToSecurityFirmAssociation',
    RegisterVenue = 'RegisterVenue',
    RegisterSecurityFirm = 'RegisterSecurityFirm',
    VenueIncidentRequiresApproval = 'VenueIncidentRequiresApproval',
    SecurityFirmIncidentRequiresApproval = 'SecurityFirmIncidentRequiresApproval',
    VenueIncidentApproved = 'VenueIncidentApproved',
    SecurityFirmIncidentApproved = 'SecurityFirmIncidentApproved',
    VenueIncidentSubmitted = 'VenueIncidentSubmitted',
    SecurityFirmIncidentSubmitted = 'SecurityFirmIncidentSubmitted',
    RegisterSecurityFirmUser = 'RegisterSecurityFirmUser',
    RegisterVenueUser = 'RegisterVenueUser',
    RegisterOtherUser = 'RegisterOtherUser',
    RequestInvalidSLEDAccess = 'RequestInvalidSLEDAccess',
    GrantInvalidSLEDAccess = 'GrantInvalidSLEDAccess',
    IncidentRequiresSubmission = 'IncidentRequiresSubmission',
    GrantAutoInvalidSLEDAccess = 'GrantAutoInvalidSLEDAccess',
    UserComplianceDocumentExpiry6Months = 'UserComplianceDocumentExpiry6Months',
    UserComplianceDocumentExpiry3Months = 'UserComplianceDocumentExpiry3Months',
    UserComplianceDocumentExpiry1Month = 'UserComplianceDocumentExpiry1Month',
    UserComplianceDocumentExpiry2Weeks = 'UserComplianceDocumentExpiry2Weeks',
    UserComplianceDocumentExpiry1Week = 'UserComplianceDocumentExpiry1Week',
    UserComplianceDocumentExpired = 'UserComplianceDocumentExpired',
    L4VenueRegisterUserComplianceDocumentExpiring = 'L4VenueRegisterUserComplianceDocumentExpiring',
    L3SecurityFirmRegisterUserComplianceDocumentExpiring = 'L3SecurityFirmRegisterUserComplianceDocumentExpiring',
    L4SecurityFirmRegisterUserComplianceDocumentExpiring = 'L4SecurityFirmRegisterUserComplianceDocumentExpiring',
    UserDocumentMissing = 'UserDocumentMissing',
    IncidentBulkApproval = 'IncidentBulkApproval',
    Broadcast = 'Broadcast',
    FacialRecognition = 'FacialRecognition',
    IncidentFlagNotification = 'IncidentFlagNotification',
    IncidentCategoryNotification = 'IncidentCategoryNotification',
    IncidentActionNotification = 'IncidentActionNotification',
    GroupUserAccessRequestNotification = 'GroupUserAccessRequestNotification',
    GroupVenueInvitationNotification = 'GroupVenueInvitationNotification',
    WatchlistEventNotification = 'WatchlistEventNotification',
    WatchlistMovedToGroup = 'WatchlistMovedToGroup',
    ScheduledTaskIncident = 'ScheduledTaskIncident',
    ScheduledTaskChecklist = 'ScheduledTaskChecklist',
    ScheduledTaskIncidentEscalation = 'ScheduledTaskIncidentEscalation',
    ScheduledTaskChecklistEscalation = 'ScheduledTaskChecklistEscalation',
    ChecklistSubmitted = 'ChecklistSubmitted',
    ChecklistValueSubmitted = 'ChecklistValueSubmitted',
    IncidentActionValueNotification = 'IncidentActionValueNotification',
    ExternalEventVenueIncident = 'ExternalEventVenueIncident',
    ExternalEventVenueChecklist = 'ExternalEventVenueChecklist',
    ExternalEventVenue = 'ExternalEventVenue',
    ExternalEventSecurityFirmIncident = 'ExternalEventSecurityFirmIncident',
    ExternalEventSecurityFirmChecklist = 'ExternalEventSecurityFirmChecklist',
    ExternalEventSecurityFirm = 'ExternalEventSecurityFirm',
}

export class Notification
{
    public notificationId: number;
    public incidentId: number;
    public watchlistEventNotificationId: number;
    public message: string;
    public dateTimeFormatted: string;
    public notificationTypeId: ngtNotificationType;
    public notificationTypeCode: string;
    public notificationCategoryId: number;
    public userId: number;
    public venueId: number;
    public securityFirmId: number;
    public groupId: number;
    public checklistId: number;
    public dueDateUtc: string;
    public obsolete: boolean;
    public isEscalation: boolean;
    public isOverDue: boolean;
    public userDisplay: string;

    public constructor(init?: Partial<Notification>) { (Object as any).assign(this, init); }
}

export class GroupUser
{
    public userRoleId: number;
    public userId: number;
    public givenName: string;
    public surname: string;
    public displayName: string;
    public nickname: string;
    public mobileNo: string;
    public rsaNumber: string;
    public userRoleTypeId: number;
    public userRoleType: string;
    public accessLevel: number;
    public userRoleStatusId: number;
    public userRoleStatus: string;
    public adminNotes: string;
    public isTemporary: boolean;
    public expiryDate: string;
    public expiry: LocalDate;
    public expiryDisplay: string;
    public obsolete: boolean;
    public notificationId: number;
    public notification: Notification;
    public groupName: string;

    public constructor(init?: Partial<GroupUser>) { (Object as any).assign(this, init); }
}

export class TimezoneData
{
    public timezoneId: number;
    public timezoneAbbreviation: string;
    public timezoneName: string;
    public gmtOffset: number;
    public dst: boolean;
    public defaultTimezone: boolean;

    public constructor(init?: Partial<TimezoneData>) { (Object as any).assign(this, init); }
}

export class ChecklistTemplateIncidentCategoryType
{
    public checklistTemplateIncidentCategoryTypeId: number;
    public checklistTemplateId: number;
    public incidentCategoryTypeId: number;
    public incidentCategoryType: IncidentCategoryType;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;

    public constructor(init?: Partial<ChecklistTemplateIncidentCategoryType>) { (Object as any).assign(this, init); }
}

export class ChecklistTemplateFunctionalArea
{
    public checklistTemplateFunctionalAreaId: number;
    public checklistTemplateId: number;
    public functionalAreaId: number;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public checklistTemplate: ChecklistTemplate;
    public functionalArea: FunctionalArea;
    public enteredByUser: User;
    public lastModifiedByUser: User;

    public constructor(init?: Partial<ChecklistTemplateFunctionalArea>) { (Object as any).assign(this, init); }
}

export class IncidentActionTemplate implements ITimestamped
{
    public incidentActionTemplateId: number;
    public name: string;
    public description: string;
    public obsolete: boolean;
    public venueId: number;
    public groupId: number;
    public canEdit: boolean;
    public fromDate: string;
    public startDate: SimpleDate;
    public timezones: TimezoneData[];
    public dateEntered: string;
    public dateModified: string;
    public enteredByUserId: number;
    public lastModifiedByUserId: number;
    public parentChecklistTemplateId: number;
    public instanceCount: number;
    public checklistTemplateItems: ChecklistTemplateItem[];
    public checklistTemplateIncidentCategoryTypes: ChecklistTemplateIncidentCategoryType[];
    public incidentCategoryTypeDisplay: string;
    public userRoleTypeSelections: UserRoleTypeSelection[];
    public isGlobal: boolean;
    public notificationTriggers: NotificationTrigger[];
    public defaultNotificationTrigger: NotificationTrigger;
    public userRoles: UserRoleSummary[];
    public checklistTemplateFunctionalAreas: ChecklistTemplateFunctionalArea[];
    public selectedFunctionalAreasDisplay: string;
    public selectedFunctionalAreas: number[];
    public functionalAreas: FunctionalArea[];

    public constructor(init?: Partial<IncidentActionTemplate>) { (Object as any).assign(this, init); }
}

export class Group
{
    public groupId: number;
    public name: string;
    public timeZone: string;
    public hideDefaultFolders: boolean;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public groupVenues: GroupVenue[];
    public groupIndustryCategories: GroupIndustryCategory[];
    public venueNotifications: Notification[];
    public venuesDisplay: string;
    public managerNotifications: Notification[];
    public managers: GroupUser[];
    public accessRequests: GroupUser[];
    public managersDisplay: string;
    public incidentActionTemplates: IncidentActionTemplate[];
    public checklistTemplates: ChecklistTemplateSummary[];
    public industryCategorySelections: IndustryCategorySelection[];
    public industryCategoriesDisplay: string;
    public featureDashboard: boolean;
    public featureVenue: boolean;
    public featureUserRoles: boolean;
    public featureUsers: boolean;
    public featureIncidentAction: boolean;
    public featureIndustry: boolean;
    public featureWatchlist: boolean;
    public featureDirectory: boolean;
    public featureChecklist: boolean;

    public constructor(init?: Partial<Group>) { (Object as any).assign(this, init); }
}

export class GroupVenueStatus
{
    public groupVenueStatusId: number;
    public name: string;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;

    public constructor(init?: Partial<GroupVenueStatus>) { (Object as any).assign(this, init); }
}

export class GroupVenue
{
    public groupVenueId: number;
    public groupId: number;
    public venueId: number;
    public groupVenueStatusId: number;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public group: Group;
    public venue: Venue;
    public groupVenueStatus: GroupVenueStatus;
    public userPermissions: ngtUserPermission[];

    public constructor(init?: Partial<GroupVenue>) { (Object as any).assign(this, init); }
}

export class Venue
{
    public venueId: number;
    public name: string;
    public address: string;
    public suburb: string;
    public stateId: number;
    public stateDisplayName: string;
    public postcode: string;
    public phone: string;
    public website: string;
    public liquorLicenceNumber: string;
    public licenceeOwnerName: string;
    public industryCategoryId: number;
    public industryCategoryName: string;
    public industryCategoryDisplay: string;
    public liquorLicenceDate: SimpleDate;
    public liquorLicenceReviewDate: string;
    public liquorLicenceReviewDateDisplay: string;
    public cutOffDate: string;
    public cutOffDateUtc: string;
    public obsolete: boolean;
    public originalRegistrationComplete: boolean;
    public registrationComplete: boolean;
    public latitude: number;
    public longitude: number;
    public exemptFromPayment: boolean;
    public soleTrader: boolean;
    public venueLicenceTypeId: number;
    public venueLicenceTypeName: string;
    public maxOccupancy: number;
    public venuePatronCapacityId: number;
    public venueTradingHoursId: number;
    public venueTradingHoursName: string;
    public venuePrecinctId: number;
    public venuePrecinctName: string;
    public patronCapacity: number;
    public abn: string;
    public temporary: boolean;
    public temporaryExpiryDate: string;
    public adminNotes: string;
    public usersSuspendedDate: string;
    public facialRecognitionNotificationGuid: string;
    public facialRecognitionNotificationExternalUrl: string;
    public onlySignedOn: boolean;
    public includeAssociatedUsers: boolean;
    public isInRange: boolean;
    public isActive: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public venueFacialRecognitionNotificationUserRoleTypes: VenueFacialRecognitionNotificationUserRoleType[];
    public userRoleTypeSelections: UserRoleTypeSelection[];
    public userRoleTypesDisplay: string;
    public groups: GroupVenue[];
    public industryGroups: Group[];

    public constructor(init?: Partial<Venue>) { (Object as any).assign(this, init); }
}

export class ScheduleVenue implements IChangeTracking
{
    public scheduleVenueId: number;
    public scheduleId: number;
    public venueId: number;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public venue: Venue;
    public enteredByUser: User;
    public lastModifiedByUser: User;

    public constructor(init?: Partial<ScheduleVenue>) { (Object as any).assign(this, init); }
}

export class VenueReportingPeriod
{
    public venueReportingPeriodId: number;
    public venueId: number;
    public venue: string;
    public venueEventId: number;
    public venueCurrentTimezone: string;
    public startTimeUtc: string;
    public startTime: LocalDate;
    public startTimeTimezone: string;
    public endTimeUtc: string;
    public endTime: LocalDate;
    public endTimeTimezone: string;
    public securityCode: string;
    public securityCodeNotRequired: boolean;
    public closedBy: number;
    public closedByName: string;
    public dateClosedUtc: string;
    public dateClosed: LocalDate;
    public dateClosedTimezone: string;
    public calculatedEndDate: string;
    public isCurrent: boolean;
    public isFuture: boolean;
    public isOver: boolean;
    public timezones: TimezoneData[];
    public modified: boolean;
    public obsolete: boolean;
    public venueEventName: string;
    public venueEventLatitude: number;
    public venueEventLongitude: number;

    public constructor(init?: Partial<VenueReportingPeriod>) { (Object as any).assign(this, init); }
}

export class VenueEvent
{
    public venueEventId: number;
    public venueId: number;
    public name: string;
    public description: string;
    public useVenueAddress: boolean;
    public suburb: string;
    public address: string;
    public stateId: number;
    public postcode: string;
    public latitude: number;
    public longitude: number;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public state: State;
    public venueReportingPeriods: VenueReportingPeriod[];
    public states: State[];
    public defaultVenueReportingPeriod: VenueReportingPeriod;
    public isCurrent: boolean;

    public constructor(init?: Partial<VenueEvent>) { (Object as any).assign(this, init); }
}

export class ScheduleVenueEvent implements IChangeTracking
{
    public scheduleVenueEventId: number;
    public scheduleId: number;
    public venueEventId: number;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public venueEvent: VenueEvent;
    public enteredByUser: User;
    public lastModifiedByUser: User;

    public constructor(init?: Partial<ScheduleVenueEvent>) { (Object as any).assign(this, init); }
}

export class ScheduleVenueLocation implements IChangeTracking
{
    public scheduleVenueLocationId: number;
    public scheduleId: number;
    public venueLocationId: number;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public venueLocation: VenueLocation;
    public enteredByUser: User;
    public lastModifiedByUser: User;

    public constructor(init?: Partial<ScheduleVenueLocation>) { (Object as any).assign(this, init); }
}

export class ScheduleIncidentFlagType implements IChangeTracking
{
    public scheduleIncidentFlagTypeId: number;
    public scheduleId: number;
    public incidentFlagTypeId: number;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public incidentFlagType: IncidentFlagType;
    public enteredByUser: User;
    public lastModifiedByUser: User;

    public constructor(init?: Partial<ScheduleIncidentFlagType>) { (Object as any).assign(this, init); }
}

export class ScheduleUserRoleType implements IChangeTracking
{
    public scheduleUserRoleTypeId: number;
    public scheduleId: number;
    public userRoleTypeId: number;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public userRoleType: UserRoleType;
    public enteredByUser: User;
    public lastModifiedByUser: User;

    public constructor(init?: Partial<ScheduleUserRoleType>) { (Object as any).assign(this, init); }
}

export class ScheduleFallbackUserRoleType
{
    public scheduleFallbackUserRoleTypeId: number;
    public scheduleId: number;
    public userRoleTypeId: number;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public userRoleType: UserRoleType;
    public enteredByUser: User;
    public lastModifiedByUser: User;

    public constructor(init?: Partial<ScheduleFallbackUserRoleType>) { (Object as any).assign(this, init); }
}

export class ScheduleEscalationUserRoleType implements IChangeTracking
{
    public scheduleEscalationUserRoleTypeId: number;
    public scheduleId: number;
    public userRoleTypeId: number;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public userRoleType: UserRoleType;
    public enteredByUser: User;
    public lastModifiedByUser: User;

    public constructor(init?: Partial<ScheduleEscalationUserRoleType>) { (Object as any).assign(this, init); }
}

export class Schedule
{
    public scheduleId: number;
    public venueId: number;
    public securityFirmId: number;
    public entityTypeID: ngtEntityType;
    public entityTypes: ngtEntityType[];
    public scheduleEventOption: ngtScheduleEventOption;
    public checklistTemplateId: number;
    public incidentTypeId: number;
    public onlySignedOn: boolean;
    public includeAssociatedUsers: boolean;
    public escalate: boolean;
    public escalateOnlySignedOn: boolean;
    public escalateIncludeAssociatedUsers: boolean;
    public escalationAfterMinutes: number;
    public hour: number;
    public minute: number;
    public timeZone: string;
    public dueInMinutes: number;
    public repeat: boolean;
    public everyMinutes: number;
    public untilHour: number;
    public untilMinute: number;
    public schedulePattern: ngtSchedulePattern;
    public dailySchedule: ngtDailySchedule;
    public dailyDays: number;
    public weeklyWeeks: number;
    public weeklyMonday: boolean;
    public weeklyTuesday: boolean;
    public weeklyWednesday: boolean;
    public weeklyThursday: boolean;
    public weeklyFriday: boolean;
    public weeklySaturday: boolean;
    public weeklySunday: boolean;
    public monthlySchedule: ngtMonthlySchedule;
    public monthlyDay: number;
    public monthlyDayEveryMonths: number;
    public monthlyDayOccurance: ngtMonthlyDayOccurance;
    public monthlyDayOccuranceWeekday: ngtMonthlyDayOccuranceWeekday;
    public monthlyDayOccuranceEveryMonths: number;
    public yearlySchedule: ngtYearlySchedule;
    public yearlyMonth: number;
    public yearlyMonthDay: number;
    public yearlyMonthDayEveryYears: number;
    public yearlyMonthlyDayOccurance: number;
    public yearlyMonthlyDayOccuranceWeekday: number;
    public yearlyMonthlyDayOccuranceMonth: number;
    public yearlyMonthlyDayOccuranceEveryYears: number;
    public rangeStartDate: string;
    public rangeStartDateTimeZone: string;
    public rangeStartTimeZoneDate: TimeZoneDate;
    public rangeOption: ngtRangeOption;
    public rangeOccurances: number;
    public rangeStopDate: string;
    public rangeStopDateTimeZone: string;
    public rangeStopTimeZoneDate: TimeZoneDate;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public enteredByDisplay: string;
    public lastModifiedByDisplay: string;
    public dateEnteredDisplay: string;
    public dateModifiedDisplay: string;
    public lastRanDisplay: string;
    public incidentType: IncidentType;
    public checklistTemplate: ChecklistTemplate;
    public enteredByUser: User;
    public lastModifiedByUser: User;
    public successCount: number;
    public scheduleIncidentCategoryTypes: ScheduleIncidentCategoryType[];
    public scheduleVenues: ScheduleVenue[];
    public scheduleVenueEvents: ScheduleVenueEvent[];
    public scheduleVenueLocations: ScheduleVenueLocation[];
    public scheduleIncidentFlagTypes: ScheduleIncidentFlagType[];
    public scheduleUserRoleTypes: ScheduleUserRoleType[];
    public scheduleFallbackUserRoleTypes: ScheduleFallbackUserRoleType[];
    public scheduleEscalationUserRoleTypes: ScheduleEscalationUserRoleType[];
    public display: string;

    public constructor(init?: Partial<Schedule>) { (Object as any).assign(this, init); }
}

export class ScheduleData
{
    public venueId: number;
    public venueName: string;
    public timeZones: TimezoneData[];
    public incidentTypes: IncidentType[];
    public incidentTypeCategoryTypes: IncidentTypeCategoryTypes[];
    public venueLocations: VenueLocation[];
    public incidentFlagTypes: IncidentFlagType[];
    public userRoleTypes: UserRoleType[];
    public venueEvents: VenueEvent[];

    public constructor(init?: Partial<ScheduleData>) { (Object as any).assign(this, init); }
}

export class PagedList<T>
{
    public data: T[];
    public paging: Paging;
    public count: number;
    public unFilteredCount: number;

    public constructor(init?: Partial<PagedList<T>>) { (Object as any).assign(this, init); }
}

export class ScheduleLog
{
    public scheduleLogId: number;
    public scheduleId: number;
    public success: boolean;
    public localDateAsInt: number;
    public localTimeAsInt: number;
    public errorMessage: string;
    public checklistId: number;
    public incidentId: number;
    public venueEventId: number;
    public venueEvent: string;
    public dueDateUtc: string;
    public escalateDateUtc: string;
    public escalatedDateUtc: string;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public enteredByUser: User;
    public lastModifiedByUser: User;
    public dateDisplay: string;
    public dueDateDisplay: string;
    public escalateDateDisplay: string;
    public notifications: Notification[];
    public dateEnteredDisplay: string;
    public created: string;
    public submitted: boolean;
    public submittedDateDisplay: string;
    public submittedBy: string;

    public constructor(init?: Partial<ScheduleLog>) { (Object as any).assign(this, init); }
}

export class FacialRecognitionWatchlistPoisFilter
{
    public groupId: number;
    public venueId: number;
    public facialRecognitionServerId: number;
    public facialRecognitionWatchlistId: number;
    public search: string;
    public showObsolete: boolean;
    public servers: FacialRecognitionServer[];
    public watchlists: FacialRecognitionWatchlist[];
    public display: string;

    public constructor(init?: Partial<FacialRecognitionWatchlistPoisFilter>) { (Object as any).assign(this, init); }
}

export class PoiWatchlistDetail
{
    public watchlistGuid: string;
    public watchlist: string;
    public facialRecognitionWatchlistId: number;
    public fullName: string;
    public notes: string;
    public obsolete: boolean;
    public dateDisplay: string;
    public facialRecognitionPOIIncidentId: number;
    public timeZoneDate: TimeZoneDate;

    public constructor(init?: Partial<PoiWatchlistDetail>) { (Object as any).assign(this, init); }
}

export class FacialRecognitionWatchlistPoi
{
    public poiGuid: string;
    public displayImage: string;
    public displayName: string;
    public fullName: string;
    public notes: string;
    public obsolete: boolean;
    public dateDisplay: string;
    public facialRecognitionWatchlistId: number;
    public facialRecognitionPOIIncidentId: number;
    public incidentPOISummaries: IncidentPOISummary[];
    public timeZoneDate: TimeZoneDate;
    public poiNotes: IncidentPOINote[];
    public incidentSummaries: PagedList<IncidentPOISummary>;
    public watchlists: PoiWatchlistDetail[];

    public constructor(init?: Partial<FacialRecognitionWatchlistPoi>) { (Object as any).assign(this, init); }
}

export class FacialRecognitionWatchlistImport
{
    public facialRecognitionServer: FacialRecognitionServer;
    public selected: boolean;
    public watchlistGuid: string;
    public name: string;
    public forGroup: boolean;
    public groups: Group[];
    public groupId: number;
    public error: string;
    public success: boolean;

    public constructor(init?: Partial<FacialRecognitionWatchlistImport>) { (Object as any).assign(this, init); }
}

export class FacialRecognitionWatchlistMigration
{
    public facialRecognitionWatchlistMigrationId: number;
    public facialRecognitionServerId: number;
    public facialRecognitionWatchlistId: number;
    public toFacialRecognitionWatchlistId: number;
    public migratePOIs: boolean;
    public migratedPOIs: number;
    public lastMigratedPOIGuid: string;
    public migrationStatus: ngtSyncStatus;
    public error: string;
    public obsolete: boolean;
    public facialRecognitionServer: FacialRecognitionServer;
    public facialRecognitionServers: FacialRecognitionServer[];
    public facialRecognitionWatchlist: FacialRecognitionWatchlist;

    public constructor(init?: Partial<FacialRecognitionWatchlistMigration>) { (Object as any).assign(this, init); }
}

export class FacialRecognitionWatchlistMove
{
    public facialRecognitionServerId: number;
    public facialRecognitionWatchlistId: number;
    public groups: Group[];
    public groupId: number;
    public privacyAck: boolean;
    public error: string;
    public facialRecognitionServer: FacialRecognitionServer;
    public facialRecognitionWatchlist: FacialRecognitionWatchlist;

    public constructor(init?: Partial<FacialRecognitionWatchlistMove>) { (Object as any).assign(this, init); }
}

export class FacialRecognitionAutomaticEnrolmentIncidentCategoryType
{
    public facialRecognitionAutomaticEnrolmentIncidentCategoryTypeId: number;
    public facialRecognitionAutomaticEnrolmentId: number;
    public incidentCategoryTypeId: number;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public incidentCategoryType: IncidentCategoryType;

    public constructor(init?: Partial<FacialRecognitionAutomaticEnrolmentIncidentCategoryType>) { (Object as any).assign(this, init); }
}

export class FacialRecognitionAutomaticEnrolment
{
    public facialRecognitionAutomaticEnrolmentId: number;
    public facialRecognitionWatchlistId: number;
    public incidentTypeId: number;
    public enrolExpiryType: ngtEnrolExpiryType;
    public expiry: number;
    public venueId: number;
    public obsolete: boolean;
    public incidentType: IncidentType;
    public incidentCategoryType: IncidentCategoryType;
    public facialRecognitionWatchlist: FacialRecognitionWatchlist;
    public facialRecognitionAutomaticEnrolmentIncidentCategoryTypes: FacialRecognitionAutomaticEnrolmentIncidentCategoryType[];
    public incidentCategoryTypesDisplay: string;
    public incidentCategoryTypeIds: number[];

    public constructor(init?: Partial<FacialRecognitionAutomaticEnrolment>) { (Object as any).assign(this, init); }
}

export class EventStreamingConfigurationCameraWatchlists
{
    public cameraGuid: string;
    public minConfidence: number;
    public watchlistGuids: string[];

    public constructor(init?: Partial<EventStreamingConfigurationCameraWatchlists>) { (Object as any).assign(this, init); }
}

export class CameraAgeRange
{
    public cameraGuid: string;
    public ageFrom: number;
    public ageTo: number;

    public constructor(init?: Partial<CameraAgeRange>) { (Object as any).assign(this, init); }
}

export class CameraDetail
{
    public cameraGuid: string;
    public venue: string;
    public name: string;

    public constructor(init?: Partial<CameraDetail>) { (Object as any).assign(this, init); }
}

export class EventStreamingConfiguration
{
    public servers: FacialRecognitionServer[];
    public cameraWatchlists: EventStreamingConfigurationCameraWatchlists[];
    public cameraAgeRanges: CameraAgeRange[];
    public cameraDetails: CameraDetail[];

    public constructor(init?: Partial<EventStreamingConfiguration>) { (Object as any).assign(this, init); }
}

export class GroupFilter
{
    public paging: Paging;
    public search: string;
    public showObsolete: boolean;
    public isAdmin: boolean;
    public display: string;

    public constructor(init?: Partial<GroupFilter>) { (Object as any).assign(this, init); }
}

export class UserSelection
{
    public userId: number;
    public name: string;
    public selected: boolean;
    public error: string;

    public constructor(init?: Partial<UserSelection>) { (Object as any).assign(this, init); }
}

export class IncidentFlagTypeFilter
{
    public search: string;
    public showObsolete: boolean;
    public display: string;

    public constructor(init?: Partial<IncidentFlagTypeFilter>) { (Object as any).assign(this, init); }
}

export class IncidentFlagTypeNotification
{
    public incidentFlagTypeNotificationId: number;
    public incidentFlagTypeId: number;
    public groupId: number;
    public venueId: number;
    public securityFirmId: number;
    public onlySignedOn: boolean;
    public includeAssociatedUsers: boolean;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredDisplay: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public enteredByUser: User;
    public lastModifiedByUser: User;
    public incidentFlagType: IncidentFlagType;
    public incidentFlagTypeNotificationUserRoleTypes: IncidentFlagTypeNotificationUserRoleType[];
    public userRoleTypeSelections: UserRoleTypeSelection[];
    public userRoleTypesDisplay: string;
    // @Ignore()
    public notificationTrigger: NotificationTrigger;

    public constructor(init?: Partial<IncidentFlagTypeNotification>) { (Object as any).assign(this, init); }
}

export class IncidentFlagTypeNotificationUserRoleType
{
    public incidentFlagTypeNotificationUserRoleTypeId: number;
    public incidentFlagTypeNotificationId: number;
    public userRoleTypeId: number;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public incidentFlagTypeNotification: IncidentFlagTypeNotification;
    public userRoleType: UserRoleType;
    public enteredByUser: User;
    public lastModifiedByUser: User;

    public constructor(init?: Partial<IncidentFlagTypeNotificationUserRoleType>) { (Object as any).assign(this, init); }
}

export interface IHasForVenueSecurityFirm
{
    forVenueId?: number;
    forSecurityFirmId?: number;
    forVenueEventId?: number;
}

export class SearchDate
{
    public isEmpty: boolean;
    public timezoneId: number;
    public gmtOffset: number;
    public year: number;
    public month: number;
    public day: number;
    public hour: number;
    public minute: number;
    public utcDate: string;
    public displayLocal: string;

    public constructor(init?: Partial<SearchDate>) { (Object as any).assign(this, init); }
}

export class FacialRecognitionNotificationFilter
{
    public search: string;
    public showObsolete: boolean;
    public venueId: number;
    public venues: VenueSelection[];
    public from: SearchDate;
    public to: SearchDate;
    public showUnmatched: boolean;
    public watchlistId: number;
    public cameraId: number;
    public cameras: FacialRecognitionCamera[];
    public watchlists: FacialRecognitionWatchlist[];
    public display: string;

    public constructor(init?: Partial<FacialRecognitionNotificationFilter>) { (Object as any).assign(this, init); }
}

export class FeedCameraData
{
    public camera_id: string;
    public camera_description: string;

    public constructor(init?: Partial<FeedCameraData>) { (Object as any).assign(this, init); }
}

export class FeedCropData
{
    public face_crop_img: string;
    public face_score: number;

    public constructor(init?: Partial<FeedCropData>) { (Object as any).assign(this, init); }
}

export class FeedWatchLists
{
    public watchlist_id: string;
    public watchlist_type: string;
    public match_outcome: string;
    public display_name: string;
    public display_color: string;

    public constructor(init?: Partial<FeedWatchLists>) { (Object as any).assign(this, init); }
}

export class FeedMatchData
{
    public utc_time_matched: string;
    public poi_id: string;
    public poi_confidence: string;
    public poi_display_img: string;
    public poi_display_name: string;
    public watchlists: FeedWatchLists[];

    public constructor(init?: Partial<FeedMatchData>) { (Object as any).assign(this, init); }
}

export class FeedFaceFeaturesData
{
    public gender_outcome: string;
    public age_group_outcome: string;
    public mask_outcome: string;

    public constructor(init?: Partial<FeedFaceFeaturesData>) { (Object as any).assign(this, init); }
}

export class FeedAppearanceData
{
    public appearance_id: string;
    public utc_time_started: string;
    public first_frame_id: number;

    public constructor(init?: Partial<FeedAppearanceData>) { (Object as any).assign(this, init); }
}

export class FeedEvent
{
    public event_id: string;
    public event_type: string;
    public poi_display_img: string;
    public camera_data: FeedCameraData;
    public crop_data: FeedCropData;
    public crop_datas: FeedCropData[];
    public match_data: FeedMatchData;
    public face_features_data: FeedFaceFeaturesData;
    public appearance_data: FeedAppearanceData;
    public watchlistNames: string;
    public displayColors: string;
    public displayColor: string;

    public constructor(init?: Partial<FeedEvent>) { (Object as any).assign(this, init); }
}

export class EventWatchlists
{
    public watchlist_id: string;
    public display_name: string;
    public display_color: string;

    public constructor(init?: Partial<EventWatchlists>) { (Object as any).assign(this, init); }
}

export class FacialRecognitionFeed
{
    public events: FeedEvent[];
    public watchlists: EventWatchlists[];
    public hasCameras: boolean;
    public venueId: number;
    public venueLocationId: number;

    public constructor(init?: Partial<FacialRecognitionFeed>) { (Object as any).assign(this, init); }
}

export class FacialRecognitionCameraImport
{
    public facialRecognitionServer: FacialRecognitionServer;
    public selected: boolean;
    public cameraGuid: string;
    public name: string;
    public error: string;
    public success: boolean;

    public constructor(init?: Partial<FacialRecognitionCameraImport>) { (Object as any).assign(this, init); }
}

export class FacialRecognitionNotificationDuration
{
    public durationsInMilliseconds: number[];
    public min: number;
    public max: number;
    public average: number;
    public count: number;
    public durations: string;

    public constructor(init?: Partial<FacialRecognitionNotificationDuration>) { (Object as any).assign(this, init); }
}

export class FacialRecognitionNotificationStatistics
{
    public duration: FacialRecognitionNotificationDuration;

    public constructor(init?: Partial<FacialRecognitionNotificationStatistics>) { (Object as any).assign(this, init); }
}

export class ReportingPeriodSelection
{
    public selected: boolean;
    public venueReportingPeriodID: number;
    public venueEventId: number;
    public reportingPeriodDisplayString: string;

    public constructor(init?: Partial<ReportingPeriodSelection>) { (Object as any).assign(this, init); }
}

export class ChecklistStatus
{
    public checklistStatusId: number;
    public name: string;
    public obsolete: boolean;
    public dateEntered: string;
    public dateModified: string;

    public constructor(init?: Partial<ChecklistStatus>) { (Object as any).assign(this, init); }
}

export class ChecklistFilter
{
    public reportingPeriods: ReportingPeriodSelection[];
    public checkListStatuses: ChecklistStatus[];
    public checkListStatusIds: number[];
    public reportingPeriodId: number;
    public byReportingPeriod: boolean;
    public from: SearchDate;
    public to: SearchDate;
    public paging: Paging;
    public search: string;
    public checklistId: number;
    public showObsolete: boolean;
    public imageOption: string;
    public display: string;

    public constructor(init?: Partial<ChecklistFilter>) { (Object as any).assign(this, init); }
}

export class ChecklistSummary
{
    public checklistId: number;
    public checklistTemplateId: number;
    public name: string;
    public enteredByUser: User;
    public enteredByUserId: number;
    public checklistStatus: ChecklistStatuses;
    public dateEntered: string;
    public dateModified: string;
    public obsolete: boolean;
    public venueEventId: number;
    public venueEventName: string;
    public information: string;

    public constructor(init?: Partial<ChecklistSummary>) { (Object as any).assign(this, init); }
}

export class ChecklistTemplateFilter
{
    public viewOption: number;
    public viewOptions: NamedValue[];
    public isAdmin: boolean;
    public groupId: number;
    public checklistTemplateFilterId: number;
    public search: string;
    public showObsolete: boolean;
    public checklistTemplateCategoryIds: number[];
    public venueIds: number[];
    public securityFirmIds: number[];
    public stateIds: number[];
    public industryCategoryIds: number[];
    public display: string;

    public constructor(init?: Partial<ChecklistTemplateFilter>) { (Object as any).assign(this, init); }
}

export class ChecklistTemplateCategory
{
    public checklistTemplateCategoryId: number;
    public name: string;
    public obsolete: boolean;
    public dateEntered: string;
    public dateModified: string;
    public enteredByUserId: number;
    public lastModifiedByUserId: number;
    public industryCategories: number[];
    public states: number[];
    public venues: number[];
    public securityFirms: number[];

    public constructor(init?: Partial<ChecklistTemplateCategory>) { (Object as any).assign(this, init); }
}

export class ChecklistTemplateCategorySummary
{
    public checklistTemplateCategoryId: number;
    public name: string;
    public enteredByUser: User;
    public dateEntered: string;
    public dateModified: string;

    public constructor(init?: Partial<ChecklistTemplateCategorySummary>) { (Object as any).assign(this, init); }
}

export enum ngtStatsMode
{
    IncidentType = 'IncidentType',
    IncidentCategoryType = 'IncidentCategoryType',
    IncidentLocation = 'IncidentLocation',
    IncidentRefusalReason = 'IncidentRefusalReason',
    IncidentRemovalReason = 'IncidentRemovalReason',
    IncidentPersonInvolvedDemographic = 'IncidentPersonInvolvedDemographic',
    IncidentEnteredBy = 'IncidentEnteredBy',
    IncidentApprovedBy = 'IncidentApprovedBy',
    IncidentPersonInvolvedGender = 'IncidentPersonInvolvedGender',
    IncidentPersonInvolvedRacialAppearance = 'IncidentPersonInvolvedRacialAppearance',
    Group = 'Group',
    IncidentWatchlist = 'IncidentWatchlist',
    ChecklistTemplate = 'ChecklistTemplate',
    RGOIncidentType = 'RGOIncidentType',
    RGOIncidentCategoryType = 'RGOIncidentCategoryType',
    RGOIncidentLocation = 'RGOIncidentLocation',
    All = 'All',
}

export class DashboardFilter
{
    public dashboardId: number;
    public mode: ngtStatsMode;
    public groupId: number;
    public venues: VenueSelection[];
    public from: SearchDate;
    public to: SearchDate;
    public includeZeroValues: boolean;
    public advanced: boolean;
    public incidentCategoryTypeId: number;
    public canViewIDChecks: boolean;
    public includeIDChecks: boolean;
    public incidentCategoryTypes: IncidentCategoryType[];
    public locationId: number;
    public locations: VenueLocation[];
    public display: string;

    public constructor(init?: Partial<DashboardFilter>) { (Object as any).assign(this, init); }
}

export class BaseChartDetails
{
    public mode: ngtStatsMode;

    public constructor(init?: Partial<BaseChartDetails>) { (Object as any).assign(this, init); }
}

export class ChartDetails extends BaseChartDetails
{
    public name: string;
    public total: number;
    public annotation: string;
    public rank: number;
    public subCategory: string;
    public color: string;

    public constructor(init?: Partial<ChartDetails>) { super(init); (Object as any).assign(this, init); }
}

export class IncidentHeatmap
{
    public incidentId: number;
    public venueId: number;
    public latitude: number;
    public longitude: number;
    public obsolete: boolean;

    public constructor(init?: Partial<IncidentHeatmap>) { (Object as any).assign(this, init); }
}

export class Position
{
    public lat: number;
    public lng: number;

    public constructor(init?: Partial<Position>) { (Object as any).assign(this, init); }
}

export class VenueMarker
{
    public venueId: number;
    public isActive: boolean;
    public position: Position;
    public name: string;
    public address: string;
    public suburb: string;
    public stateDisplayName: string;
    public postcode: string;
    public showInfo: boolean;
    public visible: boolean;

    public constructor(init?: Partial<VenueMarker>) { (Object as any).assign(this, init); }
}

export class AdminDashboardFilter
{
    public dashboardId: number;
    public from: SimpleDate;
    public to: SimpleDate;
    public showVenueMarkers: boolean;
    public showHeatmapZeroIncidents: boolean;
    public showActiveVenues: boolean;
    public showInactiveVenues: boolean;
    public showPostcodeBoundaries: boolean;
    public includeZeroValues: boolean;
    public incidentCategoryTypes: IncidentCategoryTypeSelection[];
    public incidentTypes: IncidentTypeSelection[];
    public incidentCategoryTypeIds: number[];
    public incidentTypeIds: number[];
    public postcodes: string[];
    public display: string;

    public constructor(init?: Partial<AdminDashboardFilter>) { (Object as any).assign(this, init); }
}

export class RangeChartTotal extends BaseChartDetails
{
    public name: Object;
    public dateEntered: string;
    public total: number;
    public annotation: string;

    public constructor(init?: Partial<RangeChartTotal>) { super(init); (Object as any).assign(this, init); }
}

export enum ngtOverviewStatsMode
{
    Quick = 'Quick',
    VenueIncidents = 'VenueIncidents',
    SecurityFirmIncidents = 'SecurityFirmIncidents',
    VenueUsers = 'VenueUsers',
    SecurityFirmUsers = 'SecurityFirmUsers',
    IncidentStats = 'IncidentStats',
}

export class Files
{

    public constructor(init?: Partial<Files>) { (Object as any).assign(this, init); }
}

export class Base64ImageData
{
    public data: string;
    public fileType: string;
    public fileName: string;
    public ocrText: string;
    public extension: string;

    public constructor(init?: Partial<Base64ImageData>) { (Object as any).assign(this, init); }
}

export class DeviceInstallation
{
    public installationId: string;
    public platform: string;
    public pushChannel: string;
    public tags: string[];

    public constructor(init?: Partial<DeviceInstallation>) { (Object as any).assign(this, init); }
}

export class NotificationRequest
{
    public text: string;
    public action: string;
    public tags: string[];
    public silent: boolean;

    public constructor(init?: Partial<NotificationRequest>) { (Object as any).assign(this, init); }
}

export class ContactFilter implements IHasForVenueSecurityFirm
{
    public search: string;
    public forVenueId: number;
    public forSecurityFirmId: number;
    public forVenueEventId: number;
    public display: string;

    public constructor(init?: Partial<ContactFilter>) { (Object as any).assign(this, init); }
}

export class SecurityFirmContactType
{
    public securityFirmContactTypeId: number;
    public name: string;
    public rank: number;
    public obsolete: boolean;
    public dateEntered: string;
    public dateEnteredUtc: string;

    public constructor(init?: Partial<SecurityFirmContactType>) { (Object as any).assign(this, init); }
}

export class SecurityFirmContact
{
    public securityFirmContactId: number;
    public securityFirmContactTypeId: number;
    public securityFirmId: number;
    public userId: number;
    public firstname: string;
    public lastname: string;
    public phone: string;
    public email: string;
    public primaryContact: boolean;
    public secondaryContact: boolean;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public user: User;
    public securityFirmContactType: SecurityFirmContactType;
    public displayName: string;

    public constructor(init?: Partial<SecurityFirmContact>) { (Object as any).assign(this, init); }
}

export class SecurityFirmOtherDocument
{
    public securityFirmDocumentId: number;
    public securityFirmOtherDocumentId: number;
    public securityFirmId: number;
    public extension: string;
    public originalFileName: string;
    public canvasRescaled: boolean;
    public obsolete: boolean;
    public enteredBy: number;
    public mimeType: string;
    public filePath: string;
    public filePreviewPath: string;
    public filePreviewText: string;
    public fileDisplayPath: string;
    public isImage: boolean;
    public documentTypeId: number;
    public originalDocumentTypeId: number;
    public documentType: string;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public lastModifiedBy: number;

    public constructor(init?: Partial<SecurityFirmOtherDocument>) { (Object as any).assign(this, init); }
}

export class DocumentType
{
    public documentTypeId: number;
    public documentCategoryId: number;
    public documentCategory: string;
    public entityTypeId: number;
    public entityType: string;
    public name: string;
    public rank: number;
    public obsolete: boolean;
    public dateEntered: string;
    public dateEnteredUtc: string;

    public constructor(init?: Partial<DocumentType>) { (Object as any).assign(this, init); }
}

export class SecurityFirmComplianceDocument
{
    public securityFirmDocumentId: number;
    public securityFirmComplianceDocumentId: number;
    public securityFirmId: number;
    public extension: string;
    public originalFileName: string;
    public canvasRescaled: boolean;
    public obsolete: boolean;
    public enteredBy: number;
    public mimeType: string;
    public filePath: string;
    public filePreviewPath: string;
    public filePreviewText: string;
    public fileDisplayPath: string;
    public isImage: boolean;
    public documentTypeId: number;
    public originalDocumentTypeId: number;
    public documentType: string;
    public licenceNumber: string;
    public dateExpiry: string;
    public dateOfExpiry: SimpleDate;
    public hasExpired: boolean;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public lastModifiedBy: number;
    public visibleToVenue: boolean;

    public constructor(init?: Partial<SecurityFirmComplianceDocument>) { (Object as any).assign(this, init); }
}

export class BroadcastNotificationFilter implements IHasForVenueSecurityFirm
{
    public search: string;
    public forVenueId: number;
    public forSecurityFirmId: number;
    public forVenueEventId: number;
    public display: string;

    public constructor(init?: Partial<BroadcastNotificationFilter>) { (Object as any).assign(this, init); }
}

export enum ngtBroadcastOption
{
    EmailAndSms = 'EmailAndSms',
    Email = 'Email',
    Sms = 'Sms',
}

export class BroadcastNotification
{
    public broadcastNotificationId: number;
    public groupId: number;
    public venueId: number;
    public securityFirmId: number;
    public title: string;
    public message: string;
    public isSent: boolean;
    public resend: boolean;
    public onlySignedOn: boolean;
    public includeAssociatedUsers: boolean;
    public sendMode: ngtBroadcastOption;
    public subject: string;
    public shortMessage: string;
    public accessLevel0: boolean;
    public accessLevel1: boolean;
    public accessLevel2: boolean;
    public accessLevel3: boolean;
    public accessLevel4: boolean;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredDisplay: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public enteredByUser: User;
    public lastModifiedByUser: User;
    public broadcastNotificationUserRoleTypes: BroadcastNotificationUserRoleType[];
    public userRoleTypeSelections: UserRoleTypeSelection[];
    public userRoleTypesDisplay: string;

    public constructor(init?: Partial<BroadcastNotification>) { (Object as any).assign(this, init); }
}

export class BroadcastNotificationUserRoleType
{
    public broadcastNotificationUserRoleTypeId: number;
    public broadcastNotificationId: number;
    public userRoleTypeId: number;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public broadcastNotification: BroadcastNotification;
    public userRoleType: UserRoleType;
    public enteredByUser: User;
    public lastModifiedByUser: User;

    public constructor(init?: Partial<BroadcastNotificationUserRoleType>) { (Object as any).assign(this, init); }
}

export class IncidentActionTemplateFilter
{
    public venueId: number;
    public groupId: number;
    public paging: Paging;
    public search: string;
    public showObsolete: boolean;
    public display: string;

    public constructor(init?: Partial<IncidentActionTemplateFilter>) { (Object as any).assign(this, init); }
}

export class Folder
{
    public uniqueId: string;
    public folderId: number;
    public entityId: number;
    public parentFolderId: number;
    public documentCategoryId: number;
    public name: string;
    public path: string;
    public compliance: boolean;
    public required: boolean;
    public locked: boolean;
    public canAddSubFolders: boolean;
    public canEdit: boolean;
    public canDelete: boolean;
    public count: number;
    public obsolete: boolean;
    public expiredCount: number;
    public countIncludingSubFolders: number;
    public isDefault: boolean;
    public folders: Folder[];

    public constructor(init?: Partial<Folder>) { (Object as any).assign(this, init); }
}

export class Document
{
    public folderUniqueId: string;
    public folderId: number;
    public folder: Folder;
    public documentId: number;
    public parentId: number;
    public entityId: number;
    public entityType: ngtEntityType;
    public expires: SimpleDate;
    public expired: boolean;
    public visibleToVenue: boolean;
    public locked: boolean;
    public extension: string;
    public originalFileName: string;
    public mimeType: string;
    public filePath: string;
    public filePreviewPath: string;
    public filePreviewText: string;
    public fileDisplayPath: string;
    public isImage: boolean;
    public obsolete: boolean;
    public enteredBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public lastModifiedBy: number;

    public constructor(init?: Partial<Document>) { (Object as any).assign(this, init); }
}

export class Directory
{
    public id: number;
    public entityType: ngtEntityType;
    public folders: Folder[];
    public allFolders: Folder[];
    public documents: Document[];

    public constructor(init?: Partial<Directory>) { (Object as any).assign(this, init); }
}

export class DocumentUpload
{
    public entityType: ngtEntityType;
    public id: number;
    public documentTypeId: number;
    public visibleToVenue: boolean;
    public expires: SimpleDate;
    public files: File[];

    public constructor(init?: Partial<DocumentUpload>) { (Object as any).assign(this, init); }
}

export class VenueOtherDocument
{
    public venueDocumentId: number;
    public venueOtherDocumentId: number;
    public venueId: number;
    public extension: string;
    public originalFileName: string;
    public canvasRescaled: boolean;
    public obsolete: boolean;
    public enteredBy: number;
    public mimeType: string;
    public filePath: string;
    public filePreviewPath: string;
    public filePreviewText: string;
    public fileDisplayPath: string;
    public isImage: boolean;
    public documentTypeId: number;
    public originalDocumentTypeId: number;
    public documentType: string;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public lastModifiedBy: number;

    public constructor(init?: Partial<VenueOtherDocument>) { (Object as any).assign(this, init); }
}

export class VenueComplianceDocument
{
    public venueDocumentId: number;
    public venueComplianceDocumentId: number;
    public venueId: number;
    public extension: string;
    public originalFileName: string;
    public canvasRescaled: boolean;
    public obsolete: boolean;
    public enteredBy: number;
    public mimeType: string;
    public filePath: string;
    public filePreviewPath: string;
    public filePreviewText: string;
    public fileDisplayPath: string;
    public isImage: boolean;
    public documentTypeId: number;
    public originalDocumentTypeId: number;
    public documentType: string;
    public licenceNumber: string;
    public dateExpiry: string;
    public dateOfExpiry: SimpleDate;
    public hasExpired: boolean;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public lastModifiedBy: number;

    public constructor(init?: Partial<VenueComplianceDocument>) { (Object as any).assign(this, init); }
}

export class VenueContactType
{
    public venueContactTypeId: number;
    public name: string;
    public rank: number;
    public obsolete: boolean;
    public dateEntered: string;
    public dateEnteredUtc: string;

    public constructor(init?: Partial<VenueContactType>) { (Object as any).assign(this, init); }
}

export class VenueContact
{
    public venueContactId: number;
    public venueContactTypeId: number;
    public venueId: number;
    public userId: number;
    public firstname: string;
    public lastname: string;
    public phone: string;
    public email: string;
    public primaryContact: boolean;
    public secondaryContact: boolean;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public user: User;
    public venueContactType: VenueContactType;
    public displayName: string;

    public constructor(init?: Partial<VenueContact>) { (Object as any).assign(this, init); }
}

export class UserComplianceDocumentFilter implements IHasForVenueSecurityFirm
{
    public search: string;
    public includeAssociatedSecurityFirms: boolean;
    public forVenueId: number;
    public forSecurityFirmId: number;
    public forVenueEventId: number;
    public display: string;

    public constructor(init?: Partial<UserComplianceDocumentFilter>) { (Object as any).assign(this, init); }
}

export class UserComplianceDocumentSummary implements IUserDisplay
{
    public userDocumentId: number;
    public userComplianceDocumentId: number;
    public userId: number;
    public documentTypeId: number;
    public documentType: string;
    public expires: number;
    public dateExpiry: string;
    public editUserAllowed: boolean;
    public givenName: string;
    public surname: string;
    public nickname: string;
    public rsaNumber: string;
    public licenseNumber: string;
    public venue: string;
    public securityFirms: string;
    public licenceNumber: string;
    public userDisplayName: string;

    public constructor(init?: Partial<UserComplianceDocumentSummary>) { (Object as any).assign(this, init); }
}

export class UserComplianceDocumentMissingSummary implements IUserDisplay
{
    public userId: number;
    public documentTypeId: number;
    public documentType: string;
    public editUserAllowed: boolean;
    public givenName: string;
    public surname: string;
    public nickname: string;
    public rsaNumber: string;
    public licenseNumber: string;
    public licenceNumber: string;
    public userDisplayName: string;

    public constructor(init?: Partial<UserComplianceDocumentMissingSummary>) { (Object as any).assign(this, init); }
}

export class DocumentCategory
{
    public documentCategoryId: number;
    public name: string;

    public constructor(init?: Partial<DocumentCategory>) { (Object as any).assign(this, init); }
}

export class EntityType
{
    public entityTypeId: number;
    public name: string;
    public dateEntered: string;
    public dateEnteredUtc: string;

    public constructor(init?: Partial<EntityType>) { (Object as any).assign(this, init); }
}

export class VenueLocationFilter
{
    public venueId: number;
    public search: string;
    public showObsolete: boolean;
    public showCameras: boolean;
    public display: string;

    public constructor(init?: Partial<VenueLocationFilter>) { (Object as any).assign(this, init); }
}

export class IncidentTypeFilter
{
    public specific: boolean;
    public search: string;
    public showObsolete: boolean;
    public industryCategoryId: number;
    public display: string;

    public constructor(init?: Partial<IncidentTypeFilter>) { (Object as any).assign(this, init); }
}

export enum ngtVenueReportingPeriodScheduleType
{
    Recurring = 'Recurring',
    Holiday = 'Holiday',
    OneOff = 'OneOff',
    Daily = 'Daily',
}

export class VenueReportingPeriodSchedule
{
    public venueReportingPeriodScheduleId: number;
    public venueId: number;
    public name: string;
    public startMonth: number;
    public startMonthDisplay: string;
    public startDay: number;
    public endMonth: number;
    public endMonthDisplay: string;
    public endDay: number;
    public startWeekDay: number;
    public startWeekDayDisplay: string;
    public endWeekDay: number;
    public endWeekDayDisplay: string;
    public scheduleType: ngtVenueReportingPeriodScheduleType;
    public startDate: LocalDate;
    public endDate: LocalDate;
    public startHour: number;
    public startMinute: number;
    public startDisplay: string;
    public startDisplayZ: string;
    public endHour: number;
    public endMinute: number;
    public endDisplay: string;
    public endDisplayZ: string;
    public active: boolean;
    public securityCodeNotRequired: boolean;
    public expiresDate: LocalDate;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEnteredUtc: string;
    public dateModifiedUtc: string;

    public constructor(init?: Partial<VenueReportingPeriodSchedule>) { (Object as any).assign(this, init); }
}

export class SecurityFirmSubscription
{
    public securityFirmId: number;
    public securityFirmSubscriptionId: number;
    public dateEntered: SimpleDate;
    public paywayUrl: string;
    public cutOffDate: SimpleDate;
    public active: boolean;
    public adminNotes: string;
    public suspendUserRoles: boolean;
    public unsuspendUserRoles: boolean;
    public checklists: boolean;
    public canViewAssociatedSignOnRegister: boolean;
    public eventVenues: boolean;
    public roleTypeNotifications: boolean;
    public notebook: boolean;

    public constructor(init?: Partial<SecurityFirmSubscription>) { (Object as any).assign(this, init); }
}

export class SecurityFirmSubscriptionSummary
{
    public securityFirmId: number;
    public securityFirmSubscriptionId: number;
    public paywayUrl: string;

    public constructor(init?: Partial<SecurityFirmSubscriptionSummary>) { (Object as any).assign(this, init); }
}

export class SecurityFirmAssociation
{
    public securityFirmAssociationId: number;
    public securityFirmId: number;
    public securityFirm: string;
    public parentSecurityFirmId: number;
    public parentSecurityFirm: string;
    public mlhNominatedPerson: string;
    public masterLicenceNumber: string;
    public masterLicenceExpiryDisplay: string;
    public address: string;
    public suburb: string;
    public stateId: number;
    public stateName: string;
    public stateDisplayName: string;
    public postcode: string;
    public phone: string;
    public abn: string;
    public website: string;
    public securityLicenceClassId: number;
    public securityLicenceClassDisplay: string;
    public dateLastSLEDCheckDisplay: string;
    public managerName: string;
    public dateEnteredUtc: string;
    public dateModifiedUtc: string;
    public dateEnteredDisplay: string;
    public dateModifiedDisplay: string;

    public constructor(init?: Partial<SecurityFirmAssociation>) { (Object as any).assign(this, init); }
}

export class SecurityFirmSelection
{
    public securityFirmId: number;
    public name: string;

    public constructor(init?: Partial<SecurityFirmSelection>) { (Object as any).assign(this, init); }
}

export class VenueSubscription
{
    public venueId: number;
    public venueSubscriptionId: number;
    public dateEntered: SimpleDate;
    public paywayUrl: string;
    public cutOffDate: SimpleDate;
    public active: boolean;
    public industryCategoryId: number;
    public adminNotes: string;
    public suspendUserRoles: boolean;
    public unsuspendUserRoles: boolean;
    public checklists: boolean;
    public canViewSecurityFirmSignOnRegister: boolean;
    public useLicensedIncidentReport: boolean;
    public roleTypeNotifications: boolean;
    public facialRecognitionNotification: boolean;
    public idCheck: boolean;
    public incidentActions: boolean;
    public groupIndustries: boolean;
    public correctionalFacility: boolean;
    public autoApproval: boolean;
    public ocrProcessing: boolean;
    public commLogs: boolean;
    public notebook: boolean;
    public roublerRoster: boolean;
    public cashPlayTrax: boolean;
    public cashPlayTraxSms: boolean;
    public subLocations: boolean;
    public eventVenues: boolean;
    public roller: boolean;
    public incidentUniqueID: boolean;
    public cashPlayTraxId: string;

    public constructor(init?: Partial<VenueSubscription>) { (Object as any).assign(this, init); }
}

export class VenueSubscriptionSummary
{
    public venueId: number;
    public venueSubscriptionId: number;
    public paywayUrl: string;

    public constructor(init?: Partial<VenueSubscriptionSummary>) { (Object as any).assign(this, init); }
}

export class WebsiteProperties
{
    public adminEmail: string;
    public errorEmail: string;
    public nativeApplicationMinimumVersion: string;
    public enableSmsNotifications: boolean;
    public enableMetrics: boolean;

    public constructor(init?: Partial<WebsiteProperties>) { (Object as any).assign(this, init); }
}

export class IndustryCategoriesFilter
{
    public search: string;
    public showObsolete: boolean;
    public forVenueId: number;
    public forGroupId: number;
    public display: string;

    public constructor(init?: Partial<IndustryCategoriesFilter>) { (Object as any).assign(this, init); }
}

export class IncidentVersion
{
    public incidentSerialNumber: string;
    public isAvailable: boolean;
    public incidentModificationCount: number;
    public incidentLastModifiedBy: number;
    public incidentSecurityFirmId: number;
    public incidentSequentialIncidentId: number;
    public incidentSequentialSubIncidentId: number;
    public incidentDateEntered: string;
    public incidentDateEnteredUtc: string;
    public incidentRelevantID: number;
    public venueId: number;
    public securityFirmId: number;
    public originalIncidentID: number;
    public instigatorIncidentID: number;
    public relevantIncidentID: number;
    public incidentID: number;
    public receiveDescendantPropagations: boolean;
    public obsolete: boolean;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public lastModifiedBy: number;
    public isRelevant: boolean;
    public securityFirmName: string;
    public venueName: string;

    public constructor(init?: Partial<IncidentVersion>) { (Object as any).assign(this, init); }
}

export class NotificationGroup
{
    public notificationGroupId: number;
    public title: string;
    public count: number;
    public notifications: Notification[];
    public sort: number;

    public constructor(init?: Partial<NotificationGroup>) { (Object as any).assign(this, init); }
}

export class KeyValue
{
    public key: string;
    public value: string;
    public obsolete: boolean;
    public isSelected: boolean;

    public constructor(init?: Partial<KeyValue>) { (Object as any).assign(this, init); }
}

export class PrintOptions
{
    public option: string;
    public imageOption: string;
    public ccUser: KeyValue;
    public ccVenue: KeyValue;
    public ccSecurityFirm: KeyValue;
    public recipients: KeyValue[];
    public ccUserSelected: boolean;
    public ccVenueSelected: boolean;
    public ccSecurityFirmSelected: boolean;
    public selectedRecipients: KeyValue[];
    public requireSecurityFirmCCForExtraRecipients: boolean;
    public requireVenueCCForExtraRecipients: boolean;

    public constructor(init?: Partial<PrintOptions>) { (Object as any).assign(this, init); }
}

export class VenuePersonnel
{
    public userRoleId: number;
    public venueId: number;
    public venue: string;
    public securityFirmId: number;
    public securityFirm: string;
    public userId: number;
    public givenName: string;
    public surname: string;
    public nickName: string;
    public onDuty: boolean;
    public usageCount: number;
    public multiplicity: boolean;
    public isPopular: boolean;
    public accessLevelHelper: number;
    public display: string;

    public constructor(init?: Partial<VenuePersonnel>) { (Object as any).assign(this, init); }
}

export class IncidentOptions
{
    public timezones: TimezoneData[];
    public incidentTypes: IncidentType[];
    public incidentTypeCategoryTypes: IncidentTypeCategoryTypes[];
    public venueLocations: VenueLocation[];
    public flags: IncidentFlagType[];
    public personnel: VenuePersonnel[];
    public relatedIncidents: RelatedIncident[];
    public checklistUserSelections: ChecklistUserSelection[];
    public radioChannels: RadioChannel[];
    public incidentPatronInvolvements: NamedValue[];
    public incidentPatronGenders: NamedValue[];
    public incidentPatronAgeRanges: NamedValue[];
    public incidentPatronHeights: NamedValue[];
    public incidentPatronWeights: NamedValue[];
    public incidentPatronBuilds: NamedValue[];
    public incidentPatronRacialAppearances: NamedValue[];
    public incidentPatronClothingTops: NamedValue[];
    public incidentPatronClothingTopColours: NamedValue[];
    public incidentPatronClothingBottoms: NamedValue[];
    public incidentPatronClothingBottomColours: NamedValue[];
    public incidentPatronHairColours: NamedValue[];
    public incidentPatronFacialHairs: NamedValue[];
    public incidentPatronEyeColours: NamedValue[];
    public incidentPatronComplexions: NamedValue[];
    public incidentPatronStates: NamedValue[];
    public incidentPatronCountries: NamedValue[];
    public allIncidentTypeSubLocations: boolean;

    public constructor(init?: Partial<IncidentOptions>) { (Object as any).assign(this, init); }
}

export class IncidentTypeStatistics
{
    public incidentTypeId: number;
    public name: string;
    public rank: number;
    public total: number;
    public numberInvolved: number;
    public excludeFromSnapShotIfZero: boolean;

    public constructor(init?: Partial<IncidentTypeStatistics>) { (Object as any).assign(this, init); }
}

export class IncidentCategoryTypeStatistics
{
    public incidentTypeId: number;
    public incidentTypeName: string;
    public incidentCategoryTypeId: number;
    public incidentCategoryTypeName: string;
    public rank: number;
    public total: number;
    public numberInvolved: number;

    public constructor(init?: Partial<IncidentCategoryTypeStatistics>) { (Object as any).assign(this, init); }
}

export class ChecklistStatistics
{
    public checklistTemplateId: number;
    public checklistTemplateName: string;
    public total: number;

    public constructor(init?: Partial<ChecklistStatistics>) { (Object as any).assign(this, init); }
}

export class SnapshotSummary
{
    public venueId: number;
    public incidentTypeStatistics: IncidentTypeStatistics[];
    public incidentCategoryTypeStatistics: IncidentCategoryTypeStatistics[];
    public checklistStatistics: ChecklistStatistics[];
    public timeZoneDate: TimeZoneDate;

    public constructor(init?: Partial<SnapshotSummary>) { (Object as any).assign(this, init); }
}

export class FunctionalAreaSelection
{
    public functionalAreaId: number;
    public name: string;
    public obsolete: boolean;
    public selected: boolean;

    public constructor(init?: Partial<FunctionalAreaSelection>) { (Object as any).assign(this, init); }
}

export class IncidentHistoryFilter
{
    public incidentId: number;
    public byIncidentSerialNumber: boolean;
    public byIncidentSerialNumberParts: boolean;
    public incidentSerialNumber: string;
    public incidentSerialNumberVenue: string;
    public incidentSerialNumberSecurityFirm: string;
    public incidentSerialNumberSequence: string;
    public incidentUniqueID: string;
    public forEntryPage: string;
    public reportingPeriods: ReportingPeriodSelection[];
    public idChecksOnly: boolean;
    public incidentTypes: IncidentTypeSelection[];
    public reportingPeriodId: number;
    public byReportingPeriod: boolean;
    public advanced: boolean;
    public exportIncidentDetailedLimit: number;
    public functionalAreas: FunctionalAreaSelection[];
    public from: SearchDate;
    public to: SearchDate;
    public paging: Paging;
    public unapprovedOnly: boolean;
    public legislated: boolean;
    public includeIDChecks: boolean;
    public withPhoto: boolean;
    public search: string;
    public searchOnlyUniqueIDs: boolean;
    public canFilterReportingPeriod: boolean;
    public canFilterAdvanced: boolean;
    public canViewIDChecks: boolean;
    public byEvent: boolean;
    public byEventReportingPeriods: boolean;
    public eventReportingPeriods: MultiSelectOption[];
    public venueEvents: MultiSelectOption[];
    public canViewEvents: boolean;
    public canView: boolean;
    public canEdit: boolean;
    public canApprove: boolean;
    public canPrint: boolean;
    public canPrintSummaryAndDetails: boolean;
    public canPrintSummaryAndDetailedVersion: boolean;
    public imageOption: string;
    public display: string;
    public forCountOnly: boolean;

    public constructor(init?: Partial<IncidentHistoryFilter>) { (Object as any).assign(this, init); }
}

export enum ngtReportGenerationStatus
{
    Requested = 'Requested',
    Generating = 'Generating',
    Ready = 'Ready',
    Error = 'Error',
    Cancelled = 'Cancelled',
    Dismissed = 'Dismissed',
    Downloaded = 'Downloaded',
}

export class ReportGeneration
{
    public reportGenerationId: number;
    public title: string;
    public reportLayout: string;
    public userId: number;
    public venueId: number;
    public securityFirmId: number;
    public status: ngtReportGenerationStatus;
    public duration: string;
    public path: string;
    public error: string;
    public dateEnteredUtc: string;
    public dateStartedUtc: string;
    public dateCompletedUtc: string;
    public dateDownloadedUtc: string;
    public downloadToken: string;

    public constructor(init?: Partial<ReportGeneration>) { (Object as any).assign(this, init); }
}

export class ReportOptionColumnHeader
{
    public title: string;
    public width: number;
    public source: string;
    public isMultiline: boolean;

    public constructor(init?: Partial<ReportOptionColumnHeader>) { (Object as any).assign(this, init); }
}

export class ReportOptionCell
{
    public value: string;

    public constructor(init?: Partial<ReportOptionCell>) { (Object as any).assign(this, init); }
}

export class ReportOptionRow
{
    public cells: ReportOptionCell[];

    public constructor(init?: Partial<ReportOptionRow>) { (Object as any).assign(this, init); }
}

export class ReportOptions
{
    public landscape: boolean;
    public html: string;
    public title: string;
    public subject: string;
    public venue: string;
    public securityFirm: string;
    public generateDocumentOnly: boolean;
    public email: boolean;
    public emailRecipients: string[];
    public bccMyself: boolean;
    public data: Object;
    public columns: ReportOptionColumnHeader[];
    public rows: ReportOptionRow[];
    public extraDetails: string[];

    public constructor(init?: Partial<ReportOptions>) { (Object as any).assign(this, init); }
}

export class Permission
{
    public permissionId: ngtUserPermission;
    public name: string;
    public code: string;
    public rank: number;
    public obsolete: boolean;
    public minAccessLevel: number;
    public maxAccessLevel: number;
    public accessLevelRange: string;

    public constructor(init?: Partial<Permission>) { (Object as any).assign(this, init); }
}

export class UserRoleTypeFilter implements IHasForVenueSecurityFirm
{
    public globalRoles: boolean;
    public search: string;
    public deleted: boolean;
    public industryCategoryId: number;
    public isGroupRole: boolean;
    public isVenueRole: boolean;
    public isSecurityRole: boolean;
    public isMixedVenueAndSecurityRole: boolean;
    public forVenueId: number;
    public forSecurityFirmId: number;
    public forVenueEventId: number;
    public forGroupId: number;
    public showObsolete: boolean;
    public display: string;

    public constructor(init?: Partial<UserRoleTypeFilter>) { (Object as any).assign(this, init); }
}

export class UserRoleTypeSummary
{
    public userRoleTypeId: number;
    public venue: boolean;
    public security: boolean;
    public baseName: string;
    public name: string;
    public accessLevel: number;
    public stateName: string;
    public defaultRole: boolean;
    public canBeAssigned: boolean;
    public userRolesCount: number;
    public obsolete: boolean;
    public isGlobalRole: boolean;
    public dateEntered: string;
    public dateEnteredUtc: string;

    public constructor(init?: Partial<UserRoleTypeSummary>) { (Object as any).assign(this, init); }
}

export class SecurityLicenceClass
{
    public securityLicenceClassId: number;
    public code: string;
    public name: string;
    public rank: number;
    public obsolete: boolean;

    public constructor(init?: Partial<SecurityLicenceClass>) { (Object as any).assign(this, init); }
}

export class LicenceCheckResult
{
    public sledStatus: ngtSLEDStatus;
    public isCurrentLicence: boolean;
    public invalidLicenceNumber: boolean;
    public licenceNumber: string;
    public expiryDate: string;
    public securityLicenceClasses: SecurityLicenceClass[];

    public constructor(init?: Partial<LicenceCheckResult>) { (Object as any).assign(this, init); }
}

export class PersonnelLicenceCheckResult extends LicenceCheckResult
{
    public firstName: string;
    public middleName: string;
    public lastName: string;

    public constructor(init?: Partial<PersonnelLicenceCheckResult>) { super(init); (Object as any).assign(this, init); }
}

export class SecurityFirmLicenceCheckResult extends LicenceCheckResult
{
    public tradingName: string;
    public maxLicenceClassID: number;

    public constructor(init?: Partial<SecurityFirmLicenceCheckResult>) { super(init); (Object as any).assign(this, init); }
}

export class SignOnRegisterSignOnRegisterType
{
    public signOnRegisterSignOnRegisterTypeId: number;
    public signOnRegisterId: number;
    public signOnRegisterTypeId: number;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public signOnRegisterType: SignOnRegisterType;
    public signOnRegister: SignOnRegister;
    public enteredByUser: User;
    public lastModifiedByUser: User;

    public constructor(init?: Partial<SignOnRegisterSignOnRegisterType>) { (Object as any).assign(this, init); }
}

export class SignOnRegister
{
    public venueId: number;
    public securityFirmId: number;
    public venueEventId: number;
    public userId: number;
    public userRoleId: number;
    public password: string;
    public guardNumber: string;
    public supervisorRequired: boolean;
    public supervisorUserId: number;
    public latitude: number;
    public longitude: number;
    public rosteredStart: string;
    public shiftHours: number;
    public shiftMinutes: number;
    public signOnRegisterBreaks: SignOnRegisterBreak[];
    public signOnRegisterSignOnRegisterTypes: SignOnRegisterSignOnRegisterType[];
    public selectedSignOnRegisterTypesDisplay: string;
    public selectedSignOnRegisterTypes: number[];
    public signOnRegisterTypes: SignOnRegisterType[];

    public constructor(init?: Partial<SignOnRegister>) { (Object as any).assign(this, init); }
}

export class SignOnRegisterBreak
{
    public signOnRegisterBreakId: number;
    public signOnRegisterId: number;
    public startDate: TimeZoneDate;
    public startDateUtc: string;
    public endDate: TimeZoneDate;
    public endDateUtc: string;
    public notes: string;
    public externalStartId: number;
    public externalStopId: number;
    public obsolete: boolean;
    public enteredBy: number;
    public lastModifiedBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public signOnRegister: SignOnRegister;
    public enteredByUser: User;
    public lastModifiedByUser: User;

    public constructor(init?: Partial<SignOnRegisterBreak>) { (Object as any).assign(this, init); }
}

export class UserSignOnStatus
{
    public signOnRegisterId: number;
    public venueId: number;
    public venue: string;
    public securityFirmId: number;
    public securityFirm: string;
    public venueEventId: number;
    public venueEvent: string;
    public securityFirmAddress: string;
    public userId: number;
    public givenName: string;
    public surname: string;
    public nickname: string;
    public guardNum: string;
    public licenceNumber: string;
    public supervisorRequired: boolean;
    public supervisorUserRoleId: number;
    public supervisorUserId: number;
    public supervisorFullname: string;
    public password: string;
    public userRoleId: number;
    public userSignedOn: boolean;
    public rosteredStartTimeZoneDate: TimeZoneDate;
    public rosteredStart: string;
    public rosteredStartDate: string;
    public rosteredStartDateUtc: string;
    public shiftHours: number;
    public shiftMinutes: number;
    public extendHours: number;
    public extendMinutes: number;
    public signOnDate: string;
    public signOnDateUtc: string;
    public signOffDate: string;
    public signOffDateUtc: string;
    public signOnTimezone: string;
    public signOffTimezone: string;
    public latitude: number;
    public longitude: number;
    public rosterItemId: number;
    public roster: Roster;
    public isOnBreak: boolean;
    public breakDisplay: string;
    public isAcknowledged: boolean;
    public isOverdue: boolean;
    public expectedSignOffDateUtc: string;
    public isSignedOn: boolean;
    public rosteredStartFormatted: string;
    public rosteredStartDateFormatted: string;
    public rosteredStartTimeFormatted: string;
    public rosteredFinishFormatted: string;
    public rosteredFinishDateFormatted: string;
    public rosteredFinishTimeFormatted: string;
    public signOnDateFormated: string;
    public signOnDateOnlyFormated: string;
    public signOnTimeOnlyFormated: string;
    public signOffDateFormated: string;
    public signOffDateOnlyFormated: string;
    public signOffTimeOnlyFormated: string;
    public durationFormatted: string;
    public duration: number;
    public actualDuration: number;
    public actualDurationFormatted: string;
    public shiftEndsDisplay: string;
    public shiftEndsShortDisplay: string;
    public signOnRegisterBreaks: SignOnRegisterBreak[];
    public signOnRegisterSignOnRegisterTypes: SignOnRegisterSignOnRegisterType[];
    public selectedSignOnRegisterTypesDisplay: string;
    public selectedSignOnRegisterTypes: number[];
    public signOnRegisterTypes: SignOnRegisterType[];

    public constructor(init?: Partial<UserSignOnStatus>) { (Object as any).assign(this, init); }
}

export class UserSignOnCheck
{
    public isSignedOnToADifferentVenue: boolean;
    public canSignOnToMultipleVenues: boolean;

    public constructor(init?: Partial<UserSignOnCheck>) { (Object as any).assign(this, init); }
}

export class SignOff
{
    public userId: number;
    public userRoleId: number;
    public password: string;
    public latitude: number;
    public longitude: number;
    public signOnRegisterId: number;
    public isAcknowledged: boolean;
    public signOutDate: string;

    public constructor(init?: Partial<SignOff>) { (Object as any).assign(this, init); }
}

export class SignOnExtend
{
    public signOnRegisterId: number;
    public password: string;
    public hours: number;
    public minutes: number;

    public constructor(init?: Partial<SignOnExtend>) { (Object as any).assign(this, init); }
}

export class SignOnRegisterViewOption
{
    public signOnRegisterViewOptionId: number;
    public name: string;

    public constructor(init?: Partial<SignOnRegisterViewOption>) { (Object as any).assign(this, init); }
}

export class SignOnRegisterFilter
{
    public signOnRegisterViewOptionId: number;
    public options: SignOnRegisterViewOption[];
    public byReportingPeriod: boolean;
    public reportingPeriodId: number;
    public reportingPeriods: ReportingPeriodSelection[];
    public from: SearchDate;
    public to: SearchDate;
    public search: string;
    public paging: Paging;
    public showAcknowledged: boolean;
    public showSupervisor: boolean;
    public isSecurityRole: boolean;
    public showActuals: boolean;
    public signOnRegisterTypes: NamedValue[];
    public display: string;

    public constructor(init?: Partial<SignOnRegisterFilter>) { (Object as any).assign(this, init); }
}

export class VenueIncidentCategoryType
{
    public incidentCategoryTypeID: number;
    public name: string;
    public code: string;
    public regulatedWalkthrough: boolean;
    public usageCount: number;
    public rank: number;
    public requiresPersonDescription: boolean;

    public constructor(init?: Partial<VenueIncidentCategoryType>) { (Object as any).assign(this, init); }
}

export class IncidentCategoryTypeFilter
{
    public specific: boolean;
    public search: string;
    public showObsolete: boolean;
    public showHidden: boolean;
    public hideCustom: boolean;
    public hideSystem: boolean;
    public venueId: number;
    public securityFirmId: number;
    public display: string;

    public constructor(init?: Partial<IncidentCategoryTypeFilter>) { (Object as any).assign(this, init); }
}

export class VenueIncidentCategoryTypeUsage
{
    public incidentCategoryTypeID: number;
    public name: string;
    public usageCount: number;

    public constructor(init?: Partial<VenueIncidentCategoryTypeUsage>) { (Object as any).assign(this, init); }
}

export class NotificationType
{
    public notificationTypeId: number;
    public notificationSubTypeId: number;
    public notificationCategoryId: number;
    public code: string;
    public minAccessLevelToNotify: number;
    public maxAccessLevelToNotify: number;
    public requiresAction: boolean;
    public notificationMessageString: string;
    public disabled: boolean;
    public obsolete: boolean;
    public description: string;
    public additionHours: number;
    public alert: boolean;
    public isPush: boolean;
    public notificationGroupId: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;

    public constructor(init?: Partial<NotificationType>) { (Object as any).assign(this, init); }
}

export class Timezone
{
    public timezoneId: number;
    public abbreviation: string;
    public name: string;
    public gmtOffset: number;
    public dst: boolean;
    public dateEnteredUtc: string;

    public constructor(init?: Partial<Timezone>) { (Object as any).assign(this, init); }
}

export enum ngtUserActionType
{
    Login = 'Login',
    VenueLogin = 'VenueLogin',
    VenueRequestAccess = 'VenueRequestAccess',
    VenueSecurityFirmSelection = 'VenueSecurityFirmSelection',
}

export class UserActionAudit
{
    public userActionAuditId: number;
    public userId: number;
    public actionType: ngtUserActionType;
    public device: string;
    public os: string;
    public make: string;
    public model: string;
    public appVersion: string;
    public version: string;
    public latitude: number;
    public longitude: number;
    public venueId: number;
    public venue: string;
    public securityFirmId: number;
    public securityFirm: string;
    public venueEventId: number;
    public venueEvent: string;
    public locationFound: boolean;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateEnteredUtcDisplay: string;
    public dateEnteredDisplay: string;
    public actionTypeDisplay: string;
    public display: string;

    public constructor(init?: Partial<UserActionAudit>) { (Object as any).assign(this, init); }
}

export class UserRoleFilter
{
    public search: string;
    public firstName: string;
    public lastName: string;
    public loggedInOnly: boolean;
    public loggedInOnlyForEvent: boolean;
    public removed: boolean;
    public forUserId: number;
    public forGroupId: number;
    public forVenueId: number;
    public forSecurityFirmId: number;
    public forVenueEventId: number;
    public includeAssociated: boolean;
    public userRoleTypeId: number;
    public includeLoggedInStatus: boolean;
    public removeNeverLoggedInSecurity: boolean;
    public display: string;

    public constructor(init?: Partial<UserRoleFilter>) { (Object as any).assign(this, init); }
}

export class LoggedInUser
{
    public userId: number;
    public userRoleId: number;
    public userRole: string;
    public givenName: string;
    public surname: string;
    public nickname: string;
    public email: string;
    public avatar: File;
    public device: string;
    public displayName: string;

    public constructor(init?: Partial<LoggedInUser>) { (Object as any).assign(this, init); }
}

export class UserView
{
    public userId: number;
    public isVenueStaff: boolean;
    public isSecurityStaff: boolean;
    public givenName: string;
    public surname: string;
    public nickname: string;
    public genderId: number;
    public email: string;
    public dateLastSledCheck: string;
    public dateLastSledCheckUtc: string;
    public dateVerifiedUtc: string;
    public dateLastSledCheckDisplay: string;
    public sledStatus: ngtSLEDStatus;
    public sledStatusDisplay: string;
    public sledChecked: boolean;
    public sledValid: boolean;
    public sledFailed: boolean;
    public sledEnabled: boolean;
    public stateLicensedId: number;
    public stateLicensed: string;
    public sledExpiryDate: string;
    public sledExpiryDateDisplay: string;
    public licenceNumber: string;
    public licenceNumberChecked: string;
    public rsaNumber: string;
    public dob: string;
    public dateOfBirth: SimpleDate;
    public address: string;
    public suburb: string;
    public postcode: string;
    public mobileNo: string;
    public stateId: number;
    public state: string;
    public emergencyContactName: string;
    public emergencyContactNumber: string;
    public hasAcceptedTerms: boolean;
    public displayName: string;
    public isLoggedIn: boolean;
    public isVerified: boolean;

    public constructor(init?: Partial<UserView>) { (Object as any).assign(this, init); }
}

export enum ngtUserRegistrationType
{
    VenueStaff = 'VenueStaff',
    SecurityStaff = 'SecurityStaff',
    Other = 'Other',
    RSAMarshal = 'RSAMarshal',
}

export class UserFilter
{
    public search: string;
    public firstName: string;
    public lastName: string;
    public loggedInOnly: boolean;
    public removed: boolean;
    public display: string;

    public constructor(init?: Partial<UserFilter>) { (Object as any).assign(this, init); }
}

export enum ngtUserRoleTypes
{
    None = 'None',
    Venue = 'Venue',
    Security = 'Security',
    VenueAndSecurity = 'VenueAndSecurity',
    Group = 'Group',
}

export class GroupUserSummary
{
    public groupId: number;
    public userId: number;
    public userRoleId: number;
    public user: string;
    public role: string;
    public group: string;
    public permissions: ngtUserPermission[];

    public constructor(init?: Partial<GroupUserSummary>) { (Object as any).assign(this, init); }
}

export class ngtUser
{
    public isApiUser: boolean;
    public userID: number;
    public firstName: string;
    public lastName: string;
    public nickName: string;
    public email: string;
    public mobileNo: string;
    public licenceNumber: string;
    public groupId: number;
    public venueId: number;
    public securityFirmId: number;
    public venue: string;
    public securityFirm: string;
    public venueEvent: string;
    public accessLevel: number;
    public userRoleTypes: number;
    public venueHasGroups: boolean;
    public requiresUpdate: boolean;
    public hasAcceptedTerms: boolean;
    public requiresSecurityCode: boolean;
    public showSecurityCode: boolean;
    public bypassSecurityCode: boolean;
    public requiresValidSLED: boolean;
    public pendingInvalidSLEDAccess: boolean;
    public cutOff: boolean;
    public impersonateUserId: boolean;
    public impersonate: boolean;
    public verified: boolean;
    public sledStatus: ngtSLEDStatus;
    public sledExpiryDate: string;
    public hasAnyPermissions: boolean;
    public showVenueMessageId: string;
    public maintenanceMessage: string;
    public trainingWebsite: string;
    public activityMonitorTimeout: number;
    public venueEventId: number;
    public userRoleType: ngtUserRoleTypes;
    public noVenueAndNoSecurity: boolean;
    public roles: string[];
    public initialLogin: boolean;
    public groups: GroupUserSummary[];

    public constructor(init?: Partial<ngtUser>) { (Object as any).assign(this, init); }
}

export class UserSession
{
    public userAuthId: string;
    public userId: number;
    public userName: string;
    public venueId: number;
    public securityFirmId: number;
    public venueEventId: number;
    public accessLevel: number;
    public permissions: ngtUserPermission[];
    public user: ngtUser;
    public sessionId: string;
    public isImpersonating: boolean;

    public constructor(init?: Partial<UserSession>) { (Object as any).assign(this, init); }
}

export class UserSignOnSummary
{
    public signOnRegisterId: number;
    public signOnEnabled: boolean;
    public isInRangeOfVenue: boolean;
    public hasOpenReportingPeriod: boolean;
    public currentVenueReportingPeriodId: number;
    public currentReportingEndTimeFormatted: string;
    public canStartReportingPeriod: boolean;
    public canViewSignOnRegister: boolean;
    public canViewSignOnOffRegister: boolean;
    public canSignOn: boolean;
    public venueId: number;
    public venue: string;
    public securityFirmId: number;
    public userId: number;
    public givenName: string;
    public surname: string;
    public nickname: string;
    public guardNum: string;
    public userRoleId: number;
    public isOverdue: boolean;
    public overdueCount: number;
    public isSignedOn: boolean;
    public expectedSignOffDateUtc: string;
    public shiftEndsDisplay: string;
    public shiftEndsShortDisplay: string;
    public isOnBreak: boolean;
    public breakDisplay: string;

    public constructor(init?: Partial<UserSignOnSummary>) { (Object as any).assign(this, init); }
}

export class NavigationLink
{
    public navigationLinkId: string;
    public text: string;
    public url: string;
    public hideMobile: boolean;

    public constructor(init?: Partial<NavigationLink>) { (Object as any).assign(this, init); }
}

export class UserDetailSession
{
    public userSession: UserSession;
    public userSignOnSummary: UserSignOnSummary;
    public venueIsSelected: boolean;
    public securityFirmIsSelected: boolean;
    public isAndHasNothingSelected: boolean;
    public signOnOffRegister: boolean;
    public showSecurityCode: boolean;
    public showReportingPeriod: boolean;
    public showGamblingIncidentRegister: boolean;
    public showSmsCodeVerification: boolean;
    public securityCode: string;
    public avatar: File;
    public pushNotifications: boolean;
    public venueId: number;
    public venueEventId: number;
    public securityFirmId: number;
    public accessLevel: number;
    public permissions: ngtUserPermission[];
    public roles: ngtUserRoleTypes[];
    public name: string;
    public username: string;
    public email: string;
    public venues: NamedValue[];
    public securityFirms: NamedValue[];
    public navigationLinks: NavigationLink[];
    public userRoleRequests: UserRoleRequest[];
    public userRoleSummaries: UserRoleSummary[];

    public constructor(init?: Partial<UserDetailSession>) { (Object as any).assign(this, init); }
}

export class HomeStatistics
{
    public venueId: number;
    public securityFirmId: number;
    public venueName: string;
    public securityFirmName: string;
    public venueEventId: number;
    public venueEvent: string;
    public reportingPeriodEndTime: string;
    public reportingEndTimeFormatted: string;
    public notificationCount: number;
    public incidentCount: number;
    public rgoCount: number;
    public commsLogCount: number;
    public userCount: number;
    public checklistCount: number;
    public canViewUsers: boolean;
    public venueSecurityFirmSelectionRequired: boolean;
    public canViewChecklists: boolean;
    public canViewChecklistTemplates: boolean;
    public canViewDashboard: boolean;
    public incidentTypes: IncidentType[];

    public constructor(init?: Partial<HomeStatistics>) { (Object as any).assign(this, init); }
}

export class VenueWithRange
{
    public venueId: number;
    public name: string;
    public range: number;
    public inRange: boolean;
    public mostRecent: boolean;
    public venueEventId: number;
    public venueEvent: string;
    public display: string;

    public constructor(init?: Partial<VenueWithRange>) { (Object as any).assign(this, init); }
}

export class UserForSignOn
{
    public userId: number;
    public givenName: string;
    public surname: string;
    public nickname: string;
    public display: string;
    public userRoleId: number;
    public userRoleTypeId: number;
    public userRoleTypeName: string;
    public supervisorRequired: boolean;

    public constructor(init?: Partial<UserForSignOn>) { (Object as any).assign(this, init); }
}

export class UserSignOnStart
{
    public signOnEnabled: boolean;
    public isInRangeOfVenue: boolean;
    public allowOutOfRangeSignIn: boolean;
    public hasOpenReportingPeriod: boolean;
    public canStartReportingPeriod: boolean;
    public venueReportingPeriodId: number;
    public showRestrictedLicenseeOption: boolean;
    public guardNumberIsRequired: boolean;
    public canChangeUser: boolean;
    public isAlreadySignedOn: boolean;
    public currentUserSignOnStatus: UserSignOnStatus;
    public userSignOnStatus: UserSignOnStatus;
    public signOnUsers: UserForSignOn[];
    public supervisors: UserForSignOn[];

    public constructor(init?: Partial<UserSignOnStart>) { (Object as any).assign(this, init); }
}

export class ReportingPeriod
{
    public venueReportingPeriodId: number;
    public venueId: number;
    public startTime: string;
    public endTime: string;
    public securityCode: string;
    public closedBy: number;
    public dateClosed: string;
    public enteredBy: number;
    public dateEntered: string;
    public startTimeUtc: string;
    public endTimeUtc: string;

    public constructor(init?: Partial<ReportingPeriod>) { (Object as any).assign(this, init); }
}

export class VenueSecurityFirmSummary
{
    public venueSecurityFirmId: number;
    public securityFirmId: number;
    public venue: string;
    public securityFirm: string;
    public mlhNominatedPerson: string;
    public masterLicenceNumber: string;
    public masterLicenceExpiryDisplay: string;
    public address: string;
    public suburb: string;
    public stateId: number;
    public stateName: string;
    public stateDisplayName: string;
    public postcode: string;
    public phone: string;
    public abn: string;
    public website: string;
    public securityLicenceClassId: number;
    public securityLicenceClassDisplay: string;
    public dateLastSLEDCheckDisplay: string;
    public managerName: string;
    public obsolete: boolean;
    public dateEnteredUtc: string;
    public dateModifiedUtc: string;
    public dateEnteredDisplay: string;
    public dateModifiedDisplay: string;

    public constructor(init?: Partial<VenueSecurityFirmSummary>) { (Object as any).assign(this, init); }
}

export class SecurityFirmVenueSummary
{
    public venueSecurityFirmId: number;
    public venueId: number;
    public securityFirmId: number;
    public parentSecurityFirmId: number;
    public venue: string;
    public securityFirm: string;
    public address: string;
    public suburb: string;
    public stateId: number;
    public stateName: string;
    public stateDisplayName: string;
    public postcode: string;
    public phone: string;
    public abn: string;
    public website: string;
    public parentMLH: string;
    public contactName: string;
    public dateEnteredUtc: string;
    public dateModifiedUtc: string;
    public dateEnteredDisplay: string;
    public dateModifiedDisplay: string;

    public constructor(init?: Partial<SecurityFirmVenueSummary>) { (Object as any).assign(this, init); }
}

export class VenueSecurityFirm
{
    public venueId: number;
    public venueName: string;
    public venueSecurityFirmId: number;
    public securityFirmId: number;
    public securityFirmName: string;
    public parentSecurityFirmId: number;
    public parentSecurityFirmName: string;
    public obsolete: boolean;
    public enteredBy: number;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public enteredDisplay: string;
    public lastModifiedBy: number;
    public dateModified: string;
    public dateModifiedUtc: string;

    public constructor(init?: Partial<VenueSecurityFirm>) { (Object as any).assign(this, init); }
}

export class SecurityFirm
{
    public securityFirmId: number;
    public name: string;
    public suburb: string;
    public address: string;
    public postcode: string;
    public stateId: number;
    public stateDisplayName: string;
    public stateLicensedId: number;
    public stateLicensedDisplayName: string;
    public masterLicenceNumber: string;
    public originalMasterLicenceNumber: string;
    public mlhNominatedPerson: string;
    public securityLicenceClassId: number;
    public securityLicenceClassName: string;
    public masterLicenceExpiry: string;
    public masterLicenceExpiryDisplay: string;
    public masterLicenceExpiryDate: SimpleDate;
    public abn: string;
    public phone: string;
    public website: string;
    public exemptFromPayment: boolean;
    public obsolete: boolean;
    public registrationComplete: boolean;
    public adminNotes: string;
    public originalRegistrationComplete: boolean;
    public isActive: boolean;
    public managerOwnerName: string;
    public dateLastSledCheck: string;
    public dateLastSledCheckUtc: string;
    public dateLastSledCheckDisplay: string;
    public sledStatus: ngtSLEDStatus;
    public sledStatusDisplay: string;
    public sledChecked: boolean;
    public sledValid: boolean;
    public sledFailed: boolean;
    public sledEnabled: boolean;
    public canCheckSled: boolean;
    public canEditName: boolean;
    public canEditSecurityLicenceClass: boolean;
    public canEditLicenceNumber: boolean;
    public licenceNumberChecked: string;

    public constructor(init?: Partial<SecurityFirm>) { (Object as any).assign(this, init); }
}

export class SecurityFirmFilter
{
    public search: string;
    public display: string;

    public constructor(init?: Partial<SecurityFirmFilter>) { (Object as any).assign(this, init); }
}

export class VenueLicenceType
{
    public venueLicenceTypeId: number;
    public name: string;
    public licencePrice: number;
    public subscriptionPrice: number;
    public rank: number;
    public obsolete: boolean;
    public dateEntered: string;
    public dateEnteredUtc: string;

    public constructor(init?: Partial<VenueLicenceType>) { (Object as any).assign(this, init); }
}

export class VenuePrecinct
{
    public venuePrecinctId: number;
    public name: string;
    public subscriptionPrice: number;
    public rank: number;
    public obsolete: boolean;
    public dateEntered: string;
    public dateEnteredUtc: string;

    public constructor(init?: Partial<VenuePrecinct>) { (Object as any).assign(this, init); }
}

export class VenueTradingHours
{
    public venueTradingHoursId: number;
    public name: string;
    public subscriptionPrice: number;
    public rank: number;
    public obsolete: boolean;
    public dateEntered: string;
    public dateEnteredUtc: string;

    public constructor(init?: Partial<VenueTradingHours>) { (Object as any).assign(this, init); }
}

export class VenueFilter
{
    public search: string;
    public display: string;

    public constructor(init?: Partial<VenueFilter>) { (Object as any).assign(this, init); }
}

export class SecurityFirmDescendant
{
    public securityFirmDescendantID: number;
    public securityFirmId: number;
    public securityFirmName: string;
    public venueID: number;
    public venueName: string;
    public descendantSecurityFirmID: number;
    public descendantSecurityFirmName: string;
    public obsolete: boolean;
    public dateEntered: string;
    public dateEnteredUtc: string;
    public dateModified: string;
    public dateModifiedUtc: string;
    public lastModifiedBy: number;
    public enteredDisplay: string;

    public constructor(init?: Partial<SecurityFirmDescendant>) { (Object as any).assign(this, init); }
}

export interface IObsolete
{
    obsolete: boolean;
}

export interface ITimestamped
{
    dateEntered?: string;
    dateModified?: string;
}

export interface IUserDisplay
{
    givenName: string;
    surname: string;
    nickname: string;
    licenceNumber: string;
    rsaNumber: string;
}

export interface IChangeTracking
{
    obsolete: boolean;
    enteredBy?: number;
    lastModifiedBy?: number;
    dateEntered?: string;
    dateEnteredUtc?: string;
    dateModified?: string;
    dateModifiedUtc?: string;
}

export class GetExternalApiConnectionResponse
{
    public externalApiConnection: ExternalApiConnection;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetExternalApiConnectionResponse>) { (Object as any).assign(this, init); }
}

export class PostExternalApiConnectionRefreshResponse
{
    public externalApiConnection: ExternalApiConnection;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostExternalApiConnectionRefreshResponse>) { (Object as any).assign(this, init); }
}

export class PostExternalApiConnectionRemoveResponse
{
    public externalApiConnection: ExternalApiConnection;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostExternalApiConnectionRemoveResponse>) { (Object as any).assign(this, init); }
}

export class PostExternalApiConnectionResponse
{
    public externalApiConnection: ExternalApiConnection;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostExternalApiConnectionResponse>) { (Object as any).assign(this, init); }
}

export class GetRoublerEmployeeMappingResponse
{
    public roublerEmployeeMapping: RoublerEmployeeMapping;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetRoublerEmployeeMappingResponse>) { (Object as any).assign(this, init); }
}

export class PostRoublerEmployeeExportResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostRoublerEmployeeExportResponse>) { (Object as any).assign(this, init); }
}

export class GetRollerConfigurationResponse
{
    public rollerConfiguration: RollerConfiguration;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetRollerConfigurationResponse>) { (Object as any).assign(this, init); }
}

export class PostRollerConfigurationResponse
{
    public rollerConfiguration: RollerConfiguration;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostRollerConfigurationResponse>) { (Object as any).assign(this, init); }
}

export class PostExternalApiConnectionWebhookMessagesResponse
{
    public responseStatus: ResponseStatus;
    public data: ExternalApiConnectionWebhookMessage[];
    public paging: Paging;
    public filter: SimpleFilter;

    public constructor(init?: Partial<PostExternalApiConnectionWebhookMessagesResponse>) { (Object as any).assign(this, init); }
}

export class PostExternalApiConnectionWebhookMessageReProcessResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostExternalApiConnectionWebhookMessageReProcessResponse>) { (Object as any).assign(this, init); }
}

export class PostExternalApiConnectionWebhookMessagesReProcessResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostExternalApiConnectionWebhookMessagesReProcessResponse>) { (Object as any).assign(this, init); }
}

export class AnyExternalApiConnectionWebhookResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AnyExternalApiConnectionWebhookResponse>) { (Object as any).assign(this, init); }
}

export class PostCorrectionalFacilityInMateCellsResponse
{
    public data: CorrectionalFacilityInMateCell[];
    public availableCells: VenueLocation[];
    public risks: NamedValue[];
    public genders: NamedValue[];
    public nationalities: Nationality[];
    public riskStatuses: CorrectionalFacilityRiskStatus[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostCorrectionalFacilityInMateCellsResponse>) { (Object as any).assign(this, init); }
}

export class PostNewCorrectionalFacilityInMateCellsResponse
{
    public inmates: CorrectionalFacilityInMate[];
    public correctionalFacilityInMateCell: CorrectionalFacilityInMateCell;
    public availableCells: VenueLocation[];
    public risks: NamedValue[];
    public genders: NamedValue[];
    public nationalities: Nationality[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostNewCorrectionalFacilityInMateCellsResponse>) { (Object as any).assign(this, init); }
}

export class PostCorrectionalFacilityInMateCellResponse
{
    public responseStatus: ResponseStatus;
    public correctionalFacilityInMateCell: CorrectionalFacilityInMateCell;

    public constructor(init?: Partial<PostCorrectionalFacilityInMateCellResponse>) { (Object as any).assign(this, init); }
}

export class PostCorrectionalFacilityInMateCellRemoveResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostCorrectionalFacilityInMateCellRemoveResponse>) { (Object as any).assign(this, init); }
}

export class PostCorrectionalFacilityInMateCellInspectResponse
{
    public draftIncidentId: number;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostCorrectionalFacilityInMateCellInspectResponse>) { (Object as any).assign(this, init); }
}

export class PostCorrectionalFacilityRiskStatusesResponse
{
    public riskStatuses: CorrectionalFacilityRiskStatus[];
    public paging: Paging;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostCorrectionalFacilityRiskStatusesResponse>) { (Object as any).assign(this, init); }
}

export class PostExternalEventMessageAuditsResponse
{
    public responseStatus: ResponseStatus;
    public data: ExternalEventMessageAudit[];
    public paging: Paging;
    public filter: SimpleFilter;

    public constructor(init?: Partial<PostExternalEventMessageAuditsResponse>) { (Object as any).assign(this, init); }
}

export class PostExternalEventMessageAuditResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostExternalEventMessageAuditResponse>) { (Object as any).assign(this, init); }
}

export class PostExternalEventsResponse
{
    public responseStatus: ResponseStatus;
    public data: ExternalEvent[];
    public paging: Paging;
    public filter: SimpleFilter;

    public constructor(init?: Partial<PostExternalEventsResponse>) { (Object as any).assign(this, init); }
}

export class PostExternalEventNewResponse
{
    public externalEvent: ExternalEvent;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostExternalEventNewResponse>) { (Object as any).assign(this, init); }
}

export class PostPostExternalEventResponse
{
    public externalEvent: ExternalEvent;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostPostExternalEventResponse>) { (Object as any).assign(this, init); }
}

export class GetExternalEventResponse
{
    public externalEvent: ExternalEvent;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetExternalEventResponse>) { (Object as any).assign(this, init); }
}

export class PostProcessExternalEventResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostProcessExternalEventResponse>) { (Object as any).assign(this, init); }
}

export class GetExternalEventCopyResponse
{
    public externalEventSelections: MultiSelectOption[];
    public venues: VenueSelection[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetExternalEventCopyResponse>) { (Object as any).assign(this, init); }
}

export class PostExternalEventCopyResponse
{
    public externalEventSelections: MultiSelectOption[];
    public errorMessage: string;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostExternalEventCopyResponse>) { (Object as any).assign(this, init); }
}

export class GetNotificationTriggerResponse
{
    public notificationTrigger: NotificationTrigger;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetNotificationTriggerResponse>) { (Object as any).assign(this, init); }
}

export class PostNotificationTriggerResponse
{
    public notificationTrigger: NotificationTrigger;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostNotificationTriggerResponse>) { (Object as any).assign(this, init); }
}

export class GetNotificationTriggerUsersSummaryResponse
{
    public notificationTriggerUsersSummary: NotificationTriggerUsersSummary;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetNotificationTriggerUsersSummaryResponse>) { (Object as any).assign(this, init); }
}

export class PostSignOnRegisterTypesResponse
{
    public responseStatus: ResponseStatus;
    public data: SignOnRegisterType[];
    public paging: Paging;
    public filter: SimpleFilter;

    public constructor(init?: Partial<PostSignOnRegisterTypesResponse>) { (Object as any).assign(this, init); }
}

export class PostSignOnRegisterTypeNewResponse
{
    public signOnRegisterType: SignOnRegisterType;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostSignOnRegisterTypeNewResponse>) { (Object as any).assign(this, init); }
}

export class PostPostSignOnRegisterTypeResponse
{
    public signOnRegisterType: SignOnRegisterType;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostPostSignOnRegisterTypeResponse>) { (Object as any).assign(this, init); }
}

export class GetSignOnRegisterTypeResponse
{
    public signOnRegisterType: SignOnRegisterType;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetSignOnRegisterTypeResponse>) { (Object as any).assign(this, init); }
}

export class PostFunctionalAreasResponse
{
    public responseStatus: ResponseStatus;
    public data: FunctionalArea[];
    public paging: Paging;
    public filter: SimpleFilter;

    public constructor(init?: Partial<PostFunctionalAreasResponse>) { (Object as any).assign(this, init); }
}

export class PostFunctionalAreaNewResponse
{
    public functionalArea: FunctionalArea;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostFunctionalAreaNewResponse>) { (Object as any).assign(this, init); }
}

export class PostPostFunctionalAreaResponse
{
    public functionalArea: FunctionalArea;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostPostFunctionalAreaResponse>) { (Object as any).assign(this, init); }
}

export class GetFunctionalAreaResponse
{
    public functionalArea: FunctionalArea;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetFunctionalAreaResponse>) { (Object as any).assign(this, init); }
}

export class PostNotesResponse
{
    public responseStatus: ResponseStatus;
    public data: Note[];
    public paging: Paging;
    public filter: SimpleFilter;

    public constructor(init?: Partial<PostNotesResponse>) { (Object as any).assign(this, init); }
}

export class PostNoteNewResponse
{
    public note: Note;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostNoteNewResponse>) { (Object as any).assign(this, init); }
}

export class PostPostNoteResponse
{
    public note: Note;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostPostNoteResponse>) { (Object as any).assign(this, init); }
}

export class GetNoteResponse
{
    public note: Note;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetNoteResponse>) { (Object as any).assign(this, init); }
}

export class PostRosterResponse
{
    public responseStatus: ResponseStatus;
    public filter: RosterFilter;
    public rosters: Roster[];

    public constructor(init?: Partial<PostRosterResponse>) { (Object as any).assign(this, init); }
}

export class PostRosterNewLeaveResponse
{
    public responseStatus: ResponseStatus;
    public leaveRequest: LeaveRequest;

    public constructor(init?: Partial<PostRosterNewLeaveResponse>) { (Object as any).assign(this, init); }
}

export class PostSubLocationsResponse
{
    public responseStatus: ResponseStatus;
    public data: SubLocation[];
    public paging: Paging;
    public filter: SimpleFilter;

    public constructor(init?: Partial<PostSubLocationsResponse>) { (Object as any).assign(this, init); }
}

export class PostSubLocationNewResponse
{
    public subLocation: SubLocation;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostSubLocationNewResponse>) { (Object as any).assign(this, init); }
}

export class PostPostSubLocationResponse
{
    public subLocation: SubLocation;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostPostSubLocationResponse>) { (Object as any).assign(this, init); }
}

export class GetSubLocationResponse
{
    public subLocation: SubLocation;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetSubLocationResponse>) { (Object as any).assign(this, init); }
}

export class PostRadioChannelsResponse
{
    public responseStatus: ResponseStatus;
    public data: RadioChannel[];
    public paging: Paging;
    public filter: SimpleFilter;

    public constructor(init?: Partial<PostRadioChannelsResponse>) { (Object as any).assign(this, init); }
}

export class PostRadioChannelNewResponse
{
    public radioChannel: RadioChannel;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostRadioChannelNewResponse>) { (Object as any).assign(this, init); }
}

export class PostPostRadioChannelResponse
{
    public radioChannel: RadioChannel;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostPostRadioChannelResponse>) { (Object as any).assign(this, init); }
}

export class GetRadioChannelResponse
{
    public radioChannel: RadioChannel;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetRadioChannelResponse>) { (Object as any).assign(this, init); }
}

export class PostProcessImageTriggerHistoricalProcessingResponse
{
    public responseStatus: ResponseStatus;
    public count: number;

    public constructor(init?: Partial<PostProcessImageTriggerHistoricalProcessingResponse>) { (Object as any).assign(this, init); }
}

export class PostProcessImageTemporaryFileResponse
{
    public responseStatus: ResponseStatus;
    public filePath: string;
    public imageText: string;

    public constructor(init?: Partial<PostProcessImageTemporaryFileResponse>) { (Object as any).assign(this, init); }
}

export class PostProcessImageIncidentDocumentResponse
{
    public responseStatus: ResponseStatus;
    public incidentDocumentId: number;
    public imageText: string;
    public savedToDocument: boolean;

    public constructor(init?: Partial<PostProcessImageIncidentDocumentResponse>) { (Object as any).assign(this, init); }
}

export class PostProcessImageIncidentPatronPhotoResponse
{
    public responseStatus: ResponseStatus;
    public incidentPatronPhotoId: number;
    public imageText: string;
    public savedToDocument: boolean;

    public constructor(init?: Partial<PostProcessImageIncidentPatronPhotoResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionPOIRegistrationResponse
{
    public responseStatus: ResponseStatus;
    public data: POIRegistration;

    public constructor(init?: Partial<PostFacialRecognitionPOIRegistrationResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionPOIVenueRegistrationResponse
{
    public responseStatus: ResponseStatus;
    public data: POIRegistration;

    public constructor(init?: Partial<PostFacialRecognitionPOIVenueRegistrationResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionPOIGroupRegistrationResponse
{
    public responseStatus: ResponseStatus;
    public data: POIRegistration;

    public constructor(init?: Partial<PostFacialRecognitionPOIGroupRegistrationResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionPOIForExistingRegistrationResponse
{
    public responseStatus: ResponseStatus;
    public data: POIRegistration;

    public constructor(init?: Partial<PostFacialRecognitionPOIForExistingRegistrationResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionPOIRegistrationScanFacesResponse
{
    public responseStatus: ResponseStatus;
    public data: POIRegistration;

    public constructor(init?: Partial<PostFacialRecognitionPOIRegistrationScanFacesResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionPOIRegistrationSearchFaceResponse
{
    public responseStatus: ResponseStatus;
    public data: POIRegistration;

    public constructor(init?: Partial<PostFacialRecognitionPOIRegistrationSearchFaceResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionPOIRegistrationSaveResponse
{
    public responseStatus: ResponseStatus;
    public data: POIRegistration;

    public constructor(init?: Partial<PostFacialRecognitionPOIRegistrationSaveResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionPOIIncidentsResponse
{
    public responseStatus: ResponseStatus;
    public data: POIIncidents;
    public facialRecognitionPOIIncidentId: number;
    public facialRecognitionWatchlistId: number;
    public forVenueId: number;
    public poiGuid: string;

    public constructor(init?: Partial<PostFacialRecognitionPOIIncidentsResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionPOIGuidsResponse
{
    public responseStatus: ResponseStatus;
    public data: POIIncidents[];
    public forVenueId: number;
    public poiGuids: string[];

    public constructor(init?: Partial<PostFacialRecognitionPOIGuidsResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionPOIScannedIDResponse
{
    public responseStatus: ResponseStatus;
    public data: PoiIdScan;

    public constructor(init?: Partial<PostFacialRecognitionPOIScannedIDResponse>) { (Object as any).assign(this, init); }
}

export class PacialRecognitionPOIIncidentGenerateAppearanceResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PacialRecognitionPOIIncidentGenerateAppearanceResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionPOIScanResponse
{
    public responseStatus: ResponseStatus;
    public poiScan: POIScan;

    public constructor(init?: Partial<PostFacialRecognitionPOIScanResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionExpiryFailedResponse
{
    public responseStatus: ResponseStatus;
    public data: FacialRecognitionPOIIncident[];
    public paging: Paging;
    public filter: SimpleFilter;

    public constructor(init?: Partial<PostFacialRecognitionExpiryFailedResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionExpiryRetryResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostFacialRecognitionExpiryRetryResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionExpiryDismissResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostFacialRecognitionExpiryDismissResponse>) { (Object as any).assign(this, init); }
}

export class GetPatchConnectionPoolResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetPatchConnectionPoolResponse>) { (Object as any).assign(this, init); }
}

export class GetAdminTestResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetAdminTestResponse>) { (Object as any).assign(this, init); }
}

export class GetSchedulesResponse
{
    public responseStatus: ResponseStatus;
    public data: Schedule[];

    public constructor(init?: Partial<GetSchedulesResponse>) { (Object as any).assign(this, init); }
}

export class PostScheduleNewResponse
{
    public schedule: Schedule;
    public checklistTemplates: ChecklistTemplateSummary[];
    public scheduleDatas: ScheduleData[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostScheduleNewResponse>) { (Object as any).assign(this, init); }
}

export class PostScheduleResponse
{
    public schedule: Schedule;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostScheduleResponse>) { (Object as any).assign(this, init); }
}

export class GetScheduleResponse
{
    public schedule: Schedule;
    public scheduleDatas: ScheduleData[];
    public checklistTemplates: ChecklistTemplateSummary[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetScheduleResponse>) { (Object as any).assign(this, init); }
}

export class PostScheduleCopyResponse
{
    public schedule: Schedule;
    public checklistTemplates: ChecklistTemplateSummary[];
    public scheduleDatas: ScheduleData[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostScheduleCopyResponse>) { (Object as any).assign(this, init); }
}

export class PostScheduleProcessResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostScheduleProcessResponse>) { (Object as any).assign(this, init); }
}

export class PostScheduleLogsResponse
{
    public responseStatus: ResponseStatus;
    public data: PagedList<ScheduleLog>;

    public constructor(init?: Partial<PostScheduleLogsResponse>) { (Object as any).assign(this, init); }
}

export class PostScheduleLogDeleteResponse
{
    public responseStatus: ResponseStatus;
    public data: PagedList<ScheduleLog>;

    public constructor(init?: Partial<PostScheduleLogDeleteResponse>) { (Object as any).assign(this, init); }
}

export class GetFacialRecognitionWatchlistsResponse
{
    public facialRecognitionWatchlists: FacialRecognitionWatchlist[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetFacialRecognitionWatchlistsResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionWatchlistNewResponse
{
    public facialRecognitionWatchlist: FacialRecognitionWatchlist;
    public facialRecognitionWatchlistTypes: FacialRecognitionWatchlistType[];
    public facialRecognitionServers: FacialRecognitionServer[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostFacialRecognitionWatchlistNewResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionWatchlistResponse
{
    public responseStatus: ResponseStatus;
    public facialRecognitionWatchlist: FacialRecognitionWatchlist;
    public facialRecognitionWatchlists: FacialRecognitionWatchlist[];

    public constructor(init?: Partial<PostFacialRecognitionWatchlistResponse>) { (Object as any).assign(this, init); }
}

export class GetFacialRecognitionWatchlistResponse
{
    public facialRecognitionWatchlist: FacialRecognitionWatchlist;
    public facialRecognitionWatchlistTypes: FacialRecognitionWatchlistType[];
    public facialRecognitionServers: FacialRecognitionServer[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetFacialRecognitionWatchlistResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionSyncWatchlistsResponse
{
    public responseStatus: ResponseStatus;
    public facialRecognitionWatchlists: FacialRecognitionWatchlist[];

    public constructor(init?: Partial<PostFacialRecognitionSyncWatchlistsResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionWatchlistPoisResponse
{
    public responseStatus: ResponseStatus;
    public filter: FacialRecognitionWatchlistPoisFilter;
    public data: FacialRecognitionWatchlistPoi[];
    public paging: Paging;
    public cameras: FacialRecognitionCamera[];
    public timeZones: TimeZone[];

    public constructor(init?: Partial<PostFacialRecognitionWatchlistPoisResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionWatchlistPoiResponse
{
    public responseStatus: ResponseStatus;
    public filter: FacialRecognitionWatchlistPoisFilter;
    public data: FacialRecognitionWatchlistPoi[];
    public paging: Paging;

    public constructor(init?: Partial<PostFacialRecognitionWatchlistPoiResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionWatchlistPoisRemoveResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostFacialRecognitionWatchlistPoisRemoveResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionWatchlistNewImportResponse
{
    public facialRecognitionWatchlistImports: FacialRecognitionWatchlistImport[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostFacialRecognitionWatchlistNewImportResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionWatchlistImportResponse
{
    public responseStatus: ResponseStatus;
    public facialRecognitionWatchlistImports: FacialRecognitionWatchlistImport[];

    public constructor(init?: Partial<PostFacialRecognitionWatchlistImportResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionWatchlistNewMigrationResponse
{
    public facialRecognitionWatchlistMigration: FacialRecognitionWatchlistMigration;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostFacialRecognitionWatchlistNewMigrationResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionWatchlistMigrationResponse
{
    public responseStatus: ResponseStatus;
    public facialRecognitionWatchlistMigration: FacialRecognitionWatchlistMigration;

    public constructor(init?: Partial<PostFacialRecognitionWatchlistMigrationResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionWatchlistProcessMigrationResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostFacialRecognitionWatchlistProcessMigrationResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionWatchlistCancelMigrationResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostFacialRecognitionWatchlistCancelMigrationResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionWatchlistNewMoveResponse
{
    public facialRecognitionWatchlistMove: FacialRecognitionWatchlistMove;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostFacialRecognitionWatchlistNewMoveResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionWatchlistMoveResponse
{
    public responseStatus: ResponseStatus;
    public facialRecognitionWatchlistMove: FacialRecognitionWatchlistMove;

    public constructor(init?: Partial<PostFacialRecognitionWatchlistMoveResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionAutomaticEnrolmentsResponse
{
    public responseStatus: ResponseStatus;
    public data: FacialRecognitionAutomaticEnrolment[];

    public constructor(init?: Partial<PostFacialRecognitionAutomaticEnrolmentsResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionAutomaticEnrolmentNewResponse
{
    public facialRecognitionAutomaticEnrolment: FacialRecognitionAutomaticEnrolment;
    public watchlists: FacialRecognitionWatchlist[];
    public incidentTypes: IncidentType[];
    public incidentTypeCategoryTypes: IncidentTypeCategoryTypes[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostFacialRecognitionAutomaticEnrolmentNewResponse>) { (Object as any).assign(this, init); }
}

export class PostPostFacialRecognitionAutomaticEnrolmentResponse
{
    public facialRecognitionAutomaticEnrolment: FacialRecognitionAutomaticEnrolment;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostPostFacialRecognitionAutomaticEnrolmentResponse>) { (Object as any).assign(this, init); }
}

export class GetFacialRecognitionAutomaticEnrolmentResponse
{
    public facialRecognitionAutomaticEnrolment: FacialRecognitionAutomaticEnrolment;
    public watchlists: FacialRecognitionWatchlist[];
    public incidentTypes: IncidentType[];
    public incidentTypeCategoryTypes: IncidentTypeCategoryTypes[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetFacialRecognitionAutomaticEnrolmentResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionAutomaticEnrolmentProcessResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostFacialRecognitionAutomaticEnrolmentProcessResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionWatchlistTypesResponse
{
    public responseStatus: ResponseStatus;
    public data: FacialRecognitionWatchlistType[];
    public paging: Paging;
    public filter: SimpleFilter;

    public constructor(init?: Partial<PostFacialRecognitionWatchlistTypesResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionWatchlistTypeNewResponse
{
    public facialRecognitionWatchlistType: FacialRecognitionWatchlistType;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostFacialRecognitionWatchlistTypeNewResponse>) { (Object as any).assign(this, init); }
}

export class PostPostFacialRecognitionWatchlistTypeResponse
{
    public facialRecognitionWatchlistType: FacialRecognitionWatchlistType;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostPostFacialRecognitionWatchlistTypeResponse>) { (Object as any).assign(this, init); }
}

export class GetFacialRecognitionWatchlistTypeResponse
{
    public facialRecognitionWatchlistType: FacialRecognitionWatchlistType;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetFacialRecognitionWatchlistTypeResponse>) { (Object as any).assign(this, init); }
}

export class GetFacialRecognitionServersResponse
{
    public responseStatus: ResponseStatus;
    public data: FacialRecognitionServer[];

    public constructor(init?: Partial<GetFacialRecognitionServersResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionServerNewResponse
{
    public facialRecognitionServer: FacialRecognitionServer;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostFacialRecognitionServerNewResponse>) { (Object as any).assign(this, init); }
}

export class PostPostFacialRecognitionServerResponse
{
    public facialRecognitionServer: FacialRecognitionServer;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostPostFacialRecognitionServerResponse>) { (Object as any).assign(this, init); }
}

export class GetFacialRecognitionServerResponse
{
    public facialRecognitionServer: FacialRecognitionServer;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetFacialRecognitionServerResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionServerTestResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostFacialRecognitionServerTestResponse>) { (Object as any).assign(this, init); }
}

export class GetFacialRecognitionServerEventStreamingConfigurationResponse
{
    public eventStreamingConfiguration: EventStreamingConfiguration;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetFacialRecognitionServerEventStreamingConfigurationResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionServerEventStreamingVerificationResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostFacialRecognitionServerEventStreamingVerificationResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionServerCloneResponse
{
    public facialRecognitionServer: FacialRecognitionServer;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostFacialRecognitionServerCloneResponse>) { (Object as any).assign(this, init); }
}

export class GetGroupsResponse
{
    public responseStatus: ResponseStatus;
    public groups: Group[];
    public paging: Paging;
    public filter: GroupFilter;

    public constructor(init?: Partial<GetGroupsResponse>) { (Object as any).assign(this, init); }
}

export class GetGroupForSelectionResponse
{
    public responseStatus: ResponseStatus;
    public groups: Group[];

    public constructor(init?: Partial<GetGroupForSelectionResponse>) { (Object as any).assign(this, init); }
}

export class PostGroupNewResponse
{
    public group: Group;
    public timeZones: TimeZone[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostGroupNewResponse>) { (Object as any).assign(this, init); }
}

export class GetGroupResponse
{
    public group: Group;
    public userRoleStatuses: UserRoleStatus[];
    public userRoleTypes: UserRoleType[];
    public timeZones: TimeZone[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetGroupResponse>) { (Object as any).assign(this, init); }
}

export class PostGroupResponse
{
    public group: Group;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostGroupResponse>) { (Object as any).assign(this, init); }
}

export class GetGroupAvailableVenuesResponse
{
    public venues: VenueSelection[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetGroupAvailableVenuesResponse>) { (Object as any).assign(this, init); }
}

export class PostGroupVenuesResponse
{
    public group: Group;
    public venues: VenueSelection[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostGroupVenuesResponse>) { (Object as any).assign(this, init); }
}

export class PostGroupVenueResponse
{
    public group: Group;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostGroupVenueResponse>) { (Object as any).assign(this, init); }
}

export class GetGroupAvailableUsersResponse
{
    public users: UserSelection[];
    public userRoleTypes: UserRoleType[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetGroupAvailableUsersResponse>) { (Object as any).assign(this, init); }
}

export class PostGroupUsersResponse
{
    public group: Group;
    public users: UserSelection[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostGroupUsersResponse>) { (Object as any).assign(this, init); }
}

export class PostGroupUserResponse
{
    public group: Group;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostGroupUserResponse>) { (Object as any).assign(this, init); }
}

export class PostGroupRequestAccessResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostGroupRequestAccessResponse>) { (Object as any).assign(this, init); }
}

export class PostGroupRequestAccessRejectResponse
{
    public group: Group;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostGroupRequestAccessRejectResponse>) { (Object as any).assign(this, init); }
}

export class GetIncidentFlagTypeNotificationsResponse
{
    public responseStatus: ResponseStatus;
    public incidentFlagTypeNotifications: IncidentFlagTypeNotification[];
    public paging: Paging;
    public filter: IncidentFlagTypeFilter;

    public constructor(init?: Partial<GetIncidentFlagTypeNotificationsResponse>) { (Object as any).assign(this, init); }
}

export class GetIncidentFlagTypeNotificationResponse
{
    public incidentFlagTypeNotification: IncidentFlagTypeNotification;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetIncidentFlagTypeNotificationResponse>) { (Object as any).assign(this, init); }
}

export class PostIncidentFlagTypeNotificationResponse
{
    public incidentFlagTypeNotification: IncidentFlagTypeNotification;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostIncidentFlagTypeNotificationResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionNotificationResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostFacialRecognitionNotificationResponse>) { (Object as any).assign(this, init); }
}

export class PostExternalFacialRecognitionNotificationResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostExternalFacialRecognitionNotificationResponse>) { (Object as any).assign(this, init); }
}

export class PostProcessFacialRecognitionNotificationResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostProcessFacialRecognitionNotificationResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionNotificationFilterResponse
{
    public responseStatus: ResponseStatus;
    public filter: FacialRecognitionNotificationFilter;

    public constructor(init?: Partial<PostFacialRecognitionNotificationFilterResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionNotificationsResponse
{
    public responseStatus: ResponseStatus;
    public items: FacialRecognitionNotification[];
    public filter: FacialRecognitionNotificationFilter;
    public paging: Paging;

    public constructor(init?: Partial<PostFacialRecognitionNotificationsResponse>) { (Object as any).assign(this, init); }
}

export class GetFacialRecognitionNotificationResponse
{
    public facialRecognitionNotification: FacialRecognitionNotification;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetFacialRecognitionNotificationResponse>) { (Object as any).assign(this, init); }
}

export class GetFacialRecognitionEventResponse
{
    public facialRecognitionEvents: FacialRecognitionEvent[];
    public facialRecognitionWatchlists: FacialRecognitionWatchlist[];
    public facialRecognitionCameras: FacialRecognitionCamera[];
    public incidentTypes: IncidentType[];
    public incidentTypeCategoryTypes: IncidentTypeCategoryTypes[];
    public incidentFlagTypes: IncidentFlagType[];
    public userRoleTypes: UserRoleType[];
    public defaultNotificationTrigger: NotificationTrigger;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetFacialRecognitionEventResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionEventResponse
{
    public responseStatus: ResponseStatus;
    public facialRecognitionEvents: FacialRecognitionEvent[];

    public constructor(init?: Partial<PostFacialRecognitionEventResponse>) { (Object as any).assign(this, init); }
}

export class GetFacialRecognitionCamerasResponse
{
    public facialRecognitionCameras: FacialRecognitionCamera[];
    public facialRecognitionWatchlists: FacialRecognitionWatchlist[];
    public venueLocations: VenueLocation[];
    public facialRecognitionServers: FacialRecognitionServer[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetFacialRecognitionCamerasResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionSyncCamerasResponse
{
    public responseStatus: ResponseStatus;
    public facialRecognitionCameras: FacialRecognitionCamera[];

    public constructor(init?: Partial<PostFacialRecognitionSyncCamerasResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionCamerasResponse
{
    public responseStatus: ResponseStatus;
    public facialRecognitionCameras: FacialRecognitionCamera[];

    public constructor(init?: Partial<PostFacialRecognitionCamerasResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionCameraResponse
{
    public responseStatus: ResponseStatus;
    public facialRecognitionCamera: FacialRecognitionCamera;
    public facialRecognitionCameras: FacialRecognitionCamera[];

    public constructor(init?: Partial<PostFacialRecognitionCameraResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionCameraStatusResponse
{
    public responseStatus: ResponseStatus;
    public facialRecognitionCameras: FacialRecognitionCamera[];

    public constructor(init?: Partial<PostFacialRecognitionCameraStatusResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionCameraFeedResponse
{
    public responseStatus: ResponseStatus;
    public data: FacialRecognitionFeed;

    public constructor(init?: Partial<PostFacialRecognitionCameraFeedResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionCameraNewImportResponse
{
    public facialRecognitionCameraImports: FacialRecognitionCameraImport[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostFacialRecognitionCameraNewImportResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionCameraImportResponse
{
    public responseStatus: ResponseStatus;
    public facialRecognitionCameraImports: FacialRecognitionCameraImport[];

    public constructor(init?: Partial<PostFacialRecognitionCameraImportResponse>) { (Object as any).assign(this, init); }
}

export class PostFacialRecognitionNotificationStatisticsResponse
{
    public responseStatus: ResponseStatus;
    public statistics: FacialRecognitionNotificationStatistics;

    public constructor(init?: Partial<PostFacialRecognitionNotificationStatisticsResponse>) { (Object as any).assign(this, init); }
}

export class GetChecklistSummariesResponse
{
    public responseStatus: ResponseStatus;
    public checklistSummaries: ChecklistSummary[];
    public filter: ChecklistFilter;
    public paging: Paging;

    public constructor(init?: Partial<GetChecklistSummariesResponse>) { (Object as any).assign(this, init); }
}

export class GetChecklistResponse
{
    public responseStatus: ResponseStatus;
    public checklist: Checklist;
    public checklistTemplate: ChecklistTemplate;

    public constructor(init?: Partial<GetChecklistResponse>) { (Object as any).assign(this, init); }
}

export class CreateChecklistResponse
{
    public responseStatus: ResponseStatus;
    public checklist: Checklist;
    public checklistTemplate: ChecklistTemplate;

    public constructor(init?: Partial<CreateChecklistResponse>) { (Object as any).assign(this, init); }
}

export class SaveChecklistResponse
{
    public responseStatus: ResponseStatus;
    public checklist: Checklist;
    public attachmentsChanged: boolean;

    public constructor(init?: Partial<SaveChecklistResponse>) { (Object as any).assign(this, init); }
}

export class ArchiveChecklistsResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ArchiveChecklistsResponse>) { (Object as any).assign(this, init); }
}

export class RestoreChecklistsResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<RestoreChecklistsResponse>) { (Object as any).assign(this, init); }
}

export class ApproveChecklistsResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ApproveChecklistsResponse>) { (Object as any).assign(this, init); }
}

export class CloseChecklistsResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<CloseChecklistsResponse>) { (Object as any).assign(this, init); }
}

export class PostChecklistReportResponse
{
    public sent: boolean;
    public message: string;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostChecklistReportResponse>) { (Object as any).assign(this, init); }
}

export class PostChecklistTemplatesFilterResponse
{
    public responseStatus: ResponseStatus;
    public filter: ChecklistTemplateFilter;

    public constructor(init?: Partial<PostChecklistTemplatesFilterResponse>) { (Object as any).assign(this, init); }
}

export class PostChecklistTemplatesResponse
{
    public responseStatus: ResponseStatus;
    public items: ChecklistTemplateSummary[];
    public filter: ChecklistTemplateFilter;
    public paging: Paging;

    public constructor(init?: Partial<PostChecklistTemplatesResponse>) { (Object as any).assign(this, init); }
}

export class GetChecklistTemplateSummariesResponse
{
    public responseStatus: ResponseStatus;
    public checklistTemplateSummaries: ChecklistTemplateSummary[];
    public filter: ChecklistTemplateFilter;
    public paging: Paging;

    public constructor(init?: Partial<GetChecklistTemplateSummariesResponse>) { (Object as any).assign(this, init); }
}

export class GetChecklistTemplateCategoriesResponse
{
    public responseStatus: ResponseStatus;
    public checklistTemplateCategories: ChecklistTemplateCategory[];

    public constructor(init?: Partial<GetChecklistTemplateCategoriesResponse>) { (Object as any).assign(this, init); }
}

export class GetChecklistTemplateCategorySummariesResponse
{
    public responseStatus: ResponseStatus;
    public checklistTemplateCategorySummaries: ChecklistTemplateCategorySummary[];

    public constructor(init?: Partial<GetChecklistTemplateCategorySummariesResponse>) { (Object as any).assign(this, init); }
}

export class ArchiveChecklistTemplateCategoriesResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ArchiveChecklistTemplateCategoriesResponse>) { (Object as any).assign(this, init); }
}

export class SaveChecklistTemplateCategoryResponse
{
    public responseStatus: ResponseStatus;
    public checklistTemplateCategory: ChecklistTemplateCategory;

    public constructor(init?: Partial<SaveChecklistTemplateCategoryResponse>) { (Object as any).assign(this, init); }
}

export class GetChecklistTemplateCategoryResponse
{
    public responseStatus: ResponseStatus;
    public checklistTemplateCategory: ChecklistTemplateCategory;

    public constructor(init?: Partial<GetChecklistTemplateCategoryResponse>) { (Object as any).assign(this, init); }
}

export class GetChecklistTemplateResponse
{
    public responseStatus: ResponseStatus;
    public checklistTemplate: ChecklistTemplate;

    public constructor(init?: Partial<GetChecklistTemplateResponse>) { (Object as any).assign(this, init); }
}

export class CopyChecklistTemplateResponse
{
    public responseStatus: ResponseStatus;
    public checklistTemplate: ChecklistTemplate;

    public constructor(init?: Partial<CopyChecklistTemplateResponse>) { (Object as any).assign(this, init); }
}

export class SaveChecklistTemplateResponse
{
    public responseStatus: ResponseStatus;
    public checklistTemplate: ChecklistTemplate;

    public constructor(init?: Partial<SaveChecklistTemplateResponse>) { (Object as any).assign(this, init); }
}

export class ArchiveChecklistTemplatesResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ArchiveChecklistTemplatesResponse>) { (Object as any).assign(this, init); }
}

export class ArchiveChecklistTemplateResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ArchiveChecklistTemplateResponse>) { (Object as any).assign(this, init); }
}

export class GetTermsResponse
{
    public responseStatus: ResponseStatus;
    public content: string;
    public title: string;

    public constructor(init?: Partial<GetTermsResponse>) { (Object as any).assign(this, init); }
}

export class GetPrivacyResponse
{
    public responseStatus: ResponseStatus;
    public content: string;
    public title: string;

    public constructor(init?: Partial<GetPrivacyResponse>) { (Object as any).assign(this, init); }
}

export class PostDashboardFilterResponse
{
    public responseStatus: ResponseStatus;
    public filter: DashboardFilter;

    public constructor(init?: Partial<PostDashboardFilterResponse>) { (Object as any).assign(this, init); }
}

export class PostDashboardResponse
{
    public responseStatus: ResponseStatus;
    public filter: DashboardFilter;
    public data: ChartDetails[];

    public constructor(init?: Partial<PostDashboardResponse>) { (Object as any).assign(this, init); }
}

export class PostDashboardExportResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostDashboardExportResponse>) { (Object as any).assign(this, init); }
}

export class PostDashboardIncidentsResponse
{
    public responseStatus: ResponseStatus;
    public filter: DashboardFilter;
    public incidents: IncidentHeatmap[];
    public venueMarkers: VenueMarker[];

    public constructor(init?: Partial<PostDashboardIncidentsResponse>) { (Object as any).assign(this, init); }
}

export class PostAdminDashboardFilterResponse
{
    public responseStatus: ResponseStatus;
    public filter: AdminDashboardFilter;

    public constructor(init?: Partial<PostAdminDashboardFilterResponse>) { (Object as any).assign(this, init); }
}

export class PostAdminDashboardResponse
{
    public responseStatus: ResponseStatus;
    public excludeVenues: boolean;
    public excludeIncidents: boolean;
    public filter: AdminDashboardFilter;
    public incidents: IncidentHeatmap[];
    public venueMarkers: VenueMarker[];

    public constructor(init?: Partial<PostAdminDashboardResponse>) { (Object as any).assign(this, init); }
}

export class PostAdminDashboardTotalsResponse
{
    public responseStatus: ResponseStatus;
    public filter: AdminDashboardFilter;
    public totalIncidents: RangeChartTotal[];
    public totalVenues: RangeChartTotal[];
    public totalSecurityFirms: RangeChartTotal[];
    public totalUsers: RangeChartTotal[];

    public constructor(init?: Partial<PostAdminDashboardTotalsResponse>) { (Object as any).assign(this, init); }
}

export class PostAdminDashboardOverviewResponse
{
    public responseStatus: ResponseStatus;
    public mode: ngtOverviewStatsMode;
    public data: ChartDetails[];

    public constructor(init?: Partial<PostAdminDashboardOverviewResponse>) { (Object as any).assign(this, init); }
}

export class PostAdminDashboardUserTotalsResponse
{
    public responseStatus: ResponseStatus;
    public venueUsers: ChartDetails[];
    public securityFirmUsers: ChartDetails[];

    public constructor(init?: Partial<PostAdminDashboardUserTotalsResponse>) { (Object as any).assign(this, init); }
}

export class PostAdminDashboardIncidentTotalsResponse
{
    public responseStatus: ResponseStatus;
    public incidents: RangeChartTotal[];
    public venueIncidents: ChartDetails[];
    public securityFirmIncidents: ChartDetails[];

    public constructor(init?: Partial<PostAdminDashboardIncidentTotalsResponse>) { (Object as any).assign(this, init); }
}

export class PostTemporaryFilesResponse
{
    public files: File[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostTemporaryFilesResponse>) { (Object as any).assign(this, init); }
}

export class PostTemporaryBase64FileResponse
{
    public files: File[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostTemporaryBase64FileResponse>) { (Object as any).assign(this, init); }
}

export class PostTemporaryFileListResponse
{
    public responseStatus: ResponseStatus;
    public data: PagedList<File>;

    public constructor(init?: Partial<PostTemporaryFileListResponse>) { (Object as any).assign(this, init); }
}

export class PostVenueEventsResponse
{
    public responseStatus: ResponseStatus;
    public data: VenueEvent[];
    public paging: Paging;
    public filter: SimpleFilter;

    public constructor(init?: Partial<PostVenueEventsResponse>) { (Object as any).assign(this, init); }
}

export class PostVenueEventNewResponse
{
    public venueEvent: VenueEvent;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostVenueEventNewResponse>) { (Object as any).assign(this, init); }
}

export class PostVenueEventResponse
{
    public venueEvent: VenueEvent;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostVenueEventResponse>) { (Object as any).assign(this, init); }
}

export class GetVenueEventResponse
{
    public venueEvent: VenueEvent;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetVenueEventResponse>) { (Object as any).assign(this, init); }
}

export class PutInstallationsResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PutInstallationsResponse>) { (Object as any).assign(this, init); }
}

export class DeleteInstallationsResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DeleteInstallationsResponse>) { (Object as any).assign(this, init); }
}

export class RequestPushResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<RequestPushResponse>) { (Object as any).assign(this, init); }
}

export class GetSecurityFirmContactsResponse
{
    public responseStatus: ResponseStatus;
    public securityFirmContacts: SecurityFirmContact[];
    public paging: Paging;
    public filter: ContactFilter;

    public constructor(init?: Partial<GetSecurityFirmContactsResponse>) { (Object as any).assign(this, init); }
}

export class PostSecurityFirmContactNewResponse
{
    public securityFirmContact: SecurityFirmContact;
    public securityFirmContactTypes: SecurityFirmContactType[];
    public availableUsers: UserSelection[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostSecurityFirmContactNewResponse>) { (Object as any).assign(this, init); }
}

export class GetSecurityFirmContactResponse
{
    public securityFirmContact: SecurityFirmContact;
    public securityFirmContactTypes: SecurityFirmContactType[];
    public availableUsers: UserSelection[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetSecurityFirmContactResponse>) { (Object as any).assign(this, init); }
}

export class PostSecurityFirmContactResponse
{
    public securityFirmContact: SecurityFirmContact;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostSecurityFirmContactResponse>) { (Object as any).assign(this, init); }
}

export class GetSecurityFirmDocumentsResponse
{
    public responseStatus: ResponseStatus;
    public securityFirmOtherDocuments: SecurityFirmOtherDocument[];
    public securityFirmOtherDocumentTypes: DocumentType[];
    public securityFirmComplianceDocuments: SecurityFirmComplianceDocument[];
    public securityFirmComplianceDocumentTypes: DocumentType[];

    public constructor(init?: Partial<GetSecurityFirmDocumentsResponse>) { (Object as any).assign(this, init); }
}

export class PostSecurityFirmOtherDocumentResponse
{
    public responseStatus: ResponseStatus;
    public securityFirmOtherDocuments: SecurityFirmOtherDocument[];
    public securityFirmOtherDocumentsIncludingObsolete: SecurityFirmOtherDocument[];

    public constructor(init?: Partial<PostSecurityFirmOtherDocumentResponse>) { (Object as any).assign(this, init); }
}

export class PostSecurityFirmOtherDocumentRemoveResponse
{
    public securityFirmOtherDocuments: SecurityFirmOtherDocument[];
    public securityFirmOtherDocumentsIncludingObsolete: SecurityFirmOtherDocument[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostSecurityFirmOtherDocumentRemoveResponse>) { (Object as any).assign(this, init); }
}

export class PostSecurityFirmComplianceDocumentResponse
{
    public responseStatus: ResponseStatus;
    public securityFirmComplianceDocuments: SecurityFirmComplianceDocument[];
    public securityFirmComplianceDocumentsIncludingObsolete: SecurityFirmComplianceDocument[];

    public constructor(init?: Partial<PostSecurityFirmComplianceDocumentResponse>) { (Object as any).assign(this, init); }
}

export class PostSecurityFirmComplianceDocumentRemoveResponse
{
    public securityFirmComplianceDocuments: SecurityFirmComplianceDocument[];
    public securityFirmComplianceDocumentsIncludingObsolete: SecurityFirmComplianceDocument[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostSecurityFirmComplianceDocumentRemoveResponse>) { (Object as any).assign(this, init); }
}

export class GetBroadcastNotificationsResponse
{
    public responseStatus: ResponseStatus;
    public broadcastNotifications: BroadcastNotification[];
    public paging: Paging;
    public filter: BroadcastNotificationFilter;

    public constructor(init?: Partial<GetBroadcastNotificationsResponse>) { (Object as any).assign(this, init); }
}

export class PostBroadcastNotificationNewResponse
{
    public broadcastNotification: BroadcastNotification;
    public availableUsers: UserSelection[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostBroadcastNotificationNewResponse>) { (Object as any).assign(this, init); }
}

export class GetBroadcastNotificationResponse
{
    public broadcastNotification: BroadcastNotification;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetBroadcastNotificationResponse>) { (Object as any).assign(this, init); }
}

export class PostBroadcastNotificationResponse
{
    public broadcastNotification: BroadcastNotification;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostBroadcastNotificationResponse>) { (Object as any).assign(this, init); }
}

export class PostBroadcastNotificationTestResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostBroadcastNotificationTestResponse>) { (Object as any).assign(this, init); }
}

export class GetIncidentActionTemplatesResponse
{
    public responseStatus: ResponseStatus;
    public incidentActionTemplates: IncidentActionTemplate[];
    public paging: Paging;
    public filter: IncidentActionTemplateFilter;

    public constructor(init?: Partial<GetIncidentActionTemplatesResponse>) { (Object as any).assign(this, init); }
}

export class PostIncidentActionTemplateNewResponse
{
    public incidentActionTemplate: IncidentActionTemplate;
    public incidentCategoryTypes: IncidentCategoryType[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostIncidentActionTemplateNewResponse>) { (Object as any).assign(this, init); }
}

export class GetIncidentActionTemplateResponse
{
    public incidentActionTemplate: IncidentActionTemplate;
    public incidentCategoryTypes: IncidentCategoryType[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetIncidentActionTemplateResponse>) { (Object as any).assign(this, init); }
}

export class PostIncidentActionTemplateResponse
{
    public incidentActionTemplate: IncidentActionTemplate;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostIncidentActionTemplateResponse>) { (Object as any).assign(this, init); }
}

export class PostIncidentActionTemplateCopyResponse
{
    public incidentActionTemplate: IncidentActionTemplate;
    public incidentCategoryTypes: IncidentCategoryType[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostIncidentActionTemplateCopyResponse>) { (Object as any).assign(this, init); }
}

export class GetAdminDirectoryResponse
{
    public responseStatus: ResponseStatus;
    public directory: Directory;

    public constructor(init?: Partial<GetAdminDirectoryResponse>) { (Object as any).assign(this, init); }
}

export class PostAdminDirectoryFolderResponse
{
    public responseStatus: ResponseStatus;
    public directory: Directory;

    public constructor(init?: Partial<PostAdminDirectoryFolderResponse>) { (Object as any).assign(this, init); }
}

export class GetDirectoryResponse
{
    public responseStatus: ResponseStatus;
    public directory: Directory;

    public constructor(init?: Partial<GetDirectoryResponse>) { (Object as any).assign(this, init); }
}

export class PostDirectoryDocumentResponse
{
    public responseStatus: ResponseStatus;
    public directory: Directory;

    public constructor(init?: Partial<PostDirectoryDocumentResponse>) { (Object as any).assign(this, init); }
}

export class PostDirectoryDocumentUpdateResponse
{
    public responseStatus: ResponseStatus;
    public directory: Directory;

    public constructor(init?: Partial<PostDirectoryDocumentUpdateResponse>) { (Object as any).assign(this, init); }
}

export class PostDirectoryDocumentRemoveResponse
{
    public responseStatus: ResponseStatus;
    public directory: Directory;

    public constructor(init?: Partial<PostDirectoryDocumentRemoveResponse>) { (Object as any).assign(this, init); }
}

export class PostDirectoryFolderResponse
{
    public responseStatus: ResponseStatus;
    public directory: Directory;

    public constructor(init?: Partial<PostDirectoryFolderResponse>) { (Object as any).assign(this, init); }
}

export class GetVenueDocumentsResponse
{
    public responseStatus: ResponseStatus;
    public venueOtherDocuments: VenueOtherDocument[];
    public venueOtherDocumentTypes: DocumentType[];
    public venueComplianceDocuments: VenueComplianceDocument[];
    public venueComplianceDocumentTypes: DocumentType[];
    public associatedSecurityComplianceDocuments: SecurityFirmComplianceDocument[];
    public associatedSecurityComplianceDocumentTypes: DocumentType[];

    public constructor(init?: Partial<GetVenueDocumentsResponse>) { (Object as any).assign(this, init); }
}

export class PostVenueOtherDocumentResponse
{
    public responseStatus: ResponseStatus;
    public venueOtherDocuments: VenueOtherDocument[];
    public venueOtherDocumentsIncludingObsolete: VenueOtherDocument[];

    public constructor(init?: Partial<PostVenueOtherDocumentResponse>) { (Object as any).assign(this, init); }
}

export class PostVenueOtherDocumentRemoveResponse
{
    public venueOtherDocuments: VenueOtherDocument[];
    public venueOtherDocumentsIncludingObsolete: VenueOtherDocument[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostVenueOtherDocumentRemoveResponse>) { (Object as any).assign(this, init); }
}

export class PostVenueComplianceDocumentResponse
{
    public responseStatus: ResponseStatus;
    public venueComplianceDocuments: VenueComplianceDocument[];
    public venueComplianceDocumentsIncludingObsolete: VenueComplianceDocument[];

    public constructor(init?: Partial<PostVenueComplianceDocumentResponse>) { (Object as any).assign(this, init); }
}

export class PostVenueComplianceDocumentRemoveResponse
{
    public venueComplianceDocuments: VenueComplianceDocument[];
    public venueComplianceDocumentsIncludingObsolete: VenueComplianceDocument[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostVenueComplianceDocumentRemoveResponse>) { (Object as any).assign(this, init); }
}

export class GetVenueContactsResponse
{
    public responseStatus: ResponseStatus;
    public venueContacts: VenueContact[];
    public paging: Paging;
    public filter: ContactFilter;

    public constructor(init?: Partial<GetVenueContactsResponse>) { (Object as any).assign(this, init); }
}

export class PostVenueContactNewResponse
{
    public venueContact: VenueContact;
    public venueContactTypes: VenueContactType[];
    public availableUsers: UserSelection[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostVenueContactNewResponse>) { (Object as any).assign(this, init); }
}

export class GetVenueContactResponse
{
    public venueContact: VenueContact;
    public venueContactTypes: VenueContactType[];
    public availableUsers: UserSelection[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetVenueContactResponse>) { (Object as any).assign(this, init); }
}

export class PostVenueContactResponse
{
    public venueContact: VenueContact;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostVenueContactResponse>) { (Object as any).assign(this, init); }
}

export class PostUserComplianceDocumentsExpiringResponse
{
    public responseStatus: ResponseStatus;
    public userComplianceDocuments: UserComplianceDocumentSummary[];
    public paging: Paging;
    public filter: UserComplianceDocumentFilter;

    public constructor(init?: Partial<PostUserComplianceDocumentsExpiringResponse>) { (Object as any).assign(this, init); }
}

export class PostUserComplianceDocumentsMissingResponse
{
    public responseStatus: ResponseStatus;
    public userComplianceDocuments: UserComplianceDocumentMissingSummary[];
    public paging: Paging;
    public filter: UserComplianceDocumentFilter;

    public constructor(init?: Partial<PostUserComplianceDocumentsMissingResponse>) { (Object as any).assign(this, init); }
}

export class AnyUserComplianceDocumentsExpiringExportResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AnyUserComplianceDocumentsExpiringExportResponse>) { (Object as any).assign(this, init); }
}

export class AnyUserComplianceDocumentsMissingExportResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AnyUserComplianceDocumentsMissingExportResponse>) { (Object as any).assign(this, init); }
}

export class PostDocumentTypesResponse
{
    public responseStatus: ResponseStatus;
    public data: DocumentType[];
    public paging: Paging;
    public filter: SimpleFilter;

    public constructor(init?: Partial<PostDocumentTypesResponse>) { (Object as any).assign(this, init); }
}

export class PostDocumentTypeNewResponse
{
    public documentType: DocumentType;
    public documentCategories: DocumentCategory[];
    public entitieTypes: EntityType[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostDocumentTypeNewResponse>) { (Object as any).assign(this, init); }
}

export class PostPostDocumentTypeResponse
{
    public documentType: DocumentType;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostPostDocumentTypeResponse>) { (Object as any).assign(this, init); }
}

export class GetDocumentTypeResponse
{
    public documentType: DocumentType;
    public documentCategories: DocumentCategory[];
    public entitieTypes: EntityType[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetDocumentTypeResponse>) { (Object as any).assign(this, init); }
}

export class PostVenueLocationsResponse
{
    public responseStatus: ResponseStatus;
    public data: VenueLocation[];
    public paging: Paging;
    public filter: VenueLocationFilter;

    public constructor(init?: Partial<PostVenueLocationsResponse>) { (Object as any).assign(this, init); }
}

export class PostVenueLocationNewResponse
{
    public venueLocation: VenueLocation;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostVenueLocationNewResponse>) { (Object as any).assign(this, init); }
}

export class PostPostVenueLocationResponse
{
    public venueLocation: VenueLocation;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostPostVenueLocationResponse>) { (Object as any).assign(this, init); }
}

export class GetVenueLocationResponse
{
    public venueLocation: VenueLocation;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetVenueLocationResponse>) { (Object as any).assign(this, init); }
}

export class PostIncidentFlagTypesResponse
{
    public responseStatus: ResponseStatus;
    public data: IncidentFlagType[];
    public paging: Paging;
    public filter: IncidentFlagTypeFilter;

    public constructor(init?: Partial<PostIncidentFlagTypesResponse>) { (Object as any).assign(this, init); }
}

export class PostIncidentFlagTypeNewResponse
{
    public incidentFlagType: IncidentFlagType;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostIncidentFlagTypeNewResponse>) { (Object as any).assign(this, init); }
}

export class PostIncidentFlagTypeResponse
{
    public incidentFlagType: IncidentFlagType;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostIncidentFlagTypeResponse>) { (Object as any).assign(this, init); }
}

export class PostIncidentTypesResponse
{
    public responseStatus: ResponseStatus;
    public data: IncidentType[];
    public paging: Paging;
    public filter: IncidentTypeFilter;

    public constructor(init?: Partial<PostIncidentTypesResponse>) { (Object as any).assign(this, init); }
}

export class PostIncidentTypeNewResponse
{
    public incidentType: IncidentType;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostIncidentTypeNewResponse>) { (Object as any).assign(this, init); }
}

export class PostIncidentTypeResponse
{
    public incidentType: IncidentType;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostIncidentTypeResponse>) { (Object as any).assign(this, init); }
}

export class PostVenueReportingPeriodScheduleCreateResponse
{
    public responseStatus: ResponseStatus;
    public venueReportingPeriodSchedule: VenueReportingPeriodSchedule;

    public constructor(init?: Partial<PostVenueReportingPeriodScheduleCreateResponse>) { (Object as any).assign(this, init); }
}

export class GetVenueReportingPeriodScheduleResponse
{
    public responseStatus: ResponseStatus;
    public venueReportingPeriodSchedule: VenueReportingPeriodSchedule;

    public constructor(init?: Partial<GetVenueReportingPeriodScheduleResponse>) { (Object as any).assign(this, init); }
}

export class PostVenueReportingPeriodScheduleUpsertResponse
{
    public responseStatus: ResponseStatus;
    public venueReportingPeriodSchedule: VenueReportingPeriodSchedule;

    public constructor(init?: Partial<PostVenueReportingPeriodScheduleUpsertResponse>) { (Object as any).assign(this, init); }
}

export class PostVenueReportingPeriodScheduleResponse
{
    public responseStatus: ResponseStatus;
    public data: VenueReportingPeriodSchedule[];

    public constructor(init?: Partial<PostVenueReportingPeriodScheduleResponse>) { (Object as any).assign(this, init); }
}

export class GetSecurityFirmSubscriptionResponse
{
    public responseStatus: ResponseStatus;
    public securityFirmSubscription: SecurityFirmSubscription;

    public constructor(init?: Partial<GetSecurityFirmSubscriptionResponse>) { (Object as any).assign(this, init); }
}

export class PostSecurityFirmSubscriptionResponse
{
    public responseStatus: ResponseStatus;
    public securityFirmSubscription: SecurityFirmSubscription;

    public constructor(init?: Partial<PostSecurityFirmSubscriptionResponse>) { (Object as any).assign(this, init); }
}

export class GetSecurityFirmSubscriptionSummaryResponse
{
    public responseStatus: ResponseStatus;
    public securityFirmSubscriptionSummary: SecurityFirmSubscriptionSummary;

    public constructor(init?: Partial<GetSecurityFirmSubscriptionSummaryResponse>) { (Object as any).assign(this, init); }
}

export class PostSecurityFirmAssociationSummaryResponse
{
    public data: SecurityFirmAssociation[];
    public paging: Paging;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostSecurityFirmAssociationSummaryResponse>) { (Object as any).assign(this, init); }
}

export class PostSecurityFirmAssociationCreateResponse
{
    public securityFirms: SecurityFirmSelection[];
    public securityFirmAssociation: SecurityFirmAssociation;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostSecurityFirmAssociationCreateResponse>) { (Object as any).assign(this, init); }
}

export class PostSecurityFirmAssociationResponse
{
    public securityFirmAssociation: SecurityFirmAssociation;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostSecurityFirmAssociationResponse>) { (Object as any).assign(this, init); }
}

export class DeleteSecurityFirmAssociationResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DeleteSecurityFirmAssociationResponse>) { (Object as any).assign(this, init); }
}

export class GetVenueSubscriptionResponse
{
    public responseStatus: ResponseStatus;
    public venueSubscription: VenueSubscription;
    public industryCategories: IndustryCategory[];

    public constructor(init?: Partial<GetVenueSubscriptionResponse>) { (Object as any).assign(this, init); }
}

export class PostVenueSubscriptionResponse
{
    public responseStatus: ResponseStatus;
    public venueSubscription: VenueSubscription;

    public constructor(init?: Partial<PostVenueSubscriptionResponse>) { (Object as any).assign(this, init); }
}

export class GetVenueSubscriptionSummaryResponse
{
    public responseStatus: ResponseStatus;
    public venueSubscriptionSummary: VenueSubscriptionSummary;

    public constructor(init?: Partial<GetVenueSubscriptionSummaryResponse>) { (Object as any).assign(this, init); }
}

export class PostWebsiteAdminClearCacheResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostWebsiteAdminClearCacheResponse>) { (Object as any).assign(this, init); }
}

export class PostWebsiteAdminImportDemoResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostWebsiteAdminImportDemoResponse>) { (Object as any).assign(this, init); }
}

export class GetWebsitePropertiesResponse
{
    public responseStatus: ResponseStatus;
    public websiteProperties: WebsiteProperties;

    public constructor(init?: Partial<GetWebsitePropertiesResponse>) { (Object as any).assign(this, init); }
}

export class PostWebsitePropertiesResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostWebsitePropertiesResponse>) { (Object as any).assign(this, init); }
}

export class PostIndustryCategoriesResponse
{
    public responseStatus: ResponseStatus;
    public data: IndustryCategory[];
    public paging: Paging;
    public filter: IndustryCategoriesFilter;

    public constructor(init?: Partial<PostIndustryCategoriesResponse>) { (Object as any).assign(this, init); }
}

export class GetIndustryCategoriesAvailableResponse
{
    public responseStatus: ResponseStatus;
    public data: IndustryCategory[];

    public constructor(init?: Partial<GetIndustryCategoriesAvailableResponse>) { (Object as any).assign(this, init); }
}

export class GetIndustryCategoryResponse
{
    public industryCategory: IndustryCategory;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetIndustryCategoryResponse>) { (Object as any).assign(this, init); }
}

export class PostIndustryCategoryResponse
{
    public industryCategory: IndustryCategory;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostIndustryCategoryResponse>) { (Object as any).assign(this, init); }
}

export class PostIndustryCategoryUpdateResponse
{
    public industryCategory: IndustryCategory;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostIndustryCategoryUpdateResponse>) { (Object as any).assign(this, init); }
}

export class GetIncidentVersionsResponse
{
    public responseStatus: ResponseStatus;
    public incidentVersions: IncidentVersion[];
    public relaventIncidentVersion: IncidentVersion;

    public constructor(init?: Partial<GetIncidentVersionsResponse>) { (Object as any).assign(this, init); }
}

export class GetIncidentVersionResponse
{
    public responseStatus: ResponseStatus;
    public incident: Incident;

    public constructor(init?: Partial<GetIncidentVersionResponse>) { (Object as any).assign(this, init); }
}

export class GetNotificationsResponse
{
    public groupedNotifications: NotificationGroup[];
    public alerts: Notification[];
    public groupNotifications: Notification[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetNotificationsResponse>) { (Object as any).assign(this, init); }
}

export class SendNotificationActionResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SendNotificationActionResponse>) { (Object as any).assign(this, init); }
}

export class SendNotificationsActionResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SendNotificationsActionResponse>) { (Object as any).assign(this, init); }
}

export class GetSystemLogResponse
{
    public notifications: Notification[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetSystemLogResponse>) { (Object as any).assign(this, init); }
}

export class ClearVenueNotificationsResponse
{
    public count: number;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ClearVenueNotificationsResponse>) { (Object as any).assign(this, init); }
}

export class ClearSecurityFirmNotificationsResponse
{
    public count: number;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ClearSecurityFirmNotificationsResponse>) { (Object as any).assign(this, init); }
}

export class GetPrintOptionsResponse
{
    public responseStatus: ResponseStatus;
    public printOptions: PrintOptions;

    public constructor(init?: Partial<GetPrintOptionsResponse>) { (Object as any).assign(this, init); }
}

export class GetIncidentResponse
{
    public responseStatus: ResponseStatus;
    public incident: Incident;
    public incidentOptions: IncidentOptions;

    public constructor(init?: Partial<GetIncidentResponse>) { (Object as any).assign(this, init); }
}

export class GetGetSnapshotSummaryResponse
{
    public responseStatus: ResponseStatus;
    public snapshotSummary: SnapshotSummary;

    public constructor(init?: Partial<GetGetSnapshotSummaryResponse>) { (Object as any).assign(this, init); }
}

export class GetIncidentReportResponse
{
    public title: string;
    public result: Uint8Array;
    public sent: boolean;
    public message: string;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetIncidentReportResponse>) { (Object as any).assign(this, init); }
}

export class GetIncidentCategoryTypeDocumentResponse
{
    public title: string;
    public result: Uint8Array;
    public sent: boolean;
    public message: string;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetIncidentCategoryTypeDocumentResponse>) { (Object as any).assign(this, init); }
}

export class GetIncidentHistoryFilterResponse
{
    public responseStatus: ResponseStatus;
    public filter: IncidentHistoryFilter;

    public constructor(init?: Partial<GetIncidentHistoryFilterResponse>) { (Object as any).assign(this, init); }
}

export class GetIncidentHistoryResponse
{
    public responseStatus: ResponseStatus;
    public incidents: Incident[];
    public filter: IncidentHistoryFilter;
    public paging: Paging;

    public constructor(init?: Partial<GetIncidentHistoryResponse>) { (Object as any).assign(this, init); }
}

export class GetIncidentHistoryWithDetailsReportResponse
{
    public sent: boolean;
    public url: string;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetIncidentHistoryWithDetailsReportResponse>) { (Object as any).assign(this, init); }
}

export class GetIncidentHistoryWithDetailVersionsReportResponse
{
    public sent: boolean;
    public url: string;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetIncidentHistoryWithDetailVersionsReportResponse>) { (Object as any).assign(this, init); }
}

export class SaveIncidentResponse
{
    public responseStatus: ResponseStatus;
    public incident: Incident;

    public constructor(init?: Partial<SaveIncidentResponse>) { (Object as any).assign(this, init); }
}

export class PostIncidentGenerateUniqueIDResponse
{
    public responseStatus: ResponseStatus;
    public incident: Incident;

    public constructor(init?: Partial<PostIncidentGenerateUniqueIDResponse>) { (Object as any).assign(this, init); }
}

export class PostIncidentChecklistsResponse
{
    public responseStatus: ResponseStatus;
    public incident: Incident;

    public constructor(init?: Partial<PostIncidentChecklistsResponse>) { (Object as any).assign(this, init); }
}

export class PostClearIncidentApprovalsResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostClearIncidentApprovalsResponse>) { (Object as any).assign(this, init); }
}

export class ApproveIncidentsResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ApproveIncidentsResponse>) { (Object as any).assign(this, init); }
}

export class AnyIncidentHistoryFilterReportResponse
{
    public sent: boolean;
    public message: string;
    public url: string;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AnyIncidentHistoryFilterReportResponse>) { (Object as any).assign(this, init); }
}

export class PostIncidentBuildDocumentResponse
{
    public incidentDocument: IncidentDocument;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostIncidentBuildDocumentResponse>) { (Object as any).assign(this, init); }
}

export class GetRegisterUserResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetRegisterUserResponse>) { (Object as any).assign(this, init); }
}

export class GetReportGenerationActiveResponse
{
    public responseStatus: ResponseStatus;
    public items: ReportGeneration[];

    public constructor(init?: Partial<GetReportGenerationActiveResponse>) { (Object as any).assign(this, init); }
}

export class PostReportGenerationDownloadedResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostReportGenerationDownloadedResponse>) { (Object as any).assign(this, init); }
}

export class PostReportGenerationDismissedResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostReportGenerationDismissedResponse>) { (Object as any).assign(this, init); }
}

export class PostReportGenerationDismissAllResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostReportGenerationDismissAllResponse>) { (Object as any).assign(this, init); }
}

export class GetReportGenerationResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetReportGenerationResponse>) { (Object as any).assign(this, init); }
}

export class GetReportGeneratedResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetReportGeneratedResponse>) { (Object as any).assign(this, init); }
}

export class AnyReportRunResponse
{
    public title: string;
    public result: Uint8Array;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AnyReportRunResponse>) { (Object as any).assign(this, init); }
}

export class GetUserRoleTypesResponse
{
    public responseStatus: ResponseStatus;
    public userRoleTypes: UserRoleType[];

    public constructor(init?: Partial<GetUserRoleTypesResponse>) { (Object as any).assign(this, init); }
}

export class GetUserRoleTypeResponse
{
    public responseStatus: ResponseStatus;
    public userRoleType: UserRoleType;
    public availableUserPermissions: Permission[];
    public availableIndustryCategories: IndustryCategory[];
    public availableStates: State[];

    public constructor(init?: Partial<GetUserRoleTypeResponse>) { (Object as any).assign(this, init); }
}

export class NewUserRoleTypeResponse
{
    public responseStatus: ResponseStatus;
    public userRoleType: UserRoleType;
    public availableUserPermissions: Permission[];
    public availableIndustryCategories: IndustryCategory[];
    public availableStates: State[];

    public constructor(init?: Partial<NewUserRoleTypeResponse>) { (Object as any).assign(this, init); }
}

export class PostUserRoleTypeResponse
{
    public userRoleType: UserRoleType;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostUserRoleTypeResponse>) { (Object as any).assign(this, init); }
}

export class UpdateUserRoleTypeResponse
{
    public responseStatus: ResponseStatus;
    public userRoleType: UserRoleType;

    public constructor(init?: Partial<UpdateUserRoleTypeResponse>) { (Object as any).assign(this, init); }
}

export class DeleteUserRoleTypeResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DeleteUserRoleTypeResponse>) { (Object as any).assign(this, init); }
}

export class PostUserRoleTypesResponse
{
    public responseStatus: ResponseStatus;
    public userRoleTypes: UserRoleTypeSummary[];
    public paging: Paging;
    public filter: UserRoleTypeFilter;

    public constructor(init?: Partial<PostUserRoleTypesResponse>) { (Object as any).assign(this, init); }
}

export class GetIndustryCategoriesResponse
{
    public responseStatus: ResponseStatus;
    public industryCategories: IndustryCategory[];

    public constructor(init?: Partial<GetIndustryCategoriesResponse>) { (Object as any).assign(this, init); }
}

export class GetStatesResponse
{
    public responseStatus: ResponseStatus;
    public states: State[];

    public constructor(init?: Partial<GetStatesResponse>) { (Object as any).assign(this, init); }
}

export class GetChecklistStatusesResponse
{
    public responseStatus: ResponseStatus;
    public checklistStatuses: ChecklistStatus[];

    public constructor(init?: Partial<GetChecklistStatusesResponse>) { (Object as any).assign(this, init); }
}

export class GetIncidentTypesReferenceDataResponse
{
    public responseStatus: ResponseStatus;
    public incidentTypes: IncidentType[];

    public constructor(init?: Partial<GetIncidentTypesReferenceDataResponse>) { (Object as any).assign(this, init); }
}

export class GetIncidentCategoryTypesReferenceDataResponse
{
    public responseStatus: ResponseStatus;
    public incidentCategoryTypes: IncidentCategoryType[];

    public constructor(init?: Partial<GetIncidentCategoryTypesReferenceDataResponse>) { (Object as any).assign(this, init); }
}

export class VenuesResponse
{
    public responseStatus: ResponseStatus;
    public result: NamedValue[];

    public constructor(init?: Partial<VenuesResponse>) { (Object as any).assign(this, init); }
}

export class SecurityFirmsResponse
{
    public responseStatus: ResponseStatus;
    public result: NamedValue[];

    public constructor(init?: Partial<SecurityFirmsResponse>) { (Object as any).assign(this, init); }
}

export class GetChecklistTemplateCategoriesReferenceDataResponse
{
    public responseStatus: ResponseStatus;
    public checklistTemplateCategories: ChecklistTemplateCategory[];

    public constructor(init?: Partial<GetChecklistTemplateCategoriesReferenceDataResponse>) { (Object as any).assign(this, init); }
}

export class GetPersonnelSecurityRegisterDetailsResponse
{
    public responseStatus: ResponseStatus;
    public result: PersonnelLicenceCheckResult;

    public constructor(init?: Partial<GetPersonnelSecurityRegisterDetailsResponse>) { (Object as any).assign(this, init); }
}

export class GetBulkPersonnelSecurityRegisterDetailsResponse
{
    public responseStatus: ResponseStatus;
    public result: PersonnelLicenceCheckResult[];

    public constructor(init?: Partial<GetBulkPersonnelSecurityRegisterDetailsResponse>) { (Object as any).assign(this, init); }
}

export class GetBulkSecurityFirmSecurityRegisterDetailsResponse
{
    public responseStatus: ResponseStatus;
    public result: SecurityFirmLicenceCheckResult[];

    public constructor(init?: Partial<GetBulkSecurityFirmSecurityRegisterDetailsResponse>) { (Object as any).assign(this, init); }
}

export class GetSecurityFirmSecurityRegisterDetailsResponse
{
    public responseStatus: ResponseStatus;
    public result: SecurityFirmLicenceCheckResult;

    public constructor(init?: Partial<GetSecurityFirmSecurityRegisterDetailsResponse>) { (Object as any).assign(this, init); }
}

export class GetSecurityPersonnelLicenceDetailsResponse
{
    public responseStatus: ResponseStatus;
    public result: PersonnelLicenceCheckResult;

    public constructor(init?: Partial<GetSecurityPersonnelLicenceDetailsResponse>) { (Object as any).assign(this, init); }
}

export class GetSecurityFirmLicenceDetailsResponse
{
    public responseStatus: ResponseStatus;
    public result: SecurityFirmLicenceCheckResult;

    public constructor(init?: Partial<GetSecurityFirmLicenceDetailsResponse>) { (Object as any).assign(this, init); }
}

export class SignOnToRegisterResponse
{
    public responseStatus: ResponseStatus;
    public userSignOnStatus: UserSignOnStatus;

    public constructor(init?: Partial<SignOnToRegisterResponse>) { (Object as any).assign(this, init); }
}

export class SignOnToRegisterUserCheckResponse
{
    public responseStatus: ResponseStatus;
    public userSignOnCheck: UserSignOnCheck;

    public constructor(init?: Partial<SignOnToRegisterUserCheckResponse>) { (Object as any).assign(this, init); }
}

export class SignOffRegisterResponse
{
    public responseStatus: ResponseStatus;
    public userSignOnStatus: UserSignOnStatus;

    public constructor(init?: Partial<SignOffRegisterResponse>) { (Object as any).assign(this, init); }
}

export class SignOnRegisterExtendResponse
{
    public responseStatus: ResponseStatus;
    public userSignOnStatus: UserSignOnStatus;

    public constructor(init?: Partial<SignOnRegisterExtendResponse>) { (Object as any).assign(this, init); }
}

export class GetSignOnRegisterFilterResponse
{
    public responseStatus: ResponseStatus;
    public signOnRegisterFilter: SignOnRegisterFilter;

    public constructor(init?: Partial<GetSignOnRegisterFilterResponse>) { (Object as any).assign(this, init); }
}

export class GetSignOnRegistersResponse
{
    public responseStatus: ResponseStatus;
    public signOnRegisterFilter: SignOnRegisterFilter;
    public signOns: UserSignOnStatus[];

    public constructor(init?: Partial<GetSignOnRegistersResponse>) { (Object as any).assign(this, init); }
}

export class PostSignOnRegistersReportResponse
{
    public sent: boolean;
    public message: string;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostSignOnRegistersReportResponse>) { (Object as any).assign(this, init); }
}

export class PostSignOnRegisterBreakResponse
{
    public signOnRegisterBreak: SignOnRegisterBreak;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostSignOnRegisterBreakResponse>) { (Object as any).assign(this, init); }
}

export class PostSignOnRegisterBreakUpdateResponse
{
    public userSignOnStatus: UserSignOnStatus;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostSignOnRegisterBreakUpdateResponse>) { (Object as any).assign(this, init); }
}

export class GetVenueIncidentCategoryTypesByUsageResponse
{
    public responseStatus: ResponseStatus;
    public venueIncidentCategoryTypes: VenueIncidentCategoryType[];

    public constructor(init?: Partial<GetVenueIncidentCategoryTypesByUsageResponse>) { (Object as any).assign(this, init); }
}

export class PostIncidentCategoryTypesResponse
{
    public responseStatus: ResponseStatus;
    public data: IncidentCategoryType[];
    public paging: Paging;
    public filter: IncidentCategoryTypeFilter;
    public states: State[];

    public constructor(init?: Partial<PostIncidentCategoryTypesResponse>) { (Object as any).assign(this, init); }
}

export class PostIncidentCategoryTypeNewResponse
{
    public incidentCategoryType: IncidentCategoryType;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostIncidentCategoryTypeNewResponse>) { (Object as any).assign(this, init); }
}

export class PostIncidentCategoryTypeResponse
{
    public incidentCategoryType: IncidentCategoryType;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostIncidentCategoryTypeResponse>) { (Object as any).assign(this, init); }
}

export class GetIncidentCategoryTypeByIdResponse
{
    public incidentCategoryType: IncidentCategoryType;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetIncidentCategoryTypeByIdResponse>) { (Object as any).assign(this, init); }
}

export class PostIncidentCategoryTypeHideResponse
{
    public incidentCategoryType: IncidentCategoryType;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostIncidentCategoryTypeHideResponse>) { (Object as any).assign(this, init); }
}

export class GetIncidentCategoryTypeUsageByReportingPeriodResponse
{
    public responseStatus: ResponseStatus;
    public venueIncidentCategoryTypeUsages: VenueIncidentCategoryTypeUsage[];
    public incidentTypeId: number;
    public entryPage: string;
    public reportingPeriodId: number;

    public constructor(init?: Partial<GetIncidentCategoryTypeUsageByReportingPeriodResponse>) { (Object as any).assign(this, init); }
}

export class GetPingResponse
{
    public responseStatus: ResponseStatus;
    public message: string;

    public constructor(init?: Partial<GetPingResponse>) { (Object as any).assign(this, init); }
}

export class GetAppInsightsTestResponse
{
    public responseStatus: ResponseStatus;
    public message: string;

    public constructor(init?: Partial<GetAppInsightsTestResponse>) { (Object as any).assign(this, init); }
}

export class GetDeveloperTestResponse
{
    public responseStatus: ResponseStatus;
    public message: string;

    public constructor(init?: Partial<GetDeveloperTestResponse>) { (Object as any).assign(this, init); }
}

export class GetNotificationTypeResponse
{
    public responseStatus: ResponseStatus;
    public notificationType: NotificationType;

    public constructor(init?: Partial<GetNotificationTypeResponse>) { (Object as any).assign(this, init); }
}

export class GetTimezoneResponse
{
    public responseStatus: ResponseStatus;
    public timezone: Timezone;

    public constructor(init?: Partial<GetTimezoneResponse>) { (Object as any).assign(this, init); }
}

export class PostUserOtherDocumentResponse
{
    public responseStatus: ResponseStatus;
    public userOtherDocuments: UserOtherDocument[];
    public userOtherDocumentsIncludingObsolete: UserOtherDocument[];

    public constructor(init?: Partial<PostUserOtherDocumentResponse>) { (Object as any).assign(this, init); }
}

export class PostUserOtherDocumentRemoveResponse
{
    public userOtherDocuments: UserOtherDocument[];
    public userOtherDocumentsIncludingObsolete: UserOtherDocument[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostUserOtherDocumentRemoveResponse>) { (Object as any).assign(this, init); }
}

export class PostUserComplianceDocumentResponse
{
    public responseStatus: ResponseStatus;
    public userComplianceDocuments: UserComplianceDocument[];
    public userComplianceDocumentsIncludingObsolete: UserComplianceDocument[];

    public constructor(init?: Partial<PostUserComplianceDocumentResponse>) { (Object as any).assign(this, init); }
}

export class PostUserComplianceDocumentRemoveResponse
{
    public userComplianceDocuments: UserComplianceDocument[];
    public userComplianceDocumentsIncludingObsolete: UserComplianceDocument[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostUserComplianceDocumentRemoveResponse>) { (Object as any).assign(this, init); }
}

export class PostAdminUserActionAuditsResponse
{
    public responseStatus: ResponseStatus;
    public items: UserActionAudit[];
    public paging: Paging;

    public constructor(init?: Partial<PostAdminUserActionAuditsResponse>) { (Object as any).assign(this, init); }
}

export class PostUserActionAuditResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostUserActionAuditResponse>) { (Object as any).assign(this, init); }
}

export class GetCheckApprovedUserRoleResponse
{
    public responseStatus: ResponseStatus;
    public hasApproved: boolean;

    public constructor(init?: Partial<GetCheckApprovedUserRoleResponse>) { (Object as any).assign(this, init); }
}

export class SendUserRoleRequestResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SendUserRoleRequestResponse>) { (Object as any).assign(this, init); }
}

export class SendUserRoleRequestDismissResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SendUserRoleRequestDismissResponse>) { (Object as any).assign(this, init); }
}

export class NewUserRoleResponse
{
    public userRole: UserRole;
    public userRoleTypes: UserRoleType[];
    public availableVenues: VenueSelection[];
    public availableSecurityFirms: SecurityFirmSelection[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<NewUserRoleResponse>) { (Object as any).assign(this, init); }
}

export class PostUserRoleResponse
{
    public userRole: UserRole;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostUserRoleResponse>) { (Object as any).assign(this, init); }
}

export class PostDeleteUserRoleResponse
{
    public userRole: UserRole;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostDeleteUserRoleResponse>) { (Object as any).assign(this, init); }
}

export class GetUserRoleResponse
{
    public userRole: UserRole;
    public userRoleTypes: UserRoleType[];
    public userRoleStatus: UserRoleStatus[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetUserRoleResponse>) { (Object as any).assign(this, init); }
}

export class UpdateUserRoleResponse
{
    public userRole: UserRole;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UpdateUserRoleResponse>) { (Object as any).assign(this, init); }
}

export class PostAdminUserRolesResponse
{
    public responseStatus: ResponseStatus;
    public users: UserRoleSummary[];
    public paging: Paging;
    public filter: UserRoleFilter;

    public constructor(init?: Partial<PostAdminUserRolesResponse>) { (Object as any).assign(this, init); }
}

export class PostReEnableUserRoleResponse
{
    public userRole: UserRole;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostReEnableUserRoleResponse>) { (Object as any).assign(this, init); }
}

export class PostUserRolesResponse
{
    public responseStatus: ResponseStatus;
    public userRoles: UserRoleSummary[];
    public paging: Paging;
    public filter: UserRoleFilter;

    public constructor(init?: Partial<PostUserRolesResponse>) { (Object as any).assign(this, init); }
}

export class AnyUserRolesExportResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AnyUserRolesExportResponse>) { (Object as any).assign(this, init); }
}

export class PostUserRolesSuspendResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostUserRolesSuspendResponse>) { (Object as any).assign(this, init); }
}

export class PostUserLoginLastUpdatedResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostUserLoginLastUpdatedResponse>) { (Object as any).assign(this, init); }
}

export class GetLoggedInUsersResponse
{
    public responseStatus: ResponseStatus;
    public loggedInUsers: LoggedInUser[];

    public constructor(init?: Partial<GetLoggedInUsersResponse>) { (Object as any).assign(this, init); }
}

export class GetUserResponse
{
    public responseStatus: ResponseStatus;
    public user: User;
    public avatar: File;
    public states: State[];
    public genders: Gender[];
    public userOtherDocuments: UserOtherDocument[];
    public userOtherDocumentTypes: DocumentType[];
    public userComplianceDocuments: UserComplianceDocument[];
    public userComplianceDocumentTypes: DocumentType[];
    public groupAccessRequests: GroupUser[];

    public constructor(init?: Partial<GetUserResponse>) { (Object as any).assign(this, init); }
}

export class GetUserViewResponse
{
    public responseStatus: ResponseStatus;
    public userView: UserView;
    public avatar: File;

    public constructor(init?: Partial<GetUserViewResponse>) { (Object as any).assign(this, init); }
}

export class GetNewUserResponse
{
    public responseStatus: ResponseStatus;
    public user: User;
    public avatar: File;
    public venues: VenueSelection[];
    public securityFirms: SecurityFirmSelection[];
    public states: State[];
    public genders: Gender[];
    public userOtherDocuments: File[];
    public userOtherDocumentTypes: DocumentType[];
    public userComplianceDocuments: File[];
    public userComplianceDocumentTypes: DocumentType[];
    public groupAccessRequests: GroupUser[];

    public constructor(init?: Partial<GetNewUserResponse>) { (Object as any).assign(this, init); }
}

export class PostNewUserResponse
{
    public responseStatus: ResponseStatus;
    public user: User;

    public constructor(init?: Partial<PostNewUserResponse>) { (Object as any).assign(this, init); }
}

export class PostUpdateUserAvatarResponse
{
    public responseStatus: ResponseStatus;
    public file: File;

    public constructor(init?: Partial<PostUpdateUserAvatarResponse>) { (Object as any).assign(this, init); }
}

export class PostUserResendVerificationEmailResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostUserResendVerificationEmailResponse>) { (Object as any).assign(this, init); }
}

export class PostUserEmailVerificationResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostUserEmailVerificationResponse>) { (Object as any).assign(this, init); }
}

export class PostUpdateUserResponse
{
    public responseStatus: ResponseStatus;
    public user: User;

    public constructor(init?: Partial<PostUpdateUserResponse>) { (Object as any).assign(this, init); }
}

export class PostUserSledCheckResponse
{
    public responseStatus: ResponseStatus;
    public user: User;

    public constructor(init?: Partial<PostUserSledCheckResponse>) { (Object as any).assign(this, init); }
}

export class GetUsersWithDuplicateMobilesResponse
{
    public responseStatus: ResponseStatus;
    public users: User[];
    public search: string;
    public paging: Paging;

    public constructor(init?: Partial<GetUsersWithDuplicateMobilesResponse>) { (Object as any).assign(this, init); }
}

export class PostUserForgotPasswordResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostUserForgotPasswordResponse>) { (Object as any).assign(this, init); }
}

export class PostUserForgotPasswordValidateResponse
{
    public isValid: boolean;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostUserForgotPasswordValidateResponse>) { (Object as any).assign(this, init); }
}

export class PostUserResetPasswordResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostUserResetPasswordResponse>) { (Object as any).assign(this, init); }
}

export class PostUserResetMobileResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostUserResetMobileResponse>) { (Object as any).assign(this, init); }
}

export class PostUserChangePasswordResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostUserChangePasswordResponse>) { (Object as any).assign(this, init); }
}

export class PostRequestForgotPasswordCodeResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostRequestForgotPasswordCodeResponse>) { (Object as any).assign(this, init); }
}

export class PostValidateForgotPasswordCodeResponse
{
    public isValid: boolean;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostValidateForgotPasswordCodeResponse>) { (Object as any).assign(this, init); }
}

export class PostUserResetPasswordWithCodeResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostUserResetPasswordWithCodeResponse>) { (Object as any).assign(this, init); }
}

export class PostAdminUsersResponse
{
    public responseStatus: ResponseStatus;
    public users: User[];
    public paging: Paging;
    public filter: UserFilter;

    public constructor(init?: Partial<PostAdminUsersResponse>) { (Object as any).assign(this, init); }
}

export class PostUserPushTestResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostUserPushTestResponse>) { (Object as any).assign(this, init); }
}

export class PostUserDeleteResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostUserDeleteResponse>) { (Object as any).assign(this, init); }
}

export class PostUserResendVerificationSmsResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostUserResendVerificationSmsResponse>) { (Object as any).assign(this, init); }
}

export class PostValidateSmsCodeResponse
{
    public isValid: boolean;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostValidateSmsCodeResponse>) { (Object as any).assign(this, init); }
}

export class GetUserSessionResponse
{
    public responseStatus: ResponseStatus;
    public userSession: UserSession;

    public constructor(init?: Partial<GetUserSessionResponse>) { (Object as any).assign(this, init); }
}

export class GetUserDetailSessionResponse
{
    public responseStatus: ResponseStatus;
    public userDetailSession: UserDetailSession;

    public constructor(init?: Partial<GetUserDetailSessionResponse>) { (Object as any).assign(this, init); }
}

export class GetUserStatisticsResponse
{
    public responseStatus: ResponseStatus;
    public statistics: HomeStatistics;

    public constructor(init?: Partial<GetUserStatisticsResponse>) { (Object as any).assign(this, init); }
}

export class UserDetailsResponse
{
    public responseStatus: ResponseStatus;
    public user: User;

    public constructor(init?: Partial<UserDetailsResponse>) { (Object as any).assign(this, init); }
}

export class UserVenuesResponse
{
    public responseStatus: ResponseStatus;
    public result: NamedValue[];

    public constructor(init?: Partial<UserVenuesResponse>) { (Object as any).assign(this, init); }
}

export class UserVenuesWithLocationResponse
{
    public responseStatus: ResponseStatus;
    public canSelectNoVenue: boolean;
    public result: VenueWithRange[];

    public constructor(init?: Partial<UserVenuesWithLocationResponse>) { (Object as any).assign(this, init); }
}

export class UserSecurityFirmsResponse
{
    public responseStatus: ResponseStatus;
    public result: NamedValue[];

    public constructor(init?: Partial<UserSecurityFirmsResponse>) { (Object as any).assign(this, init); }
}

export class UserVenueSecurityFirmsResponse
{
    public responseStatus: ResponseStatus;
    public result: NamedValue[];

    public constructor(init?: Partial<UserVenueSecurityFirmsResponse>) { (Object as any).assign(this, init); }
}

export class UserLogoutResponse extends BaseStatelessRequest
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UserLogoutResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetVenueReportingPeriodCurrentDetailsResponse
{
    public responseStatus: ResponseStatus;
    public venueReportingPeriod: VenueReportingPeriod;
    public venueEventReportingPeriods: VenueReportingPeriod[];

    public constructor(init?: Partial<GetVenueReportingPeriodCurrentDetailsResponse>) { (Object as any).assign(this, init); }
}

export class PostVenueReportingPeriodCloseResponse
{
    public responseStatus: ResponseStatus;
    public venueReportingPeriod: VenueReportingPeriod;

    public constructor(init?: Partial<PostVenueReportingPeriodCloseResponse>) { (Object as any).assign(this, init); }
}

export class PostVenueReportingPeriodCreateResponse
{
    public responseStatus: ResponseStatus;
    public venueReportingPeriod: VenueReportingPeriod;

    public constructor(init?: Partial<PostVenueReportingPeriodCreateResponse>) { (Object as any).assign(this, init); }
}

export class GetVenueReportingPeriodResponse
{
    public responseStatus: ResponseStatus;
    public venueReportingPeriod: VenueReportingPeriod;

    public constructor(init?: Partial<GetVenueReportingPeriodResponse>) { (Object as any).assign(this, init); }
}

export class PostVenueReportingPeriodUpsertResponse
{
    public responseStatus: ResponseStatus;
    public venueReportingPeriod: VenueReportingPeriod;

    public constructor(init?: Partial<PostVenueReportingPeriodUpsertResponse>) { (Object as any).assign(this, init); }
}

export class PostVenueReportingPeriodResponse
{
    public responseStatus: ResponseStatus;
    public data: VenueReportingPeriod[];
    public paging: Paging;

    public constructor(init?: Partial<PostVenueReportingPeriodResponse>) { (Object as any).assign(this, init); }
}

export class GetVenueReportingPeriodSignOnStartResponse
{
    public responseStatus: ResponseStatus;
    public userSignOnStart: UserSignOnStart;

    public constructor(init?: Partial<GetVenueReportingPeriodSignOnStartResponse>) { (Object as any).assign(this, init); }
}

export class GetVenueReportingPeriodUserSignOnStatusResponse
{
    public responseStatus: ResponseStatus;
    public isInRangeOfVenue: boolean;
    public allowOutOfRangeSignIn: boolean;
    public acknowledgmentRequired: boolean;
    public userSignOnStatus: UserSignOnStatus;
    public incidentsFromUtc: string;
    public incidentsToUtc: string;
    public incidents: Incident[];

    public constructor(init?: Partial<GetVenueReportingPeriodUserSignOnStatusResponse>) { (Object as any).assign(this, init); }
}

export class GetVenueReportingPeriodsClosedResponse
{
    public responseStatus: ResponseStatus;
    public reportingPeriods: ReportingPeriod[];

    public constructor(init?: Partial<GetVenueReportingPeriodsClosedResponse>) { (Object as any).assign(this, init); }
}

export class PostVenueReportingPeriodCheckDateResponse
{
    public responseStatus: ResponseStatus;
    public isCurrentReportingPeriod: boolean;

    public constructor(init?: Partial<PostVenueReportingPeriodCheckDateResponse>) { (Object as any).assign(this, init); }
}

export class GetSecurityFirmAssociationsResponse
{
    public securityFirmIds: number[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetSecurityFirmAssociationsResponse>) { (Object as any).assign(this, init); }
}

export class GetValidUserVenueSecurityFirmValidResponse
{
    public venueID: number;
    public securityFirmID: number;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetValidUserVenueSecurityFirmValidResponse>) { (Object as any).assign(this, init); }
}

export class PostVenueSecurityFirmSummaryResponse
{
    public data: VenueSecurityFirmSummary[];
    public paging: Paging;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostVenueSecurityFirmSummaryResponse>) { (Object as any).assign(this, init); }
}

export class PostSecurityFirmVenueSummaryResponse
{
    public data: SecurityFirmVenueSummary[];
    public paging: Paging;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostSecurityFirmVenueSummaryResponse>) { (Object as any).assign(this, init); }
}

export class PostVenueSecurityFirmCreateResponse
{
    public securityFirms: SecurityFirmSelection[];
    public venueSecurityFirm: VenueSecurityFirm;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostVenueSecurityFirmCreateResponse>) { (Object as any).assign(this, init); }
}

export class PostVenueSecurityFirmResponse
{
    public venueSecurityFirm: VenueSecurityFirm;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostVenueSecurityFirmResponse>) { (Object as any).assign(this, init); }
}

export class DeleteVenueSecurityFirmResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DeleteVenueSecurityFirmResponse>) { (Object as any).assign(this, init); }
}

export class PostVenueSecurityFirmRestoreResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostVenueSecurityFirmRestoreResponse>) { (Object as any).assign(this, init); }
}

export class GetNewSecurityFirmResponse
{
    public responseStatus: ResponseStatus;
    public securityFirm: SecurityFirm;
    public managerContact: SecurityFirmContact;
    public primaryContact: SecurityFirmContact;
    public states: State[];
    public securityLicenceClasses: SecurityLicenceClass[];
    public otherDocuments: File[];
    public otherDocumentTypes: DocumentType[];
    public complianceDocuments: File[];
    public complianceDocumentTypes: DocumentType[];

    public constructor(init?: Partial<GetNewSecurityFirmResponse>) { (Object as any).assign(this, init); }
}

export class PostNewSecurityFirmResponse
{
    public responseStatus: ResponseStatus;
    public securityFirm: SecurityFirm;

    public constructor(init?: Partial<PostNewSecurityFirmResponse>) { (Object as any).assign(this, init); }
}

export class PostAdminSecurityFirmDismissResponse
{
    public responseStatus: ResponseStatus;
    public securityFirm: SecurityFirm;

    public constructor(init?: Partial<PostAdminSecurityFirmDismissResponse>) { (Object as any).assign(this, init); }
}

export class PostAdminSecurityFirmsResponse
{
    public responseStatus: ResponseStatus;
    public securityFirms: SecurityFirm[];
    public paging: Paging;
    public filter: SecurityFirmFilter;

    public constructor(init?: Partial<PostAdminSecurityFirmsResponse>) { (Object as any).assign(this, init); }
}

export class GetSecurityFirmResponse
{
    public responseStatus: ResponseStatus;
    public includesOptions: boolean;
    public securityFirm: SecurityFirm;
    public states: State[];
    public securityLicenceClasses: SecurityLicenceClass[];

    public constructor(init?: Partial<GetSecurityFirmResponse>) { (Object as any).assign(this, init); }
}

export class PostSecurityFirmSledCheckResponse
{
    public responseStatus: ResponseStatus;
    public securityFirm: SecurityFirm;

    public constructor(init?: Partial<PostSecurityFirmSledCheckResponse>) { (Object as any).assign(this, init); }
}

export class PostSecurityFirmResponse
{
    public responseStatus: ResponseStatus;
    public securityFirm: SecurityFirm;

    public constructor(init?: Partial<PostSecurityFirmResponse>) { (Object as any).assign(this, init); }
}

export class GetSecurityFirmsValidForUserResponse
{
    public responseStatus: ResponseStatus;
    public venueId: number;
    public securityFirms: NamedValue[];

    public constructor(init?: Partial<GetSecurityFirmsValidForUserResponse>) { (Object as any).assign(this, init); }
}

export class GetSecurityFirmsAvailableForUserRoleRequestResponse
{
    public responseStatus: ResponseStatus;
    public securityFirms: SecurityFirmSelection[];

    public constructor(init?: Partial<GetSecurityFirmsAvailableForUserRoleRequestResponse>) { (Object as any).assign(this, init); }
}

export class PostSecurityFirmObsoleteResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostSecurityFirmObsoleteResponse>) { (Object as any).assign(this, init); }
}

export class DeleteSecurityFirmResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DeleteSecurityFirmResponse>) { (Object as any).assign(this, init); }
}

export class AnySecurityFirmsExportResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AnySecurityFirmsExportResponse>) { (Object as any).assign(this, init); }
}

export class GetStateResponse
{
    public responseStatus: ResponseStatus;
    public state: State;

    public constructor(init?: Partial<GetStateResponse>) { (Object as any).assign(this, init); }
}

export class GetNewVenueResponse
{
    public responseStatus: ResponseStatus;
    public venue: Venue;
    public licenseContact: VenueContact;
    public venueContact: VenueContact;
    public states: State[];
    public industryCategories: IndustryCategory[];
    public venueLicenceTypes: VenueLicenceType[];
    public venuePrecincts: VenuePrecinct[];
    public venueTradingHours: VenueTradingHours[];
    public otherDocuments: File[];
    public otherDocumentTypes: DocumentType[];
    public complianceDocuments: File[];
    public complianceDocumentTypes: DocumentType[];

    public constructor(init?: Partial<GetNewVenueResponse>) { (Object as any).assign(this, init); }
}

export class PostNewVenueResponse
{
    public responseStatus: ResponseStatus;
    public venue: Venue;

    public constructor(init?: Partial<PostNewVenueResponse>) { (Object as any).assign(this, init); }
}

export class GetVenueInRangeResponse
{
    public responseStatus: ResponseStatus;
    public isVenueInRange: boolean;

    public constructor(init?: Partial<GetVenueInRangeResponse>) { (Object as any).assign(this, init); }
}

export class GetVenueResponse
{
    public responseStatus: ResponseStatus;
    public venue: Venue;
    public includesOptions: boolean;
    public states: State[];
    public industryCategories: IndustryCategory[];
    public venueLicenceTypes: VenueLicenceType[];
    public venuePrecincts: VenuePrecinct[];
    public venueTradingHours: VenueTradingHours[];

    public constructor(init?: Partial<GetVenueResponse>) { (Object as any).assign(this, init); }
}

export class PostVenueResponse
{
    public responseStatus: ResponseStatus;
    public venue: Venue;

    public constructor(init?: Partial<PostVenueResponse>) { (Object as any).assign(this, init); }
}

export class GetVenuesResponse
{
    public responseStatus: ResponseStatus;
    public venues: Venue[];

    public constructor(init?: Partial<GetVenuesResponse>) { (Object as any).assign(this, init); }
}

export class GetVenuePersonnelResponse
{
    public responseStatus: ResponseStatus;
    public personnel: User[];

    public constructor(init?: Partial<GetVenuePersonnelResponse>) { (Object as any).assign(this, init); }
}

export class GetVenueLocationsResponse
{
    public responseStatus: ResponseStatus;
    public locations: VenueLocation[];

    public constructor(init?: Partial<GetVenueLocationsResponse>) { (Object as any).assign(this, init); }
}

export class GetVenueIncidentFlagTypesResponse
{
    public responseStatus: ResponseStatus;
    public incidentFlagTypes: IncidentFlagType[];

    public constructor(init?: Partial<GetVenueIncidentFlagTypesResponse>) { (Object as any).assign(this, init); }
}

export class GetVenueIncidentPersonnelResponse
{
    public responseStatus: ResponseStatus;
    public personnel: User[];

    public constructor(init?: Partial<GetVenueIncidentPersonnelResponse>) { (Object as any).assign(this, init); }
}

export class GetVenuesValidForUserResponse
{
    public responseStatus: ResponseStatus;
    public venues: NamedValue[];

    public constructor(init?: Partial<GetVenuesValidForUserResponse>) { (Object as any).assign(this, init); }
}

export class PostVenueLoginResponse
{
    public responseStatus: ResponseStatus;
    public isPendingAccessRequest: boolean;
    public isLoggedInToVenue: boolean;

    public constructor(init?: Partial<PostVenueLoginResponse>) { (Object as any).assign(this, init); }
}

export class GetVenueLevelFourUsersReportResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetVenueLevelFourUsersReportResponse>) { (Object as any).assign(this, init); }
}

export class GetVenuesAvailableForUserRoleRequestResponse
{
    public responseStatus: ResponseStatus;
    public venues: VenueSelection[];

    public constructor(init?: Partial<GetVenuesAvailableForUserRoleRequestResponse>) { (Object as any).assign(this, init); }
}

export class PostAdminVenuesResponse
{
    public responseStatus: ResponseStatus;
    public venues: Venue[];
    public paging: Paging;
    public filter: VenueFilter;

    public constructor(init?: Partial<PostAdminVenuesResponse>) { (Object as any).assign(this, init); }
}

export class PostAdminVenueDismissResponse
{
    public responseStatus: ResponseStatus;
    public venue: Venue;

    public constructor(init?: Partial<PostAdminVenueDismissResponse>) { (Object as any).assign(this, init); }
}

export class PostAdminVenueAssocationsResponse
{
    public responseStatus: ResponseStatus;
    public venueSecurityFirms: VenueSecurityFirm[];
    public securityFirmDescendants: SecurityFirmDescendant[];

    public constructor(init?: Partial<PostAdminVenueAssocationsResponse>) { (Object as any).assign(this, init); }
}

export class AdminVenueSecurityFirmRemoveResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AdminVenueSecurityFirmRemoveResponse>) { (Object as any).assign(this, init); }
}

export class AdminVenueSecurityFirmDescendantRemoveResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AdminVenueSecurityFirmDescendantRemoveResponse>) { (Object as any).assign(this, init); }
}

export class PostVenueObsoleteResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PostVenueObsoleteResponse>) { (Object as any).assign(this, init); }
}

export class DeleteVenueResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DeleteVenueResponse>) { (Object as any).assign(this, init); }
}

export class AnyVenuesExportResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AnyVenuesExportResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UnAssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UnAssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConvertSessionToTokenResponse
{
    // @DataMember(Order=1)
    public meta: { [index: string]: string; };

    // @DataMember(Order=2)
    public accessToken: string;

    // @DataMember(Order=3)
    public refreshToken: string;

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ConvertSessionToTokenResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetAccessTokenResponse
{
    // @DataMember(Order=1)
    public accessToken: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetAccessTokenResponse>) { (Object as any).assign(this, init); }
}

// @Route("/externalapiconnection/connect", "GET")
export class GetExternalApiConnection extends BaseStatelessRequest implements IReturn<GetExternalApiConnectionResponse>
{
    public entityType: ngtEntityType;
    public forVenueId: number;
    public forSecurityFirmId: number;

    public constructor(init?: Partial<GetExternalApiConnection>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetExternalApiConnectionResponse(); }
    public getTypeName() { return 'GetExternalApiConnection'; }
}

// @Route("/externalapiconnection/refresh", "POST")
export class PostExternalApiConnectionRefresh extends BaseStatelessRequest implements IReturn<PostExternalApiConnectionRefreshResponse>
{
    public externalApiConnectionId: number;

    public constructor(init?: Partial<PostExternalApiConnectionRefresh>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostExternalApiConnectionRefreshResponse(); }
    public getTypeName() { return 'PostExternalApiConnectionRefresh'; }
}

// @Route("/externalapiconnection/remove", "POST")
export class PostExternalApiConnectionRemove extends BaseStatelessRequest implements IReturn<PostExternalApiConnectionRemoveResponse>
{
    public externalApiConnectionId: number;

    public constructor(init?: Partial<PostExternalApiConnectionRemove>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostExternalApiConnectionRemoveResponse(); }
    public getTypeName() { return 'PostExternalApiConnectionRemove'; }
}

// @Route("/externalapiconnection", "POST")
export class PostExternalApiConnection extends BaseStatelessRequest implements IReturn<PostExternalApiConnectionResponse>
{
    public externalApiConnection: ExternalApiConnection;

    public constructor(init?: Partial<PostExternalApiConnection>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostExternalApiConnectionResponse(); }
    public getTypeName() { return 'PostExternalApiConnection'; }
}

// @Route("/externalapiconnection/roubleremployeemapping", "GET")
export class GetRoublerEmployeeMapping extends BaseStatelessRequest implements IReturn<GetRoublerEmployeeMappingResponse>
{

    public constructor(init?: Partial<GetRoublerEmployeeMapping>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetRoublerEmployeeMappingResponse(); }
    public getTypeName() { return 'GetRoublerEmployeeMapping'; }
}

// @Route("/externalapiconnection/roubleremployeemapping", "POST")
export class PostRoublerEmployeeMapping extends BaseStatelessRequest implements IReturn<GetRoublerEmployeeMappingResponse>
{
    public entityMapping: EntityMapping;

    public constructor(init?: Partial<PostRoublerEmployeeMapping>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetRoublerEmployeeMappingResponse(); }
    public getTypeName() { return 'PostRoublerEmployeeMapping'; }
}

// @Route("/externalapiconnection/roubleremployeeexport", "POST")
export class PostRoublerEmployeeExport extends BaseStatelessRequest implements IReturn<PostRoublerEmployeeExportResponse>
{
    public userId: number;

    public constructor(init?: Partial<PostRoublerEmployeeExport>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostRoublerEmployeeExportResponse(); }
    public getTypeName() { return 'PostRoublerEmployeeExport'; }
}

// @Route("/externalapiconnection/rollerconfiguration/get", "GET")
export class GetRollerConfiguration extends BaseStatelessRequest implements IReturn<GetRollerConfigurationResponse>
{

    public constructor(init?: Partial<GetRollerConfiguration>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetRollerConfigurationResponse(); }
    public getTypeName() { return 'GetRollerConfiguration'; }
}

// @Route("/externalapiconnection/rollerconfiguration/update", "POST")
export class PostRollerConfiguration extends BaseStatelessRequest implements IReturn<PostRollerConfigurationResponse>
{
    public rollerConfiguration: RollerConfiguration;

    public constructor(init?: Partial<PostRollerConfiguration>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostRollerConfigurationResponse(); }
    public getTypeName() { return 'PostRollerConfiguration'; }
}

// @Route("/externalapiconnection/audits")
export class PostExternalApiConnectionWebhookMessages extends BaseStatelessRequest implements IReturn<PostExternalApiConnectionWebhookMessagesResponse>
{
    public pageSize: number;
    public page: number;
    public filter: SimpleFilter;

    public constructor(init?: Partial<PostExternalApiConnectionWebhookMessages>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostExternalApiConnectionWebhookMessagesResponse(); }
    public getTypeName() { return 'PostExternalApiConnectionWebhookMessages'; }
}

// @Route("/externalapiconnection/reprocess")
export class PostExternalApiConnectionWebhookMessageReProcess extends BaseStatelessRequest implements IReturn<PostExternalApiConnectionWebhookMessageReProcessResponse>
{
    public entityType: ngtEntityType;
    public externalApiConnectionWebhookMessageId: number;

    public constructor(init?: Partial<PostExternalApiConnectionWebhookMessageReProcess>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostExternalApiConnectionWebhookMessageReProcessResponse(); }
    public getTypeName() { return 'PostExternalApiConnectionWebhookMessageReProcess'; }
}

// @Route("/externalapiconnection/reprocessfrom")
export class PostExternalApiConnectionWebhookMessagesReProcess extends BaseStatelessRequest implements IReturn<PostExternalApiConnectionWebhookMessagesReProcessResponse>
{
    public entityType: ngtEntityType;
    public fromDate: SimpleDate;
    public tickets: string;

    public constructor(init?: Partial<PostExternalApiConnectionWebhookMessagesReProcess>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostExternalApiConnectionWebhookMessagesReProcessResponse(); }
    public getTypeName() { return 'PostExternalApiConnectionWebhookMessagesReProcess'; }
}

// @Route("/externalapiconnection/webhook")
export class AnyExternalApiConnectionWebhook extends BaseStatelessRequest implements IReturn<AnyExternalApiConnectionWebhookResponse>
{

    public constructor(init?: Partial<AnyExternalApiConnectionWebhook>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new AnyExternalApiConnectionWebhookResponse(); }
    public getTypeName() { return 'AnyExternalApiConnectionWebhook'; }
}

// @Route("/correctionalfacilityinmate/cells", "POST")
export class PostCorrectionalFacilityInMateCells extends BaseStatelessRequest implements IReturn<PostCorrectionalFacilityInMateCellsResponse>
{
    public forVenueId: number;

    public constructor(init?: Partial<PostCorrectionalFacilityInMateCells>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostCorrectionalFacilityInMateCellsResponse(); }
    public getTypeName() { return 'PostCorrectionalFacilityInMateCells'; }
}

// @Route("/correctionalfacilityinmate/create", "POST")
export class PostNewCorrectionalFacilityInMateCells extends BaseStatelessRequest implements IReturn<PostNewCorrectionalFacilityInMateCellsResponse>
{
    public forVenueId: number;
    public search: string;

    public constructor(init?: Partial<PostNewCorrectionalFacilityInMateCells>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostNewCorrectionalFacilityInMateCellsResponse(); }
    public getTypeName() { return 'PostNewCorrectionalFacilityInMateCells'; }
}

// @Route("/correctionalfacilityinmate/cells", "POST")
export class PostCorrectionalFacilityInMateCell extends BaseStatelessRequest implements IReturn<PostCorrectionalFacilityInMateCellResponse>
{
    public correctionalFacilityInMateCell: CorrectionalFacilityInMateCell;

    public constructor(init?: Partial<PostCorrectionalFacilityInMateCell>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostCorrectionalFacilityInMateCellResponse(); }
    public getTypeName() { return 'PostCorrectionalFacilityInMateCell'; }
}

// @Route("/correctionalfacilityinmate/removefromcell", "POST")
export class PostCorrectionalFacilityInMateCellRemove extends BaseStatelessRequest implements IReturn<PostCorrectionalFacilityInMateCellRemoveResponse>
{
    public correctionalFacilityInMateVenueLocationId: number;

    public constructor(init?: Partial<PostCorrectionalFacilityInMateCellRemove>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostCorrectionalFacilityInMateCellRemoveResponse(); }
    public getTypeName() { return 'PostCorrectionalFacilityInMateCellRemove'; }
}

// @Route("/correctionalfacilityinmate/inspect", "POST")
export class PostCorrectionalFacilityInMateCellInspect extends BaseStatelessRequest implements IReturn<PostCorrectionalFacilityInMateCellInspectResponse>
{
    public correctionalFacilityInMateVenueLocationId: number;

    public constructor(init?: Partial<PostCorrectionalFacilityInMateCellInspect>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostCorrectionalFacilityInMateCellInspectResponse(); }
    public getTypeName() { return 'PostCorrectionalFacilityInMateCellInspect'; }
}

// @Route("/correctionalfacilityriskstatus/riskstatuses", "POST")
export class PostCorrectionalFacilityRiskStatuses extends BaseStatelessRequest implements IReturn<PostCorrectionalFacilityRiskStatusesResponse>
{
    public page: number;
    public pageSize: number;
    public forVenueId: number;

    public constructor(init?: Partial<PostCorrectionalFacilityRiskStatuses>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostCorrectionalFacilityRiskStatusesResponse(); }
    public getTypeName() { return 'PostCorrectionalFacilityRiskStatuses'; }
}

// @Route("/correctionalfacilityriskstatus/newriskstatus", "POST")
export class PostNewCorrectionalFacilityRiskStatus extends BaseStatelessRequest implements IReturn<PostCorrectionalFacilityRiskStatusesResponse>
{
    public forVenueId: number;
    public riskStatus: CorrectionalFacilityRiskStatus;

    public constructor(init?: Partial<PostNewCorrectionalFacilityRiskStatus>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostCorrectionalFacilityRiskStatusesResponse(); }
    public getTypeName() { return 'PostNewCorrectionalFacilityRiskStatus'; }
}

// @Route("/externaleventmessageaudits")
export class PostExternalEventMessageAudits extends BaseStatelessRequest implements IReturn<PostExternalEventMessageAuditsResponse>
{
    public pageSize: number;
    public page: number;
    public filter: SimpleFilter;

    public constructor(init?: Partial<PostExternalEventMessageAudits>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostExternalEventMessageAuditsResponse(); }
    public getTypeName() { return 'PostExternalEventMessageAudits'; }
}

// @Route("/externaleventmessageaudit")
export class PostExternalEventMessageAudit extends BaseStatelessRequest implements IReturn<PostExternalEventMessageAuditResponse>
{
    public externalEventMessageAudit: ExternalEventMessageAudit;

    public constructor(init?: Partial<PostExternalEventMessageAudit>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostExternalEventMessageAuditResponse(); }
    public getTypeName() { return 'PostExternalEventMessageAudit'; }
}

// @Route("/externalevents")
export class PostExternalEvents extends BaseStatelessRequest implements IReturn<PostExternalEventsResponse>
{
    public pageSize: number;
    public page: number;
    public filter: SimpleFilter;

    public constructor(init?: Partial<PostExternalEvents>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostExternalEventsResponse(); }
    public getTypeName() { return 'PostExternalEvents'; }
}

// @Route("/externalevents/new")
export class PostExternalEventNew extends BaseStatelessRequest implements IReturn<PostExternalEventNewResponse>
{
    public ngtEntityType: ngtEntityType;
    public forGroupId: number;
    public forVenueId: number;
    public forSecurityFirmId: number;

    public constructor(init?: Partial<PostExternalEventNew>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostExternalEventNewResponse(); }
    public getTypeName() { return 'PostExternalEventNew'; }
}

// @Route("/externalevents/upsert")
export class PostExternalEvent extends BaseStatelessRequest implements IReturn<PostPostExternalEventResponse>
{
    public externalEvent: ExternalEvent;

    public constructor(init?: Partial<PostExternalEvent>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostPostExternalEventResponse(); }
    public getTypeName() { return 'PostExternalEvent'; }
}

// @Route("/externalevents/{ExternalEventId}")
export class GetExternalEvent extends BaseStatelessRequest implements IReturn<GetExternalEventResponse>
{
    public externalEventId: number;

    public constructor(init?: Partial<GetExternalEvent>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetExternalEventResponse(); }
    public getTypeName() { return 'GetExternalEvent'; }
}

// @Route("/externalevent", "POST")
export class PostProcessExternalEvent extends BaseStatelessRequest implements IReturn<PostProcessExternalEventResponse>
{
    public externalEventMessage: ExternalEventMessage;

    public constructor(init?: Partial<PostProcessExternalEvent>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostProcessExternalEventResponse(); }
    public getTypeName() { return 'PostProcessExternalEvent'; }
}

// @Route("/externalevents/copyoptions", "POST")
export class GetExternalEventCopy extends BaseStatelessRequest implements IReturn<GetExternalEventCopyResponse>
{
    public filter: SimpleFilter;

    public constructor(init?: Partial<GetExternalEventCopy>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetExternalEventCopyResponse(); }
    public getTypeName() { return 'GetExternalEventCopy'; }
}

// @Route("/externalevents/copy", "POST")
export class PostExternalEventCopy extends BaseStatelessRequest implements IReturn<PostExternalEventCopyResponse>
{
    public toVenueId: number;
    public externalEventSelections: MultiSelectOption[];

    public constructor(init?: Partial<PostExternalEventCopy>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostExternalEventCopyResponse(); }
    public getTypeName() { return 'PostExternalEventCopy'; }
}

// @Route("/notificationtrigger", "GET")
export class GetNotificationTrigger extends BaseStatelessRequest implements IReturn<GetNotificationTriggerResponse>
{
    public notificationTriggerParentType: ngtNotificationTriggerParentType;
    public parentId: number;
    public forGroupId: number;
    public forVenueId: number;
    public forSecurityFirmId: number;

    public constructor(init?: Partial<GetNotificationTrigger>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetNotificationTriggerResponse(); }
    public getTypeName() { return 'GetNotificationTrigger'; }
}

// @Route("/notificationtrigger", "POST")
export class PostNotificationTrigger extends BaseStatelessRequest implements IReturn<PostNotificationTriggerResponse>
{
    public notificationTrigger: NotificationTrigger;

    public constructor(init?: Partial<PostNotificationTrigger>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostNotificationTriggerResponse(); }
    public getTypeName() { return 'PostNotificationTrigger'; }
}

// @Route("/notificationtrigger/userssummary/{NotificationTriggerId}", "GET")
export class GetNotificationTriggerUsersSummary extends BaseStatelessRequest implements IReturn<GetNotificationTriggerUsersSummaryResponse>
{
    public notificationTriggerId: number;

    public constructor(init?: Partial<GetNotificationTriggerUsersSummary>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetNotificationTriggerUsersSummaryResponse(); }
    public getTypeName() { return 'GetNotificationTriggerUsersSummary'; }
}

// @Route("/signonregistertypes")
export class PostSignOnRegisterTypes extends BaseStatelessRequest implements IReturn<PostSignOnRegisterTypesResponse>
{
    public pageSize: number;
    public page: number;
    public filter: SimpleFilter;

    public constructor(init?: Partial<PostSignOnRegisterTypes>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostSignOnRegisterTypesResponse(); }
    public getTypeName() { return 'PostSignOnRegisterTypes'; }
}

// @Route("/signonregistertypes/new")
export class PostSignOnRegisterTypeNew extends BaseStatelessRequest implements IReturn<PostSignOnRegisterTypeNewResponse>
{
    public forGroupId: number;
    public forVenueId: number;

    public constructor(init?: Partial<PostSignOnRegisterTypeNew>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostSignOnRegisterTypeNewResponse(); }
    public getTypeName() { return 'PostSignOnRegisterTypeNew'; }
}

// @Route("/signonregistertypes/upsert")
export class PostSignOnRegisterType extends BaseStatelessRequest implements IReturn<PostPostSignOnRegisterTypeResponse>
{
    public signOnRegisterType: SignOnRegisterType;

    public constructor(init?: Partial<PostSignOnRegisterType>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostPostSignOnRegisterTypeResponse(); }
    public getTypeName() { return 'PostSignOnRegisterType'; }
}

// @Route("/signonregistertypes/{SignOnRegisterTypeId}")
export class GetSignOnRegisterType extends BaseStatelessRequest implements IReturn<GetSignOnRegisterTypeResponse>
{
    public signOnRegisterTypeId: number;

    public constructor(init?: Partial<GetSignOnRegisterType>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetSignOnRegisterTypeResponse(); }
    public getTypeName() { return 'GetSignOnRegisterType'; }
}

// @Route("/functionalareas")
export class PostFunctionalAreas extends BaseStatelessRequest implements IReturn<PostFunctionalAreasResponse>
{
    public pageSize: number;
    public page: number;
    public filter: SimpleFilter;

    public constructor(init?: Partial<PostFunctionalAreas>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFunctionalAreasResponse(); }
    public getTypeName() { return 'PostFunctionalAreas'; }
}

// @Route("/functionalareas/new")
export class PostFunctionalAreaNew extends BaseStatelessRequest implements IReturn<PostFunctionalAreaNewResponse>
{
    public forGroupId: number;
    public forVenueId: number;

    public constructor(init?: Partial<PostFunctionalAreaNew>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFunctionalAreaNewResponse(); }
    public getTypeName() { return 'PostFunctionalAreaNew'; }
}

// @Route("/functionalareas/upsert")
export class PostFunctionalArea extends BaseStatelessRequest implements IReturn<PostPostFunctionalAreaResponse>
{
    public functionalArea: FunctionalArea;

    public constructor(init?: Partial<PostFunctionalArea>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostPostFunctionalAreaResponse(); }
    public getTypeName() { return 'PostFunctionalArea'; }
}

// @Route("/functionalareas/{FunctionalAreaId}")
export class GetFunctionalArea extends BaseStatelessRequest implements IReturn<GetFunctionalAreaResponse>
{
    public functionalAreaId: number;

    public constructor(init?: Partial<GetFunctionalArea>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetFunctionalAreaResponse(); }
    public getTypeName() { return 'GetFunctionalArea'; }
}

// @Route("/notes")
export class PostNotes extends BaseStatelessRequest implements IReturn<PostNotesResponse>
{
    public pageSize: number;
    public page: number;
    public filter: SimpleFilter;

    public constructor(init?: Partial<PostNotes>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostNotesResponse(); }
    public getTypeName() { return 'PostNotes'; }
}

// @Route("/notes/new")
export class PostNoteNew extends BaseStatelessRequest implements IReturn<PostNoteNewResponse>
{
    public entityType: ngtEntityType;
    public entityId: number;

    public constructor(init?: Partial<PostNoteNew>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostNoteNewResponse(); }
    public getTypeName() { return 'PostNoteNew'; }
}

// @Route("/notes/upsert")
export class PostNote extends BaseStatelessRequest implements IReturn<PostPostNoteResponse>
{
    public note: Note;

    public constructor(init?: Partial<PostNote>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostPostNoteResponse(); }
    public getTypeName() { return 'PostNote'; }
}

// @Route("/notes/{NoteId}")
export class GetNote extends BaseStatelessRequest implements IReturn<GetNoteResponse>
{
    public noteId: number;

    public constructor(init?: Partial<GetNote>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetNoteResponse(); }
    public getTypeName() { return 'GetNote'; }
}

// @Route("/roster", "POST")
export class PostRoster extends BaseStatelessRequest implements IReturn<PostRosterResponse>, IPost
{
    public filter: RosterFilter;

    public constructor(init?: Partial<PostRoster>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostRosterResponse(); }
    public getTypeName() { return 'PostRoster'; }
}

// @Route("/roster/newleave", "POST")
export class PostRosterNewLeave extends BaseStatelessRequest implements IReturn<PostRosterNewLeaveResponse>, IPost
{

    public constructor(init?: Partial<PostRosterNewLeave>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostRosterNewLeaveResponse(); }
    public getTypeName() { return 'PostRosterNewLeave'; }
}

// @Route("/roster/leave", "POST")
export class PostRosterLeave extends BaseStatelessRequest implements IReturn<PostRosterNewLeaveResponse>, IPost
{
    public leaveRequest: LeaveRequest;

    public constructor(init?: Partial<PostRosterLeave>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostRosterNewLeaveResponse(); }
    public getTypeName() { return 'PostRosterLeave'; }
}

// @Route("/sublocations")
export class PostSubLocations extends BaseStatelessRequest implements IReturn<PostSubLocationsResponse>
{
    public pageSize: number;
    public page: number;
    public filter: SimpleFilter;

    public constructor(init?: Partial<PostSubLocations>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostSubLocationsResponse(); }
    public getTypeName() { return 'PostSubLocations'; }
}

// @Route("/sublocations/new")
export class PostSubLocationNew extends BaseStatelessRequest implements IReturn<PostSubLocationNewResponse>
{
    public forGroupId: number;
    public forVenueId: number;

    public constructor(init?: Partial<PostSubLocationNew>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostSubLocationNewResponse(); }
    public getTypeName() { return 'PostSubLocationNew'; }
}

// @Route("/sublocations/upsert")
export class PostSubLocation extends BaseStatelessRequest implements IReturn<PostPostSubLocationResponse>
{
    public subLocation: SubLocation;

    public constructor(init?: Partial<PostSubLocation>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostPostSubLocationResponse(); }
    public getTypeName() { return 'PostSubLocation'; }
}

// @Route("/sublocations/{SubLocationId}")
export class GetSubLocation extends BaseStatelessRequest implements IReturn<GetSubLocationResponse>
{
    public subLocationId: number;

    public constructor(init?: Partial<GetSubLocation>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetSubLocationResponse(); }
    public getTypeName() { return 'GetSubLocation'; }
}

// @Route("/radiochannels")
export class PostRadioChannels extends BaseStatelessRequest implements IReturn<PostRadioChannelsResponse>
{
    public pageSize: number;
    public page: number;
    public filter: SimpleFilter;

    public constructor(init?: Partial<PostRadioChannels>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostRadioChannelsResponse(); }
    public getTypeName() { return 'PostRadioChannels'; }
}

// @Route("/radiochannels/new")
export class PostRadioChannelNew extends BaseStatelessRequest implements IReturn<PostRadioChannelNewResponse>
{
    public forGroupId: number;
    public forVenueId: number;

    public constructor(init?: Partial<PostRadioChannelNew>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostRadioChannelNewResponse(); }
    public getTypeName() { return 'PostRadioChannelNew'; }
}

// @Route("/radiochannels/upsert")
export class PostRadioChannel extends BaseStatelessRequest implements IReturn<PostPostRadioChannelResponse>
{
    public radioChannel: RadioChannel;

    public constructor(init?: Partial<PostRadioChannel>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostPostRadioChannelResponse(); }
    public getTypeName() { return 'PostRadioChannel'; }
}

// @Route("/radiochannels/{RadioChannelId}")
export class GetRadioChannel extends BaseStatelessRequest implements IReturn<GetRadioChannelResponse>
{
    public radioChannelId: number;

    public constructor(init?: Partial<GetRadioChannel>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetRadioChannelResponse(); }
    public getTypeName() { return 'GetRadioChannel'; }
}

// @Route("/processimage/triggerhistoricalProcessing")
export class PostProcessImageTriggerHistoricalProcessing extends BaseStatelessRequest implements IReturn<PostProcessImageTriggerHistoricalProcessingResponse>
{
    public forVenueId: number;
    public fromDate: SimpleDate;

    public constructor(init?: Partial<PostProcessImageTriggerHistoricalProcessing>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostProcessImageTriggerHistoricalProcessingResponse(); }
    public getTypeName() { return 'PostProcessImageTriggerHistoricalProcessing'; }
}

// @Route("/processimage/temporaryfile")
export class PostProcessImageTemporaryFile extends BaseStatelessRequest implements IReturn<PostProcessImageTemporaryFileResponse>
{
    public filePath: string;

    public constructor(init?: Partial<PostProcessImageTemporaryFile>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostProcessImageTemporaryFileResponse(); }
    public getTypeName() { return 'PostProcessImageTemporaryFile'; }
}

// @Route("/processimage/incidentdocument")
export class PostProcessImageIncidentDocument extends BaseStatelessRequest implements IReturn<PostProcessImageIncidentDocumentResponse>
{
    public incidentDocumentId: number;
    public saveToDocument: boolean;

    public constructor(init?: Partial<PostProcessImageIncidentDocument>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostProcessImageIncidentDocumentResponse(); }
    public getTypeName() { return 'PostProcessImageIncidentDocument'; }
}

// @Route("/processimage/incidentpatronphoto")
export class PostProcessImageIncidentPatronPhoto extends BaseStatelessRequest implements IReturn<PostProcessImageIncidentPatronPhotoResponse>
{
    public incidentPatronPhotoId: number;
    public saveToDocument: boolean;

    public constructor(init?: Partial<PostProcessImageIncidentPatronPhoto>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostProcessImageIncidentPatronPhotoResponse(); }
    public getTypeName() { return 'PostProcessImageIncidentPatronPhoto'; }
}

// @Route("/facialrecognitionpoi/{IncidentId}", "POST")
export class PostFacialRecognitionPOIRegistration extends BaseStatelessRequest implements IReturn<PostFacialRecognitionPOIRegistrationResponse>
{
    public incidentId: number;

    public constructor(init?: Partial<PostFacialRecognitionPOIRegistration>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionPOIRegistrationResponse(); }
    public getTypeName() { return 'PostFacialRecognitionPOIRegistration'; }
}

// @Route("/facialrecognitionvenuepoi/{VenueId}", "POST")
export class PostFacialRecognitionPOIVenueRegistration extends BaseStatelessRequest implements IReturn<PostFacialRecognitionPOIVenueRegistrationResponse>
{
    public forVenueId: number;

    public constructor(init?: Partial<PostFacialRecognitionPOIVenueRegistration>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionPOIVenueRegistrationResponse(); }
    public getTypeName() { return 'PostFacialRecognitionPOIVenueRegistration'; }
}

// @Route("/facialrecognitiongrouppoi", "POST")
export class PostFacialRecognitionPOIGroupRegistration extends BaseStatelessRequest implements IReturn<PostFacialRecognitionPOIGroupRegistrationResponse>
{
    public forGroupId: number;

    public constructor(init?: Partial<PostFacialRecognitionPOIGroupRegistration>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionPOIGroupRegistrationResponse(); }
    public getTypeName() { return 'PostFacialRecognitionPOIGroupRegistration'; }
}

// @Route("/facialrecognitionexistingpoi", "POST")
export class PostFacialRecognitionPOIForExistingRegistration extends BaseStatelessRequest implements IReturn<PostFacialRecognitionPOIForExistingRegistrationResponse>
{
    public forVenueId: number;
    public forGroupId: number;
    public facialRecognitionWatchlistId: number;
    public poiGuid: string;

    public constructor(init?: Partial<PostFacialRecognitionPOIForExistingRegistration>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionPOIForExistingRegistrationResponse(); }
    public getTypeName() { return 'PostFacialRecognitionPOIForExistingRegistration'; }
}

// @Route("/facialrecognitionpoi/{IncidentId}/scanfaces", "POST")
export class PostFacialRecognitionPOIRegistrationScanFaces extends BaseStatelessRequest implements IReturn<PostFacialRecognitionPOIRegistrationScanFacesResponse>
{
    public poiRegistration: POIRegistration;

    public constructor(init?: Partial<PostFacialRecognitionPOIRegistrationScanFaces>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionPOIRegistrationScanFacesResponse(); }
    public getTypeName() { return 'PostFacialRecognitionPOIRegistrationScanFaces'; }
}

// @Route("/facialrecognitionpoi/{IncidentId}/searchface", "POST")
export class PostFacialRecognitionPOIRegistrationSearchFace extends BaseStatelessRequest implements IReturn<PostFacialRecognitionPOIRegistrationSearchFaceResponse>
{
    public poiRegistration: POIRegistration;

    public constructor(init?: Partial<PostFacialRecognitionPOIRegistrationSearchFace>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionPOIRegistrationSearchFaceResponse(); }
    public getTypeName() { return 'PostFacialRecognitionPOIRegistrationSearchFace'; }
}

// @Route("/facialrecognitionpoi/{IncidentId}/save", "POST")
export class PostFacialRecognitionPOIRegistrationSave extends BaseStatelessRequest implements IReturn<PostFacialRecognitionPOIRegistrationSaveResponse>
{
    public poiRegistration: POIRegistration;

    public constructor(init?: Partial<PostFacialRecognitionPOIRegistrationSave>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionPOIRegistrationSaveResponse(); }
    public getTypeName() { return 'PostFacialRecognitionPOIRegistrationSave'; }
}

// @Route("/poiincidents", "POST")
export class PostFacialRecognitionPOIIncidents extends BaseStatelessRequest implements IReturn<PostFacialRecognitionPOIIncidentsResponse>
{
    public facialRecognitionPOIIncidentId: number;
    public forVenueId: number;
    public facialRecognitionWatchlistId: number;
    public poiGuid: string;
    public paging: Paging;

    public constructor(init?: Partial<PostFacialRecognitionPOIIncidents>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionPOIIncidentsResponse(); }
    public getTypeName() { return 'PostFacialRecognitionPOIIncidents'; }
}

// @Route("/poiguids", "POST")
export class PostFacialRecognitionPOIGuids extends BaseStatelessRequest implements IReturn<PostFacialRecognitionPOIGuidsResponse>
{
    public forVenueId: number;
    public poiGuids: string[];

    public constructor(init?: Partial<PostFacialRecognitionPOIGuids>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionPOIGuidsResponse(); }
    public getTypeName() { return 'PostFacialRecognitionPOIGuids'; }
}

// @Route("/poiscannedid", "POST")
export class PostFacialRecognitionPOIScannedID extends BaseStatelessRequest implements IReturn<PostFacialRecognitionPOIScannedIDResponse>
{
    public forVenueId: number;
    public poiGuid: string;

    public constructor(init?: Partial<PostFacialRecognitionPOIScannedID>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionPOIScannedIDResponse(); }
    public getTypeName() { return 'PostFacialRecognitionPOIScannedID'; }
}

// @Route("/facialrecognitionpoiincident/generateappearance", "POST")
export class PacialRecognitionPOIIncidentGenerateAppearance extends BaseStatelessRequest implements IReturn<PacialRecognitionPOIIncidentGenerateAppearanceResponse>
{
    public poiGuid: string;
    public facialRecognitionWatchlistId: number;
    public facialRecognitionCameraId: number;
    public facialRecognitionEventId: number;

    public constructor(init?: Partial<PacialRecognitionPOIIncidentGenerateAppearance>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PacialRecognitionPOIIncidentGenerateAppearanceResponse(); }
    public getTypeName() { return 'PacialRecognitionPOIIncidentGenerateAppearance'; }
}

// @Route("/facialrecognitionpoiscan/{IncidentId}", "POST")
export class PostFacialRecognitionPOIScan extends BaseStatelessRequest implements IReturn<PostFacialRecognitionPOIScanResponse>
{
    public incidentId: number;
    public poiScan: POIScan;

    public constructor(init?: Partial<PostFacialRecognitionPOIScan>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionPOIScanResponse(); }
    public getTypeName() { return 'PostFacialRecognitionPOIScan'; }
}

// @Route("/facialrecognitionpoiexpiryfailed", "POST")
export class PostFacialRecognitionExpiryFailed extends BaseStatelessRequest implements IReturn<PostFacialRecognitionExpiryFailedResponse>
{
    public pageSize: number;
    public page: number;
    public filter: SimpleFilter;

    public constructor(init?: Partial<PostFacialRecognitionExpiryFailed>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionExpiryFailedResponse(); }
    public getTypeName() { return 'PostFacialRecognitionExpiryFailed'; }
}

// @Route("/facialrecognitionpoiexpiryretry", "POST")
export class PostFacialRecognitionExpiryRetry extends BaseStatelessRequest implements IReturn<PostFacialRecognitionExpiryRetryResponse>
{
    public filter: SimpleFilter;
    public facialRecognitionPOIIncidentId: number;

    public constructor(init?: Partial<PostFacialRecognitionExpiryRetry>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionExpiryRetryResponse(); }
    public getTypeName() { return 'PostFacialRecognitionExpiryRetry'; }
}

// @Route("/facialrecognitionpoiexpirydismiss", "POST")
export class PostFacialRecognitionExpiryDismiss extends BaseStatelessRequest implements IReturn<PostFacialRecognitionExpiryDismissResponse>
{
    public facialRecognitionPOIIncidentId: number;

    public constructor(init?: Partial<PostFacialRecognitionExpiryDismiss>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionExpiryDismissResponse(); }
    public getTypeName() { return 'PostFacialRecognitionExpiryDismiss'; }
}

// @Route("/admin/patch/connectionpools")
export class GetPatchConnectionPools implements IReturn<GetPatchConnectionPoolResponse>
{

    public constructor(init?: Partial<GetPatchConnectionPools>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetPatchConnectionPoolResponse(); }
    public getTypeName() { return 'GetPatchConnectionPools'; }
}

// @Route("/admin/text")
export class GetAdminTest implements IReturn<GetAdminTestResponse>
{

    public constructor(init?: Partial<GetAdminTest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetAdminTestResponse(); }
    public getTypeName() { return 'GetAdminTest'; }
}

// @Route("/schedules", "GET")
export class GetSchedules extends BaseStatelessRequest implements IReturn<GetSchedulesResponse>
{

    public constructor(init?: Partial<GetSchedules>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetSchedulesResponse(); }
    public getTypeName() { return 'GetSchedules'; }
}

// @Route("/schedules/new")
export class PostScheduleNew extends BaseStatelessRequest implements IReturn<PostScheduleNewResponse>
{

    public constructor(init?: Partial<PostScheduleNew>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostScheduleNewResponse(); }
    public getTypeName() { return 'PostScheduleNew'; }
}

// @Route("/schedules/upsert")
export class PostSchedule extends BaseStatelessRequest implements IReturn<PostScheduleResponse>
{
    public schedule: Schedule;

    public constructor(init?: Partial<PostSchedule>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostScheduleResponse(); }
    public getTypeName() { return 'PostSchedule'; }
}

// @Route("/schedules/{ScheduleId}")
export class GetSchedule extends BaseStatelessRequest implements IReturn<GetScheduleResponse>
{
    public scheduleId: number;

    public constructor(init?: Partial<GetSchedule>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetScheduleResponse(); }
    public getTypeName() { return 'GetSchedule'; }
}

// @Route("/schedules/copy")
export class PostScheduleCopy extends BaseStatelessRequest implements IReturn<PostScheduleCopyResponse>
{
    public scheduleId: number;

    public constructor(init?: Partial<PostScheduleCopy>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostScheduleCopyResponse(); }
    public getTypeName() { return 'PostScheduleCopy'; }
}

// @Route("/schedules/{ScheduleId}/process")
export class PostScheduleProcess extends BaseStatelessRequest implements IReturn<PostScheduleProcessResponse>
{
    public scheduleId: number;

    public constructor(init?: Partial<PostScheduleProcess>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostScheduleProcessResponse(); }
    public getTypeName() { return 'PostScheduleProcess'; }
}

// @Route("/schedulelogs", "POST")
export class PostScheduleLogs extends BaseStatelessRequest implements IReturn<PostScheduleLogsResponse>
{
    public scheduleId: number;
    public paging: Paging;

    public constructor(init?: Partial<PostScheduleLogs>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostScheduleLogsResponse(); }
    public getTypeName() { return 'PostScheduleLogs'; }
}

// @Route("/schedulelog/delete", "POST")
export class PostScheduleLogDelete extends BaseStatelessRequest implements IReturn<PostScheduleLogDeleteResponse>
{
    public scheduleId: number;
    public paging: Paging;
    public scheduleLogId: number;

    public constructor(init?: Partial<PostScheduleLogDelete>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostScheduleLogDeleteResponse(); }
    public getTypeName() { return 'PostScheduleLogDelete'; }
}

// @Route("/facialrecognitionwatchlists", "GET")
export class GetFacialRecognitionWatchlists extends BaseStatelessRequest implements IReturn<GetFacialRecognitionWatchlistsResponse>
{
    public forVenueId: number;
    public forGroupId: number;

    public constructor(init?: Partial<GetFacialRecognitionWatchlists>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetFacialRecognitionWatchlistsResponse(); }
    public getTypeName() { return 'GetFacialRecognitionWatchlists'; }
}

// @Route("/facialrecognitionwatchlists/new")
export class PostFacialRecognitionWatchlistNew extends BaseStatelessRequest implements IReturn<PostFacialRecognitionWatchlistNewResponse>
{
    public forVenueId: number;
    public forGroupId: number;

    public constructor(init?: Partial<PostFacialRecognitionWatchlistNew>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionWatchlistNewResponse(); }
    public getTypeName() { return 'PostFacialRecognitionWatchlistNew'; }
}

// @Route("/facialrecognitionwatchlist", "POST")
export class PostFacialRecognitionWatchlist extends BaseStatelessRequest implements IReturn<PostFacialRecognitionWatchlistResponse>
{
    public facialRecognitionWatchlist: FacialRecognitionWatchlist;

    public constructor(init?: Partial<PostFacialRecognitionWatchlist>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionWatchlistResponse(); }
    public getTypeName() { return 'PostFacialRecognitionWatchlist'; }
}

// @Route("/facialrecognitionwatchlist/{FacialRecognitionWatchlistId}")
export class GetFacialRecognitionWatchlist extends BaseStatelessRequest implements IReturn<GetFacialRecognitionWatchlistResponse>
{
    public facialRecognitionWatchlistId: number;

    public constructor(init?: Partial<GetFacialRecognitionWatchlist>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetFacialRecognitionWatchlistResponse(); }
    public getTypeName() { return 'GetFacialRecognitionWatchlist'; }
}

// @Route("/facialrecognitionwatchlist/sync", "POST")
export class PostFacialRecognitionSyncWatchlists extends BaseStatelessRequest implements IReturn<PostFacialRecognitionSyncWatchlistsResponse>
{
    public forVenueId: number;
    public forGroupId: number;

    public constructor(init?: Partial<PostFacialRecognitionSyncWatchlists>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionSyncWatchlistsResponse(); }
    public getTypeName() { return 'PostFacialRecognitionSyncWatchlists'; }
}

// @Route("/facialrecognitionwatchlist/{FacialRecognitionWatchlistId}/pois", "POST")
export class PostFacialRecognitionWatchlistPois extends BaseStatelessRequest implements IReturn<PostFacialRecognitionWatchlistPoisResponse>
{
    public pageSize: number;
    public page: number;
    public filter: FacialRecognitionWatchlistPoisFilter;

    public constructor(init?: Partial<PostFacialRecognitionWatchlistPois>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionWatchlistPoisResponse(); }
    public getTypeName() { return 'PostFacialRecognitionWatchlistPois'; }
}

// @Route("/facialrecognitionwatchlist/{FacialRecognitionWatchlistId}/poi", "POST")
export class PostFacialRecognitionWatchlistPoi extends BaseStatelessRequest implements IReturn<PostFacialRecognitionWatchlistPoiResponse>
{
    public facialRecognitionWatchlistPoi: FacialRecognitionWatchlistPoi;
    public pageSize: number;
    public page: number;
    public filter: FacialRecognitionWatchlistPoisFilter;

    public constructor(init?: Partial<PostFacialRecognitionWatchlistPoi>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionWatchlistPoiResponse(); }
    public getTypeName() { return 'PostFacialRecognitionWatchlistPoi'; }
}

// @Route("/facialrecognitionwatchlist/{FacialRecognitionWatchlistId}/pois/remove", "POST")
export class PostFacialRecognitionWatchlistPoisRemove extends BaseStatelessRequest implements IReturn<PostFacialRecognitionWatchlistPoisRemoveResponse>
{
    public facialRecognitionWatchlistId: number;
    public poiGuid: string;

    public constructor(init?: Partial<PostFacialRecognitionWatchlistPoisRemove>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionWatchlistPoisRemoveResponse(); }
    public getTypeName() { return 'PostFacialRecognitionWatchlistPoisRemove'; }
}

// @Route("/facialrecognitionwatchlists/newimport")
export class PostFacialRecognitionWatchlistNewImport extends BaseStatelessRequest implements IReturn<PostFacialRecognitionWatchlistNewImportResponse>
{
    public forVenueId: number;
    public forGroupId: number;

    public constructor(init?: Partial<PostFacialRecognitionWatchlistNewImport>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionWatchlistNewImportResponse(); }
    public getTypeName() { return 'PostFacialRecognitionWatchlistNewImport'; }
}

// @Route("/facialrecognitionwatchlists/import", "POST")
export class PostFacialRecognitionWatchlistImport extends BaseStatelessRequest implements IReturn<PostFacialRecognitionWatchlistImportResponse>
{
    public facialRecognitionWatchlistImports: FacialRecognitionWatchlistImport[];

    public constructor(init?: Partial<PostFacialRecognitionWatchlistImport>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionWatchlistImportResponse(); }
    public getTypeName() { return 'PostFacialRecognitionWatchlistImport'; }
}

// @Route("/facialrecognitionwatchlists/newmigration", "POST")
export class PostFacialRecognitionWatchlistNewMigration extends BaseStatelessRequest implements IReturn<PostFacialRecognitionWatchlistNewMigrationResponse>
{
    public forVenueId: number;
    public facialRecognitionWatchlistId: number;

    public constructor(init?: Partial<PostFacialRecognitionWatchlistNewMigration>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionWatchlistNewMigrationResponse(); }
    public getTypeName() { return 'PostFacialRecognitionWatchlistNewMigration'; }
}

// @Route("/facialrecognitionwatchlist/migration", "POST")
export class PostFacialRecognitionWatchlistMigration extends BaseStatelessRequest implements IReturn<PostFacialRecognitionWatchlistMigrationResponse>
{
    public facialRecognitionWatchlistMigration: FacialRecognitionWatchlistMigration;

    public constructor(init?: Partial<PostFacialRecognitionWatchlistMigration>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionWatchlistMigrationResponse(); }
    public getTypeName() { return 'PostFacialRecognitionWatchlistMigration'; }
}

// @Route("/facialrecognitionwatchlist/processmigrations", "POST")
export class PostFacialRecognitionWatchlistProcessMigration extends BaseStatelessRequest implements IReturn<PostFacialRecognitionWatchlistProcessMigrationResponse>
{

    public constructor(init?: Partial<PostFacialRecognitionWatchlistProcessMigration>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionWatchlistProcessMigrationResponse(); }
    public getTypeName() { return 'PostFacialRecognitionWatchlistProcessMigration'; }
}

// @Route("/facialrecognitionwatchlists/cancelmigration", "POST")
export class PostFacialRecognitionWatchlistCancelMigration extends BaseStatelessRequest implements IReturn<PostFacialRecognitionWatchlistCancelMigrationResponse>
{
    public facialRecognitionWatchlistMigrationId: number;

    public constructor(init?: Partial<PostFacialRecognitionWatchlistCancelMigration>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionWatchlistCancelMigrationResponse(); }
    public getTypeName() { return 'PostFacialRecognitionWatchlistCancelMigration'; }
}

// @Route("/facialrecognitionwatchlists/newmove", "POST")
export class PostFacialRecognitionWatchlistNewMove extends BaseStatelessRequest implements IReturn<PostFacialRecognitionWatchlistNewMoveResponse>
{
    public forVenueId: number;
    public facialRecognitionWatchlistId: number;

    public constructor(init?: Partial<PostFacialRecognitionWatchlistNewMove>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionWatchlistNewMoveResponse(); }
    public getTypeName() { return 'PostFacialRecognitionWatchlistNewMove'; }
}

// @Route("/facialrecognitionwatchlist/move", "POST")
export class PostFacialRecognitionWatchlistMove extends BaseStatelessRequest implements IReturn<PostFacialRecognitionWatchlistMoveResponse>
{
    public facialRecognitionWatchlistMove: FacialRecognitionWatchlistMove;

    public constructor(init?: Partial<PostFacialRecognitionWatchlistMove>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionWatchlistMoveResponse(); }
    public getTypeName() { return 'PostFacialRecognitionWatchlistMove'; }
}

// @Route("/facialrecognitionautomaticenrolments")
export class PostFacialRecognitionAutomaticEnrolments extends BaseStatelessRequest implements IReturn<PostFacialRecognitionAutomaticEnrolmentsResponse>
{
    public forVenueId: number;

    public constructor(init?: Partial<PostFacialRecognitionAutomaticEnrolments>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionAutomaticEnrolmentsResponse(); }
    public getTypeName() { return 'PostFacialRecognitionAutomaticEnrolments'; }
}

// @Route("/facialrecognitionautomaticenrolments/new")
export class PostFacialRecognitionAutomaticEnrolmentNew extends BaseStatelessRequest implements IReturn<PostFacialRecognitionAutomaticEnrolmentNewResponse>
{
    public forVenueId: number;

    public constructor(init?: Partial<PostFacialRecognitionAutomaticEnrolmentNew>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionAutomaticEnrolmentNewResponse(); }
    public getTypeName() { return 'PostFacialRecognitionAutomaticEnrolmentNew'; }
}

// @Route("/facialrecognitionautomaticenrolments/upsert")
export class PostFacialRecognitionAutomaticEnrolment extends BaseStatelessRequest implements IReturn<PostPostFacialRecognitionAutomaticEnrolmentResponse>
{
    public facialRecognitionAutomaticEnrolment: FacialRecognitionAutomaticEnrolment;

    public constructor(init?: Partial<PostFacialRecognitionAutomaticEnrolment>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostPostFacialRecognitionAutomaticEnrolmentResponse(); }
    public getTypeName() { return 'PostFacialRecognitionAutomaticEnrolment'; }
}

// @Route("/facialrecognitionautomaticenrolments/{facialrecognitionautomaticenrolmentId}")
export class GetFacialRecognitionAutomaticEnrolment extends BaseStatelessRequest implements IReturn<GetFacialRecognitionAutomaticEnrolmentResponse>
{
    public facialRecognitionAutomaticEnrolmentId: number;

    public constructor(init?: Partial<GetFacialRecognitionAutomaticEnrolment>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetFacialRecognitionAutomaticEnrolmentResponse(); }
    public getTypeName() { return 'GetFacialRecognitionAutomaticEnrolment'; }
}

// @Route("/facialrecognitionautomaticenrolments/{facialrecognitionautomaticenrolmentId}/process")
export class PostFacialRecognitionAutomaticEnrolmentProcess extends BaseStatelessRequest implements IReturn<PostFacialRecognitionAutomaticEnrolmentProcessResponse>
{
    public facialRecognitionAutomaticEnrolmentId: number;
    public simpleDate: SimpleDate;

    public constructor(init?: Partial<PostFacialRecognitionAutomaticEnrolmentProcess>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionAutomaticEnrolmentProcessResponse(); }
    public getTypeName() { return 'PostFacialRecognitionAutomaticEnrolmentProcess'; }
}

// @Route("/facialrecognitionwatchlisttypes")
export class PostFacialRecognitionWatchlistTypes extends BaseStatelessRequest implements IReturn<PostFacialRecognitionWatchlistTypesResponse>
{
    public pageSize: number;
    public page: number;
    public filter: SimpleFilter;

    public constructor(init?: Partial<PostFacialRecognitionWatchlistTypes>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionWatchlistTypesResponse(); }
    public getTypeName() { return 'PostFacialRecognitionWatchlistTypes'; }
}

// @Route("/facialrecognitionwatchlisttypes/new")
export class PostFacialRecognitionWatchlistTypeNew extends BaseStatelessRequest implements IReturn<PostFacialRecognitionWatchlistTypeNewResponse>
{

    public constructor(init?: Partial<PostFacialRecognitionWatchlistTypeNew>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionWatchlistTypeNewResponse(); }
    public getTypeName() { return 'PostFacialRecognitionWatchlistTypeNew'; }
}

// @Route("/facialrecognitionwatchlisttypes/upsert")
export class PostFacialRecognitionWatchlistType extends BaseStatelessRequest implements IReturn<PostPostFacialRecognitionWatchlistTypeResponse>
{
    public facialRecognitionWatchlistType: FacialRecognitionWatchlistType;

    public constructor(init?: Partial<PostFacialRecognitionWatchlistType>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostPostFacialRecognitionWatchlistTypeResponse(); }
    public getTypeName() { return 'PostFacialRecognitionWatchlistType'; }
}

// @Route("/facialrecognitionwatchlisttypes/{FacialRecognitionWatchlistTypeId}")
export class GetFacialRecognitionWatchlistType extends BaseStatelessRequest implements IReturn<GetFacialRecognitionWatchlistTypeResponse>
{
    public facialRecognitionWatchlistTypeId: number;

    public constructor(init?: Partial<GetFacialRecognitionWatchlistType>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetFacialRecognitionWatchlistTypeResponse(); }
    public getTypeName() { return 'GetFacialRecognitionWatchlistType'; }
}

// @Route("/facialrecognitionservers/{ForVenueId}", "GET")
export class GetFacialRecognitionServers extends BaseStatelessRequest implements IReturn<GetFacialRecognitionServersResponse>
{
    public forVenueId: number;

    public constructor(init?: Partial<GetFacialRecognitionServers>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetFacialRecognitionServersResponse(); }
    public getTypeName() { return 'GetFacialRecognitionServers'; }
}

// @Route("/facialrecognitionservers/new")
export class PostFacialRecognitionServerNew extends BaseStatelessRequest implements IReturn<PostFacialRecognitionServerNewResponse>
{
    public forVenueId: number;

    public constructor(init?: Partial<PostFacialRecognitionServerNew>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionServerNewResponse(); }
    public getTypeName() { return 'PostFacialRecognitionServerNew'; }
}

// @Route("/facialrecognitionservers/upsert")
export class PostFacialRecognitionServer extends BaseStatelessRequest implements IReturn<PostPostFacialRecognitionServerResponse>
{
    public facialRecognitionServer: FacialRecognitionServer;

    public constructor(init?: Partial<PostFacialRecognitionServer>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostPostFacialRecognitionServerResponse(); }
    public getTypeName() { return 'PostFacialRecognitionServer'; }
}

// @Route("/facialrecognitionservers/{FacialRecognitionServerId}")
export class GetFacialRecognitionServer extends BaseStatelessRequest implements IReturn<GetFacialRecognitionServerResponse>
{
    public facialRecognitionServerId: number;

    public constructor(init?: Partial<GetFacialRecognitionServer>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetFacialRecognitionServerResponse(); }
    public getTypeName() { return 'GetFacialRecognitionServer'; }
}

// @Route("/facialrecognitionservers/test", "POST")
export class PostFacialRecognitionServerTest extends BaseStatelessRequest implements IReturn<PostFacialRecognitionServerTestResponse>
{
    public facialRecognitionServerId: number;

    public constructor(init?: Partial<PostFacialRecognitionServerTest>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionServerTestResponse(); }
    public getTypeName() { return 'PostFacialRecognitionServerTest'; }
}

// @Route("/facialrecognitionservers/eventstreaming/configuration", "GET")
export class GetFacialRecognitionServerEventStreamingConfiguration extends BaseStatelessRequest implements IReturn<GetFacialRecognitionServerEventStreamingConfigurationResponse>
{
    public apiKey: string;

    public constructor(init?: Partial<GetFacialRecognitionServerEventStreamingConfiguration>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetFacialRecognitionServerEventStreamingConfigurationResponse(); }
    public getTypeName() { return 'GetFacialRecognitionServerEventStreamingConfiguration'; }
}

// @Route("/facialrecognitionservers/eventstreaming/verification", "POST")
export class PostFacialRecognitionServerEventStreamingVerification extends BaseStatelessRequest implements IReturn<PostFacialRecognitionServerEventStreamingVerificationResponse>
{
    public apiKey: string;
    public facialRecognitionServerId: number;
    public verified: boolean;
    public error: string;

    public constructor(init?: Partial<PostFacialRecognitionServerEventStreamingVerification>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionServerEventStreamingVerificationResponse(); }
    public getTypeName() { return 'PostFacialRecognitionServerEventStreamingVerification'; }
}

// @Route("/facialrecognitionservers/clone")
export class PostFacialRecognitionServerClone extends BaseStatelessRequest implements IReturn<PostFacialRecognitionServerCloneResponse>
{
    public facialRecognitionServerId: number;
    public toVenueId: number;

    public constructor(init?: Partial<PostFacialRecognitionServerClone>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionServerCloneResponse(); }
    public getTypeName() { return 'PostFacialRecognitionServerClone'; }
}

// @Route("/groups", "POST")
export class GetGroups extends BaseStatelessRequest implements IReturn<GetGroupsResponse>
{
    public pageSize: number;
    public page: number;
    public filter: GroupFilter;

    public constructor(init?: Partial<GetGroups>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetGroupsResponse(); }
    public getTypeName() { return 'GetGroups'; }
}

// @Route("/groups/selection", "GET")
export class GetGroupForSelection extends BaseStatelessRequest implements IReturn<GetGroupForSelectionResponse>
{

    public constructor(init?: Partial<GetGroupForSelection>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetGroupForSelectionResponse(); }
    public getTypeName() { return 'GetGroupForSelection'; }
}

// @Route("/groups/new")
export class PostGroupNew extends BaseStatelessRequest implements IReturn<PostGroupNewResponse>
{

    public constructor(init?: Partial<PostGroupNew>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostGroupNewResponse(); }
    public getTypeName() { return 'PostGroupNew'; }
}

// @Route("/groups/{groupId}")
export class GetGroup extends BaseStatelessRequest implements IReturn<GetGroupResponse>
{
    public groupId: number;

    public constructor(init?: Partial<GetGroup>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetGroupResponse(); }
    public getTypeName() { return 'GetGroup'; }
}

// @Route("/groups/{groupId}")
export class PostGroup extends BaseStatelessRequest implements IReturn<PostGroupResponse>
{
    public groupId: number;
    public group: Group;

    public constructor(init?: Partial<PostGroup>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostGroupResponse(); }
    public getTypeName() { return 'PostGroup'; }
}

// @Route("/groups/availablevenues/{groupId}")
export class GetGroupAvailableVenues extends BaseStatelessRequest implements IReturn<GetGroupAvailableVenuesResponse>
{
    public groupId: number;

    public constructor(init?: Partial<GetGroupAvailableVenues>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetGroupAvailableVenuesResponse(); }
    public getTypeName() { return 'GetGroupAvailableVenues'; }
}

// @Route("/groups/venues", "POST")
export class PostGroupVenues extends BaseStatelessRequest implements IReturn<PostGroupVenuesResponse>
{
    public groupId: number;
    public venues: VenueSelection[];
    public autoApproval: boolean;

    public constructor(init?: Partial<PostGroupVenues>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostGroupVenuesResponse(); }
    public getTypeName() { return 'PostGroupVenues'; }
}

// @Route("/groups/venue", "POST")
export class PostGroupVenue extends BaseStatelessRequest implements IReturn<PostGroupVenueResponse>
{
    public groupId: number;
    public groupVenue: GroupVenue;

    public constructor(init?: Partial<PostGroupVenue>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostGroupVenueResponse(); }
    public getTypeName() { return 'PostGroupVenue'; }
}

// @Route("/groups/availableusers/{groupId}")
export class GetGroupAvailableUsers extends BaseStatelessRequest implements IReturn<GetGroupAvailableUsersResponse>
{
    public groupId: number;

    public constructor(init?: Partial<GetGroupAvailableUsers>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetGroupAvailableUsersResponse(); }
    public getTypeName() { return 'GetGroupAvailableUsers'; }
}

// @Route("/groups/users", "POST")
export class PostGroupUsers extends BaseStatelessRequest implements IReturn<PostGroupUsersResponse>
{
    public groupId: number;
    public users: UserSelection[];
    public userRoleTypeId: number;
    public autoApproval: boolean;
    public isTemporary: boolean;
    public expiryDate: LocalDate;

    public constructor(init?: Partial<PostGroupUsers>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostGroupUsersResponse(); }
    public getTypeName() { return 'PostGroupUsers'; }
}

// @Route("/groups/user", "POST")
export class PostGroupUser extends BaseStatelessRequest implements IReturn<PostGroupUserResponse>
{
    public groupId: number;
    public groupUser: GroupUser;

    public constructor(init?: Partial<PostGroupUser>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostGroupUserResponse(); }
    public getTypeName() { return 'PostGroupUser'; }
}

// @Route("/groups/requestaccess", "POST")
export class PostGroupRequestAccess extends BaseStatelessRequest implements IReturn<PostGroupRequestAccessResponse>
{
    public groupId: number;

    public constructor(init?: Partial<PostGroupRequestAccess>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostGroupRequestAccessResponse(); }
    public getTypeName() { return 'PostGroupRequestAccess'; }
}

// @Route("/groups/requestaccessreject", "POST")
export class PostGroupRequestAccessReject extends BaseStatelessRequest implements IReturn<PostGroupRequestAccessRejectResponse>
{
    public groupId: number;
    public notificationId: number;

    public constructor(init?: Partial<PostGroupRequestAccessReject>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostGroupRequestAccessRejectResponse(); }
    public getTypeName() { return 'PostGroupRequestAccessReject'; }
}

// @Route("/incidentflagtypenotifications", "POST")
export class GetIncidentFlagTypeNotifications extends BaseStatelessRequest implements IReturn<GetIncidentFlagTypeNotificationsResponse>
{
    public pageSize: number;
    public page: number;
    public filter: IncidentFlagTypeFilter;

    public constructor(init?: Partial<GetIncidentFlagTypeNotifications>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetIncidentFlagTypeNotificationsResponse(); }
    public getTypeName() { return 'GetIncidentFlagTypeNotifications'; }
}

// @Route("/incidentflagtypenotifications/{IncidentFlagTypeId}")
export class GetIncidentFlagTypeNotification extends BaseStatelessRequest implements IReturn<GetIncidentFlagTypeNotificationResponse>, IHasForVenueSecurityFirm
{
    public forVenueId: number;
    public forSecurityFirmId: number;
    public forVenueEventId: number;
    public incidentFlagTypeId: number;

    public constructor(init?: Partial<GetIncidentFlagTypeNotification>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetIncidentFlagTypeNotificationResponse(); }
    public getTypeName() { return 'GetIncidentFlagTypeNotification'; }
}

// @Route("/incidentflagtypenotifications/{IncidentFlagTypeId}")
export class PostIncidentFlagTypeNotification extends BaseStatelessRequest implements IReturn<PostIncidentFlagTypeNotificationResponse>
{
    public incidentFlagTypeId: number;
    public incidentFlagTypeNotification: IncidentFlagTypeNotification;

    public constructor(init?: Partial<PostIncidentFlagTypeNotification>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostIncidentFlagTypeNotificationResponse(); }
    public getTypeName() { return 'PostIncidentFlagTypeNotification'; }
}

// @Route("/facialrecognitionnotifications")
export class PostFacialRecognitionNotification extends BaseStatelessRequest implements IReturn<PostFacialRecognitionNotificationResponse>, IHasForVenueSecurityFirm
{
    public forVenueId: number;
    public forSecurityFirmId: number;
    public forVenueEventId: number;
    public facialRecognitionNotification: FacialRecognitionNotification;

    public constructor(init?: Partial<PostFacialRecognitionNotification>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionNotificationResponse(); }
    public getTypeName() { return 'PostFacialRecognitionNotification'; }
}

// @Route("/facialrecognitionnotification/{Guid}", "POST")
export class PostExternalFacialRecognitionNotification implements IReturn<PostExternalFacialRecognitionNotificationResponse>
{
    public guid: string;

    public constructor(init?: Partial<PostExternalFacialRecognitionNotification>) { (Object as any).assign(this, init); }
    public createResponse() { return new PostExternalFacialRecognitionNotificationResponse(); }
    public getTypeName() { return 'PostExternalFacialRecognitionNotification'; }
}

// @Route("/facialrecognitionserver/event", "POST")
export class PostFacialRecognitionServerEvent implements IReturn<PostExternalFacialRecognitionNotificationResponse>
{

    public constructor(init?: Partial<PostFacialRecognitionServerEvent>) { (Object as any).assign(this, init); }
    public createResponse() { return new PostExternalFacialRecognitionNotificationResponse(); }
    public getTypeName() { return 'PostFacialRecognitionServerEvent'; }
}

// @Route("/facialrecognitionnotifications/process", "POST")
export class PostProcessFacialRecognitionNotification extends BaseStatelessRequest implements IReturn<PostProcessFacialRecognitionNotificationResponse>
{
    public facialRecognitionNotificationId: number;

    public constructor(init?: Partial<PostProcessFacialRecognitionNotification>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostProcessFacialRecognitionNotificationResponse(); }
    public getTypeName() { return 'PostProcessFacialRecognitionNotification'; }
}

// @Route("/facialrecognitionnotifications/defaultfilter", "POST")
export class PostFacialRecognitionNotificationFilter extends BaseStatelessRequest implements IReturn<PostFacialRecognitionNotificationFilterResponse>
{

    public constructor(init?: Partial<PostFacialRecognitionNotificationFilter>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionNotificationFilterResponse(); }
    public getTypeName() { return 'PostFacialRecognitionNotificationFilter'; }
}

// @Route("/facialrecognitionnotifications/find", "POST")
export class PostFacialRecognitionNotifications extends BaseStatelessRequest implements IReturn<PostFacialRecognitionNotificationsResponse>
{
    public pageSize: number;
    public page: number;
    public filter: FacialRecognitionNotificationFilter;

    public constructor(init?: Partial<PostFacialRecognitionNotifications>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionNotificationsResponse(); }
    public getTypeName() { return 'PostFacialRecognitionNotifications'; }
}

// @Route("/facialrecognitionnotifications/{FacialRecognitionNotificationId}", "GET")
export class GetFacialRecognitionNotification extends BaseStatelessRequest implements IReturn<GetFacialRecognitionNotificationResponse>
{
    public facialRecognitionNotificationId: number;

    public constructor(init?: Partial<GetFacialRecognitionNotification>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetFacialRecognitionNotificationResponse(); }
    public getTypeName() { return 'GetFacialRecognitionNotification'; }
}

// @Route("/facialrecognition/events", "GET")
export class GetFacialRecognitionEvent extends BaseStatelessRequest implements IReturn<GetFacialRecognitionEventResponse>
{
    public forVenueId: number;
    public forGroupId: number;

    public constructor(init?: Partial<GetFacialRecognitionEvent>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetFacialRecognitionEventResponse(); }
    public getTypeName() { return 'GetFacialRecognitionEvent'; }
}

// @Route("/facialrecognition/events", "POST")
export class PostFacialRecognitionEvent extends BaseStatelessRequest implements IReturn<PostFacialRecognitionEventResponse>
{
    public facialRecognitionEvent: FacialRecognitionEvent;

    public constructor(init?: Partial<PostFacialRecognitionEvent>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionEventResponse(); }
    public getTypeName() { return 'PostFacialRecognitionEvent'; }
}

// @Route("/facialrecognition/updateevents", "POST")
export class PostFacialRecognitionEvents extends BaseStatelessRequest implements IReturn<PostFacialRecognitionEventResponse>
{
    public facialRecognitionEvents: FacialRecognitionEvent[];

    public constructor(init?: Partial<PostFacialRecognitionEvents>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionEventResponse(); }
    public getTypeName() { return 'PostFacialRecognitionEvents'; }
}

// @Route("/facialrecognition/cameras", "GET")
export class GetFacialRecognitionCameras extends BaseStatelessRequest implements IReturn<GetFacialRecognitionCamerasResponse>
{
    public forVenueId: number;

    public constructor(init?: Partial<GetFacialRecognitionCameras>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetFacialRecognitionCamerasResponse(); }
    public getTypeName() { return 'GetFacialRecognitionCameras'; }
}

// @Route("/facialrecognition/synccameras", "POST")
export class PostFacialRecognitionSyncCameras extends BaseStatelessRequest implements IReturn<PostFacialRecognitionSyncCamerasResponse>
{
    public forVenueId: number;

    public constructor(init?: Partial<PostFacialRecognitionSyncCameras>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionSyncCamerasResponse(); }
    public getTypeName() { return 'PostFacialRecognitionSyncCameras'; }
}

// @Route("/facialrecognition/cameras", "POST")
export class PostFacialRecognitionCameras extends BaseStatelessRequest implements IReturn<PostFacialRecognitionCamerasResponse>
{
    public facialRecognitionCameras: FacialRecognitionCamera[];

    public constructor(init?: Partial<PostFacialRecognitionCameras>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionCamerasResponse(); }
    public getTypeName() { return 'PostFacialRecognitionCameras'; }
}

// @Route("/facialrecognition/camera", "POST")
export class PostFacialRecognitionCamera extends BaseStatelessRequest implements IReturn<PostFacialRecognitionCameraResponse>
{
    public facialRecognitionCamera: FacialRecognitionCamera;

    public constructor(init?: Partial<PostFacialRecognitionCamera>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionCameraResponse(); }
    public getTypeName() { return 'PostFacialRecognitionCamera'; }
}

// @Route("/facialrecognition/camerastatus", "POST")
export class PostFacialRecognitionCameraStatus extends BaseStatelessRequest implements IReturn<PostFacialRecognitionCameraStatusResponse>
{
    public facialRecognitionCameraId: number;
    public forVenueId: number;
    public cameraStatus: ngtCameraStatus;

    public constructor(init?: Partial<PostFacialRecognitionCameraStatus>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionCameraStatusResponse(); }
    public getTypeName() { return 'PostFacialRecognitionCameraStatus'; }
}

// @Route("/facialrecognition/camerafeed", "POST")
export class PostFacialRecognitionCameraFeed extends BaseStatelessRequest implements IReturn<PostFacialRecognitionCameraFeedResponse>
{
    public forVenueId: number;
    public venueLocationId: number;
    public limit: number;

    public constructor(init?: Partial<PostFacialRecognitionCameraFeed>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionCameraFeedResponse(); }
    public getTypeName() { return 'PostFacialRecognitionCameraFeed'; }
}

// @Route("/facialrecognition/cameranewimport")
export class PostFacialRecognitionCameraNewImport extends BaseStatelessRequest implements IReturn<PostFacialRecognitionCameraNewImportResponse>
{
    public forVenueId: number;

    public constructor(init?: Partial<PostFacialRecognitionCameraNewImport>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionCameraNewImportResponse(); }
    public getTypeName() { return 'PostFacialRecognitionCameraNewImport'; }
}

// @Route("/facialrecognition/cameraimport", "POST")
export class PostFacialRecognitionCameraImport extends BaseStatelessRequest implements IReturn<PostFacialRecognitionCameraImportResponse>
{
    public facialRecognitionCameraImports: FacialRecognitionCameraImport[];

    public constructor(init?: Partial<PostFacialRecognitionCameraImport>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionCameraImportResponse(); }
    public getTypeName() { return 'PostFacialRecognitionCameraImport'; }
}

// @Route("/facialrecognition/statistics", "POST")
export class PostFacialRecognitionNotificationStatistics extends BaseStatelessRequest implements IReturn<PostFacialRecognitionNotificationStatisticsResponse>
{
    public fromDate: SimpleDate;
    public rangeAsMinutes: number;

    public constructor(init?: Partial<PostFacialRecognitionNotificationStatistics>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostFacialRecognitionNotificationStatisticsResponse(); }
    public getTypeName() { return 'PostFacialRecognitionNotificationStatistics'; }
}

// @Route("/checklists/summaries/")
export class GetChecklistSummaries extends BaseStatelessRequest implements IReturn<GetChecklistSummariesResponse>
{
    public filter: ChecklistFilter;
    public pageSize: number;
    public page: number;
    public searchText: string;
    public checklistStatuses: number[];
    public startDate: string;
    public endDate: string;

    public constructor(init?: Partial<GetChecklistSummaries>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetChecklistSummariesResponse(); }
    public getTypeName() { return 'GetChecklistSummaries'; }
}

// @Route("/checklists/{Id}", "GET")
export class GetChecklist extends BaseStatelessRequest implements IReturn<GetChecklistResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<GetChecklist>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetChecklistResponse(); }
    public getTypeName() { return 'GetChecklist'; }
}

// @Route("/checklists/create", "POST")
export class CreateChecklist extends BaseStatelessRequest implements IReturn<CreateChecklistResponse>
{
    public templateId: number;

    public constructor(init?: Partial<CreateChecklist>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new CreateChecklistResponse(); }
    public getTypeName() { return 'CreateChecklist'; }
}

// @Route("/checklists/", "POST")
export class SaveChecklist extends BaseStatelessRequest implements IReturn<SaveChecklistResponse>
{
    public checklist: Checklist;

    public constructor(init?: Partial<SaveChecklist>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new SaveChecklistResponse(); }
    public getTypeName() { return 'SaveChecklist'; }
}

// @Route("/checklists/archive/", "POST")
export class ArchiveChecklists extends BaseStatelessRequest implements IReturn<ArchiveChecklistsResponse>
{
    public checklistIds: number[];

    public constructor(init?: Partial<ArchiveChecklists>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new ArchiveChecklistsResponse(); }
    public getTypeName() { return 'ArchiveChecklists'; }
}

// @Route("/checklists/restore/", "POST")
export class RestoreChecklists extends BaseStatelessRequest implements IReturn<RestoreChecklistsResponse>
{
    public checklistIds: number[];

    public constructor(init?: Partial<RestoreChecklists>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new RestoreChecklistsResponse(); }
    public getTypeName() { return 'RestoreChecklists'; }
}

// @Route("/checklists/approve/", "POST")
export class ApproveChecklists extends BaseStatelessRequest implements IReturn<ApproveChecklistsResponse>
{
    public checklistIds: number[];

    public constructor(init?: Partial<ApproveChecklists>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new ApproveChecklistsResponse(); }
    public getTypeName() { return 'ApproveChecklists'; }
}

// @Route("/checklists/close/", "POST")
export class CloseChecklists extends BaseStatelessRequest implements IReturn<CloseChecklistsResponse>
{
    public checklistIds: number[];

    public constructor(init?: Partial<CloseChecklists>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new CloseChecklistsResponse(); }
    public getTypeName() { return 'CloseChecklists'; }
}

// @Route("/checklists/{Id}/print")
export class PostChecklistReport extends BaseStatelessRequest implements IReturn<PostChecklistReportResponse>
{
    public option: string;
    public emailRecipients: string[];
    public emailCcRecipients: string[];
    public emailBccRecipients: string[];
    public filter: ChecklistFilter;
    public imageOption: string;

    public constructor(init?: Partial<PostChecklistReport>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostChecklistReportResponse(); }
    public getTypeName() { return 'PostChecklistReport'; }
}

// @Route("/checklisttemplates/defaultfilter", "POST")
export class PostChecklistTemplatesFilter extends BaseStatelessRequest implements IReturn<PostChecklistTemplatesFilterResponse>
{
    public isAdmin: boolean;

    public constructor(init?: Partial<PostChecklistTemplatesFilter>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostChecklistTemplatesFilterResponse(); }
    public getTypeName() { return 'PostChecklistTemplatesFilter'; }
}

// @Route("/checklisttemplates/find", "POST")
export class PostChecklistTemplates extends BaseStatelessRequest implements IReturn<PostChecklistTemplatesResponse>
{
    public pageSize: number;
    public page: number;
    public filter: ChecklistTemplateFilter;

    public constructor(init?: Partial<PostChecklistTemplates>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostChecklistTemplatesResponse(); }
    public getTypeName() { return 'PostChecklistTemplates'; }
}

// @Route("/checklisttemplates/summaries/", "GET")
export class GetChecklistTemplateSummaries extends BaseStatelessRequest implements IReturn<GetChecklistTemplateSummariesResponse>
{
    public filter: ChecklistTemplateFilter;
    public pageSize: number;
    public page: number;
    public searchText: string;
    public venues: number[];
    public securityFirms: number[];
    public checklistTemplateCategories: number[];
    public industryCategories: number[];
    public states: number[];

    public constructor(init?: Partial<GetChecklistTemplateSummaries>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetChecklistTemplateSummariesResponse(); }
    public getTypeName() { return 'GetChecklistTemplateSummaries'; }
}

// @Route("/checklisttemplatecategories/", "GET")
export class GetChecklistTemplateCategories extends BaseStatelessRequest implements IReturn<GetChecklistTemplateCategoriesResponse>
{

    public constructor(init?: Partial<GetChecklistTemplateCategories>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetChecklistTemplateCategoriesResponse(); }
    public getTypeName() { return 'GetChecklistTemplateCategories'; }
}

// @Route("/checklisttemplatecategories/summaries/", "GET")
export class GetChecklistTemplateCategorySummaries extends BaseStatelessRequest implements IReturn<GetChecklistTemplateCategorySummariesResponse>
{
    public searchText: string;
    public venues: number[];
    public securityFirms: number[];
    public checklistTemplateCategories: number[];
    public industryCategories: number[];
    public states: number[];

    public constructor(init?: Partial<GetChecklistTemplateCategorySummaries>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetChecklistTemplateCategorySummariesResponse(); }
    public getTypeName() { return 'GetChecklistTemplateCategorySummaries'; }
}

// @Route("/checklisttemplatecategories/archive/", "POST")
export class ArchiveChecklistTemplateCategories extends BaseStatelessRequest implements IReturn<ArchiveChecklistTemplateCategoriesResponse>
{
    public checklistTemplateCategoryIds: number[];

    public constructor(init?: Partial<ArchiveChecklistTemplateCategories>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new ArchiveChecklistTemplateCategoriesResponse(); }
    public getTypeName() { return 'ArchiveChecklistTemplateCategories'; }
}

// @Route("/checklisttemplatecategories/", "POST")
export class SaveChecklistTemplateCategory extends BaseStatelessRequest implements IReturn<SaveChecklistTemplateCategoryResponse>
{
    public checklistTemplateCategory: ChecklistTemplateCategory;

    public constructor(init?: Partial<SaveChecklistTemplateCategory>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new SaveChecklistTemplateCategoryResponse(); }
    public getTypeName() { return 'SaveChecklistTemplateCategory'; }
}

// @Route("/checklisttemplatecategories/{Id}", "GET")
export class GetChecklistTemplateCategory extends BaseStatelessRequest implements IReturn<GetChecklistTemplateCategoryResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<GetChecklistTemplateCategory>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetChecklistTemplateCategoryResponse(); }
    public getTypeName() { return 'GetChecklistTemplateCategory'; }
}

// @Route("/checklisttemplates/{Id}", "GET")
export class GetChecklistTemplate extends BaseStatelessRequest implements IReturn<GetChecklistTemplateResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<GetChecklistTemplate>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetChecklistTemplateResponse(); }
    public getTypeName() { return 'GetChecklistTemplate'; }
}

// @Route("/checklisttemplates/{Id}/copy", "POST")
export class CopyChecklistTemplates extends BaseStatelessRequest implements IReturn<CopyChecklistTemplateResponse>, IPost
{
    public groupId: number;
    public checklistTemplateIds: number[];

    public constructor(init?: Partial<CopyChecklistTemplates>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new CopyChecklistTemplateResponse(); }
    public getTypeName() { return 'CopyChecklistTemplates'; }
}

// @Route("/checklisttemplates/", "POST")
export class SaveChecklistTemplate extends BaseStatelessRequest implements IReturn<SaveChecklistTemplateResponse>
{
    public checklistTemplate: ChecklistTemplate;
    public full: boolean;

    public constructor(init?: Partial<SaveChecklistTemplate>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new SaveChecklistTemplateResponse(); }
    public getTypeName() { return 'SaveChecklistTemplate'; }
}

// @Route("/checklisttemplates/archive/", "POST")
export class ArchiveChecklistTemplates extends BaseStatelessRequest implements IReturn<ArchiveChecklistTemplatesResponse>
{
    public restore: boolean;
    public checklistTemplateIds: number[];

    public constructor(init?: Partial<ArchiveChecklistTemplates>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new ArchiveChecklistTemplatesResponse(); }
    public getTypeName() { return 'ArchiveChecklistTemplates'; }
}

// @Route("/checklisttemplate/archive/", "POST")
export class ArchiveChecklistTemplate extends BaseStatelessRequest implements IReturn<ArchiveChecklistTemplateResponse>
{
    public restore: boolean;
    public checklistTemplateId: number;
    public isAdmin: boolean;

    public constructor(init?: Partial<ArchiveChecklistTemplate>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new ArchiveChecklistTemplateResponse(); }
    public getTypeName() { return 'ArchiveChecklistTemplate'; }
}

// @Route("/content/terms", "GET")
export class GetTerms implements IReturn<GetTermsResponse>
{

    public constructor(init?: Partial<GetTerms>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTermsResponse(); }
    public getTypeName() { return 'GetTerms'; }
}

// @Route("/content/privacy", "GET")
export class GetPrivacy implements IReturn<GetPrivacyResponse>
{

    public constructor(init?: Partial<GetPrivacy>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetPrivacyResponse(); }
    public getTypeName() { return 'GetPrivacy'; }
}

// @Route("/dashboard/defaultfilter", "POST")
export class PostDashboardFilter extends BaseStatelessRequest implements IReturn<PostDashboardFilterResponse>, IHasForVenueSecurityFirm
{
    public forVenueId: number;
    public forSecurityFirmId: number;
    public forVenueEventId: number;
    public forGroupId: number;

    public constructor(init?: Partial<PostDashboardFilter>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostDashboardFilterResponse(); }
    public getTypeName() { return 'PostDashboardFilter'; }
}

// @Route("/dashboard", "POST")
export class PostDashboard extends BaseStatelessRequest implements IReturn<PostDashboardResponse>, IHasForVenueSecurityFirm
{
    public filter: DashboardFilter;
    public forVenueId: number;
    public forSecurityFirmId: number;
    public forVenueEventId: number;

    public constructor(init?: Partial<PostDashboard>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostDashboardResponse(); }
    public getTypeName() { return 'PostDashboard'; }
}

// @Route("/dashboard/export", "POST")
export class PostDashboardExport extends BaseStatelessRequest implements IReturn<PostDashboardExportResponse>, IHasForVenueSecurityFirm
{
    public filter: DashboardFilter;
    public forVenueId: number;
    public forSecurityFirmId: number;
    public forVenueEventId: number;

    public constructor(init?: Partial<PostDashboardExport>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostDashboardExportResponse(); }
    public getTypeName() { return 'PostDashboardExport'; }
}

// @Route("/dashboard/incidents", "POST")
export class PostDashboardIncidents extends BaseStatelessRequest implements IReturn<PostDashboardIncidentsResponse>
{
    public filter: DashboardFilter;

    public constructor(init?: Partial<PostDashboardIncidents>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostDashboardIncidentsResponse(); }
    public getTypeName() { return 'PostDashboardIncidents'; }
}

// @Route("/dashboard/admin/defaultfilter", "POST")
export class PostAdminDashboardFilter extends BaseStatelessRequest implements IReturn<PostAdminDashboardFilterResponse>
{

    public constructor(init?: Partial<PostAdminDashboardFilter>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostAdminDashboardFilterResponse(); }
    public getTypeName() { return 'PostAdminDashboardFilter'; }
}

// @Route("/dashboard/admin", "POST")
export class PostAdminDashboard extends BaseStatelessRequest implements IReturn<PostAdminDashboardResponse>
{
    public excludeVenues: boolean;
    public excludeIncidents: boolean;
    public filter: AdminDashboardFilter;

    public constructor(init?: Partial<PostAdminDashboard>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostAdminDashboardResponse(); }
    public getTypeName() { return 'PostAdminDashboard'; }
}

// @Route("/dashboard/admintotals", "POST")
export class PostAdminDashboardTotals extends BaseStatelessRequest implements IReturn<PostAdminDashboardTotalsResponse>
{
    public filter: AdminDashboardFilter;

    public constructor(init?: Partial<PostAdminDashboardTotals>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostAdminDashboardTotalsResponse(); }
    public getTypeName() { return 'PostAdminDashboardTotals'; }
}

// @Route("/dashboard/adminoverview", "POST")
export class PostAdminDashboardOverview extends BaseStatelessRequest implements IReturn<PostAdminDashboardOverviewResponse>
{
    public mode: ngtOverviewStatsMode;

    public constructor(init?: Partial<PostAdminDashboardOverview>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostAdminDashboardOverviewResponse(); }
    public getTypeName() { return 'PostAdminDashboardOverview'; }
}

// @Route("/dashboard/adminusertotals", "POST")
export class PostAdminDashboardUserTotals extends BaseStatelessRequest implements IReturn<PostAdminDashboardUserTotalsResponse>
{

    public constructor(init?: Partial<PostAdminDashboardUserTotals>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostAdminDashboardUserTotalsResponse(); }
    public getTypeName() { return 'PostAdminDashboardUserTotals'; }
}

// @Route("/dashboard/adminincidenttotals", "POST")
export class PostAdminDashboardIncidentTotals extends BaseStatelessRequest implements IReturn<PostAdminDashboardIncidentTotalsResponse>
{

    public constructor(init?: Partial<PostAdminDashboardIncidentTotals>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostAdminDashboardIncidentTotalsResponse(); }
    public getTypeName() { return 'PostAdminDashboardIncidentTotals'; }
}

// @Route("/temporaryfiles", "POST")
export class PostTemporaryFiles implements IReturn<PostTemporaryFilesResponse>
{
    public path: string;
    public textContents: string;
    public forDownload: boolean;
    public entityType: ngtEntityType;
    public storageFolder: string;

    public constructor(init?: Partial<PostTemporaryFiles>) { (Object as any).assign(this, init); }
    public createResponse() { return new PostTemporaryFilesResponse(); }
    public getTypeName() { return 'Files.PostTemporaryFiles'; }
}

// @Route("/temporarybase64image", "POST")
export class PostTemporaryBase64File implements IReturn<PostTemporaryBase64FileResponse>
{
    public base64ImageData: Base64ImageData;
    public poiGuid: string;
    public appearanceGuid: string;

    public constructor(init?: Partial<PostTemporaryBase64File>) { (Object as any).assign(this, init); }
    public createResponse() { return new PostTemporaryBase64FileResponse(); }
    public getTypeName() { return 'Files.PostTemporaryBase64File'; }
}

// @Route("/temporaryfilelist", "POST")
export class PostTemporaryFileList extends BaseStatelessRequest implements IReturn<PostTemporaryFileListResponse>
{
    public page: number;

    public constructor(init?: Partial<PostTemporaryFileList>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostTemporaryFileListResponse(); }
    public getTypeName() { return 'Files.PostTemporaryFileList'; }
}

// @Route("/files/{Path*}", "GET")
export class GetFile
{
    public path: string;

    public constructor(init?: Partial<GetFile>) { (Object as any).assign(this, init); }
}

// @Route("/file/{id}", "GET")
export class GetFileContent
{
    public id: string;
    public downloadToken: string;

    public constructor(init?: Partial<GetFileContent>) { (Object as any).assign(this, init); }
}

// @Route("/venueevents")
export class PostVenueEvents extends BaseStatelessRequest implements IReturn<PostVenueEventsResponse>
{
    public pageSize: number;
    public page: number;
    public filter: SimpleFilter;

    public constructor(init?: Partial<PostVenueEvents>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostVenueEventsResponse(); }
    public getTypeName() { return 'PostVenueEvents'; }
}

// @Route("/venueevents/new")
export class PostVenueEventNew extends BaseStatelessRequest implements IReturn<PostVenueEventNewResponse>
{
    public forSecurityFirmId: number;

    public constructor(init?: Partial<PostVenueEventNew>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostVenueEventNewResponse(); }
    public getTypeName() { return 'PostVenueEventNew'; }
}

// @Route("/venueevents/upsert")
export class PostVenueEvent extends BaseStatelessRequest implements IReturn<PostVenueEventResponse>
{
    public venueEvent: VenueEvent;

    public constructor(init?: Partial<PostVenueEvent>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostVenueEventResponse(); }
    public getTypeName() { return 'PostVenueEvent'; }
}

// @Route("/venueevents/{Id}")
export class GetVenueEvent extends BaseStatelessRequest implements IReturn<GetVenueEventResponse>
{
    public id: number;

    public constructor(init?: Partial<GetVenueEvent>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetVenueEventResponse(); }
    public getTypeName() { return 'GetVenueEvent'; }
}

// @Route("/installations", "PUT")
export class PutInstallations implements IReturn<PutInstallationsResponse>
{
    public deviceInstallation: DeviceInstallation;

    public constructor(init?: Partial<PutInstallations>) { (Object as any).assign(this, init); }
    public createResponse() { return new PutInstallationsResponse(); }
    public getTypeName() { return 'PutInstallations'; }
}

// @Route("/installations/{InstallationId}", "DELETE")
export class DeleteInstallations implements IReturn<DeleteInstallationsResponse>
{
    public installationId: string;

    public constructor(init?: Partial<DeleteInstallations>) { (Object as any).assign(this, init); }
    public createResponse() { return new DeleteInstallationsResponse(); }
    public getTypeName() { return 'DeleteInstallations'; }
}

// @Route("/requests", "POST")
export class RequestPush implements IReturn<RequestPushResponse>
{
    public notificationRequest: NotificationRequest;

    public constructor(init?: Partial<RequestPush>) { (Object as any).assign(this, init); }
    public createResponse() { return new RequestPushResponse(); }
    public getTypeName() { return 'RequestPush'; }
}

// @Route("/secuityfirmcontacts", "POST")
export class GetSecurityFirmContacts extends BaseStatelessRequest implements IReturn<GetSecurityFirmContactsResponse>
{
    public pageSize: number;
    public page: number;
    public filter: ContactFilter;

    public constructor(init?: Partial<GetSecurityFirmContacts>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetSecurityFirmContactsResponse(); }
    public getTypeName() { return 'GetSecurityFirmContacts'; }
}

// @Route("/secuityfirmcontacts/new")
export class PostSecurityFirmContactNew extends BaseStatelessRequest implements IReturn<PostSecurityFirmContactNewResponse>, IHasForVenueSecurityFirm
{
    public forVenueId: number;
    public forSecurityFirmId: number;
    public forVenueEventId: number;

    public constructor(init?: Partial<PostSecurityFirmContactNew>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostSecurityFirmContactNewResponse(); }
    public getTypeName() { return 'PostSecurityFirmContactNew'; }
}

// @Route("/secuityfirmcontacts/{secuityfirmContactId}")
export class GetSecurityFirmContact extends BaseStatelessRequest implements IReturn<GetSecurityFirmContactResponse>, IHasForVenueSecurityFirm
{
    public forVenueId: number;
    public forSecurityFirmId: number;
    public forVenueEventId: number;
    public includeReferenceData: boolean;
    public securityFirmContactId: number;

    public constructor(init?: Partial<GetSecurityFirmContact>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetSecurityFirmContactResponse(); }
    public getTypeName() { return 'GetSecurityFirmContact'; }
}

// @Route("/secuityfirmcontacts/{secuityfirmContactId}")
export class PostSecurityFirmContact extends BaseStatelessRequest implements IReturn<PostSecurityFirmContactResponse>
{
    public securityFirmContactId: number;
    public securityFirmContact: SecurityFirmContact;

    public constructor(init?: Partial<PostSecurityFirmContact>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostSecurityFirmContactResponse(); }
    public getTypeName() { return 'PostSecurityFirmContact'; }
}

// @Route("/securityfirmdocuments/{ForSecurityFirmId}", "GET")
export class GetSecurityFirmDocuments extends BaseStatelessRequest implements IReturn<GetSecurityFirmDocumentsResponse>
{
    public forSecurityFirmId: number;
    public showObsoleteDocuments: boolean;

    public constructor(init?: Partial<GetSecurityFirmDocuments>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetSecurityFirmDocumentsResponse(); }
    public getTypeName() { return 'GetSecurityFirmDocuments'; }
}

// @Route("/securityfirmdocuments/other", "POST")
export class PostSecurityFirmOtherDocument extends BaseStatelessRequest implements IReturn<PostSecurityFirmOtherDocumentResponse>
{
    public forSecurityFirmId: number;
    public documentTypeId: number;
    public files: File[];

    public constructor(init?: Partial<PostSecurityFirmOtherDocument>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostSecurityFirmOtherDocumentResponse(); }
    public getTypeName() { return 'PostSecurityFirmOtherDocument'; }
}

// @Route("/securityfirmdocuments/other/remove", "POST")
export class PostSecurityFirmOtherDocumentRemove extends BaseStatelessRequest implements IReturn<PostSecurityFirmOtherDocumentRemoveResponse>
{
    public forSecurityFirmId: number;
    public securityFirmDocumentId: number;
    public securityFirmOtherDocumentId: number;

    public constructor(init?: Partial<PostSecurityFirmOtherDocumentRemove>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostSecurityFirmOtherDocumentRemoveResponse(); }
    public getTypeName() { return 'PostSecurityFirmOtherDocumentRemove'; }
}

// @Route("/securityfirmdocuments/compliance", "POST")
export class PostSecurityFirmComplianceDocument extends BaseStatelessRequest implements IReturn<PostSecurityFirmComplianceDocumentResponse>
{
    public forSecurityFirmId: number;
    public documentTypeId: number;
    public visibleToVenue: boolean;
    public expiryDate: SimpleDate;
    public files: File[];

    public constructor(init?: Partial<PostSecurityFirmComplianceDocument>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostSecurityFirmComplianceDocumentResponse(); }
    public getTypeName() { return 'PostSecurityFirmComplianceDocument'; }
}

// @Route("/securityfirmdocuments/compliance/remove", "POST")
export class PostSecurityFirmComplianceDocumentRemove extends BaseStatelessRequest implements IReturn<PostSecurityFirmComplianceDocumentRemoveResponse>
{
    public forSecurityFirmId: number;
    public securityFirmDocumentId: number;
    public securityFirmComplianceDocumentId: number;

    public constructor(init?: Partial<PostSecurityFirmComplianceDocumentRemove>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostSecurityFirmComplianceDocumentRemoveResponse(); }
    public getTypeName() { return 'PostSecurityFirmComplianceDocumentRemove'; }
}

// @Route("/broadcastnotifications", "POST")
export class GetBroadcastNotifications extends BaseStatelessRequest implements IReturn<GetBroadcastNotificationsResponse>
{
    public pageSize: number;
    public page: number;
    public filter: BroadcastNotificationFilter;

    public constructor(init?: Partial<GetBroadcastNotifications>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetBroadcastNotificationsResponse(); }
    public getTypeName() { return 'GetBroadcastNotifications'; }
}

// @Route("/broadcastnotifications/new")
export class PostBroadcastNotificationNew extends BaseStatelessRequest implements IReturn<PostBroadcastNotificationNewResponse>, IHasForVenueSecurityFirm
{
    public forVenueId: number;
    public forSecurityFirmId: number;
    public forVenueEventId: number;
    public cloneId: number;

    public constructor(init?: Partial<PostBroadcastNotificationNew>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostBroadcastNotificationNewResponse(); }
    public getTypeName() { return 'PostBroadcastNotificationNew'; }
}

// @Route("/broadcastnotifications/{broadcastNotificationId}")
export class GetBroadcastNotification extends BaseStatelessRequest implements IReturn<GetBroadcastNotificationResponse>, IHasForVenueSecurityFirm
{
    public forVenueId: number;
    public forSecurityFirmId: number;
    public forVenueEventId: number;
    public broadcastNotificationId: number;

    public constructor(init?: Partial<GetBroadcastNotification>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetBroadcastNotificationResponse(); }
    public getTypeName() { return 'GetBroadcastNotification'; }
}

// @Route("/broadcastnotifications/{broadcastNotificationId}")
export class PostBroadcastNotification extends BaseStatelessRequest implements IReturn<PostBroadcastNotificationResponse>
{
    public broadcastNotificationId: number;
    public broadcastNotification: BroadcastNotification;

    public constructor(init?: Partial<PostBroadcastNotification>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostBroadcastNotificationResponse(); }
    public getTypeName() { return 'PostBroadcastNotification'; }
}

// @Route("/broadcastnotification/pushtest")
export class PostBroadcastNotificationTest extends BaseStatelessRequest implements IReturn<PostBroadcastNotificationTestResponse>
{
    public broadcastNotificationId: number;

    public constructor(init?: Partial<PostBroadcastNotificationTest>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostBroadcastNotificationTestResponse(); }
    public getTypeName() { return 'PostBroadcastNotificationTest'; }
}

// @Route("/incidentactiontemplate", "POST")
export class GetIncidentActionTemplates extends BaseStatelessRequest implements IReturn<GetIncidentActionTemplatesResponse>
{
    public pageSize: number;
    public page: number;
    public filter: IncidentActionTemplateFilter;

    public constructor(init?: Partial<GetIncidentActionTemplates>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetIncidentActionTemplatesResponse(); }
    public getTypeName() { return 'GetIncidentActionTemplates'; }
}

// @Route("/incidentactiontemplate/new")
export class PostIncidentActionTemplateNew extends BaseStatelessRequest implements IReturn<PostIncidentActionTemplateNewResponse>
{
    public forVenueId: number;
    public forGroupId: number;

    public constructor(init?: Partial<PostIncidentActionTemplateNew>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostIncidentActionTemplateNewResponse(); }
    public getTypeName() { return 'PostIncidentActionTemplateNew'; }
}

// @Route("/incidentactiontemplate/{incidentActionTemplateId}")
export class GetIncidentActionTemplate extends BaseStatelessRequest implements IReturn<GetIncidentActionTemplateResponse>
{
    public incidentActionTemplateId: number;

    public constructor(init?: Partial<GetIncidentActionTemplate>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetIncidentActionTemplateResponse(); }
    public getTypeName() { return 'GetIncidentActionTemplate'; }
}

// @Route("/incidentactiontemplate/{incidentActionTemplateId}")
export class PostIncidentActionTemplate extends BaseStatelessRequest implements IReturn<PostIncidentActionTemplateResponse>
{
    public incidentActionTemplateId: number;
    public incidentActionTemplate: IncidentActionTemplate;

    public constructor(init?: Partial<PostIncidentActionTemplate>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostIncidentActionTemplateResponse(); }
    public getTypeName() { return 'PostIncidentActionTemplate'; }
}

// @Route("/incidentactiontemplate/copy/{Id}", "POST")
export class PostIncidentActionTemplateCopy extends BaseStatelessRequest implements IReturn<PostIncidentActionTemplateCopyResponse>, IPost
{
    public incidentActionTemplateId: number;
    public forGroupId: number;
    public forVenueId: number;

    public constructor(init?: Partial<PostIncidentActionTemplateCopy>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostIncidentActionTemplateCopyResponse(); }
    public getTypeName() { return 'PostIncidentActionTemplateCopy'; }
}

// @Route("/admindirectories/{EntityType}", "GET")
export class GetAdminDirectory extends BaseStatelessRequest implements IReturn<GetAdminDirectoryResponse>
{
    public entityType: ngtEntityType;

    public constructor(init?: Partial<GetAdminDirectory>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetAdminDirectoryResponse(); }
    public getTypeName() { return 'GetAdminDirectory'; }
}

// @Route("/admindirectories/{EntityType}/folder", "POST")
export class PostAdminDirectoryFolder extends BaseStatelessRequest implements IReturn<PostAdminDirectoryFolderResponse>
{
    public entityType: ngtEntityType;
    public folder: Folder;

    public constructor(init?: Partial<PostAdminDirectoryFolder>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostAdminDirectoryFolderResponse(); }
    public getTypeName() { return 'PostAdminDirectoryFolder'; }
}

// @Route("/directories/{EntityType}/{Id}", "GET")
export class GetDirectory extends BaseStatelessRequest implements IReturn<GetDirectoryResponse>
{
    public entityType: ngtEntityType;
    public id: number;
    public showObsolete: boolean;

    public constructor(init?: Partial<GetDirectory>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetDirectoryResponse(); }
    public getTypeName() { return 'GetDirectory'; }
}

// @Route("/directories/{EntityType}/update/{Id}", "POST")
export class PostDirectoryDocument extends BaseStatelessRequest implements IReturn<PostDirectoryDocumentResponse>
{
    public id: number;
    public documentUpload: DocumentUpload;
    public showObsolete: boolean;

    public constructor(init?: Partial<PostDirectoryDocument>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostDirectoryDocumentResponse(); }
    public getTypeName() { return 'PostDirectoryDocument'; }
}

// @Route("/directories/{EntityType}/update/{Id}", "POST")
export class PostDirectoryDocumentUpdate extends BaseStatelessRequest implements IReturn<PostDirectoryDocumentUpdateResponse>
{
    public id: number;
    public document: Document;
    public showObsolete: boolean;

    public constructor(init?: Partial<PostDirectoryDocumentUpdate>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostDirectoryDocumentUpdateResponse(); }
    public getTypeName() { return 'PostDirectoryDocumentUpdate'; }
}

// @Route("/directories/{EntityType}/remove/{Id}", "POST")
export class PostDirectoryDocumentRemove extends BaseStatelessRequest implements IReturn<PostDirectoryDocumentRemoveResponse>
{
    public id: number;
    public document: Document;
    public showObsolete: boolean;

    public constructor(init?: Partial<PostDirectoryDocumentRemove>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostDirectoryDocumentRemoveResponse(); }
    public getTypeName() { return 'PostDirectoryDocumentRemove'; }
}

// @Route("/directories/{EntityType}/folder", "POST")
export class PostDirectoryFolder extends BaseStatelessRequest implements IReturn<PostDirectoryFolderResponse>
{
    public id: number;
    public entityType: ngtEntityType;
    public folder: Folder;
    public showObsolete: boolean;

    public constructor(init?: Partial<PostDirectoryFolder>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostDirectoryFolderResponse(); }
    public getTypeName() { return 'PostDirectoryFolder'; }
}

// @Route("/venuedocuments/{ForVenueId}", "GET")
export class GetVenueDocuments extends BaseStatelessRequest implements IReturn<GetVenueDocumentsResponse>
{
    public forVenueId: number;
    public showObsoleteDocuments: boolean;

    public constructor(init?: Partial<GetVenueDocuments>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetVenueDocumentsResponse(); }
    public getTypeName() { return 'GetVenueDocuments'; }
}

// @Route("/venuedocuments/other", "POST")
export class PostVenueOtherDocument extends BaseStatelessRequest implements IReturn<PostVenueOtherDocumentResponse>
{
    public forVenueId: number;
    public documentTypeId: number;
    public files: File[];

    public constructor(init?: Partial<PostVenueOtherDocument>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostVenueOtherDocumentResponse(); }
    public getTypeName() { return 'PostVenueOtherDocument'; }
}

// @Route("/venuedocuments/other/remove", "POST")
export class PostVenueOtherDocumentRemove extends BaseStatelessRequest implements IReturn<PostVenueOtherDocumentRemoveResponse>
{
    public forVenueId: number;
    public venueDocumentId: number;
    public venueOtherDocumentId: number;

    public constructor(init?: Partial<PostVenueOtherDocumentRemove>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostVenueOtherDocumentRemoveResponse(); }
    public getTypeName() { return 'PostVenueOtherDocumentRemove'; }
}

// @Route("/venuedocuments/compliance", "POST")
export class PostVenueComplianceDocument extends BaseStatelessRequest implements IReturn<PostVenueComplianceDocumentResponse>
{
    public forVenueId: number;
    public documentTypeId: number;
    public expiryDate: SimpleDate;
    public files: File[];

    public constructor(init?: Partial<PostVenueComplianceDocument>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostVenueComplianceDocumentResponse(); }
    public getTypeName() { return 'PostVenueComplianceDocument'; }
}

// @Route("/venuedocuments/compliance/remove", "POST")
export class PostVenueComplianceDocumentRemove extends BaseStatelessRequest implements IReturn<PostVenueComplianceDocumentRemoveResponse>
{
    public forVenueId: number;
    public venueDocumentId: number;
    public venueComplianceDocumentId: number;

    public constructor(init?: Partial<PostVenueComplianceDocumentRemove>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostVenueComplianceDocumentRemoveResponse(); }
    public getTypeName() { return 'PostVenueComplianceDocumentRemove'; }
}

// @Route("/venuecontacts", "POST")
export class GetVenueContacts extends BaseStatelessRequest implements IReturn<GetVenueContactsResponse>
{
    public pageSize: number;
    public page: number;
    public filter: ContactFilter;

    public constructor(init?: Partial<GetVenueContacts>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetVenueContactsResponse(); }
    public getTypeName() { return 'GetVenueContacts'; }
}

// @Route("/venuecontacts/new")
export class PostVenueContactNew extends BaseStatelessRequest implements IReturn<PostVenueContactNewResponse>, IHasForVenueSecurityFirm
{
    public forVenueId: number;
    public forSecurityFirmId: number;
    public forVenueEventId: number;

    public constructor(init?: Partial<PostVenueContactNew>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostVenueContactNewResponse(); }
    public getTypeName() { return 'PostVenueContactNew'; }
}

// @Route("/venuecontacts/{venueContactId}")
export class GetVenueContact extends BaseStatelessRequest implements IReturn<GetVenueContactResponse>, IHasForVenueSecurityFirm
{
    public forVenueId: number;
    public forSecurityFirmId: number;
    public forVenueEventId: number;
    public includeReferenceData: boolean;
    public venueContactId: number;

    public constructor(init?: Partial<GetVenueContact>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetVenueContactResponse(); }
    public getTypeName() { return 'GetVenueContact'; }
}

// @Route("/venuecontacts/{venueContactId}")
export class PostVenueContact extends BaseStatelessRequest implements IReturn<PostVenueContactResponse>
{
    public venueContactId: number;
    public venueContact: VenueContact;

    public constructor(init?: Partial<PostVenueContact>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostVenueContactResponse(); }
    public getTypeName() { return 'PostVenueContact'; }
}

// @Route("/usercompliancedocuments/expiring", "POST")
export class PostUserComplianceDocumentsExpiring extends BaseStatelessRequest implements IReturn<PostUserComplianceDocumentsExpiringResponse>
{
    public pageSize: number;
    public page: number;
    public filter: UserComplianceDocumentFilter;

    public constructor(init?: Partial<PostUserComplianceDocumentsExpiring>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostUserComplianceDocumentsExpiringResponse(); }
    public getTypeName() { return 'PostUserComplianceDocumentsExpiring'; }
}

// @Route("/usercompliancedocuments/missing", "POST")
export class PostUserComplianceDocumentsMissing extends BaseStatelessRequest implements IReturn<PostUserComplianceDocumentsMissingResponse>
{
    public pageSize: number;
    public page: number;
    public filter: UserComplianceDocumentFilter;

    public constructor(init?: Partial<PostUserComplianceDocumentsMissing>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostUserComplianceDocumentsMissingResponse(); }
    public getTypeName() { return 'PostUserComplianceDocumentsMissing'; }
}

// @Route("/usercompliancedocuments/expiringexport")
export class AnyUserComplianceDocumentsExpiringExport extends BaseStatelessRequest implements IReturn<AnyUserComplianceDocumentsExpiringExportResponse>
{
    public filter: UserComplianceDocumentFilter;

    public constructor(init?: Partial<AnyUserComplianceDocumentsExpiringExport>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new AnyUserComplianceDocumentsExpiringExportResponse(); }
    public getTypeName() { return 'AnyUserComplianceDocumentsExpiringExport'; }
}

// @Route("/usercompliancedocuments/missingexport")
export class AnyUserComplianceDocumentsMissingExport extends BaseStatelessRequest implements IReturn<AnyUserComplianceDocumentsMissingExportResponse>
{
    public filter: UserComplianceDocumentFilter;

    public constructor(init?: Partial<AnyUserComplianceDocumentsMissingExport>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new AnyUserComplianceDocumentsMissingExportResponse(); }
    public getTypeName() { return 'AnyUserComplianceDocumentsMissingExport'; }
}

// @Route("/documenttypes")
export class PostDocumentTypes extends BaseStatelessRequest implements IReturn<PostDocumentTypesResponse>
{
    public pageSize: number;
    public page: number;
    public filter: SimpleFilter;

    public constructor(init?: Partial<PostDocumentTypes>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostDocumentTypesResponse(); }
    public getTypeName() { return 'PostDocumentTypes'; }
}

// @Route("/documenttypes/new")
export class PostDocumentTypeNew extends BaseStatelessRequest implements IReturn<PostDocumentTypeNewResponse>
{

    public constructor(init?: Partial<PostDocumentTypeNew>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostDocumentTypeNewResponse(); }
    public getTypeName() { return 'PostDocumentTypeNew'; }
}

// @Route("/documenttypes/upsert")
export class PostDocumentType extends BaseStatelessRequest implements IReturn<PostPostDocumentTypeResponse>
{
    public documentType: DocumentType;

    public constructor(init?: Partial<PostDocumentType>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostPostDocumentTypeResponse(); }
    public getTypeName() { return 'PostDocumentType'; }
}

// @Route("/documenttypes/{DocumentTypeId}")
export class GetDocumentType extends BaseStatelessRequest implements IReturn<GetDocumentTypeResponse>
{
    public documentTypeId: number;

    public constructor(init?: Partial<GetDocumentType>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetDocumentTypeResponse(); }
    public getTypeName() { return 'GetDocumentType'; }
}

// @Route("/venuelocations")
export class PostVenueLocations extends BaseStatelessRequest implements IReturn<PostVenueLocationsResponse>
{
    public pageSize: number;
    public page: number;
    public filter: VenueLocationFilter;

    public constructor(init?: Partial<PostVenueLocations>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostVenueLocationsResponse(); }
    public getTypeName() { return 'PostVenueLocations'; }
}

// @Route("/venuelocations/new")
export class PostVenueLocationNew extends BaseStatelessRequest implements IReturn<PostVenueLocationNewResponse>
{
    public forVenueId: number;

    public constructor(init?: Partial<PostVenueLocationNew>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostVenueLocationNewResponse(); }
    public getTypeName() { return 'PostVenueLocationNew'; }
}

// @Route("/venuelocations/upsert")
export class PostVenueLocation extends BaseStatelessRequest implements IReturn<PostPostVenueLocationResponse>
{
    public venueLocation: VenueLocation;

    public constructor(init?: Partial<PostVenueLocation>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostPostVenueLocationResponse(); }
    public getTypeName() { return 'PostVenueLocation'; }
}

// @Route("/venuelocations/{VenueLocationId}")
export class GetVenueLocation extends BaseStatelessRequest implements IReturn<GetVenueLocationResponse>
{
    public venueLocationId: number;

    public constructor(init?: Partial<GetVenueLocation>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetVenueLocationResponse(); }
    public getTypeName() { return 'GetVenueLocation'; }
}

// @Route("/incidentflagtypes")
export class PostIncidentFlagTypes extends BaseStatelessRequest implements IReturn<PostIncidentFlagTypesResponse>
{
    public pageSize: number;
    public page: number;
    public filter: IncidentFlagTypeFilter;

    public constructor(init?: Partial<PostIncidentFlagTypes>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostIncidentFlagTypesResponse(); }
    public getTypeName() { return 'PostIncidentFlagTypes'; }
}

// @Route("/incidentflagtypes/new")
export class PostIncidentFlagTypeNew extends BaseStatelessRequest implements IReturn<PostIncidentFlagTypeNewResponse>
{

    public constructor(init?: Partial<PostIncidentFlagTypeNew>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostIncidentFlagTypeNewResponse(); }
    public getTypeName() { return 'PostIncidentFlagTypeNew'; }
}

// @Route("/incidentflagtype/{incidentFlagTypeId}")
export class PostIncidentFlagType extends BaseStatelessRequest implements IReturn<PostIncidentFlagTypeResponse>
{
    public incidentFlagTypeId: number;
    public incidentFlagType: IncidentFlagType;

    public constructor(init?: Partial<PostIncidentFlagType>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostIncidentFlagTypeResponse(); }
    public getTypeName() { return 'PostIncidentFlagType'; }
}

// @Route("/incidenttypes")
export class PostIncidentTypes extends BaseStatelessRequest implements IReturn<PostIncidentTypesResponse>
{
    public pageSize: number;
    public page: number;
    public filter: IncidentTypeFilter;

    public constructor(init?: Partial<PostIncidentTypes>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostIncidentTypesResponse(); }
    public getTypeName() { return 'PostIncidentTypes'; }
}

// @Route("/incidenttypes/new")
export class PostIncidentTypeNew extends BaseStatelessRequest implements IReturn<PostIncidentTypeNewResponse>
{

    public constructor(init?: Partial<PostIncidentTypeNew>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostIncidentTypeNewResponse(); }
    public getTypeName() { return 'PostIncidentTypeNew'; }
}

// @Route("/incidenttype/{incidentTypeId}")
export class PostIncidentType extends BaseStatelessRequest implements IReturn<PostIncidentTypeResponse>
{
    public incidentTypeId: number;
    public incidentType: IncidentType;

    public constructor(init?: Partial<PostIncidentType>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostIncidentTypeResponse(); }
    public getTypeName() { return 'PostIncidentType'; }
}

// @Route("/venuereportingperiodschedule/create", "POST")
export class PostVenueReportingPeriodScheduleCreate extends BaseStatelessRequest implements IReturn<PostVenueReportingPeriodScheduleCreateResponse>
{
    public forVenueId: number;

    public constructor(init?: Partial<PostVenueReportingPeriodScheduleCreate>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostVenueReportingPeriodScheduleCreateResponse(); }
    public getTypeName() { return 'PostVenueReportingPeriodScheduleCreate'; }
}

// @Route("/venuereportingperiodschedule/{VenueReportingPeriodScheduleId}", "GET")
export class GetVenueReportingPeriodSchedule extends BaseStatelessRequest implements IReturn<GetVenueReportingPeriodScheduleResponse>
{
    public venueReportingPeriodScheduleId: number;

    public constructor(init?: Partial<GetVenueReportingPeriodSchedule>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetVenueReportingPeriodScheduleResponse(); }
    public getTypeName() { return 'GetVenueReportingPeriodSchedule'; }
}

// @Route("/venuereportingperiodschedule/upsert", "POST")
export class PostVenueReportingPeriodScheduleUpsert extends BaseStatelessRequest implements IReturn<PostVenueReportingPeriodScheduleUpsertResponse>
{
    public venueReportingPeriodSchedule: VenueReportingPeriodSchedule;

    public constructor(init?: Partial<PostVenueReportingPeriodScheduleUpsert>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostVenueReportingPeriodScheduleUpsertResponse(); }
    public getTypeName() { return 'PostVenueReportingPeriodScheduleUpsert'; }
}

// @Route("/venuereportingperiodschedule/list", "POST")
export class PostVenueReportingPeriodSchedule extends BaseStatelessRequest implements IReturn<PostVenueReportingPeriodScheduleResponse>
{
    public forVenueId: number;

    public constructor(init?: Partial<PostVenueReportingPeriodSchedule>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostVenueReportingPeriodScheduleResponse(); }
    public getTypeName() { return 'PostVenueReportingPeriodSchedule'; }
}

// @Route("/securityfirmsubscription/{ForSecurityFirmId}")
export class GetSecurityFirmSubscription extends BaseStatelessRequest implements IReturn<GetSecurityFirmSubscriptionResponse>
{
    public forSecurityFirmId: number;

    public constructor(init?: Partial<GetSecurityFirmSubscription>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetSecurityFirmSubscriptionResponse(); }
    public getTypeName() { return 'GetSecurityFirmSubscription'; }
}

// @Route("/securityfirmsubscription", "POST")
export class PostSecurityFirmSubscription extends BaseStatelessRequest implements IReturn<PostSecurityFirmSubscriptionResponse>
{
    public securityFirmSubscription: SecurityFirmSubscription;

    public constructor(init?: Partial<PostSecurityFirmSubscription>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostSecurityFirmSubscriptionResponse(); }
    public getTypeName() { return 'PostSecurityFirmSubscription'; }
}

// @Route("/securityfirmsubscription")
export class GetSecurityFirmSubscriptionSummary extends BaseStatelessRequest implements IReturn<GetSecurityFirmSubscriptionSummaryResponse>
{

    public constructor(init?: Partial<GetSecurityFirmSubscriptionSummary>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetSecurityFirmSubscriptionSummaryResponse(); }
    public getTypeName() { return 'GetSecurityFirmSubscriptionSummary'; }
}

// @Route("/securityfirmassocations/summary")
export class PostSecurityFirmAssociationSummary extends BaseStatelessRequest implements IReturn<PostSecurityFirmAssociationSummaryResponse>, IHasForVenueSecurityFirm
{
    public forVenueId: number;
    public forSecurityFirmId: number;
    public forVenueEventId: number;
    public pageSize: number;
    public page: number;

    public constructor(init?: Partial<PostSecurityFirmAssociationSummary>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostSecurityFirmAssociationSummaryResponse(); }
    public getTypeName() { return 'PostSecurityFirmAssociationSummary'; }
}

// @Route("/securityfirmassocations/create", "POST")
export class PostSecurityFirmAssociationCreate extends BaseStatelessRequest implements IReturn<PostSecurityFirmAssociationCreateResponse>, IHasForVenueSecurityFirm
{
    public forVenueId: number;
    public forSecurityFirmId: number;
    public forVenueEventId: number;

    public constructor(init?: Partial<PostSecurityFirmAssociationCreate>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostSecurityFirmAssociationCreateResponse(); }
    public getTypeName() { return 'PostSecurityFirmAssociationCreate'; }
}

// @Route("/securityfirmassocation", "POST")
export class PostSecurityFirmAssociation extends BaseStatelessRequest implements IReturn<PostSecurityFirmAssociationResponse>
{
    public securityFirmAssociation: SecurityFirmAssociation;

    public constructor(init?: Partial<PostSecurityFirmAssociation>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostSecurityFirmAssociationResponse(); }
    public getTypeName() { return 'PostSecurityFirmAssociation'; }
}

// @Route("/securityfirmassocation/{SecurityFirmAssociationId}", "DELETE")
export class DeleteSecurityFirmAssociation extends BaseStatelessRequest implements IReturn<DeleteSecurityFirmAssociationResponse>
{
    public securityFirmAssociationId: number;
    public removeAll: boolean;

    public constructor(init?: Partial<DeleteSecurityFirmAssociation>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new DeleteSecurityFirmAssociationResponse(); }
    public getTypeName() { return 'DeleteSecurityFirmAssociation'; }
}

// @Route("/venuesubscription/{ForVenueId}")
export class GetVenueSubscription extends BaseStatelessRequest implements IReturn<GetVenueSubscriptionResponse>
{
    public forVenueId: number;

    public constructor(init?: Partial<GetVenueSubscription>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetVenueSubscriptionResponse(); }
    public getTypeName() { return 'GetVenueSubscription'; }
}

// @Route("/venuesubscription", "POST")
export class PostVenueSubscription extends BaseStatelessRequest implements IReturn<PostVenueSubscriptionResponse>
{
    public venueSubscription: VenueSubscription;

    public constructor(init?: Partial<PostVenueSubscription>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostVenueSubscriptionResponse(); }
    public getTypeName() { return 'PostVenueSubscription'; }
}

// @Route("/venuesubscriptionsummary")
export class GetVenueSubscriptionSummary extends BaseStatelessRequest implements IReturn<GetVenueSubscriptionSummaryResponse>
{

    public constructor(init?: Partial<GetVenueSubscriptionSummary>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetVenueSubscriptionSummaryResponse(); }
    public getTypeName() { return 'GetVenueSubscriptionSummary'; }
}

// @Route("/websiteadmin/clearcache", "POST")
export class PostWebsiteAdminClearCache extends BaseStatelessRequest implements IReturn<PostWebsiteAdminClearCacheResponse>
{

    public constructor(init?: Partial<PostWebsiteAdminClearCache>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostWebsiteAdminClearCacheResponse(); }
    public getTypeName() { return 'PostWebsiteAdminClearCache'; }
}

// @Route("/websiteadmin/importdemo", "POST")
export class PostWebsiteAdminImportDemo extends BaseStatelessRequest implements IReturn<PostWebsiteAdminImportDemoResponse>
{

    public constructor(init?: Partial<PostWebsiteAdminImportDemo>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostWebsiteAdminImportDemoResponse(); }
    public getTypeName() { return 'PostWebsiteAdminImportDemo'; }
}

// @Route("/websiteproperties", "GET")
export class GetWebsiteProperties extends BaseStatelessRequest implements IReturn<GetWebsitePropertiesResponse>
{

    public constructor(init?: Partial<GetWebsiteProperties>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetWebsitePropertiesResponse(); }
    public getTypeName() { return 'GetWebsiteProperties'; }
}

// @Route("/websiteproperties", "POST")
export class PostWebsiteProperties extends BaseStatelessRequest implements IReturn<PostWebsitePropertiesResponse>
{
    public websiteProperties: WebsiteProperties;

    public constructor(init?: Partial<PostWebsiteProperties>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostWebsitePropertiesResponse(); }
    public getTypeName() { return 'PostWebsiteProperties'; }
}

// @Route("/industrycategories")
export class PostIndustryCategories extends BaseStatelessRequest implements IReturn<PostIndustryCategoriesResponse>
{
    public pageSize: number;
    public page: number;
    public filter: IndustryCategoriesFilter;

    public constructor(init?: Partial<PostIndustryCategories>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostIndustryCategoriesResponse(); }
    public getTypeName() { return 'PostIndustryCategories'; }
}

// @Route("/industrycategories/available")
export class GetIndustryCategoriesAvailable extends BaseStatelessRequest implements IReturn<GetIndustryCategoriesAvailableResponse>
{

    public constructor(init?: Partial<GetIndustryCategoriesAvailable>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetIndustryCategoriesAvailableResponse(); }
    public getTypeName() { return 'GetIndustryCategoriesAvailable'; }
}

// @Route("/industrycategory/{industryCategoryId}")
export class GetIndustryCategory extends BaseStatelessRequest implements IReturn<GetIndustryCategoryResponse>
{
    public industryCategoryId: number;

    public constructor(init?: Partial<GetIndustryCategory>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetIndustryCategoryResponse(); }
    public getTypeName() { return 'GetIndustryCategory'; }
}

// @Route("/industrycategory")
export class PostIndustryCategory extends BaseStatelessRequest implements IReturn<PostIndustryCategoryResponse>
{
    public industryCategory: IndustryCategory;

    public constructor(init?: Partial<PostIndustryCategory>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostIndustryCategoryResponse(); }
    public getTypeName() { return 'PostIndustryCategory'; }
}

// @Route("/industrycategory/{industryCategoryId}")
export class PostIndustryCategoryUpdate extends BaseStatelessRequest implements IReturn<PostIndustryCategoryUpdateResponse>
{
    public industryCategoryId: number;
    public industryCategory: IndustryCategory;

    public constructor(init?: Partial<PostIndustryCategoryUpdate>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostIndustryCategoryUpdateResponse(); }
    public getTypeName() { return 'PostIndustryCategoryUpdate'; }
}

// @Route("/incidentversions/{IncidentId}")
export class GetIncidentVersions extends BaseStatelessRequest implements IReturn<GetIncidentVersionsResponse>
{
    public incidentId: number;

    public constructor(init?: Partial<GetIncidentVersions>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetIncidentVersionsResponse(); }
    public getTypeName() { return 'GetIncidentVersions'; }
}

// @Route("/incidentversions/{IncidentId}/{Version}")
export class GetIncidentVersion extends BaseStatelessRequest implements IReturn<GetIncidentVersionResponse>
{
    public incidentId: number;
    public incidentRelaventId: number;
    public version: number;

    public constructor(init?: Partial<GetIncidentVersion>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetIncidentVersionResponse(); }
    public getTypeName() { return 'GetIncidentVersion'; }
}

// @Route("/notifications", "GET")
export class GetNotifications extends BaseStatelessRequest implements IReturn<GetNotificationsResponse>
{

    public constructor(init?: Partial<GetNotifications>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetNotificationsResponse(); }
    public getTypeName() { return 'GetNotifications'; }
}

// @Route("/notifications/action", "POST")
export class SendNotificationAction extends BaseStatelessRequest implements IReturn<SendNotificationActionResponse>
{
    public notificationId: number;
    public actionResponse: boolean;

    public constructor(init?: Partial<SendNotificationAction>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new SendNotificationActionResponse(); }
    public getTypeName() { return 'SendNotificationAction'; }
}

// @Route("/notifications/actions", "POST")
export class SendNotificationsAction extends BaseStatelessRequest implements IReturn<SendNotificationsActionResponse>
{
    public notificationIds: number[];
    public actionResponse: boolean;

    public constructor(init?: Partial<SendNotificationsAction>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new SendNotificationsActionResponse(); }
    public getTypeName() { return 'SendNotificationsAction'; }
}

// @Route("/systemlog", "GET")
export class GetSystemLog extends BaseStatelessRequest implements IReturn<GetSystemLogResponse>
{

    public constructor(init?: Partial<GetSystemLog>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetSystemLogResponse(); }
    public getTypeName() { return 'GetSystemLog'; }
}

// @Route("/notifications/venueclear", "POST")
export class ClearVenueNotifications extends BaseStatelessRequest implements IReturn<ClearVenueNotificationsResponse>, IPost
{
    public forVenueId: number;
    public simpleDate: SimpleDate;

    public constructor(init?: Partial<ClearVenueNotifications>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new ClearVenueNotificationsResponse(); }
    public getTypeName() { return 'ClearVenueNotifications'; }
}

// @Route("/notifications/securityfirmclear", "POST")
export class ClearSecurityFirmNotifications extends BaseStatelessRequest implements IReturn<ClearSecurityFirmNotificationsResponse>, IPost
{
    public forSecurityFirmId: number;
    public simpleDate: SimpleDate;

    public constructor(init?: Partial<ClearSecurityFirmNotifications>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new ClearSecurityFirmNotificationsResponse(); }
    public getTypeName() { return 'ClearSecurityFirmNotifications'; }
}

// @Route("/print/options", "GET")
export class GetPrintOptions extends BaseStatelessRequest implements IReturn<GetPrintOptionsResponse>
{

    public constructor(init?: Partial<GetPrintOptions>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetPrintOptionsResponse(); }
    public getTypeName() { return 'GetPrintOptions'; }
}

// @Route("/incidents/{Id}")
export class GetIncident extends BaseStatelessRequest implements IReturn<GetIncidentResponse>, IGet
{
    public id: number;
    public incidentTypeId: number;
    public entryPage: string;
    public includeOptions: boolean;

    public constructor(init?: Partial<GetIncident>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetIncidentResponse(); }
    public getTypeName() { return 'GetIncident'; }
}

// @Route("/home/snapshotsummary", "GET")
export class GetSnapshotSummary extends BaseStatelessRequest implements IReturn<GetGetSnapshotSummaryResponse>
{

    public constructor(init?: Partial<GetSnapshotSummary>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetGetSnapshotSummaryResponse(); }
    public getTypeName() { return 'GetSnapshotSummary'; }
}

// @Route("/incidents/{Id}/report")
export class GetIncidentReport extends BaseStatelessRequest implements IReturn<GetIncidentReportResponse>
{
    public option: string;
    public emailRecipients: string[];
    public emailCcRecipients: string[];
    public emailBccRecipients: string[];
    public id: number;
    public includeVersions: boolean;
    public generateDocumentOnly: boolean;
    public runInBackground: boolean;
    public imageOption: string;

    public constructor(init?: Partial<GetIncidentReport>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetIncidentReportResponse(); }
    public getTypeName() { return 'GetIncidentReport'; }
}

// @Route("/incidents/{Id}/incidentcategorytypedocument")
export class GetIncidentCategoryTypeDocument extends BaseStatelessRequest implements IReturn<GetIncidentCategoryTypeDocumentResponse>
{
    public option: string;
    public emailRecipients: string[];
    public emailCcRecipients: string[];
    public emailBccRecipients: string[];
    public id: number;
    public incidentCategoryTypeDocumentId: number;
    public generateDocumentOnly: boolean;
    public runInBackground: boolean;

    public constructor(init?: Partial<GetIncidentCategoryTypeDocument>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetIncidentCategoryTypeDocumentResponse(); }
    public getTypeName() { return 'GetIncidentCategoryTypeDocument'; }
}

// @Route("/incident/historyfilter")
export class GetIncidentHistoryFilter extends BaseStatelessRequest implements IReturn<GetIncidentHistoryFilterResponse>
{
    public reset: boolean;
    public requiringApprovals: boolean;
    public forEntryPage: string;

    public constructor(init?: Partial<GetIncidentHistoryFilter>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetIncidentHistoryFilterResponse(); }
    public getTypeName() { return 'GetIncidentHistoryFilter'; }
}

// @Route("/incidents/history/")
export class GetIncidentHistory extends BaseStatelessRequest implements IReturn<GetIncidentHistoryResponse>
{
    public filter: IncidentHistoryFilter;
    public search: string;
    public startDateEnteredUtc: string;
    public endDateEnteredUtc: string;
    public startDate: string;
    public startTime: string;
    public endDate: string;
    public endTime: string;
    public unapprovedOnly: boolean;
    public pageSize: number;
    public page: number;
    public saveFilter: boolean;

    public constructor(init?: Partial<GetIncidentHistory>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetIncidentHistoryResponse(); }
    public getTypeName() { return 'GetIncidentHistory'; }
}

// @Route("/incidents/historydetailedreport")
export class GetIncidentHistoryWithDetailsReport extends BaseStatelessRequest implements IReturn<GetIncidentHistoryWithDetailsReportResponse>
{
    public filter: IncidentHistoryFilter;
    public option: string;
    public emailRecipients: string[];
    public emailCcRecipients: string[];
    public emailBccRecipients: string[];
    public runInBackground: boolean;
    public imageOption: string;

    public constructor(init?: Partial<GetIncidentHistoryWithDetailsReport>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetIncidentHistoryWithDetailsReportResponse(); }
    public getTypeName() { return 'GetIncidentHistoryWithDetailsReport'; }
}

// @Route("/incidents/historydetailedversionsreport")
export class GetIncidentHistoryWithDetailVersionsReport extends BaseStatelessRequest implements IReturn<GetIncidentHistoryWithDetailVersionsReportResponse>
{
    public filter: IncidentHistoryFilter;
    public option: string;
    public emailRecipients: string[];
    public emailCcRecipients: string[];
    public emailBccRecipients: string[];
    public runInBackground: boolean;
    public imageOption: string;

    public constructor(init?: Partial<GetIncidentHistoryWithDetailVersionsReport>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetIncidentHistoryWithDetailVersionsReportResponse(); }
    public getTypeName() { return 'GetIncidentHistoryWithDetailVersionsReport'; }
}

// @Route("/incidents/", "POST")
export class SaveIncident extends BaseStatelessRequest implements IReturn<SaveIncidentResponse>
{
    public incident: Incident;
    public isDraft: boolean;
    public approve: boolean;

    public constructor(init?: Partial<SaveIncident>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new SaveIncidentResponse(); }
    public getTypeName() { return 'SaveIncident'; }
}

// @Route("/incident/generateuniqueId", "POST")
export class PostIncidentGenerateUniqueID extends BaseStatelessRequest implements IReturn<PostIncidentGenerateUniqueIDResponse>
{
    public incidentId: number;

    public constructor(init?: Partial<PostIncidentGenerateUniqueID>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostIncidentGenerateUniqueIDResponse(); }
    public getTypeName() { return 'PostIncidentGenerateUniqueID'; }
}

// @Route("/incidents/checklists", "POST")
export class PostIncidentChecklists extends BaseStatelessRequest implements IReturn<PostIncidentChecklistsResponse>
{
    public incident: Incident;

    public constructor(init?: Partial<PostIncidentChecklists>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostIncidentChecklistsResponse(); }
    public getTypeName() { return 'PostIncidentChecklists'; }
}

// @Route("/incidents/clearapprovals", "POST")
export class PostClearIncidentApprovals extends BaseStatelessRequest implements IReturn<PostClearIncidentApprovalsResponse>
{
    public forVenueId: number;
    public simpleDate: SimpleDate;

    public constructor(init?: Partial<PostClearIncidentApprovals>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostClearIncidentApprovalsResponse(); }
    public getTypeName() { return 'PostClearIncidentApprovals'; }
}

// @Route("/incidents/approve", "POST")
export class ApproveIncidents extends BaseStatelessRequest implements IReturn<ApproveIncidentsResponse>, IPost
{
    public incidentIds: number[];

    public constructor(init?: Partial<ApproveIncidents>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new ApproveIncidentsResponse(); }
    public getTypeName() { return 'ApproveIncidents'; }
}

// @Route("/incidents/historyfilterreport")
export class AnyIncidentHistoryFilterReport extends BaseStatelessRequest implements IReturn<AnyIncidentHistoryFilterReportResponse>
{
    public filter: IncidentHistoryFilter;
    public option: string;
    public emailRecipients: string[];
    public emailCcRecipients: string[];
    public emailBccRecipients: string[];
    public runInBackground: boolean;
    public imageOption: string;

    public constructor(init?: Partial<AnyIncidentHistoryFilterReport>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new AnyIncidentHistoryFilterReportResponse(); }
    public getTypeName() { return 'AnyIncidentHistoryFilterReport'; }
}

// @Route("/incidents/builddocument")
export class PostIncidentBuildDocument extends BaseStatelessRequest implements IReturn<PostIncidentBuildDocumentResponse>
{
    public incidentID: number;
    public incidentCategoryTypeDocumentID: number;

    public constructor(init?: Partial<PostIncidentBuildDocument>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostIncidentBuildDocumentResponse(); }
    public getTypeName() { return 'PostIncidentBuildDocument'; }
}

// @Route("/registeruser", "GET")
export class GetRegisterUser implements IReturn<GetRegisterUserResponse>
{

    public constructor(init?: Partial<GetRegisterUser>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetRegisterUserResponse(); }
    public getTypeName() { return 'GetRegisterUser'; }
}

// @Route("/reportgeneration/active", "GET")
export class GetReportGenerationActive extends BaseStatelessRequest implements IReturn<GetReportGenerationActiveResponse>
{

    public constructor(init?: Partial<GetReportGenerationActive>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetReportGenerationActiveResponse(); }
    public getTypeName() { return 'GetReportGenerationActive'; }
}

// @Route("/reportgeneration/downloaded", "POST")
export class PostReportGenerationDownloaded extends BaseStatelessRequest implements IReturn<PostReportGenerationDownloadedResponse>
{
    public reportGenerationId: number;

    public constructor(init?: Partial<PostReportGenerationDownloaded>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostReportGenerationDownloadedResponse(); }
    public getTypeName() { return 'PostReportGenerationDownloaded'; }
}

// @Route("/reportgeneration/dismissed", "POST")
export class PostReportGenerationDismissed extends BaseStatelessRequest implements IReturn<PostReportGenerationDismissedResponse>
{
    public reportGenerationId: number;

    public constructor(init?: Partial<PostReportGenerationDismissed>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostReportGenerationDismissedResponse(); }
    public getTypeName() { return 'PostReportGenerationDismissed'; }
}

// @Route("/reportgeneration/dismissall", "POST")
export class PostReportGenerationDismissAll extends BaseStatelessRequest implements IReturn<PostReportGenerationDismissAllResponse>
{

    public constructor(init?: Partial<PostReportGenerationDismissAll>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostReportGenerationDismissAllResponse(); }
    public getTypeName() { return 'PostReportGenerationDismissAll'; }
}

// @Route("/reportgeneration/{ReportGenerationId}", "GET")
export class GetReportGeneration extends BaseStatelessRequest implements IReturn<GetReportGenerationResponse>
{
    public reportGenerationId: number;

    public constructor(init?: Partial<GetReportGeneration>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetReportGenerationResponse(); }
    public getTypeName() { return 'GetReportGeneration'; }
}

// @Route("/reportgenerated/{ReportGenerationId}", "GET")
export class GetReportGenerated extends BaseStatelessRequest implements IReturn<GetReportGeneratedResponse>
{
    public reportGenerationId: number;
    public downloadToken: string;

    public constructor(init?: Partial<GetReportGenerated>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetReportGeneratedResponse(); }
    public getTypeName() { return 'GetReportGenerated'; }
}

// @Route("/report/run")
export class AnyReportRun implements IReturn<AnyReportRunResponse>
{
    public reportOptions: ReportOptions;

    public constructor(init?: Partial<AnyReportRun>) { (Object as any).assign(this, init); }
    public createResponse() { return new AnyReportRunResponse(); }
    public getTypeName() { return 'AnyReportRun'; }
}

// @Route("/roles", "GET")
export class GetUserRoleTypes implements IReturn<GetUserRoleTypesResponse>, IGet
{
    public forGroupId: number;
    public forVenueId: number;
    public forSecurityFirmId: number;

    public constructor(init?: Partial<GetUserRoleTypes>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetUserRoleTypesResponse(); }
    public getTypeName() { return 'GetUserRoleTypes'; }
}

// @Route("/roles/{Id}", "GET")
export class GetUserRoleType extends BaseStatelessRequest implements IReturn<GetUserRoleTypeResponse>, IGet
{
    public id: number;

    public constructor(init?: Partial<GetUserRoleType>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetUserRoleTypeResponse(); }
    public getTypeName() { return 'GetUserRoleType'; }
}

// @Route("/roles/newuserroletype", "POST")
export class NewUserRoleType extends BaseStatelessRequest implements IReturn<NewUserRoleTypeResponse>, IPost
{
    public cloneUserRoleTypeId: number;

    public constructor(init?: Partial<NewUserRoleType>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new NewUserRoleTypeResponse(); }
    public getTypeName() { return 'NewUserRoleType'; }
}

// @Route("/roles/userroletype", "POST")
export class PostUserRoleType extends BaseStatelessRequest implements IReturn<PostUserRoleTypeResponse>
{
    public userRoleType: UserRoleType;
    public localRoleEditMode: boolean;

    public constructor(init?: Partial<PostUserRoleType>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostUserRoleTypeResponse(); }
    public getTypeName() { return 'PostUserRoleType'; }
}

// @Route("/roles/{UserRoleTypeId}", "PUT")
export class UpdateUserRoleType extends BaseStatelessRequest implements IReturn<UpdateUserRoleTypeResponse>, IPut
{
    public userRoleType: UserRoleType;
    public localRoleEditMode: boolean;

    public constructor(init?: Partial<UpdateUserRoleType>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new UpdateUserRoleTypeResponse(); }
    public getTypeName() { return 'UpdateUserRoleType'; }
}

// @Route("/roles/{UserRoleTypeId}", "DELETE")
export class DeleteUserRoleType extends BaseStatelessRequest implements IReturn<DeleteUserRoleTypeResponse>, IDelete
{
    public userRoleTypeId: number;

    public constructor(init?: Partial<DeleteUserRoleType>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new DeleteUserRoleTypeResponse(); }
    public getTypeName() { return 'DeleteUserRoleType'; }
}

// @Route("/roles/search", "POST")
export class PostUserRoleTypes extends BaseStatelessRequest implements IReturn<PostUserRoleTypesResponse>
{
    public pageSize: number;
    public page: number;
    public filter: UserRoleTypeFilter;

    public constructor(init?: Partial<PostUserRoleTypes>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostUserRoleTypesResponse(); }
    public getTypeName() { return 'PostUserRoleTypes'; }
}

// @Route("/referencedata/industrycategories", "GET")
export class GetIndustryCategories extends BaseStatelessRequest implements IReturn<GetIndustryCategoriesResponse>
{

    public constructor(init?: Partial<GetIndustryCategories>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetIndustryCategoriesResponse(); }
    public getTypeName() { return 'GetIndustryCategories'; }
}

// @Route("/referencedata/states", "GET")
export class GetStates extends BaseStatelessRequest implements IReturn<GetStatesResponse>
{

    public constructor(init?: Partial<GetStates>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetStatesResponse(); }
    public getTypeName() { return 'GetStates'; }
}

// @Route("/referencedata/checkliststatuses", "GET")
export class GetChecklistStatuses extends BaseStatelessRequest implements IReturn<GetChecklistStatusesResponse>
{

    public constructor(init?: Partial<GetChecklistStatuses>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetChecklistStatusesResponse(); }
    public getTypeName() { return 'GetChecklistStatuses'; }
}

// @Route("/referencedata/incidenttypes/")
export class GetIncidentTypesReferenceData extends BaseStatelessRequest implements IReturn<GetIncidentTypesReferenceDataResponse>
{

    public constructor(init?: Partial<GetIncidentTypesReferenceData>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetIncidentTypesReferenceDataResponse(); }
    public getTypeName() { return 'GetIncidentTypesReferenceData'; }
}

// @Route("/referencedata/incidentcategorytypes/")
export class GetIncidentCategoryTypesReferenceData extends BaseStatelessRequest implements IReturn<GetIncidentCategoryTypesReferenceDataResponse>
{

    public constructor(init?: Partial<GetIncidentCategoryTypesReferenceData>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetIncidentCategoryTypesReferenceDataResponse(); }
    public getTypeName() { return 'GetIncidentCategoryTypesReferenceData'; }
}

// @Route("/referencedata/venues")
export class Venues extends BaseStatelessRequest implements IReturn<VenuesResponse>
{

    public constructor(init?: Partial<Venues>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new VenuesResponse(); }
    public getTypeName() { return 'Venues'; }
}

// @Route("/referencedata/securityfirms")
export class SecurityFirms extends BaseStatelessRequest implements IReturn<SecurityFirmsResponse>
{

    public constructor(init?: Partial<SecurityFirms>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new SecurityFirmsResponse(); }
    public getTypeName() { return 'SecurityFirms'; }
}

// @Route("/referencedata/checklisttemplatecategories/")
export class GetChecklistTemplateCategoriesReferenceData extends BaseStatelessRequest implements IReturn<GetChecklistTemplateCategoriesReferenceDataResponse>
{

    public constructor(init?: Partial<GetChecklistTemplateCategoriesReferenceData>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetChecklistTemplateCategoriesReferenceDataResponse(); }
    public getTypeName() { return 'GetChecklistTemplateCategoriesReferenceData'; }
}

// @Route("/securityregister/personnel/{LicenceNumber}", "GET")
export class GetPersonnelSecurityRegisterDetails implements IReturn<GetPersonnelSecurityRegisterDetailsResponse>, IGet
{
    public licenceNumber: string;

    public constructor(init?: Partial<GetPersonnelSecurityRegisterDetails>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetPersonnelSecurityRegisterDetailsResponse(); }
    public getTypeName() { return 'GetPersonnelSecurityRegisterDetails'; }
}

// @Route("/securityregister/personnel/bulk", "GET")
export class GetBulkPersonnelSecurityRegisterDetails implements IReturn<GetBulkPersonnelSecurityRegisterDetailsResponse>, IGet
{
    public licenceNumbers: string[];

    public constructor(init?: Partial<GetBulkPersonnelSecurityRegisterDetails>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetBulkPersonnelSecurityRegisterDetailsResponse(); }
    public getTypeName() { return 'GetBulkPersonnelSecurityRegisterDetails'; }
}

// @Route("/securityregister/securityfirms/bulk", "GET")
export class GetBulkSecurityFirmSecurityRegisterDetails implements IReturn<GetBulkSecurityFirmSecurityRegisterDetailsResponse>, IGet
{
    public licenceNumbers: string[];

    public constructor(init?: Partial<GetBulkSecurityFirmSecurityRegisterDetails>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetBulkSecurityFirmSecurityRegisterDetailsResponse(); }
    public getTypeName() { return 'GetBulkSecurityFirmSecurityRegisterDetails'; }
}

// @Route("/securityregister/securityfirms/{LicenceNumber}", "GET")
export class GetSecurityFirmSecurityRegisterDetails implements IReturn<GetSecurityFirmSecurityRegisterDetailsResponse>, IGet
{
    public licenceNumber: string;

    public constructor(init?: Partial<GetSecurityFirmSecurityRegisterDetails>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetSecurityFirmSecurityRegisterDetailsResponse(); }
    public getTypeName() { return 'GetSecurityFirmSecurityRegisterDetails'; }
}

// @Route("/licencecheck/securitypersonnel/{LicenceNumber}", "GET")
export class GetSecurityPersonnelLicenceDetails implements IReturn<GetSecurityPersonnelLicenceDetailsResponse>, IGet
{
    public licenceNumber: string;

    public constructor(init?: Partial<GetSecurityPersonnelLicenceDetails>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetSecurityPersonnelLicenceDetailsResponse(); }
    public getTypeName() { return 'GetSecurityPersonnelLicenceDetails'; }
}

// @Route("/licencecheck/securityfirm/{LicenceNumber}")
export class GetSecurityFirmLicenceDetails implements IReturn<GetSecurityFirmLicenceDetailsResponse>
{
    public licenceNumber: string;

    public constructor(init?: Partial<GetSecurityFirmLicenceDetails>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetSecurityFirmLicenceDetailsResponse(); }
    public getTypeName() { return 'GetSecurityFirmLicenceDetails'; }
}

// @Route("/signonregister/signon")
export class SignOnToRegister extends BaseStatelessRequest implements IReturn<SignOnToRegisterResponse>
{
    public userId: number;
    public userRoleId: number;
    public password: string;
    public guardNumber: string;
    public supervisorRequired: boolean;
    public supervisorUserId: number;
    public latitude: number;
    public longitude: number;
    public rosteredStart: string;
    public shiftHours: number;
    public shiftMinutes: number;
    public userSignOnStatus: UserSignOnStatus;

    public constructor(init?: Partial<SignOnToRegister>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new SignOnToRegisterResponse(); }
    public getTypeName() { return 'SignOnToRegister'; }
}

// @Route("/signonregister/usercheck")
export class SignOnToRegisterUserCheck extends BaseStatelessRequest implements IReturn<SignOnToRegisterUserCheckResponse>
{
    public userRoleId: number;

    public constructor(init?: Partial<SignOnToRegisterUserCheck>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new SignOnToRegisterUserCheckResponse(); }
    public getTypeName() { return 'SignOnToRegisterUserCheck'; }
}

// @Route("/signonregister/signoff")
export class SignOffRegister extends BaseStatelessRequest implements IReturn<SignOffRegisterResponse>
{
    public userId: number;
    public userRoleId: number;
    public password: string;
    public latitude: number;
    public longitude: number;
    public signOnRegisterId: number;
    public isAcknowledged: boolean;
    public signOutDate: string;
    public signOff: SignOff;

    public constructor(init?: Partial<SignOffRegister>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new SignOffRegisterResponse(); }
    public getTypeName() { return 'SignOffRegister'; }
}

// @Route("/signonregister/extend")
export class SignOnRegisterExtend extends BaseStatelessRequest implements IReturn<SignOnRegisterExtendResponse>
{
    public signOnRegisterId: number;
    public password: string;
    public hours: number;
    public minutes: number;
    public signOnExtend: SignOnExtend;

    public constructor(init?: Partial<SignOnRegisterExtend>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new SignOnRegisterExtendResponse(); }
    public getTypeName() { return 'SignOnRegisterExtend'; }
}

// @Route("/signonregister/filter")
export class GetSignOnRegisterFilter extends BaseStatelessRequest implements IReturn<GetSignOnRegisterFilterResponse>
{
    public reset: boolean;

    public constructor(init?: Partial<GetSignOnRegisterFilter>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetSignOnRegisterFilterResponse(); }
    public getTypeName() { return 'GetSignOnRegisterFilter'; }
}

// @Route("/signonregisters")
export class GetSignOnRegisters extends BaseStatelessRequest implements IReturn<GetSignOnRegistersResponse>
{
    public filter: SignOnRegisterFilter;

    public constructor(init?: Partial<GetSignOnRegisters>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetSignOnRegistersResponse(); }
    public getTypeName() { return 'GetSignOnRegisters'; }
}

// @Route("/signonregisters/print")
export class PostSignOnRegistersReport extends BaseStatelessRequest implements IReturn<PostSignOnRegistersReportResponse>
{
    public option: string;
    public emailRecipients: string[];
    public emailCcRecipients: string[];
    public emailBccRecipients: string[];
    public filter: SignOnRegisterFilter;

    public constructor(init?: Partial<PostSignOnRegistersReport>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostSignOnRegistersReportResponse(); }
    public getTypeName() { return 'PostSignOnRegistersReport'; }
}

// @Route("/signonregister/break")
export class PostSignOnRegisterBreak extends BaseStatelessRequest implements IReturn<PostSignOnRegisterBreakResponse>
{
    public signOnRegisterId: number;

    public constructor(init?: Partial<PostSignOnRegisterBreak>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostSignOnRegisterBreakResponse(); }
    public getTypeName() { return 'PostSignOnRegisterBreak'; }
}

// @Route("/signonregister/breakupdate")
export class PostSignOnRegisterBreakUpdate extends BaseStatelessRequest implements IReturn<PostSignOnRegisterBreakUpdateResponse>
{
    public signOnRegisterBreak: SignOnRegisterBreak;

    public constructor(init?: Partial<PostSignOnRegisterBreakUpdate>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostSignOnRegisterBreakUpdateResponse(); }
    public getTypeName() { return 'PostSignOnRegisterBreakUpdate'; }
}

// @Route("/incidentcategorytype/venuebyusage")
export class GetVenueIncidentCategoryTypesByUsage implements IReturn<GetVenueIncidentCategoryTypesByUsageResponse>
{
    public venueId: number;
    public securityFirmId: number;
    public incidentTypeId: number;
    public top: number;
    public includeObsoleteForIncidentId: number;

    public constructor(init?: Partial<GetVenueIncidentCategoryTypesByUsage>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetVenueIncidentCategoryTypesByUsageResponse(); }
    public getTypeName() { return 'GetVenueIncidentCategoryTypesByUsage'; }
}

// @Route("/incidentcategorytypes")
export class PostIncidentCategoryTypes extends BaseStatelessRequest implements IReturn<PostIncidentCategoryTypesResponse>
{
    public pageSize: number;
    public page: number;
    public filter: IncidentCategoryTypeFilter;

    public constructor(init?: Partial<PostIncidentCategoryTypes>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostIncidentCategoryTypesResponse(); }
    public getTypeName() { return 'PostIncidentCategoryTypes'; }
}

// @Route("/incidentcategorytypes/new")
export class PostIncidentCategoryTypeNew extends BaseStatelessRequest implements IReturn<PostIncidentCategoryTypeNewResponse>
{
    public forVenueId: number;
    public forSecurityFirmId: number;

    public constructor(init?: Partial<PostIncidentCategoryTypeNew>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostIncidentCategoryTypeNewResponse(); }
    public getTypeName() { return 'PostIncidentCategoryTypeNew'; }
}

// @Route("/incidentcategorytype/{incidentCategoryTypeId}")
export class PostIncidentCategoryType extends BaseStatelessRequest implements IReturn<PostIncidentCategoryTypeResponse>
{
    public incidentCategoryTypeId: number;
    public incidentCategoryType: IncidentCategoryType;

    public constructor(init?: Partial<PostIncidentCategoryType>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostIncidentCategoryTypeResponse(); }
    public getTypeName() { return 'PostIncidentCategoryType'; }
}

// @Route("/incidentcategorytype/{incidentCategoryTypeId}")
export class GetIncidentCategoryTypeById extends BaseStatelessRequest implements IReturn<GetIncidentCategoryTypeByIdResponse>
{
    public incidentCategoryTypeId: number;
    public specific: boolean;

    public constructor(init?: Partial<GetIncidentCategoryTypeById>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetIncidentCategoryTypeByIdResponse(); }
    public getTypeName() { return 'GetIncidentCategoryTypeById'; }
}

// @Route("/incidentcategorytype/hide")
export class PostIncidentCategoryTypeHide extends BaseStatelessRequest implements IReturn<PostIncidentCategoryTypeHideResponse>
{
    public incidentCategoryTypeId: number;
    public forVenueId: number;
    public forSecurityFirmId: number;
    public hide: boolean;

    public constructor(init?: Partial<PostIncidentCategoryTypeHide>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostIncidentCategoryTypeHideResponse(); }
    public getTypeName() { return 'PostIncidentCategoryTypeHide'; }
}

// @Route("/incidentcategorytype/reportingperiod")
export class GetIncidentCategoryTypeUsageByReportingPeriod extends BaseStatelessRequest implements IReturn<GetIncidentCategoryTypeUsageByReportingPeriodResponse>
{
    public incidentTypeId: number;
    public entryPage: string;
    public reportingPeriodId: number;

    public constructor(init?: Partial<GetIncidentCategoryTypeUsageByReportingPeriod>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetIncidentCategoryTypeUsageByReportingPeriodResponse(); }
    public getTypeName() { return 'GetIncidentCategoryTypeUsageByReportingPeriod'; }
}

// @Route("/ping")
export class GetPing implements IReturn<GetPingResponse>
{
    public message: string;

    public constructor(init?: Partial<GetPing>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetPingResponse(); }
    public getTypeName() { return 'GetPing'; }
}

// @Route("/appinsightstest")
export class GetAppInsightsTest implements IReturn<GetAppInsightsTestResponse>
{
    public message: string;

    public constructor(init?: Partial<GetAppInsightsTest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetAppInsightsTestResponse(); }
    public getTypeName() { return 'GetAppInsightsTest'; }
}

// @Route("/developertest")
export class GetDeveloperTest implements IReturn<GetDeveloperTestResponse>
{
    public message: string;

    public constructor(init?: Partial<GetDeveloperTest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetDeveloperTestResponse(); }
    public getTypeName() { return 'GetDeveloperTest'; }
}

// @Route("/notificationtypes/{NotificationTypeId}")
export class GetNotificationType implements IReturn<GetNotificationTypeResponse>
{
    public notificationTypeId: number;

    public constructor(init?: Partial<GetNotificationType>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetNotificationTypeResponse(); }
    public getTypeName() { return 'GetNotificationType'; }
}

// @Route("/timezones/{TimezoneId}")
export class GetTimezone implements IReturn<GetTimezoneResponse>
{
    public timezoneId: number;

    public constructor(init?: Partial<GetTimezone>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetTimezoneResponse(); }
    public getTypeName() { return 'GetTimezone'; }
}

// @Route("/userdocuments/other", "POST")
export class PostUserOtherDocument extends BaseStatelessRequest implements IReturn<PostUserOtherDocumentResponse>
{
    public userId: number;
    public documentTypeId: number;
    public files: File[];

    public constructor(init?: Partial<PostUserOtherDocument>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostUserOtherDocumentResponse(); }
    public getTypeName() { return 'PostUserOtherDocument'; }
}

// @Route("/userdocuments/other/remove", "POST")
export class PostUserOtherDocumentRemove extends BaseStatelessRequest implements IReturn<PostUserOtherDocumentRemoveResponse>
{
    public userId: number;
    public userDocumentId: number;
    public userOtherDocumentId: number;

    public constructor(init?: Partial<PostUserOtherDocumentRemove>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostUserOtherDocumentRemoveResponse(); }
    public getTypeName() { return 'PostUserOtherDocumentRemove'; }
}

// @Route("/userdocuments/compliance", "POST")
export class PostUserComplianceDocument extends BaseStatelessRequest implements IReturn<PostUserComplianceDocumentResponse>
{
    public userId: number;
    public documentTypeId: number;
    public expiryDate: SimpleDate;
    public files: File[];

    public constructor(init?: Partial<PostUserComplianceDocument>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostUserComplianceDocumentResponse(); }
    public getTypeName() { return 'PostUserComplianceDocument'; }
}

// @Route("/userdocuments/compliance/remove", "POST")
export class PostUserComplianceDocumentRemove extends BaseStatelessRequest implements IReturn<PostUserComplianceDocumentRemoveResponse>
{
    public userId: number;
    public userDocumentId: number;
    public userComplianceDocumentId: number;

    public constructor(init?: Partial<PostUserComplianceDocumentRemove>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostUserComplianceDocumentRemoveResponse(); }
    public getTypeName() { return 'PostUserComplianceDocumentRemove'; }
}

// @Route("/admin/useractionaudits")
export class PostAdminUserActionAudits extends BaseStatelessRequest implements IReturn<PostAdminUserActionAuditsResponse>
{
    public userId: number;
    public pageSize: number;
    public page: number;

    public constructor(init?: Partial<PostAdminUserActionAudits>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostAdminUserActionAuditsResponse(); }
    public getTypeName() { return 'PostAdminUserActionAudits'; }
}

// @Route("/useractionaudits", "POST")
export class PostUserActionAudit extends BaseStatelessRequest implements IReturn<PostUserActionAuditResponse>
{
    public actionType: ngtUserActionType;
    public deviceId: string;
    public os: string;
    public model: string;
    public make: string;
    public version: string;
    public appVersion: string;
    public locationFound: boolean;
    public latitude: number;
    public longitude: number;

    public constructor(init?: Partial<PostUserActionAudit>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostUserActionAuditResponse(); }
    public getTypeName() { return 'PostUserActionAudit'; }
}

// @Route("/userrole/checkapproved", "GET")
export class GetCheckApprovedUserRole implements IReturn<GetCheckApprovedUserRoleResponse>
{
    public userId: number;
    public venueId: number;
    public securityFirmId: number;

    public constructor(init?: Partial<GetCheckApprovedUserRole>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetCheckApprovedUserRoleResponse(); }
    public getTypeName() { return 'GetCheckApprovedUserRole'; }
}

// @Route("/userroles/requestrole", "POST")
export class SendUserRoleRequest extends BaseStatelessRequest implements IReturn<SendUserRoleRequestResponse>
{
    public venueIds: number[];
    public securityFirmIds: number[];
    public userId: number;

    public constructor(init?: Partial<SendUserRoleRequest>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new SendUserRoleRequestResponse(); }
    public getTypeName() { return 'SendUserRoleRequest'; }
}

// @Route("/userroles/requestroledismiss", "POST")
export class SendUserRoleRequestDismiss extends BaseStatelessRequest implements IReturn<SendUserRoleRequestDismissResponse>
{
    public notificationId: number;

    public constructor(init?: Partial<SendUserRoleRequestDismiss>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new SendUserRoleRequestDismissResponse(); }
    public getTypeName() { return 'SendUserRoleRequestDismiss'; }
}

// @Route("/userroles/newuserrole", "POST")
export class NewUserRole extends BaseStatelessRequest implements IReturn<NewUserRoleResponse>
{
    public userId: number;
    public forSecurityFirmId: number;
    public addForSecurityFirm: boolean;

    public constructor(init?: Partial<NewUserRole>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new NewUserRoleResponse(); }
    public getTypeName() { return 'NewUserRole'; }
}

// @Route("/userroles", "POST")
export class PostUserRole extends BaseStatelessRequest implements IReturn<PostUserRoleResponse>
{
    public notificationId: number;
    public userRole: UserRole;

    public constructor(init?: Partial<PostUserRole>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostUserRoleResponse(); }
    public getTypeName() { return 'PostUserRole'; }
}

// @Route("/userrole/remove", "POST")
export class PostDeleteUserRole extends BaseStatelessRequest implements IReturn<PostDeleteUserRoleResponse>
{
    public userRoleId: number;
    public adminNotes: string;

    public constructor(init?: Partial<PostDeleteUserRole>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostDeleteUserRoleResponse(); }
    public getTypeName() { return 'PostDeleteUserRole'; }
}

// @Route("/userroles/{UserRoleId}", "GET")
export class GetUserRole extends BaseStatelessRequest implements IReturn<GetUserRoleResponse>
{
    public userRoleId: number;

    public constructor(init?: Partial<GetUserRole>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetUserRoleResponse(); }
    public getTypeName() { return 'GetUserRole'; }
}

// @Route("/userroles/{UserRoleId}", "PUT")
export class UpdateUserRole extends BaseStatelessRequest implements IReturn<UpdateUserRoleResponse>
{
    public userRoleId: number;
    public adminNotes: string;
    public userRole: UserRole;

    public constructor(init?: Partial<UpdateUserRole>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new UpdateUserRoleResponse(); }
    public getTypeName() { return 'UpdateUserRole'; }
}

// @Route("/admin/userroles")
export class PostAdminUserRoles extends BaseStatelessRequest implements IReturn<PostAdminUserRolesResponse>
{
    public pageSize: number;
    public page: number;
    public filter: UserRoleFilter;

    public constructor(init?: Partial<PostAdminUserRoles>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostAdminUserRolesResponse(); }
    public getTypeName() { return 'PostAdminUserRoles'; }
}

// @Route("/userrole/reenable", "POST")
export class PostReEnableUserRole extends BaseStatelessRequest implements IReturn<PostReEnableUserRoleResponse>
{
    public userRoleId: number;
    public adminNotes: string;

    public constructor(init?: Partial<PostReEnableUserRole>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostReEnableUserRoleResponse(); }
    public getTypeName() { return 'PostReEnableUserRole'; }
}

// @Route("/userroles/search", "POST")
export class PostUserRoles extends BaseStatelessRequest implements IReturn<PostUserRolesResponse>
{
    public pageSize: number;
    public page: number;
    public filter: UserRoleFilter;

    public constructor(init?: Partial<PostUserRoles>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostUserRolesResponse(); }
    public getTypeName() { return 'PostUserRoles'; }
}

// @Route("/userroles/export")
export class AnyUserRolesExport extends BaseStatelessRequest implements IReturn<AnyUserRolesExportResponse>
{
    public filter: UserRoleFilter;

    public constructor(init?: Partial<AnyUserRolesExport>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new AnyUserRolesExportResponse(); }
    public getTypeName() { return 'AnyUserRolesExport'; }
}

// @Route("/userroles/suspend", "POST")
export class PostUserRolesSuspend extends BaseStatelessRequest implements IReturn<PostUserRolesSuspendResponse>
{
    public forVenueId: number;
    public forSecurityFirmId: number;
    public undo: boolean;

    public constructor(init?: Partial<PostUserRolesSuspend>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostUserRolesSuspendResponse(); }
    public getTypeName() { return 'PostUserRolesSuspend'; }
}

// @Route("/userlogin/", "POST")
export class PostUserLoginLastUpdated implements IReturn<PostUserLoginLastUpdatedResponse>
{
    public userId: number;
    public venueId: number;
    public securityFirmId: number;

    public constructor(init?: Partial<PostUserLoginLastUpdated>) { (Object as any).assign(this, init); }
    public createResponse() { return new PostUserLoginLastUpdatedResponse(); }
    public getTypeName() { return 'PostUserLoginLastUpdated'; }
}

// @Route("/home/loggedinusers", "GET")
export class GetLoggedInUsers extends BaseStatelessRequest implements IReturn<GetLoggedInUsersResponse>
{

    public constructor(init?: Partial<GetLoggedInUsers>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetLoggedInUsersResponse(); }
    public getTypeName() { return 'GetLoggedInUsers'; }
}

// @Route("/users/{Id}", "GET")
export class GetUser extends BaseStatelessRequest implements IReturn<GetUserResponse>
{
    public id: number;
    public includeReferences: boolean;
    public showObsoleteUserRoles: boolean;
    public showObsoleteDocuments: boolean;

    public constructor(init?: Partial<GetUser>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetUserResponse(); }
    public getTypeName() { return 'GetUser'; }
}

// @Route("/userview/{Id}", "GET")
export class GetUserView extends BaseStatelessRequest implements IReturn<GetUserViewResponse>
{
    public id: number;

    public constructor(init?: Partial<GetUserView>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetUserViewResponse(); }
    public getTypeName() { return 'GetUserView'; }
}

// @Route("/newuser", "GET")
export class GetNewUser extends BaseStatelessRequest implements IReturn<GetNewUserResponse>
{

    public constructor(init?: Partial<GetNewUser>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetNewUserResponse(); }
    public getTypeName() { return 'GetNewUser'; }
}

// @Route("/newuser", "POST")
export class PostNewUser extends BaseStatelessRequest implements IReturn<PostNewUserResponse>
{
    public registerAs: ngtUserRegistrationType;
    public user: User;
    public avatar: File;
    public venueIds: number[];
    public securityFirmIds: number[];
    public userOtherDocuments: File[];
    public userComplianceDocuments: File[];

    public constructor(init?: Partial<PostNewUser>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostNewUserResponse(); }
    public getTypeName() { return 'PostNewUser'; }
}

// @Route("/useravatar", "POST")
export class PostUpdateUserAvatar extends BaseStatelessRequest implements IReturn<PostUpdateUserAvatarResponse>
{
    public userId: number;
    public file: File;

    public constructor(init?: Partial<PostUpdateUserAvatar>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostUpdateUserAvatarResponse(); }
    public getTypeName() { return 'PostUpdateUserAvatar'; }
}

// @Route("/userresendverification", "POST")
export class PostUserResendVerificationEmail extends BaseStatelessRequest implements IReturn<PostUserResendVerificationEmailResponse>
{

    public constructor(init?: Partial<PostUserResendVerificationEmail>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostUserResendVerificationEmailResponse(); }
    public getTypeName() { return 'PostUserResendVerificationEmail'; }
}

// @Route("/useremailverification", "POST")
export class PostUserEmailVerification extends BaseStatelessRequest implements IReturn<PostUserEmailVerificationResponse>
{
    public userID: number;
    public guid: string;
    public password: string;

    public constructor(init?: Partial<PostUserEmailVerification>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostUserEmailVerificationResponse(); }
    public getTypeName() { return 'PostUserEmailVerification'; }
}

// @Route("/users/{Id}", "POST")
export class PostUpdateUser extends BaseStatelessRequest implements IReturn<PostUpdateUserResponse>
{
    public id: number;
    public user: User;

    public constructor(init?: Partial<PostUpdateUser>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostUpdateUserResponse(); }
    public getTypeName() { return 'PostUpdateUser'; }
}

// @Route("/usersledcheck", "POST")
export class PostUserSledCheck extends BaseStatelessRequest implements IReturn<PostUserSledCheckResponse>
{
    public user: User;

    public constructor(init?: Partial<PostUserSledCheck>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostUserSledCheckResponse(); }
    public getTypeName() { return 'PostUserSledCheck'; }
}

// @Route("/admin/userswithduplicatemobiles")
export class GetUsersWithDuplicateMobiles extends BaseStatelessRequest implements IReturn<GetUsersWithDuplicateMobilesResponse>
{
    public search: string;
    public pageSize: number;
    public page: number;

    public constructor(init?: Partial<GetUsersWithDuplicateMobiles>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetUsersWithDuplicateMobilesResponse(); }
    public getTypeName() { return 'GetUsersWithDuplicateMobiles'; }
}

// @Route("/forgotpassword", "POST")
export class PostUserForgotPassword extends BaseStatelessRequest implements IReturn<PostUserForgotPasswordResponse>
{
    public username: string;

    public constructor(init?: Partial<PostUserForgotPassword>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostUserForgotPasswordResponse(); }
    public getTypeName() { return 'PostUserForgotPassword'; }
}

// @Route("/forgotpasswordvalidate", "POST")
export class PostUserForgotPasswordValidate extends BaseStatelessRequest implements IReturn<PostUserForgotPasswordValidateResponse>
{
    public userId: number;
    public userForgotPasswordId: number;
    public forgotPasswordGuid: string;

    public constructor(init?: Partial<PostUserForgotPasswordValidate>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostUserForgotPasswordValidateResponse(); }
    public getTypeName() { return 'PostUserForgotPasswordValidate'; }
}

// @Route("/resetpassword", "POST")
export class PostUserResetPassword extends BaseStatelessRequest implements IReturn<PostUserResetPasswordResponse>
{
    public userId: number;
    public userForgotPasswordId: number;
    public forgotPasswordGuid: string;
    public newPassword: string;
    public newPasswordConfirm: string;

    public constructor(init?: Partial<PostUserResetPassword>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostUserResetPasswordResponse(); }
    public getTypeName() { return 'PostUserResetPassword'; }
}

// @Route("/resetusermobile", "POST")
export class PostUserResetMobile extends BaseStatelessRequest implements IReturn<PostUserResetMobileResponse>
{
    public userId: number;
    public mobileNo: string;

    public constructor(init?: Partial<PostUserResetMobile>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostUserResetMobileResponse(); }
    public getTypeName() { return 'PostUserResetMobile'; }
}

// @Route("/userchangepassword", "POST")
export class PostUserChangePassword extends BaseStatelessRequest implements IReturn<PostUserChangePasswordResponse>
{
    public userId: number;
    public oldPassword: string;
    public newPassword: string;
    public newPasswordConfirm: string;

    public constructor(init?: Partial<PostUserChangePassword>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostUserChangePasswordResponse(); }
    public getTypeName() { return 'PostUserChangePassword'; }
}

// @Route("/requestforgotpasswordcode", "POST")
export class PostRequestForgotPasswordCode extends BaseStatelessRequest implements IReturn<PostRequestForgotPasswordCodeResponse>
{
    public mobile: string;

    public constructor(init?: Partial<PostRequestForgotPasswordCode>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostRequestForgotPasswordCodeResponse(); }
    public getTypeName() { return 'PostRequestForgotPasswordCode'; }
}

// @Route("/validateforgotpasswordcode", "POST")
export class PostValidateForgotPasswordCode extends BaseStatelessRequest implements IReturn<PostValidateForgotPasswordCodeResponse>
{
    public mobile: string;
    public code: number;

    public constructor(init?: Partial<PostValidateForgotPasswordCode>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostValidateForgotPasswordCodeResponse(); }
    public getTypeName() { return 'PostValidateForgotPasswordCode'; }
}

// @Route("/resetpasswordusingcode", "POST")
export class PostUserResetPasswordWithCode extends BaseStatelessRequest implements IReturn<PostUserResetPasswordWithCodeResponse>
{
    public mobile: string;
    public code: number;
    public newPassword: string;
    public newPasswordConfirm: string;

    public constructor(init?: Partial<PostUserResetPasswordWithCode>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostUserResetPasswordWithCodeResponse(); }
    public getTypeName() { return 'PostUserResetPasswordWithCode'; }
}

// @Route("/admin/users")
export class PostAdminUsers extends BaseStatelessRequest implements IReturn<PostAdminUsersResponse>
{
    public pageSize: number;
    public page: number;
    public filter: UserFilter;

    public constructor(init?: Partial<PostAdminUsers>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostAdminUsersResponse(); }
    public getTypeName() { return 'PostAdminUsers'; }
}

// @Route("/userpushtest", "POST")
export class PostUserPushTest extends BaseStatelessRequest implements IReturn<PostUserPushTestResponse>
{
    public userId: number;
    public incidentID: number;

    public constructor(init?: Partial<PostUserPushTest>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostUserPushTestResponse(); }
    public getTypeName() { return 'PostUserPushTest'; }
}

// @Route("/user/deletion", "POST")
export class PostUserDelete extends BaseStatelessRequest implements IReturn<PostUserDeleteResponse>
{
    public reconfirmedEmail: string;

    public constructor(init?: Partial<PostUserDelete>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostUserDeleteResponse(); }
    public getTypeName() { return 'PostUserDelete'; }
}

// @Route("/userresendsmsverification", "POST")
export class PostUserResendVerificationSms extends BaseStatelessRequest implements IReturn<PostUserResendVerificationSmsResponse>
{

    public constructor(init?: Partial<PostUserResendVerificationSms>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostUserResendVerificationSmsResponse(); }
    public getTypeName() { return 'PostUserResendVerificationSms'; }
}

// @Route("/validatesmscode", "POST")
export class PostValidateSmsCode extends BaseStatelessRequest implements IReturn<PostValidateSmsCodeResponse>
{
    public code: string;

    public constructor(init?: Partial<PostValidateSmsCode>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostValidateSmsCodeResponse(); }
    public getTypeName() { return 'PostValidateSmsCode'; }
}

// @Route("/user/session", "GET")
export class GetUserSession implements IReturn<GetUserSessionResponse>, IGet
{

    public constructor(init?: Partial<GetUserSession>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetUserSessionResponse(); }
    public getTypeName() { return 'GetUserSession'; }
}

// @Route("/user/details", "GET")
export class GetUserDetailSession extends BaseStatelessRequest implements IReturn<GetUserDetailSessionResponse>
{
    public latitude: number;
    public longitude: number;
    public locationFound: boolean;
    public impersonateUserId: number;

    public constructor(init?: Partial<GetUserDetailSession>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetUserDetailSessionResponse(); }
    public getTypeName() { return 'GetUserDetailSession'; }
}

// @Route("/home/userstatistics", "GET")
export class GetUserStatistics extends BaseStatelessRequest implements IReturn<GetUserStatisticsResponse>
{

    public constructor(init?: Partial<GetUserStatistics>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetUserStatisticsResponse(); }
    public getTypeName() { return 'GetUserStatistics'; }
}

// @Route("/user/{UserId}", "GET")
export class UserDetails extends BaseStatelessRequest implements IReturn<UserDetailsResponse>, IGet
{
    public userId: number;

    public constructor(init?: Partial<UserDetails>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new UserDetailsResponse(); }
    public getTypeName() { return 'UserDetails'; }
}

// @Route("/user/venues")
export class UserVenues extends BaseStatelessRequest implements IReturn<UserVenuesResponse>
{

    public constructor(init?: Partial<UserVenues>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new UserVenuesResponse(); }
    public getTypeName() { return 'UserVenues'; }
}

// @Route("/user/venues/location")
export class UserVenuesWithLocation extends BaseStatelessRequest implements IReturn<UserVenuesWithLocationResponse>
{
    public latitude: number;
    public longitude: number;

    public constructor(init?: Partial<UserVenuesWithLocation>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new UserVenuesWithLocationResponse(); }
    public getTypeName() { return 'UserVenuesWithLocation'; }
}

// @Route("/user/securityfirms")
export class UserSecurityFirms extends BaseStatelessRequest implements IReturn<UserSecurityFirmsResponse>
{

    public constructor(init?: Partial<UserSecurityFirms>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new UserSecurityFirmsResponse(); }
    public getTypeName() { return 'UserSecurityFirms'; }
}

// @Route("/user/venuesecurityfirms")
export class UserVenueSecurityFirms extends BaseStatelessRequest implements IReturn<UserVenueSecurityFirmsResponse>
{

    public constructor(init?: Partial<UserVenueSecurityFirms>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new UserVenueSecurityFirmsResponse(); }
    public getTypeName() { return 'UserVenueSecurityFirms'; }
}

// @Route("/user/logout", "POST")
export class UserLogout extends BaseStatelessRequest implements IReturn<UserLogoutResponse>, IPost
{
    public userId: number;

    public constructor(init?: Partial<UserLogout>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new UserLogoutResponse(); }
    public getTypeName() { return 'UserLogout'; }
}

// @Route("/venuereportingperiod/currentdetails", "POST")
export class GetVenueReportingPeriodCurrentDetails extends BaseStatelessRequest implements IReturn<GetVenueReportingPeriodCurrentDetailsResponse>
{
    public forVenueId: number;

    public constructor(init?: Partial<GetVenueReportingPeriodCurrentDetails>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetVenueReportingPeriodCurrentDetailsResponse(); }
    public getTypeName() { return 'GetVenueReportingPeriodCurrentDetails'; }
}

// @Route("/venuereportingperiod/close", "POST")
export class PostVenueReportingPeriodClose extends BaseStatelessRequest implements IReturn<PostVenueReportingPeriodCloseResponse>
{
    public venueReportingPeriodId: number;

    public constructor(init?: Partial<PostVenueReportingPeriodClose>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostVenueReportingPeriodCloseResponse(); }
    public getTypeName() { return 'PostVenueReportingPeriodClose'; }
}

// @Route("/venuereportingperiods/create", "POST")
export class PostVenueReportingPeriodCreate extends BaseStatelessRequest implements IReturn<PostVenueReportingPeriodCreateResponse>
{
    public forVenueId: number;

    public constructor(init?: Partial<PostVenueReportingPeriodCreate>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostVenueReportingPeriodCreateResponse(); }
    public getTypeName() { return 'PostVenueReportingPeriodCreate'; }
}

// @Route("/venuereportingperiods/{VenueReportingPeriodId}", "GET")
export class GetVenueReportingPeriod extends BaseStatelessRequest implements IReturn<GetVenueReportingPeriodResponse>
{
    public venueReportingPeriodId: number;

    public constructor(init?: Partial<GetVenueReportingPeriod>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetVenueReportingPeriodResponse(); }
    public getTypeName() { return 'GetVenueReportingPeriod'; }
}

// @Route("/venuereportingperiods/upsert", "POST")
export class PostVenueReportingPeriodUpsert extends BaseStatelessRequest implements IReturn<PostVenueReportingPeriodUpsertResponse>
{
    public venueReportingPeriod: VenueReportingPeriod;

    public constructor(init?: Partial<PostVenueReportingPeriodUpsert>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostVenueReportingPeriodUpsertResponse(); }
    public getTypeName() { return 'PostVenueReportingPeriodUpsert'; }
}

// @Route("/venuereportingperiods/search", "POST")
export class PostVenueReportingPeriod extends BaseStatelessRequest implements IReturn<PostVenueReportingPeriodResponse>
{
    public forVenueId: number;
    public pageSize: number;
    public page: number;

    public constructor(init?: Partial<PostVenueReportingPeriod>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostVenueReportingPeriodResponse(); }
    public getTypeName() { return 'PostVenueReportingPeriod'; }
}

// @Route("/venuereportingperiod/start", "POST")
export class GetVenueReportingPeriodSignOnStart extends BaseStatelessRequest implements IReturn<GetVenueReportingPeriodSignOnStartResponse>
{
    public latitude: number;
    public longitude: number;
    public locationFound: boolean;

    public constructor(init?: Partial<GetVenueReportingPeriodSignOnStart>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetVenueReportingPeriodSignOnStartResponse(); }
    public getTypeName() { return 'GetVenueReportingPeriodSignOnStart'; }
}

// @Route("/venuereportingperiod/usersignonstatus", "GET")
export class GetVenueReportingPeriodUserSignOnStatus extends BaseStatelessRequest implements IReturn<GetVenueReportingPeriodUserSignOnStatusResponse>
{
    public validateLocation: boolean;
    public includeIncidents: boolean;
    public latitude: number;
    public longitude: number;
    public locationFound: boolean;
    public signOnRegisterId: number;

    public constructor(init?: Partial<GetVenueReportingPeriodUserSignOnStatus>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetVenueReportingPeriodUserSignOnStatusResponse(); }
    public getTypeName() { return 'GetVenueReportingPeriodUserSignOnStatus'; }
}

// @Route("/venuereportingperiod/closed", "GET")
export class GetVenueReportingPeriodsClosed implements IReturn<GetVenueReportingPeriodsClosedResponse>
{

    public constructor(init?: Partial<GetVenueReportingPeriodsClosed>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetVenueReportingPeriodsClosedResponse(); }
    public getTypeName() { return 'GetVenueReportingPeriodsClosed'; }
}

// @Route("/venuereportingperiod/checkdate", "POST")
export class PostVenueReportingPeriodCheckDate extends BaseStatelessRequest implements IReturn<PostVenueReportingPeriodCheckDateResponse>
{
    public incidentDate: IncidentDate;

    public constructor(init?: Partial<PostVenueReportingPeriodCheckDate>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostVenueReportingPeriodCheckDateResponse(); }
    public getTypeName() { return 'PostVenueReportingPeriodCheckDate'; }
}

// @Route("/venuesecurityfirm/securityfirmassociations", "GET")
export class GetSecurityFirmAssociations extends BaseStatelessRequest implements IReturn<GetSecurityFirmAssociationsResponse>, IGet
{

    public constructor(init?: Partial<GetSecurityFirmAssociations>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetSecurityFirmAssociationsResponse(); }
    public getTypeName() { return 'GetSecurityFirmAssociations'; }
}

// @Route("/venuesecurityfirm/validatecombination")
export class GetValidUserVenueSecurityFirmValid implements IReturn<GetValidUserVenueSecurityFirmValidResponse>
{
    public userId: number;
    public venueId: number;
    public securityFirmId: number;

    public constructor(init?: Partial<GetValidUserVenueSecurityFirmValid>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetValidUserVenueSecurityFirmValidResponse(); }
    public getTypeName() { return 'GetValidUserVenueSecurityFirmValid'; }
}

// @Route("/venuesecurityfirm/summary")
export class PostVenueSecurityFirmSummary extends BaseStatelessRequest implements IReturn<PostVenueSecurityFirmSummaryResponse>, IHasForVenueSecurityFirm
{
    public forVenueId: number;
    public forSecurityFirmId: number;
    public forVenueEventId: number;
    public pageSize: number;
    public page: number;
    public showObsolete: boolean;

    public constructor(init?: Partial<PostVenueSecurityFirmSummary>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostVenueSecurityFirmSummaryResponse(); }
    public getTypeName() { return 'PostVenueSecurityFirmSummary'; }
}

// @Route("/venuesecurityfirm/venuesummary")
export class PostSecurityFirmVenueSummary extends BaseStatelessRequest implements IReturn<PostSecurityFirmVenueSummaryResponse>, IHasForVenueSecurityFirm
{
    public forSecurityFirmId: number;
    public forVenueId: number;
    public forVenueEventId: number;
    public pageSize: number;
    public page: number;

    public constructor(init?: Partial<PostSecurityFirmVenueSummary>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostSecurityFirmVenueSummaryResponse(); }
    public getTypeName() { return 'PostSecurityFirmVenueSummary'; }
}

// @Route("/venuesecurityfirm/create", "POST")
export class PostVenueSecurityFirmCreate extends BaseStatelessRequest implements IReturn<PostVenueSecurityFirmCreateResponse>, IHasForVenueSecurityFirm
{
    public forVenueId: number;
    public forSecurityFirmId: number;
    public forVenueEventId: number;

    public constructor(init?: Partial<PostVenueSecurityFirmCreate>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostVenueSecurityFirmCreateResponse(); }
    public getTypeName() { return 'PostVenueSecurityFirmCreate'; }
}

// @Route("/venuesecurityfirm", "POST")
export class PostVenueSecurityFirm extends BaseStatelessRequest implements IReturn<PostVenueSecurityFirmResponse>
{
    public venueSecurityFirm: VenueSecurityFirm;

    public constructor(init?: Partial<PostVenueSecurityFirm>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostVenueSecurityFirmResponse(); }
    public getTypeName() { return 'PostVenueSecurityFirm'; }
}

// @Route("/venuesecurityfirm/{VenueSecurityFirmId}", "DELETE")
export class DeleteVenueSecurityFirm extends BaseStatelessRequest implements IReturn<DeleteVenueSecurityFirmResponse>
{
    public venueSecurityFirmId: number;

    public constructor(init?: Partial<DeleteVenueSecurityFirm>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new DeleteVenueSecurityFirmResponse(); }
    public getTypeName() { return 'DeleteVenueSecurityFirm'; }
}

// @Route("/venuesecurityfirm/{VenueSecurityFirmId}/restore", "POST")
export class PostVenueSecurityFirmRestore extends BaseStatelessRequest implements IReturn<PostVenueSecurityFirmRestoreResponse>
{
    public venueSecurityFirmId: number;

    public constructor(init?: Partial<PostVenueSecurityFirmRestore>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostVenueSecurityFirmRestoreResponse(); }
    public getTypeName() { return 'PostVenueSecurityFirmRestore'; }
}

// @Route("/newsecurityfirm", "GET")
export class GetNewSecurityFirm extends BaseStatelessRequest implements IReturn<GetNewSecurityFirmResponse>
{

    public constructor(init?: Partial<GetNewSecurityFirm>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetNewSecurityFirmResponse(); }
    public getTypeName() { return 'GetNewSecurityFirm'; }
}

// @Route("/newsecurityfirm", "POST")
export class PostNewSecurityFirm extends BaseStatelessRequest implements IReturn<PostNewSecurityFirmResponse>
{
    public securityFirm: SecurityFirm;
    public managerContact: SecurityFirmContact;
    public primaryContact: SecurityFirmContact;
    public otherDocuments: File[];
    public complianceDocuments: File[];

    public constructor(init?: Partial<PostNewSecurityFirm>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostNewSecurityFirmResponse(); }
    public getTypeName() { return 'PostNewSecurityFirm'; }
}

// @Route("/admin/securityfirms/dismiss")
export class PostAdminSecurityFirmDismiss extends BaseStatelessRequest implements IReturn<PostAdminSecurityFirmDismissResponse>
{
    public forSecurityFirmId: number;

    public constructor(init?: Partial<PostAdminSecurityFirmDismiss>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostAdminSecurityFirmDismissResponse(); }
    public getTypeName() { return 'PostAdminSecurityFirmDismiss'; }
}

// @Route("/admin/securityfirms")
export class PostAdminSecurityFirms extends BaseStatelessRequest implements IReturn<PostAdminSecurityFirmsResponse>
{
    public pageSize: number;
    public page: number;
    public filter: SecurityFirmFilter;

    public constructor(init?: Partial<PostAdminSecurityFirms>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostAdminSecurityFirmsResponse(); }
    public getTypeName() { return 'PostAdminSecurityFirms'; }
}

// @Route("/securityfirm/{ForSecurityFirmId}")
export class GetSecurityFirm extends BaseStatelessRequest implements IReturn<GetSecurityFirmResponse>
{
    public forSecurityFirmId: number;
    public includeOptions: boolean;

    public constructor(init?: Partial<GetSecurityFirm>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetSecurityFirmResponse(); }
    public getTypeName() { return 'GetSecurityFirm'; }
}

// @Route("/securityfirmsledcheck", "POST")
export class PostSecurityFirmSledCheck extends BaseStatelessRequest implements IReturn<PostSecurityFirmSledCheckResponse>
{
    public securityFirm: SecurityFirm;

    public constructor(init?: Partial<PostSecurityFirmSledCheck>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostSecurityFirmSledCheckResponse(); }
    public getTypeName() { return 'PostSecurityFirmSledCheck'; }
}

// @Route("/securityfirm", "POST")
export class PostSecurityFirm extends BaseStatelessRequest implements IReturn<PostSecurityFirmResponse>
{
    public securityFirm: SecurityFirm;

    public constructor(init?: Partial<PostSecurityFirm>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostSecurityFirmResponse(); }
    public getTypeName() { return 'PostSecurityFirm'; }
}

// @Route("/securityfirms/validforuser", "GET")
export class GetSecurityFirmsValidForUser implements IReturn<GetSecurityFirmsValidForUserResponse>
{
    public userId: number;
    public venueId: number;

    public constructor(init?: Partial<GetSecurityFirmsValidForUser>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetSecurityFirmsValidForUserResponse(); }
    public getTypeName() { return 'GetSecurityFirmsValidForUser'; }
}

// @Route("/securityfirms/availableforuserrolerequest", "GET")
export class GetSecurityFirmsAvailableForUserRoleRequest extends BaseStatelessRequest implements IReturn<GetSecurityFirmsAvailableForUserRoleRequestResponse>
{

    public constructor(init?: Partial<GetSecurityFirmsAvailableForUserRoleRequest>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetSecurityFirmsAvailableForUserRoleRequestResponse(); }
    public getTypeName() { return 'GetSecurityFirmsAvailableForUserRoleRequest'; }
}

// @Route("/securityfirms/{SecurityFirmToRemoveId}/obsolete", "POST")
export class PostSecurityFirmObsolete extends BaseStatelessRequest implements IReturn<PostSecurityFirmObsoleteResponse>
{
    public securityFirmToRemoveId: number;

    public constructor(init?: Partial<PostSecurityFirmObsolete>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostSecurityFirmObsoleteResponse(); }
    public getTypeName() { return 'PostSecurityFirmObsolete'; }
}

// @Route("/securityfirms/{SecurityFirmToDeleteId}", "DELETE")
export class DeleteSecurityFirm extends BaseStatelessRequest implements IReturn<DeleteSecurityFirmResponse>
{
    public securityFirmToDeleteId: number;

    public constructor(init?: Partial<DeleteSecurityFirm>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new DeleteSecurityFirmResponse(); }
    public getTypeName() { return 'DeleteSecurityFirm'; }
}

// @Route("/admin/securityfirms/export")
export class AnySecurityFirmsExport extends BaseStatelessRequest implements IReturn<AnySecurityFirmsExportResponse>
{
    public filter: SecurityFirmFilter;

    public constructor(init?: Partial<AnySecurityFirmsExport>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new AnySecurityFirmsExportResponse(); }
    public getTypeName() { return 'AnySecurityFirmsExport'; }
}

// @Route("/states/{StateId}")
export class GetState implements IReturn<GetStateResponse>
{
    public stateId: number;

    public constructor(init?: Partial<GetState>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetStateResponse(); }
    public getTypeName() { return 'GetState'; }
}

// @Route("/newvenue", "GET")
export class GetNewVenue extends BaseStatelessRequest implements IReturn<GetNewVenueResponse>
{

    public constructor(init?: Partial<GetNewVenue>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetNewVenueResponse(); }
    public getTypeName() { return 'GetNewVenue'; }
}

// @Route("/newvenue", "POST")
export class PostNewVenue extends BaseStatelessRequest implements IReturn<PostNewVenueResponse>
{
    public venue: Venue;
    public licenseContact: VenueContact;
    public venueContact: VenueContact;
    public otherDocuments: File[];
    public complianceDocuments: File[];

    public constructor(init?: Partial<PostNewVenue>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostNewVenueResponse(); }
    public getTypeName() { return 'PostNewVenue'; }
}

// @Route("/venues/{VenueId}/inrange")
export class GetVenueInRange implements IReturn<GetVenueInRangeResponse>
{
    public venueId: number;
    public latitude: number;
    public longitude: number;

    public constructor(init?: Partial<GetVenueInRange>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetVenueInRangeResponse(); }
    public getTypeName() { return 'GetVenueInRange'; }
}

// @Route("/venues/{ForVenueId}")
export class GetVenue extends BaseStatelessRequest implements IReturn<GetVenueResponse>
{
    public forVenueId: number;
    public includeOptions: boolean;

    public constructor(init?: Partial<GetVenue>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetVenueResponse(); }
    public getTypeName() { return 'GetVenue'; }
}

// @Route("/venue", "POST")
export class PostVenue extends BaseStatelessRequest implements IReturn<PostVenueResponse>
{
    public venue: Venue;

    public constructor(init?: Partial<PostVenue>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostVenueResponse(); }
    public getTypeName() { return 'PostVenue'; }
}

// @Route("/venues/")
export class GetVenues implements IReturn<GetVenuesResponse>
{

    public constructor(init?: Partial<GetVenues>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetVenuesResponse(); }
    public getTypeName() { return 'GetVenues'; }
}

// @Route("/venues/{VenueId}/personnel")
export class GetVenuePersonnel implements IReturn<GetVenuePersonnelResponse>
{
    public venueId: number;

    public constructor(init?: Partial<GetVenuePersonnel>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetVenuePersonnelResponse(); }
    public getTypeName() { return 'GetVenuePersonnel'; }
}

// @Route("/venues/{VenueId}/locations")
export class GetVenueLocations implements IReturn<GetVenueLocationsResponse>
{
    public venueId: number;

    public constructor(init?: Partial<GetVenueLocations>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetVenueLocationsResponse(); }
    public getTypeName() { return 'GetVenueLocations'; }
}

// @Route("/venues/{VenueId}/incidentflagtypes")
export class GetVenueIncidentFlagTypes implements IReturn<GetVenueIncidentFlagTypesResponse>
{
    public venueId: number;

    public constructor(init?: Partial<GetVenueIncidentFlagTypes>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetVenueIncidentFlagTypesResponse(); }
    public getTypeName() { return 'GetVenueIncidentFlagTypes'; }
}

// @Route("/venues/{VenueId}/incidentpersonnel")
export class GetVenueIncidentPersonnel implements IReturn<GetVenueIncidentPersonnelResponse>
{
    public venueId: number;
    public securityFirmId: number;
    public incidentDateTime: string;

    public constructor(init?: Partial<GetVenueIncidentPersonnel>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetVenueIncidentPersonnelResponse(); }
    public getTypeName() { return 'GetVenueIncidentPersonnel'; }
}

// @Route("/venues/validforuser", "GET")
export class GetVenuesValidForUser implements IReturn<GetVenuesValidForUserResponse>
{

    public constructor(init?: Partial<GetVenuesValidForUser>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetVenuesValidForUserResponse(); }
    public getTypeName() { return 'GetVenuesValidForUser'; }
}

// @Route("/venues/{VenueId}/login", "POST")
export class PostVenueLogin extends BaseStatelessRequest implements IReturn<PostVenueLoginResponse>
{
    public code: string;
    public deviceId: string;
    public os: string;
    public model: string;
    public make: string;
    public version: string;
    public appVersion: string;
    public locationFound: boolean;
    public latitude: number;
    public longitude: number;

    public constructor(init?: Partial<PostVenueLogin>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostVenueLoginResponse(); }
    public getTypeName() { return 'PostVenueLogin'; }
}

// @Route("/venues/levelfourusersreport", "GET")
export class GetVenueLevelFourUsersReport implements IReturn<GetVenueLevelFourUsersReportResponse>
{

    public constructor(init?: Partial<GetVenueLevelFourUsersReport>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetVenueLevelFourUsersReportResponse(); }
    public getTypeName() { return 'GetVenueLevelFourUsersReport'; }
}

// @Route("/venues/availableforuserrolerequest", "GET")
export class GetVenuesAvailableForUserRoleRequest extends BaseStatelessRequest implements IReturn<GetVenuesAvailableForUserRoleRequestResponse>
{

    public constructor(init?: Partial<GetVenuesAvailableForUserRoleRequest>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new GetVenuesAvailableForUserRoleRequestResponse(); }
    public getTypeName() { return 'GetVenuesAvailableForUserRoleRequest'; }
}

// @Route("/admin/venues")
export class PostAdminVenues extends BaseStatelessRequest implements IReturn<PostAdminVenuesResponse>
{
    public pageSize: number;
    public page: number;
    public filter: VenueFilter;

    public constructor(init?: Partial<PostAdminVenues>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostAdminVenuesResponse(); }
    public getTypeName() { return 'PostAdminVenues'; }
}

// @Route("/admin/venue/dismiss")
export class PostAdminVenueDismiss extends BaseStatelessRequest implements IReturn<PostAdminVenueDismissResponse>
{
    public forVenueId: number;

    public constructor(init?: Partial<PostAdminVenueDismiss>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostAdminVenueDismissResponse(); }
    public getTypeName() { return 'PostAdminVenueDismiss'; }
}

// @Route("/admin/venue/associations")
export class PostAdminVenueAssocations extends BaseStatelessRequest implements IReturn<PostAdminVenueAssocationsResponse>
{
    public forVenueId: number;

    public constructor(init?: Partial<PostAdminVenueAssocations>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostAdminVenueAssocationsResponse(); }
    public getTypeName() { return 'PostAdminVenueAssocations'; }
}

// @Route("/admin/venue/venuesecurityfirmobsolete")
export class AdminVenueSecurityFirmRemove extends BaseStatelessRequest implements IReturn<AdminVenueSecurityFirmRemoveResponse>
{
    public venueSecurityFirmId: number;

    public constructor(init?: Partial<AdminVenueSecurityFirmRemove>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new AdminVenueSecurityFirmRemoveResponse(); }
    public getTypeName() { return 'AdminVenueSecurityFirmRemove'; }
}

// @Route("/admin/venue/venuesecurityfirmdescendantobsolete")
export class AdminVenueSecurityFirmDescendantRemove extends BaseStatelessRequest implements IReturn<AdminVenueSecurityFirmDescendantRemoveResponse>
{
    public securityFirmDescendantId: number;

    public constructor(init?: Partial<AdminVenueSecurityFirmDescendantRemove>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new AdminVenueSecurityFirmDescendantRemoveResponse(); }
    public getTypeName() { return 'AdminVenueSecurityFirmDescendantRemove'; }
}

// @Route("/venues/{VenueToRemoveId}/obsolete", "POST")
export class PostVenueObsolete extends BaseStatelessRequest implements IReturn<PostVenueObsoleteResponse>
{
    public venueToRemoveId: number;

    public constructor(init?: Partial<PostVenueObsolete>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new PostVenueObsoleteResponse(); }
    public getTypeName() { return 'PostVenueObsolete'; }
}

// @Route("/venues/{VenueToDeleteId}", "DELETE")
export class DeleteVenue extends BaseStatelessRequest implements IReturn<DeleteVenueResponse>
{
    public venueToDeleteId: number;

    public constructor(init?: Partial<DeleteVenue>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new DeleteVenueResponse(); }
    public getTypeName() { return 'DeleteVenue'; }
}

// @Route("/admin/venues/export")
export class AnyVenuesExport extends BaseStatelessRequest implements IReturn<AnyVenuesExportResponse>
{
    public filter: VenueFilter;

    public constructor(init?: Partial<AnyVenuesExport>) { super(init); (Object as any).assign(this, init); }
    public createResponse() { return new AnyVenuesExportResponse(); }
    public getTypeName() { return 'AnyVenuesExport'; }
}

// @Route("/auth")
// @Route("/auth/{provider}")
// @Route("/authenticate")
// @Route("/authenticate/{provider}")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe: boolean;

    // @DataMember(Order=8)
    public continue: string;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=16)
    public useTokenCookie: boolean;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AuthenticateResponse(); }
    public getTypeName() { return 'Authenticate'; }
}

// @Route("/assignroles")
// @DataContract
export class AssignRoles implements IReturn<AssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AssignRoles>) { (Object as any).assign(this, init); }
    public createResponse() { return new AssignRolesResponse(); }
    public getTypeName() { return 'AssignRoles'; }
}

// @Route("/unassignroles")
// @DataContract
export class UnAssignRoles implements IReturn<UnAssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UnAssignRoles>) { (Object as any).assign(this, init); }
    public createResponse() { return new UnAssignRolesResponse(); }
    public getTypeName() { return 'UnAssignRoles'; }
}

// @Route("/session-to-token")
// @DataContract
export class ConvertSessionToToken implements IReturn<ConvertSessionToTokenResponse>, IPost
{
    // @DataMember(Order=1)
    public preserveSession: boolean;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ConvertSessionToToken>) { (Object as any).assign(this, init); }
    public createResponse() { return new ConvertSessionToTokenResponse(); }
    public getTypeName() { return 'ConvertSessionToToken'; }
}

// @Route("/access-token")
// @DataContract
export class GetAccessToken implements IReturn<GetAccessTokenResponse>, IPost
{
    // @DataMember(Order=1)
    public refreshToken: string;

    // @DataMember(Order=2)
    public useTokenCookie: boolean;

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<GetAccessToken>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetAccessTokenResponse(); }
    public getTypeName() { return 'GetAccessToken'; }
}

