import { connect } from 'react-redux'
import IncidentVersion from '../components/incidents/versions/IncidentVersion';
import { displayIncidentVersionLoad } from '../common/actions/displayIncident';
import { withRouter } from "react-router";
import { permissionsLogic } from '../common/logic/permissionsLogic';

const mapStateToProps = (state, props) => {
    return {
        incidentId: props.match.params.incidentid,
        securityFirmId: props.match.params.securityfirmid,
        incidentRelevantId: props.match.params.incidentrelaventid,
        version: props.match.params.version,
        incident: state.displayIncident.incidentVersion,
        isLoading: state.displayIncident.isLoadingIncidentVersion,
        canViewUniqueIncidentID: permissionsLogic.hasPermissionForState(state,"IncidentUniqueIDView"),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoadVersion: (securityFirmId, incidentId, incidentRelaventId, version) => {
            dispatch(displayIncidentVersionLoad(securityFirmId, incidentId, incidentRelaventId, version))
        }
    }
}

const IncidentVersionContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(IncidentVersion))

export default IncidentVersionContainer