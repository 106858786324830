import update from "immutability-helper";
import * as AusComplyDtos from "../dto/AusComply.dtos";
import { incidentLogic } from '../../common/logic/incidentLogic';
import { checklistLogic } from '../../common/logic/checklistLogic';
import * as editIncidentActions from "../actions/editIncident";
import {
    INCIDENTS_RESET
} from "../actions/incidents";
import * as imageProcessingActions from "../actions/imageProcessing";
import * as incidentDocumentActions from "../actions/incidentDocument";

const initialState = {
    isLoading: false,
    incident: { incidentId: 0 },
    incidentOptions: {},
    isSaving: false,
    isGeneratingUniqueID: false,
    error: "",
    step: 1,
    hasChecklists: false,
    isUploading: false,
    isLoadingChecklists: false
};

function loading(state, isLoading) {
    let newState = update(state, {
        isLoading: { $set: isLoading }
    });
    return newState;
}

function uploading(state, isUploading) {
    let newState = update(state, {
        isUploading: { $set: isUploading }
    });
    return newState;
}

function loadingChecklists(state, isLoadingChecklists) {
    let newState = update(state, {
        isLoadingChecklists: { $set: isLoadingChecklists }
    });
    return newState;
}

function saving(state, isSaving) {
    let newState = update(state, {
        isSaving: { $set: isSaving }
    });
    return newState;
}

function setError(state, error) {
    let newState = update(state, {
        error: { $set: error }
    });
    return newState;
}

function setStep(state, step) {
    let newState = update(state, {
        step: { $set: step }
    });
    return newState;
}

function updateIncident(state, incident) {
    let newState = update(state, {
        incident: { $set: incident }
    });
    return setHasChecklists(newState);
}

function setIncident(state, incident, incidentOptions) {
    let newState = update(state, {
        incident: { $set: incident },
        incidentOptions: { $set: incidentOptions }
    });
    return setHasChecklists(newState);
}

function setIncidentOnly(state, incident) {
    let newState = update(state, {
        incident: { $set: incident }
    });
    return setHasChecklists(newState);
}

function setChecklists(state, incident) {
    var newIncident = { ...state.incident };
    newIncident.checklists = [...incident.checklists];
    let newState = update(state, {
        incident: { $set: newIncident }
    });
    return setHasChecklists(newState);
}

function setHasChecklists(state) {
    let newState = update(state, {
        hasChecklists: { $set: incidentLogic.hasChecklists(state.incident, state.incidentOptions) }
    });
    return newState;
}

function updateIncidentIsCurrentReportingPeriod(state, isCurrentReportingPeriod) {
    let newState = update(state, {
        incident: { isCurrentReportingPeriod: { $set: isCurrentReportingPeriod } }
    });
    return newState;
}

function filesUploaded(state, temporaryFiles: AusComplyDtos.File[]) {
    let incident = { ...state.incident };
    temporaryFiles.forEach(item => {
        let document = new AusComplyDtos.IncidentDocument();
        document.obsolete = false;
        document.originalFilename = item.originalName;
        document.isImage = item.isImage;
        document.incidentId = incident.incidentId;
        document.canvasRescaled = false;
        document.filePath = item.path;
        document.filePreviewPath = item.filePreviewPath;
        document.fileDisplayPath = item.fileDisplayPath;
        document.extension = item.extension;
        document.incidentDocumentId = 0;
        document.filePreviewText = item.filePreviewText;
        document.hasChanged = true;
        document.canvasRescaled = item.canvasRescaled;
        document.storageFileName = item.storageFileName;
        incident.incidentDocuments.push(document);
    });
    let newState = update(state, {
        incident: { $set: incident }
    });
    return newState;
}

function patronFilesUploaded(state, patronIndex: number, temporaryFiles: AusComplyDtos.File[]) {
    let incident = { ...state.incident };
    if (incident.incidentPatrons && incident.incidentPatrons.length > patronIndex) {
        if (incident.incidentPatrons[patronIndex].incidentPatronPhotos == null) {
            incident.incidentPatrons[patronIndex].incidentPatronPhotos = [];
        }
        temporaryFiles.forEach(item => {
            let photo = new AusComplyDtos.IncidentPatronPhoto();
            photo.obsolete = false;
            photo.originalFileName = item.originalName;
            photo.isImage = item.isImage;
            photo.incidentPatronId = incident.incidentPatrons[patronIndex].incidentPatronId;
            photo.canvasRescaled = false;
            photo.filePath = item.path;
            photo.filePreviewPath = item.filePreviewPath;
            photo.fileDisplayPath = item.fileDisplayPath;
            photo.extension = item.extension;
            photo.incidentPatronPhotoId = 0;
            photo.filePreviewText = item.filePreviewText;
            photo.hasChanged = true;
            photo.canvasRescaled = item.canvasRescaled;
            photo.storageFileName = item.storageFileName;
            incident.incidentPatrons[patronIndex].incidentPatronPhotos.push(photo);
        });
    }
    let newState = update(state, {
        incident: { $set: incident }
    });
    return newState;
}


function filesAttachmentUploaded(state, temporaryFiles: AusComplyDtos.File[], checklistTemplateItemId) {
    let incident = { ...state.incident };
    if (incident.checklists != null) {
        incident.checklists.forEach(checklist => {
            checklist.checklistItems.forEach((item, index) => {
                if (item.checklistTemplateItemId == checklistTemplateItemId) {
                    temporaryFiles.forEach(file => {
                        if (!checklist.checklistItems[index].files) {
                            checklist.checklistItems[index].files = [];
                        }
                        checklist.checklistItems[index].files.push(file);
                    });
                }
            });
            checklist.checkListDisplayItems.forEach((item, index) => {
                if (item.template.checklistTemplateItemId == checklistTemplateItemId) {
                    temporaryFiles.forEach(file => {
                        if (!checklist.checkListDisplayItems[index].item.files) {
                            checklist.checkListDisplayItems[index].item.files = [];
                        }
                        checklist.checkListDisplayItems[index].item.files.push(file);
                        checklist.checkListDisplayItems[index].isRequired = checklistLogic.validateChecklistDisplayItemIsRequired(checklist.checkListDisplayItems[index]);
                    });
                }
            });
            checklist.checkListDisplayItems = checklistLogic.validateChecklistDisplayItemsShown(checklist.checkListDisplayItems);
            checklist.checklistItems = [...checklist.checklistItems];
            checklist.checkListDisplayItems = [...checklist.checkListDisplayItems];
        });
    }

    let newState = update(state, {
        incident: { $set: incident }
    });
    return newState;
}

function incidentTemporaryFileUpdated(state, imageText, filePath) {
    let incident = { ...state.incident };
    if (incident.incidentDocuments) {
        for (let index = 0; index < incident.incidentDocuments.length; index++) {
            if (filePath.indexOf(incident.incidentDocuments[index].fileDisplayPath) > -1) {
                incident.incidentDocuments[index].imageText = imageText;
                incident.incidentDocuments[index].hasChanged = true;
            }
        }
    }
    let newState = update(state, {
        incident: { $set: incident }
    });
    return newState;
}

function incidentDocumentUpdated(state, imageText, incidentDocumentId, savedToDocument) {
    let incident = { ...state.incident };
    if (incident.incidentDocuments) {
        for (let index = 0; index < incident.incidentDocuments.length; index++) {
            if (incident.incidentDocuments[index].incidentDocumentId == incidentDocumentId) {
                incident.incidentDocuments[index].imageText = imageText;
                if (!savedToDocument) {
                    incident.incidentDocuments[index].hasChanged = true;
                }
            }
        }
    }
    let newState = update(state, {
        incident: { $set: incident }
    });
    return newState;
}

function addIncidentDocument(state, incidentDocument: AusComplyDtos.IncidentDocument) {
    let incident = { ...state.incident };
    if (incident && incidentDocument && incident.incidentDocuments && incident.incidentId === incidentDocument.incidentId) {
        incident.incidentDocuments = [...incident.incidentDocuments, incidentDocument];
    }
    let newState = update(state, {
        incident: { $set: incident }
    });
    return newState;
}

function generatingUniqueID(state, isGeneratingUniqueID) {
    let newState = update(state, {
        isGeneratingUniqueID: { $set: isGeneratingUniqueID }
    });
    return newState;
}

export default function editIncident(state = initialState, action) {
    switch (action.type) {
        case INCIDENTS_RESET:
            return { ...initialState };
        case editIncidentActions.EDIT_INCIDENT_LOAD_REQUEST:
            return loading(setIncident(uploading(state, false), { incidentId: 0 }, {}), true);
        case editIncidentActions.EDIT_INCIDENT_LOAD_REQUEST_SUCCESS:
            return loading(setIncident(setError(setStep(state, 1), ""), action.incident, action.incidentOptions), false);
        case editIncidentActions.EDIT_INCIDENT_LOAD_REQUEST_FAILURE:
            return loading(state, false);
        case editIncidentActions.EDIT_INCIDENT_CLEAR:
            return setIncident(saving(loading(setStep(uploading(state, false), 1), false), false), { incidentId: 0 }, {});
        case editIncidentActions.EDIT_INCIDENT_UPDATE:
            return updateIncident(state, action.incident);
        case editIncidentActions.EDIT_INCIDENT_SAVE_REQUEST:
            return saving(setError(state, ""), true);
        case editIncidentActions.EDIT_INCIDENT_SAVE_REQUEST_SUCCESS:
            return saving(updateIncident(setStep(state, 1), action.incident), false);
        case editIncidentActions.EDIT_INCIDENT_SAVE_REQUEST_FAILURE:
            return saving(setError(state, action.error), false);
        case editIncidentActions.EDIT_INCIDENT_CLEAR_ERROR:
            return setError(state, "");
        case editIncidentActions.EDIT_INCIDENT_CHECK_REPORTING_PERIOD_DATE_REQUEST_SUCCESS:
            return updateIncidentIsCurrentReportingPeriod(state, action.isCurrentReportingPeriod);
        case editIncidentActions.EDIT_INCIDENT_SET_STEP:
            return setStep(state, action.step);
        case editIncidentActions.EDIT_INCIDENT_UPLOAD_REQUEST:
            return uploading(state, true);
        case editIncidentActions.EDIT_INCIDENT_UPLOAD_REQUEST_SUCCESS:
            return filesUploaded(uploading(state, false), action.temporaryFiles);
        case editIncidentActions.EDIT_INCIDENT_UPLOAD_REQUEST_FAILURE:
            return uploading(state, false);
        case editIncidentActions.EDIT_INCIDENT_PATRON_UPLOAD_REQUEST:
            return uploading(state, true);
        case editIncidentActions.EDIT_INCIDENT_PATRON_UPLOAD_REQUEST_SUCCESS:
            return patronFilesUploaded(uploading(state, false), action.patronIndex, action.temporaryFiles);
        case editIncidentActions.EDIT_INCIDENT_PATRON_UPLOAD_REQUEST_FAILURE:
            return uploading(state, false);
        case editIncidentActions.EDIT_INCIDENT_CHECKLIST_ITEM_UPLOAD_REQUEST:
            return uploading(state, true);
        case editIncidentActions.EDIT_INCIDENT_CHECKLIST_ITEM_UPLOAD_REQUEST_SUCCESS:
            return filesAttachmentUploaded(uploading(state, false), action.temporaryFiles, action.checklistTemplateItemId);
        case editIncidentActions.EDIT_INCIDENT_CHECKLIST_ITEM_UPLOAD_REQUEST_FAILURE:
            return uploading(state, false);
        case editIncidentActions.EDIT_INCIDENT_CHECKLISTS_REQUEST:
            return loadingChecklists(state, true);
        case editIncidentActions.EDIT_INCIDENT_CHECKLISTS_REQUEST_SUCCESS:
            return setChecklists(loadingChecklists(state, false), action.incident);
        case editIncidentActions.EDIT_INCIDENT_CHECKLISTS_REQUEST_FAILURE:
            return loadingChecklists(state, false);
        case imageProcessingActions.IMAGE_PROCESSING_INCIDENT_DOCUMENT_REQUEST_SUCCESS:
            return incidentDocumentUpdated(state, action.imageText, action.incidentDocumentId, action.savedToDocument);
        case imageProcessingActions.IMAGE_PROCESSING_TEMPORARY_FILE_REQUEST_SUCCESS:
            return incidentTemporaryFileUpdated(state, action.imageText, action.filePath);
        case incidentDocumentActions.INCIDENT_DOCUMENT_BUILD_REQUEST_SUCCESS:
            return addIncidentDocument(state, action.incidentDocument);

        case editIncidentActions.EDIT_INCIDENT_GENERATE_UNIQUEID_REQUEST:
            return generatingUniqueID(state, true);
        case editIncidentActions.EDIT_INCIDENT_GENERATE_UNIQUEID_REQUEST_SUCCESS:
            return generatingUniqueID(setIncidentOnly(state, action.incident), false);
        case editIncidentActions.EDIT_INCIDENT_GENERATE_UNIQUEID_REQUEST_FAILURE:
            return generatingUniqueID(state, false);
    }
    return state;
}