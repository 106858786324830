import { connect } from 'react-redux'
import DraftIncident from '../components/incidents/DraftIncident'
import * as draftIncidentActions from '../common/actions/draftIncident'
import { locationRequest } from '../common/actions/location';
import { incidentsFilterRequest } from '../common/actions/incidents';
import { notificationShow } from '../common/actions/notification';
import { incidentLogic } from '../common/logic/incidentLogic';
import { permissionsLogic } from '../common/logic/permissionsLogic';

const mapStateToProps = (state, props) => {
    return {
        incidentTypeId: props.match.params.incidenttypeid,
        incident: state.draftIncident.incident,
        incidentOptions: state.draftIncidentOptions.incidentOptions,
        hasDraftIncident: state.draftIncident.hasDraftIncident,
        isLoading: state.draftIncident.isLoading || state.draftIncidentOptions.isLoading,
        isSaving: state.draftIncident.isSaving,
        error: state.draftIncident.error,
        isLocationLoading: state.location.isLoading,
        latitude: state.location.latitude,
        longitude: state.location.longitude,
        locationFound: state.location.found,
        locationError: state.location.error,
        step: state.draftIncident.step,
        isUploading: state.draftIncident.isUploading,
        canViewUniqueIncidentID: permissionsLogic.hasPermissionForState(state, "IncidentUniqueIDView"),
        hasChecklists: incidentLogic.hasChecklists(state.draftIncident.incident, state.draftIncidentOptions.incidentOptions),
        isLoadingChecklists: state.draftIncident.isLoadingChecklists
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCreateIncident: (incidentTypeId) => {
            dispatch(draftIncidentActions.draftIncidentCreate(incidentTypeId, ""))
        },
        onLoadIncidentOptions: () => {
            dispatch(draftIncidentActions.draftIncidentOptionsRequest(0, ""))
        },
        onClear: () => {
            dispatch(draftIncidentActions.draftIncidentClear())
        },
        onUpdateIncident: (incident) => {
            dispatch(draftIncidentActions.draftIncidentUpdate(incident))
        },
        onClearError: () => {
            dispatch(draftIncidentActions.draftIncidentClearError())
        },
        onSaveDraft: () => {
            dispatch(draftIncidentActions.draftIncidentSave(true))
        },
        onSaveFinal: () => {
            dispatch(draftIncidentActions.draftIncidentSave(false))
        },
        onCheckIncidentDateReportingPeriod: (incidentDate) => {
            dispatch(draftIncidentActions.draftIncidentCheckReportingPeriodDate(incidentDate))
        },
        onRefreshLocation: () => {
            dispatch(locationRequest())
        },
        onErrorNotification: (message) => {
            dispatch(notificationShow(message));
        },
        onSetStep: (step) => {
            dispatch(draftIncidentActions.draftIncidentSetStep(step));
        },
        onResetFilter: () => {
            dispatch(incidentsFilterRequest(true, ""))
        },
        onUploadFiles: (files, storageFolder) => {
            dispatch(draftIncidentActions.draftIncidentUploadRequest(files, storageFolder))
        },
        onUploadPatronFiles: (patronIndex, files, storageFolder) => {
            dispatch(draftIncidentActions.draftIncidentPatronUploadRequest(patronIndex, files, storageFolder))
        },
        onUploadAttachment: (files, checklistTemplateItemId, storageFolder) => {
            dispatch(draftIncidentActions.draftIncidentChecklistItemUploadRequest(files, checklistTemplateItemId, storageFolder))
        },
        onLoadChecklists: () => {
            dispatch(draftIncidentActions.draftIncidentChecklistsRequest())
        }
    }
}

const DraftIncidentContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(DraftIncident)

export default DraftIncidentContainer