import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import { Incident as IncidentDTO, IncidentOptions as IncidentOptionsDTO } from "../../../common/dto/AusComply.dtos";
import { IncidentPatron as IncidentPatronDTO } from "../../../common/dto/AusComply.dtos";
import { incidentLogic } from '../../../common/logic/incidentLogic';
import Grid from '@material-ui/core/Grid';
import Error from '../../common/Error';
import IncidentWizardPerson from './IncidentWizardPerson';
import IncidentWizardPeopleGroup from './IncidentWizardPeopleGroup';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import FullPageDialog from '../../layout/FullPageDialog';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DefaultButton from '../../controls/DefaultButton';
import TransparentButton from '../../controls/TransparentButton';
import SwipeContainer from '../../layout/SwipeContainer';
import Box from '@material-ui/core/Box';
import GroupAddIcon from '../../../resources/GroupAddIcon';
import PersonAddIcon from '../../../resources/PersonAddIcon';
import ButtonLink from '../../controls/ButtonLink';
import People from '../../common/People';
import { commonLogic } from '../../../common/logic/common';

export interface IIncidentWizardPeopleAndGroupsProps {
    incident: IncidentDTO;
    incidentOptions: IncidentOptionsDTO;
    onUpdateIncident: Function;
    onUploadPatronFiles: Function;
    isUploading: boolean;
    theme: any;
    classes: any;
    onSwipedRight?: Function;
    onSwipedLeft?: Function;
}

interface IIncidentWizardPeopleAndGroupsState {
    patrons: IncidentPatronDTO[],
    requiresPerson: boolean;
    contactTracing: boolean;
    hasGroupDetails: boolean;
    showGroup: boolean;
    patronIndex: number;
    confirmRemovePersonIndex: number;
    confirmRemoveGroup: boolean;
}

class IncidentWizardPeopleAndGroups extends Component<IIncidentWizardPeopleAndGroupsProps, IIncidentWizardPeopleAndGroupsState> {
    constructor(props: IIncidentWizardPeopleAndGroupsProps) {
        super(props)
        this.state = {
            patrons: [],
            requiresPerson: false,
            hasGroupDetails: false,
            showGroup: false,
            patronIndex: -1,
            confirmRemovePersonIndex: -1,
            confirmRemoveGroup: false,
            contactTracing: false
        }

        this.onPatronUpdated = this.onPatronUpdated.bind(this);
        this.onAddPatron = this.onAddPatron.bind(this);
        this.onUpdatePatrons = this.onUpdatePatrons.bind(this);
        this.onAddGroupDetails = this.onAddGroupDetails.bind(this);
        this.onRemoveGroupDetails = this.onRemoveGroupDetails.bind(this);
        this.onShowGroupDetails = this.onShowGroupDetails.bind(this);
        this.onHideGroupDetails = this.onHideGroupDetails.bind(this);
        this.onShowPatron = this.onShowPatron.bind(this);
        this.onRemovePerson = this.onRemovePerson.bind(this);
        this.onHidePerson = this.onHidePerson.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.handleNotConfirm = this.handleNotConfirm.bind(this);
        this.onRemovePersonRequest = this.onRemovePersonRequest.bind(this);
        this.onRemoveGroupRequest = this.onRemoveGroupRequest.bind(this);
        this.onShowPreviousPatron = this.onShowPreviousPatron.bind(this);
        this.onShowNextPatron = this.onShowNextPatron.bind(this);
        this.onUploadPatronFiles = this.onUploadPatronFiles.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
    }

    componentDidMount() {
        try {
            let requiresPerson = false;
            let hasGroupDetails = false;
            let contactTracing = false;
            if (this.props.incident && this.props.incidentOptions) {
                requiresPerson = incidentLogic.isPersonDescriptionRequired(this.props.incident, this.props.incidentOptions);
            }
            if ((this.props.incident.numberInvolved && this.props.incident.numberInvolved > 0) || (this.props.incident.involvedDescription && this.props.incident.involvedDescription !== "")) {
                hasGroupDetails = true;
            }
            if (this.props.incident && this.props.incidentOptions) {
                contactTracing = incidentLogic.isIncidentContactTracing(this.props.incident, this.props.incidentOptions);;
            }
            this.setState({
                patrons: [...this.props.incident.incidentPatrons],
                requiresPerson,
                hasGroupDetails,
                contactTracing,
                patronIndex: -1
            });
        } catch (err) {
            commonLogic.trackException(err, "IncidentWizardPeopleAndGroups.componentDidMount", {})
        }
    }

    onPatronUpdated(patron) {
        if (this.state.patrons && this.state.patrons.length > 0 && this.state.patronIndex > -1) {
            var patrons = [...this.state.patrons];
            let activePatrons = patrons.filter(p => !p.obsolete);
            let index = patrons.indexOf(activePatrons[this.state.patronIndex]);
            if (index === -1) return;

            patron.hasChanged = true;
            patrons[index] = patron;
            this.setState({
                patrons
            }, () => this.onUpdatePatrons());
        }
    }

    onUploadPatronFiles(files) {
        var patrons = [...this.state.patrons];
        let activePatrons = patrons.filter(p => !p.obsolete);
        let index = patrons.indexOf(activePatrons[this.state.patronIndex]);
        if (index === -1) return;
        this.props.onUploadPatronFiles(index, files, this.props.incident.storageFolder);
    }

    onAddPatron() {
        const patrons = this.state.patrons.map(l => Object.assign({}, l));
        let incidentPatron = new IncidentPatronDTO();
        incidentPatron.incidentPatronId = 0;
        incidentPatron.name = "";
        incidentPatron.obsolete = false;
        incidentPatron.hasChanged = true;
        incidentPatron.countryId = 1;
        patrons.push(incidentPatron);
        let activePatrons = patrons.filter(p => !p.obsolete);
        this.setState({
            patrons,
            patronIndex: activePatrons.length - 1
        }, () => this.onUpdatePatrons());
    }

    onUpdatePatrons() {
        let incident = { ...this.props.incident };
        incident.incidentPatrons = [...this.state.patrons];
        this.props.onUpdateIncident(incident);
    }

    onAddGroupDetails() {
        this.setState({ hasGroupDetails: true, showGroup: true });
    }

    onRemoveGroupRequest() {
        this.setState({ confirmRemoveGroup: true });
    }

    onRemoveGroupDetails() {
        let incident = { ...this.props.incident };
        incident.numberInvolved = 0;
        incident.involvedDescription = "";
        this.props.onUpdateIncident(incident);
        this.setState({ hasGroupDetails: false, showGroup: false, confirmRemovePersonIndex: -1, confirmRemoveGroup: false })
    }

    onShowGroupDetails() {
        this.setState({ showGroup: true });
    }

    onHideGroupDetails() {
        this.setState({ patronIndex: -1, showGroup: false });
    }

    onShowPatron(index) {
        this.setState({ patronIndex: index });
    }


    onShowPreviousPatron() {
        let patronIndex = this.state.patronIndex - 1;
        this.setState({ patronIndex });
    }

    onShowNextPatron() {
        let patronIndex = this.state.patronIndex + 1;
        let activePatrons = this.state.patrons.filter(p => !p.obsolete);
        if (patronIndex >= activePatrons.length) {
            patronIndex = -1;
        }
        this.setState({ patronIndex });
    }

    onRemovePersonRequest(index: number) {
        this.setState({ confirmRemovePersonIndex: index });
    }

    onRemovePerson() {
        if (this.state.patrons && this.state.patrons.length > 0 && (this.state.patronIndex > -1 || this.state.confirmRemovePersonIndex > -1)) {
            let patronIndex = this.state.confirmRemovePersonIndex;
            if (patronIndex == -1) {
                patronIndex = this.state.patronIndex;
            }

            // find the patron
            var patrons = [...this.state.patrons];
            //let patronIndex = this.state.patronIndex;
            let activePatrons = patrons.filter(p => !p.obsolete);
            let index = patrons.indexOf(activePatrons[patronIndex]);
            if (index === -1) return;

            // is this a saved item, or are we making an existing item obsolete
            if (patrons[index].incidentPatronId === 0) {
                // remove
                patrons.splice(index, 1);

            } else {
                // make obsolete
                patrons[index].hasChanged = true;
                patrons[index].obsolete = true;
            }

            this.setState({
                patrons,
                patronIndex: -1,
                confirmRemovePersonIndex: -1,
                confirmRemoveGroup: false
            }, () => this.onUpdatePatrons());

        }
    }

    onHidePerson() {
        this.setState({ patronIndex: -1, showGroup: false });
    }

    handleConfirm() {
        if (this.state.confirmRemoveGroup) {
            this.onRemoveGroupDetails();
        }
        if (this.state.confirmRemovePersonIndex > -1) {
            this.onRemovePerson();
        }
    }

    handleNotConfirm() {
        this.setState({ confirmRemovePersonIndex: -1, confirmRemoveGroup: false });
    }

    handleAdd() {
        this.setState({ patronIndex: -1, showGroup: false }, () => {
            this.onAddPatron();
        });
    }

    render() {
        const { theme } = this.props;

        let patronMessage;
        let patrons = this.state.patrons.filter(p => !p.obsolete);
        let hasGroupDetails = this.props.incident.numberInvolved && this.props.incident.numberInvolved > 0 && this.props.incident.involvedDescription && this.props.incident.involvedDescription !== "";
        let hasPatrons = patrons.length > 0;

        let removePatronDialog = (
            <Dialog
                open={this.state.confirmRemovePersonIndex > -1 || this.state.confirmRemoveGroup}
                onClose={this.handleNotConfirm}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Confirm Removal"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to remove this {this.state.confirmRemoveGroup ? "group" : "person"}  from the incident?
                        </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <DefaultButton onClick={this.handleNotConfirm} style={{ width: 'auto', marginRight: '10px' }}>No</DefaultButton>
                    <DefaultButton text={"Yes"} onClick={this.handleConfirm} style={{ width: 'auto', marginRight: '10px' }} autoFocus></DefaultButton>
                </DialogActions>
            </Dialog>
        );

        let groupError = false;
        if (this.state.requiresPerson && !hasPatrons && !hasGroupDetails) {
            patronMessage = <Error message={"POI information required for this incident category"}></Error>
            if (!hasPatrons && hasGroupDetails) {
                groupError = true;
            }
        }

        let patronButtons = patrons.map((p, index) => {
            let previewPath = "";
            if (p.incidentPatronPhotos) {
                let photos = p.incidentPatronPhotos.filter(f => f.isImage && !f.obsolete);
                if (photos && photos.length > 0) {
                    previewPath = photos[0].filePreviewPath;
                }
            }
            let isValid = p.sourceFacialRecognitionEvent || (incidentLogic.validateIncidentPatron(p, this.props.incident, this.props.incidentOptions).length === 0);
            return (
                <Box key={"patron_" + index} p={0}>
                    <People
                        isGroup={false}
                        text={p.name || "PERSON"}
                        isError={!isValid}
                        previewPath={previewPath}
                        isBlocked={p.isUnderAgeLocked}
                        onClick={() => this.onShowPatron(index)}
                        onRemove={() => this.onRemovePersonRequest(index)}
                    />
                </Box>
            );
        });

        return (
            <>
                <FullPageDialog
                    open={this.state.showGroup || this.state.patronIndex > -1}
                    title={this.state.showGroup ? "Involved Group" : "Involved Person"}
                    hideCloseButton={false}
                    notFullWidth={this.state.showGroup}
                    showAddButton={this.state.patronIndex > -1}
                     onAdd={this.handleAdd}
                     addText={"Extra POI"}
                    onDismissed={this.onHidePerson}
                    style={{ paddingTop: '0', paddingBottom: '80px' }}>
                    {this.state.patronIndex > -1 && (
                        <IncidentWizardPerson
                            incident={this.props.incident}
                            incidentOptions={this.props.incidentOptions}
                            patron={patrons[this.state.patronIndex]}
                            onPatronUpdated={this.onPatronUpdated}
                            onUploadPatronFiles={this.onUploadPatronFiles}
                            isUploading={this.props.isUploading}
                            contactTracing={this.state.contactTracing}
                            onRemove={this.onRemovePerson}
                            onDone={this.onHidePerson} />
                    )}
                    {this.state.showGroup && (
                        <IncidentWizardPeopleGroup
                            incident={this.props.incident}
                            incidentOptions={this.props.incidentOptions}
                            onUpdateIncident={this.props.onUpdateIncident}
                            onRemove={this.onRemoveGroupDetails}
                            onDone={this.onHideGroupDetails} />
                    )}
                </FullPageDialog>
                {(!this.state.showGroup && this.state.patronIndex == -1) && (
                    <SwipeContainer onSwipedLeft={this.props.onSwipedLeft} onSwipedRight={this.props.onSwipedRight}>
                        <CardContainer
                            title={"Involved people"}>
                            <CardContent style={{ paddingTop: 0 }}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Box display="flex" flexDirection="row" justifyContent="center">
                                            <Box p={1}>
                                                <ButtonLink style={{ padding: 0 }} onClick={this.onAddPatron}>
                                                    <Box display="flex" flexDirection="column" justifyContent="center">
                                                        <Box p={0} flexGrow={1}>
                                                            <PersonAddIcon style={{
                                                                color: theme.custom.colors.controlBorder,
                                                                width: '70px',
                                                                height: '70px',
                                                                margin: '0 28px 0 5px',
                                                                display: 'block'
                                                            }} />
                                                        </Box>
                                                        <Box p={0} flexGrow={0}>
                                                            <p style={{
                                                                display: 'block',
                                                                textTransform: 'uppercase',
                                                                margin: 0,
                                                                fontSize: '12px'
                                                            }}>Person</p>
                                                        </Box>
                                                    </Box>
                                                </ButtonLink>
                                            </Box>
                                            {!this.state.hasGroupDetails && (
                                                <Box p={1}>
                                                    <ButtonLink style={{ padding: 0 }} onClick={this.onAddGroupDetails}>
                                                        <Box display="flex" flexDirection="column" justifyContent="center">
                                                            <Box p={0} flexGrow={1}>
                                                                <GroupAddIcon
                                                                    style={{
                                                                        color: theme.custom.colors.controlBorder,
                                                                        width: '90px',
                                                                        height: '70px',
                                                                        margin: '0 28px 0 0',
                                                                        display: 'block'
                                                                    }} />
                                                            </Box>
                                                            <Box p={0} flexGrow={0}>
                                                                <p style={{
                                                                    display: 'block',
                                                                    textTransform: 'uppercase',
                                                                    margin: 0,
                                                                    fontSize: '12px'
                                                                }}>Group</p>
                                                            </Box>
                                                        </Box>
                                                    </ButtonLink>
                                                </Box>
                                            )}
                                        </Box>
                                        <Box display="flex" flexDirection="row" justifyContent="left" flexWrap="wrap">
                                            {this.state.hasGroupDetails && (
                                                <Box p={0}>
                                                    <People
                                                        isGroup={true}
                                                        text={this.props.incident.involvedDescription || "Group"}
                                                        groupCount={this.props.incident.numberInvolved}
                                                        isError={groupError}
                                                        onClick={this.onShowGroupDetails}
                                                        onRemove={() => this.onRemoveGroupRequest()}
                                                    />
                                                </Box>
                                            )}
                                            {patronButtons}
                                        </Box>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                {patronMessage}
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </CardContent>
                        </CardContainer>
                        {removePatronDialog}
                    </SwipeContainer>
                )
                }
            </>
        );
    }
}

export default withStyles(styles, { withTheme: true })(IncidentWizardPeopleAndGroups);