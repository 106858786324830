import { connect } from 'react-redux'
import { withRouter } from "react-router";
import FacialRecognitionStatistics from '../components/facialRecognition/facialRecognitionServers/FacialRecognitionStatistics';
import * as facialRecognitionNotificationActions from "../common/actions/facialRecognitionNotification";

const mapStateToProps = (state, props) => {
    return {
        isLoading: state.facialRecognitionNotification.statistics.isLoading,
        fromDate: state.facialRecognitionNotification.statistics.fromDate,
        rangeAsMinutes: state.facialRecognitionNotification.statistics.rangeAsMinutes,
        statistics: state.facialRecognitionNotification.statistics.statistics,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSet: (fromDate, rangeAsMinutes) => {
            dispatch(facialRecognitionNotificationActions.facialRecognitionNotificationStatisticsSet(fromDate, rangeAsMinutes))
        },
        onLoad: (fromDate, rangeAsMinutes) => {
            dispatch(facialRecognitionNotificationActions.facialRecognitionNotificationStatisticsRequest(fromDate, rangeAsMinutes))
        }
    }
}

const FacialRecognitionStatisticsContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(FacialRecognitionStatistics))

export default FacialRecognitionStatisticsContainer