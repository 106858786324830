import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { permissionsLogic } from '../common/logic/permissionsLogic';
import VenueEvents from '../components/venue/venueEvents/VenueEvents'
import * as venueEventActions from '../common/actions/venueEvent';
import * as venueSecurityFirmActions from '../common/actions/venueSecurityFirm'



const mapStateToProps = (state, props) => {
    return {
        paging: state.venueEvent.venueEvents.paging,
        filter: state.venueEvent.venueEvents.filter,
        isLoading: state.venueEvent.venueEvents.isLoading,
        venueEvents: state.venueEvent.venueEvents.venueEvents,
        venueEventId: state.venueEvent.venueEvent.venueEventId,
        canEdit: permissionsLogic.hasPermissionForState(state, "ManageEvent")
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (pageSize, page, filter) => {
            dispatch(venueEventActions.venueEventsRequest(page, pageSize, filter))
        },
        onLoadItem: (venueEventId) => {
            dispatch(venueEventActions.venueEventRequest(venueEventId))
        },
        onCreate: () => {
            dispatch(venueEventActions.venueEventCreateRequest())
        },
        onResetItem: () => {
            dispatch(venueEventActions.venueEventReset())
        },
        onUpdate: (venueEvent) => {
            dispatch(venueEventActions.venueEventUpdate(venueEvent))
        },
        onSave: () => {
            dispatch(venueEventActions.venueEventSaveRequest())
        },
        onSelectVenueSecurityFirm: (venueId, securityFirmId, venueEventId) => {
            dispatch(venueSecurityFirmActions.venueSecurityFirmSelect(venueId, securityFirmId, venueEventId))
        },
    }
}

const VenueEventsContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(VenueEvents))

export default VenueEventsContainer