import { connect } from 'react-redux'
import { withRouter } from "react-router";
import POIWatchlistRegistration from '../components/facialRecognition/facialRecognitionPOI/POIWatchlistRegistration'
import { permissionsLogic } from '../common/logic/permissionsLogic';
import * as facialRecognitionPOIActions from '../common/actions/facialRecognitionPOI';

const mapStateToProps = (state, props) => {
    return {
        isSaving: state.facialRecognitionPOI.poiWatchlistRegistration.isSaving,
        isLoading: state.facialRecognitionPOI.poiWatchlistRegistration.isLoading,
        poiRegistration: state.facialRecognitionPOI.poiWatchlistRegistration.poiRegistration,
        canCreate: permissionsLogic.hasPermissionForState(state, 'RegisterFacialRecognitionPOI')
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdate: (poiRegistration) => {
            dispatch(facialRecognitionPOIActions.facialRecognitionPoiWatchlistRegistrationSet(poiRegistration))
        },
        onCancel: () => {
            dispatch(facialRecognitionPOIActions.facialRecognitionPoiWatchlistRegistrationReset())
        },
        onSave: () => {
            dispatch(facialRecognitionPOIActions.facialRecognitionPoiWatchlistRegistrationSaveRequest())
        }
    }
}

const FacialRecognitionPOIWatchlistRegistrationContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(POIWatchlistRegistration))

export default FacialRecognitionPOIWatchlistRegistrationContainer