import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import View from '../../common/View';
import Tabs from '../../common/Tabs';

export interface IFacialRecognitionNotificationsTabsProps {
    venueId: number;
    selectedTab: string;
    canManageCameras: boolean;
    canManageWatchlists: boolean;
    canManageEvents: boolean;
    canManageEnrolments: boolean;
    history: any;
}

class FacialRecognitionNotificationsTabs extends Component<IFacialRecognitionNotificationsTabsProps, any> {
    constructor(props: IFacialRecognitionNotificationsTabsProps) {
        super(props)

        this.state = {
        };
        this.tabs = this.tabs.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
    }

    handleTabChange = (event, value) => {
        let tabName = this.tabs()[value];
        // console.log(value, tabName)
        switch (tabName) {
            case "Events":
                this.props.history.push('/venue/facialRecognitionNotification/events');
                break;
            case "Watchlists":
                this.props.history.push('/venue/facialRecognitionNotification/watchlists');
                break;
            case "Search Pois":
                this.props.history.push('/venue/facialRecognitionNotification/pois');
                break;
            case "Enrol":
                this.props.history.push('/venue/facialRecognitionNotification/enrol');
                break;
            case "Cameras":
                this.props.history.push('/venue/facialRecognitionNotification/cameras');
                break;

            default:
                this.props.history.push('/venue/facialRecognitionNotification');
                break;
        }
    };

    tabs() {
        let tabs: string[] = [];
        // the tabs are dymanic based on permissions
        tabs.push('Notifications');
        if (this.props.canManageCameras) {
            tabs.push('Cameras');
        }
        if (this.props.canManageWatchlists) {
            tabs.push('Watchlists');
        }
        if (this.props.canManageWatchlists) {
            tabs.push('Search Pois');
        }
        if (this.props.canManageEvents) {
            tabs.push('Events');
        }
        if (this.props.canManageEnrolments) {
            tabs.push('Enrol');
        }
        return tabs;
    }

    selected(tabs: string[]) {
        let index = tabs.indexOf(this.props.selectedTab);
        if (index < 0) index = 0;
        return index;
    }

    render() {
        const tabs = this.tabs();

        return (<View style={{ marginTop: '10px' }}>
            <Tabs selected={this.selected(tabs)} labels={tabs} onClick={index => this.handleTabChange(null, index)}></Tabs>
        </View>
        );
    }
}

export default withStyles(styles, { withTheme: true })(FacialRecognitionNotificationsTabs);