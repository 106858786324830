import { makeActionCreator } from './helpers';

export const
    FACIAL_RECOGNITION_NOTIFICATION_FILTER_REQUEST = "FACIAL_RECOGNITION_NOTIFICATION_FILTER_REQUEST",
    FACIAL_RECOGNITION_NOTIFICATION_FILTER_REQUEST_FAILURE = "FACIAL_RECOGNITION_NOTIFICATION_FILTER_REQUEST_FAILURE",
    FACIAL_RECOGNITION_NOTIFICATION_FILTER_REQUEST_SUCCESS = "FACIAL_RECOGNITION_NOTIFICATION_FILTER_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_NOTIFICATION_SET_FILTER = "FACIAL_RECOGNITION_NOTIFICATION_SET_FILTER",
    FACIAL_RECOGNITION_NOTIFICATION_SET_PAGING = "FACIAL_RECOGNITION_NOTIFICATION_SET_PAGING",
    FACIAL_RECOGNITION_NOTIFICATION_SET_ITEM = "FACIAL_RECOGNITION_NOTIFICATION_SET_ITEM",
    FACIAL_RECOGNITION_NOTIFICATION_REQUEST = "FACIAL_RECOGNITION_NOTIFICATION_REQUEST",
    FACIAL_RECOGNITION_NOTIFICATION_REQUEST_FAILURE = "FACIAL_RECOGNITION_NOTIFICATION_REQUEST_FAILURE",
    FACIAL_RECOGNITION_NOTIFICATION_REQUEST_SUCCESS = "FACIAL_RECOGNITION_NOTIFICATION_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_NOTIFICATION_GET_REQUEST = "FACIAL_RECOGNITION_NOTIFICATION_GET_REQUEST",
    FACIAL_RECOGNITION_NOTIFICATION_GET_REQUEST_FAILURE = "FACIAL_RECOGNITION_NOTIFICATION_REQUEST_GET_FAILURE",
    FACIAL_RECOGNITION_NOTIFICATION_GET_REQUEST_SUCCESS = "FACIAL_RECOGNITION_NOTIFICATION_REQUEST_GET_SUCCESS",

    FACIAL_RECOGNITION_NOTIFICATION_EVENT_SET = "FACIAL_RECOGNITION_NOTIFICATION_EVENT_SET",
    FACIAL_RECOGNITION_NOTIFICATION_EVENT_REQUEST = "FACIAL_RECOGNITION_NOTIFICATION_EVENT_REQUEST",
    FACIAL_RECOGNITION_NOTIFICATION_EVENT_REQUEST_FAILURE = "FACIAL_RECOGNITION_NOTIFICATION_EVENT_REQUEST_FAILURE",
    FACIAL_RECOGNITION_NOTIFICATION_EVENT_REQUEST_SUCCESS = "FACIAL_RECOGNITION_NOTIFICATION_EVENT_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_NOTIFICATION_EVENT_SAVE_REQUEST = "FACIAL_RECOGNITION_NOTIFICATION_EVENT_SAVE_REQUEST",
    FACIAL_RECOGNITION_NOTIFICATION_EVENT_SAVE_REQUEST_FAILURE = "FACIAL_RECOGNITION_NOTIFICATION_EVENT_SAVE_REQUEST_FAILURE",
    FACIAL_RECOGNITION_NOTIFICATION_EVENT_SAVE_REQUEST_SUCCESS = "FACIAL_RECOGNITION_NOTIFICATION_EVENT_SAVE_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_NOTIFICATION_EVENT_MOVE_ITEM = "FACIAL_RECOGNITION_NOTIFICATION_EVENT_MOVE_ITEM",
    FACIAL_RECOGNITION_NOTIFICATION_EVENTS_SAVE_REQUEST_SUCCESS = "FACIAL_RECOGNITION_NOTIFICATION_EVENTS_SAVE_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_NOTIFICATION_EVENTS_SAVE_REQUEST_FAILURE = "FACIAL_RECOGNITION_NOTIFICATION_EVENTS_SAVE_REQUEST_FAILURE",

    FACIAL_RECOGNITION_NOTIFICATION_CAMERAS_SET = "FACIAL_RECOGNITION_NOTIFICATION_CAMERAS_SET",
    FACIAL_RECOGNITION_NOTIFICATION_CAMERAS_REQUEST = "FACIAL_RECOGNITION_NOTIFICATION_CAMERAS_REQUEST",
    FACIAL_RECOGNITION_NOTIFICATION_CAMERAS_REQUEST_FAILURE = "FACIAL_RECOGNITION_NOTIFICATION_CAMERAS_REQUEST_FAILURE",
    FACIAL_RECOGNITION_NOTIFICATION_CAMERAS_REQUEST_SUCCESS = "FACIAL_RECOGNITION_NOTIFICATION_CAMERAS_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_NOTIFICATION_CAMERAS_SAVE_REQUEST = "FACIAL_RECOGNITION_NOTIFICATION_CAMERAS_SAVE_REQUEST",
    FACIAL_RECOGNITION_NOTIFICATION_CAMERAS_SAVE_REQUEST_FAILURE = "FACIAL_RECOGNITION_NOTIFICATION_CAMERAS_SAVE_REQUEST_FAILURE",
    FACIAL_RECOGNITION_NOTIFICATION_CAMERAS_SAVE_REQUEST_SUCCESS = "FACIAL_RECOGNITION_NOTIFICATION_CAMERAS_SAVE_REQUEST_SUCCESS",

    FACIAL_RECOGNITION_NOTIFICATION_PROCESS_REQUEST = "FACIAL_RECOGNITION_NOTIFICATION_PROCESS_REQUEST",
    FACIAL_RECOGNITION_NOTIFICATION_PROCESS_REQUEST_FAILURE = "FACIAL_RECOGNITION_NOTIFICATION_PROCESS_REQUEST_FAILURE",
    FACIAL_RECOGNITION_NOTIFICATION_PROCESS_REQUEST_SUCCESS = "FACIAL_RECOGNITION_NOTIFICATION_PROCESS_REQUEST_SUCCESS",

    FACIAL_RECOGNITION_NOTIFICATION_CAMERA_CREATE = "FACIAL_RECOGNITION_NOTIFICATION_CAMERA_CREATE",
    FACIAL_RECOGNITION_NOTIFICATION_CAMERA_SET = "FACIAL_RECOGNITION_NOTIFICATION_CAMERA_SET",
    FACIAL_RECOGNITION_NOTIFICATION_CAMERA_RESET = "FACIAL_RECOGNITION_NOTIFICATION_CAMERA_RESET",
    FACIAL_RECOGNITION_NOTIFICATION_CAMERA_SAVE_REQUEST = "FACIAL_RECOGNITION_NOTIFICATION_CAMERA_SAVE_REQUEST",
    FACIAL_RECOGNITION_NOTIFICATION_CAMERA_SAVE_REQUEST_FAILURE = "FACIAL_RECOGNITION_NOTIFICATION_CAMERA_SAVE_REQUEST_FAILURE",
    FACIAL_RECOGNITION_NOTIFICATION_CAMERA_SAVE_REQUEST_SUCCESS = "FACIAL_RECOGNITION_NOTIFICATION_CAMERA_SAVE_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_NOTIFICATION_CAMERA_SYNC_REQUEST = "FACIAL_RECOGNITION_NOTIFICATION_CAMERA_SYNC_REQUEST",
    FACIAL_RECOGNITION_NOTIFICATION_CAMERA_SYNC_REQUEST_FAILURE = "FACIAL_RECOGNITION_NOTIFICATION_CAMERA_SYNC_REQUEST_FAILURE",
    FACIAL_RECOGNITION_NOTIFICATION_CAMERA_SYNC_REQUEST_SUCCESS = "FACIAL_RECOGNITION_NOTIFICATION_CAMERA_SYNC_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_NOTIFICATION_CAMERA_STATUS_SAVE_REQUEST = "FACIAL_RECOGNITION_NOTIFICATION_CAMERA_STATUS_SAVE_REQUEST",
    FACIAL_RECOGNITION_NOTIFICATION_CAMERA_STATUS_SAVE_REQUEST_FAILURE = "FACIAL_RECOGNITION_NOTIFICATION_CAMERA_STATUS_SAVE_REQUEST_FAILURE",
    FACIAL_RECOGNITION_NOTIFICATION_CAMERA_STATUS_SAVE_REQUEST_SUCCESS = "FACIAL_RECOGNITION_NOTIFICATION_CAMERA_STATUS_SAVE_REQUEST_SUCCESS",

    FACIAL_RECOGNITION_CAMERA_NEW_IMPORT_REQUEST = "FACIAL_RECOGNITION_CAMERA_NEW_IMPORT_REQUEST",
    FACIAL_RECOGNITION_CAMERA_NEW_IMPORT_REQUEST_FAILURE = "FACIAL_RECOGNITION_CAMERA_NEW_IMPORT_REQUEST_FAILURE",
    FACIAL_RECOGNITION_CAMERA_NEW_IMPORT_REQUEST_SUCCESS = "FACIAL_RECOGNITION_CAMERA_NEW_IMPORT_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_CAMERA_IMPORT_SET = "FACIAL_RECOGNITION_CAMERA_IMPORT_SET",
    FACIAL_RECOGNITION_CAMERA_IMPORT_REQUEST = "FACIAL_RECOGNITION_CAMERA_IMPORT_REQUEST",
    FACIAL_RECOGNITION_CAMERA_IMPORT_REQUEST_FAILURE = "FACIAL_RECOGNITION_CAMERA_IMPORT_REQUEST_FAILURE",
    FACIAL_RECOGNITION_CAMERA_IMPORT_REQUEST_SUCCESS = "FACIAL_RECOGNITION_CAMERA_IMPORT_REQUEST_SUCCESS",

    FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPES_REQUEST = "FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPES_REQUEST",
    FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPES_REFRESH = "FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPES_REFRESH",
    FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPES_REQUEST_SUCCESS = "FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPES_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPES_REQUEST_FAILURE = "FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPES_REQUEST_FAILURE",
    FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_RESET = "FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_RESET",
    FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_SET = "FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_SET",
    FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_REQUEST = "FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_REQUEST",
    FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_REQUEST_SUCCESS = "FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_REQUEST_FAILURE = "FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_REQUEST_FAILURE",
    FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_CREATE_REQUEST = "FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_CREATE_REQUEST",
    FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_CREATE_REQUEST_SUCCESS = "FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_CREATE_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_CREATE_REQUEST_FAILURE = "FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_CREATE_REQUEST_FAILURE",
    FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_UPSERT_REQUEST = "FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_UPSERT_REQUEST",
    FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_UPSERT_REQUEST_SUCCESS = "FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_UPSERT_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_UPSERT_REQUEST_FAILURE = "FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_UPSERT_REQUEST_FAILURE",
    FACIAL_RECOGNITION_NOTIFICATION_STATISTICS_SET = "FACIAL_RECOGNITION_NOTIFICATION_STATISTICS_SET",
    FACIAL_RECOGNITION_NOTIFICATION_STATISTICS_REQUEST = "FACIAL_RECOGNITION_NOTIFICATION_STATISTICS_REQUEST",
    FACIAL_RECOGNITION_NOTIFICATION_STATISTICS_REQUEST_SUCCESS = "FACIAL_RECOGNITION_NOTIFICATION_STATISTICS_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_NOTIFICATION_STATISTICS_REQUEST_FAILURE = "FACIAL_RECOGNITION_NOTIFICATION_STATISTICS_REQUEST_FAILURE"
    ;

// import
export const facialRecognitionNotificationStatisticsSet = makeActionCreator(FACIAL_RECOGNITION_NOTIFICATION_STATISTICS_SET, 'fromDate', 'rangeAsMinutes');
export const facialRecognitionNotificationStatisticsRequest = makeActionCreator(FACIAL_RECOGNITION_NOTIFICATION_STATISTICS_REQUEST, 'fromDate', 'rangeAsMinutes');
export const facialRecognitionNotificationStatisticsRequestFailure = makeActionCreator(FACIAL_RECOGNITION_NOTIFICATION_STATISTICS_REQUEST_FAILURE, 'error');
export const facialRecognitionNotificationStatisticsRequestSuccess = makeActionCreator(FACIAL_RECOGNITION_NOTIFICATION_STATISTICS_REQUEST_SUCCESS, 'statistics');

export const facialRecognitionCameraNewImportRequest = makeActionCreator(FACIAL_RECOGNITION_CAMERA_NEW_IMPORT_REQUEST, 'forVenueId');
export const facialRecognitionCameraNewImportRequestFailure = makeActionCreator(FACIAL_RECOGNITION_CAMERA_NEW_IMPORT_REQUEST_FAILURE, 'error');
export const facialRecognitionCameraNewImportRequestSuccess = makeActionCreator(FACIAL_RECOGNITION_CAMERA_NEW_IMPORT_REQUEST_SUCCESS, 'facialRecognitionCameraImports');
export const facialRecognitionCameraImportSet = makeActionCreator(FACIAL_RECOGNITION_CAMERA_IMPORT_SET, 'facialRecognitionCameraImports');
export const facialRecognitionCameraImportRequest = makeActionCreator(FACIAL_RECOGNITION_CAMERA_IMPORT_REQUEST);
export const facialRecognitionCameraImportRequestFailure = makeActionCreator(FACIAL_RECOGNITION_CAMERA_IMPORT_REQUEST_FAILURE, 'error');
export const facialRecognitionCameraImportRequestSuccess = makeActionCreator(FACIAL_RECOGNITION_CAMERA_IMPORT_REQUEST_SUCCESS, 'facialRecognitionCameraImports');

export function facialRecognitionNotificationWatchlistTypesRefresh() {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPES_REFRESH
    }
}

export function facialRecognitionNotificationWatchlistTypesRequest(page, pageSize, filter) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPES_REQUEST,
        page,
        pageSize,
        filter
    };
}

export function facialRecognitionNotificationWatchlistTypesRequestSuccess(facialRecognitionNotificationWatchlistTypes, paging, filter) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPES_REQUEST_SUCCESS,
        facialRecognitionNotificationWatchlistTypes,
        paging,
        filter
    };
}

export function facialRecognitionNotificationWatchlistTypesRequestFailure(error) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPES_REQUEST_FAILURE,
        error
    };
}

export function facialRecognitionNotificationWatchlistTypeReset() {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_RESET
    };
}

export function facialRecognitionNotificationWatchlistTypeSet(facialRecognitionNotificationWatchlistType) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_SET,
        facialRecognitionNotificationWatchlistType
    };
}

export function facialRecognitionNotificationWatchlistTypeRequest(facialRecognitionNotificationWatchlistTypeId) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_REQUEST,
        facialRecognitionNotificationWatchlistTypeId
    };
}

export function facialRecognitionNotificationWatchlistTypeRequestSuccess(facialRecognitionNotificationWatchlistType) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_REQUEST_SUCCESS,
        facialRecognitionNotificationWatchlistType
    };
}

export function facialRecognitionNotificationWatchlistTypeRequestFailure(error) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_REQUEST_FAILURE,
        error
    };
}

export function facialRecognitionNotificationWatchlistTypeCreateRequest() {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_CREATE_REQUEST
    };
}

export function facialRecognitionNotificationWatchlistTypeCreateRequestSuccess(facialRecognitionNotificationWatchlistType) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_CREATE_REQUEST_SUCCESS,
        facialRecognitionNotificationWatchlistType
    };
}

export function facialRecognitionNotificationWatchlistTypeCreateRequestFailure(error) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_CREATE_REQUEST_FAILURE,
        error
    };
}

export function facialRecognitionNotificationWatchlistTypeUpsertRequest() {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_UPSERT_REQUEST
    };
}

export function facialRecognitionNotificationWatchlistTypeUpsertRequestSuccess(facialRecognitionNotificationWatchlistType) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_UPSERT_REQUEST_SUCCESS,
        facialRecognitionNotificationWatchlistType
    };
}

export function facialRecognitionNotificationWatchlistTypeUpsertRequestFailure(error) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_WATCHLIST_TYPE_UPSERT_REQUEST_FAILURE,
        error
    };
}

export function facialRecognitionNotificationProcessRequest(facialRecognitionNotificationId) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_PROCESS_REQUEST,
        facialRecognitionNotificationId
    };
}

export function facialRecognitionNotificationProcessRequestSuccess() {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_PROCESS_REQUEST_SUCCESS
    };
}

export function facialRecognitionNotificationProcessRequestFailure(error) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_PROCESS_REQUEST_FAILURE,
        error
    };
}

export function facialRecognitionNotificationSetFilter(filter) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_SET_FILTER,
        filter
    }
}

export function facialRecognitionNotificationSetPaging(paging) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_SET_PAGING,
        paging
    }
}

export function facialRecognitionNotificationSetItem(facialRecognitionNotification) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_SET_ITEM,
        facialRecognitionNotification
    }
}

export function facialRecognitionNotificationFilterRequest() {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_FILTER_REQUEST
    };
}

export function facialRecognitionNotificationFilterRequestSuccess(filter) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_FILTER_REQUEST_SUCCESS,
        filter
    };
}

export function facialRecognitionNotificationFilterRequestFailure(error) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_FILTER_REQUEST_FAILURE,
        error
    };
}

export function facialRecognitionNotificationRequest() {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_REQUEST
    };
}

export function facialRecognitionNotificationRequestSuccess(filter, paging, facialRecognitionNotifications) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_REQUEST_SUCCESS,
        filter,
        paging,
        facialRecognitionNotifications
    };
}

export function facialRecognitionNotificationRequestFailure(error) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_REQUEST_FAILURE,
        error
    };
}

export function facialRecognitionNotificationGetRequest(facialRecognitionNotificationId) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_GET_REQUEST,
        facialRecognitionNotificationId
    };
}

export function facialRecognitionNotificationGetRequestSuccess(facialRecognitionNotification) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_GET_REQUEST_SUCCESS,
        facialRecognitionNotification
    };
}

export function facialRecognitionNotificationGetRequestFailure(error) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_GET_REQUEST_FAILURE,
        error
    };
}

export function facialRecognitionNotificationEventSet(facialRecognitionEvent) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_EVENT_SET,
        facialRecognitionEvent
    };
}

export function facialRecognitionNotificationEventRequest(forVenueId, forGroupId) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_EVENT_REQUEST,
        forVenueId,
        forGroupId
    };
}

export function facialRecognitionNotificationEventRequestSuccess(
    facialRecognitionEvents,
    facialRecognitionCameras,
    facialRecognitionWatchlists,
    incidentTypes,
    incidentTypeCategoryTypes,
    incidentFlagTypes,
    userRoleTypes,
    defaultNotificationTrigger
) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_EVENT_REQUEST_SUCCESS,
        facialRecognitionEvents,
        facialRecognitionCameras,
        facialRecognitionWatchlists,
        incidentTypes,
        incidentTypeCategoryTypes,
        incidentFlagTypes,
        userRoleTypes,
        defaultNotificationTrigger
    };
}

export function facialRecognitionNotificationEventRequestFailure(error) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_EVENT_REQUEST_FAILURE,
        error
    };
}

export function facialRecognitionNotificationEventSaveRequest() {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_EVENT_SAVE_REQUEST
    };
}

export function facialRecognitionNotificationEventSaveRequestSuccess(facialRecognitionEvents) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_EVENT_SAVE_REQUEST_SUCCESS,
        facialRecognitionEvents
    };
}

export function facialRecognitionNotificationEventSaveRequestFailure(error) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_EVENT_SAVE_REQUEST_FAILURE,
        error
    };
}

export function facialRecognitionNotificationEventMoveItem(facialRecognitionEvent, position) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_EVENT_MOVE_ITEM,
        facialRecognitionEvent,
        position
    }
}

export function facialRecognitionNotificationEventsSaveRequestSuccess(facialRecognitionEvents) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_EVENTS_SAVE_REQUEST_SUCCESS,
        facialRecognitionEvents
    };
}

export function facialRecognitionNotificationEventsSaveRequestFailure(error) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_EVENTS_SAVE_REQUEST_FAILURE,
        error
    };
}

export function facialRecognitionNotificationCamerasSet(facialRecognitionCameras) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_CAMERAS_SET,
        facialRecognitionCameras
    };
}

export function facialRecognitionNotificationCamerasRequest(forVenueId) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_CAMERAS_REQUEST,
        forVenueId
    };
}

export function facialRecognitionNotificationCamerasRequestSuccess(facialRecognitionCameras, venueLocations, facialRecognitionWatchlists, facialRecognitionServers) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_CAMERAS_REQUEST_SUCCESS,
        facialRecognitionCameras,
        venueLocations,
        facialRecognitionWatchlists,
        facialRecognitionServers
    };
}

export function facialRecognitionNotificationCamerasRequestFailure(error) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_CAMERAS_REQUEST_FAILURE,
        error
    };
}

export function facialRecognitionNotificationCamerasSaveRequest() {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_CAMERAS_SAVE_REQUEST
    };
}

export function facialRecognitionNotificationCamerasSaveRequestSuccess(facialRecognitionCameras) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_CAMERAS_SAVE_REQUEST_SUCCESS,
        facialRecognitionCameras
    };
}

export function facialRecognitionNotificationCamerasSaveRequestFailure(error) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_CAMERAS_SAVE_REQUEST_FAILURE,
        error
    };
}

export function facialRecognitionNotificationCameraCreate(forVenueId) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_CAMERA_CREATE,
        forVenueId
    };
}

export function facialRecognitionNotificationCameraSet(facialRecognitionCamera) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_CAMERA_SET,
        facialRecognitionCamera
    };
}

export function facialRecognitionNotificationCameraReset() {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_CAMERA_RESET
    };
}

export function facialRecognitionNotificationCameraSaveRequest() {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_CAMERA_SAVE_REQUEST
    };
}

export function facialRecognitionNotificationCameraSaveRequestSuccess(facialRecognitionCameras) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_CAMERA_SAVE_REQUEST_SUCCESS,
        facialRecognitionCameras
    };
}

export function facialRecognitionNotificationCameraSaveRequestFailure(error) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_CAMERA_SAVE_REQUEST_FAILURE,
        error
    };
}

export function facialRecognitionNotificationCameraStatusSaveRequest(facialRecognitionCameraId, cameraStatus, forVenueId) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_CAMERA_STATUS_SAVE_REQUEST,
        facialRecognitionCameraId,
        cameraStatus,
        forVenueId
    };
}

export function facialRecognitionNotificationCameraStatusSaveRequestSuccess(facialRecognitionCameras) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_CAMERA_STATUS_SAVE_REQUEST_SUCCESS,
        facialRecognitionCameras
    };
}

export function facialRecognitionNotificationCameraStatusSaveRequestFailure(error) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_CAMERA_STATUS_SAVE_REQUEST_FAILURE,
        error
    };
}

export function facialRecognitionNotificationCameraSyncRequest(forVenueId) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_CAMERA_SYNC_REQUEST,
        forVenueId
    };
}

export function facialRecognitionNotificationCameraSyncRequestSuccess(facialRecognitionCameras) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_CAMERA_SYNC_REQUEST_SUCCESS,
        facialRecognitionCameras
    };
}

export function facialRecognitionNotificationCameraSyncRequestFailure(error) {
    return {
        type: FACIAL_RECOGNITION_NOTIFICATION_CAMERA_SYNC_REQUEST_FAILURE,
        error
    };
}

