import React, { Component } from 'react';
import ControlGroup from "./ControlGroup";
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface ISelectControlGroupProps {
    theme: any;
    classes: any;
    text?: string;
    error?: string;
    defaultValue?: any;
    readonly?: boolean;
    onChange?: Function;
    labelAbove?: boolean;
    placeholder?: string;
    items?: any;
}
class SelectControlGroup extends React.PureComponent<ISelectControlGroupProps, any> {

    constructor(props: ISelectControlGroupProps) {
        super(props)
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = name => event => {
        if (event != null) {
            if (this.props.onChange) {
                this.props.onChange(event.target.value);
            }
        }
    }


    render() {
        const { theme, classes } = this.props;
        const style = this.props.readonly ? theme.custom.textFieldReadonly : this.props.error ? theme.custom.textFieldError : theme.custom.textField;
        return (
            <ControlGroup text={this.props.text} error={this.props.error ? true: false} labelAbove={this.props.labelAbove}>
                <FormControl error={this.props.error ? true : false }  style={{width: '100%'}} disabled={this.props.readonly}>
                    <NativeSelect
                        value={this.props.defaultValue}
                        onChange={this.handleChange('name')}
                        name="name"
                        placeholder={this.props.placeholder}
                        classes={{icon: classes.backgroundColorDefault}}
                        input={<InputBase style={style} 
                        />}
                    >
                        {this.props.items}
                        {this.props.children}
                    </NativeSelect>
                    {this.props.error && (
                        <FormHelperText>{this.props.error}</FormHelperText>
                    )}
                </FormControl>
            </ControlGroup>
        );
    }
}

export default withStyles(styles, { withTheme: true })(SelectControlGroup);