import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import DefaultFab from '../../common/DefaultFab';
import DefaultButton from '../../controls/DefaultButton';
import LayoutForm from '../../layout/LayoutForm';
import TextDisplayControlGroup from '../../controls/TextDisplayControlGroup';
import TextAreaControlGroup from '../../controls/TextAreaControlGroup';
import SelectControlGroup from '../../controls/SelectControlGroup';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import ControlGroup from '../../controls/ControlGroup';
import Grid from '@material-ui/core/Grid';
import FullPageDialog from '../../layout/FullPageDialog';
import PrimaryButton from '../../controls/PrimaryButton';
import ResponsiveList from '../../common/ResponsiveList';
import { KeyName } from '../../../common/dto/common';
import FacialRecognitionCameraWatchlists from '../../venue/facialRecognitionNotifications/FacialRecognitionCameraWatchlists';
import FacialRecognitionCameraImportContainer from '../../../containers/FacialRecognitionCameraImportContainer';
import PageLayout from '../../layout/PageLayout';
import VenueFacialRecognitionNotificationsTabsContainer from '../../../containers/VenueFacialRecognitionNotificationsTabsContainer';
import TransparentButton from '../../controls/TransparentButton';
import VenueTabs from '../../admin/venue/VenueTabs';
import FacialRecognitionTabs from '../../admin/venue/facialRecognition/FacialRecognitionTabs';
import Spacer from '../../common/Spacer';


export interface IFacialRecognitionCamerasProps {
    venueId: number;
    venueMode?: boolean;
    isLoading: boolean;
    isSaving: boolean;
    canEdit: boolean;
    facialRecognitionCameras: AusComplyDtos.FacialRecognitionCamera[];
    facialRecognitionCamera: AusComplyDtos.FacialRecognitionCamera;
    facialRecognitionWatchlists: AusComplyDtos.FacialRecognitionWatchlist[];
    venueLocations: AusComplyDtos.VenueLocation[];
    facialRecognitionServers: AusComplyDtos.FacialRecognitionServer[];
    onLoad: Function;
    onSet: Function;
    onSave: Function;
    onCreateCamera: Function;
    onSetCamera: Function;
    onResetCamera: Function;
    onSaveCamera: Function;
    onSaveCameraStatus: Function;
    onSyncCameras: Function;
    isLevelSix: boolean;
    classes: any;
    theme: any;
    history: any;
    location: any;
}

interface IFacialRecognitionCamerasState {
    showConfiguration: boolean;
}

class FacialRecognitionCameras extends Component<IFacialRecognitionCamerasProps, IFacialRecognitionCamerasState> {
    constructor(props: IFacialRecognitionCamerasProps) {
        super(props)

        this.state = {
            showConfiguration: false
        };
        this.onReset = this.onReset.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onSelectedItemChanged = this.onSelectedItemChanged.bind(this);
        this.getCommands = this.getCommands.bind(this);
        this.onCommand = this.onCommand.bind(this);
        this.onPageCommand = this.onPageCommand.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.venueId);
    }

    componentDidUpdate(prevProps) {
    }

    onReset() {
        this.props.onLoad(this.props.venueId);
    }

    onSave() {
        this.props.onSave();
    }

    onSelectedItemChanged(value, camera) {
        // console.log('onSelectedItemChanged', value, camera);
        let cameras = [...this.props.facialRecognitionCameras];
        cameras.forEach(c => {
            if (c.facialRecognitionCameraId === camera.facialRecognitionCameraId) {
                c.venueLocationId = value;
            }
        });
        this.props.onSet(cameras);
    }

    getCommands(camera: AusComplyDtos.FacialRecognitionCamera) {
        var commands: KeyName[] = [];
        if (!camera.obsolete) {
            if (this.props.canEdit) {
                commands.push({ key: "edit", name: "Edit" });
            }
            if (camera.cameraStatusDescription == "stopped" || camera.cameraStatusDescription == "idle") {
                commands.push({ key: "start", name: "Start" });
            } else if (camera.cameraStatusDescription == "active") {
                commands.push({ key: "stop", name: "Stop" });
            }
        } else if (camera.obsolete && this.props.canEdit) {
            commands.push({ key: "restore", name: "Restore" });
        }
        return commands;
    }

    onCommand(command: string, camera: AusComplyDtos.FacialRecognitionCamera) {
        switch (command) {
            case "edit":
                this.props.onSetCamera({ ...camera });
                break;
            case "start":
                this.props.onSaveCameraStatus(camera.facialRecognitionCameraId, AusComplyDtos.ngtCameraStatus.Running, this.props.venueId);
                break;
            case "stop":
                this.props.onSaveCameraStatus(camera.facialRecognitionCameraId, AusComplyDtos.ngtCameraStatus.Stopped, this.props.venueId);
                break;
            case "restore":
                this.props.onSetCamera({ ...camera, obsolete: false });
                break;
            default:
                break;
        }

    }

    onPageCommand(command: string) {
        switch (command) {
            case "sync":
                this.props.onSyncCameras(this.props.venueId);
                break;
            case "refresh":
                this.onReset();
                break;
            default:
                break;
        }
    }

    onCancel() {
        //this.props.onLoad(this.props.venueId);
        this.props.history.push('/admin/venues');
    }

    render() {

        let self = this;
        let cameras;
        if (!this.props.isLoading && !this.props.isSaving && this.props.facialRecognitionCameras && this.props.venueLocations) {
            cameras = <ResponsiveList
                loading={this.props.isLoading || this.props.isSaving}
                data={this.props.facialRecognitionCameras}
                headers={["Name", "Venue Location", "Server", "Watchlists", "Event Watchlists", "Sync Status", "Camera Status"]}
                columns={["displayName", "venueLocation.name", "facialRecognitionServer.name", "watchlists", "eventWatchlists", "syncStatus", "cameraStatusDescription"]}
                getCommands={item => this.getCommands(item)}
                onCommand={this.onCommand}
                isDanger={item => item.obsolete}
                canEdit={this.props.canEdit}
                toggleDanger={true}
            />;
        }

        let pageCommands: KeyName[] = [];
        let createFab;
        let createSyncFab;
        pageCommands.push({ key: "refresh", name: "Refresh" });
        if (this.props.canEdit) {
            pageCommands.push({ key: "sync", name: "Sync Cameras" });
            createSyncFab = (
                <FacialRecognitionCameraImportContainer venueId={this.props.venueId} buttonMode={true} />
            );

            createFab = (
                <DefaultFab onClick={() => self.props.onSyncCameras(self.props.venueId)} isExtended={true}>Sync Cameras</DefaultFab>
            );
        }

        let camera;
        let showCamera = false;
        if (this.props.facialRecognitionCamera &&
            this.props.facialRecognitionCamera.facialRecognitionCameraId > -1) {
            camera = <LayoutForm saving={this.props.isSaving}>
                <TextAreaControlGroup
                    text="Name"
                    onChange={(value) => self.props.onSetCamera({ ...self.props.facialRecognitionCamera, displayName: value })}
                    defaultValue={self.props.facialRecognitionCamera.displayName}>
                </TextAreaControlGroup>
                <SelectControlGroup
                    text="Venue Server"
                    onChange={(value) => self.props.onSetCamera({ ...self.props.facialRecognitionCamera, facialRecognitionServerId: value })}
                    defaultValue={self.props.facialRecognitionCamera.facialRecognitionServerId}>
                    <option value={0} />
                    {this.props.facialRecognitionServers.map((facialRecognitionServer, index) => (
                        <option key={facialRecognitionServer.facialRecognitionServerId} value={facialRecognitionServer.facialRecognitionServerId}>{facialRecognitionServer.name}</option>
                    ))}
                </SelectControlGroup>
                <SelectControlGroup
                    text="Venue Location"
                    onChange={(value) => self.props.onSetCamera({ ...self.props.facialRecognitionCamera, venueLocationId: value })}
                    defaultValue={self.props.facialRecognitionCamera.venueLocationId}>
                    <option value={0} />
                    {this.props.venueLocations.map((venueLocation, index) => (
                        <option key={venueLocation.venueLocationId} value={venueLocation.venueLocationId}>{venueLocation.name}</option>
                    ))}
                </SelectControlGroup>
                <FacialRecognitionCameraWatchlists
                    facialRecognitionCamera={this.props.facialRecognitionCamera}
                    facialRecognitionWatchlists={this.props.facialRecognitionWatchlists}
                    onUpdate={updated => self.props.onSetCamera(updated)}
                />
                {this.props.facialRecognitionCamera.facialRecognitionCameraId > 0 && (
                    <>
                        <TextDisplayControlGroup text="Event Watchlists" value={this.props.facialRecognitionCamera.eventWatchlists} />
                        <TextDisplayControlGroup text="Sync Status" value={this.props.facialRecognitionCamera.syncStatus} error={this.props.facialRecognitionCamera.syncError} />
                        <TextDisplayControlGroup text="ID" value={this.props.facialRecognitionCamera.cameraGuid} />
                        <TextDisplayControlGroup text="Camera Status" value={this.props.facialRecognitionCamera.cameraStatusDescription} />
                        <CheckboxControlGroup
                            label={" "}
                            text={"Active"}
                            isReversed={true}
                            defaultValue={this.props.facialRecognitionCamera.obsolete}
                            onChanged={(value) => self.props.onSetCamera({ ...self.props.facialRecognitionCamera, obsolete: value })} />
                    </>
                )}
                {this.props.isLevelSix && <>
                    <ControlGroup text="Camera Configuration">
                        {!this.state.showConfiguration ?
                            <>
                                <div>
                                    <TransparentButton text={"Show Configuration"} onClick={() => self.setState({ showConfiguration: true })} />
                                </div>
                            </>
                            :
                            <>
                                <div>
                                    <TransparentButton text={"Hide Configuration"} onClick={() => self.setState({ showConfiguration: false })} />
                                </div>
                                <Spacer />
                                <pre id="json" style={this.props.theme.custom.textDisplay}>
                                    <code>
                                        {JSON.stringify(JSON.parse(this.props.facialRecognitionCamera.cameraConfiguration), null, 4)}
                                    </code>
                                </pre>
                            </>}

                    </ControlGroup>
                    <Spacer />
                </>}

            </LayoutForm>;
            showCamera = true;
        }

        if (this.props.venueMode) {
            return <PageLayout
                headerText="Facial Recognition"
                loading={this.props.isLoading}
                saving={this.props.isSaving}
                footerLeftContent={createSyncFab}
                moreCommands={pageCommands}
                onCommand={this.onPageCommand}
            >
                <VenueFacialRecognitionNotificationsTabsContainer selectedTab={"Cameras"} history={this.props.history} />
                {cameras}
                <FullPageDialog open={showCamera} title="Camera" onDismissed={() => self.props.onResetCamera()}>
                    {camera}
                </FullPageDialog>
            </PageLayout>
        }

        return (
            <PageLayout
                headerText={"Venue"}
                loading={this.props.isLoading}
                saving={this.props.isSaving}
                footerLeftContent={<TransparentButton text={"Cancel"} onClick={this.onCancel} />}
                footerCenterContent={createSyncFab}
                moreCommands={pageCommands}
                onCommand={this.onPageCommand}
            >
                <VenueTabs venueId={this.props.venueId} selectedTab={"Facial Recognition"} history={this.props.history} />
                <FacialRecognitionTabs venueId={this.props.venueId} selectedTab={"Cameras"} history={this.props.history} />
                <LayoutForm
                    loading={this.props.isLoading}
                    saving={this.props.isSaving}
                    fullWidth={true}
                >
                    {cameras}
                </LayoutForm>
                <FullPageDialog 
                    open={showCamera} 
                    title="Camera" 
                    footerLeftContent={<PrimaryButton text={"Save"} onClick={() => self.props.onSaveCamera()}></PrimaryButton>}
                    footerRightContent={<DefaultButton text={"Cancel"} onClick={() => self.props.onResetCamera()}></DefaultButton>}
                    onDismissed={() => self.props.onResetCamera()}>
                    {camera}
                </FullPageDialog>
            </PageLayout>
        );
    }
}

export default withStyles(styles, { withTheme: true })(FacialRecognitionCameras);