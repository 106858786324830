import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import ResponsiveList from '../../common/ResponsiveList';
import FabContainer from '../../layout/FabContainer';
import FullPageDialog from '../../layout/FullPageDialog';
import DefaultFab from '../../common/DefaultFab';
import PrimaryButton from '../../controls/PrimaryButton';
import DefaultButton from '../../controls/DefaultButton';
import LayoutForm from '../../layout/LayoutForm';
import TextControlGroup from '../../controls/TextControlGroup';
import ControlGroup from '../../controls/ControlGroup';
import SelectControlGroup from '../../controls/SelectControlGroup';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import TextDisplayControlGroup from '../../controls/TextDisplayControlGroup';
import { SketchPicker, CompactPicker } from 'react-color';
import { KeyName } from '../../../common/dto/common';
import FacialRecognitionWatchlistPoisContainer from '../../../containers/FacialRecognitionWatchlistPoisContainer';
import FacialRecognitionPOIVenueRegistrationContainer from '../../../containers/FacialRecognitionPOIVenueRegistrationContainer';
import FacialRecognitionWatchlistImportContainer from '../../../containers/FacialRecognitionWatchlistImportContainer';
import FacialRecognitionWatchlistMigrationContainer from '../../../containers/FacialRecognitionWatchlistMigrationContainer';
import PageLayout from '../../layout/PageLayout';
import VenueFacialRecognitionNotificationsTabsContainer from '../../../containers/VenueFacialRecognitionNotificationsTabsContainer';
import TransparentButton from '../../controls/TransparentButton';
import VenueTabs from '../../admin/venue/VenueTabs';
import FacialRecognitionTabs from '../../admin/venue/facialRecognition/FacialRecognitionTabs';
import FacialRecognitionWatchlistMoveContainer from '../../../containers/FacialRecognitionWatchlistMoveContainer';
import { unescapeLeadingUnderscores } from 'typescript';


export interface IFacialRecognitionWatchlistsProps {
    venueId: number;
    groupId: number;
    venueMode?: boolean;
    groupMode?: boolean;
    groupName?: string;
    backButton?: any;
    tabs?: any;
    thumbnails: boolean;
    adminMode?: boolean;
    facialRecognitionWatchlists: AusComplyDtos.FacialRecognitionWatchlist[];
    facialRecognitionWatchlist: AusComplyDtos.FacialRecognitionWatchlist;
    facialRecognitionWatchlistId: number;
    facialRecognitionWatchlistTypes: AusComplyDtos.FacialRecognitionWatchlistType[];
    facialRecognitionServers: AusComplyDtos.FacialRecognitionServer[];
    isLoading: boolean;
    isLoadingItem: boolean;
    isSaving: boolean;
    onLoad: Function;
    onLoadItem: Function;
    onCreate: Function;
    onResetItem: Function;
    onUpdate: Function;
    onSave: Function;
    onCancel: Function;
    onSync: Function;
    onLoadPois: Function;
    onMove: Function;
    onMigrate: Function;
    canMigrate: boolean;
    canMaintainGroupWatchlists: boolean;
    onProcess?: Function;
    history: any;
    location: any;
}

interface IFacialRecognitionWatchlistsState {
}

export default class FacialRecognitionWatchlists extends Component<IFacialRecognitionWatchlistsProps, IFacialRecognitionWatchlistsState> {
    constructor(props: IFacialRecognitionWatchlistsProps) {
        super(props)
        this.state = {
        };
        this.onCommand = this.onCommand.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
        this.getCommands = this.getCommands.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.venueId, this.props.groupId);
    }

    onValueChanged(fieldName: string, value: any) {
        let facialRecognitionWatchlist = { ...this.props.facialRecognitionWatchlist };
        facialRecognitionWatchlist[fieldName] = value;
        this.props.onUpdate(facialRecognitionWatchlist)
    }

    getCommands(facialRecognitionWatchlist: AusComplyDtos.FacialRecognitionWatchlist) {
        var commands: KeyName[] = [];
        if (facialRecognitionWatchlist.venueId == this.props.venueId && facialRecognitionWatchlist.groupId == this.props.groupId) {
            if (!facialRecognitionWatchlist.obsolete) {
                commands.push({ key: "edit", name: "Edit" });
                commands.push({ key: "delete", name: "Remove" });
                if (facialRecognitionWatchlist.watchlistGuid != '') {
                    commands.push({ key: "pois", name: "Show POIs" });
                }
                if (this.props.canMigrate) {
                    commands.push({ key: "migrate", name: "Migrate to a different server" });
                }
                if (facialRecognitionWatchlist.canMigrateToGroup && this.props.adminMode) {
                    commands.push({ key: "move", name: "Move to a group" });
                }
            } else {
                commands.push({ key: "restore", name: "Restore" });
            }
        } else if (this.props.venueId > 0 && facialRecognitionWatchlist.venueId == 0 && facialRecognitionWatchlist.groupId > 0) {
            // Group watchlist viewed from venue
            if (!facialRecognitionWatchlist.obsolete) {
                if (facialRecognitionWatchlist.watchlistGuid != '') {
                    commands.push({ key: "pois", name: "Show POIs" });
                }
            }
        }
        return commands;
    }

    onCommand(command: string, facialRecognitionWatchlist: AusComplyDtos.FacialRecognitionWatchlist) {
        switch (command) {
            case "add":
                this.props.onCreate(this.props.venueId, this.props.groupId);
                break;
            case "edit":
                this.props.onLoadItem(facialRecognitionWatchlist.facialRecognitionWatchlistId);
                break;
            case "restore":
                var updated = { ...facialRecognitionWatchlist };
                updated.obsolete = false;
                this.props.onUpdate(updated);
                this.props.onSave();
                break;
            case "delete":
                var updated = { ...facialRecognitionWatchlist };
                updated.obsolete = true;
                this.props.onUpdate(updated);
                this.props.onSave();
                break;
            case "pois":
                let filter = {venueId: this.props.venueId, facialRecognitionWatchlistId: facialRecognitionWatchlist.facialRecognitionWatchlistId};
                this.props.onLoadPois(this.props.thumbnails ? 50 : 20, filter);
                break;
            case "migrate":
                this.props.onMigrate(facialRecognitionWatchlist.venueId, facialRecognitionWatchlist.facialRecognitionWatchlistId);
                break;
            case "move":
                this.props.onMove(facialRecognitionWatchlist.venueId, facialRecognitionWatchlist.facialRecognitionWatchlistId);
                break;
            default:
                break;
        }

    }

    onCancel() {
        this.props.history.push('/admin/venues');
    }

    render() {
        let self = this;
        let pageCommands: KeyName[] = [];
        let facialRecognitionWatchlist;
        if (this.props.facialRecognitionWatchlistId != -1) {

            var obsolete = false;
            if (this.props.facialRecognitionWatchlist && this.props.facialRecognitionWatchlist.obsolete != undefined) {
                obsolete = this.props.facialRecognitionWatchlist.obsolete;
            }

            let isExported = false;
            if (this.props.facialRecognitionWatchlist.watchlistGuid && this.props.facialRecognitionWatchlist.watchlistGuid != '') {
                isExported = true;
            }

            facialRecognitionWatchlist = (
                <LayoutForm
                    loading={this.props.isLoadingItem}
                    saving={this.props.isSaving}
                >
                    {this.props.facialRecognitionWatchlist && (
                        <form>
                            <TextControlGroup
                                text={"Name"}
                                defaultValue={this.props.facialRecognitionWatchlist.displayName}
                                onBlur={(value) => this.onValueChanged("displayName", value)} />

                            <SelectControlGroup
                                text="Type"
                                onChange={(value) => this.onValueChanged("watchlistType", value)}
                                defaultValue={self.props.facialRecognitionWatchlist.watchlistType}>
                                <option key={"blacklist"} value={"blacklist"}>Blacklist</option>
                                <option key={"whitelist"} value={"whitelist"}>Whitelist</option>
                            </SelectControlGroup>

                            <ControlGroup text="Display Color">
                                <CompactPicker
                                    color={self.props.facialRecognitionWatchlist.displayColor}
                                    onChangeComplete={(color) => this.onValueChanged("displayColor", color.hex)}
                                />
                            </ControlGroup>

                            <SelectControlGroup
                                text="Server"
                                readonly={isExported}
                                onChange={(value) => this.onValueChanged("facialRecognitionServerId", value)}
                                defaultValue={self.props.facialRecognitionWatchlist.facialRecognitionServerId}>
                                <option value={0} />
                                {this.props.facialRecognitionServers.filter(f => (
                                    f.serverType != AusComplyDtos.ngtServerType.Edge &&
                                    !f.obsolete) || f.facialRecognitionServerId == self.props.facialRecognitionWatchlist.facialRecognitionServerId).map((facialRecognitionServer, index) => (
                                        <option key={facialRecognitionServer.facialRecognitionServerId} value={facialRecognitionServer.facialRecognitionServerId}>{facialRecognitionServer.name}</option>
                                    ))}
                            </SelectControlGroup>

                            <SelectControlGroup
                                text="Watchlist Type"
                                onChange={(value) => this.onValueChanged("facialRecognitionWatchlistTypeId", value)}
                                defaultValue={self.props.facialRecognitionWatchlist.facialRecognitionWatchlistTypeId}>
                                <option value={0} />
                                {this.props.facialRecognitionWatchlistTypes.map((facialRecognitionWatchlistType, index) => (
                                    <option key={facialRecognitionWatchlistType.facialRecognitionWatchlistTypeId} value={facialRecognitionWatchlistType.facialRecognitionWatchlistTypeId}>{facialRecognitionWatchlistType.name}</option>
                                ))}
                            </SelectControlGroup>

                            {false && (
                                <SelectControlGroup
                                    text="Visibility"
                                    onChange={(value) => this.onValueChanged("visibility", value)}
                                    defaultValue={self.props.facialRecognitionWatchlist.visibility}>
                                    <option key={AusComplyDtos.ngtVisibility.Private} value={AusComplyDtos.ngtVisibility.Private}>Private</option>
                                    <option key={AusComplyDtos.ngtVisibility.Public} value={AusComplyDtos.ngtVisibility.Public}>Public</option>
                                </SelectControlGroup>
                            )}

                            {this.props.facialRecognitionWatchlist.groupId > 0 && (
                                <CheckboxControlGroup
                                    label={" "}
                                    text={"Hide from Venues"}
                                    isReversed={false}
                                    defaultValue={this.props.facialRecognitionWatchlist.hideFromVenues}
                                    onChanged={(value) => this.onValueChanged("hideFromVenues", value)} />
                            )}


                            {this.props.facialRecognitionWatchlist.facialRecognitionWatchlistId > 0 && (
                                <>
                                    <TextDisplayControlGroup text="Sync Status" value={this.props.facialRecognitionWatchlist.syncStatus} error={this.props.facialRecognitionWatchlist.syncError} />
                                    <TextDisplayControlGroup text="ID" value={this.props.facialRecognitionWatchlist.watchlistGuid} />
                                    <CheckboxControlGroup
                                        label={" "}
                                        text={"Active"}
                                        isReversed={true}
                                        defaultValue={obsolete}
                                        onChanged={(value) => this.onValueChanged("obsolete", value)} />
                                </>
                            )}

                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <PrimaryButton text={"Save"} onClick={() => this.props.onSave()}></PrimaryButton>
                                </Grid>
                                <Grid item xs={6}>
                                    <DefaultButton text={"Cancel"} onClick={() => this.props.onCancel()}></DefaultButton>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </LayoutForm >
            );
        }

        let createFab;
        let create;
        let createSyncFab;
        let addPoiFab;
        let processFab;
        if (this.props.venueId != 0 || this.props.groupId != 0) {
            createFab = (
                <DefaultFab onClick={() => self.props.onCreate(this.props.venueId, this.props.groupId)} isExtended={true}>Add Watchlist</DefaultFab>
            );
            create = <TransparentButton text="Add Watchlist" onClick={() => self.props.onCreate(this.props.venueId, this.props.groupId)} />

            //createSyncFab = (
            //    <FacialRecognitionWatchlistImportContainer venueId={this.props.venueId} groupId={this.props.groupId} buttonMode={this.props.venueMode || this.props.groupMode || this.props.adminMode} />
            //);

            pageCommands.push({ key: "", name: "" });
            addPoiFab = (
                <FacialRecognitionPOIVenueRegistrationContainer incidentId={0} venueId={this.props.venueId} groupId={this.props.groupId} buttonMode={this.props.venueMode || this.props.groupMode || this.props.adminMode} facialRecognitionWatchlistId={0} poiGuid="" />
            );
        }

        let footerOffset = "0";
        if (this.props.groupId > 0 || this.props.venueId > 0) {
            footerOffset = '120px';
        }

        if (this.props.venueMode) {
            return <PageLayout
                headerText="Facial Recognition"
                loading={this.props.isLoading}
                saving={this.props.isSaving}
                footerLeftContent={create}
                footerCenterContent={createSyncFab}
                footerRightContent={addPoiFab}
            >
                <VenueFacialRecognitionNotificationsTabsContainer selectedTab={"Watchlists"} history={this.props.history} />
                <ResponsiveList
                    loading={this.props.isLoading || this.props.isSaving}
                    data={this.props.facialRecognitionWatchlists}
                    headers={["Name", "Server", "Watchlist Id", "Sync Status", "Error"]}
                    columns={["displayName", "facialRecognitionServer.name", "watchlistGuid", "syncStatus", "syncError"]}
                    getCommands={item => this.getCommands(item)}
                    onCommand={(command, item) => this.onCommand(command, item)}
                    isDanger={item => item.obsolete}
                    isWarning={item => item.syncStatus == AusComplyDtos.ngtSyncStatus.Error || item.syncStatus == AusComplyDtos.ngtSyncStatus.ErrorMigration}
                    isInfo={item => item.venueId == 0 && item.groupId == 0}
                    isInfoLabel={"Global"}
                    isSuccess={item => item.venueId == 0 && item.groupId > 0}
                    isSuccessLabel={"Group"}
                    canEdit={true}
                    toggleDanger={true}
                />
                <FullPageDialog open={this.props.facialRecognitionWatchlistId != -1} title="Watchlist" onDismissed={() => this.props.onResetItem()}>
                    {facialRecognitionWatchlist}
                </FullPageDialog>
                <FacialRecognitionWatchlistPoisContainer venueId={this.props.venueId} />
                <FacialRecognitionWatchlistMigrationContainer />
            </PageLayout>
        }

        if (this.props.groupMode) {
            if (!this.props.canMaintainGroupWatchlists) {
                create = undefined;
                addPoiFab = undefined;
            }
            return <PageLayout
                headerText={this.props.groupName}
                loading={this.props.isLoading}
                saving={this.props.isSaving}
                footerLeftContent={this.props.backButton ? this.props.backButton : create}
                footerCenterContent={this.props.backButton ? create : undefined}
                footerRightContent={addPoiFab}
            >
                {this.props.tabs}
                <ResponsiveList
                    loading={this.props.isLoading || this.props.isSaving}
                    data={this.props.facialRecognitionWatchlists}
                    headers={["Name", "Server", "Watchlist Id", "Sync Status", "Error"]}
                    columns={["displayName", "facialRecognitionServer.name", "watchlistGuid", "syncStatus", "syncError"]}
                    getCommands={item => this.getCommands(item)}
                    onCommand={(command, item) => this.onCommand(command, item)}
                    isDanger={item => item.obsolete}
                    isWarning={item => item.syncStatus == AusComplyDtos.ngtSyncStatus.Error || item.syncStatus == AusComplyDtos.ngtSyncStatus.ErrorMigration}
                    isInfo={item => item.venueId == 0 && item.groupId == 0}
                    isInfoLabel={"Global"}
                    isSuccess={item => item.venueId == 0 && item.groupId > 0}
                    isSuccessLabel={"Group"}
                    canEdit={true}
                    toggleDanger={true}
                />
                <FullPageDialog open={this.props.facialRecognitionWatchlistId != -1} title="Watchlist" onDismissed={() => this.props.onResetItem()}>
                    {facialRecognitionWatchlist}
                </FullPageDialog>
                <FacialRecognitionWatchlistPoisContainer venueId={this.props.venueId} groupId={this.props.groupId} />
                <FacialRecognitionWatchlistMigrationContainer />
            </PageLayout>
        }

        if (this.props.adminMode) {
            return <PageLayout
                headerText="Venue"
                loading={this.props.isLoading}
                saving={this.props.isSaving}
                footerLeftContent={create}
                footerCenterContent={createSyncFab}
                footerRightContent={addPoiFab}
            >
                <VenueTabs venueId={this.props.venueId} selectedTab={"Facial Recognition"} history={this.props.history} />
                <FacialRecognitionTabs venueId={this.props.venueId} selectedTab={"Watchlists"} history={this.props.history} />
                <ResponsiveList
                    loading={this.props.isLoading || this.props.isSaving}
                    data={this.props.facialRecognitionWatchlists}
                    headers={["Name", "Server", "Watchlist Id", "Sync Status", "Error"]}
                    columns={["displayName", "facialRecognitionServer.name", "watchlistGuid", "syncStatus", "syncError"]}
                    getCommands={item => this.getCommands(item)}
                    onCommand={(command, item) => this.onCommand(command, item)}
                    isDanger={item => item.obsolete}
                    isWarning={item => item.syncStatus == AusComplyDtos.ngtSyncStatus.Error || item.syncStatus == AusComplyDtos.ngtSyncStatus.ErrorMigration}
                    isInfo={item => item.venueId == 0 && item.groupId == 0}
                    isInfoLabel={"Global"}
                    isSuccess={item => item.venueId == 0 && item.groupId > 0}
                    isSuccessLabel={"Group"}
                    canEdit={true}
                    toggleDanger={true}
                />
                <FullPageDialog open={this.props.facialRecognitionWatchlistId != -1} title="Watchlist" onDismissed={() => this.props.onResetItem()}>
                    {facialRecognitionWatchlist}
                </FullPageDialog>
                <FacialRecognitionWatchlistPoisContainer venueId={this.props.venueId} />
                <FacialRecognitionWatchlistMigrationContainer />
                <FacialRecognitionWatchlistMoveContainer />
            </PageLayout>
        }

        return (
            <>
                <ResponsiveList
                    loading={this.props.isLoading || this.props.isSaving}
                    data={this.props.facialRecognitionWatchlists}
                    headers={["Name", "Server", "Watchlist Id", "Sync Status", "Error"]}
                    columns={["displayName", "facialRecognitionServer.name", "watchlistGuid", "syncStatus", "syncError"]}
                    getCommands={item => this.getCommands(item)}
                    onCommand={(command, item) => this.onCommand(command, item)}
                    isDanger={item => item.obsolete}
                    isWarning={item => item.syncStatus == AusComplyDtos.ngtSyncStatus.Error || item.syncStatus == AusComplyDtos.ngtSyncStatus.ErrorMigration}
                    isInfo={item => item.venueId == 0 && item.groupId == 0}
                    isInfoLabel={"Global"}
                    isSuccess={item => item.venueId == 0 && item.groupId > 0}
                    isSuccessLabel={"Group"}
                    canEdit={true}
                    toggleDanger={true}
                />
                <FabContainer style={{ right: footerOffset, bottom: '3px' }}>
                    {processFab}
                    {addPoiFab}
                    {createSyncFab}
                    {createFab}
                </FabContainer>
                <FullPageDialog open={this.props.facialRecognitionWatchlistId != -1} title="Watchlist" onDismissed={() => this.props.onResetItem()}>
                    {facialRecognitionWatchlist}
                </FullPageDialog>
                <FacialRecognitionWatchlistPoisContainer venueId={this.props.venueId} />
                <FacialRecognitionWatchlistMigrationContainer />
            </>
        );

    }
}