import update from "immutability-helper";
import * as AusComplyDtos from "../dto/AusComply.dtos";
import {
    INCIDENT_FIND_RESET,
    INCIDENT_FIND_REQUEST,
    INCIDENT_FIND_REQUEST_FAILURE,
    INCIDENT_FIND_REQUEST_SUCCESS,
    INCIDENT_FIND_REFRESH_REQUEST,
    INCIDENT_FIND_FILTER_REQUEST,
    INCIDENT_FIND_FILTER_REQUEST_FAILURE,
    INCIDENT_FIND_FILTER_REQUEST_SUCCESS
} from "../actions/incidentFind";
import {
    INCIDENTS_RESET
} from "../actions/incidents";

const initialState = {
    filter: {},
    paging: {},
    items: [],
    isLoading: false,
    error: ""
};

function setLoading(state, isLoading) {
    let newState = update(state, {
        isLoading: { $set: isLoading }
    });
    return newState;
}

function setInitialFilter(state, filter: AusComplyDtos.IncidentHistoryFilter){
    // select all the incident types
    filter.incidentTypes.forEach(t => t.selected = true);

    let newState = update(state, {
        filter: { $set: filter }
    });
    return newState;
}

function setFilter(state, filter) {
    
    let newState = update(state, {
        filter: { $set: filter }
    });
    return newState;
}

function setIncidentItems(state, items) {
    let newState = update(state, {
        items: { $set: items }
    });
    return newState;
}

function setIncidents(state, items, paging) {
    let newState = update(state, {
        items: { $set: items },
        paging: { $set: paging }
    });
    return newState;
}

export default function incidentFind(state = initialState, action) {
    switch (action.type) {
        case INCIDENTS_RESET:
            return {...initialState};
        case INCIDENT_FIND_RESET:
            return {...initialState};
        case INCIDENT_FIND_REQUEST:
            return setLoading(setIncidentItems(state, []), true);
        case INCIDENT_FIND_REQUEST_FAILURE:
            return setLoading(state, false);
        case INCIDENT_FIND_REQUEST_SUCCESS:
            return setLoading(setIncidents(setFilter(state, action.incidentFilter), action.incidents, action.paging), false);
        case INCIDENT_FIND_REFRESH_REQUEST:
            return setLoading(state, true);
        case INCIDENT_FIND_FILTER_REQUEST:
            return setLoading(state, true);
        case INCIDENT_FIND_FILTER_REQUEST_FAILURE:
            return setLoading(state, false);
        case INCIDENT_FIND_FILTER_REQUEST_SUCCESS:
            return setLoading(setInitialFilter(state, action.incidentFilter), false);
    }
    return state;
}
