import React, { Component } from 'react';
import { Incident as IncidentDto, Paging as PagingDto, IncidentHistoryFilter } from "../../common/dto/AusComply.dtos";
import View from '../common/View';
import PagedList from '../common/PagedList';
import IncidentCard from './IncidentCard';
import { KeyName } from '../../common/dto/common';
import IncidentLegend from './IncidentLegend';
import IncidentHeader from './IncidentHeader';
import IncidentRow from './IncidentRow';
import TableContainer from '../layout/TableContainer';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import SecondaryFab from '../common/SecondaryFab';
import IncidentsPrintContainer from '../../containers/IncidentsPrintContainer';
import DefaultFab from '../common/DefaultFab';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FullPageDialog from '../layout/FullPageDialog';
import IncidentsFilter from './IncidentsFilter';
import IncidentsFilterSummary from './IncidentsFilterSummary';
import PageLayout from '../layout/PageLayout';
import TransparentButton from '../controls/TransparentButton';
import PrimaryButton from '../controls/PrimaryButton';
import IncidentPhotoCard from './IncidentPhotoCard';
import Time24HourSelectorControlGroup from '../controls/Time24HourSelectorControlGroup';

export interface IIncidentsProps {
    isLoading: boolean;
    isApproving: boolean;
    items: IncidentDto[];
    paging: PagingDto;
    filter: IncidentHistoryFilter;
    initialFilterLoaded: boolean;
    onRefresh: Function;
    onResetFilter: Function;
    onIncidentsSelectForApproval: Function;
    onIncidentsSelectAllForApproval: Function;
    onIncidentsApproveSelectedRequest: Function;
    onIncidentClear: Function;
    onErrorNotification: Function;
    onPrint: Function;
    showEvents: boolean;
    canViewUniqueIncidentID: boolean;
    history: any;
    classes: any;
    theme: any;
}

interface IIncidentsState {
    filter: IncidentHistoryFilter;
    filterExpanded: boolean;
    showPrint: boolean;
    selectedCount: number;
}

class Incidents extends Component<IIncidentsProps, IIncidentsState> {
    constructor(props: IIncidentsProps) {
        super(props)

        this.state = {
            filter: new IncidentHistoryFilter(),
            selectedCount: 0,
            filterExpanded: false,
            showPrint: true
        };

        this.onCommand = this.onCommand.bind(this);
        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.handlePageSizeSelected = this.handlePageSizeSelected.bind(this);
        this.handleSelectedClicked = this.handleSelectedClicked.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onResetSearch = this.onResetSearch.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.handlePrintDismissed = this.handlePrintDismissed.bind(this);
        this.onError = this.onError.bind(this);
        this.filterExpanded = this.filterExpanded.bind(this);
        this.onUpdated = this.onUpdated.bind(this);
    }

    componentDidMount() {
        if (!this.props.isLoading) {
            let forEntryPage = "";
            if (this.props.filter.forEntryPage != null && this.props.filter.forEntryPage !== undefined) {
                forEntryPage = this.props.filter.forEntryPage;
            }
            if (!this.props.initialFilterLoaded || !this.props.filter || forEntryPage !== "") {
                this.props.onResetFilter("");
            } else {
                this.setState({ filter: this.props.filter });
                this.props.onRefresh(this.props.filter);
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isLoading && this.props.filter !== prevProps.filter) {
            this.setState({ filter: this.props.filter });
        }
        // update the selected count
        if (!this.props.isLoading && this.props.items) {
            let selectedCount = 0;
            this.props.items.forEach(incident => {
                if (incident.selected) {
                    selectedCount++;
                }
            });
            if (selectedCount !== this.state.selectedCount) {
                this.setState({
                    selectedCount
                });
            }
        }

    }

    onCommand(command) {
        switch (command) {
            case "refresh":
                this.onSearch();
                break;
            case "clearselection":
                this.props.onIncidentsSelectAllForApproval(false);
                break;
            case "selectall":
                this.props.onIncidentsSelectAllForApproval(true);
                break;
            case "approve":
                this.props.onIncidentsApproveSelectedRequest();
                break;
            case "print":
                //this.setState({showPrint: true});
                this.props.onPrint();
                break;

        }
    }

    handlePageSelected(page) {
        let filter = { ...this.state.filter };
        if (filter.paging) {
            filter.paging.page = page;
        }
        this.props.onRefresh(filter);
    }

    handlePageSizeSelected(page, pageSize) {
        let filter = { ...this.state.filter };
        if (filter.paging) {
            filter.paging.page = page;
            filter.paging.pageSize = pageSize;
        }
        this.props.onRefresh(filter);
    }

    handleSelectedClicked(incident: IncidentDto) {
        if (this.props.onIncidentsSelectForApproval) {
            this.props.onIncidentsSelectForApproval(incident.incidentId, incident.selected);
        }
    }

    handlePrintDismissed() {
        this.setState({ showPrint: false });
    }

    onSearch() {
        let filter = { ...this.state.filter };
        if (filter.paging) {
            filter.paging.page = 1;
        }
        this.props.onRefresh(filter);
    }

    onResetSearch() {
        this.setState({
            filterExpanded: false
        }, () => {
            this.props.onResetFilter("");
        });
    }

    onEdit(incident: IncidentDto) {
        if (incident.completed && incident.viewAllowed) {
            this.props.onIncidentClear();
            this.props.history.push('/incidentview/' + incident.incidentId);

        } else if (!incident.completed && incident.editAllowed) {
            this.props.onIncidentClear();
            this.props.history.push('/incident/' + incident.incidentId);
        }
    }

    onError(error) {
        this.props.onErrorNotification(error);
    }

    onUpdated(filter) {
        if (filter) {
            if (filter.paging) {
                filter.paging.page = 1;
            }
            this.setState({ filter });
        }
    }

    filterExpanded(value: boolean) {
        if (this.state.filterExpanded && !value) {
            this.onSearch();
        }
        this.setState({
            filterExpanded: value
        })
    }

    render() {
        let paging = this.props.paging;
        let cardList;
        let rowList;
        let rows;

        let canApprove = false;
        let canEdit = false;
        let canView = false;
        let canPrint = false;
        let filter = this.props.filter != null ? this.state.filter : null;

        if (filter != null) {
            paging = this.props.paging;
            canApprove = filter.canApprove;
            canEdit = filter.canEdit;
            canView = filter.canView;
            canPrint = filter.canPrint;
        }

        if (this.props.items && this.props.items.length > 0) {
            if (this.props.filter.withPhoto) {
                cardList = this.props.items.map((item, index) => {
                    return (
                        <IncidentPhotoCard key={"card-" + item.incidentId}
                            incident={item}
                            canApprove={canApprove}
                            canEdit={canEdit && (item.viewAllowed || item.editAllowed)}
                            canView={canView && (item.viewAllowed || item.editAllowed)}
                            onEdit={this.onEdit}
                            showEvents={this.props.showEvents}
                            onSelected={this.handleSelectedClicked}
                        />
                    );
                });
            } else {
                cardList = this.props.items.map((item, index) => {
                    return (
                        <IncidentCard key={"card-" + item.incidentId}
                            incident={item}
                            canApprove={canApprove}
                            canEdit={canEdit && (item.viewAllowed || item.editAllowed)}
                            canView={canView && (item.viewAllowed || item.editAllowed)}
                            onEdit={this.onEdit}
                            onSelected={this.handleSelectedClicked}
                            showEvents={this.props.showEvents}
                            canViewUniqueIncidentID={this.props.canViewUniqueIncidentID}
                        />
                    );
                });
            }


            rows = this.props.items.map((item, index) => {
                return (
                    <IncidentRow
                        key={"row-" + item.incidentId.toString()}
                        incident={item}
                        canApprove={canApprove}
                        canEdit={canEdit && (item.viewAllowed || item.editAllowed)}
                        canView={canView && (item.viewAllowed || item.editAllowed)}
                        onEdit={this.onEdit}
                        showEvents={this.props.showEvents}
                        canViewUniqueIncidentID={this.props.canViewUniqueIncidentID}
                        onSelected={this.handleSelectedClicked} />
                );
            });
        }

        let moreCommands: KeyName[] = [];

        if (!this.props.isLoading && !this.props.isApproving) {
            moreCommands.push({ key: "refresh", name: "Refresh" });
            if (canApprove) {
                if (this.state.selectedCount > 0) {
                    moreCommands.push({ key: "clearselection", name: "Clear selections" });
                }
                else {
                    moreCommands.push({ key: "selectall", name: "Select all for approval" });
                }
            }
            if (canPrint) {
                moreCommands.push({ key: "print", name: "Print / Download" });
            }
        }

        rowList = (
            <TableContainer
                header={<IncidentHeader 
                    canApprove={canApprove} 
                    canEdit={canEdit} 
                    canView={canView}
                    canViewUniqueIncidentID={this.props.canViewUniqueIncidentID}
                    showEvents={this.props.showEvents} />}
                rows={rows}
            />
        );

        let incidentsFilter;
        let incidentsFilterSummary;
        if (this.state.filterExpanded) {
            incidentsFilter = <IncidentsFilter
                filter={this.state.filter}
                canViewUniqueIncidentID={this.props.canViewUniqueIncidentID}
                onUpdateFilter={filter => this.onUpdated(filter)}
                onSearch={() => this.filterExpanded(false)}
                onReset={() => this.onResetSearch()} />;

        } else if (this.props.isLoading) {
            let loadingFilter = new IncidentHistoryFilter();
            loadingFilter.display = "Searching...";
            incidentsFilterSummary = <View style={{ marginBottom: 10 }}>
                <IncidentsFilterSummary filter={loadingFilter} onClick={() => this.filterExpanded(true)} />
            </View>;
        } else if (this.state.filter.canFilterReportingPeriod || this.state.filter.canFilterAdvanced) {
            incidentsFilterSummary = <View style={{ marginBottom: 10 }}>
                <IncidentsFilterSummary filter={this.props.filter} onClick={() => this.filterExpanded(true)} />
            </View>;
        }

        let fabs;
        if (!this.props.isLoading && !this.props.isApproving) {
            fabs = (
                <>
                    {(this.state.selectedCount > 0 && !this.props.isApproving && !this.props.isLoading) && (
                        <SecondaryFab isExtended={true} onClick={() => this.props.onIncidentsApproveSelectedRequest()}>
                            Approve Selected
                        </SecondaryFab>
                    )}
                    <DefaultFab actions={moreCommands} onClick={this.onCommand}><MoreVertIcon style={{ marginTop: '6px' }} /></DefaultFab>
                </>
            );
        }
        let leftButton;
        if (this.state.selectedCount > 0 && !this.props.isApproving && !this.props.isLoading) {
            leftButton = <TransparentButton text={"Approve Selected"} onClick={() => this.props.onIncidentsApproveSelectedRequest()} />
        }
        let content;
        if (this.props.filter.withPhoto) {
            content = <PagedList
                loading={this.props.isLoading || this.props.isApproving}
                paging={paging}
                cards={<div style={{ textAlign: 'center' }}>{cardList}</div>}
                rows={<div style={{ textAlign: 'center' }}>{cardList}</div>}
                footer={<IncidentLegend />}
                label={"Incidents"}
                onPageSelected={i => this.handlePageSelected(i)}
                onPageSizeSelected={(page, pageSize) => this.handlePageSizeSelected(page, pageSize)}
            />
        } else {
            content = <PagedList
                loading={this.props.isLoading || this.props.isApproving}
                paging={paging}
                cards={cardList}
                rows={rowList}
                footer={<IncidentLegend />}
                label={"Incidents"}
                onPageSelected={i => this.handlePageSelected(i)}
                onPageSizeSelected={(page, pageSize) => this.handlePageSizeSelected(page, pageSize)}
            />
        }

        return <PageLayout
            headerText={"Incident Register"}
            footerLeftContent={leftButton}
            moreCommands={moreCommands}
            onCommand={this.onCommand}
        >
            {incidentsFilterSummary}
            {content}
            <IncidentsPrintContainer />
            <FullPageDialog
                notFullWidth={true}
                footerRightContent={<PrimaryButton text={"Search"} onClick={() => this.filterExpanded(false)} />}
                footerCenterContent={<TransparentButton text={"Reset"} onClick={() => this.onResetSearch()} />}
                open={this.state.filterExpanded}
                title="Search"
                onDismissed={() => this.filterExpanded(false)}>
                {incidentsFilter}
            </FullPageDialog>
        </PageLayout>
    }
}

export default withStyles(styles, { withTheme: true })(Incidents);