import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import HomeContainer from '../../containers/HomeContainer';
import IncidentsContainer from '../../containers/IncidentsContainer';
import ProfileContainer from '../../containers/ProfileContainer';
import TermsContainer from '../../containers/TermsContainer';
import PrivacyPolicyContainer from '../../containers/PrivacyPolicyContainer';
import SignOnContainer from '../../containers/SignOnContainer';
import SignOffContainer from '../../containers/SignOffContainer';
import SignOnExtendContainer from '../../containers/SignOnExtendContainer';
import DraftIncidentContainer from '../../containers/DraftIncidentContainer';
import DraftIDScanIncidentContainer from '../../containers/DraftIDScanIncidentContainer';
import DraftCommsLogIncidentContainer from '../../containers/DraftCommsLogIncidentContainer';
import IncidentContainer from '../../containers/IncidentContainer';
import IncidentViewContainer from '../../containers/IncidentViewContainer';
import VenueRoute from './VenueRoute';
import AdminRoute from './AdminRoute';
import SecurityFirmRoute from './SecurityFirmRoute';
import SignOnOffRegisterContainer from '../../containers/SignOnOffRegisterContainer';
import ProfileEmailConfirmationContainer from '../../containers/ProfileEmailConfirmationContainer';
import NotificationsContainer from '../../containers/NotificationsContainer';
import CheckListsContainer from '../../containers/ChecklistsContainer';
import CheckListContainer from '../../containers/CheckListContainer';
import IncidentVersionContainer from '../../containers/IncidentVersionContainer';
import IncidentVersionsContainer from '../../containers/IncidentVersionsContainer';
import LoggedInUsersContainer from '../../containers/LoggedInUsersContainer';
import UserRolesContainer from '../../containers/UserRolesContainer';
import UserRoleUserContainer from '../../containers/UserRoleUserContainer';
import Test from '../test/Test';
import RegisterVenueContainer from '../../containers/RegisterVenueContainer';
import RegisterVenueTerms from '../../components/register/venue/RegisterVenueTerms';
import RegsiterVenuePrivacyPolicy from '../../components/register/venue/RegsiterVenuePrivacyPolicy';
import RegisterVenueSuccess from '../../components/register/venue/RegisterVenueSuccess';
import RegisterSecurityFirmContainer from '../../containers/RegisterSecurityFirmContainer';
import RegisterSecurityFirmTerms from '../../components/register/securityFirm/RegisterSecurityFirmTerms';
import RegisterSecurityFirmPrivacyPolicy from '../../components/register/securityFirm/RegisterSecurityFirmPrivacyPolicy';
import RegisterSecurityFirmSuccess from '../../components/register/securityFirm/RegisterSecurityFirmSuccess';
import DashboardContainer from '../../containers/DashboardContainer';
import RegisterUserContainer from '../../containers/RegisterUserContainer';
import RegisterUserTerms from '../../components/register/profile/RegisterUserTerms';
import RegsiterUserPrivacyPolicy from '../../components/register/profile/RegsiterUserPrivacyPolicy';
import GroupContainer from '../../containers/GroupContainer';
import GroupChecklistTemplateContainer from '../../containers/GroupChecklistTemplateContainer';
import GroupIncidentActionTemplateContainer from '../../containers/GroupIncidentActionTemplateContainer';
import CorrectionalFacilityInmatesCellsContainer from '../../containers/CorrectionalFacilityInmatesCellsContainer';
import CorrectionalFacilityCreateInMateCellContainer from '../../containers/CorrectionalFacilityCreateInMateCellContainer';
import UserPermissionsContainer from '../../containers/UserPermissionsContainer';
import GroupUserContainer from '../../containers/GroupUserContainer';
import ProfileDeleteContainer from '../../containers/ProfileDeleteContainer';
import EditCommsLogIncidentContainer from '../../containers/EditCommsLogIncidentContainer';
import CommsLogsContainer from '../../containers/CommsLogsContainer';
import NotebookContainer from '../../containers/NotebookContainer';
import RosterContainer from '../../containers/RosterContainer';
import RoublerEmployeeMappingContainer from '../../containers/RoublerEmployeeMappingContainer';
import RGOIncidentsContainer from '../../containers/RGOIncidentsContainer';
import CashTraxIncidentsContainer from '../../containers/CashTraxIncidentsContainer';
import CashTraxIncidentContainer from '../../containers/CashTraxIncidentContainer';
import PlayTraxIncidentsContainer from '../../containers/PlayTraxIncidentsContainer';
import PlayTraxIncidentContainer from '../../containers/PlayTraxIncidentContainer';
import RollerIntegrationContainer from '../../containers/RollerIntegrationContainer';
import FacialRecognitionStatisticsContainer from '../../containers/FacialRecognitionStatisticsContainer';
import { withRouter } from "react-router";

import ReactGA from 'react-ga4';

export interface IBaseRoute {
    venueId?: number;
    securityFirmId?: number;
    profileisNotValid: boolean;
    venueIsSelected: boolean;
    requiresSecurityCode: boolean;
    pendingInvalidSLEDAccess: boolean;
}

class BaseRoute extends Component<any, any> {
    componentDidMount() {
        try {
            this.props.history.listen((location, action) => {
                // location is an object like window.location
                ReactGA.set({ page: location.pathname }); // Update the user's current page
                ReactGA.send({ hitType: "pageview", page: location.pathname });
            });
        } catch (error) {
            // console.log(error);
        }
    }

    render() {

        if (this.props.profileisNotValid) {
            // limited access
            return (
                <Switch>
                    <Route path='/profile/delete' component={ProfileDeleteContainer} />
                    <Route path='/profile' component={ProfileContainer} />
                    <Route path='/terms' component={TermsContainer} />
                    <Route path='/privacypolicy' component={PrivacyPolicyContainer} />
                    <Route path='/emailverification/:userId/:emailConfirmationGuid' component={ProfileEmailConfirmationContainer} />
                    <Route path='/admin' component={AdminRoute} />
                    <Route path='/test' component={Test} />
                    <Route path='/register/user/terms' component={RegisterUserTerms} />
                    <Route path='/register/user/privacypolicy' component={RegsiterUserPrivacyPolicy} />
                    <Route path='/register/user' component={RegisterUserContainer} />
                    <Route path='/register/venue/terms' component={RegisterVenueTerms} />
                    <Route path='/register/venue/privacypolicy' component={RegsiterVenuePrivacyPolicy} />
                    <Route path='/register/venue/success' component={RegisterVenueSuccess} />
                    <Route path='/register/venue' component={RegisterVenueContainer} />
                    <Route path='/register/securityFirm/terms' component={RegisterSecurityFirmTerms} />
                    <Route path='/register/securityFirm/privacypolicy' component={RegisterSecurityFirmPrivacyPolicy} />
                    <Route path='/register/securityFirm/success' component={RegisterSecurityFirmSuccess} />
                    <Route path='/register/securityFirm' component={RegisterSecurityFirmContainer} />
                    <Route path='/permissions' component={UserPermissionsContainer} />
                    <Redirect to={'/profile'} />
                </Switch>
            );
        }
        if (!this.props.venueIsSelected || this.props.requiresSecurityCode || this.props.pendingInvalidSLEDAccess) {
            // reduced options
            return (
                <Switch>
                    <Route path='/dashboard' component={DashboardContainer} />
                    <Route path='/roster' component={RosterContainer} />
                    <Route path='/notebook' component={NotebookContainer} />
                    <Route path='/notifications' component={NotificationsContainer} />
                    <Route path='/securityfirm' component={SecurityFirmRoute} />
                    <Route path='/loggedinusers' component={LoggedInUsersContainer} />
                    <Route path='/groups/:groupId/checklistTemplate/:checklistTemplateId' component={GroupChecklistTemplateContainer} />
                    <Route path='/groups/:groupId/incidentActionTemplate/:incidentActionTemplateId' component={GroupIncidentActionTemplateContainer} />
                    <Route path='/groups/:groupId/user/:userId' component={GroupUserContainer} />
                    <Route path='/groups/:groupId' component={GroupContainer} />
                    <Route path='/profile/delete' component={ProfileDeleteContainer} />
                    <Route path='/profile' component={ProfileContainer} />
                    <Route path='/terms' component={TermsContainer} />
                    <Route path='/privacypolicy' component={PrivacyPolicyContainer} />
                    <Route path='/emailverification/:userId/:emailConfirmationGuid' component={ProfileEmailConfirmationContainer} />
                    <Route path='/home' component={HomeContainer} />
                    <Route path='/checklists' component={CheckListsContainer} />
                    <Route path='/checklist/:checklistId' component={CheckListContainer} />
                    <Route path='/checklist' component={CheckListContainer} />
                    <Route path='/users' component={UserRolesContainer} />
                    <Route path='/user/:userId' component={UserRoleUserContainer} />
                    <Route path='/test' component={Test} />
                    <Route path='/admin' component={AdminRoute} />
                    <Route path='/register/user/terms' component={RegisterUserTerms} />
                    <Route path='/register/user/privacypolicy' component={RegsiterUserPrivacyPolicy} />
                    <Route path='/register/user' component={RegisterUserContainer} />
                    <Route path='/register/venue/terms' component={RegisterVenueTerms} />
                    <Route path='/register/venue/privacypolicy' component={RegsiterVenuePrivacyPolicy} />
                    <Route path='/register/venue/success' component={RegisterVenueSuccess} />
                    <Route path='/register/venue' component={RegisterVenueContainer} />
                    <Route path='/register/securityFirm/terms' component={RegisterSecurityFirmTerms} />
                    <Route path='/register/securityFirm/privacypolicy' component={RegisterSecurityFirmPrivacyPolicy} />
                    <Route path='/register/securityFirm/success' component={RegisterSecurityFirmSuccess} />
                    <Route path='/register/securityFirm' component={RegisterSecurityFirmContainer} />
                    <Route path='/permissions' component={UserPermissionsContainer} />
                    <Redirect to={'/home'} />
                </Switch>
            );
        }
        return (
            <Switch>
                <Route path='/profile/delete' component={ProfileDeleteContainer} />
                <Route path='/profile' component={ProfileContainer} />
                <Route path='/dashboard' component={DashboardContainer} />
                <Route path='/notebook' component={NotebookContainer} />
                <Route path='/roster' component={RosterContainer} />
                <Route path='/roubleremployeemapping' component={RoublerEmployeeMappingContainer} />
                <Route path='/rollerintegration' component={RollerIntegrationContainer} />
                <Route path='/venue' component={VenueRoute} />
                <Route path='/admin' component={AdminRoute} />
                <Route path='/securityfirm' component={SecurityFirmRoute} />
                <Route path='/loggedinusers' component={LoggedInUsersContainer} />
                <Route path='/groups/:groupId/checklistTemplate/:checklistTemplateId' component={GroupChecklistTemplateContainer} />
                <Route path='/groups/:groupId/incidentActionTemplate/:incidentActionTemplateId' component={GroupIncidentActionTemplateContainer} />
                <Route path='/groups/:groupId/user/:userId' component={GroupUserContainer} />
                <Route path='/groups/:groupId' component={GroupContainer} />
                <Route path='/draftincident/:incidenttypeid' component={DraftIncidentContainer} />
                <Route path='/idscan/:incidenttypeid' component={DraftIDScanIncidentContainer} />
                <Route path='/gamblingincidentregister' component={RGOIncidentsContainer} />
                <Route path='/cashtrax/:incidentid' component={CashTraxIncidentContainer} />
                <Route path='/cashtrax' component={CashTraxIncidentsContainer} />
                <Route path='/playtrax/:incidentid' component={PlayTraxIncidentContainer} />
                <Route path='/playtrax' component={PlayTraxIncidentsContainer} />
                <Route path='/commslogs/:incidentid' component={IncidentViewContainer} />
                <Route path='/commslogs' component={CommsLogsContainer} />
                <Route path='/commslog/:incidenttypeid' component={DraftCommsLogIncidentContainer} />
                <Route path='/editcommslog/:incidentid' component={EditCommsLogIncidentContainer} />
                <Route path='/incident/:incidentid' component={IncidentContainer} />
                <Route path='/incidentview/:incidentid/versions/:securityfirmid/:incidentrelaventid/:version' component={IncidentVersionContainer} />
                <Route path='/incidentview/:incidentid/versions' component={IncidentVersionsContainer} />
                <Route path='/incidentview/:incidentid' component={IncidentViewContainer} />
                <Route path='/incidents' component={IncidentsContainer} />
                <Route path='/inmatescells/add' component={CorrectionalFacilityCreateInMateCellContainer} />
                <Route path='/inmatescells' component={CorrectionalFacilityInmatesCellsContainer} />
                <Route path='/terms' component={TermsContainer} />
                <Route path='/privacypolicy' component={PrivacyPolicyContainer} />
                <Route path='/signonregister' component={SignOnContainer} />
                <Route path='/signonoffregister' component={SignOnOffRegisterContainer} />
                <Route path='/signoffregister/:usersignoffstatusid' component={SignOffContainer} />
                <Route path='/signonextend/:usersignonstatusid' component={SignOnExtendContainer} />
                <Route path='/emailverification/:userId/:emailConfirmationGuid' component={ProfileEmailConfirmationContainer} />
                <Route path='/notifications' component={NotificationsContainer} />
                <Route path='/checklists' component={CheckListsContainer} />
                <Route path='/checklist/:checklistId' component={CheckListContainer} />
                <Route path='/checklist' component={CheckListContainer} />
                <Route path='/users' component={UserRolesContainer} />
                <Route path='/user/:userId' component={UserRoleUserContainer} />
                <Route path='/home' component={HomeContainer} />
                <Route path='/test' component={Test} />
                <Route path='/register/user/terms' component={RegisterUserTerms} />
                <Route path='/register/user/privacypolicy' component={RegsiterUserPrivacyPolicy} />
                <Route path='/register/user' component={RegisterUserContainer} />
                <Route path='/register/venue/terms' component={RegisterVenueTerms} />
                <Route path='/register/venue/privacypolicy' component={RegsiterVenuePrivacyPolicy} />
                <Route path='/register/venue/success' component={RegisterVenueSuccess} />
                <Route path='/register/venue' component={RegisterVenueContainer} />
                <Route path='/register/securityFirm/terms' component={RegisterSecurityFirmTerms} />
                <Route path='/register/securityFirm/privacypolicy' component={RegisterSecurityFirmPrivacyPolicy} />
                <Route path='/register/securityFirm/success' component={RegisterSecurityFirmSuccess} />
                <Route path='/register/securityFirm' component={RegisterSecurityFirmContainer} />
                <Route path='/permissions' component={UserPermissionsContainer} />
                <Redirect to={'/home'} />
            </Switch>
        );
    }
}

export default withRouter(BaseRoute);