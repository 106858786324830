import { connect } from 'react-redux' 
import DraftCommsLogIncident from '../components/incidents/commlogs/DraftCommsLogIncident'
import * as draftIncidentActions from '../common/actions/draftIncident'
import { locationRequest } from '../common/actions/location';
import { incidentsFilterRequest } from '../common/actions/incidents';
import { notificationShow } from '../common/actions/notification';
import { permissionsLogic } from '../common/logic/permissionsLogic';
import { incidentLogic } from '../common/logic/incidentLogic';

const mapStateToProps = (state, props)=> {
    return {
        incidentTypeId: props.match.params.incidenttypeid,
        incident: state.draftIncident.incident,
        incidentOptions: state.draftIncidentOptions.incidentOptions,
        hasDraftIncident: state.draftIncident.hasDraftIncident,
        isLoading: state.draftIncident.isLoading || state.draftIncidentOptions.isLoading,
        isSaving: state.draftIncident.isSaving,
        error: state.draftIncident.error,
        isLocationLoading: state.location.isLoading,
        latitude: state.location.latitude,
        longitude: state.location.longitude,
        locationFound: state.location.found,
        locationError: state.location.error,
        step: state.draftIncident.step,
        isUploading: state.draftIncident.isUploading,
        venueLocationId: state.draftIncident.venueLocationId,
        closeAfterSave: state.draftIncident.closeAfterSave,
        action: state.draftIncident.action,
        hasChecklists: incidentLogic.hasChecklists(state.draftIncident.incident, state.draftIncidentOptions.incidentOptions),
        isLoadingChecklists: state.draftIncident.isLoadingChecklists,
        canViewCommsLogs: permissionsLogic.hasPermissionForState(state, "CommsLogsView"),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCreateIncident: () => {
            dispatch(draftIncidentActions.draftIncidentCreate(0, "CommsLog"))
        },
        onLoadIncidentOptions: () => {
            dispatch(draftIncidentActions.draftIncidentOptionsRequest(0, "CommsLog"))
        },
        onClear: () => {
            dispatch(draftIncidentActions.draftIncidentClear())
        },
        onUpdateIncident: (incident) => {
            dispatch(draftIncidentActions.draftIncidentUpdate(incident))
        },
        onClearError: () => {
            dispatch(draftIncidentActions.draftIncidentClearError())
        },
        onSaveDraft: () => {
            dispatch(draftIncidentActions.draftIncidentSave(true))
        },
        onSaveFinal: () => {
            dispatch(draftIncidentActions.draftIncidentSave(false))
        },
        onCheckIncidentDateReportingPeriod: (incidentDate) => {
            dispatch(draftIncidentActions.draftIncidentCheckReportingPeriodDate(incidentDate))
        },
        onRefreshLocation: () => {
            dispatch(locationRequest())
        },
        onErrorNotification: (message) => {
            dispatch(notificationShow(message));
        },
        onSetStep: (step) => {
            dispatch(draftIncidentActions.draftIncidentSetStep(step));
        },
        onResetFilter:() => {
            dispatch(incidentsFilterRequest(true, "CommsLog"))
        },
        onUploadFiles:(files, storageFolder) => {
            dispatch(draftIncidentActions.draftIncidentUploadRequest(files, storageFolder))
        },
        onUploadPatronFiles: (patronIndex, files, storageFolder) => {
            dispatch(draftIncidentActions.draftIncidentPatronUploadRequest(patronIndex ,files, storageFolder))
        },
        setDefaults: (venueLocationId, closeAfterSave) => {
            dispatch(draftIncidentActions.draftIncidentSetDefaults(venueLocationId, closeAfterSave))
        },
        setAction: (action) => {
            dispatch(draftIncidentActions.draftIncidentSetAction(action))
        },
        onUploadAttachment: (files, checklistTemplateItemId, storageFolder) => {
            dispatch(draftIncidentActions.draftIncidentChecklistItemUploadRequest(files, checklistTemplateItemId, storageFolder))
        },
        onLoadChecklists: () => {
            dispatch(draftIncidentActions.draftIncidentChecklistsRequest())
        },
        onUploadBase64File: (file, fileType, fileName, poiGuid, faceGuid) => {
            dispatch(draftIncidentActions.draftIncidentUpdateBase64Request(file, fileType, fileName, poiGuid, faceGuid))
        }
    }
}

const DraftCommsLogIncidentContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(DraftCommsLogIncident)

export default DraftCommsLogIncidentContainer