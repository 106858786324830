import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import ResponsiveList from '../../common/ResponsiveList';
import FabContainer from '../../layout/FabContainer';
import FullPageDialog from '../../layout/FullPageDialog';
import DefaultFab from '../../common/DefaultFab';
import PrimaryButton from '../../controls/PrimaryButton';
import DefaultButton from '../../controls/DefaultButton';
import LayoutForm from '../../layout/LayoutForm';
import TextControlGroup from '../../controls/TextControlGroup';
import SelectControlGroup from '../../controls/SelectControlGroup';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import SimpleDateControlGroup from '../../controls/SimpleDateControlGroup';
import MultiSelectControl from '../../controls/MutiSelectControl';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import ControlGroup from '../../controls/ControlGroup';
import { KeyName } from '../../../common/dto/common';
import { MulitSelectOption } from '../../../common/dto/common';
import PageLayout from '../../layout/PageLayout';
import VenueFacialRecognitionNotificationsTabsContainer from '../../../containers/VenueFacialRecognitionNotificationsTabsContainer';
import TransparentButton from '../../controls/TransparentButton';
import VenueTabs from '../../admin/venue/VenueTabs';
import FacialRecognitionTabs from '../../admin/venue/facialRecognition/FacialRecognitionTabs';


export interface IFacialRecognitionAutomaticEnrolmentsProps {
    venueId: number;
    venueMode?: boolean;
    adminMode?: boolean;
    facialRecognitionAutomaticEnrolments: AusComplyDtos.FacialRecognitionAutomaticEnrolment[];
    facialRecognitionAutomaticEnrolment: AusComplyDtos.FacialRecognitionAutomaticEnrolment;
    facialRecognitionAutomaticEnrolmentId: number;
    incidentTypes: AusComplyDtos.IncidentType[];
    incidentCategoryTypes: MulitSelectOption[];
    watchlists: AusComplyDtos.FacialRecognitionWatchlist[];
    isLoading: boolean;
    isLoadingItem: boolean;
    isSaving: boolean;
    onLoad: Function;
    onLoadItem: Function;
    onCreate: Function;
    onResetItem: Function;
    onUpdate: Function;
    onSave: Function;
    onCancel: Function;
    onProcess: Function;
    history: any;
    location: any;
}

interface IFacialRecognitionAutomaticEnrolmentsState {
    date: AusComplyDtos.SimpleDate;
    processId: number;
}

export default class FacialRecognitionAutomaticEnrolments extends Component<IFacialRecognitionAutomaticEnrolmentsProps, IFacialRecognitionAutomaticEnrolmentsState> {
    constructor(props: IFacialRecognitionAutomaticEnrolmentsProps) {
        super(props)
        var date = new AusComplyDtos.SimpleDate();
        date.isEmpty = true;
        this.state = { date, processId: 0 };
        this.onCommand = this.onCommand.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
        this.getCommands = this.getCommands.bind(this);
        this.onSetDate = this.onSetDate.bind(this);
        this.onProcess = this.onProcess.bind(this);
        //this.loadSelections = this.loadSelections.bind(this);
        this.onSelectIncidentCategoryTypesChanged = this.onSelectIncidentCategoryTypesChanged.bind(this);
        this.onCancel = this.onCancel.bind(this);

    }

    componentDidMount() {
        this.props.onLoad(this.props.venueId);
    }
    /*
        componentDidUpdate(prevProps) {
            if (prevProps.isLoadingItem && !this.props.isLoadingItem) {
                this.loadSelections(this.props.facialRecognitionAutomaticEnrolment);
            }
        }
    */
    onValueChanged(fieldName: string, value: any) {
        let facialRecognitionAutomaticEnrolment = { ...this.props.facialRecognitionAutomaticEnrolment };
        //var reloadSelections = false;
        //if (fieldName === "incidentTypeId" && facialRecognitionAutomaticEnrolment[fieldName] != value) {
        //    reloadSelections = true;
        //}
        facialRecognitionAutomaticEnrolment[fieldName] = value;
        //if (reloadSelections) {
        //    this.loadSelections(facialRecognitionAutomaticEnrolment);
        //}
        this.props.onUpdate(facialRecognitionAutomaticEnrolment);
    }

    onSetDate(value: AusComplyDtos.SimpleDate) {
        this.setState({ date: { ...value } });
    }

    onProcess() {
        const id = this.state.processId;
        if (!this.state.date.isEmpty && this.state.processId > 0) {
            this.props.onProcess(id, this.state.date);
        }
        this.setState({ processId: 0 });
    }

    getCommands(facialRecognitionAutomaticEnrolment: AusComplyDtos.FacialRecognitionAutomaticEnrolment) {
        var commands: KeyName[] = [];
        if (!facialRecognitionAutomaticEnrolment.obsolete) {
            commands.push({ key: "edit", name: "Edit" });
            commands.push({ key: "delete", name: "Remove" });
            commands.push({ key: "process", name: "Process" });
        } else {
            commands.push({ key: "restore", name: "Restore" });
        }
        return commands;
    }

    onCommand(command: string, facialRecognitionAutomaticEnrolment: AusComplyDtos.FacialRecognitionAutomaticEnrolment) {
        switch (command) {
            case "add":
                this.props.onCreate(this.props.venueId);
                break;
            case "edit":
                this.props.onLoadItem(facialRecognitionAutomaticEnrolment.facialRecognitionAutomaticEnrolmentId);
                break;
            case "restore":
                var updated = { ...facialRecognitionAutomaticEnrolment };
                updated.obsolete = false;
                this.props.onUpdate(updated);
                this.props.onSave();
                break;
            case "delete":
                var updated = { ...facialRecognitionAutomaticEnrolment };
                updated.obsolete = true;
                this.props.onUpdate(updated);
                this.props.onSave();
                break;
            case "process":
                var date = new AusComplyDtos.SimpleDate();
                date.isEmpty = true;
                this.setState({ processId: facialRecognitionAutomaticEnrolment.facialRecognitionAutomaticEnrolmentId, date });
                break;
            default:
                break;
        }

    }

    /*
    loadSelections(facialRecognitionAutomaticEnrolment: AusComplyDtos.FacialRecognitionAutomaticEnrolment) {

        let categories: MulitSelectOption[] = [];
        if (this.props.incidentCategoryTypes) {
            this.props.incidentCategoryTypes.forEach((incidentCategoryType, index) => {
                debugger;
                if (incidentCategoryType.incidentTypeId.toString() === (facialRecognitionAutomaticEnrolment.incidentTypeId || 0).toString()) {
                    let isSelected = false;
                    if (facialRecognitionAutomaticEnrolment.facialRecognitionAutomaticEnrolmentIncidentCategoryTypes) {
                        isSelected = facialRecognitionAutomaticEnrolment.facialRecognitionAutomaticEnrolmentIncidentCategoryTypes.filter(f => f.incidentCategoryTypeId == incidentCategoryType.incidentCategoryTypeId).length > 0;
                    }
                    categories.push({
                        id: incidentCategoryType.incidentCategoryTypeId,
                        name: incidentCategoryType.name,
                        isCommon: false,
                        selected: isSelected
                    });
                }
            });
        }

        this.setState({
            categories
        }, () => { });
    }
    */

    onSelectIncidentCategoryTypesChanged(items) {
        let facialRecognitionAutomaticEnrolment = { ...this.props.facialRecognitionAutomaticEnrolment };
        facialRecognitionAutomaticEnrolment.facialRecognitionAutomaticEnrolmentIncidentCategoryTypes = [];
        items.forEach(item => {
            var facialRecognitionAutomaticEnrolmentIncidentCategoryType = new AusComplyDtos.FacialRecognitionAutomaticEnrolmentIncidentCategoryType();
            facialRecognitionAutomaticEnrolmentIncidentCategoryType.incidentCategoryTypeId = item.id;
            facialRecognitionAutomaticEnrolment.facialRecognitionAutomaticEnrolmentIncidentCategoryTypes.push(facialRecognitionAutomaticEnrolmentIncidentCategoryType);
        });
        this.props.onUpdate(facialRecognitionAutomaticEnrolment);
    }

    onCancel() {
        this.props.history.push('/admin/venues');
    }

    render() {
        let self = this;
        let facialRecognitionAutomaticEnrolment;
        if (this.props.facialRecognitionAutomaticEnrolmentId != -1) {

            var obsolete = false;
            if (this.props.facialRecognitionAutomaticEnrolment && this.props.facialRecognitionAutomaticEnrolment.obsolete != undefined) {
                obsolete = this.props.facialRecognitionAutomaticEnrolment.obsolete;
            }
            var expiryValue = "0";
            if (this.props.facialRecognitionAutomaticEnrolment && this.props.facialRecognitionAutomaticEnrolment.expiry != undefined) {
                expiryValue = this.props.facialRecognitionAutomaticEnrolment.expiry.toString();
            }

            var expriryLabel = "Expiry after ";
            if (this.props.facialRecognitionAutomaticEnrolment) {
                var expriryLabel = "Expiry after " + self.props.facialRecognitionAutomaticEnrolment.enrolExpiryType;
                if(self.props.facialRecognitionAutomaticEnrolment.enrolExpiryType == AusComplyDtos.ngtEnrolExpiryType.Age) {
                    expriryLabel = "Expiry at age";
                }
            }

            facialRecognitionAutomaticEnrolment = (
                <LayoutForm
                    loading={this.props.isLoadingItem}
                    saving={this.props.isSaving}
                >
                    {this.props.facialRecognitionAutomaticEnrolment && (
                        <form>
                            <SelectControlGroup
                                text="Incident Type"
                                onChange={(value) => this.onValueChanged("incidentTypeId", value)}
                                defaultValue={this.props.facialRecognitionAutomaticEnrolment.incidentTypeId}>
                                <option value={0} />
                                {this.props.incidentTypes && this.props.incidentTypes.map((incidentType, index) => (
                                    <option key={incidentType.incidentTypeId} value={incidentType.incidentTypeId}>{incidentType.name}</option>
                                ))}
                            </SelectControlGroup>

                            <ControlGroup text={" "}>
                                <CardContainer
                                    title={"Incident Categories"}
                                    style={{ paddingTop: 0 }}>
                                    <CardContent style={{ paddingTop: 0 }}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12}>
                                                <MultiSelectControl
                                                    label={""}
                                                    items={this.props.incidentCategoryTypes}
                                                    searchText="Type at least three letters..."
                                                    minimumCharactersRequired={3}
                                                    onSelectItemsChanged={this.onSelectIncidentCategoryTypesChanged}
                                                    canShowAll={true} />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </CardContainer>
                            </ControlGroup>

                            <SelectControlGroup
                                text="Watchlist"
                                onChange={(value) => this.onValueChanged("facialRecognitionWatchlistId", value)}
                                defaultValue={this.props.facialRecognitionAutomaticEnrolment.facialRecognitionWatchlistId}>
                                <option value={0} />
                                {this.props.watchlists && this.props.watchlists.filter(f => !f.obsolete || f.facialRecognitionWatchlistId == this.props.facialRecognitionAutomaticEnrolment.facialRecognitionWatchlistId).map((watchlist, index) => (
                                    <option key={watchlist.facialRecognitionWatchlistId} value={watchlist.facialRecognitionWatchlistId}>{watchlist.displayName}</option>
                                ))}
                            </SelectControlGroup>

                            <SelectControlGroup
                                text="Expiry"
                                onChange={(value) => this.onValueChanged("enrolExpiryType", value)}
                                defaultValue={self.props.facialRecognitionAutomaticEnrolment.enrolExpiryType}>
                                <option key={AusComplyDtos.ngtEnrolExpiryType.Never} value={AusComplyDtos.ngtEnrolExpiryType.Never}>Never expire / Life ban</option>
                                <option key={AusComplyDtos.ngtEnrolExpiryType.Hours} value={AusComplyDtos.ngtEnrolExpiryType.Hours}>Hours</option>
                                <option key={AusComplyDtos.ngtEnrolExpiryType.Days} value={AusComplyDtos.ngtEnrolExpiryType.Days}>Days</option>
                                <option key={AusComplyDtos.ngtEnrolExpiryType.Age} value={AusComplyDtos.ngtEnrolExpiryType.Age}>Age (requires patron DOB)</option>
                            </SelectControlGroup>

                            {self.props.facialRecognitionAutomaticEnrolment.enrolExpiryType != AusComplyDtos.ngtEnrolExpiryType.Never && (
                                <TextControlGroup
                                    text={expriryLabel}
                                    numericOnly={true}
                                    onChange={(value) => this.onValueChanged("expiry", value)}
                                    defaultValue={expiryValue} />
                            )}


                            <CheckboxControlGroup
                                label={" "}
                                text={"Active"}
                                isReversed={true}
                                defaultValue={obsolete}
                                onChanged={(value) => this.onValueChanged("obsolete", value)} />
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <PrimaryButton text={"Save"} onClick={() => this.props.onSave()}></PrimaryButton>
                                </Grid>
                                <Grid item xs={6}>
                                    <DefaultButton text={"Cancel"} onClick={() => this.props.onCancel()}></DefaultButton>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </LayoutForm >
            );
        }

        let createFab = (
            <DefaultFab onClick={() => self.props.onCreate(this.props.venueId)} isExtended={true}>Add Automatic Enrolment</DefaultFab>
        );

        if (this.props.venueMode) {
            return (
                <PageLayout
                    headerText={"Facial Recognition"}
                    loading={this.props.isLoading}
                    saving={this.props.isSaving}
                    footerLeftContent={<TransparentButton text="Add Automatic Enrolment" onClick={() => self.props.onCreate(this.props.venueId)} />}
                >
                    <VenueFacialRecognitionNotificationsTabsContainer selectedTab={"Enrol"} history={this.props.history} />
                    <ResponsiveList
                        loading={this.props.isLoading || this.props.isSaving}
                        data={this.props.facialRecognitionAutomaticEnrolments}
                        headers={["For Incident Type", "With Incident Category Types", "Enrol in Watchlist", "Enrolment Expires", "Expiry"]}
                        columns={["incidentType.name", "incidentCategoryTypesDisplay", "facialRecognitionWatchlist.displayName", "enrolExpiryType", "expiry"]}
                        getCommands={item => this.getCommands(item)}
                        onCommand={(command, item) => this.onCommand(command, item)}
                        isDanger={item => item.obsolete}
                        toggleDanger={true}
                        canEdit={true}
                    />
                    <FullPageDialog open={this.props.facialRecognitionAutomaticEnrolmentId != -1} title="Automatic Enrolment" onDismissed={() => this.props.onResetItem()}>
                        {facialRecognitionAutomaticEnrolment}
                    </FullPageDialog>
                    <FullPageDialog open={this.state.processId > 0} notFullWidth={true} title="Process Automatic Enrolments" onDismissed={() => self.setState({ processId: 0 })}>
                        <LayoutForm>
                            <SimpleDateControlGroup
                                rightText="Process Incidents From"
                                labelAbove={true}
                                date={this.state.date}
                                onChange={(value) => this.onSetDate(value)}
                            />
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <PrimaryButton disabled={this.state.date.isEmpty} text={"Process"} onClick={() => this.onProcess()}></PrimaryButton>
                                </Grid>
                                <Grid item xs={6}>
                                    <DefaultButton text={"Cancel"} onClick={() => self.setState({ processId: 0 })}></DefaultButton>
                                </Grid>
                            </Grid>
                        </LayoutForm>
                    </FullPageDialog>
                </PageLayout>
            );
        }

        if (this.props.adminMode) {
            return (
                <PageLayout
                    headerText={"Venue"}
                    loading={this.props.isLoading}
                    saving={this.props.isSaving}
                    footerLeftContent={<TransparentButton text={"Cancel"} onClick={() => this.onCancel()}></TransparentButton>}
                    hideQuickActions={true}
                    footerRightContent={<TransparentButton text="Add Enrolment" onClick={() => self.props.onCreate(this.props.venueId)} />}
                >
                    <VenueTabs venueId={this.props.venueId} selectedTab={"Facial Recognition"} history={this.props.history} />
                    <FacialRecognitionTabs venueId={this.props.venueId} selectedTab={"Enrol"} history={this.props.history} />
                    <ResponsiveList
                        loading={this.props.isLoading || this.props.isSaving}
                        data={this.props.facialRecognitionAutomaticEnrolments}
                        headers={["For Incident Type", "With Incident Category Types", "Enrol in Watchlist", "Enrolment Expires", "Expiry"]}
                        columns={["incidentType.name", "incidentCategoryTypesDisplay", "facialRecognitionWatchlist.displayName", "enrolExpiryType", "expiry"]}
                        getCommands={item => this.getCommands(item)}
                        onCommand={(command, item) => this.onCommand(command, item)}
                        isDanger={item => item.obsolete}
                        toggleDanger={true}
                        canEdit={true}
                    />
                    <FullPageDialog open={this.props.facialRecognitionAutomaticEnrolmentId != -1} title="Automatic Enrolment" onDismissed={() => this.props.onResetItem()}>
                        {facialRecognitionAutomaticEnrolment}
                    </FullPageDialog>
                    <FullPageDialog open={this.state.processId > 0} notFullWidth={true} title="Process Automatic Enrolments" onDismissed={() => self.setState({ processId: 0 })}>
                        <LayoutForm>
                            <SimpleDateControlGroup
                                rightText="Process Incidents From"
                                labelAbove={true}
                                date={this.state.date}
                                onChange={(value) => this.onSetDate(value)}
                            />
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <PrimaryButton disabled={this.state.date.isEmpty} text={"Process"} onClick={() => this.onProcess()}></PrimaryButton>
                                </Grid>
                                <Grid item xs={6}>
                                    <DefaultButton text={"Cancel"} onClick={() => self.setState({ processId: 0 })}></DefaultButton>
                                </Grid>
                            </Grid>
                        </LayoutForm>
                    </FullPageDialog>
                </PageLayout>
            );
        }

        return (
            <>
                <ResponsiveList
                    loading={this.props.isLoading || this.props.isSaving}
                    data={this.props.facialRecognitionAutomaticEnrolments}
                    headers={["For Incident Type", "With Incident Category Types", "Enrol in Watchlist", "Enrolment Expires", "Expiry"]}
                    columns={["incidentType.name", "incidentCategoryTypesDisplay", "facialRecognitionWatchlist.displayName", "enrolExpiryType", "expiry"]}
                    getCommands={item => this.getCommands(item)}
                    onCommand={(command, item) => this.onCommand(command, item)}
                    isDanger={item => item.obsolete}
                    toggleDanger={true}
                    canEdit={true}
                />
                <FabContainer style={{ right: '120px', bottom: '3px' }}>
                    {createFab}
                </FabContainer>
                <FullPageDialog open={this.props.facialRecognitionAutomaticEnrolmentId != -1} title="Automatic Enrolment" onDismissed={() => this.props.onResetItem()}>
                    {facialRecognitionAutomaticEnrolment}
                </FullPageDialog>
                <FullPageDialog open={this.state.processId > 0} title="Process Automatic Enrolments" onDismissed={() => self.setState({ processId: 0 })}>
                    <LayoutForm>
                        <SimpleDateControlGroup
                            rightText="Process Incidents From"
                            labelAbove={true}
                            date={this.state.date}
                            onChange={(value) => this.onSetDate(value)}
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <PrimaryButton disabled={this.state.date.isEmpty} text={"Process"} onClick={() => this.onProcess()}></PrimaryButton>
                            </Grid>
                            <Grid item xs={6}>
                                <DefaultButton text={"Cancel"} onClick={() => self.setState({ processId: 0 })}></DefaultButton>
                            </Grid>
                        </Grid>
                    </LayoutForm>
                </FullPageDialog>
            </>
        );

    }
}