export const
    DRAFT_INCIDENT_OPTIONS_REQUEST = "DRAFT_INCIDENT_OPTIONS_REQUEST",
    DRAFT_INCIDENT_OPTIONS_REQUEST_SUCCESS = "DRAFT_INCIDENT_OPTIONS_REQUEST_SUCCESS",
    DRAFT_INCIDENT_OPTIONS_REQUEST_FAILURE = "DRAFT_INCIDENT_OPTIONS_REQUEST_FAILURE",
    DRAFT_INCIDENT_SET_STEP = "DRAFT_INCIDENT_SET_STEP",
    DRAFT_INCIDENT_SET_DEFAULTS = "DRAFT_INCIDENT_SET_DEFAULTS",
    DRAFT_INCIDENT_SET_ACTION = "DRAFT_INCIDENT_SET_ACTION",
    DRAFT_INCIDENT_CREATE_REQUEST = "DRAFT_INCIDENT_CREATE_REQUEST",
    DRAFT_INCIDENT_CREATE_REQUEST_SUCCESS = "DRAFT_INCIDENT_CREATE_REQUEST_SUCCESS",
    DRAFT_INCIDENT_CREATE_REQUEST_FAILURE = "DRAFT_INCIDENT_CREATE_REQUEST_FAILURE",
    DRAFT_INCIDENT_CLEAR = "DRAFT_INCIDENT_CLEAR",
    DRAFT_INCIDENT_UPDATE = "DRAFT_INCIDENT_UPDATE",
    DRAFT_INCIDENT_CLEAR_ERROR = "DRAFT_INCIDENT_CLEAR_ERROR",
    DRAFT_INCIDENT_SAVE_REQUEST = "DRAFT_INCIDENT_SAVE_REQUEST",
    DRAFT_INCIDENT_SAVE_REQUEST_SUCCESS = "DRAFT_INCIDENT_SAVE_REQUEST_SUCCESS",
    DRAFT_INCIDENT_SAVE_REQUEST_FAILURE = "DRAFT_INCIDENT_SAVE_REQUEST_FAILURE",
    DRAFT_INCIDENT_CHECK_REPORTING_PERIOD_DATE_REQUEST = "DRAFT_INCIDENT_CHECK_REPORTING_PERIOD_DATE_REQUEST",
    DRAFT_INCIDENT_CHECK_REPORTING_PERIOD_DATE_REQUEST_SUCCESS = "DRAFT_INCIDENT_CHECK_REPORTING_PERIOD_DATE_REQUEST_SUCCESS",
    DRAFT_INCIDENT_CHECK_REPORTING_PERIOD_DATE_REQUEST_FAILURE = "DRAFT_INCIDENT_CHECK_REPORTING_PERIOD_DATE_REQUEST_FAILURE",
    DRAFT_INCIDENT_PATRON_UPLOAD_REQUEST = "DRAFT_INCIDENT_PATRON_UPLOAD_REQUEST",
    DRAFT_INCIDENT_PATRON_UPLOAD_REQUEST_SUCCESS = "DRAFT_INCIDENT_PATRON_UPLOAD_REQUEST_SUCCESS",
    DRAFT_INCIDENT_PATRON_UPLOAD_REQUEST_FAILURE = "DRAFT_INCIDENT_PATRON_UPLOAD_REQUEST_FAILURE",
    DRAFT_INCIDENT_UPLOAD_REQUEST = "DRAFT_INCIDENT_UPLOAD_REQUEST",
    DRAFT_INCIDENT_UPLOAD_BASE64_REQUEST = "DRAFT_INCIDENT_UPLOAD_BASE64_REQUEST",
    DRAFT_INCIDENT_UPLOAD_REQUEST_SUCCESS = "DRAFT_INCIDENT_UPLOAD_REQUEST_SUCCESS",
    DRAFT_INCIDENT_UPLOAD_REQUEST_FAILURE = "DRAFT_INCIDENT_UPLOAD_REQUEST_FAILURE",
    DRAFT_INCIDENT_CHECKLIST_ITEM_UPLOAD_REQUEST = "DRAFT_INCIDENT_CHECKLIST_ITEM_UPLOAD_REQUEST",
    DRAFT_INCIDENT_CHECKLIST_ITEM_UPLOAD_REQUEST_SUCCESS = "DRAFT_INCIDENT_CHECKLIST_ITEM_UPLOAD_REQUEST_SUCCESS",
    DRAFT_INCIDENT_CHECKLIST_ITEM_UPLOAD_REQUEST_FAILURE = "DRAFT_INCIDENT_CHECKLIST_ITEM_UPLOAD_REQUEST_FAILURE",
    DRAFT_INCIDENT_CHECKLISTS_REQUEST = "DRAFT_INCIDENT_CHECKLISTS_REQUEST",
    DRAFT_INCIDENT_CHECKLISTS_REQUEST_SUCCESS = "DRAFT_INCIDENT_CHECKLISTS_REQUEST_SUCCESS",
    DRAFT_INCIDENT_CHECKLISTS_REQUEST_FAILURE = "DRAFT_INCIDENT_CHECKLISTS_REQUEST_FAILURE";

export function draftIncidentSetStep(step) {
    return {
        type: DRAFT_INCIDENT_SET_STEP,
        step
    };
}

export function draftIncidentSetDefaults(venueLocationId, closeAfterSave) {
    return {
        type: DRAFT_INCIDENT_SET_DEFAULTS,
        venueLocationId, 
        closeAfterSave
    };
}

export function draftIncidentSetAction(action) {
    return {
        type: DRAFT_INCIDENT_SET_ACTION,
        action
    };
}

export function draftIncidentCreate(incidentTypeId, entryPage) {
    return {
        type: DRAFT_INCIDENT_CREATE_REQUEST,
        incidentTypeId,
        entryPage
    };
}

export function draftIncidentCreateSuccess(incident, incidentOptions) {
    return {
        type: DRAFT_INCIDENT_CREATE_REQUEST_SUCCESS,
        incident,
        incidentOptions
    };
}

export function draftIncidentCreateFailure(error) {
    return {
        type: DRAFT_INCIDENT_CREATE_REQUEST_FAILURE,
        error
    };
}

export function draftIncidentOptionsRequest(incidentTypeId, entryPage) {
    return {
        type: DRAFT_INCIDENT_OPTIONS_REQUEST,
        incidentTypeId,
        entryPage
    };
}

export function draftIncidentOptionsRequestSuccess(incidentOptions) {
    return {
        type: DRAFT_INCIDENT_OPTIONS_REQUEST_SUCCESS,
        incidentOptions
    };
}

export function draftIncidentOptionsRequestFailure(error) {
    return {
        type: DRAFT_INCIDENT_OPTIONS_REQUEST_FAILURE,
        error
    };
}

export function draftIncidentClear() {
    return {
        type: DRAFT_INCIDENT_CLEAR
    };
}

export function draftIncidentUpdate(incident) {
    return {
        type: DRAFT_INCIDENT_UPDATE,
        incident
    };
}

export function draftIncidentSave(isDraft) {
    return {
        type: DRAFT_INCIDENT_SAVE_REQUEST,
        isDraft
    };
}

export function draftIncidentSaveSuccess(incident) {
    return {
        type: DRAFT_INCIDENT_SAVE_REQUEST_SUCCESS,
        incident
    };
}

export function draftIncidentSaveFailure(error) {
    return {
        type: DRAFT_INCIDENT_SAVE_REQUEST_FAILURE,
        error
    };
}

export function draftIncidentClearError() {
    return {
        type: DRAFT_INCIDENT_CLEAR_ERROR
    }
}

export function draftIncidentCheckReportingPeriodDate(incidentDate) {
    return {
        type: DRAFT_INCIDENT_CHECK_REPORTING_PERIOD_DATE_REQUEST,
        incidentDate
    };
}

export function draftIncidentCheckReportingPeriodDateSuccess(isCurrentReportingPeriod) {
    return {
        type: DRAFT_INCIDENT_CHECK_REPORTING_PERIOD_DATE_REQUEST_SUCCESS,
        isCurrentReportingPeriod
    };
}

export function draftIncidentCheckReportingPeriodDateFailure(error) {
    return {
        type: DRAFT_INCIDENT_CHECK_REPORTING_PERIOD_DATE_REQUEST_FAILURE,
        error
    };
}

export function draftIncidentUploadRequest(files, storageFolder) {
    return {
        type: DRAFT_INCIDENT_UPLOAD_REQUEST,
        files,
        storageFolder
    }
}

export function draftIncidentUpdateBase64Request(file, fileType, fileName, poiGuid, appearanceGuid) {
    return {
        type: DRAFT_INCIDENT_UPLOAD_BASE64_REQUEST,
        file, 
        fileType, 
        fileName, 
        poiGuid, 
        appearanceGuid
    }
}

export function draftIncidentUploadRequestSuccess(temporaryFiles) {
    return {
        type: DRAFT_INCIDENT_UPLOAD_REQUEST_SUCCESS,
        temporaryFiles
    }
}

export function draftIncidentUploadRequestFailure(error) {
    return {
        type: DRAFT_INCIDENT_UPLOAD_REQUEST_FAILURE,
        error
    }
}

export function draftIncidentPatronUploadRequest(patronIndex, files, storageFolder) {
    return {
        type: DRAFT_INCIDENT_PATRON_UPLOAD_REQUEST,
        patronIndex,
        files,
        storageFolder
    }
}

export function draftIncidentPatronUploadRequestSuccess(patronIndex, temporaryFiles) {
    return {
        type: DRAFT_INCIDENT_PATRON_UPLOAD_REQUEST_SUCCESS,
        patronIndex,
        temporaryFiles
    }
}

export function draftIncidentPatronUploadRequestFailure(error) {
    return {
        type: DRAFT_INCIDENT_PATRON_UPLOAD_REQUEST_FAILURE,
        error
    }
}


export function draftIncidentChecklistItemUploadRequest(files, checklistTemplateItemId, storageFolder) {
    return {
        type: DRAFT_INCIDENT_CHECKLIST_ITEM_UPLOAD_REQUEST,
        files,
        checklistTemplateItemId,
        storageFolder
    }
  }
  
  export function draftIncidentChecklistItemUploadRequestSuccess(temporaryFiles, checklistTemplateItemId) {
    return {
        type: DRAFT_INCIDENT_CHECKLIST_ITEM_UPLOAD_REQUEST_SUCCESS,
        temporaryFiles,
        checklistTemplateItemId
    }
  }
  
  export function draftIncidentChecklistItemUploadRequestFailure(error) {
    return {
        type: DRAFT_INCIDENT_CHECKLIST_ITEM_UPLOAD_REQUEST_FAILURE,
        error
    }
  }

  export function draftIncidentChecklistsRequest() {
      return {
          type: DRAFT_INCIDENT_CHECKLISTS_REQUEST
      }
    }
    
    export function draftIncidentChecklistsRequestSuccess(incident) {
      return {
          type: DRAFT_INCIDENT_CHECKLISTS_REQUEST_SUCCESS,
          incident
      }
    }
    
    export function draftIncidentChecklistsRequestFailure(error) {
      return {
          type: DRAFT_INCIDENT_CHECKLISTS_REQUEST_FAILURE,
          error
      }
    }