import { makeActionCreator } from './helpers';

export const
    FACIAL_RECOGNITION_POI_REGISTRATION_REQUEST = "FACIAL_RECOGNITION_POI_REGISTRATION_REQUEST",    
    FACIAL_RECOGNITION_POI_REGISTRATION_REQUEST_SUCCESS = "FACIAL_RECOGNITION_POI_REGISTRATION_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_POI_REGISTRATION_REQUEST_FAILURE = "FACIAL_RECOGNITION_POI_REGISTRATION_REQUEST_FAILURE",
    FACIAL_RECOGNITION_POI_VENUE_REGISTRATION_REQUEST = "FACIAL_RECOGNITION_POI_VENUE_REGISTRATION_REQUEST",    
    FACIAL_RECOGNITION_POI_GROUP_REGISTRATION_REQUEST = "FACIAL_RECOGNITION_POI_GROUP_REGISTRATION_REQUEST",  
    FACIAL_RECOGNITION_POI_REGISTRATION_RESET = "FACIAL_RECOGNITION_POI_REGISTRATION_RESET",
    FACIAL_RECOGNITION_POI_REGISTRATION_SET = "FACIAL_RECOGNITION_POI_REGISTRATION_SET",
    FACIAL_RECOGNITION_POI_REGISTRATION_SCAN_FACES_REQUEST = "FACIAL_RECOGNITION_POI_REGISTRATION_SCAN_FACES_REQUEST",    
    FACIAL_RECOGNITION_POI_REGISTRATION_SCAN_FACES_REQUEST_SUCCESS = "FACIAL_RECOGNITION_POI_REGISTRATION_REQUEST_SCAN_FACES_SUCCESS",
    FACIAL_RECOGNITION_POI_REGISTRATION_SCAN_FACES_REQUEST_FAILURE = "FACIAL_RECOGNITION_POI_REGISTRATION_REQUEST_SCAN_FACES_FAILURE",
    FACIAL_RECOGNITION_POI_REGISTRATION_SEARCH_FACE_REQUEST = "FACIAL_RECOGNITION_POI_REGISTRATION_SEARCH_FACE_REQUEST",    
    FACIAL_RECOGNITION_POI_REGISTRATION_SEARCH_FACE_REQUEST_SUCCESS = "FACIAL_RECOGNITION_POI_REGISTRATION_SEARCH_FACE_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_POI_REGISTRATION_SEARCH_FACE_REQUEST_FAILURE = "FACIAL_RECOGNITION_POI_REGISTRATION_SEARCH_FACE_REQUEST_FAILURE",
    FACIAL_RECOGNITION_POI_REGISTRATION_SAVE_REQUEST = "FACIAL_RECOGNITION_POI_REGISTRATION_SAVE_REQUEST",    
    FACIAL_RECOGNITION_POI_REGISTRATION_SAVE_REQUEST_SUCCESS = "FACIAL_RECOGNITION_POI_REGISTRATION_SAVE_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_POI_REGISTRATION_SAVE_REQUEST_FAILURE = "FACIAL_RECOGNITION_POI_REGISTRATION_SAVE_REQUEST_FAILURE",
    FACIAL_RECOGNITION_POI_INCIDENTS_REQUEST = "FACIAL_RECOGNITION_POI_INCIDENTS_REQUEST",    
    FACIAL_RECOGNITION_POI_INCIDENTS_REQUEST_SUCCESS = "FACIAL_RECOGNITION_POI_INCIDENTS_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_POI_INCIDENTS_REQUEST_FAILURE = "FACIAL_RECOGNITION_POI_INCIDENTS_REQUEST_FAILURE",
    FACIAL_RECOGNITION_POIS_INCIDENTS_REQUEST = "FACIAL_RECOGNITION_POIS_INCIDENTS_REQUEST",    
    FACIAL_RECOGNITION_POIS_INCIDENTS_REQUEST_SUCCESS = "FACIAL_RECOGNITION_POIS_INCIDENTS_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_POIS_INCIDENTS_REQUEST_FAILURE = "FACIAL_RECOGNITION_POIS_INCIDENTS_REQUEST_FAILURE",
    FACIAL_RECOGNITION_POI_GENERATE_APPEARANCE_REQUEST = "FACIAL_RECOGNITION_POI_GENERATE_APPEARANCE_REQUEST",    
    FACIAL_RECOGNITION_POI_GENERATE_APPEARANCE_REQUEST_SUCCESS = "FACIAL_RECOGNITION_POI_GENERATE_APPEARANCE_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_POI_GENERATE_APPEARANCE_REQUEST_FAILURE = "FACIAL_RECOGNITION_POI_GENERATE_APPEARANCE_REQUEST_FAILURE",
    FACIAL_RECOGNITION_POI_SEARCH_REQUEST = "FACIAL_RECOGNITION_POI_SEARCH_REQUEST",    
    FACIAL_RECOGNITION_POI_SEARCH_REQUEST_SUCCESS = "FACIAL_RECOGNITION_POI_SEARCH_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_POI_SEARCH_REQUEST_FAILURE = "FACIAL_RECOGNITION_POI_SEARCH_REQUEST_FAILURE",
    FACIAL_RECOGNITION_POI_EXPIRY_FAILURES_REQUEST = "FACIAL_RECOGNITION_POI_EXPIRY_FAILURES_REQUEST",    
    FACIAL_RECOGNITION_POI_EXPIRY_FAILURES_REQUEST_SUCCESS = "FACIAL_RECOGNITION_POI_EXPIRY_FAILURES_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_POI_EXPIRY_FAILURES_REQUEST_FAILURE = "FACIAL_RECOGNITION_POI_EXPIRY_FAILURES_REQUEST_FAILURE",
    FACIAL_RECOGNITION_POI_EXPIRY_RETRY_REQUEST = "FACIAL_RECOGNITION_POI_EXPIRY_RETRY_REQUEST",    
    FACIAL_RECOGNITION_POI_EXPIRY_RETRY_REQUEST_SUCCESS = "FACIAL_RECOGNITION_POI_EXPIRY_RETRY_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_POI_EXPIRY_RETRY_REQUEST_FAILURE = "FACIAL_RECOGNITION_POI_EXPIRY_RETRY_REQUEST_FAILURE",
    FACIAL_RECOGNITION_POI_EXPIRY_DISMISS_REQUEST = "FACIAL_RECOGNITION_POI_EXPIRY_DISMISS_REQUEST",    
    FACIAL_RECOGNITION_POI_EXPIRY_DISMISS_REQUEST_SUCCESS = "FACIAL_RECOGNITION_POI_EXPIRY_DISMISS_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_POI_EXPIRY_DISMISS_REQUEST_FAILURE = "FACIAL_RECOGNITION_POI_EXPIRY_DISMISS_REQUEST_FAILURE",
    
    FACIAL_RECOGNITION_POI_WATCHLIST_REGISTRATION_REQUEST = "FACIAL_RECOGNITION_POI_WATCHLIST_REGISTRATION_REQUEST", 
    FACIAL_RECOGNITION_POI_WATCHLIST_REGISTRATION_REQUEST_SUCCESS = "FACIAL_RECOGNITION_POI_WATCHLIST_REGISTRATION_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_POI_WATCHLIST_REGISTRATION_REQUEST_FAILURE = "FACIAL_RECOGNITION_POI_WATCHLIST_REGISTRATION_REQUEST_FAILURE", 
    FACIAL_RECOGNITION_POI_WATCHLIST_REGISTRATION_RESET = "FACIAL_RECOGNITION_POI_REGISTRATION_WATCHLIST_RESET",
    FACIAL_RECOGNITION_POI_WATCHLIST_REGISTRATION_SET = "FACIAL_RECOGNITION_POI_REGISTRATION_WATCHLIST_SET",
    FACIAL_RECOGNITION_POI_WATCHLIST_REGISTRATION_SAVE_REQUEST = "FACIAL_RECOGNITION_POI_WATCHLIST_REGISTRATION_SAVE_REQUEST",    
    FACIAL_RECOGNITION_POI_WATCHLIST_REGISTRATION_SAVE_REQUEST_SUCCESS = "FACIAL_RECOGNITION_WATCHLIST_POI_REGISTRATION_SAVE_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_POI_WATCHLIST_REGISTRATION_SAVE_REQUEST_FAILURE = "FACIAL_RECOGNITION_WATCHLIST_POI_REGISTRATION_SAVE_REQUEST_FAILURE",

    FACIAL_RECOGNITION_POI_SCANNED_ID_REQUEST = "FACIAL_RECOGNITION_POI_SCANNED_ID_REQUEST",
    FACIAL_RECOGNITION_POI_SCANNED_ID_REQUEST_SUCCESS = "FACIAL_RECOGNITION_POI_SCANNED_ID_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_POI_SCANNED_ID_REQUEST_FAILURE = "FACIAL_RECOGNITION_POI_SCANNED_ID_REQUEST_FAILURE"
    ;

export const facialRecognitionPoiRegistrationRequest = makeActionCreator(FACIAL_RECOGNITION_POI_REGISTRATION_REQUEST, 'incidentId');
export const facialRecognitionPoiVenueRegistrationRequest = makeActionCreator(FACIAL_RECOGNITION_POI_VENUE_REGISTRATION_REQUEST, 'forVenueId');
export const facialRecognitionPoiGroupRegistrationRequest = makeActionCreator(FACIAL_RECOGNITION_POI_GROUP_REGISTRATION_REQUEST, 'forGroupId');
export const facialRecognitionPoiRegistrationRequestSuccess = makeActionCreator(FACIAL_RECOGNITION_POI_REGISTRATION_REQUEST_SUCCESS, 'poiRegistration');
export const facialRecognitionPoiRegistrationRequestFailure = makeActionCreator(FACIAL_RECOGNITION_POI_REGISTRATION_REQUEST_FAILURE, 'error');
export const facialRecognitionPoiRegistrationScanFacesRequest = makeActionCreator(FACIAL_RECOGNITION_POI_REGISTRATION_SCAN_FACES_REQUEST);
export const facialRecognitionPoiRegistrationScanFacesRequestSuccess = makeActionCreator(FACIAL_RECOGNITION_POI_REGISTRATION_SCAN_FACES_REQUEST_SUCCESS, 'poiRegistration');
export const facialRecognitionPoiRegistrationScanFacesRequestFailure = makeActionCreator(FACIAL_RECOGNITION_POI_REGISTRATION_SCAN_FACES_REQUEST_FAILURE, 'error');
export const facialRecognitionPoiRegistrationReset = makeActionCreator(FACIAL_RECOGNITION_POI_REGISTRATION_RESET);
export const facialRecognitionPoiRegistrationSet = makeActionCreator(FACIAL_RECOGNITION_POI_REGISTRATION_SET, 'poiRegistration');
export const facialRecognitionPoiRegistrationSearchFaceRequest = makeActionCreator(FACIAL_RECOGNITION_POI_REGISTRATION_SEARCH_FACE_REQUEST);
export const facialRecognitionPoiRegistrationSearchFaceRequestSuccess = makeActionCreator(FACIAL_RECOGNITION_POI_REGISTRATION_SEARCH_FACE_REQUEST_SUCCESS, 'poiRegistration');
export const facialRecognitionPoiRegistrationSearchFaceRequestFailure = makeActionCreator(FACIAL_RECOGNITION_POI_REGISTRATION_SEARCH_FACE_REQUEST_FAILURE, 'error');
export const facialRecognitionPoiRegistrationSaveRequest = makeActionCreator(FACIAL_RECOGNITION_POI_REGISTRATION_SAVE_REQUEST);
export const facialRecognitionPoiRegistrationSaveRequestSuccess = makeActionCreator(FACIAL_RECOGNITION_POI_REGISTRATION_SAVE_REQUEST_SUCCESS, 'poiRegistration');
export const facialRecognitionPoiRegistrationSaveRequestFailure = makeActionCreator(FACIAL_RECOGNITION_POI_REGISTRATION_SAVE_REQUEST_FAILURE, 'error');
export const facialRecognitionPoiIncidentsRequest = makeActionCreator(FACIAL_RECOGNITION_POI_INCIDENTS_REQUEST, 'facialRecognitionPOIIncidentId', 'poiGuid', 'paging', 'forVenueId', 'facialRecognitionWatchlistId');
export const facialRecognitionPoiIncidentsRequestSuccess = makeActionCreator(FACIAL_RECOGNITION_POI_INCIDENTS_REQUEST_SUCCESS, 'poiIncidents','facialRecognitionPOIIncidentId', 'poiGuid', 'facialRecognitionWatchlistId');
export const facialRecognitionPoiIncidentsRequestFailure = makeActionCreator(FACIAL_RECOGNITION_POI_INCIDENTS_REQUEST_FAILURE, 'error');
export const facialRecognitionPoisIncidentsRequest = makeActionCreator(FACIAL_RECOGNITION_POIS_INCIDENTS_REQUEST, 'poiGuids');
export const facialRecognitionPoisIncidentsRequestSuccess = makeActionCreator(FACIAL_RECOGNITION_POIS_INCIDENTS_REQUEST_SUCCESS, 'data');
export const facialRecognitionPoisIncidentsRequestFailure = makeActionCreator(FACIAL_RECOGNITION_POIS_INCIDENTS_REQUEST_FAILURE, 'error');
export const facialRecognitionPoiGenerateAppearanceRequest = makeActionCreator(FACIAL_RECOGNITION_POI_GENERATE_APPEARANCE_REQUEST, 'poiGuid', 'facialRecognitionWatchlistId', 'facialRecognitionCameraId', 'facialRecognitionEventId');
export const facialRecognitionPoiGenerateAppearanceRequestSuccess = makeActionCreator(FACIAL_RECOGNITION_POI_GENERATE_APPEARANCE_REQUEST_SUCCESS);
export const facialRecognitionPoiGenerateAppearanceRequestFailure = makeActionCreator(FACIAL_RECOGNITION_POI_GENERATE_APPEARANCE_REQUEST_FAILURE, 'error');
export const facialRecognitionPoiSearchRequest = makeActionCreator(FACIAL_RECOGNITION_POI_SEARCH_REQUEST, 'incidentId', 'poiScan');
export const facialRecognitionPoiSearchRequestSuccess = makeActionCreator(FACIAL_RECOGNITION_POI_SEARCH_REQUEST_SUCCESS, 'poiScan');
export const facialRecognitionPoiSearchRequestFailure = makeActionCreator(FACIAL_RECOGNITION_POI_SEARCH_REQUEST_FAILURE, 'error');

export const facialRecognitionPoiExpiryFailureRequest = makeActionCreator(FACIAL_RECOGNITION_POI_EXPIRY_FAILURES_REQUEST, 'page', 'pageSize', 'filter');
export const facialRecognitionPoiExpiryFailureRequestSuccess = makeActionCreator(FACIAL_RECOGNITION_POI_EXPIRY_FAILURES_REQUEST_SUCCESS, 'data', 'paging', 'filter');
export const facialRecognitionPoiExpiryFailureRequestFailure = makeActionCreator(FACIAL_RECOGNITION_POI_EXPIRY_FAILURES_REQUEST_FAILURE, 'error');

export const facialRecognitionPoiExpiryRetryRequest = makeActionCreator(FACIAL_RECOGNITION_POI_EXPIRY_RETRY_REQUEST, 'filter', 'facialRecognitionPOIIncidentId');
export const facialRecognitionPoiExpiryRetryRequestSuccess = makeActionCreator(FACIAL_RECOGNITION_POI_EXPIRY_RETRY_REQUEST_SUCCESS);
export const facialRecognitionPoiExpiryRetryRequestFailure = makeActionCreator(FACIAL_RECOGNITION_POI_EXPIRY_RETRY_REQUEST_FAILURE, 'error');

export const facialRecognitionPoiExpiryDismissRequest = makeActionCreator(FACIAL_RECOGNITION_POI_EXPIRY_DISMISS_REQUEST, 'facialRecognitionPOIIncidentId');
export const facialRecognitionPoiExpiryDismissRequestSuccess = makeActionCreator(FACIAL_RECOGNITION_POI_EXPIRY_DISMISS_REQUEST_SUCCESS);
export const facialRecognitionPoiExpiryDismissRequestFailure = makeActionCreator(FACIAL_RECOGNITION_POI_EXPIRY_DISMISS_REQUEST_FAILURE, 'error');

export const facialRecognitionPoiWatchlistRegistrationRequest = makeActionCreator(FACIAL_RECOGNITION_POI_WATCHLIST_REGISTRATION_REQUEST, 'forVenueId', 'forGroupId', 'facialRecognitionWatchlistId', 'poiGuid');
export const facialRecognitionPoiWatchlistRegistrationRequestSuccess = makeActionCreator(FACIAL_RECOGNITION_POI_WATCHLIST_REGISTRATION_REQUEST_SUCCESS, 'poiRegistration');
export const facialRecognitionPoiWatchlistRegistrationRequestFailure = makeActionCreator(FACIAL_RECOGNITION_POI_WATCHLIST_REGISTRATION_REQUEST_FAILURE, 'error');
export const facialRecognitionPoiWatchlistRegistrationReset = makeActionCreator(FACIAL_RECOGNITION_POI_WATCHLIST_REGISTRATION_RESET);
export const facialRecognitionPoiWatchlistRegistrationSet = makeActionCreator(FACIAL_RECOGNITION_POI_WATCHLIST_REGISTRATION_SET, 'poiRegistration');
export const facialRecognitionPoiWatchlistRegistrationSaveRequest = makeActionCreator(FACIAL_RECOGNITION_POI_WATCHLIST_REGISTRATION_SAVE_REQUEST);
export const facialRecognitionPoiWatchlistRegistrationSaveRequestSuccess = makeActionCreator(FACIAL_RECOGNITION_POI_WATCHLIST_REGISTRATION_SAVE_REQUEST_SUCCESS, 'poiRegistration');
export const facialRecognitionPoiWatchlistRegistrationSaveRequestFailure = makeActionCreator(FACIAL_RECOGNITION_POI_WATCHLIST_REGISTRATION_SAVE_REQUEST_FAILURE, 'error');

export const facialRecognitionPoiScannedIDRequest =          makeActionCreator(FACIAL_RECOGNITION_POI_SCANNED_ID_REQUEST, 'forVenueId', 'poiGuid');
export const facialRecognitionPoiScannedIDRequestSuccess =   makeActionCreator(FACIAL_RECOGNITION_POI_SCANNED_ID_REQUEST_SUCCESS, 'data');
export const facialRecognitionPoiScannedIDRequestFailure =   makeActionCreator(FACIAL_RECOGNITION_POI_SCANNED_ID_REQUEST_FAILURE, 'error', 'originalAction');
