import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import SecurityFirmContainer from '../../containers/SecurityFirmContainer';
import SecurityFirmUsersContainer from '../../containers/SecurityFirmUsersContainer';
import SecurityFirmUserContainer from '../../containers/SecurityFirmUserContainer';
import SecurityFirmContactsContainer from '../../containers/SecurityFirmContactsContainer';
import SecurityFirmSubscriptionContainer from '../../containers/SecurityFirmSubscriptionContainer';
import SecurityFirmEditContainer from '../../containers/SecurityFirmEditContainer';
import SecurityFirmDocumentsContainer from '../../containers/SecurityFirmDocumentsContainer';
import SecurityFirmUserComplianceDocumentsExpiredContainer from '../../containers/SecurityFirmUserComplianceDocumentsExpiredContainer';
import SecurityFirmUserComplianceDocumentsMissingContainer from '../../containers/SecurityFirmUserComplianceDocumentsMissingContainer';
import SecurityFirmSystemLogContainer from '../../containers/SecurityFirmSystemLogContainer';
import SecurityFirmIncidentCategoryTypesContainer from '../../containers/SecurityFirmIncidentCategoryTypesContainer';
import SecurityFirmVenuesContainer from '../../containers/SecurityFirmVenuesContainer';
import SecurityFirmAssociationsContainer from '../../containers/SecurityFirmAssociationsContainer';
import SecurityFirmChecklistTemplatesContainer from '../../containers/SecurityFirmChecklistTemplatesContainer';
import CheckListTemplateContainer from '../../containers/CheckListTemplateContainer';
import SecurityFirmRoleTypesContainer from '../../containers/SecurityFirmRoleTypesContainer';
import SecurityFirmBroadcastNotificationContainer from '../../containers/SecurityFirmBroadcastNotificationContainer';
import SecurityFirmBroadcastNotificationsContainer from '../../containers/SecurityFirmBroadcastNotificationsContainer';
import SecurityFirmDirectoryContainer from '../../containers/SecurityFirmDirectoryContainer';
import SecurityFirmSchedulesContainer from '../../containers/SecurityFirmSchedulesContainer';
import SecurityFirmScheduleContainer from '../../containers/SecurityFirmScheduleContainer';
import SecurityFirmScheduleLogsContainer from '../../containers/SecurityFirmScheduleLogsContainer';

export default class SecurityFirmRoute extends Component<any, any> {

    render() {
        return (
            <Switch>
                <Route path={`${this.props.match.url}/edit`} component={SecurityFirmEditContainer} />
                <Route path={`${this.props.match.url}/users`} component={SecurityFirmUsersContainer} />
                <Route path={`${this.props.match.url}/roles`} component={SecurityFirmRoleTypesContainer} />
                <Route path={`${this.props.match.url}/contacts`} component={SecurityFirmContactsContainer} />
                <Route path={`${this.props.match.url}/user/:userId`} component={SecurityFirmUserContainer} />
                <Route path={`${this.props.match.url}/checklistTemplates/:checklistTemplateId`} component={CheckListTemplateContainer} />
                <Route path={`${this.props.match.url}/checklistTemplates`} component={SecurityFirmChecklistTemplatesContainer} />
                <Route path={`${this.props.match.url}/documents`} component={SecurityFirmDocumentsContainer} />
                <Route path={`${this.props.match.url}/directory`} component={SecurityFirmDirectoryContainer} />
                <Route path={`${this.props.match.url}/subscription`} component={SecurityFirmSubscriptionContainer} />
                <Route path={`${this.props.match.url}/expiringcompliancedocuments`} component={SecurityFirmUserComplianceDocumentsExpiredContainer} />
                <Route path={`${this.props.match.url}/expiringcompliancedocument/:userId`} component={SecurityFirmUserContainer} />
                <Route path={`${this.props.match.url}/missingcompliancedocuments`} component={SecurityFirmUserComplianceDocumentsMissingContainer} />
                <Route path={`${this.props.match.url}/missingcompliancedocument/:userId`} component={SecurityFirmUserContainer} />
                <Route path={`${this.props.match.url}/incidentcategorytypes`} component={SecurityFirmIncidentCategoryTypesContainer} />
                <Route path={`${this.props.match.url}/systemlog`} component={SecurityFirmSystemLogContainer} />
                <Route path={`${this.props.match.url}/venues`} component={SecurityFirmVenuesContainer} />
                <Route path={`${this.props.match.url}/associations`} component={SecurityFirmAssociationsContainer} />
                <Route path={`${this.props.match.url}/broadcastnotifications/:broadcastnotificationId`} component={SecurityFirmBroadcastNotificationContainer} />
                <Route path={`${this.props.match.url}/broadcastnotifications`} component={SecurityFirmBroadcastNotificationsContainer} />
                <Route path={`${this.props.match.url}/schedules/:scheduleId/logs`} component={SecurityFirmScheduleLogsContainer} />
                <Route path={`${this.props.match.url}/schedules/:scheduleId/:copyScheduleId`} component={SecurityFirmScheduleContainer} />
                <Route path={`${this.props.match.url}/schedules`} component={SecurityFirmSchedulesContainer} />
                <Route path={`${this.props.match.url}`} component={SecurityFirmContainer} />
                <Redirect to={`${this.props.match.url}`} />
            </Switch>
        );
    }
}