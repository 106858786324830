import React from 'react';
import View from '../../common/View';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import TextControlGroup from '../../controls/TextControlGroup';
import TransparentButton from '../../controls/TransparentButton';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import LayoutForm from '../../layout/LayoutForm';
import DefaultButton from '../../controls/DefaultButton';
import Box from '@material-ui/core/Box';
import PersonIcon from '../../../resources/PersonIcon';
import ListIcon from '../../../resources/ListIcon';
import SelectControlGroup from '../../controls/SelectControlGroup';

export interface IFacialRecognitionPOIsFilterProps {
    filter: AusComplyDTOs.FacialRecognitionWatchlistPoisFilter;
    //onUpdateFilter: Function;
    watchlistSelection?: boolean;
    thumbnail: boolean;
    onThumbnail: Function;
    onReset: Function;
    onSearch: Function;
}

interface IFacialRecognitionPOIsFilterState {
    filter: AusComplyDTOs.FacialRecognitionWatchlistPoisFilter;
}

export default class FacialRecognitionPOIsFilter extends React.Component<IFacialRecognitionPOIsFilterProps, IFacialRecognitionPOIsFilterState> {
    constructor(props: IFacialRecognitionPOIsFilterProps) {
        super(props);
        this.onResetSearch = this.onResetSearch.bind(this);
        this.onChange = this.onChange.bind(this);

        this.state = {
            filter: new AusComplyDTOs.FacialRecognitionWatchlistPoisFilter()
        };
    }

    componentDidMount() {
        this.setState({ filter: this.props.filter });
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.filter) != JSON.stringify(this.props.filter)) {
            this.setState({ filter: this.props.filter });
        }
    }

    onResetSearch() {
        if (this.props.onReset) {
            this.props.onReset();
        }
    }

    onChange(fieldName, value, submit) {
        let filter = { ... this.state.filter };
        filter[fieldName] = value;
        this.setState({ filter }, () => {
            if (submit) {
                this.props.onSearch(this.state.filter);
            }
            //this.props.onUpdateFilter(this.state.filter);
        });
    }

    render() {
        if (!this.state.filter) {
            return (
                <View></View>
            );
        }

        let watchlist;
        if (this.props.watchlistSelection && this.state.filter.watchlists) {
            watchlist = (<SelectControlGroup
                text={"Watchlist"}
                labelAbove={true}
                onChange={(value) => this.onChange('facialRecognitionWatchlistId', value, true)}
                defaultValue={this.state.filter.facialRecognitionWatchlistId}>
                <option value={0}>All</option>
                {this.state.filter.watchlists.map((watchlist, index) => (
                    <option key={watchlist.facialRecognitionWatchlistId} value={watchlist.facialRecognitionWatchlistId}>{watchlist.displayName}</option>
                ))}
            </SelectControlGroup>);
        }

        return <Box display="flex" flexDirection="row" style={{ marginTop: '20px' }}>
            <Box p={1} flexGrow={1}>
                <TextControlGroup text={"Search"}
                    labelAbove={true}
                    defaultValue={this.state.filter.search}
                    onSubmit={value => {
                        this.onChange('search', value, true);
                    }}
                    onChange={value => this.onChange('search', value, false)} />
            </Box>
            <Box p={1} flexGrow={0}>
                {watchlist}
            </Box>
            <Box p={1} flexGrow={0}>
                <Box display="flex" flexDirection="column" style={{ height: '100%' }}>
                    <Box flex={1}></Box>
                    <Box flex={0}>
                        <CheckboxControlGroup text="Expired" defaultValue={this.state.filter.showObsolete} onChanged={value => this.onChange('showObsolete', value, false)} />
                    </Box>
                    <Box flex={1}></Box>
                </Box>
            </Box>
            <Box p={1} flexGrow={0}>
                <Box display="flex" flexDirection="column" style={{ height: '100%' }}>
                    <Box flex={1}>

                    </Box>
                    <Box flex={0}>
                        <Box display="flex" flexDirection="row" style={{ height: '100%' }}>
                            <Box flex={0}>
                                {this.props.thumbnail && (
                                    <Box display="flex" flexDirection="row" style={{ height: '100%', paddingRight: '10px' }}>
                                        <Box flex={1}>
                                            <TransparentButton onClick={() => this.props.onThumbnail(false)}><ListIcon style={{ fontSize: '1.5em' }} /></TransparentButton>
                                        </Box>
                                        <Box flex={1}>
                                            <DefaultButton onClick={() => this.props.onThumbnail(true)} ><PersonIcon style={{ fontSize: '1.5em' }} /></DefaultButton>
                                        </Box>
                                    </Box>
                                )}
                                {!this.props.thumbnail && (
                                    <Box display="flex" flexDirection="row" style={{ height: '100%', paddingRight: '10px' }}>
                                        <Box flex={1}>
                                            <DefaultButton onClick={() => this.props.onThumbnail(false)} ><ListIcon style={{ fontSize: '1.5em' }} /></DefaultButton>
                                        </Box>
                                        <Box flex={1}>
                                            <TransparentButton onClick={() => this.props.onThumbnail(true)} ><PersonIcon style={{ fontSize: '1.5em' }} /></TransparentButton>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                            <Box flex={1}>
                                <TransparentButton text={"Reset"} onClick={this.onResetSearch} />
                            </Box>
                            <Box flex={1}>
                                <DefaultButton text={"Search"} onClick={() => this.props.onSearch(this.state.filter)} />
                            </Box>
                        </Box>
                    </Box>
                    <Box flex={1}>

                    </Box>
                </Box>
            </Box>
        </Box>

        return (
            <LayoutForm fullWidth={true}>
                <Grid container spacing={1} style={{ marginTop: '20px' }}>
                    <Grid item md={6} xs={8}>
                        <TextControlGroup text={"Search"}
                            labelAbove={false}
                            defaultValue={this.state.filter.search}
                            onSubmit={value => {
                                this.onChange('search', value, true);
                            }}
                            onChange={value => this.onChange('search', value, false)} />
                    </Grid>
                    <Grid item md={2} xs={4}>
                        <CheckboxControlGroup text="Expired" defaultValue={this.state.filter.showObsolete} onChanged={value => this.onChange('showObsolete', value, false)} />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Box display="flex" flexDirection="column" style={{ height: '100%' }}>
                            <Box flex={1}>

                            </Box>
                            <Box flex={0}>
                                <Box display="flex" flexDirection="row" style={{ height: '100%' }}>
                                    <Box flex={0}>
                                        {this.props.thumbnail && (
                                            <Box display="flex" flexDirection="row" style={{ height: '100%', paddingRight: '10px' }}>
                                                <Box flex={1}>
                                                    <TransparentButton onClick={() => this.props.onThumbnail(false)}><ListIcon style={{ fontSize: '1.5em' }} /></TransparentButton>
                                                </Box>
                                                <Box flex={1}>
                                                    <DefaultButton onClick={() => this.props.onThumbnail(true)} ><PersonIcon style={{ fontSize: '1.5em' }} /></DefaultButton>
                                                </Box>
                                            </Box>
                                        )}
                                        {!this.props.thumbnail && (
                                            <Box display="flex" flexDirection="row" style={{ height: '100%', paddingRight: '10px' }}>
                                                <Box flex={1}>
                                                    <DefaultButton onClick={() => this.props.onThumbnail(false)} ><ListIcon style={{ fontSize: '1.5em' }} /></DefaultButton>
                                                </Box>
                                                <Box flex={1}>
                                                    <TransparentButton onClick={() => this.props.onThumbnail(true)} ><PersonIcon style={{ fontSize: '1.5em' }} /></TransparentButton>
                                                </Box>
                                            </Box>
                                        )}
                                    </Box>
                                    <Box flex={1}>
                                        <TransparentButton text={"Reset"} onClick={this.onResetSearch} />
                                    </Box>
                                    <Box flex={1}>
                                        <DefaultButton text={"Search"} onClick={() => this.props.onSearch(this.state.filter)} />
                                    </Box>
                                </Box>
                            </Box>
                            <Box flex={1}>

                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </LayoutForm>
        );


    }
}