
import * as AusComplyDtos from "../dto/AusComply.dtos";
import { RegexConstants } from '../constants/regex';
import { isLatitude, isLongitude } from '../constants/validators';
import { KeyName } from '../../common/dto/common';

export const securityFirmLogic = {
    validateRegisterSecurityFirm(
        securityFirm: AusComplyDtos.SecurityFirm,
        managerContact: AusComplyDtos.SecurityFirmContact,
        primaryContact: AusComplyDtos.SecurityFirmContact) {
        var errors = {};
        var count = 0;

        if (securityFirm) {

            if (!securityFirm.stateLicensedId || securityFirm.stateLicensedId == 0) {
                errors["stateLicensedId"] = "Required";
                count++;
            }
            
            if (!securityFirm.name || securityFirm.name === "") {
                errors["name"] = "Required";
                count++;
            }

            if (!securityFirm.masterLicenceNumber || securityFirm.masterLicenceNumber === "") {
                errors["masterLicenceNumber"] = "Required";
                count++;
            } else {
                if (!RegexConstants.MASTER_LICENCE_NUMBER.test(securityFirm.masterLicenceNumber)) {
                    errors["masterLicenceNumber"] = RegexConstants.MASTER_LICENCE_NUMBER_DESCRIPTION;
                    count++;
                }
            }

            if (!securityFirm.securityLicenceClassId || securityFirm.securityLicenceClassId == 0) {
                errors["securityLicenceClassId"] = "Required";
                count++;
            }

            if (!securityFirm.mlhNominatedPerson || securityFirm.mlhNominatedPerson === "") {
                errors["mlhNominatedPerson"] = "Required";
                count++;
            }

            if (!securityFirm.masterLicenceExpiryDate || securityFirm.masterLicenceExpiryDate.isEmpty) {
                errors["masterLicenceExpiryDate"] = "Required";
                count++;
            }

            if (!securityFirm.abn || securityFirm.abn === "") {
                errors["abn"] = "Required";
                count++;
            } else {
                if (!RegexConstants.ABN.test(securityFirm.abn)) {
                    errors["abn"] = RegexConstants.ABN_DESCRIPTION;
                    count++;
                }
            }

            if (!securityFirm.phone || securityFirm.phone === "") {
                errors["phone"] = "Required";
                count++;
            } else {
                if (!RegexConstants.PHONE.test(securityFirm.phone)) {
                    errors["phone"] = RegexConstants.PHONE_DESCRIPTION;
                    count++;
                }
            }

            if (!securityFirm.address || securityFirm.address === "") {
                errors["address"] = "Required";
                count++;
            }

            if (!securityFirm.suburb || securityFirm.suburb === "") {
                errors["suburb"] = "Required";
                count++;
            }

            if (!securityFirm.stateId || securityFirm.stateId == 0) {
                errors["stateId"] = "Required";
                count++;
            }

            if (!securityFirm.postcode || securityFirm.postcode === "") {
                errors["postcode"] = "Required";
                count++;
            } else {
                if (!RegexConstants.POST_CODE.test(securityFirm.postcode)) {
                    errors["postcode"] = RegexConstants.POST_CODE_DESCRIPTION;
                    count++;
                }
            }

            if (primaryContact && managerContact && this.showPrimaryContactDetails(primaryContact, managerContact)) {
                if (!primaryContact.firstname || primaryContact.firstname === "") {
                    errors["primaryContact.firstname"] = "Required";
                    count++;
                }

                if (!primaryContact.lastname || primaryContact.lastname === "") {
                    errors["primaryContact.lastname"] = "Required";
                    count++;
                }

                if (!primaryContact.phone || primaryContact.phone === "") {
                    errors["primaryContact.phone"] = "Required";
                    count++;
                } else {
                    if (!RegexConstants.PHONE.test(primaryContact.phone)) {
                        errors["primaryContact.phone"] = RegexConstants.PHONE_DESCRIPTION;
                        count++;
                    }
                }

                if (!primaryContact.email || primaryContact.email === "") {
                    errors["primaryContact.email"] = "Required";
                    count++;
                } else {
                    if (!RegexConstants.EMAIL.test(primaryContact.email)) {
                        errors["primaryContact.email"] = RegexConstants.EMAIL_DESCRIPTION;
                        count++;
                    }
                }
            }
            if (managerContact && primaryContact && this.showLicenseeContactDetails(primaryContact, managerContact)) {
                if (!managerContact.firstname || managerContact.firstname === "") {
                    errors["managerContact.firstname"] = "Required";
                    count++;
                }

                if (!managerContact.lastname || managerContact.lastname === "") {
                    errors["managerContact.lastname"] = "Required";
                    count++;
                }

                if (!managerContact.phone || managerContact.phone === "") {
                    errors["managerContact.phone"] = "Required";
                    count++;
                } else {
                    if (!RegexConstants.PHONE.test(managerContact.phone)) {
                        errors["managerContact.phone"] = RegexConstants.PHONE_DESCRIPTION;
                        count++;
                    }
                }

                if (!managerContact.email || managerContact.email === "") {
                    errors["managerContact.email"] = "Required";
                    count++;
                } else {
                    if (!RegexConstants.EMAIL.test(managerContact.email)) {
                        errors["managerContact.email"] = RegexConstants.EMAIL_DESCRIPTION;
                        count++;
                    }
                }
            }
        }
        errors["count"] = count;
        return errors;
    },
    validateSecurityFirm(
        securityFirm: AusComplyDtos.SecurityFirm) {
        var errors = {};
        var count = 0;

        if (securityFirm) {

            if (!securityFirm.stateLicensedId || securityFirm.stateLicensedId == 0) {
                errors["stateLicensedId"] = "Required";
                count++;
            }
            
            if (!securityFirm.name || securityFirm.name === "") {
                errors["name"] = "Required";
                count++;
            }

            if (!securityFirm.masterLicenceNumber || securityFirm.masterLicenceNumber === "") {
                errors["masterLicenceNumber"] = "Required";
                count++;
            } else {
                if (!RegexConstants.MASTER_LICENCE_NUMBER.test(securityFirm.masterLicenceNumber)) {
                    errors["abn"] = RegexConstants.MASTER_LICENCE_NUMBER_DESCRIPTION;
                    count++;
                }
            }

            if (!securityFirm.securityLicenceClassId || securityFirm.securityLicenceClassId == 0) {
                errors["securityLicenceClassId"] = "Required";
                count++;
            }

            if (!securityFirm.mlhNominatedPerson || securityFirm.mlhNominatedPerson === "") {
                errors["mlhNominatedPerson"] = "Required";
                count++;
            }

            if (!securityFirm.masterLicenceExpiryDate || securityFirm.masterLicenceExpiryDate.isEmpty) {
                errors["masterLicenceExpiryDate"] = "Required";
                count++;
            }

            if (!securityFirm.abn || securityFirm.abn === "") {
                errors["abn"] = "Required";
                count++;
            } else {
                if (!RegexConstants.ABN.test(securityFirm.abn)) {
                    errors["abn"] = RegexConstants.ABN_DESCRIPTION;
                    count++;
                }
            }

            if (!securityFirm.phone || securityFirm.phone === "") {
                errors["phone"] = "Required";
                count++;
            } else {
                if (!RegexConstants.PHONE.test(securityFirm.phone)) {
                    errors["phone"] = RegexConstants.PHONE_DESCRIPTION;
                    count++;
                }
            }

            if (!securityFirm.address || securityFirm.address === "") {
                errors["address"] = "Required";
                count++;
            }

            if (!securityFirm.suburb || securityFirm.suburb === "") {
                errors["suburb"] = "Required";
                count++;
            }

            if (!securityFirm.stateId || securityFirm.stateId == 0) {
                errors["stateId"] = "Required";
                count++;
            }

            if (!securityFirm.postcode || securityFirm.postcode === "") {
                errors["postcode"] = "Required";
                count++;
            } else {
                if (!RegexConstants.POST_CODE.test(securityFirm.postcode)) {
                    errors["postcode"] = RegexConstants.POST_CODE_DESCRIPTION;
                    count++;
                }
            }
        }
        errors["count"] = count;
        return errors;
    },
    showLicenseeContactDetails(
        primaryContact: AusComplyDtos.SecurityFirmContact,
        managerContact: AusComplyDtos.SecurityFirmContact) {
        return !managerContact.obsolete;
    },
    showLicenseeIsPrimaryContactDetails(
        primaryContact: AusComplyDtos.SecurityFirmContact,
        managerContact: AusComplyDtos.SecurityFirmContact) {
        return !primaryContact.obsolete && !managerContact.obsolete;
    },
    showPrimaryContactDetails(
        primaryContact: AusComplyDtos.SecurityFirmContact,
        managerContact: AusComplyDtos.SecurityFirmContact) {
        if (!primaryContact.obsolete && managerContact.obsolete) {
            return true;
        } else if (!managerContact.obsolete) {
            if (!managerContact.primaryContact && !primaryContact.obsolete) {
                return true;
            }
        }
        return false;
    },
    getAdminCommands(securityFirm: AusComplyDtos.SecurityFirm) {
        var commands: KeyName[] = [];
        commands.push({ key: "edit", name: "Edit" });
        if (!securityFirm.registrationComplete) {
            commands.push({ key: "dismiss", name: "Dismiss Registration" });
        } else if (!securityFirm.obsolete) {
            commands.push({ key: "remove", name: "Remove" });
        }
        return commands;
    }
}