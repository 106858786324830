import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import { Incident as IncidentDTO, IncidentOptions as IncidentOptionsDTO } from "../../common/dto/AusComply.dtos";
import { withRouter } from "react-router";
import { incidentLogic } from '../../common/logic/incidentLogic';
import IncidentViewDates from './view/IncidentViewDates';
import IncidentViewSnapshot from './view/IncidentViewSnapshot';
import IncidentViewType from './view/IncidentViewType';
import IncidentViewCategories from './view/IncidentViewCategories';
import IncidentViewLocations from './view/IncidentViewLocations';
import IncidentViewPersonnel from './view/IncidentViewPersonnel';
import IncidentViewNarrative from './view/IncidentViewNarrative';
import IncidentViewNumber from './view/IncidentViewNumber';
import IncidentViewRelated from './view/IncidentViewRelated';
import IncidentViewFlags from './view/IncidentViewFlags';
import IncidentViewDocuments from './view/IncidentViewDocuments';
import IncidentViewParent from './view/IncidentViewParent';
import IncidentViewPeopleAndGroups from './view/IncidentViewPeopleAndGroups';
import IncidentViewFacialRecognition from './view/IncidentViewFacialRecognition';
import IncidentViewChecklists from './view/IncidentViewChecklists';
import IncidentViewNotifiedUsers from './view/IncidentViewNotifiedUsers';
import IncidentViewRadioChannelFunctionalArea from './view/IncidentViewRadioChannelFunctionalArea';
import ControlGroup from '../controls/ControlGroup';
import SecondaryFab from '../common/SecondaryFab';
import IncidentPrintContainer from '../../containers/IncidentPrintContainer';
import FacialRecognitionPOIRegistrationContainer from '../../containers/FacialRecognitionPOIRegistrationContainer';
import FacialRecognitionPOISearchContainer from '../../containers/FacialRecognitionPOISearchContainer';
import IncidentViewPOIsContainer from '../../containers/IncidentViewPOIsContainer';
import PageLayout from '../layout/PageLayout';
import TransparentButton from '../controls/TransparentButton';
import PrimaryButton from '../controls/PrimaryButton';
import IncidentCategoryTypeDocumentsContainer from '../../containers/IncidentCategoryTypeDocumentsContainer';
import { KeyName } from '../../common/dto/common';
import IncidentViewOtherVenue from './view/IncidentViewOtherVenue';
import TextDisplayExpanded from '../controls/TextDisplayExpanded';


export interface IIncidentViewProps {
    incidentId: number;
    isLoading: boolean;
    isSaving: boolean;
    error: string;
    incident: IncidentDTO;
    incidentOptions?: IncidentOptionsDTO;
    onLoadIncident: Function;
    onClear: Function;
    onClearError: Function;
    onPrint: Function;
    onApprove: Function;
    onErrorNotification: Function;
    canShowVersions?: boolean;
    canViewUniqueIncidentID: boolean;
    canCreateUniqueIncidentID: boolean;
    onCreateUniqueID: Function;
    isGeneratingUniqueID: boolean;
    history: any;
    location: any;
    classes: any;
    theme: any;
}

export interface IIncidentViewState {
    anchorEl: any;
}

class IncidentView extends Component<IIncidentViewProps, IIncidentViewState> {

    constructor(props: IIncidentViewProps) {
        super(props)
        this.state = {
            anchorEl: null
        };

        this.onCancel = this.onCancel.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onApprove = this.onApprove.bind(this);
        this.onClearError = this.onClearError.bind(this);
        this.onPrint = this.onPrint.bind(this);
        this.onCommand = this.onCommand.bind(this);
    }

    componentDidMount() {
        // eslint-disable-next-line
        if (!this.props.isLoading && this.props.incident && this.props.incident.incidentId != this.props.incidentId && this.props.incidentId != 0) {
            this.props.onLoadIncident(this.props.incidentId);
        }
    }

    componentDidUpdate(preProps) {
        if (!this.props.isSaving && preProps.isSaving) {
            // saving completed, i.e. approving
            if (!this.props.error) {
                // Saved successfully
                this.onCancel();
            }
        }
        if (!this.props.isGeneratingUniqueID && preProps.isGeneratingUniqueID){
            if (this.props.incident && this.props.incident.incidentId != this.props.incidentId){
                this.props.history.push('/incidentview/' + this.props.incident.incidentId);
            }
        } else if (!this.props.isLoading && preProps.isLoading && this.props.incident && this.props.incident.incidentId != this.props.incidentId && this.props.incident.incidentId == 0) {
            this.onCancel();
            // eslint-disable-next-line
        } else if (!this.props.isLoading && this.props.incident && this.props.incident.incidentId != this.props.incidentId && this.props.incidentId != 0) {
            this.props.onLoadIncident(this.props.incidentId);
        } else if (!this.props.isLoading && this.props.incident && this.props.incident.incidentId == this.props.incidentId ) {
            // check to see if this is a draft incident
            if (!this.props.incident.completed) {
                //debugger;
                if(this.props.incident.editAllowed) {
                    
                    if (this.props.incident.editPage == "CashTraxEdit") {
                        this.props.history.push('/cashtrax/' + this.props.incident.incidentId);
                    } else if (this.props.incident.editPage == "PlayTraxEdit"){
                        this.props.history.push('/playtrax/' + this.props.incident.incidentId);
                    } else {
                        this.props.history.push('/incident/' + this.props.incident.incidentId);
                    }
                    /*
                    this.props.history.push('/incident/' + this.props.incident.incidentId);
                    */
                } else {
                    this.onCancel();
                }
            }
        }
    }

    onEdit() {
        //if (this.props.incident.editPage !== undefined && this.props.incident.editPage !== "") {
        //    this.props.history.push('/' + this.props.incident.editPage + '/' + this.props.incident.incidentId);
        //} else {
        this.props.history.push('/incident/' + this.props.incident.incidentId);
        //}
    }

    onClearError() {
        //this.props.onClearError();
    }

    onCancel() {
        let url: string = "/incidents";
        if (this.props.incident && this.props.incidentOptions) {
            if (incidentLogic.isRGO(this.props.incident, this.props.incidentOptions)) {
                url = "/gamblingincidentregister";
            }
        }
        if (this.props.onClear) {
            this.props.onClear();
        }
        this.props.history.push(url);
    }

    onApprove() {
        if (this.props.incidentOptions) {
            var validationResult = incidentLogic.validateIncident(this.props.incident, this.props.incidentOptions);
            if (validationResult.submitOk) {
                this.props.onApprove();
            } else {
                this.props.onErrorNotification(validationResult.error);
            }
        } else {
            this.props.onApprove();
        }
    }

    onPrint() {
        this.props.onPrint();
    }

    onCommand(command) {
        switch (command) {
            case "print":
                this.props.onPrint();
                break;
        }
    }

    render() {
        let moreCommands: KeyName[] = [];
        moreCommands.push({ key: "print", name: "Print / Download" });
        let otherVenueIncident: boolean = false;
        if (this.props.incident.accessViaVenueId && this.props.incident.accessViaVenueId > 0)
        {
            otherVenueIncident = true;
        }
        return (
            <PageLayout
                headerText={"View Incident"}
                loadingText={"Loading incident"}
                footerLeftContent={<TransparentButton text={"Close"} onClick={() => this.onCancel()} />}
                footerCenterContent={<PrimaryButton text={"Edit"} onClick={() => this.onEdit()} disabled={this.props.isLoading || (this.props.incident && !this.props.incident.editAllowed)} />}
                loading={this.props.isLoading || this.props.incident.incidentId != this.props.incidentId}
                saving={this.props.isSaving}
                error={this.props.error}
                moreCommands={moreCommands}
                onCommand={this.onCommand}
                onClearError={this.props.onClearError}
            >
                <IncidentViewOtherVenue incident={this.props.incident} />
                <IncidentViewNumber 
                    incident={this.props.incident} 
                    canViewUniqueIncidentID={this.props.canViewUniqueIncidentID} 
                    canCreateUniqueIncidentID={this.props.canCreateUniqueIncidentID}
                    onCreateUniqueID={this.props.onCreateUniqueID} 
                    isGeneratingUniqueID={this.props.isGeneratingUniqueID}
                    />
                <IncidentViewRadioChannelFunctionalArea incident={this.props.incident} />
                <IncidentViewDates incident={this.props.incident} history={this.props.history} canShowVersions={!otherVenueIncident && this.props.canShowVersions} />
                <IncidentViewType incident={this.props.incident} />
                <IncidentViewDocuments incident={this.props.incident} />
                <IncidentViewCategories incident={this.props.incident} />
                <IncidentViewSnapshot incident={this.props.incident} />
                <IncidentViewLocations incident={this.props.incident} />
                <IncidentViewPeopleAndGroups incident={this.props.incident} />
                <IncidentViewPersonnel incident={this.props.incident} />
                <IncidentViewNarrative incident={this.props.incident} />
                <IncidentViewFlags incident={this.props.incident} />
                <IncidentViewParent incident={this.props.incident} history={this.props.history} canShowVersions={this.props.canShowVersions} />
                <IncidentViewRelated incident={this.props.incident} canView={true} />
                <IncidentViewChecklists incident={this.props.incident} openIncident={() => this.onEdit()} />
                <IncidentViewNotifiedUsers incident={this.props.incident} />
                <IncidentViewFacialRecognition facialRecognitionNotification={this.props.incident.facialRecognitionNotification} />
                <IncidentViewPOIsContainer poiGuids={this.props.incident.poiGuids} />
                <TextDisplayExpanded title="Event Data" text={this.props.incident.externalEventData} />
                <ControlGroup text={" "} style={{ marginTop: '30px' }}>
                    {(!this.props.incident.approved && this.props.incident.canApprove) && (
                        <SecondaryFab isExtended={true} onClick={this.onApprove}>
                            Approve
                        </SecondaryFab>
                    )}
                    {(!this.props.incident.accessViaVenueId) && (
                        <>
                        <FacialRecognitionPOISearchContainer incidentId={this.props.incidentId} />
                        <FacialRecognitionPOIRegistrationContainer incidentId={this.props.incidentId} />
                        </>
                    )}
                </ControlGroup>
                <IncidentCategoryTypeDocumentsContainer />
                <IncidentPrintContainer />
            </PageLayout>
        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(IncidentView));