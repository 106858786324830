import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { permissionsLogic } from '../common/logic/permissionsLogic';
import VenueFacialRecognitionPOIs from '../components/venue/facialRecognitionNotifications/VenueFacialRecognitionPOIs'

const mapStateToProps = (state, props) => {
    return {
        venueId: state.user.details.userSession.user.venueId
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

const VenueFacialRecognitionPOIsContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(VenueFacialRecognitionPOIs))

export default VenueFacialRecognitionPOIsContainer