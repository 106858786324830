import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PrimaryButton from '../../controls/PrimaryButton';
import DefaultButton from '../../controls/DefaultButton';
import ControlGroup from '../../controls/ControlGroup';
import TextControlGroup from '../../controls/TextControlGroup';
import TextDisplayControlGroup from '../../controls/TextDisplayControlGroup';
import HourMinuteControlGroup from '../../controls/HourMinuteControlGroup';
import SimpleDateControlGroup from '../../controls/SimpleDateControlGroup';
import PageLayout from '../../layout/PageLayout';
import Spacer from '../../common/Spacer';
import View from '../../common/View';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import { stat } from 'fs';

export interface IFacialRecognitionStatisticsProps {
    statistics: AusComplyDtos.FacialRecognitionNotificationStatistics;
    isLoading: boolean;
    fromDate: AusComplyDtos.SimpleDate;
    rangeAsMinutes: number;
    onSet: Function;
    onLoad: Function;
}

export default class FacialRecognitionStatistics extends Component<IFacialRecognitionStatisticsProps, any> {
    constructor(props: IFacialRecognitionStatisticsProps) {
        super(props)
        this.state = {
        }
    }

    render() {
        const { statistics, isLoading, fromDate, onSet, onLoad, rangeAsMinutes } = this.props;
        let self = this;

        return (
            <PageLayout
                loading={isLoading}
                notFullWidth={true}
                headerText={"Facial Recognition Statistics"}>
                <CardContainer
                    title={"Statistics for 1 hour"}>
                    <CardContent style={{ paddingTop: 0 }}>
                        <SimpleDateControlGroup
                            text='From Date'
                            date={fromDate}
                            onChange={value => {
                                value.hour = fromDate.hour;
                                value.minute = fromDate.minute;
                                onSet(value, rangeAsMinutes);
                            }}
                        />
                        <HourMinuteControlGroup
                            text={"From Time"}
                            hourValue={fromDate.hour}
                            minuteValue={fromDate.minute}
                            onChange={(value) => {
                                let newFromDate = { ...fromDate };
                                if (value.isEmpty) {
                                    newFromDate.hour = 0;
                                    newFromDate.minute = 0;

                                } else {
                                    newFromDate.hour = value.hour;
                                    newFromDate.minute = value.minute;
                                }
                                onSet(newFromDate, rangeAsMinutes);
                            }}
                        />
                        <TextControlGroup 
                            text={"Minutes Range"}
                            numericOnly={true}
                            numberMustBePositive={true}
                            defaultValue={rangeAsMinutes.toString()}
                            onChange={value => {
                                onSet(fromDate, Number(value));
                            }}
                        />
                        <View>
                            <Spacer />
                            <PrimaryButton text='Find' onClick={() => onLoad(fromDate, rangeAsMinutes)} />
                        </View>
                    </CardContent>
                </CardContainer>

                {(statistics && statistics.duration) && (<>
                    <TextDisplayControlGroup text='Count' value={statistics.duration.count.toString()} />
                    <TextDisplayControlGroup text='Min (ms)' value={statistics.duration.min.toString()} />
                    <TextDisplayControlGroup text='Max (ms)' value={statistics.duration.max.toString()} />
                    <TextDisplayControlGroup text='Average (ms)' value={statistics.duration.average.toString()} />
                    <hr />
                    <TextDisplayControlGroup text='Values  (ms)' value={statistics.duration.durations.toString()} />
                </>)}
            </PageLayout>
        );
    }
}