import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import SecondaryFab from '../../common/SecondaryFab';
import FullPageDialog from '../../layout/FullPageDialog';
import TransparentButton from '../../controls/TransparentButton';
import LayoutForm from '../../layout/LayoutForm';
import ControlGroup from '../../controls/ControlGroup';
import POIRegistrationWatchlists from './POIRegistrationWatchlists';
import ImagePreview from '../../common/ImagePreview';
import Thumbnail from '../../common/Thumbnail';
import Box from '@material-ui/core/Box';
import Loading from '../../common/Loading';
import Information from '../../common/Information';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import CardTypography from '../../common/CardTypography';
import CardTitle from '../../common/CardTitle';
import CardLabel from '../../common/CardLabel';
import CardList from '../../common/CardList';
import SubTitle from '../../common/SubTitle';
import POIPagedIncidentsContainer from '../../../containers/POIPagedIncidentsContainer';
import FacialRecognitionPOIScannedIDContainer from '../../../containers/FacialRecognitionPOIScannedIDContainer';

export interface IPOISearchProps {
    incidentId: number;
    isLoading: boolean;
    poiScan: AusComplyDtos.POIScan;
    onSearch: Function;
}

interface IPOISearchState {
    show: boolean;
    selectedFilePath: string;
    selectedFileName: string;
}

export default class POISearch extends Component<IPOISearchProps, IPOISearchState> {
    constructor(props: IPOISearchProps) {
        super(props)
        this.state = {
            show: false,
            selectedFilePath: "",
            selectedFileName: ""
        };
        this.onSelectFilePath = this.onSelectFilePath.bind(this);
        this.onScanDocument = this.onScanDocument.bind(this);
        this.onScanPatronPhoto = this.onScanPatronPhoto.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onShow = this.onShow.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    onScanDocument(document: AusComplyDtos.IncidentDocument) {
        let poiScan = { ...this.props.poiScan };
        poiScan.incidentDocument = document;
        // @ts-ignore
        poiScan.incidentPatronPhoto = undefined;
        this.props.onSearch(this.props.incidentId, poiScan);
    }

    onScanPatronPhoto(patronPhoto: AusComplyDtos.IncidentPatronPhoto) {
        let poiScan = { ...this.props.poiScan };
        // @ts-ignore
        poiScan.incidentDocument = undefined;
        poiScan.incidentPatronPhoto = patronPhoto;
        this.props.onSearch(this.props.incidentId, poiScan);
    }

    onSelectFilePath(filePath, text) {
        this.setState({
            selectedFilePath: filePath,
            selectedFileName: text
        });
    }

    onCancel() {
        this.setState({ show: false });
    }

    onShow() {
        this.setState({ show: true }, () => {
            this.props.onSearch(this.props.incidentId, null);
        });
    }

    render() {
        let self = this;
        let poiScan;
        let images;
        let error;

        if (this.props.isLoading) {
            poiScan = (
                <ControlGroup labelAbove={true}>
                    <Loading message={"Scanning POI Image..."} />
                </ControlGroup>
            )
        } else if (this.state.show && !this.props.isLoading && this.props.poiScan) {

            let documents;
            let currentDocumentId = 0;
            if (this.props.poiScan.incidentDocument) {
                currentDocumentId = this.props.poiScan.incidentDocument.incidentDocumentId
            }
            if (this.props.poiScan.incidentDocuments && this.props.poiScan.incidentDocuments.length > 0) {
                documents = this.props.poiScan.incidentDocuments.filter(item => !item.obsolete && item.incidentDocumentId != currentDocumentId).map((item, index) => {
                    return (
                        <Box p={0} key={"file-box-" + item.originalFilename + "_" + index.toString()}>
                            <Thumbnail
                                key={"file-" + item.originalFilename + "_" + index.toString()}
                                previewPath={item.filePreviewPath}
                                displayPath={item.fileDisplayPath}
                                isImage={item.isImage}
                                text={item.filePreviewText}
                                fullText={item.originalFilename}
                                onPreview={(path, text) => this.onSelectFilePath(path, text)}
                            />
                            <TransparentButton text={"Scan"} style={{ height: '25px', marginLeft: '22px' }} onClick={() => this.onScanDocument(item)} />
                        </Box>
                    )
                });
            }

            let patronPhotos;
            let currentPhotoId = 0;
            if (this.props.poiScan.incidentPatronPhoto) {
                currentPhotoId = this.props.poiScan.incidentPatronPhoto.incidentPatronPhotoId
            }
            if (this.props.poiScan.incidentPatronPhotos && this.props.poiScan.incidentPatronPhotos.length > 0) {
                patronPhotos = this.props.poiScan.incidentPatronPhotos.filter(item => !item.obsolete && item.incidentPatronPhotoId != currentPhotoId).map((item, index) => {
                    return (
                        <Box p={0} key={"file-box-patron" + item.originalFileName + "_" + index.toString()}>
                            <Thumbnail
                                key={"file-patron-" + item.originalFileName + "_" + index.toString()}
                                previewPath={item.filePreviewPath}
                                displayPath={item.fileDisplayPath}
                                isImage={item.isImage}
                                text={item.filePreviewText}
                                fullText={item.originalFileName}
                                onPreview={(path, text) => this.onSelectFilePath(path, text)}
                            />
                            <TransparentButton text={"Scan"} style={{ height: '25px', marginLeft: '22px' }} onClick={() => this.onScanPatronPhoto(item)} />
                        </Box>
                    )
                });
            }

            images = <CardContainer
                title={"Incident Images"}>
                <CardContent>
                    <Box display="flex" flexDirection="row" style={{ marginTop: '20px' }}>
                        <Box p={0} flex={0} >
                            <SubTitle text={"Scanned"} style={{ textAlign: 'center' }} />
                            <Box display="flex" flexDirection="row" justifyContent="center" flexWrap="wrap">
                                <Box p={0} key={"selected-box-patron"}>
                                    {this.props.poiScan.incidentDocument && (
                                        <Thumbnail
                                            key={"file-patron-" + this.props.poiScan.incidentDocument.originalFilename}
                                            previewPath={this.props.poiScan.incidentDocument.filePreviewPath}
                                            displayPath={this.props.poiScan.incidentDocument.fileDisplayPath}
                                            isImage={this.props.poiScan.incidentDocument.isImage}
                                            text={this.props.poiScan.incidentDocument.filePreviewText}
                                            fullText={this.props.poiScan.incidentDocument.originalFilename}
                                            onPreview={(path, text) => this.onSelectFilePath(path, text)}
                                        />
                                    )}
                                    {this.props.poiScan.incidentPatronPhoto && (
                                        <Thumbnail
                                            key={"file-patron-" + this.props.poiScan.incidentPatronPhoto.originalFileName}
                                            previewPath={this.props.poiScan.incidentPatronPhoto.filePreviewPath}
                                            displayPath={this.props.poiScan.incidentPatronPhoto.fileDisplayPath}
                                            isImage={this.props.poiScan.incidentPatronPhoto.isImage}
                                            text={this.props.poiScan.incidentPatronPhoto.filePreviewText}
                                            fullText={this.props.poiScan.incidentPatronPhoto.originalFileName}
                                            onPreview={(path, text) => this.onSelectFilePath(path, text)}
                                        />
                                    )}
                                </Box>
                            </Box>
                        </Box>
                        <Box p={0} flex={1} >
                            <SubTitle text={"Scan a different image"} style={{ textAlign: 'center' }} />
                            <Box display="flex" flexDirection="row" justifyContent="center" flexWrap="wrap">
                                {documents} {patronPhotos}
                            </Box>
                        </Box>
                    </Box>
                </CardContent>
            </CardContainer>;

            if (this.props.poiScan.error) {
                error = <Information isError={true} message={this.props.poiScan.error} />
            }

            let poiScanResult;
            if (!this.props.poiScan.poiScanResults || this.props.poiScan.poiScanResults.length === 0) {
                poiScanResult = <></>;
            } else {
                poiScanResult = <>
                    {
                        this.props.poiScan.poiScanResults.map((result, index) => {
                            /*let incidents;
                            if (result.poiIncidents && result.poiIncidents.incidents) {
                                incidents = <ResponsiveList
                                    data={result.poiIncidents.incidents.data}
                                    headers={["Incident Serial Number", "Incident Type", "Watchlist", "Expires", "Created"]}
                                    columns={["incidentSerialNumber", "incidentType", "watchlist", "expires", "incidentDateDisplay"]}
                                    getCommands={item => []}
                                    onCommand={(command, item) => { }}
                                    isDanger={item => item.obsolete}
                                    isDangerLabel={"Expired"}
                                    canEdit={false}
                                    onGetLink={(columnName, rowData) => {
                                        if (columnName == "incidentSerialNumber") {
                                            return "/incidentview/" + rowData["incidentId"];
                                        }
                                        return "";
                                    }}
                                />;
                            }*/
                            let pagedIncidents;
                            if (result.poiIncidents){
                                pagedIncidents = <POIPagedIncidentsContainer 
                                facialRecognitionPOIIncidentId={0}
                                poiGuid={result.poiIncidents.poiGUID}
                                detailed={false}
                                forVenueId={result.poiIncidents.venueId}
                                  />
                            }
                            let notes;
                            if (result.poiNotes && result.poiNotes.length > 0) {
                                notes = <>
                                    <SubTitle text={"Notes"} />
                                    <CardList
                                        loading={false}
                                        data={result.poiNotes}
                                        getCommands={item => []}
                                        onCommand={(command, item) => { }}
                                        canEdit={false}
                                        card={(note: AusComplyDtos.IncidentPOINote) => <>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} sm={6}>
                                                    <CardTitle>{note.timeZoneDate.displayShortDate} {note.venue}</CardTitle>
                                                    <CardTypography>{note.note}</CardTypography>
                                                </Grid>
                                            </Grid>
                                        </>}
                                    /></>
                            }

                            return (
                                <>
                                    {index > 0 && (
                                        <div style={{margin: '10px 0', height: '1px', width: '100%', backgroundColor: '#555'}} />
                                    )}
                                    <Box key={result.poi.id}
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="center"
                                        flexWrap="wrap">
                                        <Box p={0} flex={0} >
                                            <Box display="flex" flexDirection="column">
                                                <Box p={0} flex={0} >
                                                    <Thumbnail
                                                        key={"poi-image-" + result.poi.id}
                                                        previewPath={""}
                                                        displayPath={""}
                                                        base64={result.poi.image}
                                                        base64Type={"image/jpeg"}
                                                        isImage={true}
                                                        text={"Detected face"}
                                                        fullText={""}
                                                        title={Math.round(result.poi.confidence).toString() + "%"}
                                                    />
                                                </Box>
                                                <Box p={1} flex={0} >
                                                    <CardLabel style={{ textAlign: 'left', color: '#aaa' }}>Name</CardLabel>
                                                    <CardTypography>{result.poi.displayName ? result.poi.displayName : " - empty - "}</CardTypography>
                                                    <CardLabel style={{ textAlign: 'left', color: '#aaa' }}>ID</CardLabel>
                                                    <CardTypography style={{ color: '#ccc' }}><small>{result.poi.id}</small></CardTypography>
                                                </Box>
                                                <Box p={1} flex={0}>
                                                    <CardLabel style={{ textAlign: 'left', color: '#aaa' }}>Scanned ID</CardLabel>
                                                    <FacialRecognitionPOIScannedIDContainer poiGuid={result.poi.id} />
                                                </Box>
                                            </Box >
                                        </Box>
                                        <Box p={0} flex={1} >
                                            {pagedIncidents}
                                            {notes}
                                        </Box>
                                    </Box>
                                </>);
                        })
                    }
                </>;

            }

            poiScan = <CardContainer
                title={this.props.poiScan.poiScanResults && this.props.poiScan.poiScanResults.length > 0 ? "Scan Results" : "Scan Result"}>
                <CardContent>
                    {poiScanResult}
                </CardContent>
            </CardContainer>;
        }

        return (
            <>
                <SecondaryFab
                    isExtended={true}
                    onClick={() => { if (!self.props.isLoading) { self.onShow() } }}>Search POI</SecondaryFab>
                <div style={{ display: 'inline-table' }}>
                    <FullPageDialog open={this.state.show} title={"Search POI"} onDismissed={() => this.onCancel()}>
                        {images}
                        {poiScan}
                        {error}
                    </FullPageDialog>
                    <ImagePreview filePath={this.state.selectedFilePath} fileName={this.state.selectedFileName} onDismissed={() => this.onSelectFilePath("", "")} />
                </div>
            </>
        );

    }
}