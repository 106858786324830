import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import {
    userLogoutRequest
} from '../actions/userLogin';
import * as apiRequest from "../services/common";

import { notificationShow } from "../actions/notification";
import { commonLogic } from '../logic/common'

export const notifyErrorMessage = (error, notification, action?) => {
    if (notification !== "Failed to load statistics") {
        commonLogic.trackException(error, "notifyErrorMessage," + notification, {});
    }
    let errorMessage = error.responseStatus ? error.responseStatus.message : error.message;
    if (notification == "") {
        notification = errorMessage;
    }
    if (error.responseStatus && error.responseStatus.errorCode && error.responseStatus.errorCode == "TokenException") {
        if (action) {
            return of(
                action(errorMessage),
                notificationShow(notification),
                userLogoutRequest()
            );
        }

        return of(
            notificationShow(notification),
            userLogoutRequest()
        );
    }
    if (action) {
        return of(
            action(errorMessage),
            notificationShow(notification)
        );
    }

    return of(
        notificationShow(notification)
    );
}

export const notifyErrorMessageActionNoMessage = (error, notification, action?) => {
    commonLogic.trackException(error, "notifyErrorMessageActionNoMessage," + notification, {});

    let errorMessage = error.responseStatus ? error.responseStatus.message : error.message;
    if (notification == "") {
        notification = errorMessage;
    }

    if (error.responseStatus && error.responseStatus.errorCode && error.responseStatus.errorCode == "TokenException") {
        if (action) {
            return of(
                action(),
                notificationShow(notification),
                userLogoutRequest()
            );
        }

        return of(
            notificationShow(notification),
            userLogoutRequest()
        );
    }
    if (action) {
        return of(
            action(),
            notificationShow(notification)
        );
    }

    return of(
        notificationShow(notification)
    );
}

export const noNotifyError = (error, source, action) => {

    commonLogic.trackException(error, "notifyError, " + source, {});

    let errorMessage = error.responseStatus ? error.responseStatus.message : error.message;

    if (error.responseStatus && error.responseStatus.errorCode && error.responseStatus.errorCode == "TokenException") {
        return of(
            action(errorMessage),
            userLogoutRequest()
        );
    }
    return of(
        action(errorMessage)
    );
}

export const notifyError = (error, source, action?, originalAction?) => {

    commonLogic.trackException(error, "notifyError, " + source, {});

    let errorMessage = error.responseStatus ? error.responseStatus.message : error.message;

    if (error.responseStatus && error.responseStatus.errorCode && error.responseStatus.errorCode == "TokenException") {
        if (action) {
            return of(
                action(errorMessage, originalAction),
                notificationShow(errorMessage),
                userLogoutRequest()
            );
        }

        return of(
            notificationShow(errorMessage),
            userLogoutRequest()
        );
    }
    if (action) {
        return of(
            action(errorMessage, originalAction),
            notificationShow(errorMessage)
        );
    }

    return of(
        notificationShow(errorMessage)
    );
}

export const notifyErrorNoTrack= (error, source, action?) => {

    let errorMessage = error.responseStatus ? error.responseStatus.message : error.message;

    if (error.responseStatus && error.responseStatus.errorCode && error.responseStatus.errorCode == "TokenException") {
        if (action) {
            return of(
                action(errorMessage),
                notificationShow(errorMessage),
                userLogoutRequest()
            );
        }

        return of(
            notificationShow(errorMessage),
            userLogoutRequest()
        );
    }
    if (action) {
        return of(
            action(errorMessage),
            notificationShow(errorMessage)
        );
    }

    return of(
        notificationShow(errorMessage)
    );
}

export const notifyErrorResponseStatus = (error, source, action) => {
    commonLogic.trackException(error, "notifyErrorResponseStatus," + source, {});

    let errorMessage = error.responseStatus ? error.responseStatus.message : error.message;
    let responseStatus;
    if (error.responseStatus) {
        responseStatus = error.responseStatus;
    } else {
        responseStatus = { message: error.message };
    }

    if (error.responseStatus && error.responseStatus.errorCode && error.responseStatus.errorCode == "TokenException") {
        return of(
            action(responseStatus),
            notificationShow(errorMessage),
            userLogoutRequest()
        );
    }
    return of(
        action(responseStatus),
        notificationShow(errorMessage)
    );
}

export function epicApiGet(actionConstant, requestType, requestArgs: any[], success: Function, failureActionCreator: Function) {
    return function (action$, state$) {
        return action$.pipe(
            ofType(actionConstant),
            withLatestFrom(state$),
            mergeMap(([action, state]) =>
                apiRequest.get(requestType, ...requestArgs)(state, action)
                    .pipe(
                        map(response => success(response)),
                        catchError(error => notifyError(error, "epic:get:" + actionConstant, failureActionCreator))
                    )
            ),
            catchError(error => notifyError(error, "epic:" + actionConstant, failureActionCreator))
        );
    }
  }

  export function epicApiPost(actionConstant, requestType, requestArgs: any[], success: Function, failureActionCreator: Function) {
    return function (action$, state$) {
        return action$.pipe(
            ofType(actionConstant),
            withLatestFrom(state$),
            mergeMap(([action, state]) =>
                apiRequest.post(requestType, ...requestArgs)(state, action)
                    .pipe(
                        map(response => success(response)),
                        catchError(error => notifyError(error, "epic:post:" + actionConstant, failureActionCreator))
                    )
            ),
            catchError(error => notifyError(error, "epic:" + actionConstant, failureActionCreator))
        );
    }
  }