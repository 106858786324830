import React, { Component } from 'react';
import * as AusComplyDtos from "../../../../common/dto/AusComply.dtos";
import PageTitle from '../../../common/PageTitle'
import FullPageDialog from '../../../layout/FullPageDialog';
import VenueQuickActionsContainer from '../../../../containers/VenueQuickActionsContainer';
import DefaultFab from '../../../common/DefaultFab';
import SubTitle from '../../../common/SubTitle';
import List from '../../../common/List';
import SecondaryFab from '../../../common/SecondaryFab';
import CardContainer from '../../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import CheckboxControl from "../../../controls/CheckboxControl";
import PagedList from '../../../common/PagedList';
import Loading from '../../../common/Loading';
import TableContainer from '../../../layout/TableContainer';
import UserComplianceDocumentMissingCard from './UserComplianceDocumentMissingCard';
import UserComplianceDocumentMissingHeader from './UserComplianceDocumentMissingHeader';
import UserComplianceDocumentMissingRow from './UserComplianceDocumentMissingRow';
import PageMessage from '../../../common/PageMessage';
import PageLayout from '../../../layout/PageLayout';
import TransparentButton from '../../../controls/TransparentButton';

export interface IUserComplianceDocumentsMissingProps {
    venueId?: number;
    securityFirmId?: number;
    isLoading: boolean;
    userComplianceDocuments: AusComplyDtos.UserComplianceDocumentMissingSummary[];
    paging: AusComplyDtos.Paging;
    filter: AusComplyDtos.UserComplianceDocumentFilter;
    onLoad: Function;
    onExport: Function;
    history: any;
}

export default class UserComplianceDocumentsMissing extends Component<IUserComplianceDocumentsMissingProps, any> {
    constructor(props: IUserComplianceDocumentsMissingProps) {
        super(props)

        this.state = {
        };
        this.onExport = this.onExport.bind(this);
        this.onCommand = this.onCommand.bind(this);
        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.onIncludeAssociatedSecurityFirmsChanged = this.onIncludeAssociatedSecurityFirmsChanged.bind(this);
    }

    componentDidMount() {
        var page = this.props.paging.page === 0 ? 1 : this.props.paging.page;
        this.props.onLoad(this.props.paging.pageSize, page, this.prepareFilter(this.props.filter), this.props.venueId, this.props.securityFirmId);
    }

    componentDidUpdate(prevProps) {

    }

    onIncludeAssociatedSecurityFirmsChanged(value) {
        var filter = { ...this.props.filter };
        filter.includeAssociatedSecurityFirms = value;
        this.props.onLoad(this.props.paging.pageSize, 1, this.prepareFilter(filter), this.props.venueId, this.props.securityFirmId);
    }

    prepareFilter(filter: AusComplyDtos.UserComplianceDocumentFilter) {
        let preparedFilter = { ...filter };
        return preparedFilter;
    }

    handlePageSelected(page) {
        this.props.onLoad(this.props.paging.pageSize, page, this.prepareFilter(this.props.filter), this.props.venueId, this.props.securityFirmId);
    }

    onExport() {
        if (this.props.onExport) {
            this.props.onExport(this.props.venueId, this.props.securityFirmId);
        }
    }

    onCommand(command: string, item: AusComplyDtos.UserComplianceDocumentMissingSummary) {
        switch (command) {
            case "edit":
                this.props.history.push('/venue/missingcompliancedocument/' + item.userId);
                break
            default:
                break;
        }
    }

    render() {
        let cardList;
        let rows;
        let rowList;

        if (this.props.userComplianceDocuments && this.props.userComplianceDocuments.length > 0) {
            cardList = this.props.userComplianceDocuments.map((item, index) => {
                return (
                    <UserComplianceDocumentMissingCard
                        onCommand={this.onCommand}
                        key={"card-" + item.userId.toString() + "_" + item.documentTypeId}
                        userComplianceDocument={item}
                    />
                );
            });
            rows = this.props.userComplianceDocuments.map((item, index) => {
                return (
                    <UserComplianceDocumentMissingRow
                        onCommand={this.onCommand}
                        key={"row-" + item.userId.toString() + "_" + item.documentTypeId}
                        userComplianceDocument={item}
                    />
                );
            });
            rowList = (
                <TableContainer
                    header={<UserComplianceDocumentMissingHeader />}
                    rows={rows}
                />
            );
        }

        return (<PageLayout
            headerText="Missing Compliance Documents"
            loading={this.props.isLoading}
            footerLeftContent={<TransparentButton text="Export" onClick={this.onExport} />}
        >
            {this.props.filter && 
                <CardContainer title={"Filter"}>
                    <CardContent>
                        <CheckboxControl
                            text={"Show Associated Firm"}
                            defaultValue={this.props.filter.includeAssociatedSecurityFirms}
                            onChanged={this.onIncludeAssociatedSecurityFirmsChanged} />
                    </CardContent>
                </CardContainer>
            }
            <PagedList
                loading={this.props.isLoading}
                paging={this.props.paging}
                cards={cardList}
                rows={rowList}
                onPageSelected={i => this.handlePageSelected(i)}
            />
        </PageLayout>);
    }
}