import React from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import LayoutForm from '../../layout/LayoutForm';
import PageTitle from '../../common/PageTitle';
import TextControlGroup from '../../controls/TextControlGroup';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import ControlGroup from '../../controls/ControlGroup';
import { withRouter } from "react-router";
import PrimaryButton from '../../controls/PrimaryButton';
import TransparentButton from '../../controls/TransparentButton';

export interface IWebsitePropertiesProps {
    isLoading: boolean,
    isSaving: boolean,
    websiteProperties: AusComplyDtos.WebsiteProperties
    onRefresh: Function;
    onSave: Function;
    onResetCache: Function;
    onResetPool: Function;
    onImport: Function;
}

export interface IWebsitePropertiesState {
    websiteProperties: AusComplyDtos.WebsiteProperties
}

class WebsiteProperties extends React.PureComponent<IWebsitePropertiesProps, IWebsitePropertiesState> {
    constructor(props: IWebsitePropertiesProps) {
        super(props);

        this.onSave = this.onSave.bind(this);

        this.state = {
            websiteProperties: new AusComplyDtos.WebsiteProperties()
        }
    }

    componentDidMount() {
        this.props.onRefresh();
        this.setState({
            websiteProperties: { ...this.props.websiteProperties }
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.websiteProperties) !== JSON.stringify(this.props.websiteProperties)) {
            this.setState({
                websiteProperties: { ...this.props.websiteProperties }
            })
        }
    }

    onChanged(fieldName: string, value: any) {
        let websiteProperties = { ...this.state.websiteProperties };
        websiteProperties[fieldName] = value;
        this.setState({ websiteProperties });
    }

    onSave() {
        this.props.onSave(this.state.websiteProperties);
    }

    render() {
        let websitePropertiesForm;
        if (this.state.websiteProperties) {
            websitePropertiesForm = (
                <>
                    <TextControlGroup text="Admin Email"
                        defaultValue={this.state.websiteProperties.adminEmail}
                        onChange={(value) => this.onChanged('adminEmail', value)}
                        readonly={false} />
                    <TextControlGroup text="Error Email"
                        defaultValue={this.state.websiteProperties.errorEmail}
                        onChange={(value) => this.onChanged('errorEmail', value)}
                        readonly={false} />
                    <TextControlGroup text="Native Application Minimum Version"
                        defaultValue={this.state.websiteProperties.nativeApplicationMinimumVersion}
                        onChange={(value) => this.onChanged('nativeApplicationMinimumVersion', value)}
                        readonly={false} />
                    <CheckboxControlGroup
                        text={"Enable SMS Notifications"}
                        label={" "}
                        defaultValue={this.state.websiteProperties.enableSmsNotifications}
                        onChanged={(value) => this.onChanged('enableSmsNotifications', value)}
                        readonly={false}
                    />
                    <CheckboxControlGroup
                        text={"Enable Metrics"}
                        label={" "}
                        defaultValue={this.state.websiteProperties.enableMetrics}
                        onChanged={(value) => this.onChanged('enableMetrics', value)}
                        readonly={false}
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} style={{ textAlign: "right" }}>
                            <PrimaryButton
                                onClick={this.onSave}
                                style={{ marginLeft: '20px', width: 'auto' }}
                                disabled={this.props.isSaving || this.props.isLoading}
                            >Save</PrimaryButton>
                        </Grid>
                    </Grid>
                    <ControlGroup text={" "}>
                        <TransparentButton text={"Reset Cache"} onClick={() => this.props.onResetCache()} />
                    </ControlGroup>
                    <ControlGroup text={" "}>
                        <TransparentButton text={"Reset Connection Pool"} onClick={() => this.props.onResetPool()} />
                    </ControlGroup>
                </>
            );
        }

        /*
                    <ControlGroup text={" "}>
                        <TransparentButton text={"Import"} onClick={() => this.props.onImport()} />
                    </ControlGroup> 
                    */

        return (
            <LayoutForm
                header={<PageTitle title="Website Settings"></PageTitle>}
                loading={this.props.isLoading}
                saving={this.props.isSaving}
            >
                <form>
                    {websitePropertiesForm}
                </form>
            </LayoutForm>
        );
    }
}

export default withRouter(WebsiteProperties)