import React, { Component } from 'react';
import { styles } from '../../../../styles';
import { withStyles } from '@material-ui/core/styles';
import { IncidentPatron as IncidentPatronDTO, IncidentOptions as IncidentOptionsDTO } from "../../../../common/dto/AusComply.dtos";
import TextControlGroup from '../../../controls/TextControlGroup';
import SelectControlGroup from '../../../controls/SelectControlGroup';
import SimpleDateControlGroup from '../../../controls/SimpleDateControlGroup';
import TextAreaControlGroup from '../../../controls/TextAreaControlGroup';
import LockIcon from '@material-ui/icons/Lock';

export interface IIncidentPatronAddressProps {
    incidentPatron: IncidentPatronDTO;
    incidentOptions: IncidentOptionsDTO;
    contactTracing: boolean;
    onUpdated: Function;
    theme: any;
    classes: any;
}

class IncidentPatronAddress extends Component<IIncidentPatronAddressProps, any> {
    constructor(props: IIncidentPatronAddressProps) {
        super(props)
        this.state = {
            error: ""
        };
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
    }

    onSelectionChanged(fieldName: string, value: any) {
        let patron = { ...this.props.incidentPatron };
        patron[fieldName] = value;
        this.props.onUpdated(patron);
    }

    render() {
        const { classes } = this.props;
        let patron = this.props.incidentPatron;
        
        
        return (
            <>
                {!patron.isUnderAgeLocked && (
                    <>
                        <TextAreaControlGroup text="Address"
                            defaultValue={patron.address}
                            maxLength={255}
                            onChange={(value) => this.onSelectionChanged("address", value)}
                            labelAbove={true} />
                        <TextControlGroup text="Suburb"
                            defaultValue={patron.suburb}
                            maxlength={255}
                            onChange={(value) => this.onSelectionChanged("suburb", value)}
                            labelAbove={true} />
                        <TextControlGroup text="Postcode"
                            defaultValue={patron.postcode}
                            maxlength={4}
                            onChange={(value) => this.onSelectionChanged("postcode", value)}
                            labelAbove={true} />
                        <SelectControlGroup text="State"
                            defaultValue={patron.stateId}
                            onChange={(value) => this.onSelectionChanged("stateId", value)}
                            labelAbove={true}>
                            <option value={0} />
                            {this.props.incidentOptions && this.props.incidentOptions.incidentPatronStates.filter(item => item.parentId == this.props.incidentPatron.countryId).map((item) => (
                                <option key={"state" + item.id} value={item.id}>{item.name}</option>
                            ))}
                        </SelectControlGroup>
                        <SelectControlGroup text="Country"
                            defaultValue={patron.countryId}
                            onChange={(value) => this.onSelectionChanged("countryId", value)}
                            labelAbove={true}>
                            <option value={0} />
                            {this.props.incidentOptions && this.props.incidentOptions.incidentPatronCountries.map((item) => (
                                <option key={"country" + item.id} value={item.id}>{item.name}</option>
                            ))}
                        </SelectControlGroup>
                    </>
                )}
                {patron.isUnderAgeLocked && (
                    <div style={{ width: '100%', textAlign: 'center', marginTop: '30px' }}><LockIcon style={{ fontSize: '3em' }} /></div>
                )}
            </>
        );
    }
}

export default withStyles(styles, { withTheme: true })(IncidentPatronAddress);