import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import SecondaryFab from '../../common/SecondaryFab';
import FullPageDialog from '../../layout/FullPageDialog';
import TransparentButton from '../../controls/TransparentButton';
import LayoutForm from '../../layout/LayoutForm';
import ControlGroup from '../../controls/ControlGroup';
import POIRegistrationWatchlists from './POIRegistrationWatchlists';
import ImagePreview from '../../common/ImagePreview';
import Thumbnail from '../../common/Thumbnail';
import Box from '@material-ui/core/Box';
import Loading from '../../common/Loading';
import ErrorButton from '../../controls/ErrorButton';
import PrimaryButton from '../../controls/PrimaryButton';
import NoRowsMessage from '../../common/NoRowsMessage';
import TextControlGroup from '../../controls/TextControlGroup';
import FileUpload from '../../common/FileUpload';
import Information from '../../common/Information';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import RadioButtonUnCheckedIcon from '../../../resources/RadioButtonUnCheckedIcon';
import RadioButtonCheckedIcon from '../../../resources/RadioButtonCheckedIcon';
import PoiPerson from '../../../resources/PoiPerson';
import ClearButton from '../../controls/ClearButton';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';


export interface IPOIWatchlistRegistrationProps {
    poiRegistration: AusComplyDtos.POIRegistration;
    isLoading: boolean;
    isSaving: boolean;
    onUpdate: Function;
    onSave: Function;
    onCancel: Function;
}

interface IPOIWatchlistRegistrationState {
    selectedFilePath: string;
    selectedFileName: string;
}

class POIWatchlistRegistration extends Component<IPOIWatchlistRegistrationProps, IPOIWatchlistRegistrationState> {
    constructor(props: IPOIWatchlistRegistrationProps) {
        super(props)
        this.state = {
            selectedFilePath: "",
            selectedFileName: ""
        };
        this.onValueChanged = this.onValueChanged.bind(this);
        this.onSelectFilePath = this.onSelectFilePath.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        // it is expected on a successfult save that the result returns an empty poi
        if (prevProps.isSaving && !this.props.isSaving && this.props.poiRegistration.poi == null) {
            if (this.props.onCancel != null) {
                this.props.onCancel();
            }
        }
    }

    onValueChanged(fieldName: string, value: any) {
        let poiRegistration = { ...this.props.poiRegistration };
        poiRegistration[fieldName] = value;
        this.props.onUpdate(poiRegistration)
    }

    onSelectFilePath(filePath, text) {
        this.setState({
            selectedFilePath: filePath,
            selectedFileName: text
        });
    }

    onSave() {
        this.props.onSave();

    }

    onCancel() {
        this.props.onCancel();
    }

    render() {
        let self = this;
        const showModal = this.props.poiRegistration != undefined &&
            (this.props.poiRegistration.poiGuid != "" || this.props.isLoading);
        const watchlistSelected = this.props.poiRegistration.registerWatchlists && this.props.poiRegistration.registerWatchlists.length > 0;

        let poi;
        /*
         if (!this.props.isSearching && this.props.poiRegistration.scannedFace && this.props.poiRegistration.pois) {
            let label = this.props.poiRegistration.pois.length > 0 ? this.props.poiRegistration.pois.length == 0 ? "Match found" : "Matches found" : "No match found, create one";

            // potential pois found
            pois = <ControlGroup text="Select an existing POI or create a new record" labelAbove={true}>
                <Box display="flex" flexDirection="row" justifyContent="left" flexWrap="wrap">
                    {this.props.poiRegistration.pois.map((poi, poiIndex) =>
                        <Box p={1} key={"file-box-poi-" + poiIndex}>
                            <Thumbnail
                                key={"file-face-" + poiIndex.toString()}
                                previewPath={""}
                                displayPath={""}
                                base64={poi.image}
                                base64Type={"image/jpeg"}
                                isImage={true}
                                title={Math.round(poi.confidence).toString() + "% " + poi.displayName}
                                fullText={Math.round(poi.confidence).toString() + "% " + poi.displayName}
                                text={poi.displayName}
                                onPreview={(path, text) => { }}
                            />
                            <TransparentButton text={"Use"} small={true} style={{ height: '25px', width: '100%' }} onClick={() => this.onSelectPOI(poi)} />
                        </Box>
                    )}
                </Box>
            </ControlGroup >;



            let poiOption;
            if (true) {
                

                let poiCreateColor = this.state.createOption === "CREATE" ? "#fff" : "#999";
                let poiCreateIcon = this.state.createOption === "CREATE" ? <RadioButtonCheckedIcon fill={"#fff"} /> : <RadioButtonUnCheckedIcon fill={"#999"} />;
                let poiCreate = <Box flex={0} p={1} key={"CREATE"}>
                    <Box p={1} key={"file-box-poi-create"}>
                        <Box p={1} style={{ paddingBottom: '8px' }} onClick={() => self.onPoiOptionSelect("CREATE", this.props.poiRegistration.defaultName || "", this.props.poiRegistration.defaultNotes || "")}>
                            <div
                                style={{
                                    background: 'transparent',
                                    border: '1px solid rgb(84, 84, 84)',
                                    borderRadius: '5px',
                                    padding: '3px',
                                    position: 'relative',
                                    display: 'inline-block',
                                    margin: '0px'
                                }}
                            >
                                <div style={{ width: '80px', height: '85px', padding: '8px' }}>
                                    <p style={{
                                        color: poiCreateColor,
                                        position: 'absolute',
                                        fontSize: '8px',
                                        top: '2px',
                                        background: 'black',
                                        opacity: 0.7,
                                        bottom: 'auto',
                                        left: 0,
                                        textAlign: 'center',
                                        width: "100%",
                                        margin: "0",
                                        padding: "3px 0",
                                        lineHeight: "11px"
                                    }}>Create New POI</p>
                                    <PoiPerson fill={poiCreateColor} />
                                </div>
                            </div>
                        </Box>
                        <Box display="flex" flexDirection="row" justifyContent="center">
                            <ClearButton onClick={() => self.onPoiOptionSelect("CREATE", this.props.poiRegistration.defaultName || "", this.props.poiRegistration.defaultNotes || "")} style={{ height: '25px', width: '25px' }} >
                                {poiCreateIcon}
                            </ClearButton>
                        </Box>
                    </Box>
                </Box>;
                poiOption = <Box display="flex" flexDirection="row" justifyContent="center" flexWrap="wrap">
                    {this.props.poiRegistration.pois.map((item, index) => {
                        let icon = item.id === this.state.createOption ? <RadioButtonCheckedIcon fill={"#fff"} /> : <RadioButtonUnCheckedIcon fill={"#999"} />;
                        return (
                            <Box flex={0} p={1} key={item.id}>
                                <Box p={1} key={"file-box-poi-" + index}>
                                    <Thumbnail
                                        key={"file-face-" + index.toString()}
                                        previewPath={""}
                                        displayPath={""}
                                        base64={item.image}
                                        base64Type={"image/jpeg"}
                                        isImage={true}
                                        title={Math.round(item.confidence).toString() + "% " + item.displayName}
                                        fullText={Math.round(item.confidence).toString() + "% " + item.displayName}
                                        text={item.displayName}
                                        onPreview={(path, text) => self.onPoiOptionSelect(item.id, item.displayName, item.notes)}
                                    />
                                    <Box display="flex" flexDirection="row" justifyContent="center">
                                        <ClearButton onClick={() => self.onPoiOptionSelect(item.id, item.displayName, item.notes)} style={{ height: '25px', width: '25px' }} >
                                            {icon}
                                        </ClearButton>
                                    </Box>
                                </Box>
                            </Box>
                        )
                    })}
                    {poiCreate}
                </Box>;
            }

            let isSharedWatchlist = false;
            if (this.props.poiRegistration.registerWatchlists.filter(f => f.facialRecognitionWatchlist.venueId === 0).length > 0) {
                isSharedWatchlist = true;
            }

            let poiContent =
                <Box key={this.state.createOption} display="flex" flexDirection="row" justifyContent="center" flexWrap="wrap">
                    <Box p={1} key={"file-box-poi-create"} style={{ minWidth: '280px' }}>
                        <Information
                            isWarning={true}
                        >
                            <p style={{ ...theme.custom.label, fontSize: '0.7em', maxWidth: '400px', padding: '0 10px', textAlign: 'center', color: theme.custom.colors.warning }}>
                                {isSharedWatchlist && (<>
                                    <span>
                                        WARNING: INFORMATION ENTERED HERE WILL BE VISIBLE TO OTHER VENUES SHARING THIS WATCHLIST.
                                        <br /> <br />
                                    </span>
                                </>)}
                                <span>AusComply Facial Recognition must continue to be used in line with our Terms &amp; Conditions, Privacy Statement and any Service Level Agreement. A breach of these conditions may result in restriction or termination of service.</span>
                            </p>
                        </Information>
                        <TextControlGroup text={"Name"} labelAbove={true}
                            defaultValue={this.props.poiRegistration.name}
                            onChange={(value) => this.onValueChanged("name", value)}
                        />

                        {this.state.createOption !== "CREATE" && (
                            <CheckboxControlGroup
                                label={" "}
                                labelAbove={true}
                                text={"Set new image as display / primary image"}
                                defaultValue={this.props.poiRegistration.setAsPrimaryImage}
                                onChanged={(value) => this.onValueChanged("setAsPrimaryImage", value)} />
                        )}

                        <PrimaryButton text={this.state.createOption === "CREATE" ? "Create New POI" : "Update Existing POI"} style={{ width: '100%', marginTop: '10px' }} onClick={() => this.onCreatePOI()} />
                    </Box>
                </Box>;

            pois = <CardContainer
                title={label}>
                <CardContent>
                    {poiOption}
                    {poiContent}
                </CardContent>
            </CardContainer>;
        }
*/


        let poiRegistration;
        let watchlistSelection;
        let saveDisabled: boolean = true;
        if (showModal && this.props.poiRegistration != undefined && this.props.poiRegistration) {
            watchlistSelection = (<POIRegistrationWatchlists
                poiRegistration={this.props.poiRegistration}
                multiple={true}
                facialRecognitionWatchlists={this.props.poiRegistration.watchlists}
                onUpdate={value => this.props.onUpdate(value)} />);

            saveDisabled = this.props.isSaving || this.props.isLoading || this.props.poiRegistration.registerWatchlists == undefined || this.props.poiRegistration.registerWatchlists == null ||this.props.poiRegistration.registerWatchlists.length == 0;

            if (!this.props.isLoading && this.props.poiRegistration.poi) {
                poi = (<Box display="flex" flexDirection="row" justifyContent="center" flexWrap="wrap">
                    <Box p={1} key={"file-box-poi"}>
                        <Thumbnail
                            key={"file-face"}
                            previewPath={""}
                            displayPath={""}
                            base64={this.props.poiRegistration.poi.image}
                            base64Type={"image/jpeg"}
                            isImage={true}
                            title={this.props.poiRegistration.poi.displayName}
                            fullText={this.props.poiRegistration.poi.displayName}
                            text={this.props.poiRegistration.poi.displayName}
                            onPreview={(path, text) => { }}
                        />
                    </Box>
                </Box>);
            }

            poiRegistration = (
                <LayoutForm
                    loading={this.props.isLoading}
                    saving={this.props.isSaving}>
                    {poi}
                    {watchlistSelection}
                    <ImagePreview filePath={this.state.selectedFilePath} fileName={this.state.selectedFileName} onDismissed={() => this.onSelectFilePath("", "")} />
                </LayoutForm>
            );
        }


        return (
            <div style={{ display: 'inline-table' }}>
                <FullPageDialog
                    open={showModal}
                    loading={this.props.isLoading}
                    saving={this.props.isSaving}
                    title={"Add POI to another watchlist"}
                    footerRightContent={<PrimaryButton text='Save' disabled={saveDisabled} onClick={() => this.onSave()} />}
                    footerLeftContent={<TransparentButton text='Cancel' onClick={() => this.onCancel()} />}
                    onDismissed={() => this.onCancel()}>
                    {poiRegistration}
                </FullPageDialog>
            </div>
        );

    }
}
export default withStyles(styles, { withTheme: true })(POIWatchlistRegistration);