import { makeActionCreator } from './helpers';

export const
    ADMIN_DUPLICATE_USER_MOBILES_REQUEST = "ADMIN_DUPLICATE_USER_MOBILES_REQUEST",
    ADMIN_DUPLICATE_USER_MOBILES_REQUEST_FAILURE = "ADMIN_DUPLICATE_USER_MOBILES_REQUEST_FAILURE",
    ADMIN_DUPLICATE_USER_MOBILES_REQUEST_SUCCESS = "ADMIN_DUPLICATE_USER_MOBILES_REQUEST_SUCCESS",
    ADMIN_DUPLICATE_USER_MOBILE_UPDATE_REQUEST = "ADMIN_DUPLICATE_USER_MOBILE_UPDATE_REQUEST",
    ADMIN_DUPLICATE_USER_MOBILE_UPDATE_REQUEST_FAILURE = "ADMIN_DUPLICATE_USER_MOBILE_UPDATE_REQUEST_FAILURE",
    ADMIN_DUPLICATE_USER_MOBILE_UPDATE_REQUEST_SUCCESS = "ADMIN_DUPLICATE_USER_MOBILE_UPDATE_REQUEST_SUCCESS",
    ADMIN_USER_ROLES_REQUEST = "ADMIN_USER_ROLES_REQUEST",
    ADMIN_USER_ROLES_REQUEST_FAILURE = "ADMIN_USER_ROLES_REQUEST_FAILURE",
    ADMIN_USER_ROLES_REQUEST_SUCCESS = "ADMIN_USER_ROLES_REQUEST_SUCCESS",
    ADMIN_USER_ROLES_FILTER_SET = "ADMIN_USER_ROLES_FILTER_SET",
    ADMIN_USER_ACTION_AUDITS_REQUEST = "ADMIN_USER_ACTION_AUDITS_REQUEST",
    ADMIN_USER_ACTION_AUDITS_REQUEST_FAILURE = "ADMIN_USER_ACTION_AUDITS_REQUEST_FAILURE",
    ADMIN_USER_ACTION_AUDITS_REQUEST_SUCCESS = "ADMIN_USER_ACTION_AUDITS_REQUEST_SUCCESS",
    ADMIN_USERS_REQUEST = "ADMIN_USERS_REQUEST",
    ADMIN_USERS_REQUEST_FAILURE = "ADMIN_USERS_REQUEST_FAILURE",
    ADMIN_USERS_REQUEST_SUCCESS = "ADMIN_USERS_REQUEST_SUCCESS",
    ADMIN_USERS_FILTER_SET = "ADMIN_USERS_FILTER_SET",
    ADMIN_VENUES_REQUEST = "ADMIN_VENUES_REQUEST",
    ADMIN_VENUES_REFRESH_REQUEST = "ADMIN_VENUES_REFRESH_REQUEST",
    ADMIN_VENUES_REQUEST_FAILURE = "ADMIN_VENUES_REQUEST_FAILURE",
    ADMIN_VENUES_REQUEST_SUCCESS = "ADMIN_VENUES_REQUEST_SUCCESS",
    ADMIN_VENUES_FILTER_SET = "ADMIN_VENUES_FILTER_SET",
    ADMIN_VENUE_ASSOCIATIONS_REQUEST = "ADMIN_VENUE_ASSOCIATIONS_REQUEST",
    ADMIN_VENUE_ASSOCIATIONS_REQUEST_FAILURE = "ADMIN_VENUE_ASSOCIATIONS_REQUEST_FAILURE",
    ADMIN_VENUE_ASSOCIATIONS_REQUEST_SUCCESS = "ADMIN_VENUE_ASSOCIATIONS_REQUEST_SUCCESS",
    ADMIN_VENUESECURITYFIRM_REMOVE_REQUEST = "ADMIN_VENUESECURITYFIRM_REMOVE_REQUEST",
    ADMIN_VENUESECURITYFIRM_REMOVE_REQUEST_FAILURE = "ADMIN_VENUESECURITYFIRM_REMOVE_REQUEST_FAILURE",
    ADMIN_VENUESECURITYFIRM_REMOVE_REQUEST_SUCCESS = "ADMIN_VENUESECURITYFIRM_REMOVE_REQUEST_SUCCESS",
    ADMIN_VENUESECURITYFIRMDESCENDANT_REMOVE_REQUEST = "ADMIN_VENUESECURITYFIRM_REMOVE_REQUEST",
    ADMIN_VENUESECURITYFIRMDESCENDANT_REMOVE_REQUEST_FAILURE = "ADMIN_VENUESECURITYFIRM_REMOVE_REQUEST_FAILURE",
    ADMIN_VENUESECURITYFIRMDESCENDANT_REMOVE_REQUEST_SUCCESS = "ADMIN_VENUESECURITYFIRM_REMOVE_REQUEST_SUCCESS",
    ADMIN_WEBSITE_PROPERTIES_REQUEST = "ADMIN_WEBSITE_PROPERTIES_REQUEST",
    ADMIN_WEBSITE_PROPERTIES_REQUEST_FAILURE = "ADMIN_WEBSITE_PROPERTIES_REQUEST_FAILURE",
    ADMIN_WEBSITE_PROPERTIES_REQUEST_SUCCESS = "ADMIN_WEBSITE_PROPERTIES_REQUEST_SUCCESS",
    ADMIN_WEBSITE_PROPERTIES_SAVE_REQUEST = "ADMIN_WEBSITE_PROPERTIES_SAVE_REQUEST",
    ADMIN_WEBSITE_PROPERTIES_SAVE_REQUEST_FAILURE = "ADMIN_WEBSITE_PROPERTIES_SAVE_REQUEST_FAILURE",
    ADMIN_WEBSITE_PROPERTIES_SAVE_REQUEST_SUCCESS = "ADMIN_WEBSITE_PROPERTIES_SAVE_REQUEST_SUCCESS",
    ADMIN_WEBSITE_RESET_CACHE_REQUEST = "ADMIN_WEBSITE_RESET_CACHE_REQUEST",
    ADMIN_WEBSITE_RESET_CACHE_REQUEST_FAILURE = "ADMIN_WEBSITE_RESET_CACHE_REQUEST_FAILURE",
    ADMIN_WEBSITE_RESET_CACHE_REQUEST_SUCCESS = "ADMIN_WEBSITE_RESET_CACHE_REQUEST_SUCCESS",
    ADMIN_USER_CHANGE_PASSWORD_REQUEST = "ADMIN_USER_CHANGE_PASSWORD_REQUEST",
    ADMIN_USER_CHANGE_PASSWORD_REQUEST_SUCCESS = "ADMIN_USER_CHANGE_PASSWORD_REQUEST_SUCCESS",
    ADMIN_USER_CHANGE_PASSWORD_REQUEST_FAILURE = "ADMIN_USER_CHANGE_PASSWORD_REQUEST_FAILURE",
    ADMIN_USER_RESET = "ADMIN_USER_RESET",
    ADMIN_USER_SET = "ADMIN_USER_SET",
    ADMIN_USER_REFRESH = "ADMIN_USER_REFRESH",
    ADMIN_USER_REQUEST = "ADMIN_USER_REQUEST",
    ADMIN_USER_REQUEST_FAILURE = "ADMIN_USER_REQUEST_FAILURE",
    ADMIN_USER_REQUEST_SUCCESS = "ADMIN_USER_REQUEST_SUCCESS",
    ADMIN_OTHER_DOCUMENTS_SAVE = "ADMIN_OTHER_DOCUMENTS_SAVE",
    ADMIN_OTHER_DOCUMENTS_SAVE_SUCCESS = "ADMIN_OTHER_DOCUMENTS_SAVE_SUCCESS",
    ADMIN_OTHER_DOCUMENTS_SAVE_FAILURE = "ADMIN_OTHER_DOCUMENTS_SAVE_FAILURE",
    ADMIN_OTHER_DOCUMENT_REMOVE = "ADMIN_OTHER_DOCUMENT_REMOVE",
    ADMIN_OTHER_DOCUMENT_REMOVE_SUCCESS = "ADMIN_OTHER_DOCUMENT_REMOVE_SUCCESS",
    ADMIN_OTHER_DOCUMENT_REMOVE_FAILURE = "ADMIN_OTHER_DOCUMENT_REMOVE_FAILURE",
    ADMIN_COMPLIANCE_DOCUMENTS_SAVE = "ADMIN_COMPLIANCE_DOCUMENTS_SAVE",
    ADMIN_COMPLIANCE_DOCUMENTS_SAVE_SUCCESS = "ADMIN_COMPLIANCE_DOCUMENTS_SAVE_SUCCESS",
    ADMIN_COMPLIANCE_DOCUMENTS_SAVE_FAILURE = "ADMIN_COMPLIANCE_DOCUMENTS_SAVE_FAILURE",
    ADMIN_COMPLIANCE_DOCUMENT_REMOVE = "ADMIN_COMPLIANCE_DOCUMENT_REMOVE",
    ADMIN_COMPLIANCE_DOCUMENT_REMOVE_SUCCESS = "ADMIN_COMPLIANCE_DOCUMENT_REMOVE_SUCCESS",
    ADMIN_COMPLIANCE_DOCUMENT_REMOVE_FAILURE = "ADMIN_COMPLIANCE_DOCUMENT_REMOVE_FAILURE",
    ADMIN_USER_SAVE_REQUEST = "ADMIN_USER_SAVE_REQUEST",
    ADMIN_USER_SAVE_REQUEST_SUCCESS = "ADMIN_USER_SAVE_REQUEST_SUCCESS",
    ADMIN_USER_SAVE_REQUEST_FAILURE = "ADMIN_USER_SAVE_REQUEST_FAILURE",
    ADMIN_USER_SLED_CHECK_REQUEST = "ADMIN_USER_SLED_CHECK_REQUEST",
    ADMIN_USER_SLED_CHECK_REQUEST_SUCCESS = "ADMIN_USER_SLED_CHECK_REQUEST_SUCCESS",
    ADMIN_USER_SLED_CHECK_REQUEST_FAILURE = "ADMIN_USER_SLED_CHECK_REQUEST_FAILURE",
    ADMIN_INDUSTRY_CATEGORIES_REQUEST = "ADMIN_INDUSTRY_CATEGORIES_REQUEST",
    ADMIN_INDUSTRY_CATEGORIES_REQUEST_FAILURE = "ADMIN_INDUSTRY_CATEGORIES_REQUEST_FAILURE",
    ADMIN_INDUSTRY_CATEGORIES_REQUEST_SUCCESS = "ADMIN_INDUSTRY_CATEGORIES_REQUEST_SUCCESS",
    ADMIN_INDUSTRY_CATEGORY_CREATE_REQUEST = "ADMIN_INDUSTRY_CATEGORY_CREATE_REQUEST",
    ADMIN_INDUSTRY_CATEGORY_CREATE_REQUEST_FAILURE = "ADMIN_INDUSTRY_CATEGORY_CREATE_REQUEST_FAILURE",
    ADMIN_INDUSTRY_CATEGORY_CREATE_REQUEST_SUCCESS = "ADMIN_INDUSTRY_CATEGORY_CREATE_REQUEST_SUCCESS",
    ADMIN_INDUSTRY_CATEGORY_UPDATE_REQUEST = "ADMIN_INDUSTRY_CATEGORY_UPDATE_REQUEST",
    ADMIN_INDUSTRY_CATEGORY_UPDATE_REQUEST_FAILURE = "ADMIN_INDUSTRY_CATEGORY_UPDATE_REQUEST_FAILURE",
    ADMIN_INDUSTRY_CATEGORY_UPDATE_REQUEST_SUCCESS = "ADMIN_INDUSTRY_CATEGORY_UPDATE_REQUEST_SUCCESS",
    ADMIN_INCIDENT_TYPES_REQUEST = "ADMIN_INCIDENT_TYPES_REQUEST",
    ADMIN_INCIDENT_TYPES_REFRESH = "ADMIN_INCIDENT_TYPES_REFRESH",
    ADMIN_INCIDENT_TYPES_REQUEST_FAILURE = "ADMIN_INCIDENT_TYPES_REQUEST_FAILURE",
    ADMIN_INCIDENT_TYPES_REQUEST_SUCCESS = "ADMIN_INCIDENT_TYPES_REQUEST_SUCCESS",
    ADMIN_INCIDENT_TYPE_SET = "ADMIN_INCIDENT_TYPE_SET",
    ADMIN_INCIDENT_TYPE_CREATE_NEW_REQUEST = "ADMIN_INCIDENT_TYPE_CREATE_NEW_REQUEST",
    ADMIN_INCIDENT_TYPE_CREATE_NEW_REQUEST_FAILURE = "ADMIN_INCIDENT_TYPE_CREATE_NEW_REQUEST_FAILURE",
    ADMIN_INCIDENT_TYPE_CREATE_NEW_REQUEST_SUCCESS = "ADMIN_INCIDENT_TYPE_CREATE_NEW_REQUEST_SUCCESS",
    ADMIN_INCIDENT_TYPE_UPSERT_REQUEST = "ADMIN_INCIDENT_TYPE_UPSERT_REQUEST",
    ADMIN_INCIDENT_TYPE_UPSERT_REQUEST_FAILURE = "ADMIN_INCIDENT_TYPE_UPSERT_REQUEST_FAILURE",
    ADMIN_INCIDENT_TYPE_UPSERT_REQUEST_SUCCESS = "ADMIN_INCIDENT_TYPE_UPSERT_REQUEST_SUCCESS",
    ADMIN_INCIDENT_FLAG_TYPES_REQUEST = "ADMIN_INCIDENT_FLAG_TYPES_REQUEST",
    ADMIN_INCIDENT_FLAG_TYPES_REQUEST_FAILURE = "ADMIN_INCIDENT_FLAG_TYPES_REQUEST_FAILURE",
    ADMIN_INCIDENT_FLAG_TYPES_REQUEST_SUCCESS = "ADMIN_INCIDENT_FLAG_TYPES_REQUEST_SUCCESS",
    ADMIN_INCIDENT_FLAG_TYPE_SET = "ADMIN_INCIDENT_FLAG_TYPE_SET",
    ADMIN_INCIDENT_FLAG_TYPE_CREATE_NEW_REQUEST = "ADMIN_INCIDENT_FLAG_TYPE_CREATE_NEW_REQUEST",
    ADMIN_INCIDENT_FLAG_TYPE_CREATE_NEW_REQUEST_FAILURE = "ADMIN_INCIDENT_FLAG_TYPE_CREATE_NEW_REQUEST_FAILURE",
    ADMIN_INCIDENT_FLAG_TYPE_CREATE_NEW_REQUEST_SUCCESS = "ADMIN_INCIDENT_FLAG_TYPE_CREATE_NEW_REQUEST_SUCCESS",
    ADMIN_INCIDENT_FLAG_TYPE_UPSERT_REQUEST = "ADMIN_INCIDENT_FLAG_TYPE_UPSERT_REQUEST",
    ADMIN_INCIDENT_FLAG_TYPE_UPSERT_REQUEST_FAILURE = "ADMIN_INCIDENT_FLAG_TYPE_UPSERT_REQUEST_FAILURE",
    ADMIN_INCIDENT_FLAG_TYPE_UPSERT_REQUEST_SUCCESS = "ADMIN_INCIDENT_FLAG_TYPE_UPSERT_REQUEST_SUCCESS",
    ADMIN_INCIDENT_CATEGORY_TYPES_REQUEST = "ADMIN_INCIDENT_CATEGORY_TYPES_REQUEST",
    ADMIN_INCIDENT_CATEGORY_TYPES_REQUEST_FAILURE = "ADMIN_INCIDENT_CATEGORY_TYPES_REQUEST_FAILURE",
    ADMIN_INCIDENT_CATEGORY_TYPES_REQUEST_SUCCESS = "ADMIN_INCIDENT_CATEGORY_TYPES_REQUEST_SUCCESS",
    ADMIN_INCIDENT_CATEGORY_TYPE_SET = "ADMIN_INCIDENT_CATEGORY_TYPE_SET",
    ADMIN_INCIDENT_CATEGORY_TYPE_CREATE_NEW_REQUEST = "ADMIN_INCIDENT_CATEGORY_TYPE_CREATE_NEW_REQUEST",
    ADMIN_INCIDENT_CATEGORY_TYPE_CREATE_NEW_REQUEST_FAILURE = "ADMIN_INCIDENT_CATEGORY_TYPE_CREATE_NEW_REQUEST_FAILURE",
    ADMIN_INCIDENT_CATEGORY_TYPE_CREATE_NEW_REQUEST_SUCCESS = "ADMIN_INCIDENT_CATEGORY_TYPE_CREATE_NEW_REQUEST_SUCCESS",
    ADMIN_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST = "ADMIN_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST",
    ADMIN_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST_FAILURE = "ADMIN_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST_FAILURE",
    ADMIN_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST_SUCCESS = "ADMIN_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST_SUCCESS",

    ADMIN_VENUE_LOCATIONS_REQUEST = "ADMIN_VENUE_LOCATIONS_REQUEST",
    ADMIN_VENUE_LOCATIONS_REFRESH = "ADMIN_VENUE_LOCATIONS_REFRESH",
    ADMIN_VENUE_LOCATIONS_REQUEST_SUCCESS = "ADMIN_VENUE_LOCATIONS_REQUEST_SUCCESS",
    ADMIN_VENUE_LOCATIONS_REQUEST_FAILURE = "ADMIN_VENUE_LOCATIONS_REQUEST_FAILURE",
    ADMIN_VENUE_LOCATION_RESET = "ADMIN_VENUE_LOCATION_RESET",
    ADMIN_VENUE_LOCATION_SET = "ADMIN_VENUE_LOCATION_SET",
    ADMIN_VENUE_LOCATION_REQUEST = "ADMIN_VENUE_LOCATION_REQUEST",
    ADMIN_VENUE_LOCATION_REQUEST_SUCCESS = "ADMIN_VENUE_LOCATION_REQUEST_SUCCESS",
    ADMIN_VENUE_LOCATION_REQUEST_FAILURE = "ADMIN_VENUE_LOCATION_REQUEST_FAILURE",
    ADMIN_VENUE_LOCATION_CREATE_REQUEST = "ADMIN_VENUE_LOCATION_CREATE_REQUEST",
    ADMIN_VENUE_LOCATION_CREATE_REQUEST_SUCCESS = "ADMIN_VENUE_LOCATION_CREATE_REQUEST_SUCCESS",
    ADMIN_VENUE_LOCATION_CREATE_REQUEST_FAILURE = "ADMIN_VENUE_LOCATION_CREATE_REQUEST_FAILURE",
    ADMIN_VENUE_LOCATION_UPSERT_REQUEST = "ADMIN_VENUE_LOCATION_UPSERT_REQUEST",
    ADMIN_VENUE_LOCATION_UPSERT_REQUEST_SUCCESS = "ADMIN_VENUE_LOCATION_UPSERT_REQUEST_SUCCESS",
    ADMIN_VENUE_LOCATION_UPSERT_REQUEST_FAILURE = "ADMIN_VENUE_LOCATION_UPSERT_REQUEST_FAILURE",
    ADMIN_VENUE_REGISTRATION_DISMISS_REQUEST = "ADMIN_VENUE_REGISTRATION_DISMISS_REQUEST",
    ADMIN_VENUE_REGISTRATION_DISMISS_REQUEST_SUCCESS = "ADMIN_VENUE_REGISTRATION_DISMISS_REQUEST_SUCCESS",
    ADMIN_VENUE_REGISTRATION_DISMISS_REQUEST_FAILURE = "ADMIN_VENUE_REGISTRATION_DISMISS_REQUEST_FAILURE",

    ADMIN_VENUE_OBSOLETE_REQUEST = "ADMIN_VENUE_OBSOLETE_REQUEST",
    ADMIN_VENUE_OBSOLETE_REQUEST_FAILURE = "ADMIN_VENUE_OBSOLETE_REQUEST_FAILURE",
    ADMIN_VENUE_OBSOLETE_REQUEST_SUCCESS = "ADMIN_VENUE_OBSOLETE_REQUEST_SUCCESS",
    ADMIN_VENUE_DELETE_REQUEST = "ADMIN_VENUE_DELETE_REQUEST",
    ADMIN_VENUE_DELETE_REQUEST_FAILURE = "ADMIN_VENUE_DELETE_REQUEST_FAILURE",
    ADMIN_VENUE_DELETE_REQUEST_SUCCESS = "ADMIN_VENUE_DELETE_REQUEST_SUCCESS",
    ADMIN_VENUE_EXPORT_REQUEST = "ADMIN_VENUE_EXPORT_REQUEST",
    ADMIN_VENUE_EXPORT_REQUEST_SUCCESS = "ADMIN_VENUE_EXPORT_REQUEST_SUCCESS",
    ADMIN_VENUE_EXPORT_REQUEST_FAILURE = "ADMIN_VENUE_EXPORT_REQUEST_FAILURE",

    ADMIN_VENUE_SUBSCRIPTION_REQUEST = "ADMIN_VENUE_SUBSCRIPTION_REQUEST",
    ADMIN_VENUE_SUBSCRIPTION_REQUEST_SUCCESS = "ADMIN_VENUE_SUBSCRIPTION_REQUEST_SUCCESS",
    ADMIN_VENUE_SUBSCRIPTION_REQUEST_FAILURE = "ADMIN_VENUE_SUBSCRIPTION_REQUEST_FAILURE",
    ADMIN_VENUE_SUBSCRIPTION_UPDATE = "ADMIN_VENUE_SUBSCRIPTION_UPDATE",
    ADMIN_VENUE_SUBSCRIPTION_SAVE_REQUEST = "ADMIN_VENUE_SUBSCRIPTION_SAVE_REQUEST",
    ADMIN_VENUE_SUBSCRIPTION_SAVE_REQUEST_SUCCESS = "ADMIN_VENUE_SUBSCRIPTION_SAVE_REQUEST_SUCCESS",
    ADMIN_VENUE_SUBSCRIPTION_SAVE_REQUEST_FAILURE = "ADMIN_VENUE_SUBSCRIPTION_SAVE_REQUEST_FAILURE",

    ADMIN_DOCUMENT_TYPES_REQUEST = "ADMIN_DOCUMENT_TYPES_REQUEST",
    ADMIN_DOCUMENT_TYPES_REFRESH = "ADMIN_DOCUMENT_TYPES_REFRESH",
    ADMIN_DOCUMENT_TYPES_REQUEST_SUCCESS = "ADMIN_DOCUMENT_TYPES_REQUEST_SUCCESS",
    ADMIN_DOCUMENT_TYPES_REQUEST_FAILURE = "ADMIN_DOCUMENT_TYPES_REQUEST_FAILURE",
    ADMIN_DOCUMENT_TYPE_RESET = "ADMIN_DOCUMENT_TYPE_RESET",
    ADMIN_DOCUMENT_TYPE_SET = "ADMIN_DOCUMENT_TYPE_SET",
    ADMIN_DOCUMENT_TYPE_REQUEST = "ADMIN_DOCUMENT_TYPE_REQUEST",
    ADMIN_DOCUMENT_TYPE_REQUEST_SUCCESS = "ADMIN_DOCUMENT_TYPE_REQUEST_SUCCESS",
    ADMIN_DOCUMENT_TYPE_REQUEST_FAILURE = "ADMIN_DOCUMENT_TYPE_REQUEST_FAILURE",
    ADMIN_DOCUMENT_TYPE_CREATE_REQUEST = "ADMIN_DOCUMENT_TYPE_CREATE_REQUEST",
    ADMIN_DOCUMENT_TYPE_CREATE_REQUEST_SUCCESS = "ADMIN_DOCUMENT_TYPE_CREATE_REQUEST_SUCCESS",
    ADMIN_DOCUMENT_TYPE_CREATE_REQUEST_FAILURE = "ADMIN_DOCUMENT_TYPE_CREATE_REQUEST_FAILURE",
    ADMIN_DOCUMENT_TYPE_UPSERT_REQUEST = "ADMIN_DOCUMENT_TYPE_UPSERT_REQUEST",
    ADMIN_DOCUMENT_TYPE_UPSERT_REQUEST_SUCCESS = "ADMIN_DOCUMENT_TYPE_UPSERT_REQUEST_SUCCESS",
    ADMIN_DOCUMENT_TYPE_UPSERT_REQUEST_FAILURE = "ADMIN_DOCUMENT_TYPE_UPSERT_REQUEST_FAILURE",

    ADMIN_SECURITY_FIRMS_REQUEST = "ADMIN_SECURITY_FIRMS_REQUEST",
    ADMIN_SECURITY_FIRMS_REFRESH_REQUEST = "ADMIN_SECURITY_FIRMS_REFRESH_REQUEST",
    ADMIN_SECURITY_FIRMS_REQUEST_FAILURE = "ADMIN_SECURITY_FIRMS_REQUEST_FAILURE",
    ADMIN_SECURITY_FIRMS_REQUEST_SUCCESS = "ADMIN_SECURITY_FIRMS_REQUEST_SUCCESS",
    ADMIN_SECURITY_FIRMS_FILTER_SET = "ADMIN_SECURITY_FIRMS_FILTER_SET",

    ADMIN_SECURITY_FIRM_SUBSCRIPTION_REQUEST = "ADMIN_SECURITY_FIRM_SUBSCRIPTION_REQUEST",
    ADMIN_SECURITY_FIRM_SUBSCRIPTION_REQUEST_SUCCESS = "ADMIN_SECURITY_FIRM_SUBSCRIPTION_REQUEST_SUCCESS",
    ADMIN_SECURITY_FIRM_SUBSCRIPTION_REQUEST_FAILURE = "ADMIN_SECURITY_FIRM_SUBSCRIPTION_REQUEST_FAILURE",
    ADMIN_SECURITY_FIRM_SUBSCRIPTION_UPDATE = "ADMIN_SECURITY_FIRM_SUBSCRIPTION_UPDATE",
    ADMIN_SECURITY_FIRM_SUBSCRIPTION_SAVE_REQUEST = "ADMIN_SECURITY_FIRM_SUBSCRIPTION_SAVE_REQUEST",
    ADMIN_SECURITY_FIRM_SUBSCRIPTION_SAVE_REQUEST_SUCCESS = "ADMIN_SECURITY_FIRM_SUBSCRIPTION_SAVE_REQUEST_SUCCESS",
    ADMIN_SECURITY_FIRM_SUBSCRIPTION_SAVE_REQUEST_FAILURE = "ADMIN_SECURITY_FIRM_SUBSCRIPTION_SAVE_REQUEST_FAILURE",
    ADMIN_SECURITY_FIRM_REGISTRATION_DISMISS_REQUEST = "ADMIN_SECURITY_FIRM_REGISTRATION_DISMISS_REQUEST",
    ADMIN_SECURITY_FIRM_REGISTRATION_DISMISS_REQUEST_SUCCESS = "ADMIN_SECURITY_FIRM_REGISTRATION_DISMISS_REQUEST_SUCCESS",
    ADMIN_SECURITY_FIRM_REGISTRATION_DISMISS_REQUEST_FAILURE = "ADMIN_SECURITY_FIRM_REGISTRATION_DISMISS_REQUEST_FAILURE",

    ADMIN_SECURITY_FIRM_OBSOLETE_REQUEST = "ADMIN_SECURITY_FIRM_OBSOLETE_REQUEST",
    ADMIN_SECURITY_FIRM_OBSOLETE_REQUEST_FAILURE = "ADMIN_SECURITY_FIRM_OBSOLETE_REQUEST_FAILURE",
    ADMIN_SECURITY_FIRM_OBSOLETE_REQUEST_SUCCESS = "ADMIN_SECURITY_FIRM_OBSOLETE_REQUEST_SUCCESS",
    ADMIN_SECURITY_FIRM_DELETE_REQUEST = "ADMIN_SECURITY_FIRM_DELETE_REQUEST",
    ADMIN_SECURITY_FIRM_DELETE_REQUEST_FAILURE = "ADMIN_SECURITY_FIRM_DELETE_REQUEST_FAILURE",
    ADMIN_SECURITY_FIRM_DELETE_REQUEST_SUCCESS = "ADMIN_SECURITY_FIRM_DELETE_REQUEST_SUCCESS",
    ADMIN_SECURITY_FIRM_EXPORT_REQUEST = "ADMIN_SECURITY_FIRM_EXPORT_REQUEST",
    ADMIN_SECURITY_FIRM_EXPORT_REQUEST_SUCCESS = "ADMIN_SECURITY_FIRM_EXPORT_REQUEST_SUCCESS",
    ADMIN_SECURITY_FIRM_EXPORT_REQUEST_FAILURE = "ADMIN_SECURITY_FIRM_EXPORT_REQUEST_FAILURE",

    ADMIN_USER_IMPERSONATE = "ADMIN_USER_IMPERSONATE",
    ADMIN_USER_IMPERSONATE_SUCCESS = "ADMIN_USER_IMPERSONATE_SUCCESS",

    ADMIN_FACIAL_RECOGNITION_NOTIFICATION_FILTER_REQUEST = "ADMIN_FACIAL_RECOGNITION_NOTIFICATION_FILTER_REQUEST",
    ADMIN_FACIAL_RECOGNITION_NOTIFICATION_FILTER_REQUEST_FAILURE = "ADMIN_FACIAL_RECOGNITION_NOTIFICATION_FILTER_REQUEST_FAILURE",
    ADMIN_FACIAL_RECOGNITION_NOTIFICATION_FILTER_REQUEST_SUCCESS = "ADMIN_FACIAL_RECOGNITION_NOTIFICATION_FILTER_REQUEST_SUCCESS",
    ADMIN_FACIAL_RECOGNITION_NOTIFICATION_SET_FILTER = "ADMIN_FACIAL_RECOGNITION_NOTIFICATION_SET_FILTER",
    ADMIN_FACIAL_RECOGNITION_NOTIFICATION_SET_PAGING = "ADMIN_FACIAL_RECOGNITION_NOTIFICATION_SET_PAGING",
    ADMIN_FACIAL_RECOGNITION_NOTIFICATION_SET_ITEM = "ADMIN_FACIAL_RECOGNITION_NOTIFICATION_SET_ITEM",
    ADMIN_FACIAL_RECOGNITION_NOTIFICATION_REQUEST = "ADMIN_FACIAL_RECOGNITION_NOTIFICATION_REQUEST",
    ADMIN_FACIAL_RECOGNITION_NOTIFICATION_REQUEST_FAILURE = "ADMIN_FACIAL_RECOGNITION_NOTIFICATION_REQUEST_FAILURE",
    ADMIN_FACIAL_RECOGNITION_NOTIFICATION_REQUEST_SUCCESS = "ADMIN_FACIAL_RECOGNITION_NOTIFICATION_REQUEST_SUCCESS",
    ADMIN_FACIAL_RECOGNITION_NOTIFICATION_GET_REQUEST = "ADMIN_FACIAL_RECOGNITION_NOTIFICATION_GET_REQUEST",
    ADMIN_FACIAL_RECOGNITION_NOTIFICATION_GET_REQUEST_FAILURE = "ADMIN_FACIAL_RECOGNITION_NOTIFICATION_REQUEST_GET_FAILURE",
    ADMIN_FACIAL_RECOGNITION_NOTIFICATION_GET_REQUEST_SUCCESS = "ADMIN_FACIAL_RECOGNITION_NOTIFICATION_REQUEST_GET_SUCCESS",

    ADMIN_USER_PUSH_TEST_REQUEST = "ADMIN_USER_PUSH_TEST_REQUEST",
    ADMIN_USER_PUSH_TEST_REQUEST_SUCCESS = "ADMIN_USER_PUSH_TEST_REQUEST_SUCCESS",
    ADMIN_USER_PUSH_TEST_REQUEST_FAILURE = "ADMIN_USER_PUSH_TEST_REQUEST_FAILURE",

    ADMIN_NOTIFICATIONS_VENUE_CLEAR = "ADMIN_NOTIFICATIONS_VENUE_CLEAR",
    ADMIN_NOTIFICATIONS_SECURITY_FIRM_CLEAR = "ADMIN_NOTIFICATIONS_SECURITY_FIRM_CLEAR",
    ADMIN_VENUE_INCIDENT_APPROVALS_CLEAR = "ADMIN_VENUE_INCIDENT_APPROVALS_CLEAR",
    
    ADMIN_WEBSITE_RESET_POOL_REQUEST = "ADMIN_WEBSITE_RESET_POOL_REQUEST",
    ADMIN_WEBSITE_RESET_POOL_REQUEST_FAILURE = "ADMIN_WEBSITE_RESET_POOL_REQUEST_FAILURE",
    ADMIN_WEBSITE_RESET_POOL_REQUEST_SUCCESS = "ADMIN_WEBSITE_RESET_POOL_REQUEST_SUCCESS",
    
    ADMIN_WEBSITE_IMPORT_REQUEST = "ADMIN_WEBSITE_IMPORT_REQUEST",
    ADMIN_WEBSITE_IMPORT_REQUEST_FAILURE = "ADMIN_WEBSITE_IMPORT_REQUEST_FAILURE",
    ADMIN_WEBSITE_IMPORT_REQUEST_SUCCESS = "ADMIN_WEBSITE_IMPORT_REQUEST_SUCCESS"
    ;

export const adminNotificationsVenueClearRequest = makeActionCreator(ADMIN_NOTIFICATIONS_VENUE_CLEAR, 'forVenueId', 'simpleDate');
export const adminNotificationsSecurityFirmClearRequest = makeActionCreator(ADMIN_NOTIFICATIONS_SECURITY_FIRM_CLEAR, 'forSecurityFirmId', 'simpleDate');
export const adminUserPushTestRequest = makeActionCreator(ADMIN_USER_PUSH_TEST_REQUEST, 'userId', 'incidentId');
export const adminUserPushTestRequestSuccess = makeActionCreator(ADMIN_USER_PUSH_TEST_REQUEST_SUCCESS);
export const adminUserPushTestRequestFailure = makeActionCreator(ADMIN_USER_PUSH_TEST_REQUEST_FAILURE, 'error');
export const adminVenueIncidentsClearRequest = makeActionCreator(ADMIN_VENUE_INCIDENT_APPROVALS_CLEAR, 'forVenueId', 'simpleDate');

export function adminFacialRecognitionNotificationSetFilter(filter) {
    return {
        type: ADMIN_FACIAL_RECOGNITION_NOTIFICATION_SET_FILTER,
        filter
    }
}

export function adminFacialRecognitionNotificationSetPaging(paging) {
    return {
        type: ADMIN_FACIAL_RECOGNITION_NOTIFICATION_SET_PAGING,
        paging
    }
}

export function adminFacialRecognitionNotificationSetItem(facialRecognitionNotification) {
    return {
        type: ADMIN_FACIAL_RECOGNITION_NOTIFICATION_SET_ITEM,
        facialRecognitionNotification
    }
}

export function adminFacialRecognitionNotificationFilterRequest() {
    return {
        type: ADMIN_FACIAL_RECOGNITION_NOTIFICATION_FILTER_REQUEST
    };
}

export function adminFacialRecognitionNotificationFilterRequestSuccess(filter) {
    return {
        type: ADMIN_FACIAL_RECOGNITION_NOTIFICATION_FILTER_REQUEST_SUCCESS,
        filter
    };
}

export function adminFacialRecognitionNotificationFilterRequestFailure(error) {
    return {
        type: ADMIN_FACIAL_RECOGNITION_NOTIFICATION_FILTER_REQUEST_FAILURE,
        error
    };
}

export function adminFacialRecognitionNotificationRequest() {
    return {
        type: ADMIN_FACIAL_RECOGNITION_NOTIFICATION_REQUEST
    };
}

export function adminFacialRecognitionNotificationRequestSuccess(filter, paging, facialRecognitionNotifications) {
    return {
        type: ADMIN_FACIAL_RECOGNITION_NOTIFICATION_REQUEST_SUCCESS,
        filter,
        paging,
        facialRecognitionNotifications
    };
}

export function adminFacialRecognitionNotificationRequestFailure(error) {
    return {
        type: ADMIN_FACIAL_RECOGNITION_NOTIFICATION_REQUEST_FAILURE,
        error
    };
}

export function adminFacialRecognitionNotificationGetRequest(facialRecognitionNotificationId) {
    return {
        type: ADMIN_FACIAL_RECOGNITION_NOTIFICATION_GET_REQUEST,
        facialRecognitionNotificationId
    };
}

export function adminFacialRecognitionNotificationGetRequestSuccess(facialRecognitionNotification) {
    return {
        type: ADMIN_FACIAL_RECOGNITION_NOTIFICATION_GET_REQUEST_SUCCESS,
        facialRecognitionNotification
    };
}

export function adminFacialRecognitionNotificationGetRequestFailure(error) {
    return {
        type: ADMIN_FACIAL_RECOGNITION_NOTIFICATION_GET_REQUEST_FAILURE,
        error
    };
}

export function adminUserImpersonate(userId) {
    return {
        type: ADMIN_USER_IMPERSONATE,
        userId
    }
}

export function adminUserImpersonateSuccess(details) {
    return {
        type: ADMIN_USER_IMPERSONATE_SUCCESS,
        details
    }
}

export function adminSecurityFirmSubscriptionRequest(securityFirmId) {
    return {
        type: ADMIN_SECURITY_FIRM_SUBSCRIPTION_REQUEST,
        securityFirmId
    };
}

export function adminSecurityFirmSubscriptionRequestSuccess(securityFirmSubscription) {
    return {
        type: ADMIN_SECURITY_FIRM_SUBSCRIPTION_REQUEST_SUCCESS,
        securityFirmSubscription
    };
}

export function adminSecurityFirmSubscriptionRequestFailure(error) {
    return {
        type: ADMIN_SECURITY_FIRM_SUBSCRIPTION_REQUEST_FAILURE,
        error
    };
}

export function adminSecurityFirmSubscriptionUpdate(securityFirmSubscription) {
    return {
        type: ADMIN_SECURITY_FIRM_SUBSCRIPTION_UPDATE,
        securityFirmSubscription
    }
}

export function adminSecurityFirmSubscriptionSaveRequest() {
    return {
        type: ADMIN_SECURITY_FIRM_SUBSCRIPTION_SAVE_REQUEST
    };
}

export function adminSecurityFirmSubscriptionSaveRequestSuccess(securityFirmSubscription) {
    return {
        type: ADMIN_SECURITY_FIRM_SUBSCRIPTION_SAVE_REQUEST_SUCCESS,
        securityFirmSubscription
    };
}

export function adminSecurityFirmSubscriptionSaveRequestFailure(error) {
    return {
        type: ADMIN_SECURITY_FIRM_SUBSCRIPTION_SAVE_REQUEST_FAILURE,
        error
    };
}

export function adminSecurityFirmsRequest(filter, page, pageSize) {
    return {
        type: ADMIN_SECURITY_FIRMS_REQUEST,
        filter,
        page,
        pageSize
    };
}

export function adminSecurityFirmsRefreshRequest() {
    return {
        type: ADMIN_SECURITY_FIRMS_REFRESH_REQUEST
    }
}

export function adminSecurityFirmsRequestSuccess(securityFirms, paging, filter) {
    return {
        type: ADMIN_SECURITY_FIRMS_REQUEST_SUCCESS,
        securityFirms,
        paging,
        filter
    };
}

export function adminSecurityFirmsRequestFailure(error) {
    return {
        type: ADMIN_SECURITY_FIRMS_REQUEST_FAILURE,
        error
    };
}

export function adminSecurityFirmsFilterSet(filter) {
    return {
        type: ADMIN_SECURITY_FIRMS_FILTER_SET,
        filter
    }
}

export function adminDocumentTypesRefresh() {
    return {
        type: ADMIN_DOCUMENT_TYPES_REFRESH
    }
}

export function adminDocumentTypesRequest(page, pageSize, filter) {
    return {
        type: ADMIN_DOCUMENT_TYPES_REQUEST,
        page,
        pageSize,
        filter
    };
}

export function adminDocumentTypesRequestSuccess(documentTypes, paging, filter) {
    return {
        type: ADMIN_DOCUMENT_TYPES_REQUEST_SUCCESS,
        documentTypes,
        paging,
        filter
    };
}

export function adminDocumentTypesRequestFailure(error) {
    return {
        type: ADMIN_DOCUMENT_TYPES_REQUEST_FAILURE,
        error
    };
}

export function adminDocumentTypeReset() {
    return {
        type: ADMIN_DOCUMENT_TYPE_RESET
    };
}

export function adminDocumentTypeSet(documentType) {
    return {
        type: ADMIN_DOCUMENT_TYPE_SET,
        documentType
    };
}

export function adminDocumentTypeRequest(documentTypeId) {
    return {
        type: ADMIN_DOCUMENT_TYPE_REQUEST,
        documentTypeId
    };
}

export function adminDocumentTypeRequestSuccess(documentType, documentCategories, entityTypes) {
    return {
        type: ADMIN_DOCUMENT_TYPE_REQUEST_SUCCESS,
        documentType,
        documentCategories,
        entityTypes
    };
}

export function adminDocumentTypeRequestFailure(error) {
    return {
        type: ADMIN_DOCUMENT_TYPE_REQUEST_FAILURE,
        error
    };
}

export function adminDocumentTypeCreateRequest() {
    return {
        type: ADMIN_DOCUMENT_TYPE_CREATE_REQUEST
    };
}

export function adminDocumentTypeCreateRequestSuccess(documentType, documentCategories, entityTypes) {
    return {
        type: ADMIN_DOCUMENT_TYPE_CREATE_REQUEST_SUCCESS,
        documentType,
        documentCategories,
        entityTypes
    };
}

export function adminDocumentTypeCreateRequestFailure(error) {
    return {
        type: ADMIN_DOCUMENT_TYPE_CREATE_REQUEST_FAILURE,
        error
    };
}

export function adminDocumentTypeUpsertRequest() {
    return {
        type: ADMIN_DOCUMENT_TYPE_UPSERT_REQUEST
    };
}

export function adminDocumentTypeUpsertRequestSuccess(documentType) {
    return {
        type: ADMIN_DOCUMENT_TYPE_UPSERT_REQUEST_SUCCESS,
        documentType
    };
}

export function adminDocumentTypeUpsertRequestFailure(error) {
    return {
        type: ADMIN_DOCUMENT_TYPE_UPSERT_REQUEST_FAILURE,
        error
    };
}

export function adminIncidentCategoryTypesRequest(page, pageSize, filter) {
    return {
        type: ADMIN_INCIDENT_CATEGORY_TYPES_REQUEST,
        page,
        pageSize,
        filter
    };
}

export function adminIncidentCategoryTypesRequestSuccess(data, paging, filter, states) {
    return {
        type: ADMIN_INCIDENT_CATEGORY_TYPES_REQUEST_SUCCESS,
        data,
        paging,
        filter,
        states
    };
}

export function adminIncidentCategoryTypesRequestFailure(error) {
    return {
        type: ADMIN_INCIDENT_CATEGORY_TYPES_REQUEST_FAILURE,
        error
    };
}

export function adminIncidentCategoryTypeSet(incidentCategoryType) {
    return {
        type: ADMIN_INCIDENT_CATEGORY_TYPE_SET,
        incidentCategoryType
    };
}

export function adminIncidentCategoryTypeCreateNewRequest() {
    return {
        type: ADMIN_INCIDENT_CATEGORY_TYPE_CREATE_NEW_REQUEST
    };
}

export function adminIncidentCategoryTypeCreateNewRequestSuccess(incidentCategoryType) {
    return {
        type: ADMIN_INCIDENT_CATEGORY_TYPE_CREATE_NEW_REQUEST_SUCCESS,
        incidentCategoryType
    };
}

export function adminIncidentCategoryTypeCreateNewRequestFailure(error) {
    return {
        type: ADMIN_INCIDENT_CATEGORY_TYPE_CREATE_NEW_REQUEST_FAILURE,
        error
    };
}

export function adminIncidentCategoryTypeUpdateRequest(incidentCategoryType) {
    return {
        type: ADMIN_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST,
        incidentCategoryType
    };
}

export function adminIncidentCategoryTypeUpdateRequestSuccess(incidentCategoryType) {
    return {
        type: ADMIN_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST_SUCCESS,
        incidentCategoryType
    };
}

export function adminIncidentCategoryTypeUpdateRequestFailure(error) {
    return {
        type: ADMIN_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST_FAILURE,
        error
    };
}

export function adminIncidentFlagTypesRequest(page, pageSize, filter) {
    return {
        type: ADMIN_INCIDENT_FLAG_TYPES_REQUEST,
        page,
        pageSize,
        filter
    };
}

export function adminIncidentFlagTypesRequestSuccess(data, paging, filter) {
    return {
        type: ADMIN_INCIDENT_FLAG_TYPES_REQUEST_SUCCESS,
        data,
        paging,
        filter
    };
}

export function adminIncidentFlagTypesRequestFailure(error) {
    return {
        type: ADMIN_INCIDENT_FLAG_TYPES_REQUEST_FAILURE,
        error
    };
}

export function adminIncidentFlagTypeSet(incidentFlagType) {
    return {
        type: ADMIN_INCIDENT_FLAG_TYPE_SET,
        incidentFlagType
    };
}

export function adminIncidentFlagTypeCreateNewRequest() {
    return {
        type: ADMIN_INCIDENT_FLAG_TYPE_CREATE_NEW_REQUEST
    };
}

export function adminIncidentFlagTypeCreateNewRequestSuccess(incidentFlagType) {
    return {
        type: ADMIN_INCIDENT_FLAG_TYPE_CREATE_NEW_REQUEST_SUCCESS,
        incidentFlagType
    };
}

export function adminIncidentFlagTypeCreateNewRequestFailure(error) {
    return {
        type: ADMIN_INCIDENT_FLAG_TYPE_CREATE_NEW_REQUEST_FAILURE,
        error
    };
}

export function adminIncidentFlagTypeUpdateRequest(incidentFlagType) {
    return {
        type: ADMIN_INCIDENT_FLAG_TYPE_UPSERT_REQUEST,
        incidentFlagType
    };
}

export function adminIncidentFlagTypeUpdateRequestSuccess(incidentFlagType) {
    return {
        type: ADMIN_INCIDENT_FLAG_TYPE_UPSERT_REQUEST_SUCCESS,
        incidentFlagType
    };
}

export function adminIncidentFlagTypeUpdateRequestFailure(error) {
    return {
        type: ADMIN_INCIDENT_FLAG_TYPE_UPSERT_REQUEST_FAILURE,
        error
    };
}

export function adminIncidentTypesRequest(page, pageSize, filter) {
    return {
        type: ADMIN_INCIDENT_TYPES_REQUEST,
        page,
        pageSize,
        filter
    };
}

export function adminIncidentTypesRefresh() {
    return {
        type: ADMIN_INCIDENT_TYPES_REFRESH
    };
}

export function adminIncidentTypesRequestSuccess(data, paging, filter) {
    return {
        type: ADMIN_INCIDENT_TYPES_REQUEST_SUCCESS,
        data,
        paging,
        filter
    };
}

export function adminIncidentTypesRequestFailure(error) {
    return {
        type: ADMIN_INCIDENT_TYPES_REQUEST_FAILURE,
        error
    };
}

export function adminIncidentTypeSet(incidentType) {
    return {
        type: ADMIN_INCIDENT_TYPE_SET,
        incidentType
    };
}

export function adminIncidentTypeCreateNewRequest() {
    return {
        type: ADMIN_INCIDENT_TYPE_CREATE_NEW_REQUEST
    };
}

export function adminIncidentTypeCreateNewRequestSuccess(incidentType) {
    return {
        type: ADMIN_INCIDENT_TYPE_CREATE_NEW_REQUEST_SUCCESS,
        incidentType
    };
}

export function adminIncidentTypeCreateNewRequestFailure(error) {
    return {
        type: ADMIN_INCIDENT_TYPE_CREATE_NEW_REQUEST_FAILURE,
        error
    };
}

export function adminIncidentTypeUpdateRequest(incidentType) {
    return {
        type: ADMIN_INCIDENT_TYPE_UPSERT_REQUEST,
        incidentType
    };
}

export function adminIncidentTypeUpdateRequestSuccess(incidentType) {
    return {
        type: ADMIN_INCIDENT_TYPE_UPSERT_REQUEST_SUCCESS,
        incidentType
    };
}

export function adminIncidentTypeUpdateRequestFailure(error) {
    return {
        type: ADMIN_INCIDENT_TYPE_UPSERT_REQUEST_FAILURE,
        error
    };
}

export function adminIndustryCategoriesRequest(page, pageSize, filter) {
    return {
        type: ADMIN_INDUSTRY_CATEGORIES_REQUEST,
        page,
        pageSize,
        filter
    };
}

export function adminIndustryCategoriesSuccess(data, paging, filter) {
    return {
        type: ADMIN_INDUSTRY_CATEGORIES_REQUEST_SUCCESS,
        data,
        paging,
        filter
    };
}

export function adminIndustryCategoriesFailure(error) {
    return {
        type: ADMIN_INDUSTRY_CATEGORIES_REQUEST_FAILURE,
        error
    };
}

export function adminIndustryCategoryCreateRequest(industryCategory) {
    return {
        type: ADMIN_INDUSTRY_CATEGORY_CREATE_REQUEST,
        industryCategory
    };
}

export function adminIndustryCategoryCreateRequestSuccess(industryCategory) {
    return {
        type: ADMIN_INDUSTRY_CATEGORY_CREATE_REQUEST_SUCCESS,
        industryCategory
    };
}

export function adminIndustryCategoryCreateRequestFailure(error) {
    return {
        type: ADMIN_INDUSTRY_CATEGORY_CREATE_REQUEST_FAILURE,
        error
    };
}

export function adminIndustryCategoryUpdateRequest(industryCategory) {
    return {
        type: ADMIN_INDUSTRY_CATEGORY_UPDATE_REQUEST,
        industryCategory
    };
}

export function adminIndustryCategoryUpdateRequestSuccess(industryCategory) {
    return {
        type: ADMIN_INDUSTRY_CATEGORY_UPDATE_REQUEST_SUCCESS,
        industryCategory
    };
}

export function adminIndustryCategoryUpdateRequestFailure(error) {
    return {
        type: ADMIN_INDUSTRY_CATEGORY_UPDATE_REQUEST_FAILURE,
        error
    };
}

export function adminUserRefresh() {
    return {
        type: ADMIN_USER_REFRESH
    }
}

export function adminUserSaveRequest(user) {
    return {
        type: ADMIN_USER_SAVE_REQUEST,
        user
    };
}

export function adminUserSaveRequestSuccess(user) {
    return {
        type: ADMIN_USER_SAVE_REQUEST_SUCCESS,
        user
    };
}

export function adminUserSaveRequestFailure(error) {
    return {
        type: ADMIN_USER_SAVE_REQUEST_FAILURE,
        error
    };
}

export function adminUserSledCheckRequest(user) {
    return {
        type: ADMIN_USER_SLED_CHECK_REQUEST,
        user
    };
}

export function adminUserSledCheckRequestSuccess(user) {
    return {
        type: ADMIN_USER_SLED_CHECK_REQUEST_SUCCESS,
        user
    };
}

export function adminUserSledCheckRequestFailure(error) {
    return {
        type: ADMIN_USER_SLED_CHECK_REQUEST_FAILURE,
        error
    };
}

export function adminUserSet(user) {
    return {
        type: ADMIN_USER_SET,
        user
    }
}

export function adminSaveOtherDocuments(userId, documentTypeId, files) {
    return {
        type: ADMIN_OTHER_DOCUMENTS_SAVE,
        userId,
        documentTypeId,
        files
    };
}

export function adminSaveOtherDocumentsSuccess(userOtherDocuments) {
    return {
        type: ADMIN_OTHER_DOCUMENTS_SAVE_SUCCESS,
        userOtherDocuments
    };
}

export function adminSaveOtherDocumentsFailure(error) {
    return {
        type: ADMIN_OTHER_DOCUMENTS_SAVE_FAILURE,
        error
    };
}

export function adminRemoveOtherDocument(userId, otherDocument) {
    return {
        type: ADMIN_OTHER_DOCUMENT_REMOVE,
        userId,
        otherDocument
    };
}

export function adminRemoveOtherDocumentSuccess(userOtherDocuments) {
    return {
        type: ADMIN_OTHER_DOCUMENT_REMOVE_SUCCESS,
        userOtherDocuments
    };
}

export function adminRemoveOtherDocumentFailure(error) {
    return {
        type: ADMIN_OTHER_DOCUMENT_REMOVE_FAILURE,
        error
    };
}

export function adminSaveComplianceDocuments(userId, documentTypeId, expiryDate, files) {
    return {
        type: ADMIN_COMPLIANCE_DOCUMENTS_SAVE,
        userId,
        documentTypeId,
        expiryDate,
        files
    };
}

export function adminSaveComplianceDocumentsSuccess(userComplianceDocuments) {
    return {
        type: ADMIN_COMPLIANCE_DOCUMENTS_SAVE_SUCCESS,
        userComplianceDocuments
    };
}

export function adminSaveComplianceDocumentsFailure(error) {
    return {
        type: ADMIN_COMPLIANCE_DOCUMENTS_SAVE_FAILURE,
        error
    };
}

export function adminRemoveComplianceDocument(userId, complianceDocument) {
    return {
        type: ADMIN_COMPLIANCE_DOCUMENT_REMOVE,
        userId,
        complianceDocument
    };
}

export function adminRemoveComplianceDocumentSuccess(userComplianceDocuments) {
    return {
        type: ADMIN_COMPLIANCE_DOCUMENT_REMOVE_SUCCESS,
        userComplianceDocuments
    };
}

export function adminRemoveComplianceDocumentFailure(error) {
    return {
        type: ADMIN_COMPLIANCE_DOCUMENT_REMOVE_FAILURE,
        error
    };
}

export function adminUserReset() {
    return {
        type: ADMIN_USER_RESET
    }
}

export function adminUserRequest(userId) {
    return {
        type: ADMIN_USER_REQUEST,
        userId
    };
}

export function adminUserRequestSuccess(user, states, genders, otherDocuments, otherDocumentTypes, complianceDocuments, complianceDocumentTypes, avatar) {
    return {
        type: ADMIN_USER_REQUEST_SUCCESS,
        user,
        states,
        genders,
        otherDocuments,
        otherDocumentTypes,
        complianceDocuments,
        complianceDocumentTypes,
        avatar
    };
}

export function adminUserRequestFailure(error) {
    return {
        type: ADMIN_USER_REQUEST_FAILURE,
        error
    };
}

export function adminUserChangePasswordRequest(userId, password, confirmPassword) {
    return {
        type: ADMIN_USER_CHANGE_PASSWORD_REQUEST,
        userId,
        password,
        confirmPassword
    };
}

export function adminUserChangePasswordRequestSuccess() {
    return {
        type: ADMIN_USER_CHANGE_PASSWORD_REQUEST_SUCCESS
    };
}

export function adminUserChangePasswordRequestFailure(error) {
    return {
        type: ADMIN_USER_CHANGE_PASSWORD_REQUEST_FAILURE,
        error
    };
}

export function adminDuplicateUserMobilesRequest(search, page, pageSize) {
    return {
        type: ADMIN_DUPLICATE_USER_MOBILES_REQUEST,
        search,
        page,
        pageSize
    };
}

export function adminDuplicateUserMobilesSuccess(users, paging, search) {
    return {
        type: ADMIN_DUPLICATE_USER_MOBILES_REQUEST_SUCCESS,
        users,
        paging,
        search
    };
}

export function adminDuplicateUserMobilesFailure(error) {
    return {
        type: ADMIN_DUPLICATE_USER_MOBILES_REQUEST_FAILURE,
        error
    };
}

export function adminDuplicateUserMobileUpdateRequest(userId, mobileNo) {
    return {
        type: ADMIN_DUPLICATE_USER_MOBILE_UPDATE_REQUEST,
        userId,
        mobileNo
    };
}

export function adminDuplicateUserMobileUpdateSuccess() {
    return {
        type: ADMIN_DUPLICATE_USER_MOBILE_UPDATE_REQUEST_SUCCESS
    };
}

export function adminDuplicateUserMobileUpdateFailure(error) {
    return {
        type: ADMIN_DUPLICATE_USER_MOBILE_UPDATE_REQUEST_FAILURE,
        error
    };
}

export function adminUserRolesRequest(filter, page, pageSize) {
    return {
        type: ADMIN_USER_ROLES_REQUEST,
        filter,
        page,
        pageSize
    };
}

export function adminUserRolesRequestSuccess(users, paging, filter) {
    return {
        type: ADMIN_USER_ROLES_REQUEST_SUCCESS,
        users,
        paging,
        filter
    };
}

export function adminUserRolesRequestFailure(error) {
    return {
        type: ADMIN_USER_ROLES_REQUEST_FAILURE,
        error
    };
}

export function adminUserRolesFilterSet(filter) {
    return {
        type: ADMIN_USER_ROLES_FILTER_SET,
        filter
    }
}

export function adminUserActionAuditRequest(userId, page, pageSize) {
    return {
        type: ADMIN_USER_ACTION_AUDITS_REQUEST,
        userId: userId,
        page: page,
        pageSize: pageSize
    }
}

export function adminUserActionAuditSuccess(userActionAudits, paging) {
    return {
        type: ADMIN_USER_ACTION_AUDITS_REQUEST_SUCCESS,
        userActionAudits,
        paging
    };
}

export function adminUserActionAuditFailure(error) {
    return {
        type: ADMIN_USER_ACTION_AUDITS_REQUEST_FAILURE,
        error
    };
}

export function adminUsersRequest(filter, page, pageSize) {
    return {
        type: ADMIN_USERS_REQUEST,
        filter,
        page,
        pageSize
    };
}

export function adminUsersRequestSuccess(users, paging, filter) {
    return {
        type: ADMIN_USERS_REQUEST_SUCCESS,
        users,
        paging,
        filter
    };
}

export function adminUsersRequestFailure(error) {
    return {
        type: ADMIN_USERS_REQUEST_FAILURE,
        error
    };
}

export function adminUsersFilterSet(filter) {
    return {
        type: ADMIN_USERS_FILTER_SET,
        filter
    }
}

export function adminVenuesRequest(filter, page, pageSize) {
    return {
        type: ADMIN_VENUES_REQUEST,
        filter,
        page,
        pageSize
    };
}

export function adminVenuesRefreshRequest() {
    return {
        type: ADMIN_VENUES_REFRESH_REQUEST
    }
}

export function adminVenuesRequestSuccess(venues, paging, filter) {
    return {
        type: ADMIN_VENUES_REQUEST_SUCCESS,
        venues,
        paging,
        filter
    };
}

export function adminVenuesRequestFailure(error) {
    return {
        type: ADMIN_VENUES_REQUEST_FAILURE,
        error
    };
}

export function adminVenuesFilterSet(filter) {
    return {
        type: ADMIN_VENUES_FILTER_SET,
        filter
    }
}

export function adminVenueAssociationsRequest(venueId) {
    return {
        type: ADMIN_VENUE_ASSOCIATIONS_REQUEST,
        venueId
    };
}

export function adminVenueAssociationsRequestSuccess(venueSecurityFirms, securityFirmDescendants) {
    return {
        type: ADMIN_VENUE_ASSOCIATIONS_REQUEST_SUCCESS,
        venueSecurityFirms,
        securityFirmDescendants
    };
}

export function adminVenueAssociationsRequestFailure(error) {
    return {
        type: ADMIN_VENUE_ASSOCIATIONS_REQUEST_FAILURE,
        error
    };
}

export function adminVenueSecurityFirmRemoveRequest(venueSecurityFirmId) {
    return {
        type: ADMIN_VENUESECURITYFIRM_REMOVE_REQUEST,
        venueSecurityFirmId
    };
}

export function adminVenueSecurityFirmRemoveRequestSuccess() {
    return {
        type: ADMIN_VENUESECURITYFIRM_REMOVE_REQUEST_SUCCESS
    };
}

export function adminVenueSecurityFirmRemoveRequestFailure(error) {
    return {
        type: ADMIN_VENUESECURITYFIRM_REMOVE_REQUEST_FAILURE,
        error
    };
}

export function adminVenueSecurityFirmDescendantRemoveRequest(securityFirmDescendantId) {
    return {
        type: ADMIN_VENUESECURITYFIRMDESCENDANT_REMOVE_REQUEST,
        securityFirmDescendantId
    };
}

export function adminVenueSecurityFirmDescendantRemoveRequestSuccess() {
    return {
        type: ADMIN_VENUESECURITYFIRMDESCENDANT_REMOVE_REQUEST_SUCCESS
    };
}

export function adminVenueSecurityFirmDescendantRemoveRequestFailure(error) {
    return {
        type: ADMIN_VENUESECURITYFIRMDESCENDANT_REMOVE_REQUEST_FAILURE,
        error
    };
}

export function adminWebsitePropertiesRequest() {
    return {
        type: ADMIN_WEBSITE_PROPERTIES_REQUEST
    };
}

export function adminWebsitePropertiesRequestSuccess(websiteProperties) {
    return {
        type: ADMIN_WEBSITE_PROPERTIES_REQUEST_SUCCESS,
        websiteProperties
    };
}

export function adminWebsitePropertiesRequestFailure(error) {
    return {
        type: ADMIN_WEBSITE_PROPERTIES_REQUEST_FAILURE,
        error
    };
}

export function adminWebsitePropertiesSaveRequest(websiteProperties) {
    return {
        type: ADMIN_WEBSITE_PROPERTIES_SAVE_REQUEST,
        websiteProperties
    };
}

export function adminWebsitePropertiesSaveRequestSuccess() {
    return {
        type: ADMIN_WEBSITE_PROPERTIES_SAVE_REQUEST_SUCCESS
    };
}

export function adminWebsitePropertiesSaveRequestFailure(error) {
    return {
        type: ADMIN_WEBSITE_PROPERTIES_SAVE_REQUEST_FAILURE,
        error
    };
}

export function adminVenueLocationsRefresh() {
    return {
        type: ADMIN_VENUE_LOCATIONS_REFRESH
    }
}

export function adminVenueLocationsRequest(page, pageSize, filter) {
    return {
        type: ADMIN_VENUE_LOCATIONS_REQUEST,
        page,
        pageSize,
        filter
    };
}

export function adminVenueLocationsRequestSuccess(venueLocations, paging, filter) {
    return {
        type: ADMIN_VENUE_LOCATIONS_REQUEST_SUCCESS,
        venueLocations,
        paging,
        filter
    };
}

export function adminVenueLocationsRequestFailure(error) {
    return {
        type: ADMIN_VENUE_LOCATIONS_REQUEST_FAILURE,
        error
    };
}

export function adminVenueLocationReset() {
    return {
        type: ADMIN_VENUE_LOCATION_RESET
    };
}

export function adminVenueLocationSet(venueLocation) {
    return {
        type: ADMIN_VENUE_LOCATION_SET,
        venueLocation
    };
}

export function adminVenueLocationRequest(venueLocationId) {
    return {
        type: ADMIN_VENUE_LOCATION_REQUEST,
        venueLocationId
    };
}

export function adminVenueLocationRequestSuccess(venueLocation) {
    return {
        type: ADMIN_VENUE_LOCATION_REQUEST_SUCCESS,
        venueLocation
    };
}

export function adminVenueLocationRequestFailure(error) {
    return {
        type: ADMIN_VENUE_LOCATION_REQUEST_FAILURE,
        error
    };
}

export function adminVenueLocationCreateRequest() {
    return {
        type: ADMIN_VENUE_LOCATION_CREATE_REQUEST
    };
}

export function adminVenueLocationCreateRequestSuccess(venueLocation) {
    return {
        type: ADMIN_VENUE_LOCATION_CREATE_REQUEST_SUCCESS,
        venueLocation
    };
}

export function adminVenueLocationCreateRequestFailure(error) {
    return {
        type: ADMIN_VENUE_LOCATION_CREATE_REQUEST_FAILURE,
        error
    };
}

export function adminVenueLocationUpsertRequest() {
    return {
        type: ADMIN_VENUE_LOCATION_UPSERT_REQUEST
    };
}

export function adminVenueLocationUpsertRequestSuccess(venueLocation) {
    return {
        type: ADMIN_VENUE_LOCATION_UPSERT_REQUEST_SUCCESS,
        venueLocation
    };
}

export function adminVenueLocationUpsertRequestFailure(error) {
    return {
        type: ADMIN_VENUE_LOCATION_UPSERT_REQUEST_FAILURE,
        error
    };
}

export function adminVenueRegistratoinDismissRequest(forVenueId) {
    return {
        type: ADMIN_VENUE_REGISTRATION_DISMISS_REQUEST,
        forVenueId
    };
}

export function adminVenueRegistratoinDismissRequestSuccess(venue) {
    return {
        type: ADMIN_VENUE_REGISTRATION_DISMISS_REQUEST_SUCCESS,
        venue
    };
}

export function adminVenueRegistratoinDismissRequestFailure(error) {
    return {
        type: ADMIN_VENUE_REGISTRATION_DISMISS_REQUEST_FAILURE,
        error
    };
}

export function adminVenueObsoleteRequest(venueToRemoveId) {
    return {
        type: ADMIN_VENUE_OBSOLETE_REQUEST,
        venueToRemoveId
    };
}

export function adminVenueObsoleteRequestSuccess() {
    return {
        type: ADMIN_VENUE_OBSOLETE_REQUEST_SUCCESS
    };
}

export function adminVenueObsoleteRequestFailure(error) {
    return {
        type: ADMIN_VENUE_OBSOLETE_REQUEST_FAILURE,
        error
    };
}

export function adminVenueDeleteRequest(venueToDeleteId) {
    return {
        type: ADMIN_VENUE_DELETE_REQUEST,
        venueToDeleteId
    };
}

export function adminVenueDeleteRequestSuccess() {
    return {
        type: ADMIN_VENUE_DELETE_REQUEST_SUCCESS
    };
}

export function adminVenueDeleteRequestFailure(error) {
    return {
        type: ADMIN_VENUE_DELETE_REQUEST_FAILURE,
        error
    };
}

export function adminVenueExportRequest() {
    return {
        type: ADMIN_VENUE_EXPORT_REQUEST
    };
}

export function adminVenueExportRequestSuccess() {
    return {
        type: ADMIN_VENUE_EXPORT_REQUEST_SUCCESS
    };
}

export function adminVenueExportRequestFailure(error) {
    return {
        type: ADMIN_VENUE_EXPORT_REQUEST_FAILURE,
        error
    };
}

export function adminVenueSubscriptionRequest(venueId) {
    return {
        type: ADMIN_VENUE_SUBSCRIPTION_REQUEST,
        venueId
    };
}

export function adminVenueSubscriptionRequestSuccess(venueSubscription, industryCategories) {
    return {
        type: ADMIN_VENUE_SUBSCRIPTION_REQUEST_SUCCESS,
        venueSubscription,
        industryCategories
    };
}

export function adminVenueSubscriptionRequestFailure(error) {
    return {
        type: ADMIN_VENUE_SUBSCRIPTION_REQUEST_FAILURE,
        error
    };
}

export function adminVenueSubscriptionUpdate(venueSubscription) {
    return {
        type: ADMIN_VENUE_SUBSCRIPTION_UPDATE,
        venueSubscription
    }
}

export function adminVenueSubscriptionSaveRequest() {
    return {
        type: ADMIN_VENUE_SUBSCRIPTION_SAVE_REQUEST
    };
}

export function adminVenueSubscriptionSaveRequestSuccess(venueSubscription) {
    return {
        type: ADMIN_VENUE_SUBSCRIPTION_SAVE_REQUEST_SUCCESS,
        venueSubscription
    };
}

export function adminVenueSubscriptionSaveRequestFailure(error) {
    return {
        type: ADMIN_VENUE_SUBSCRIPTION_SAVE_REQUEST_FAILURE,
        error
    };
}


export function adminSecurityFirmObsoleteRequest(securityFirmToRemoveId) {
    return {
        type: ADMIN_SECURITY_FIRM_OBSOLETE_REQUEST,
        securityFirmToRemoveId
    };
}

export function adminSecurityFirmObsoleteRequestSuccess() {
    return {
        type: ADMIN_SECURITY_FIRM_OBSOLETE_REQUEST_SUCCESS
    };
}

export function adminSecurityFirmObsoleteRequestFailure(error) {
    return {
        type: ADMIN_SECURITY_FIRM_OBSOLETE_REQUEST_FAILURE,
        error
    };
}

export function adminSecurityFirmDeleteRequest(securityFirmToDeleteId) {
    return {
        type: ADMIN_SECURITY_FIRM_DELETE_REQUEST,
        securityFirmToDeleteId
    };
}

export function adminSecurityFirmDeleteRequestSuccess() {
    return {
        type: ADMIN_SECURITY_FIRM_DELETE_REQUEST_SUCCESS
    };
}

export function adminSecurityFirmDeleteRequestFailure(error) {
    return {
        type: ADMIN_SECURITY_FIRM_DELETE_REQUEST_FAILURE,
        error
    };
}

export function adminSecurityFirmExportRequest() {
    return {
        type: ADMIN_SECURITY_FIRM_EXPORT_REQUEST
    };
}

export function adminSecurityFirmExportRequestSuccess() {
    return {
        type: ADMIN_SECURITY_FIRM_EXPORT_REQUEST_SUCCESS
    };
}

export function adminSecurityFirmExportRequestFailure(error) {
    return {
        type: ADMIN_SECURITY_FIRM_EXPORT_REQUEST_FAILURE,
        error
    };
}

export function adminSecurityFirmRegistratoinDismissRequest(forSecurityFirmId) {
    return {
        type: ADMIN_SECURITY_FIRM_REGISTRATION_DISMISS_REQUEST,
        forSecurityFirmId
    };
}

export function adminSecurityFirmRegistratoinDismissRequestSuccess(securityFirm) {
    return {
        type: ADMIN_SECURITY_FIRM_REGISTRATION_DISMISS_REQUEST_SUCCESS,
        securityFirm
    };
}

export function adminSecurityFirmRegistratoinDismissRequestFailure(error) {
    return {
        type: ADMIN_SECURITY_FIRM_REGISTRATION_DISMISS_REQUEST_FAILURE,
        error
    };
}

export function adminWebsiteResetCacheRequest() {
    return {
        type: ADMIN_WEBSITE_RESET_CACHE_REQUEST
    };
}

export function adminWebsiteResetCacheRequestSuccess() {
    return {
        type: ADMIN_WEBSITE_RESET_CACHE_REQUEST_SUCCESS
    };
}

export function adminWebsiteResetCacheRequestFailure(error) {
    return {
        type: ADMIN_WEBSITE_RESET_CACHE_REQUEST_FAILURE,
        error
    };
}

export function adminWebsiteResetPoolRequest() {
    return {
        type: ADMIN_WEBSITE_RESET_POOL_REQUEST
    };
}

export function adminWebsiteResetPoolRequestSuccess() {
    return {
        type: ADMIN_WEBSITE_RESET_POOL_REQUEST_SUCCESS
    };
}

export function adminWebsiteResetPoolRequestFailure(error) {
    return {
        type: ADMIN_WEBSITE_RESET_POOL_REQUEST_FAILURE,
        error
    };
}

export function adminWebsiteImportRequest() {
    return {
        type: ADMIN_WEBSITE_IMPORT_REQUEST
    };
}

export function adminWebsiteImportRequestSuccess() {
    return {
        type: ADMIN_WEBSITE_IMPORT_REQUEST_SUCCESS
    };
}

export function adminWebsiteImportRequestFailure(error) {
    return {
        type: ADMIN_WEBSITE_IMPORT_REQUEST_FAILURE,
        error
    };
}