import update from "immutability-helper";
import * as adminActions from "../actions/admin";
import { userLogic } from '../logic/userLogic';

const initialState = {
    facialRecognitionNotifications: {
        isLoading: false,
        error: "",
        facialRecognitionNotifications: [],
        facialRecognitionNotification: {
            isLoading: false,
            facialRecognitionNotification: { facialRecognitionNotificationId: -1 }
        },
        paging: { pageSize: 0, page: 1 },
        filter: { search: "", showObsolete: false }
    },
    duplicateUserMobiles: {
        isLoading: false,
        isSaving: false,
        users: [],
        paging: { pageSize: 0, page: 1 },
        search: ""
    },
    userRoles: {
        isLoading: false,
        userRoles: [],
        paging: { pageSize: 0, page: 1 },
        filter: { search: "" }
    },
    userActionAudits: {
        isLoading: false,
        userActionAudits: [],
        paging: { pageSize: 0, page: 1 }
    },
    industryCategories: {
        isLoading: false,
        isSaving: false,
        error: "",
        industryCategories: [],
        paging: { pageSize: 0, page: 1 },
        filter: { search: "", showObsolete: false }
    },
    users: {
        isLoading: false,
        users: [],
        paging: { pageSize: 0, page: 1 },
        filter: { search: "" }
    },
    venues: {
        isLoading: false,
        venues: [],
        paging: { pageSize: 0, page: 1 },
        filter: { search: "" },
        venueId: 0,
        venueSecurityFirms: [],
        securityFirmDescendants: []
    },
    websiteProperties: {
        isLoading: false,
        isSaving: false,
        websiteProperties: {}
    },
    user: {
        isSavingPassword: false,
        changePasswordError: "",
        isLoading: false,
        isSaving: false,
        user: { userId: 0 },
        error: null,
        isCheckingLicense: false,
        errors: {},
        states: [],
        genders: [],
        otherDocuments: [],
        otherDocumentTypes: [],
        complianceDocuments: [],
        complianceDocumentTypes: [],
        avatar: {}
    },
    incidentTypes: {
        isLoading: false,
        isSaving: false,
        error: "",
        incidentTypes: [],
        incidentType: { incidentTypeId: -1 },
        paging: { pageSize: 0, page: 1 },
        filter: { search: "", showObsolete: false }
    },
    incidentFlagTypes: {
        isLoading: false,
        isSaving: false,
        error: "",
        incidentFlagTypes: [],
        incidentFlagType: { incidentFlagTypeId: -1 },
        paging: { pageSize: 0, page: 1 },
        filter: { search: "", showObsolete: false }
    },
    incidentCategoryTypes: {
        isLoading: false,
        isSaving: false,
        error: "",
        incidentCategoryTypes: [],
        incidentCategoryType: { incidentCategoryTypeId: -1 },
        paging: { pageSize: 0, page: 1 },
        filter: { search: "", showObsolete: false },
        states: []
    },
    userRoleTypes: {
        userRoleTypes: [],
    },
    venueLocations: {
        isLoading: false,
        filter: {},
        venueLocations: [],
        paging: { pageSize: 0, page: 1 }
    },
    venueLocation: {
        venueLocationId: -1,
        isLoading: false,
        isSaving: false,
        venueLocation: { venueLocationId: 0 }
    },
    venueSubscription: {
        isLoading: false,
        isSaving: false,
        error: null,
        venueSubscription: { venueId: 0 },
        industryCategories: []
    },
    documentTypes: {
        isLoading: false,
        filter: {},
        documentTypes: [],
        paging: { pageSize: 0, page: 1 }
    },
    documentType: {
        documentTypeId: -1,
        isLoading: false,
        isSaving: false,
        documentType: { documentTypeId: 0 },
        documentCategories: [],
        entityTypes: []
    },
    securityFirms: {
        isLoading: false,
        securityFirms: [],
        paging: { pageSize: 0, page: 1 },
        filter: { search: "" },
        securityFirmsId: 0
    },
    securityFirmSubscription: {
        isLoading: false,
        isSaving: false,
        error: null,
        securityFirmSubscription: { securityFirmId: 0 }
    },
};


function loadingFacialRecognitionNotifications(state, isLoading) {
    let newState = update(state, {
        facialRecognitionNotifications: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setFacialRecognitionNotificationFilter(state, filter) {
    let newState = update(state, {
        facialRecognitionNotifications: {
            filter: { $set: filter }
        }
    });
    return newState;
}

function setFacialRecognitionNotificationPaging(state, paging) {
    let newState = update(state, {
        facialRecognitionNotifications: {
            paging: { $set: paging }
        }
    });
    return newState;
}

function setFacialRecognitionNotificationError(state, error) {
    let newState = update(state, {
        facialRecognitionNotifications: {
            error: { $set: error }
        }
    });
    return newState;
}

function setFacialRecognitionNotifications(state, facialRecognitionNotifications) {
    let newState = update(state, {
        facialRecognitionNotifications: {
            facialRecognitionNotifications: { $set: facialRecognitionNotifications }
        }
    });
    return newState;
}

function setFacialRecognitionNotification(state, facialRecognitionNotification) {
    let newState = update(state, {
        facialRecognitionNotifications: {
            facialRecognitionNotification: {
                facialRecognitionNotification: { $set: facialRecognitionNotification }
            }
        }
    });
    return newState;
}

function setFacialRecognitionNotificationIsLoading(state, isLoading) {
    let newState = update(state, {
        facialRecognitionNotifications: {
            facialRecognitionNotification: {
                isLoading: { $set: isLoading }
            }
        }
    });
    return newState;
}


function loadingDocumentTypes(state, isLoading) {
    let newState = update(state, {
        documentTypes: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setDocumentTypes(state, documentTypes, filter, paging) {
    let newState = update(state, {
        documentTypes: {
            documentTypes: { $set: documentTypes },
            filter: { $set: filter },
            paging: { $set: paging }
        }
    });
    return newState;
}

function loadingDocumentType(state, isLoading) {
    let newState = update(state, {
        documentType: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setDocumentType(state, documentType) {
    let newState = update(state, {
        documentType: {
            documentType: { $set: documentType }
        }
    });
    return newState;
}

function setDocumentTypeOptions(state, documentCategories, entityTypes) {
    let newState = update(state, {
        documentType: {
            documentCategories: { $set: documentCategories },
            entityTypes: { $set: entityTypes }
        }
    });
    return newState;
}

function savingDocumentType(state, isSaving) {
    let newState = update(state, {
        documentType: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setDocumentTypeId(state, documentTypeId) {
    let newState = update(state, {
        documentType: {
            documentTypeId: { $set: documentTypeId }
        }
    });
    return newState;
}

function setLoadingIncidentCategoryTypes(state, isLoading) {
    let newState = update(state, {
        incidentCategoryTypes: {
            isLoading: { $set: isLoading },
            error: { $set: "" }
        }
    });
    return newState;
}

function setSavingIncidentCategoryTypes(state, isSaving, error) {
    let newState = update(state, {
        incidentCategoryTypes: {
            isSaving: { $set: isSaving },
            error: { $set: error }
        }
    });
    return newState;
}

function setIncidentCategoryTypes(state, incidentCategoryTypes, paging, filter, states) {
    let newState = update(state, {
        incidentCategoryTypes: {
            incidentCategoryTypes: { $set: incidentCategoryTypes },
            paging: { $set: paging },
            filter: { $set: filter },
            states: { $set: states }
        }
    });
    return newState;
}

function setIncidentCategoryType(state, incidentCategoryType) {
    let newState = update(state, {
        incidentCategoryTypes: {
            incidentCategoryType: { $set: incidentCategoryType }
        }
    });
    return newState;
}

function setLoadingIncidentFlagTypes(state, isLoading) {
    let newState = update(state, {
        incidentFlagTypes: {
            isLoading: { $set: isLoading },
            error: { $set: "" }
        }
    });
    return newState;
}

function setSavingIncidentFlagTypes(state, isSaving, error) {
    let newState = update(state, {
        incidentFlagTypes: {
            isSaving: { $set: isSaving },
            error: { $set: error }
        }
    });
    return newState;
}

function setIncidentFlagTypes(state, incidentFlagTypes, paging, filter) {
    let newState = update(state, {
        incidentFlagTypes: {
            incidentFlagTypes: { $set: incidentFlagTypes },
            paging: { $set: paging },
            filter: { $set: filter }
        }
    });
    return newState;
}

function setIncidentFlagType(state, incidentFlagType) {
    let newState = update(state, {
        incidentFlagTypes: {
            incidentFlagType: { $set: incidentFlagType }
        }
    });
    return newState;
}

function setLoadingIncidentTypes(state, isLoading) {
    let newState = update(state, {
        incidentTypes: {
            isLoading: { $set: isLoading },
            error: { $set: "" }
        }
    });
    return newState;
}

function setSavingIncidentTypes(state, isSaving, error) {
    let newState = update(state, {
        incidentTypes: {
            isSaving: { $set: isSaving },
            error: { $set: error }
        }
    });
    return newState;
}

function setIncidentTypes(state, incidentTypes, paging, filter) {
    let newState = update(state, {
        incidentTypes: {
            incidentTypes: { $set: incidentTypes },
            paging: { $set: paging },
            filter: { $set: filter }
        }
    });
    return newState;
}

function setIncidentType(state, incidentType) {
    let newState = update(state, {
        incidentTypes: {
            incidentType: { $set: incidentType }
        }
    });
    return newState;
}

function isCheckingLicense(state, isCheckingLicense) {
    let newState = update(state, {
        user: {
            isCheckingLicense: { $set: isCheckingLicense }
        }
    });
    return newState;
}

function updateUser(state, user) {
    let newState = update(state, {
        user: {
            user: { $set: user }
        }
    });
    return newState;
}

function setUser(state, user, states, genders, otherDocuments, otherDocumentTypes, complianceDocuments, complianceDocumentTypes, avatar) {
    let newState = update(state, {
        user: {
            user: { $set: user },
            states: { $set: states },
            genders: { $set: genders },
            otherDocuments: { $set: otherDocuments },
            otherDocumentTypes: { $set: otherDocumentTypes },
            complianceDocuments: { $set: complianceDocuments },
            complianceDocumentTypes: { $set: complianceDocumentTypes },
            avatar: { $set: avatar }
        }
    });
    return newState;
}

function setUserErrors(state) {
    let newState = update(state, {
        user: {
            errors: { $set: userLogic.validateUser(state.user.user) }
        }
    });
    return newState;
}

function setUserIsLoading(state, isLoading) {
    let newState = update(state, {
        user: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setUserError(state, error) {
    let newState = update(state, {
        user: {
            error: { $set: error }
        }
    });
    return newState;
}

function setUserIsSaving(state, isSaving) {
    let newState = update(state, {
        user: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setOtherDocuments(state, otherDocuments) {
    let newState = update(state, {
        user: {
            otherDocuments: { $set: otherDocuments }
        }
    });
    return newState;
}

function setComplianceDocuments(state, complianceDocuments) {
    let newState = update(state, {
        user: {
            complianceDocuments: { $set: complianceDocuments }
        }
    });
    return newState;
}

function setUserPasswordChange(state, isSaving, error) {
    let newState = update(state, {
        user: {
            isSavingPassword: { $set: isSaving },
            changePasswordError: { $set: error }
        }
    });
    return newState;
}

function setLoadingDuplicateUserMobiles(state, isLoading) {
    let newState = update(state, {
        duplicateUserMobiles: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setDuplicateSaving(state, isSaving) {
    let newState = update(state, {
        duplicateUserMobiles: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setDuplicateUserMobiles(state, users, paging, search) {
    let newState = update(state, {
        duplicateUserMobiles: {
            users: { $set: users },
            paging: { $set: paging },
            search: { $set: search }
        }
    });
    return newState;
}

function setLoadingUserRoles(state, isLoading) {
    let newState = update(state, {
        userRoles: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setUserRoles(state, users, paging, filter) {
    let newState = update(state, {
        userRoles: {
            userRoles: { $set: users },
            paging: { $set: paging },
            filter: { $set: filter }
        }
    });
    return newState;
}

function setUserRolesFilter(state, filter) {
    let newState = update(state, {
        userRoles: {
            filter: { $set: filter }
        }
    });
    return newState;
}

function setLoadingIndustryCategories(state, isLoading) {
    let newState = update(state, {
        industryCategories: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setSavingIndustryCategories(state, isSaving, error) {
    let newState = update(state, {
        industryCategories: {
            isSaving: { $set: isSaving },
            error: { $set: error }
        }
    });
    return newState;
}

function setIndustryCategories(state, industryCategories, paging, filter) {
    let newState = update(state, {
        industryCategories: {
            industryCategories: { $set: industryCategories },
            paging: { $set: paging },
            filter: { $set: filter }
        }
    });
    return newState;
}

function setLoadingUserActionAudits(state, isLoading) {
    let newState = update(state, {
        userActionAudits: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setUserActionAudits(state, userActionAudits, paging) {
    let newState = update(state, {
        userActionAudits: {
            userActionAudits: { $set: userActionAudits },
            paging: { $set: paging }
        }
    });
    return newState;
}

function setLoadingUsers(state, isLoading) {
    let newState = update(state, {
        users: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setUsers(state, users, paging, filter) {
    let newState = update(state, {
        users: {
            users: { $set: users },
            paging: { $set: paging },
            filter: { $set: filter }
        }
    });
    return newState;
}

function setUsersFilter(state, filter) {
    let newState = update(state, {
        users: {
            filter: { $set: filter }
        }
    });
    return newState;
}

function setLoadingVenues(state, isLoading) {
    let newState = update(state, {
        venues: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setVenues(state, venues, paging, filter) {
    let newState = update(state, {
        venues: {
            venues: { $set: venues },
            paging: { $set: paging },
            filter: { $set: filter }
        }
    });
    return newState;
}

function setVenuesFilter(state, filter) {
    let newState = update(state, {
        venues: {
            filter: { $set: filter }
        }
    });
    return newState;
}

function setVenueId(state, venueId) {
    let newState = update(state, {
        venues: {
            venueId: { $set: venueId }
        }
    });
    return newState;
}

function setVenueAssociations(state, venueSecurityFirms, securityFirmDescendants) {
    let newState = update(state, {
        venues: {
            venueSecurityFirms: { $set: venueSecurityFirms },
            securityFirmDescendants: { $set: securityFirmDescendants }
        }
    });
    return newState;
}

function setWebsitePropertiesIsLoading(state, isLoading) {
    let newState = update(state, {
        websiteProperties: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setWebsitePropertiesIsSaving(state, isSaving) {
    let newState = update(state, {
        websiteProperties: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setWebsiteProperties(state, websiteProperties) {
    let newState = update(state, {
        websiteProperties: {
            websiteProperties: { $set: websiteProperties }
        }
    });
    return newState;
}

function loadingVenueLocations(state, isLoading) {
    let newState = update(state, {
        venueLocations: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setVenueLocations(state, venueLocations, filter, paging) {
    let newState = update(state, {
        venueLocations: {
            venueLocations: { $set: venueLocations },
            filter: { $set: filter },
            paging: { $set: paging }
        }
    });
    return newState;
}

function loadingVenueLocation(state, isLoading) {
    let newState = update(state, {
        venueLocation: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setVenueLocation(state, venueLocation) {
    let newState = update(state, {
        venueLocation: {
            venueLocation: { $set: venueLocation }
        }
    });
    return newState;
}

function savingVenueLocation(state, isSaving) {
    let newState = update(state, {
        venueLocation: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setVenueLocationId(state, venueLocationId) {
    let newState = update(state, {
        venueLocation: {
            venueLocationId: { $set: venueLocationId }
        }
    });
    return newState;
}

function loadingVenueSubscription(state, isLoading, error) {
    let newState = update(state, {
        venueSubscription: {
            isLoading: { $set: isLoading },
            error: { $set: error }
        }
    });
    return newState;
}

function setVenueSubscription(state, venueSubscription) {
    let newState = update(state, {
        venueSubscription: {
            venueSubscription: { $set: venueSubscription }
        }
    });
    return newState;
}

function setVenueSubscriptionOptions(state, industryCategories) {
    let newState = update(state, {
        venueSubscription: {
            industryCategories: { $set: industryCategories }
        }
    });
    return newState;
}

function savingVenueSubscription(state, isSaving, error) {
    let newState = update(state, {
        venueSubscription: {
            isSaving: { $set: isSaving },
            error: { $set: error }
        }
    });
    return newState;
}

function setLoadingSecurityFirms(state, isLoading) {
    let newState = update(state, {
        securityFirms: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setSecurityFirms(state, securityFirms, paging, filter) {
    let newState = update(state, {
        securityFirms: {
            securityFirms: { $set: securityFirms },
            paging: { $set: paging },
            filter: { $set: filter }
        }
    });
    return newState;
}

function setSecurityFirmsPage(state, paging) {
    let newState = update(state, {
        securityFirms: {
            paging: { $set: paging }
        }
    });
    return newState;
}

function setSecurityFirmsFilter(state, filter) {
    let newState = update(state, {
        securityFirms: {
            filter: { $set: filter }
        }
    });
    return newState;
}

function setSecurityFirmId(state, securityFirmId) {
    let newState = update(state, {
        securityFirms: {
            securityFirmId: { $set: securityFirmId }
        }
    });
    return newState;
}

function loadingSecurityFirmSubscription(state, isLoading, error) {
    let newState = update(state, {
        securityFirmSubscription: {
            isLoading: { $set: isLoading },
            error: { $set: error }
        }
    });
    return newState;
}

function setSecurityFirmSubscription(state, securityFirmSubscription) {
    let newState = update(state, {
        securityFirmSubscription: {
            securityFirmSubscription: { $set: securityFirmSubscription }
        }
    });
    return newState;
}

function savingSecurityFirmSubscription(state, isSaving, error) {
    let newState = update(state, {
        securityFirmSubscription: {
            isSaving: { $set: isSaving },
            error: { $set: error }
        }
    });
    return newState;
}

export default function admin(state = initialState, action) {
    switch (action.type) {
        case adminActions.ADMIN_DUPLICATE_USER_MOBILES_REQUEST:
            return setLoadingDuplicateUserMobiles(state, true);
        case adminActions.ADMIN_DUPLICATE_USER_MOBILES_REQUEST_SUCCESS:
            return setLoadingDuplicateUserMobiles(setDuplicateUserMobiles(state, action.users, action.paging, action.search), false);
        case adminActions.ADMIN_DUPLICATE_USER_MOBILES_REQUEST_FAILURE:
            return setLoadingDuplicateUserMobiles(setDuplicateUserMobiles(state, [], { pageSize: 0, page: 1 }, action.search), false);
        case adminActions.ADMIN_DUPLICATE_USER_MOBILE_UPDATE_REQUEST:
            return setDuplicateSaving(state, true);
        case adminActions.ADMIN_DUPLICATE_USER_MOBILE_UPDATE_REQUEST_FAILURE:
            return setDuplicateSaving(state, false);
        case adminActions.ADMIN_DUPLICATE_USER_MOBILE_UPDATE_REQUEST_SUCCESS:
            return setDuplicateSaving(state, false);
        case adminActions.ADMIN_USER_ROLES_REQUEST:
            return setLoadingUserRoles(state, true);
        case adminActions.ADMIN_USER_ROLES_REQUEST_SUCCESS:
            return setLoadingUserRoles(setUserRoles(state, action.users, action.paging, action.filter), false);
        case adminActions.ADMIN_USER_ROLES_REQUEST_FAILURE:
            return setLoadingUserRoles(setUserRoles(state, [], { pageSize: 0, page: 1 }, action.filter), false);
        case adminActions.ADMIN_USER_ROLES_FILTER_SET:
            return setUserRolesFilter(state, action.filter);
        case adminActions.ADMIN_USER_ACTION_AUDITS_REQUEST:
            return setLoadingUserActionAudits(state, true);
        case adminActions.ADMIN_USER_ACTION_AUDITS_REQUEST_SUCCESS:
            return setLoadingUserActionAudits(setUserActionAudits(state, action.userActionAudits, action.paging), false);
        case adminActions.ADMIN_USER_ACTION_AUDITS_REQUEST_FAILURE:
            return setLoadingUserActionAudits(setUserActionAudits(state, [], { pageSize: 0, page: 1 }), false);
        case adminActions.ADMIN_USERS_REQUEST:
            return setLoadingUsers(state, true);
        case adminActions.ADMIN_USERS_REQUEST_SUCCESS:
            return setLoadingUsers(setUsers(state, action.users, action.paging, action.filter), false);
        case adminActions.ADMIN_USERS_REQUEST_FAILURE:
            return setLoadingUsers(setUsers(state, [], { pageSize: 0, page: 1 }, action.filter), false);
        case adminActions.ADMIN_USERS_FILTER_SET:
            return setUsersFilter(state, action.filter);
        case adminActions.ADMIN_VENUES_REQUEST:
            return setLoadingVenues(state, true);
        case adminActions.ADMIN_VENUES_REFRESH_REQUEST:
            return setLoadingVenues(state, true);
        case adminActions.ADMIN_VENUES_REQUEST_SUCCESS:
            return setLoadingVenues(setVenues(state, action.venues, action.paging, action.filter), false);
        case adminActions.ADMIN_VENUES_REQUEST_FAILURE:
            return setLoadingVenues(setVenues(state, [], { pageSize: 0, page: 1 }, action.filter), false);
        case adminActions.ADMIN_VENUES_FILTER_SET:
            return setVenuesFilter(state, action.filter);
        case adminActions.ADMIN_VENUE_DELETE_REQUEST:
            return setLoadingVenues(state, true);
        case adminActions.ADMIN_VENUE_DELETE_REQUEST_SUCCESS:
            return setLoadingVenues(state, false);
        case adminActions.ADMIN_VENUE_DELETE_REQUEST_FAILURE:
            return setLoadingVenues(state, false);
        case adminActions.ADMIN_VENUE_OBSOLETE_REQUEST:
            return setLoadingVenues(state, true);
        case adminActions.ADMIN_VENUE_OBSOLETE_REQUEST_SUCCESS:
            return setLoadingVenues(state, false);
        case adminActions.ADMIN_VENUE_OBSOLETE_REQUEST_FAILURE:
            return setLoadingVenues(state, false);
        case adminActions.ADMIN_VENUE_REGISTRATION_DISMISS_REQUEST:
            return setLoadingVenues(state, true);
        case adminActions.ADMIN_VENUE_REGISTRATION_DISMISS_REQUEST_SUCCESS:
            return setLoadingVenues(state, false);
        case adminActions.ADMIN_VENUE_REGISTRATION_DISMISS_REQUEST_FAILURE:
            return setLoadingVenues(state, false);

        case adminActions.ADMIN_VENUE_ASSOCIATIONS_REQUEST:
            return setLoadingVenues(setVenueId(setVenueAssociations(state, [], []), action.venueId), true);
        case adminActions.ADMIN_VENUE_ASSOCIATIONS_REQUEST_SUCCESS:
            return setLoadingVenues(setVenueAssociations(state, action.venueSecurityFirms, action.securityFirmDescendants), false);
        case adminActions.ADMIN_VENUE_ASSOCIATIONS_REQUEST_FAILURE:
            return setLoadingVenues(state, false);
        case adminActions.ADMIN_VENUESECURITYFIRM_REMOVE_REQUEST:
            return setLoadingVenues(state, true);
        case adminActions.ADMIN_VENUESECURITYFIRM_REMOVE_REQUEST_FAILURE:
            return setLoadingVenues(state, false);
        case adminActions.ADMIN_VENUESECURITYFIRM_REMOVE_REQUEST_SUCCESS:
            return setLoadingVenues(state, false);
        case adminActions.ADMIN_VENUESECURITYFIRMDESCENDANT_REMOVE_REQUEST:
            return setLoadingVenues(state, true);
        case adminActions.ADMIN_VENUESECURITYFIRMDESCENDANT_REMOVE_REQUEST_FAILURE:
            return setLoadingVenues(state, false);
        case adminActions.ADMIN_VENUESECURITYFIRMDESCENDANT_REMOVE_REQUEST_SUCCESS:
            return setLoadingVenues(state, false);
        case adminActions.ADMIN_WEBSITE_PROPERTIES_REQUEST:
            return setWebsitePropertiesIsLoading(state, true);
        case adminActions.ADMIN_WEBSITE_PROPERTIES_REQUEST_FAILURE:
            return setWebsitePropertiesIsLoading(state, false);
        case adminActions.ADMIN_WEBSITE_PROPERTIES_REQUEST_SUCCESS:
            return setWebsitePropertiesIsLoading(setWebsiteProperties(state, action.websiteProperties), false);
        case adminActions.ADMIN_WEBSITE_PROPERTIES_SAVE_REQUEST:
            return setWebsitePropertiesIsSaving(state, true);
        case adminActions.ADMIN_WEBSITE_PROPERTIES_SAVE_REQUEST_FAILURE:
            return setWebsitePropertiesIsSaving(state, false);
        case adminActions.ADMIN_WEBSITE_PROPERTIES_SAVE_REQUEST_SUCCESS:
            return setWebsitePropertiesIsSaving(state, false);
        case adminActions.ADMIN_USER_CHANGE_PASSWORD_REQUEST:
            return setUserPasswordChange(state, true, "");
        case adminActions.ADMIN_USER_CHANGE_PASSWORD_REQUEST_SUCCESS:
            return setUserPasswordChange(state, false, "");
        case adminActions.ADMIN_USER_CHANGE_PASSWORD_REQUEST_FAILURE:
            return setUserPasswordChange(state, false, action.error);
        case adminActions.ADMIN_USER_REQUEST:
            return setUserIsLoading(state, true);
        case adminActions.ADMIN_USER_REQUEST_SUCCESS:
            return setUserIsLoading(setUserErrors(setUser(state, action.user, action.states, action.genders, action.otherDocuments, action.otherDocumentTypes, action.complianceDocuments, action.complianceDocumentTypes, action.avatar)), false);
        case adminActions.ADMIN_USER_REQUEST_FAILURE:
            return setUserIsLoading(setUser(state, { userId: 0 }, [], [], [], [], [], [], {}), false);
        case adminActions.ADMIN_USER_RESET:
            return setUser(state, { userId: 0 }, [], [], [], [], [], [], {});
        case adminActions.ADMIN_USER_SET:
            return setUserErrors(updateUser(state, action.user));
        case adminActions.ADMIN_OTHER_DOCUMENTS_SAVE:
            return setUserIsSaving(setUserError(state, ""), true);
        case adminActions.ADMIN_OTHER_DOCUMENTS_SAVE_SUCCESS:
            return setUserIsSaving(setOtherDocuments(state, action.userOtherDocuments), false);
        case adminActions.ADMIN_OTHER_DOCUMENTS_SAVE_FAILURE:
            return setUserIsSaving(setUserError(state, action.error), false);
        case adminActions.ADMIN_OTHER_DOCUMENT_REMOVE:
            return setUserIsSaving(setUserError(state, ""), true);
        case adminActions.ADMIN_OTHER_DOCUMENT_REMOVE_SUCCESS:
            return setUserIsSaving(setOtherDocuments(state, action.userOtherDocuments), false);
        case adminActions.ADMIN_OTHER_DOCUMENT_REMOVE_FAILURE:
            return setUserIsSaving(setUserError(state, action.error), false);
        case adminActions.ADMIN_COMPLIANCE_DOCUMENTS_SAVE:
            return setUserIsSaving(setUserError(state, ""), true);
        case adminActions.ADMIN_COMPLIANCE_DOCUMENTS_SAVE_SUCCESS:
            return setUserIsSaving(setComplianceDocuments(state, action.userComplianceDocuments), false);
        case adminActions.ADMIN_COMPLIANCE_DOCUMENTS_SAVE_FAILURE:
            return setUserIsSaving(setUserError(state, action.error), false);
        case adminActions.ADMIN_COMPLIANCE_DOCUMENT_REMOVE:
            return setUserIsSaving(setUserError(state, ""), true);
        case adminActions.ADMIN_COMPLIANCE_DOCUMENT_REMOVE_SUCCESS:
            return setUserIsSaving(setComplianceDocuments(state, action.userComplianceDocuments), false);
        case adminActions.ADMIN_COMPLIANCE_DOCUMENT_REMOVE_FAILURE:
            return setUserIsSaving(setUserError(state, action.error), false);
        case adminActions.ADMIN_USER_SAVE_REQUEST:
            return setUserError(setUserIsSaving(state, true), "");
        case adminActions.ADMIN_USER_SAVE_REQUEST_SUCCESS:
            return setUserError(setUserIsSaving(updateUser(state, action.user), false), null);
        case adminActions.ADMIN_USER_SAVE_REQUEST_FAILURE:
            return setUserError(setUserIsSaving(state, false), action.error);
        case adminActions.ADMIN_USER_SLED_CHECK_REQUEST:
            return isCheckingLicense(state, true);
        case adminActions.ADMIN_USER_SLED_CHECK_REQUEST_SUCCESS:
            return isCheckingLicense(setUserErrors(updateUser(state, action.user)), false);
        case adminActions.ADMIN_USER_SLED_CHECK_REQUEST_FAILURE:
            return isCheckingLicense(state, false);
        case adminActions.ADMIN_INDUSTRY_CATEGORIES_REQUEST:
            return setLoadingIndustryCategories(setIndustryCategories(state, [], { page: action.page, pageSize: action.pageSize }, action.filter), true);
        case adminActions.ADMIN_INDUSTRY_CATEGORIES_REQUEST_SUCCESS:
            return setLoadingIndustryCategories(setIndustryCategories(state, action.data, action.paging, action.filter), false);
        case adminActions.ADMIN_INDUSTRY_CATEGORIES_REQUEST_FAILURE:
            return setLoadingIndustryCategories(setIndustryCategories(state, [], { pageSize: 0, page: 1 }, state.industryCategories.filter), false);
        case adminActions.ADMIN_INDUSTRY_CATEGORY_CREATE_REQUEST:
            return setSavingIndustryCategories(state, true, "");
        case adminActions.ADMIN_INDUSTRY_CATEGORY_CREATE_REQUEST_FAILURE:
            return setSavingIndustryCategories(state, false, action.error);
        case adminActions.ADMIN_INDUSTRY_CATEGORY_CREATE_REQUEST_SUCCESS:
            return setSavingIndustryCategories(state, false, "");
        case adminActions.ADMIN_INDUSTRY_CATEGORY_UPDATE_REQUEST:
            return setSavingIndustryCategories(state, true, "");
        case adminActions.ADMIN_INDUSTRY_CATEGORY_UPDATE_REQUEST_FAILURE:
            return setSavingIndustryCategories(state, false, action.error);
        case adminActions.ADMIN_INDUSTRY_CATEGORY_UPDATE_REQUEST_SUCCESS:
            return setSavingIndustryCategories(state, false, "");

        case adminActions.ADMIN_INCIDENT_TYPES_REQUEST:
            return setLoadingIncidentTypes(setIncidentTypes(state, [], { page: action.page, pageSize: action.pageSize }, action.filter), true);
        case adminActions.ADMIN_INCIDENT_TYPES_REQUEST_FAILURE:
            return setLoadingIncidentTypes(setIncidentTypes(state, [], { pageSize: 0, page: 1 }, state.incidentTypes.filter), false);
        case adminActions.ADMIN_INCIDENT_TYPES_REQUEST_SUCCESS:
            return setLoadingIncidentTypes(setIncidentTypes(state, action.data, action.paging, action.filter), false);
        case adminActions.ADMIN_INCIDENT_TYPES_REFRESH:
            return setLoadingIncidentTypes(state, true);
        case adminActions.ADMIN_INCIDENT_TYPE_SET:
            return setIncidentType(state, action.incidentType);
        case adminActions.ADMIN_INCIDENT_TYPE_CREATE_NEW_REQUEST:
            return setLoadingIncidentTypes(state, true);
        case adminActions.ADMIN_INCIDENT_TYPE_CREATE_NEW_REQUEST_SUCCESS:
            return setLoadingIncidentTypes(setIncidentType(state, action.incidentType), false);
        case adminActions.ADMIN_INCIDENT_TYPE_CREATE_NEW_REQUEST_FAILURE:
            return setLoadingIncidentTypes(setIncidentType(state, { incidentTypeId: -1 }), false);
        case adminActions.ADMIN_INCIDENT_TYPE_UPSERT_REQUEST:
            return setSavingIncidentTypes(state, true, "");
        case adminActions.ADMIN_INCIDENT_TYPE_UPSERT_REQUEST_SUCCESS:
            return setSavingIncidentTypes(setIncidentType(state, { incidentTypeId: -1 }), false, "");
        case adminActions.ADMIN_INCIDENT_TYPE_UPSERT_REQUEST_FAILURE:
            return setSavingIncidentTypes(state, false, action.error);

        case adminActions.ADMIN_INCIDENT_FLAG_TYPES_REQUEST:
            return setLoadingIncidentFlagTypes(setIncidentFlagTypes(state, [], { page: action.page, pageSize: action.pageSize }, action.filter), true);
        case adminActions.ADMIN_INCIDENT_FLAG_TYPES_REQUEST_FAILURE:
            return setLoadingIncidentFlagTypes(setIncidentFlagTypes(state, [], { pageSize: 0, page: 1 }, state.incidentFlagTypes.filter), false);
        case adminActions.ADMIN_INCIDENT_FLAG_TYPES_REQUEST_SUCCESS:
            return setLoadingIncidentFlagTypes(setIncidentFlagTypes(state, action.data, action.paging, action.filter), false);
        case adminActions.ADMIN_INCIDENT_FLAG_TYPE_SET:
            return setIncidentFlagType(state, action.incidentFlagType);
        case adminActions.ADMIN_INCIDENT_FLAG_TYPE_CREATE_NEW_REQUEST:
            return setLoadingIncidentFlagTypes(state, true);
        case adminActions.ADMIN_INCIDENT_FLAG_TYPE_CREATE_NEW_REQUEST_SUCCESS:
            return setLoadingIncidentFlagTypes(setIncidentFlagType(state, action.incidentFlagType), false);
        case adminActions.ADMIN_INCIDENT_FLAG_TYPE_CREATE_NEW_REQUEST_FAILURE:
            return setLoadingIncidentFlagTypes(setIncidentFlagType(state, { incidentFlagTypeId: -1 }), false);
        case adminActions.ADMIN_INCIDENT_FLAG_TYPE_UPSERT_REQUEST:
            return setSavingIncidentFlagTypes(state, true, "");
        case adminActions.ADMIN_INCIDENT_FLAG_TYPE_UPSERT_REQUEST_SUCCESS:
            return setSavingIncidentFlagTypes(setIncidentFlagType(state, { incidentFlagTypeId: -1 }), false, "");
        case adminActions.ADMIN_INCIDENT_FLAG_TYPE_UPSERT_REQUEST_FAILURE:
            return setSavingIncidentFlagTypes(state, false, action.error);

        case adminActions.ADMIN_INCIDENT_CATEGORY_TYPES_REQUEST:
            return setLoadingIncidentCategoryTypes(setIncidentCategoryTypes(state, [], { page: action.page, pageSize: action.pageSize }, action.filter, state.incidentCategoryTypes.states), true);
        case adminActions.ADMIN_INCIDENT_CATEGORY_TYPES_REQUEST_FAILURE:
            return setLoadingIncidentCategoryTypes(setIncidentCategoryTypes(state, [], { pageSize: 0, page: 1 }, state.incidentCategoryTypes.filter, state.incidentCategoryTypes.states), false);
        case adminActions.ADMIN_INCIDENT_CATEGORY_TYPES_REQUEST_SUCCESS:
            return setLoadingIncidentCategoryTypes(setIncidentCategoryTypes(state, action.data, action.paging, action.filter, action.states), false);
        case adminActions.ADMIN_INCIDENT_CATEGORY_TYPE_SET:
            return setIncidentCategoryType(state, action.incidentCategoryType);
        case adminActions.ADMIN_INCIDENT_CATEGORY_TYPE_CREATE_NEW_REQUEST:
            return setLoadingIncidentCategoryTypes(state, true);
        case adminActions.ADMIN_INCIDENT_CATEGORY_TYPE_CREATE_NEW_REQUEST_SUCCESS:
            return setLoadingIncidentCategoryTypes(setIncidentCategoryType(state, action.incidentCategoryType), false);
        case adminActions.ADMIN_INCIDENT_CATEGORY_TYPE_CREATE_NEW_REQUEST_FAILURE:
            return setLoadingIncidentCategoryTypes(setIncidentCategoryType(state, { incidentCategoryTypeId: -1 }), false);
        case adminActions.ADMIN_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST:
            return setSavingIncidentCategoryTypes(state, true, "");
        case adminActions.ADMIN_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST_SUCCESS:
            return setSavingIncidentCategoryTypes(setIncidentCategoryType(state, { incidentCategoryTypeId: -1 }), false, "");
        case adminActions.ADMIN_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST_FAILURE:
            return setSavingIncidentCategoryTypes(state, false, action.error);

        case adminActions.ADMIN_VENUE_LOCATIONS_REFRESH:
            return loadingVenueLocations(setVenueLocationId(state, -1), true);
        case adminActions.ADMIN_VENUE_LOCATIONS_REQUEST:
            return setVenueLocations(loadingVenueLocations(setVenueLocationId(state, -1), true), [], action.filter, { pageSize: action.pageSize, page: action.page });
        case adminActions.ADMIN_VENUE_LOCATIONS_REQUEST_SUCCESS:
            return setVenueLocations(loadingVenueLocations(state, false), action.venueLocations, action.filter, action.paging);
        case adminActions.ADMIN_VENUE_LOCATIONS_REQUEST_FAILURE:
            return loadingVenueLocations(state, false);
        case adminActions.ADMIN_VENUE_LOCATION_RESET:
            return setVenueLocationId(state, -1);
        case adminActions.ADMIN_VENUE_LOCATION_REQUEST:
            return setVenueLocation(loadingVenueLocation(setVenueLocationId(state, action.venueLocationId), true), {});
        case adminActions.ADMIN_VENUE_LOCATION_REQUEST_SUCCESS:
            return setVenueLocation(loadingVenueLocation(state, false), action.venueLocation);
        case adminActions.ADMIN_VENUE_LOCATION_REQUEST_FAILURE:
            return loadingVenueLocation(setVenueLocationId(state, -1), false);
        case adminActions.ADMIN_VENUE_LOCATION_CREATE_REQUEST:
            return setVenueLocation(loadingVenueLocation(setVenueLocationId(state, 0), true), {});;
        case adminActions.ADMIN_VENUE_LOCATION_CREATE_REQUEST_SUCCESS:
            return setVenueLocation(loadingVenueLocation(state, false), action.venueLocation);;
        case adminActions.ADMIN_VENUE_LOCATION_CREATE_REQUEST_FAILURE:
            return loadingVenueLocation(setVenueLocationId(state, -1), false);
        case adminActions.ADMIN_VENUE_LOCATION_UPSERT_REQUEST:
            return savingVenueLocation(state, true);
        case adminActions.ADMIN_VENUE_LOCATION_UPSERT_REQUEST_SUCCESS:
            return setVenueLocation(savingVenueLocation(setVenueLocationId(state, -1), false), action.venueLocation);
        case adminActions.ADMIN_VENUE_LOCATION_UPSERT_REQUEST_FAILURE:
            return savingVenueLocation(state, false);
        case adminActions.ADMIN_VENUE_LOCATION_SET:
            return setVenueLocation(state, action.venueLocation);

        case adminActions.ADMIN_VENUE_SUBSCRIPTION_REQUEST:
            return setVenueSubscription(loadingVenueSubscription(state, true, null), {});
        case adminActions.ADMIN_VENUE_SUBSCRIPTION_REQUEST_SUCCESS:
            return setVenueSubscriptionOptions(setVenueSubscription(loadingVenueSubscription(state, false, null), action.venueSubscription), action.industryCategories);
        case adminActions.ADMIN_VENUE_SUBSCRIPTION_REQUEST_FAILURE:
            return loadingVenueSubscription(state, false, action.error);
        case adminActions.ADMIN_VENUE_SUBSCRIPTION_UPDATE:
            return setVenueSubscription(state, action.venueSubscription);
        case adminActions.ADMIN_VENUE_SUBSCRIPTION_SAVE_REQUEST:
            return savingVenueSubscription(state, true, null);
        case adminActions.ADMIN_VENUE_SUBSCRIPTION_SAVE_REQUEST_SUCCESS:
            return setVenueSubscription(savingVenueSubscription(state, false, null), action.venueSubscription);
        case adminActions.ADMIN_VENUE_SUBSCRIPTION_SAVE_REQUEST_FAILURE:
            return savingVenueSubscription(state, false, action.error);

        case adminActions.ADMIN_DOCUMENT_TYPES_REFRESH:
            return loadingDocumentTypes(setDocumentTypeId(state, -1), true);
        case adminActions.ADMIN_DOCUMENT_TYPES_REQUEST:
            return setDocumentTypes(loadingDocumentTypes(setDocumentTypeId(state, -1), true), [], action.filter, { pageSize: action.pageSize, page: action.page });
        case adminActions.ADMIN_DOCUMENT_TYPES_REQUEST_SUCCESS:
            return setDocumentTypes(loadingDocumentTypes(state, false), action.documentTypes, action.filter, action.paging);
        case adminActions.ADMIN_DOCUMENT_TYPES_REQUEST_FAILURE:
            return loadingDocumentTypes(state, false);
        case adminActions.ADMIN_DOCUMENT_TYPE_RESET:
            return setDocumentTypeId(state, -1);
        case adminActions.ADMIN_DOCUMENT_TYPE_REQUEST:
            return setDocumentType(loadingDocumentType(setDocumentTypeId(state, action.documentType), true), {});
        case adminActions.ADMIN_DOCUMENT_TYPE_REQUEST_SUCCESS:
            return setDocumentType(setDocumentTypeOptions(loadingDocumentType(state, false), action.documentCategories, action.entityTypes), action.documentType);
        case adminActions.ADMIN_DOCUMENT_TYPE_REQUEST_FAILURE:
            return loadingDocumentType(setDocumentTypeId(state, -1), false);
        case adminActions.ADMIN_DOCUMENT_TYPE_CREATE_REQUEST:
            return setDocumentType(loadingDocumentType(setDocumentTypeId(state, 0), true), {});;
        case adminActions.ADMIN_DOCUMENT_TYPE_CREATE_REQUEST_SUCCESS:
            return setDocumentType(setDocumentTypeOptions(loadingDocumentType(state, false), action.documentCategories, action.entityTypes), action.documentType);;
        case adminActions.ADMIN_DOCUMENT_TYPE_CREATE_REQUEST_FAILURE:
            return loadingDocumentType(setDocumentTypeId(state, -1), false);
        case adminActions.ADMIN_DOCUMENT_TYPE_UPSERT_REQUEST:
            return savingDocumentType(state, true);
        case adminActions.ADMIN_DOCUMENT_TYPE_UPSERT_REQUEST_SUCCESS:
            return setDocumentType(savingDocumentType(setDocumentTypeId(state, -1), false), action.documentType);
        case adminActions.ADMIN_DOCUMENT_TYPE_UPSERT_REQUEST_FAILURE:
            return savingDocumentType(state, false);
        case adminActions.ADMIN_DOCUMENT_TYPE_SET:
            return setDocumentType(state, action.documentType);

        case adminActions.ADMIN_SECURITY_FIRMS_REQUEST:
            return setLoadingSecurityFirms(setSecurityFirmsPage(state, { page: action.page, pageSize: action.pageSize }), true);
        case adminActions.ADMIN_SECURITY_FIRMS_REFRESH_REQUEST:
            return setLoadingSecurityFirms(state, true);
        case adminActions.ADMIN_SECURITY_FIRMS_REQUEST_SUCCESS:
            return setLoadingSecurityFirms(setSecurityFirms(state, action.securityFirms, action.paging, action.filter), false);
        case adminActions.ADMIN_SECURITY_FIRMS_REQUEST_FAILURE:
            return setLoadingSecurityFirms(setSecurityFirms(state, [], { pageSize: 0, page: 1 }, action.filter), false);
        case adminActions.ADMIN_SECURITY_FIRMS_FILTER_SET:
            return setSecurityFirmsFilter(state, action.filter);

        case adminActions.ADMIN_SECURITY_FIRM_SUBSCRIPTION_REQUEST:
            return setSecurityFirmSubscription(loadingSecurityFirmSubscription(state, true, null), {});
        case adminActions.ADMIN_SECURITY_FIRM_SUBSCRIPTION_REQUEST_SUCCESS:
            return setSecurityFirmSubscription(loadingSecurityFirmSubscription(state, false, null), action.securityFirmSubscription);
        case adminActions.ADMIN_SECURITY_FIRM_SUBSCRIPTION_REQUEST_FAILURE:
            return loadingSecurityFirmSubscription(state, false, action.error);
        case adminActions.ADMIN_SECURITY_FIRM_SUBSCRIPTION_UPDATE:
            return setSecurityFirmSubscription(state, action.securityFirmSubscription);
        case adminActions.ADMIN_SECURITY_FIRM_SUBSCRIPTION_SAVE_REQUEST:
            return savingSecurityFirmSubscription(state, true, null);
        case adminActions.ADMIN_SECURITY_FIRM_SUBSCRIPTION_SAVE_REQUEST_SUCCESS:
            return setSecurityFirmSubscription(savingSecurityFirmSubscription(state, false, null), action.securityFirmSubscription);
        case adminActions.ADMIN_SECURITY_FIRM_SUBSCRIPTION_SAVE_REQUEST_FAILURE:
            return savingSecurityFirmSubscription(state, false, action.error);

        case adminActions.ADMIN_SECURITY_FIRM_DELETE_REQUEST:
            return setLoadingSecurityFirms(state, true);
        case adminActions.ADMIN_SECURITY_FIRM_DELETE_REQUEST_SUCCESS:
            return setLoadingSecurityFirms(state, false);
        case adminActions.ADMIN_SECURITY_FIRM_DELETE_REQUEST_FAILURE:
            return setLoadingSecurityFirms(state, false);
        case adminActions.ADMIN_SECURITY_FIRM_OBSOLETE_REQUEST:
            return setLoadingSecurityFirms(state, true);
        case adminActions.ADMIN_SECURITY_FIRM_OBSOLETE_REQUEST_SUCCESS:
            return setLoadingSecurityFirms(state, false);
        case adminActions.ADMIN_SECURITY_FIRM_OBSOLETE_REQUEST_FAILURE:
            return setLoadingSecurityFirms(state, false);

        case adminActions.ADMIN_FACIAL_RECOGNITION_NOTIFICATION_FILTER_REQUEST:
            return loadingFacialRecognitionNotifications(state, true);
        case adminActions.ADMIN_FACIAL_RECOGNITION_NOTIFICATION_FILTER_REQUEST_FAILURE:
            return loadingFacialRecognitionNotifications(state, false);
        case adminActions.ADMIN_FACIAL_RECOGNITION_NOTIFICATION_FILTER_REQUEST_SUCCESS:
            return loadingFacialRecognitionNotifications(setFacialRecognitionNotificationFilter(state, action.filter), false);
        case adminActions.ADMIN_FACIAL_RECOGNITION_NOTIFICATION_SET_FILTER:
            return setFacialRecognitionNotificationFilter(state, action.filter);
        case adminActions.ADMIN_FACIAL_RECOGNITION_NOTIFICATION_SET_PAGING:
            return setFacialRecognitionNotificationPaging(state, action.paging);
        case adminActions.ADMIN_FACIAL_RECOGNITION_NOTIFICATION_SET_ITEM:
            return setFacialRecognitionNotification(state, action.facialRecognitionNotification);
        case adminActions.ADMIN_FACIAL_RECOGNITION_NOTIFICATION_REQUEST:
            return loadingFacialRecognitionNotifications(state, true);
        case adminActions.ADMIN_FACIAL_RECOGNITION_NOTIFICATION_REQUEST_FAILURE:
            return loadingFacialRecognitionNotifications(state, false);
        case adminActions.ADMIN_FACIAL_RECOGNITION_NOTIFICATION_REQUEST_SUCCESS:
            return loadingFacialRecognitionNotifications(
                setFacialRecognitionNotifications(
                    setFacialRecognitionNotificationPaging(
                        setFacialRecognitionNotificationFilter(state, action.filter)
                        , action.paging),
                    action.facialRecognitionNotifications)
                , false);
        case adminActions.ADMIN_FACIAL_RECOGNITION_NOTIFICATION_GET_REQUEST:
            return setFacialRecognitionNotification(setFacialRecognitionNotificationIsLoading(state, true), {facialRecognitionNotificationId: 0});
        case adminActions.ADMIN_FACIAL_RECOGNITION_NOTIFICATION_GET_REQUEST_SUCCESS:
            return setFacialRecognitionNotification(setFacialRecognitionNotificationIsLoading(state, false), action.facialRecognitionNotification);
        case adminActions.ADMIN_FACIAL_RECOGNITION_NOTIFICATION_GET_REQUEST_FAILURE:
            return setFacialRecognitionNotification(setFacialRecognitionNotificationIsLoading(state, false), {facialRecognitionNotificationId: -1});

        case adminActions.ADMIN_SECURITY_FIRM_REGISTRATION_DISMISS_REQUEST:
            return setLoadingSecurityFirms(state, true);
        case adminActions.ADMIN_SECURITY_FIRM_REGISTRATION_DISMISS_REQUEST_SUCCESS:
            return setLoadingSecurityFirms(state, false);
        case adminActions.ADMIN_SECURITY_FIRM_REGISTRATION_DISMISS_REQUEST_FAILURE:
            return setLoadingSecurityFirms(state, false);

    }
    return state;
}