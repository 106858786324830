import React, { Component } from 'react';
import { Incident as IncidentDTO, IncidentOptions as IncidentOptionsDTO } from "../../../common/dto/AusComply.dtos";
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import SwipeContainer from '../../layout/SwipeContainer';
import DefaultButton from '../../controls/DefaultButton';
import Information from '../../common/Information';
import FullPageDialog from '../../layout/FullPageDialog';
import IncidentFindContainer from '../../../containers/IncidentFindContainer';
import IncidentHeader from '../IncidentHeader';
import IncidentRow from '../IncidentRow';
import TableContainer from '../../layout/TableContainer';
import IncidentCard from '../IncidentCard';
import PagedList from '../../common/PagedList';
import IncidentDisplayContainer from '../../../containers/IncidentDisplayContainer';

export interface IIncidentWizardRelatedIncidentsProps {
    incident: IncidentDTO;
    incidentOptions: IncidentOptionsDTO;
    onUpdateIncident: Function;
    onSwipedRight?: Function;
    onSwipedLeft?: Function;
    canViewUniqueIncidentID: boolean;
}

interface IIncidentWizardRelatedIncidentsState {
    findRelatedIncident: boolean;
    viewIncidentId: number;
}

export default class IncidentWizardRelatedIncidents extends Component<IIncidentWizardRelatedIncidentsProps, IIncidentWizardRelatedIncidentsState> {
    constructor(props: IIncidentWizardRelatedIncidentsProps) {
        super(props)
        this.state = {
            findRelatedIncident: false,
            viewIncidentId: 0
        }
        this.showRelatedIncident = this.showRelatedIncident.bind(this);
        this.onSelected = this.onSelected.bind(this);
        this.hideRelatedIncident = this.hideRelatedIncident.bind(this);
        this.onRemove = this.onRemove.bind(this);
    }
    componentDidMount() {
    }

    findRelatedIncident(value: boolean) {
        this.setState({ findRelatedIncident: value });
    }

    showRelatedIncident(incident: AusComplyDtos.Incident) {
        this.setState({ viewIncidentId: incident.incidentId });
    }

    hideRelatedIncident() {
        this.setState({ viewIncidentId: 0 });
    }

    onSelected(incident: AusComplyDtos.Incident) {
        if (this.props.incident.linkedIncidents.filter(item => item.incidentId == incident.incidentId).length == 0) {
            let newLinkedIncidents = [...this.props.incident.linkedIncidents, incident];
            let newIncident = { ...this.props.incident };
            newIncident.linkedIncidents = newLinkedIncidents;
            this.props.onUpdateIncident(newIncident);
        }
        this.findRelatedIncident(false);
    }

    onRemove(incidentId: number) {
        let newLinkedIncidents = this.props.incident.linkedIncidents.filter(item => item.incidentId != incidentId);
        let newIncident = { ...this.props.incident };
        newIncident.linkedIncidents = newLinkedIncidents;
        this.props.onUpdateIncident(newIncident);
    }

    render() {

        let linkedIncidents;
        if (!this.props.incident.linkedIncidents || this.props.incident.linkedIncidents.length === 0) {
            linkedIncidents = (
                <>
                    <Grid container spacing={1} style={{ marginBottom: '10px' }}>
                        <Grid item xs={12}>
                            <DefaultButton onClick={() => this.findRelatedIncident(true)}>Add Related Incident</DefaultButton>
                        </Grid>
                    </Grid>
                    <Information message={"No related incidents"}></Information>
                </>
            );
        } else if (this.props.incident.linkedIncidents && this.props.incident.linkedIncidents.length > 0) {
            let cardList = this.props.incident.linkedIncidents.map((item, index) => {
                return (
                    <IncidentCard key={"incident-find-card-" + item.incidentId}
                        incident={item}
                        canApprove={false}
                        canEdit={false}
                        canView={true}
                        showEvents={false}
                        onRemove={() => this.onRemove(item.incidentId)}
                        onView={() => this.showRelatedIncident(item)}
                        onEdit={() => this.showRelatedIncident(item)}
                        canViewUniqueIncidentID={this.props.canViewUniqueIncidentID}
                    />
                );
            });

            let rows = this.props.incident.linkedIncidents.map((item, index) => {
                return (
                    <IncidentRow
                        key={"incident-find-row-" + item.incidentId.toString()}
                        incident={item}
                        canApprove={false}
                        canEdit={false}
                        canView={true}
                        showEvents={false}
                        canViewUniqueIncidentID={this.props.canViewUniqueIncidentID}
                        onRemove={() => this.onRemove(item.incidentId)}
                        onView={() => this.showRelatedIncident(item)}
                        onEdit={() => this.showRelatedIncident(item)} />
                );
            });

            let rowList = (
                <TableContainer
                    header={<IncidentHeader canApprove={false} canEdit={false} canView={true}
                    canViewUniqueIncidentID={this.props.canViewUniqueIncidentID}
                    showEvents={false} canRemove={true} />}
                    rows={rows}
                />
            );

            let paging = new AusComplyDtos.Paging();
            paging.page = 1;
            paging.pages = 1;
            paging.pageSize = this.props.incident.linkedIncidents.length;
            paging.total = this.props.incident.linkedIncidents.length;
            linkedIncidents = (
                <>
                    <Grid container spacing={1} style={{ marginBottom: '10px' }}>
                        <Grid item xs={12}>
                            <DefaultButton onClick={() => this.findRelatedIncident(true)}>Add Related Incident</DefaultButton>
                        </Grid>
                    </Grid>
                    <PagedList
                        paging={paging}
                        rows={rowList}
                        cards={cardList}
                    />

                </>
            );
        }

        return (
            <>
                <FullPageDialog 
                    open={this.state.findRelatedIncident} title={"Find Related Incident"}
                    hideCloseButton={false}
                    onDismissed={() => this.findRelatedIncident(false)}
                    style={{ paddingTop: '0', paddingBottom: '80px' }}>
                    {this.state.findRelatedIncident && (
                        <IncidentFindContainer onSelected={this.onSelected} excludeIncidentId={this.props.incident.incidentId} />
                    )}
                </FullPageDialog>
                <FullPageDialog open={this.state.viewIncidentId !== 0} title={"Related Incident"}
                    hideCloseButton={false}
                    onDismissed={() => this.hideRelatedIncident()}
                    style={{ paddingTop: '0', paddingBottom: '80px' }}>
                    {this.state.viewIncidentId !== 0 && (
                        <IncidentDisplayContainer incidentId={this.state.viewIncidentId} />
                    )}
                </FullPageDialog>

                {(!this.state.findRelatedIncident && this.state.viewIncidentId == 0) && (
                    <SwipeContainer onSwipedLeft={this.props.onSwipedLeft} onSwipedRight={this.props.onSwipedRight}>
                        <CardContainer
                            title={"Related Incidents"}>
                            <CardContent>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        {linkedIncidents}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </CardContainer>
                    </SwipeContainer>
                )}
            </>
        );
    }
}