import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";

export const venueReportingPeriodServiceApi = {
  venueCurrentReportingPeriodCheckDate: (venueId: number, incidentDate: AusComplyDtos.IncidentDate, venueEventId: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostVenueReportingPeriodCheckDate();
    query.venueId = venueId;
    query.incidentDate = incidentDate;
    query.venueEventId = venueEventId;
    const request = client.post(query);
    return from(request);  
  },
  find: (venueId, securityFirmId, forVenueId, page, pageSize) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostVenueReportingPeriod();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.forVenueId = forVenueId;
    query.page = page;
    query.pageSize = pageSize;
    const request = client.post(query);
    return from(request);
  },
  get: (venueId, securityFirmId, venueReportingPeriodId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetVenueReportingPeriod();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.venueReportingPeriodId = venueReportingPeriodId;
    const request = client.get(query);
    return from(request);
  },
  create: (venueId, securityFirmId, forVenueId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostVenueReportingPeriodCreate();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.forVenueId = forVenueId;
    const request = client.post(query);
    return from(request);
  },
  upsert: (venueId, securityFirmId, venueReportingPeriod) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostVenueReportingPeriodUpsert();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.venueReportingPeriod = venueReportingPeriod;
    const request = client.post(query);
    return from(request);
  },
  current: (venueId, securityFirmId, forVenueId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetVenueReportingPeriodCurrentDetails();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.forVenueId = forVenueId;
    const request = client.post(query);
    return from(request);
  },
  close: (venueId, securityFirmId, venueReportingPeriodId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostVenueReportingPeriodClose();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.venueReportingPeriodId = venueReportingPeriodId;
    const request = client.post(query);
    return from(request);
  }
};
