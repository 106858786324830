
import * as AusComplyDtos from "../dto/AusComply.dtos";

export const userLogic = {
    validateUser(user: AusComplyDtos.User) {
        var errors = {};
        var count = 0;

        if (user && user.userId > 0) {

            if (user.isSecurityStaff) {
                if (!user.stateLicensedId || user.stateLicensedId == 0) {
                    errors["stateLicensedId"] = "Required";
                }
                if (!user.licenceNumber || user.licenceNumber == "") {
                    errors["licenceNumber"] = "Required";
                    count++;
                }
            }
            if (user.isVenueStaff) {
                if (!user.rsaNumber || user.rsaNumber == "") {
                    errors["rsaNumber"] = "Required";
                    count++;
                }
            }


            if ((!user.surname || user.surname === "") && (!user.givenName || user.givenName === "")) {
                errors["surname"] = "First or Last name is required";
                errors["givenName"] = "First or Last name is required";
                count++;
            }

            if (!user.genderId || user.genderId == 0) {
                errors["genderId"] = "Required";
                count++;
            }

            /*if (!user.dateOfBirth || user.dateOfBirth.isEmpty) {
                errors["dateOfBirth"] = "Required";
                count++;
            }*/

            if (!user.email || user.email === "") {
                errors["email"] = "Required";
                count++;
            }

            if (!user.mobileNo || user.mobileNo === "" || user.mobileNo.replace(/ /g, "").replace("(", "").replace(")", "").length !== 10) {
                errors["mobileNo"] = "Required to be 10 numbers";
                count++;
            }

            if (!user.address || user.address === "") {
                errors["address"] = "Required";
                count++;
            }

            if (!user.suburb || user.suburb === "") {
                errors["suburb"] = "Required";
                count++;
            }

            if (!user.stateId || user.stateId == 0) {
                errors["stateId"] = "Required";
                count++;
            }

            if (!user.postcode || user.postcode === "" || user.postcode.length !== 4) {
                errors["postcode"] = "Required to be 4 numbers";
                count++;
            }

            if (!user.emergencyContactName || user.emergencyContactName === "") {
                errors["emergencyContactName"] = "Required";
                count++;
            }

            if (!user.emergencyContactNumber || user.emergencyContactNumber === "" || user.emergencyContactNumber.replace(/ /g, "").length !== 10) {
                errors["emergencyContactNumber"] = "Required to be 10 numbers";
                count++;
            }
        }

        errors["count"] = count;
        return errors;
    },
    validateRegisterUser(
        user: AusComplyDtos.User,
        venueIds: number[],
        securityFirmIds: number[],
        isLicensedVenue: boolean) {
        let errors:any[] = []

        if (!user.registerUserTo) {
            errors.push({ field: "registerUserTo", error: "Required", page: "REGISTER_TO", submitOk: false });
        }

        if (user.registerUserTo === "Venue") {
            if (!venueIds || venueIds.length === 0 ) {
                errors.push({ field: "", error: "At least one venue is required", page: "REGISTER_TO", submitOk: false });
            }
        }
        if (user.registerUserTo === "Security") {
            if (!securityFirmIds || securityFirmIds.length === 0 ) {
                errors.push({ field: "", error: "At least one security firm is required", page: "REGISTER_TO", submitOk: false });
            }
        }

        if (user.registerUserAs === undefined) {
            errors.push({ field: "registerUserAs", error: "Required", page: "REGISTER_AS", submitOk: false });
        }

        if (user.sledEnabled && user.registerUserTo === "Security" && user.registerUserAs === AusComplyDtos.ngtRegisterUserAs.Staff) {
            if (!user.stateLicensedId || user.stateLicensedId == 0) {
                errors.push({ field: "stateLicensedId", error: "Required", page: "REGISTER_AS", submitOk: false });
            }

            if (user.stateLicensedId == 2 && !user.sledValid) {
                errors.push({ field: "licenceNumber", error: "Valid license required", page: "REGISTER_AS", submitOk: false });
            }
        }

        if ((!user.surname || user.surname === "") && (!user.givenName || user.givenName === "")) {
            errors.push({ field: "surname", error: "First or Last name is required", page: "REGISTER_DETAILS", submitOk: false });
            errors.push({ field: "givenName", error: "First or Last name is required", page: "REGISTER_DETAILS", submitOk: false });
        }

        if (!user.genderId || user.genderId == 0) {
            errors.push({ field: "genderId", error: "Required", page: "REGISTER_DETAILS", submitOk: false });
        }

        /*if (!user.dateOfBirth || user.dateOfBirth.isEmpty) {
            errors.push({ field: "dateOfBirth", error: "Required", page: "REGISTER_DETAILS", submitOk: false });
        }*/

        if (!user.email || user.email === "") {
            errors.push({ field: "email", error: "Required", page: "REGISTER_DETAILS", submitOk: false });
        }

        if (!user.mobileNo || user.mobileNo === "" || user.mobileNo.length !== 10) {
            errors.push({ field: "mobileNo", error: "Required to be 10 numbers", page: "REGISTER_DETAILS", submitOk: false });
        }

        if (!user.address || user.address === "") {
            errors.push({ field: "address", error: "Required", page: "REGISTER_ADDRESS", submitOk: false });
        }

        if (!user.suburb || user.suburb === "") {
            errors.push({ field: "suburb", error: "Required", page: "REGISTER_ADDRESS", submitOk: false });
        }

        if (!user.stateId || user.stateId == 0) {
            errors.push({ field: "stateId", error: "Required", page: "REGISTER_ADDRESS", submitOk: false });
        }

        if (!user.postcode || user.postcode === "" || user.postcode.length !== 4) {
            errors.push({ field: "postcode", error: "Required to be 4 numbers", page: "REGISTER_ADDRESS", submitOk: false });
        }

        if (!user.emergencyContactName || user.emergencyContactName === "") {
            errors.push({ field: "emergencyContactName", error: "Required", page: "REGISTER_ADDRESS", submitOk: false });
        }

        if (!user.emergencyContactNumber || user.emergencyContactNumber === "" || user.emergencyContactNumber.length !== 10) {
            errors.push({ field: "emergencyContactNumber", error: "Required to be 10 numbers", page: "REGISTER_ADDRESS", submitOk: false });
        }
        if (isLicensedVenue &&
            user.registerUserTo == AusComplyDtos.ngtRegisterUserTo.Venue && (
                user.registerUserAs == AusComplyDtos.ngtRegisterUserAs.Staff ||
                user.registerUserAs == AusComplyDtos.ngtRegisterUserAs.RSAMarshal)) {
            if (!user.rsaNumber || user.rsaNumber == "") {
                errors.push({ field: "rsaNumber", error: "Required", page: "", submitOk: false });
            }
        }

        if (user.registerUserTo == AusComplyDtos.ngtRegisterUserTo.Security && (
            user.registerUserAs == AusComplyDtos.ngtRegisterUserAs.RSAMarshal)) {
            if (!user.rsaNumber || user.rsaNumber == "") {
                errors.push({ field: "rsaNumber", error: "Required", page: "", submitOk: false });
            }
        }

        if (user.registerUserTo == AusComplyDtos.ngtRegisterUserTo.Security && (
            user.registerUserAs == AusComplyDtos.ngtRegisterUserAs.Staff)) {
            if (!user.licenceNumber || user.licenceNumber == "") {
                errors.push({ field: "licenceNumber", error: "Required", page: "", submitOk: false });
            }

            if (!user.stateLicensedId || user.stateLicensedId == 0) {
                errors.push({ field: "stateLicensedId", error: "Required", page: "", submitOk: false });
            }
        }

        if (!user.newPassword || user.newPassword === "") {
            errors.push({ field: "newPassword", error: "Required", page: "REGISTER_PASSWORD", submitOk: false });
        }

        if (!user.newPasswordConfirm || user.newPasswordConfirm === "") {
            errors.push({ field: "newPasswordConfirm", error: "Required", page: "REGISTER_PASSWORD", submitOk: false });
        } else if (user.newPasswordConfirm !== user.newPassword) {
            errors.push({ field: "newPasswordConfirm", error: "Does not match password", page: "REGISTER_PASSWORD", submitOk: false });
        }

        if (!user.hasAcceptedTerms) {
            errors.push({ field: "", error: "Please agree to terms and conditions", page: "REGISTER_PASSWORD", submitOk: false });
        }

        return errors;
    },
    isLicensedVenueSelected(selectedVenueIds: number[], venues: AusComplyDtos.VenueSelection[]) {
        let isLicensedVenue = false;
        if (venues) {
            venues.forEach(v => {
                if (v.industryCategoryID == 1 && selectedVenueIds.indexOf(v.venueId) != -1) {
                    isLicensedVenue = true;
                }
            });
        }

        return isLicensedVenue;
    }
}