import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../dto/AusComply.dtos";
import * as request from './common';

export const facialRecognitionWatchlistServiceApi = {
  find: (venueId, securityFirmId, forVenueId, forGroupId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetFacialRecognitionWatchlists();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.forVenueId = forVenueId;
    query.forGroupId = forGroupId;
    const request = client.get(query);
    return from(request);
  },
  sync: (venueId: number, securityFirmId: number, forVenueId: number, forGroupId: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostFacialRecognitionSyncWatchlists();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.forVenueId = forVenueId;
    query.forGroupId = forGroupId;
    const request = client.post(query);
    return from(request);
  },
  create: (venueId, securityFirmId, forVenueId: number, forGroupId: number) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostFacialRecognitionWatchlistNew();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.forVenueId = forVenueId;
    query.forGroupId = forGroupId;
    const request = client.post(query);
    return from(request);
  },
  upsert: (venueId, securityFirmId, facialRecognitionWatchlist) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.PostFacialRecognitionWatchlist();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.facialRecognitionWatchlist = facialRecognitionWatchlist;
    const request = client.post(query);
    return from(request);
  },
  get: (venueId, securityFirmId, facialRecognitionWatchlistId) => {
    var client = new AusComplyJsonServiceClient();
    var query = new AusComplyDtos.GetFacialRecognitionWatchlist();
    query.venueId = venueId;
    query.securityFirmId = securityFirmId;
    query.facialRecognitionWatchlistId = facialRecognitionWatchlistId;
    const request = client.get(query);
    return from(request);
  },
  pois: request.makePost(
    AusComplyDtos.PostFacialRecognitionWatchlistPois,
    'venueId', 'securityFirmId', 'page', 'pageSize', 'filter'
  ),
  poiRemove: request.makePost(
    AusComplyDtos.PostFacialRecognitionWatchlistPoisRemove,
    'venueId', 'securityFirmId', 'facialRecognitionWatchlistId', 'poiGuid'
  ),
  poiSave: request.makePost(
    AusComplyDtos.PostFacialRecognitionWatchlistPoi,
    'facialRecognitionWatchlistPoi', 'page', 'pageSize', 'filter'
  ),
  newImport: request.makePost(
    AusComplyDtos.PostFacialRecognitionWatchlistNewImport,
    'venueId', 'securityFirmId', 'forVenueId', 'forGroupId'
  ),
  import: request.makePost(
    AusComplyDtos.PostFacialRecognitionWatchlistImport,
    'venueId', 'securityFirmId', 'facialRecognitionWatchlistImports'
  ),
  newMigration: request.makePost(
    AusComplyDtos.PostFacialRecognitionWatchlistNewMigration,
    'venueId', 'securityFirmId', 'forVenueId', 'facialRecognitionWatchlistId'
  ),
  migration: request.makePost(
    AusComplyDtos.PostFacialRecognitionWatchlistMigration,
    'venueId', 'securityFirmId', 'facialRecognitionWatchlistMigration'
  ),
  processMigrations: request.makePost(
    AusComplyDtos.PostFacialRecognitionWatchlistProcessMigration,
    'venueId', 'securityFirmId'
  ),
  cancelMigration: request.makePost(
    AusComplyDtos.PostFacialRecognitionWatchlistCancelMigration,
    'venueId', 'securityFirmId', 'facialRecognitionWatchlistMigrationId'
  ),
  newMove: request.makePost(
    AusComplyDtos.PostFacialRecognitionWatchlistNewMove,
    'venueId', 'securityFirmId', 'forVenueId', 'facialRecognitionWatchlistId'
  ),
  move: request.makePost(
    AusComplyDtos.PostFacialRecognitionWatchlistMove,
    'venueId', 'securityFirmId', 'facialRecognitionWatchlistMove'
  ),
};
