import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import Thumbnail from '../../common/Thumbnail';
import Box from '@material-ui/core/Box';
import ImagePreview from '../../common/ImagePreview';
import FileUpload from '../../common/FileUpload';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface IChecklistTemplateAttachmentFileProps {
    file?: AusComplyDtos.File;
    onUpdate: Function;
    theme: any;
}

interface ICheckListItemAttachmentFileState {
    selectedFilePath: string;
    selectedFileName: string;
}

class ChecklistTemplateAttachmentFile extends Component<IChecklistTemplateAttachmentFileProps, ICheckListItemAttachmentFileState> {
    constructor(props: IChecklistTemplateAttachmentFileProps) {
        super(props)
        this.state = {
            selectedFilePath: "",
            selectedFileName: ""
        }
        this.onSelectFilePath = this.onSelectFilePath.bind(this);
        this.onDownload = this.onDownload.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.fileWasUploaded = this.fileWasUploaded.bind(this);
    }

    onSelectFilePath(filePath, text) {
        this.setState({
            selectedFilePath: filePath,
            selectedFileName: text
        });
    }

    onDownload(filePath) {
        // fake server request, getting the file url as response
        setTimeout(() => {
            const response = {
                file: filePath,
            };
            // server sent the url to the file!
            // now, let's download:
            window.open(response.file);
            // you could also do:
            // window.location.href = response.file;
        }, 100);
    }

    onRemove() {
        if (this.props.file) {
            let file = { ...this.props.file };
            file.obsolete = true;
            this.props.onUpdate(file);
        }
    }

    fileWasUploaded(temporaryFiles: AusComplyDtos.File[]) {
        // console .log('file was updated');
        if (temporaryFiles.length > 0) {
            let file = temporaryFiles[0];
            if (this.props.onUpdate) {
                this.props.onUpdate(file);
            }
        }
    }


    render() {
        const hasFile = (this.props.file && !this.props.file.obsolete && this.props.file.fileDisplayPath && this.props.file.fileDisplayPath.length > 0);
        let attachments;
        if (hasFile && this.props.file) {
            let item = this.props.file;
            attachments = (
                <Box p={0} key={"file-box-" + item.fileName}>
                    <Thumbnail
                        key={"file-" + item.fileName}
                        previewPath={item.filePreviewPath}
                        displayPath={item.fileDisplayPath}
                        alwaysShowText={true}
                        isImage={item.isImage}
                        text={item.filePreviewText}
                        fullText={item.fileName}
                        onPreview={(path, text) => this.onSelectFilePath(path, text)}
                        onRemove={() => this.onRemove()}
                        onDownload={path => this.onDownload(path)}
                    />
                </Box>);
        }

        let fileUpload;
        if (!hasFile) {
            fileUpload = (
                <FileUpload
                    onFileWasUploaded={this.fileWasUploaded}
                    alternateIcons={false}
                    imageIcon={false}
                    entityType={AusComplyDtos.ngtEntityType.Attachment}
                    altText={"Upload Image or Pdf"}
                    hasFiles={false}
                    justifyContent="center" />
            );
        }
        return (
            <div >
                <Box display="flex" flexDirection="row" justifyContent="center" flexWrap="wrap">
                    {attachments}
                </Box>
                {fileUpload}
                <ImagePreview filePath={this.state.selectedFilePath} fileName={this.state.selectedFileName} onDismissed={() => this.onSelectFilePath("", "")} />
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(ChecklistTemplateAttachmentFile);