import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import Loading from '../../common/Loading';
import ControlGroup from '../../controls/ControlGroup';
import TransparentButton from '../../controls/TransparentButton';
import Box from '@material-ui/core/Box';
import POI from '../../facialRecognition/facialRecognitionPOI/POI';

export interface IIncidentViewPOIsProps {
    poiGuids: string[];
    isLoading: boolean;
    pois: AusComplyDtos.POIIncidents[];
    onLoad: Function;
    classes: any;
    theme: any;
}

interface IIncidentViewPOIsState {
}

class IncidentViewPOIs extends Component<IIncidentViewPOIsProps, IIncidentViewPOIsState> {
    constructor(props: IIncidentViewPOIsProps) {
        super(props)

        this.state = {
        };
    }

    componentDidMount() {
        if (!this.props.isLoading && this.props.poiGuids) {
            this.props.onLoad(this.props.poiGuids);
        }
    }

    componentDidUpdate(prevProps) {
        
        if (!this.props.isLoading && (JSON.stringify(prevProps.poiGuids || []) != JSON.stringify(this.props.poiGuids || []))) {
            this.props.onLoad(this.props.poiGuids);
        }
    }

    render() {
        if (!this.props.poiGuids || this.props.poiGuids.length == 0) {
            return (<></>);
        }

        return (
            <>
                <ControlGroup text={"POIs"}>
                    <Box display="flex" flexDirection="row" justifyContent="left" flexWrap="wrap">
                        {this.props.isLoading && (
                            <Loading />
                        )}
                        {(!this.props.isLoading && this.props.pois && this.props.pois.length == 0) && (
                            <TransparentButton text={"Retry"} onClick={() => this.props.onLoad(this.props.poiGuids)} />
                        )}
                        {(!this.props.isLoading && this.props.pois) && this.props.pois.map((poi, poiIndex) =>
                            <Box p={1} key={"file-box-poi-" + poiIndex}>
                                <POI poiIncidents={poi} />
                            </Box>
                        )}
                    </Box>
                </ControlGroup>
            </>
        );
    }
}

export default withStyles(styles, { withTheme: true })(IncidentViewPOIs);