import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import FullPageDialog from '../../layout/FullPageDialog';
import PagedList from '../../common/PagedList';
import Thumbnail from '../../common/Thumbnail';
import ErrorButton from '../../controls/ErrorButton';
import Box from '@material-ui/core/Box';
import CardRow from '../../common/CardRow';
import CardTypography from '../../common/CardTypography';
import CardTitle from '../../common/CardTitle';
import CardLabel from '../../common/CardLabel';
import ResponsiveList from '../../common/ResponsiveList';
import { KeyName } from '../../../common/dto/common';
import { isEmpty } from 'rxjs-compat/operator/isEmpty';
import FacialRecognitionWatchlistPoisFilter from './FacialRecognitionWatchlistPoisFilter';
import LayoutForm from '../../layout/LayoutForm';
import SearchDateControlGroup from '../../controls/SearchDateControlGroup';
import TimeZoneDateControlGroup from '../../controls/TimeZoneDateControlGroup';
import { Grid } from '@material-ui/core';
import DefaultButton from '../../controls/DefaultButton';
import PrimaryButton from '../../controls/PrimaryButton';
import TextAreaControlGroup from '../../controls/TextAreaControlGroup';
import CardList from '../../common/CardList';
import SubTitle from '../../common/SubTitle';
import Tabs from '../../common/Tabs';
import POIPagedIncidentsContainer from '../../../containers/POIPagedIncidentsContainer';
import FacialRecognitionPOIWatchlistRegistrationContainer from '../../../containers/FacialRecognitionPOIWatchlistRegistrationContainer';
import FacialRecognitionPOIScannedIDContainer from '../../../containers/FacialRecognitionPOIScannedIDContainer';

export interface IFacialRecognitionWatchlistPoisProps {
    isSaving: boolean;
    venueId?: number;
    groupId?: number;
    facialRecognitionWatchlistId: number;
    thumbnails: boolean;
    data: AusComplyDtos.FacialRecognitionWatchlistPoi[];
    facialRecognitionWatchlistPoi: AusComplyDtos.FacialRecognitionWatchlistPoi;
    paging: AusComplyDtos.Paging;
    filter: AusComplyDtos.FacialRecognitionWatchlistPoisFilter;
    isLoading: boolean;
    onSet: Function;
    onSave: Function;
    onLoad: Function;
    onReset: Function;
    onRemove: Function;
    canGenerateAppearance: boolean;
    cameras: AusComplyDtos.FacialRecognitionCamera[];
    onGenerateAppearance: Function;
    onThumbnails: Function;
    onAddToAnotherWatchlist: Function;
}

interface IFacialRecognitionWatchlistPoisState {
    expireDate: AusComplyDtos.SearchDate;
    info?: AusComplyDtos.FacialRecognitionWatchlistPoi;
    selectedTab: number;
}

export default class FacialRecognitionWatchlistPois extends Component<IFacialRecognitionWatchlistPoisProps, IFacialRecognitionWatchlistPoisState> {
    constructor(props: IFacialRecognitionWatchlistPoisProps) {
        super(props)
        this.state = {
            expireDate: new AusComplyDtos.SearchDate(),
            info: undefined,
            selectedTab: 0,
        };
        this.onCommand = this.onCommand.bind(this);
        this.onCancelEdit = this.onCancelEdit.bind(this);
        this.onSavePoi = this.onSavePoi.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
    }

    handleTabChange = (event, value) => {
        this.setState({ selectedTab: value });
    };

    onCommand(command: string, item: AusComplyDtos.FacialRecognitionWatchlistPoi) {
        switch (command) {
            case "edit":
                this.setState({ info: undefined });
                this.props.onSet(item);
                break;
            case "remove":
                this.setState({ info: undefined });
                this.props.onRemove(this.props.filter.facialRecognitionWatchlistId, item.poiGuid)
                break;
            case "add":
                this.setState({ info: undefined });
                this.props.onAddToAnotherWatchlist(this.props.venueId, this.props.groupId, this.props.filter.facialRecognitionWatchlistId, item.poiGuid)
                break;
        }
        if (command.indexOf('generate|') > -1) {
            let cameraId = command.replace('generate|', '');
            this.props.onGenerateAppearance(item.poiGuid, this.props.filter.facialRecognitionWatchlistId, cameraId);
        }
    }

    onCancelEdit() {
        var facialRecognitionWatchlistPoi = new AusComplyDtos.FacialRecognitionWatchlistPoi();
        facialRecognitionWatchlistPoi.poiGuid = "";
        this.props.onSet(facialRecognitionWatchlistPoi);
    }

    onChangeValue(fieldName: string, value: any) {
        // console.log(value);
        var facialRecognitionWatchlistPoi = { ...this.props.facialRecognitionWatchlistPoi };
        facialRecognitionWatchlistPoi[fieldName] = value;
        this.props.onSet(facialRecognitionWatchlistPoi);
    }

    onSavePoi() {
        this.props.onSave(1, this.props.paging.pageSize, this.props.filter);
    }

    render() {
        let self = this;

        let watchlistId = 0;
        let poisCards;
        let poisThumbs;
        let content;
        let tabs;

        var commands: KeyName[] = [];
        commands.push({ key: "edit", name: "Edit" });
        commands.push({ key: "remove", name: "Remove" });
        commands.push({ key: "add", name: "Add to another watchlist" });

        if (this.props.canGenerateAppearance && this.props.cameras && this.props.cameras.length > 0) {
            commands.push({ key: "heading", name: "Test on camera" });
            this.props.cameras.forEach(camera => {
                commands.push({ key: "generate|" + camera.facialRecognitionCameraId, name: camera.displayName });
            });
        }

        if (this.props.facialRecognitionWatchlistId > 0) {
            watchlistId = this.props.facialRecognitionWatchlistId;
        }

        if (this.props.filter.facialRecognitionWatchlistId > 0) {
            watchlistId = this.props.filter.facialRecognitionWatchlistId;

            if (this.props.data) {
                poisCards = this.props.data.map((item, index) => {
                    let pagedIncidents;
                    if (item) {
                        pagedIncidents = <POIPagedIncidentsContainer
                            facialRecognitionPOIIncidentId={0}
                            facialRecognitionWatchlistId={item.facialRecognitionWatchlistId}
                            poiGuid={item.poiGuid}
                            detailed={false}
                            summary={true}
                            forVenueId={0}
                        />
                    }

                    let notes;
                    if (item.poiNotes && item.poiNotes.length > 0) {
                        notes = <>
                            <SubTitle text={"Notes"} />
                            <CardList
                                loading={false}
                                data={item.poiNotes}
                                getCommands={item => []}
                                onCommand={(command, item) => { }}
                                canEdit={false}
                                card={(note: AusComplyDtos.IncidentPOINote) => <>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={6}>
                                            <CardTitle>{note.timeZoneDate.displayShortDate} {note.venue}</CardTitle>
                                            <CardTypography>{note.note}</CardTypography>
                                        </Grid>
                                    </Grid>
                                </>}
                            /></>
                    }

                    let expiryDisplay;
                    if (item.watchlists && item.watchlists.length > 0) {
                        expiryDisplay = (<>
                            {(item.watchlists && item.watchlists.length > 0) && (
                                <>
                                    {(item.watchlists[0].timeZoneDate && !item.watchlists[0].timeZoneDate.isEmpty) && (
                                        <>
                                            <CardLabel style={{ textAlign: 'left', color: '#aaa' }}>Expires</CardLabel>
                                            <CardTypography>{item.watchlists[0].timeZoneDate.displayShortDate}</CardTypography>
                                            <CardTypography style={{ fontSize: '8px', color: '#888' }}>{item.watchlists[0].timeZoneDate.timeZoneDisplay}</CardTypography>
                                        </>
                                    )}
                                </>
                            )}
                        </>);
                    } else if (item.timeZoneDate && !item.timeZoneDate.isEmpty) {
                        expiryDisplay = (<>
                            {(item.timeZoneDate && !item.timeZoneDate.isEmpty) && (
                                <>
                                    <CardLabel style={{ textAlign: 'left', color: '#aaa' }}>Expires</CardLabel>
                                    <CardTypography>{item.timeZoneDate.displayShortDate}</CardTypography>
                                    <CardTypography style={{ fontSize: '8px', color: '#888' }}>{item.timeZoneDate.timeZoneDisplay}</CardTypography>
                                </>)}
                        </>);
                    }

                    return <CardRow
                        key={"poi-" + index}
                        commands={commands}
                        onCommand={(command) => this.onCommand(command, item)}
                        isDanger={item.obsolete}
                    >
                        <Box display="flex" flexDirection="row">
                            <Box p={0} flex={0} >
                                <Thumbnail
                                    key={"poi-image-" + item.poiGuid}
                                    previewPath={""}
                                    displayPath={""}
                                    base64={item.displayImage}
                                    base64Type={"image/jpeg"}
                                    isImage={true}
                                    text={"Detected face"}
                                    fullText={""}
                                    title={""}
                                />
                            </Box>
                            <Box p={0} flex={1} >
                                {this.state.selectedTab == 0 && (<Grid container spacing={0}>
                                    <Grid item md={4} xs={8}>
                                        <CardLabel style={{ textAlign: 'left', color: '#aaa' }}>Name</CardLabel>
                                        <CardTypography>{item.fullName ? item.fullName : " - empty - "}</CardTypography>
                                        {expiryDisplay}
                                    </Grid>
                                    <Grid item md={2} xs={4}>
                                        <CardLabel style={{ textAlign: 'left', color: '#aaa' }}>Scanned ID</CardLabel>
                                        <FacialRecognitionPOIScannedIDContainer poiGuid={item.poiGuid} />
                                    </Grid>
                                    <Grid item md={3} xs={12}>
                                        {item.notes && (
                                            <>
                                                <CardLabel style={{ textAlign: 'left', color: '#aaa' }}>Note</CardLabel>
                                                <CardTypography>{item.notes}</CardTypography>
                                            </>
                                        )}
                                        <CardLabel style={{ textAlign: 'left', color: '#aaa' }}>ID</CardLabel>
                                        <CardTypography>{item.poiGuid}</CardTypography>
                                    </Grid>
                                    <Grid item md={3} xs={12}>
                                        <CardLabel style={{ textAlign: 'left', color: '#aaa' }}>Incidents</CardLabel>
                                        <CardTypography>{item.incidentSummaries.paging.total}</CardTypography>
                                        <CardLabel style={{ textAlign: 'left', color: '#aaa' }}>Notes</CardLabel>
                                        <CardTypography>{item.poiNotes.length}</CardTypography>
                                    </Grid>
                                </Grid>)}
                                {this.state.selectedTab == 1 && (<>
                                    {pagedIncidents}
                                </>)}
                                {this.state.selectedTab == 2 && (<>
                                    {notes}
                                </>)}
                            </Box>
                        </Box >
                    </CardRow >
                });

                poisThumbs = (
                    <Box display="flex" flexDirection="row" justifyContent="left" flexWrap="wrap">
                        {this.props.data.map((item, index) => {
                            let expires = "";
                            if (item.timeZoneDate && !item.timeZoneDate.isEmpty) {
                                expires = item.timeZoneDate.displayShortDate
                            }
                            return (
                                <Box p={0} key={"poi-" + index}>
                                    <Thumbnail
                                        key={"poi-image-" + item.poiGuid}
                                        previewPath={"info"}
                                        displayPath={""}
                                        base64={item.displayImage}
                                        base64Type={"image/jpeg"}
                                        isImage={true}
                                        text={"Detected face"}
                                        isDanger={item.obsolete}
                                        fullText={item.displayName + " " + expires}
                                        title={item.displayName + " " + expires}
                                        onPreview={() => self.setState({ info: item })}
                                    />
                                </Box>
                            )
                        }
                        )}
                    </Box>
                );
            }
        }

        if (this.props.thumbnails) {
            content = <PagedList
                loading={this.props.isLoading}
                paging={this.props.paging}
                cards={poisThumbs}
                rows={poisThumbs}
                onPageSelected={i => {
                    let filter = { ...this.props.filter };
                    filter.facialRecognitionWatchlistId = watchlistId;
                    this.props.onLoad(i, this.props.thumbnails ? 50 : 20, filter);
                }}
            />;
        } else {
            tabs = <div style={{ display: 'display-block', marginTop: '10px' }}>
                <Tabs selected={this.state.selectedTab} labels={["Details", "Incidents", "Notes"]} onClick={index => this.handleTabChange(null, index)}></Tabs>
            </div>;

            content = <PagedList
                loading={this.props.isLoading}
                paging={this.props.paging}
                cards={poisCards}
                rows={poisCards}
                onPageSelected={i => {
                    let filter = { ...this.props.filter };
                    filter.facialRecognitionWatchlistId = watchlistId;
                    this.props.onLoad(i, this.props.thumbnails ? 50 : 20, this.props.filter);
                }}
            />;
        }

        let facialRecognitionWatchlistPoiInfo;
        if (this.state.info !== undefined) {
            let pagedIncidents
            let item = this.state.info;
            if (item) {
                pagedIncidents = <POIPagedIncidentsContainer
                    facialRecognitionPOIIncidentId={0}
                    facialRecognitionWatchlistId={item.facialRecognitionWatchlistId}
                    poiGuid={item.poiGuid}
                    detailed={false}
                    summary={true}
                    forVenueId={0}
                />
            }

            let notes;
            if (item.poiNotes && item.poiNotes.length > 0) {
                notes = <>
                    <SubTitle text={"Notes"} />
                    <CardList
                        loading={false}
                        data={item.poiNotes}
                        getCommands={item => []}
                        onCommand={(command, item) => { }}
                        canEdit={false}
                        card={(note: AusComplyDtos.IncidentPOINote) => <>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6}>
                                    <CardTitle>{note.timeZoneDate.displayShortDate} {note.venue}</CardTitle>
                                    <CardTypography>{note.note}</CardTypography>
                                </Grid>
                            </Grid>
                        </>}
                    /></>
            }

            facialRecognitionWatchlistPoiInfo = <CardRow
                key={"poi-" + item.poiGuid}
                commands={commands}
                onCommand={(command) => this.onCommand(command, item)}
                isDanger={item.obsolete}
            >
                <Box display="flex" flexDirection="row">
                    <Box p={0} flex={0} >
                        <Thumbnail
                            key={"poi-image-" + item.poiGuid}
                            previewPath={""}
                            displayPath={""}
                            base64={item.displayImage}
                            base64Type={"image/jpeg"}
                            isImage={true}
                            text={"Detected face"}
                            fullText={""}
                            title={""}
                        />
                    </Box>
                    <Box p={0} flex={1} >
                        <CardLabel style={{ textAlign: 'left', color: '#aaa' }}>Name</CardLabel>
                        <CardTypography>{item.displayName ? item.displayName : " - empty - "}</CardTypography>
                        {item.timeZoneDate && (
                            <>
                                <CardLabel style={{ textAlign: 'left', color: '#aaa' }}>Expires</CardLabel>
                                <CardTypography>{item.timeZoneDate.displayShortDate}</CardTypography>
                                <CardTypography style={{ fontSize: '8px', color: '#888' }}>{item.timeZoneDate.timeZoneDisplay}</CardTypography>
                            </>)}
                        {item.notes && (
                            <>
                                <CardLabel style={{ textAlign: 'left', color: '#aaa' }}>Notes</CardLabel>
                                <CardTypography>{item.notes}</CardTypography>
                            </>
                        )}
                        <CardLabel style={{ textAlign: 'left', color: '#aaa' }}>ID</CardLabel>
                        <CardTypography>{item.poiGuid}</CardTypography>
                    </Box>
                </Box >
                {pagedIncidents}
                {notes}
            </CardRow >;
        }


        let showItem = false;
        let facialRecognitionWatchlistPoi;
        if (this.props.facialRecognitionWatchlistPoi != null && this.props.facialRecognitionWatchlistPoi.poiGuid !== "") {
            let item = this.props.facialRecognitionWatchlistPoi;
            showItem = true;
            facialRecognitionWatchlistPoi = (
                <LayoutForm saving={this.props.isSaving}>
                    <Box>
                        <TextAreaControlGroup text="Name"
                            defaultValue={this.props.facialRecognitionWatchlistPoi.fullName}
                            onChange={value => this.onChangeValue('fullName', value)}
                            readonly={false}
                            labelAbove={true} />
                    </Box>
                    <br />
                    <Box>
                        <TextAreaControlGroup text="Notes"
                            defaultValue={this.props.facialRecognitionWatchlistPoi.notes}
                            onChange={value => this.onChangeValue('notes', value)}
                            readonly={false}
                            labelAbove={true} />
                    </Box>
                    <br />
                    <TimeZoneDateControlGroup
                        text={"Expiry date"}
                        labelAbove={true}
                        futureOnly={true}
                        onChange={value => this.onChangeValue('timeZoneDate', value)}
                        date={this.props.facialRecognitionWatchlistPoi.timeZoneDate}
                    />
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <PrimaryButton text={"Save"} onClick={this.onSavePoi}></PrimaryButton>
                        </Grid>
                        <Grid item xs={6}>
                            <DefaultButton text={"Cancel"} onClick={this.onCancelEdit}></DefaultButton>
                        </Grid>
                    </Grid>
                </LayoutForm>
            )
        }

        return (
            <>
                <FullPageDialog open={watchlistId > 0 || this.props.isLoading} title="Watchlist POIs" onDismissed={() => self.props.onReset()}>
                    <FacialRecognitionWatchlistPoisFilter
                        filter={this.props.filter}
                        thumbnail={this.props.thumbnails}
                        onThumbnail={(thumbnails) => {
                            self.props.onThumbnails(thumbnails);
                            let filter = { ...this.props.filter };
                            filter.facialRecognitionWatchlistId = self.props.filter.facialRecognitionWatchlistId;
                            self.props.onLoad(1, thumbnails ? 50 : 20, filter);
                        }}
                        onReset={() => {
                            let filter = { ...this.props.filter };
                            filter.facialRecognitionWatchlistId = self.props.filter.facialRecognitionWatchlistId;
                            filter.venueId = self.props.venueId || 0;
                            filter.groupId = self.props.groupId || 0;
                            self.props.onLoad(1, this.props.thumbnails ? 50 : 20, filter);
                        }}
                        onSearch={(filter) => {
                            filter.facialRecognitionWatchlistId = self.props.filter.facialRecognitionWatchlistId;
                            self.props.onLoad(1, this.props.thumbnails ? 50 : 20, filter);
                        }} />
                    {tabs}
                    {content}
                    <FullPageDialog open={self.state.info !== undefined} title="POI" onDismissed={() => self.setState({ info: undefined })}>
                        {facialRecognitionWatchlistPoiInfo}
                    </FullPageDialog>
                    <FullPageDialog open={showItem} title="Watchlist POI" onDismissed={() => this.onCancelEdit()}>
                        {facialRecognitionWatchlistPoi}
                    </FullPageDialog>
                    <FacialRecognitionPOIWatchlistRegistrationContainer />
                </FullPageDialog>
            </>
        );

    }
}