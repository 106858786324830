import React from 'react';
import { Incident } from '../../common/dto/AusComply.dtos';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardTypography from '../common/CardTypography';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import CheckboxControl from '../controls/CheckboxControl';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CrossIcon from '../../resources/CrossIcon';
import Button from '@material-ui/core/Button';
import ContainedParagraph from '../common/ContainedParagraph';


export interface IIncidentCardProps {
    incident: Incident;
    isCurrent?: boolean;
    onSelected?: Function;
    onEdit?: Function;
    onView?: Function;
    onRemove?: Function;
    canApprove: boolean;
    canEdit: boolean;
    canView: boolean;
    showEvents: boolean;
    canViewUniqueIncidentID: boolean;
    classes: any;
    theme: any;
}

class IncidentCard extends React.Component<IIncidentCardProps, any> {

    constructor(props: IIncidentCardProps) {
        super(props)
        this.onSelected = this.onSelected.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onRemove = this.onRemove.bind(this);
    }

    onSelected(value) {
        var incident = { ...this.props.incident };
        incident.selected = value;
        if (this.props.onSelected) {
            this.props.onSelected(incident);
        }
    }

    onClick() {
        if (this.props.canEdit && this.props.onEdit) {
            this.props.onEdit(this.props.incident);
        }
        else if (this.props.canView && this.props.onView) {
            this.props.onView(this.props.incident);
        }
    }

    onRemove() {
        if (this.props.onRemove) {
            this.props.onRemove();
        }
    }

    render() {
        const { classes, theme } = this.props;
        let className = classes.cardIncidentPending;
        if (this.props.isCurrent) {
            className = classes.cardIncidentCurrent;
        } else if (!this.props.incident.completed) {
            className = classes.cardIncidentDraft;
        } else if (this.props.incident.hasRequiredActions) {
            className = classes.rowStatusDanger;
        } else if (this.props.incident.approved) {
            className = classes.cardIncidentApproved;
        }


        return (
            <Card style={{ marginTop: '6px', marginBottom: '0' }} className={className}>
                <CardContent style={{ paddingBottom: '16px' }}>
                    <Grid container spacing={1} onClick={this.onClick}>
                        <Grid item xs={9}>
                            <CardTypography><b>{this.props.incident.incidentSerialNumber}</b></CardTypography>
                        </Grid>
                        <Grid item xs={3} style={{ textAlign: 'right' }} >
                            {(this.props.incident.hasAttachment && !this.props.isCurrent) && (
                                <AttachFileIcon style={{ fontSize: '17px' }} />
                            )}
                            {(this.props.onRemove && !this.props.isCurrent) && (
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    style={{
                                        display: 'inline-flex',
                                        padding: '4px', minWidth: '0',
                                        marginLeft: '10px',
                                        marginTop: '-20px',
                                        marginRight: '-10px',
                                    }}
                                    onClick={e => { e.stopPropagation(); this.onRemove(); }}
                                    className={classes.linearOutlineColorError}
                                ><CrossIcon fill={theme.custom.colors.error} style={{ fontSize: '0.8em' }} /></Button>
                            )}
                        </Grid>
                    </Grid>
                    {!this.props.isCurrent && (
                        <>
                            {this.props.canViewUniqueIncidentID && (
                            <Grid container spacing={1} onClick={this.onClick}>
                                <Grid item xs={12}>
                                    <CardTypography>{this.props.incident.uniqueID}</CardTypography>
                                </Grid>
                            </Grid>
                            )}
                            {this.props.showEvents && (
                                <Grid container spacing={1} onClick={this.onClick}>
                                    <Grid item xs={12}>
                                        <CardTypography>{this.props.incident.venueEvent}</CardTypography>
                                    </Grid>
                                </Grid>)
                            }
                            <Grid container spacing={1} onClick={this.onClick}>
                                <Grid item xs={6}>
                                    <CardTypography><b>{this.props.incident.incidentType}</b></CardTypography>
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: 'right' }}>
                                    <CardTypography>{this.props.incident.incidentDateTimeShortString}</CardTypography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} onClick={this.onClick}>
                                <Grid item xs={12}>
                                    <CardTypography style={{ fontStyle: 'italic' }}>{this.props.incident.locations}</CardTypography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} onClick={this.onClick}>
                                <Grid item xs={12}>
                                    <CardTypography style={{ fontStyle: 'italic' }}>{this.props.incident.categories}</CardTypography>
                                </Grid>
                            </Grid>
                            {this.props.incident.categories != this.props.incident.summary && (
                                <Grid container spacing={1} onClick={this.onClick}>
                                    <Grid item xs={12}>
                                        <ContainedParagraph text={this.props.incident.summary} maxLines={3} />
                                    </Grid>
                                </Grid>
                            )}
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    {(this.props.canApprove && this.props.incident.allowBulkApproval && this.props.incident.completed && !this.props.incident.approved) && (
                                        <CheckboxControl text="Approve" defaultValue={this.props.incident.selected} onChanged={this.onSelected} />
                                    )}
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: 'right' }} onClick={this.onClick}>
                                    <CardTypography>{this.props.incident.lastModifiedByUser}</CardTypography>
                                </Grid>
                            </Grid>
                        </>
                    )}

                </CardContent>

            </Card>
        );
    }
}
export default withStyles(styles, { withTheme: true })(IncidentCard);