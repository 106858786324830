import { connect } from 'react-redux'
import { withRouter } from "react-router";
import POIRegistration from '../components/facialRecognition/facialRecognitionPOI/POIRegistration'
import { permissionsLogic } from '../common/logic/permissionsLogic';
import * as facialRecognitionPOIActions from '../common/actions/facialRecognitionPOI';

const mapStateToProps = (state, props) => {
    return {
        incidentId: 0,
        venueId: props.venueId,
        groupId: props.groupId,
        facialRecognitionWatchlistId: props.facialRecognitionWatchlistId,
        poiGuid: props.poiGuid,
        buttonMode: props.buttonMode,
        isScanning: state.facialRecognitionPOI.poiRegistration.isScanning,
        isSearching: state.facialRecognitionPOI.poiRegistration.isSearching,
        isSaving: state.facialRecognitionPOI.poiRegistration.isSaving,
        isLoading: state.facialRecognitionPOI.poiRegistration.isLoading,
        poiRegistration: state.facialRecognitionPOI.poiRegistration.poiRegistration,
        canCreate: permissionsLogic.hasPermissionForState(state, 'RegisterFacialRecognitionPOI')
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCreate: (incidentId, venueId) => {
            dispatch(facialRecognitionPOIActions.facialRecognitionPoiVenueRegistrationRequest(venueId))
        },
        onCreateGroup: (groupId) => {
            dispatch(facialRecognitionPOIActions.facialRecognitionPoiGroupRegistrationRequest(groupId))
        },
        onUpdate: (poiRegistration) => {
            dispatch(facialRecognitionPOIActions.facialRecognitionPoiRegistrationSet(poiRegistration))
        },
        onScanFaces: () => {
            dispatch(facialRecognitionPOIActions.facialRecognitionPoiRegistrationScanFacesRequest())
        },
        onSearchFace: () => {
            dispatch(facialRecognitionPOIActions.facialRecognitionPoiRegistrationSearchFaceRequest())
        },
        onCancel: () => {
            dispatch(facialRecognitionPOIActions.facialRecognitionPoiRegistrationReset())
        },
        onSave: () => {
            dispatch(facialRecognitionPOIActions.facialRecognitionPoiRegistrationSaveRequest())
        }
    }
}

const FacialRecognitionPOIVenueRegistrationContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(POIRegistration))

export default FacialRecognitionPOIVenueRegistrationContainer