import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import FullPageDialog from '../../layout/FullPageDialog';
import Thumbnail from '../../common/Thumbnail';
import Box from '@material-ui/core/Box';
import Loading from '../../common/Loading';
import PersonIcon from '../../../resources/PersonIcon';
import ResponsiveList from '../../common/ResponsiveList';
import TextDisplayControlGroup from '../../controls/TextDisplayControlGroup';

export interface IPOIPagedIncidentsProps {
    facialRecognitionPOIIncidentId: number;
    poiGuid: string;
    forVenueId: number;
    facialRecognitionWatchlistId: number;
    detailed: boolean;
    summary: boolean;
    incidents: AusComplyDtos.IncidentPOISummary[];
    paging: AusComplyDtos.Paging;
    isLoading: boolean;
    onLoad: Function;
}

interface IPOIPagedIncidentsState {
}

class POIPagedIncidents extends Component<IPOIPagedIncidentsProps, IPOIPagedIncidentsState> {
    constructor(props: IPOIPagedIncidentsProps) {
        super(props)
        this.state = {
        };
    }

    componentDidMount() {
        this.props.onLoad(this.props.facialRecognitionPOIIncidentId, this.props.poiGuid, { page: 1, pageSize: 5 }, this.props.forVenueId, this.props.facialRecognitionWatchlistId);
    }
    render() {
        let self = this;

        let incidents;

        if (this.props.isLoading) {
            incidents = <Loading />;
        } else if (!this.props.isLoading && this.props.incidents) {
            
            let headers = this.props.summary ?
                ["Incident", "Incident Type", "Watchlist", "Expires", "Submitted", "Created", "Created By"]
                : this.props.detailed ? 
                ["Incident Serial Number", "Incident Type", "Watchlist", "Expires", "Submitted", "Created", "Created By", "Note"] 
                : ["Incident Serial Number", "Incident Type", "Watchlist", "Expires", "Created"];
            let columns = this.props.summary ?
                ["incident", "incidentType", "watchlist", "expires", "incidentSubmitted", "incidentDateOnlyDisplay", "createdBy"]
                : this.props.detailed ? 
                ["incidentSerialNumber", "incidentType", "watchlist", "expires", "incidentSubmitted", "incidentDateOnlyDisplay", "createdBy", "notes"] 
                : ["incidentSerialNumber", "incidentType", "watchlist", "expires", "incidentDateDisplay"];

            if (this.props.incidents ) {
                incidents = <ResponsiveList
                    data={this.props.incidents}
                    headers={headers}
                    columns={columns}
                    noRowsMessage={"No incidents found."}
                    getCommands={item => []}
                    paging={this.props.paging}
                    onPageSelected={page => self.props.onLoad(self.props.facialRecognitionPOIIncidentId, self.props.poiGuid, { page: page, pageSize: 5 }, self.props.forVenueId, self.props.facialRecognitionWatchlistId)}
                    onCommand={(command, item) => { }}
                    isDanger={item => item.obsolete}
                    isDangerLabel={"Expired"}
                    canEdit={false}
                    onGetLink={(columnName, rowData) => {
                        if (columnName == "incidentSerialNumber") {
                            return "/incidentview/" + rowData["incidentId"];
                        }
                        if (columnName == "incident" && rowData["readOnly"] == false) {
                            return "/incidentview/" + rowData["incidentId"];
                        }
                        return "";
                    }}
                    onFormatValue={(column, value, data, row) => {
                        if (column == "expires" && row.obsolete) {
                            return <i>Expired</i>
                        }
                        return value;
                    }}
                />;
            }
        } 

        return (
            <>
                {incidents}
            </>
        );

    }
}

export default withStyles(styles, { withTheme: true })(POIPagedIncidents);