import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { permissionsLogic } from '../common/logic/permissionsLogic';
import * as facialRecognitionPOIActions from '../common/actions/facialRecognitionPOI';
import FacialRecognitionPOIScannedID from '../components/facialRecognition/facialRecognitionPOI/FacialRecognitionPOIScannedID'

const mapStateToProps = (state, props) => {

    let isLoading = false;
    let poiIdScan = {};
    let record = state.facialRecognitionPOI.poiScannedIds.find(f => f.poiGuid == props.poiGuid);
    if(record) {
        isLoading = record.isLoading;
        poiIdScan = record.poiIdScan;
    }

    return {
        venueId: state.user.details.userSession.user.venueId,
        poiGuid: props.poiGuid,
        isLoading,
        poiIdScan
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad:(venueId, poiGuid) => {
            dispatch(facialRecognitionPOIActions.facialRecognitionPoiScannedIDRequest(venueId, poiGuid))
        },
    }
}

const FacialRecognitionPOIScannedIDContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(FacialRecognitionPOIScannedID))

export default FacialRecognitionPOIScannedIDContainer