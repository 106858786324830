import React, { Component } from 'react';
import { Incident as IncidentDTO } from "../../../common/dto/AusComply.dtos";
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';
import TransparentButton from '../../controls/TransparentButton';
import Saving from '../../common/Saving';

export interface IIncidentViewNumberProps {
    incident: IncidentDTO;
    canViewUniqueIncidentID: boolean;
    canCreateUniqueIncidentID: boolean;
    onCreateUniqueID?: Function;
    isGeneratingUniqueID?: boolean;
}

export default class IncidentViewNumber extends Component<IIncidentViewNumberProps, any> {

    constructor(props: IIncidentViewNumberProps) {
        super(props)

        this.state = {
        }
        this.onGenerateUniqueID = this.onGenerateUniqueID.bind(this);
    }

    onGenerateUniqueID() {
        if (this.props.onCreateUniqueID) {
            this.props.onCreateUniqueID(this.props.incident.incidentId);
        }
    }

    render() {

        let uniqueID;

        if (this.props.isGeneratingUniqueID) {
            uniqueID = (
                <ControlGroup text="Unique ID">
                   <Saving message='Generating...' />
                </ControlGroup>
            );
        } else if (this.props.canViewUniqueIncidentID) {
            if (this.props.incident.uniqueID != undefined &&
                this.props.incident.uniqueID != null &&
                this.props.incident.uniqueID != "") {
                uniqueID = (
                    <ControlGroup text="Unique ID">
                        <TextDisplay>{this.props.incident.uniqueID}</TextDisplay>
                    </ControlGroup>
                );
            } else if (this.props.canCreateUniqueIncidentID && this.props.onCreateUniqueID) {
                uniqueID = (
                    <ControlGroup text="Unique ID">
                        <TransparentButton text='Generate Unique ID' onClick={this.onGenerateUniqueID} />
                    </ControlGroup>
                );
            }
        }
        return (
            <>
                <ControlGroup text="ID">
                    <TextDisplay>{this.props.incident.incidentSerialNumber}</TextDisplay>
                </ControlGroup>
                {uniqueID}
                <ControlGroup text="Report Status">
                    <TextDisplay>{this.props.incident.incidentStatusDisplay}</TextDisplay>
                </ControlGroup>
                {this.props.incident.venueEvent && (
                    <ControlGroup text="Event">
                        <TextDisplay>{this.props.incident.venueEvent}</TextDisplay>
                    </ControlGroup>)}
            </>
        );
    }
}