import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import ControlGroup from '../../controls/ControlGroup';
import TextDisplayControlGroup from '../../controls/TextDisplayControlGroup';
import TextControlGroup from '../../controls/TextControlGroup';
import SelectControlGroup from '../../controls/SelectControlGroup';
import { MulitSelectOption } from '../../../common/dto/common';
import Box from '@material-ui/core/Box';
import TransparentButton from '../../controls/TransparentButton';
import TimeZoneDateControlGroup from '../../controls/TimeZoneDateControlGroup';
import DeleteOutlinedIcon from '../../../resources/DeleteOutlinedIcon';
import SectionDivider from '../../common/SectionDivider';
import TextAreaControlGroup from '../../controls/TextAreaControlGroup';
import { Palette } from '../../../common/constants/palette';

export interface IPOIRegistrationWatchlistsProps {
    poiRegistration: AusComplyDtos.POIRegistration;
    facialRecognitionWatchlists: AusComplyDtos.FacialRecognitionWatchlist[];
    multiple: boolean;
    onUpdate: Function;
    classes: any;
    theme: any;
}

interface IPOIRegistrationWatchlistsState {
    watchlists: MulitSelectOption[];
}

class POIRegistrationWatchlists extends Component<IPOIRegistrationWatchlistsProps, IPOIRegistrationWatchlistsState> {
    constructor(props: IPOIRegistrationWatchlistsProps) {
        super(props)

        this.state = {
            watchlists: []
        };
        this.onValueChanged = this.onValueChanged.bind(this);
        this.onWatchlistSelected = this.onWatchlistSelected.bind(this);
        this.onWatchlistRemoved = this.onWatchlistRemoved.bind(this);
        this.onWatchlistValueChanged = this.onWatchlistValueChanged.bind(this);
    }

    componentDidMount() {
        this.loadWatchlists();
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.facialRecognitionWatchlists || {}) != JSON.stringify(prevProps.facialRecognitionWatchlists || {}) ||
            JSON.stringify(this.props.poiRegistration || {}) != JSON.stringify(prevProps.poiRegistration || {})) {
            this.loadWatchlists();
        }
    }

    loadWatchlists() {
    }

    onValueChanged(field, value) {
        let poiRegistration = { ...this.props.poiRegistration };
        poiRegistration[field] = value;
        this.props.onUpdate(poiRegistration);
    }

    onWatchlistSelected(id) {
        let poiRegistration = { ...this.props.poiRegistration };
        if (!poiRegistration.registerWatchlists) {
            poiRegistration.registerWatchlists = [];
        }
        var facialRecognitionWatchlist = poiRegistration.watchlists.find(f => f.facialRecognitionWatchlistId == id);
        if (facialRecognitionWatchlist && !poiRegistration.registerWatchlists.find(f => f.facialRecognitionWatchlist.facialRecognitionWatchlistId == id)) {
            var registerWatchlist = new AusComplyDtos.RegisterWatchlist();
            registerWatchlist.facialRecognitionWatchlist = { ...facialRecognitionWatchlist };
            registerWatchlist.expiry = 90;
            registerWatchlist.enrolExpiryType = AusComplyDtos.ngtEnrolExpiryType.Days;
            registerWatchlist.timeZoneDate = {...poiRegistration.timeZoneDate}
            poiRegistration.registerWatchlists.push(registerWatchlist);
        }
        this.props.onUpdate(poiRegistration);
    }

    onWatchlistRemoved(id) {
        let poiRegistration = { ...this.props.poiRegistration };
        if (!poiRegistration.registerWatchlists) {
            poiRegistration.registerWatchlists = [];
        }
        poiRegistration.registerWatchlists = poiRegistration.registerWatchlists.filter(f => f.facialRecognitionWatchlist.facialRecognitionWatchlistId != id);
        // @ts-ignore
        poiRegistration.incidentDocument = undefined;
        // @ts-ignore
        poiRegistration.incidentPatronPhoto = undefined;
        // @ts-ignore
        poiRegistration.scannedFace = undefined;
        // @ts-ignore
        poiRegistration.pois = undefined;
        // @ts-ignore
        if (!poiRegistration.otherFacialRecognitionWatchlistId || poiRegistration.otherFacialRecognitionWatchlistId == 0) {
            // @ts-ignore
            poiRegistration.poi = undefined;
        }
        this.props.onUpdate(poiRegistration);
    }

    onWatchlistValueChanged(index, fieldName, value) {
        let poiRegistration = { ...this.props.poiRegistration };
        if (!poiRegistration.registerWatchlists) {
            poiRegistration.registerWatchlists = [];
        }
        if (poiRegistration.registerWatchlists[index].enrolExpiryType != value && value == AusComplyDtos.ngtEnrolExpiryType.Age) {
            poiRegistration.registerWatchlists[index].expiry = 18;
        } else if (poiRegistration.registerWatchlists[index].enrolExpiryType != value && poiRegistration.registerWatchlists[index].enrolExpiryType == AusComplyDtos.ngtEnrolExpiryType.Age) {
            poiRegistration.registerWatchlists[index].expiry = 90;
        }
        poiRegistration.registerWatchlists[index][fieldName] = value;

        this.props.onUpdate(poiRegistration);
    }


    render() {

        let self = this;
        let selectedWatchlists;
        let selectedWatchlistsDivider;
        let selectWatchlist;
        let selectWatchlistInfo;
        let selectedWatchlistIds: number[] = [];
        let selectedWatchlistServerId: number = 0
        let hasSelectedWatchlists = this.props.poiRegistration.registerWatchlists && this.props.poiRegistration.registerWatchlists.length > 0;
        if (hasSelectedWatchlists) {
            selectedWatchlistIds = this.props.poiRegistration.registerWatchlists.map((item) => item.facialRecognitionWatchlist.facialRecognitionWatchlistId);
            selectedWatchlistServerId = this.props.poiRegistration.registerWatchlists[0].facialRecognitionWatchlist.facialRecognitionServerId;
            selectedWatchlists = (<>
                {this.props.poiRegistration.registerWatchlists.map((item, index) => {
                    var expiryValue = "0";
                    if (item && item.expiry != undefined) {
                        expiryValue = item.expiry.toString();
                    }
                    var expriryLabel = "Expiry after ";
                    if (item) {
                        var expriryLabel = "Expiry after " + item.enrolExpiryType;
                        if (item.enrolExpiryType == AusComplyDtos.ngtEnrolExpiryType.Age) {
                            expriryLabel = "Expiry at age";
                        }
                    }
                    return (
                        <div key={item.facialRecognitionWatchlist.facialRecognitionWatchlistId}>
                            <Box display="flex" flexDirection="row">
                                <Box p={1} flex={1} >
                                    <TextDisplayControlGroup labelAbove={true} text={"Watchlist"} value={item.facialRecognitionWatchlist.displayName} />
                                </Box>
                                <Box p={1} flex={0} >
                                    <Box display="flex" flexDirection="column" style={{ height: '100%' }}>
                                        <Box flex={1}>

                                        </Box>
                                        <Box flex={0}>
                                            <TransparentButton small={true} onClick={() => {
                                                self.onWatchlistRemoved(item.facialRecognitionWatchlist.facialRecognitionWatchlistId);
                                            }} >
                                                <DeleteOutlinedIcon />
                                            </TransparentButton>
                                        </Box>
                                        <Box flex={0}>

                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box display="flex" flexDirection="row">
                                <Box p={1} flex={0.75} >
                                    <SelectControlGroup
                                        text="Expiry"
                                        labelAbove={true}
                                        onChange={(value) => this.onWatchlistValueChanged(index, "enrolExpiryType", value)}
                                        defaultValue={item.enrolExpiryType}>
                                        <option key={AusComplyDtos.ngtEnrolExpiryType.Never} value={AusComplyDtos.ngtEnrolExpiryType.Never}>Never expire / Life ban</option>
                                        <option key={AusComplyDtos.ngtEnrolExpiryType.Hours} value={AusComplyDtos.ngtEnrolExpiryType.Hours}>Hours</option>
                                        <option key={AusComplyDtos.ngtEnrolExpiryType.Days} value={AusComplyDtos.ngtEnrolExpiryType.Days}>Days</option>
                                        <option key={AusComplyDtos.ngtEnrolExpiryType.Age} value={AusComplyDtos.ngtEnrolExpiryType.Age}>Age (requires patron DOB)</option>
                                        <option key={AusComplyDtos.ngtEnrolExpiryType.Date} value={AusComplyDtos.ngtEnrolExpiryType.Date}>Date</option>
                                    </SelectControlGroup>
                                </Box>
                                <Box p={1} flex={1} >
                                    {item.enrolExpiryType &&
                                        item.enrolExpiryType != AusComplyDtos.ngtEnrolExpiryType.Never &&
                                        item.enrolExpiryType != AusComplyDtos.ngtEnrolExpiryType.Date &&
                                        (
                                            <TextControlGroup
                                                text={expriryLabel}
                                                labelAbove={true}
                                                numericOnly={true}
                                                numberMustBePositive={true}
                                                onChange={(value) => this.onWatchlistValueChanged(index, "expiry", value)}
                                                defaultValue={expiryValue} />
                                        )}
                                    {item.enrolExpiryType &&
                                        item.enrolExpiryType == AusComplyDtos.ngtEnrolExpiryType.Date &&
                                        (
                                            <TimeZoneDateControlGroup
                                                text={"Expiry date"}
                                                labelAbove={true}
                                                futureOnly={true}
                                                onChange={(value) => this.onWatchlistValueChanged(index, "timeZoneDate", value)}
                                                date={item.timeZoneDate} />
                                        )}
                                </Box>
                            </Box>
                            <Box display="flex" flexDirection="row">
                                <TextAreaControlGroup text={"Notes"} labelAbove={true}
                                    defaultValue={item.notes}
                                    defaultRows={1}
                                    onChange={(value) => this.onWatchlistValueChanged(index, "notes", value)}
                                />
                            </Box>
                        </div>
                    )
                })}
            </>
            );
        }
        if (hasSelectedWatchlists && this.props.multiple) {
            selectedWatchlistsDivider = <SectionDivider text='' />
        }
        if (this.props.poiRegistration && this.props.poiRegistration.watchlists && (!hasSelectedWatchlists || this.props.multiple)) {

            let availableWatchlists = this.props.poiRegistration.watchlists.filter(f => 
                (selectedWatchlistServerId == 0 || selectedWatchlistServerId == f.facialRecognitionServerId)
                && selectedWatchlistIds.indexOf(f.facialRecognitionWatchlistId) == -1);

            if (availableWatchlists.length > 0) {
                selectWatchlist = (<SelectControlGroup
                    text={hasSelectedWatchlists ? "Add POI to additional watchlist" : "Watchlist"}
                    onChange={(value) => this.onWatchlistSelected(value)}
                    defaultValue={0}>
                    <option value={0} />
                    {availableWatchlists.map((watchlist, index) => (
                        <option key={watchlist.facialRecognitionWatchlistId} value={watchlist.facialRecognitionWatchlistId}>{watchlist.displayName}</option>
                    ))}
                </SelectControlGroup>);
            }


            /*
            selectWatchlistInfo = (<ControlGroup text=' '>
                <p style={{ textTransform: 'none', color: Palette.BontrolContrastText, padding: 0, margin: 0 }}>* All watchlists must be on the same facial recognition server</p>
            </ControlGroup>            
            );
            */
        }
        return (
            <ControlGroup>
                <CardContainer
                    title={"Select watchlist(s) for the POI"}>
                    <CardContent>
                        {selectedWatchlists}
                        {selectedWatchlistsDivider}
                        {selectWatchlist}
                        {selectWatchlistInfo}
                    </CardContent>
                </CardContainer>
            </ControlGroup>);
    }
}

export default withStyles(styles, { withTheme: true })(POIRegistrationWatchlists);