import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import FullPageDialog from '../../layout/FullPageDialog';
import Thumbnail from '../../common/Thumbnail';
import Box from '@material-ui/core/Box';
import Loading from '../../common/Loading';
import PersonIcon from '../../../resources/PersonIcon';
import ResponsiveList from '../../common/ResponsiveList';
import TextDisplayControlGroup from '../../controls/TextDisplayControlGroup';
import POIPagedIncidentsContainer from '../../../containers/POIPagedIncidentsContainer';

export interface IPOIProps {
    poiIncidents: AusComplyDtos.POIIncidents;
    theme: any;
    classes: any;
}

interface IPOIState {
    show: boolean;
}

class POI extends Component<IPOIProps, IPOIState> {
    constructor(props: IPOIProps) {
        super(props)
        this.state = {
            show: false
        };
        this.toggleShow = this.toggleShow.bind(this);
    }

    componentDidMount() {

    }

    toggleShow() {
        this.setState({ show: !this.state.show }, () => {
        })
    }

    render() {
        const { theme } = this.props;
        let profile;
        let faces;
        let incidents;
        let pagedIncidents;
        let label = "";
        let notes;

        label = this.props.poiIncidents.displayName;
        profile = (<Thumbnail
            previewPath={""}
            displayPath={""}
            base64={this.props.poiIncidents.image}
            base64Type={"image/jpeg"}
            isImage={true}
            title={this.props.poiIncidents.watchlist}
            fullText={this.props.poiIncidents.displayName}
            alwaysShowText={true}
            text={this.props.poiIncidents.displayName}
            onPreview={() => this.toggleShow()}
        />);
        // stacked details, faces, and incidents
        if (this.props.poiIncidents.notes) {
            notes = <p style={{ ...theme.custom.label }}>{this.props.poiIncidents.notes}</p>
        }

        if (this.props.poiIncidents.incidents) {
            /*incidents = <ResponsiveList
                data={this.props.poiIncidents.incidents.data}
                headers={["Incident Serial Number", "Incident Type", "Watchlist", "Expires", "Submitted", "Created", "Created By", "Note"]}
                columns={["incidentSerialNumber", "incidentType", "watchlist", "expires", "incidentSubmitted", "incidentDateOnlyDisplay", "createdBy", "notes"]}
                getCommands={item => []}
                onCommand={(command, item) => { }}
                paging={this.props.poiIncidents.incidents.paging}
                isDanger={item => item.obsolete}
                isDangerLabel={"Expired"}
                canEdit={false}
                onGetLink={(columnName, rowData) => {
                    if (columnName == "incidentSerialNumber") {
                        return "/incidentview/" + rowData["incidentId"];
                    }
                    return "";
                }}
            />;*/
            pagedIncidents = <POIPagedIncidentsContainer 
                facialRecognitionPOIIncidentId={this.props.poiIncidents.facialRecognitionPOIIncidentId}
                poiGuid={this.props.poiIncidents.poiGUID}
                detailed={true}
                forVenueId={this.props.poiIncidents.venueId}
                  />
        }

        if (this.props.poiIncidents.faces) {
            faces = <Box display="flex" flexDirection="row" justifyContent="left" flexWrap="wrap">
                <Box p={1} key={"file-box-poi-" + this.props.poiIncidents.poiGUID}>
                    <Thumbnail
                        previewPath={""}
                        displayPath={""}
                        base64={this.props.poiIncidents.image}
                        base64Type={"image/jpeg"}
                        isImage={true}
                        title={this.props.poiIncidents.displayName}
                        fullText={this.props.poiIncidents.displayName}
                        text={this.props.poiIncidents.displayName}
                        onPreview={(path, text) => { }}
                    />
                </Box>
                {this.props.poiIncidents.faces.map((face, faceIndex) =>
                    <Box p={1} key={"file-box-poi-" + face.faceId}>
                        <Thumbnail
                            previewPath={""}
                            displayPath={""}
                            base64={face.image}
                            base64Type={"image/jpeg"}
                            isImage={true}
                            title={""}
                            fullText={""}
                            text={""}
                            onPreview={(path, text) => { }}
                        />
                    </Box>
                )}
            </Box>
        }

        return (
            <>
                {profile}
                <FullPageDialog open={this.state.show} title={label} onDismissed={() => this.toggleShow()}>
                    {faces}
                    {notes}
                    {incidents}
                    {pagedIncidents}
                </FullPageDialog>
            </>
        );

    }
}

export default withStyles(styles, { withTheme: true })(POI);