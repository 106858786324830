import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { permissionsLogic } from '../common/logic/permissionsLogic';
import FacialRecognitionPOIs from '../components/facialRecognition/facialRecognitionPOI/FacialRecognitionPOIs'
import * as facialRecognitionWatchlistActions from '../common/actions/facialRecognitionWatchlist';
import * as facialRecognitionPOIActions from '../common/actions/facialRecognitionPOI';

const mapStateToProps = (state, props) => {
    return {
        isSaving: state.facialRecognitionWatchlists.facialRecognitionWatchlistPois.isSaving,
        isPoiSaving: state.facialRecognitionPOI.poiRegistration.isSaving,
        venueId: props.venueId,        
        groupId: props.groupId,
        thumbnails: state.facialRecognitionWatchlists.facialRecognitionWatchlistPois.thumbnails,
        //facialRecognitionWatchlistId: state.facialRecognitionWatchlists.facialRecognitionWatchlistPois.facialRecognitionWatchlistId,
        isLoading: state.facialRecognitionWatchlists.facialRecognitionWatchlistPois.isLoading,
        data: state.facialRecognitionWatchlists.facialRecognitionWatchlistPois.facialRecognitionWatchlistPois,
        facialRecognitionWatchlistPoi: state.facialRecognitionWatchlists.facialRecognitionWatchlistPois.facialRecognitionWatchlistPoi,
        filter: state.facialRecognitionWatchlists.facialRecognitionWatchlistPois.filter,
        paging: state.facialRecognitionWatchlists.facialRecognitionWatchlistPois.paging,
        canGenerateAppearance: permissionsLogic.isLevelSix(state),
        cameras: state.facialRecognitionWatchlists.facialRecognitionWatchlistPois.cameras
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSet: (facialRecognitionWatchlistPoi) => {
            dispatch(facialRecognitionWatchlistActions.facialRecognitionWatchlistPoiSet(facialRecognitionWatchlistPoi))
        },
        onSave: (page, pageSize, filter) => {
            dispatch(facialRecognitionWatchlistActions.facialRecognitionWatchlistPoiSaveRequest(page, pageSize, filter));
        },
        onReset: () => {
            dispatch(facialRecognitionWatchlistActions.facialRecognitionWatchlistPoisReset())
        },
        onLoad:(page, pageSize, filter) => {
            dispatch(facialRecognitionWatchlistActions.facialRecognitionWatchlistPoisRequest(page, pageSize, filter))
        },
        onRemove: (facialRecognitionWatchlistId, poiGuid) => {
            dispatch(facialRecognitionWatchlistActions.facialRecognitionWatchlistPoisRemoveRequest(facialRecognitionWatchlistId, poiGuid))
        },
        onGenerateAppearance: (poiGuid, facialRecognitionWatchlistId, facialRecognitionCameraId) => {
            dispatch(facialRecognitionPOIActions.facialRecognitionPoiGenerateAppearanceRequest(poiGuid, facialRecognitionWatchlistId, facialRecognitionCameraId, 0))
        },
        onThumbnails: (thumbnails) => {
            dispatch(facialRecognitionWatchlistActions.facialRecognitionWatchlistPoisThumbnails(thumbnails))
        },
        onAddToAnotherWatchlist: (venueId, groupId, facialRecognitionWatchlistId, poiGuid) => {
            dispatch(facialRecognitionPOIActions.facialRecognitionPoiWatchlistRegistrationRequest(venueId, groupId, facialRecognitionWatchlistId, poiGuid))
        },
    }
}

const FacialRecognitionPOIsContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(FacialRecognitionPOIs))

export default FacialRecognitionPOIsContainer