import React from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import { appSource } from '../../utilities/constants';
import ButtonLink from '../controls/ButtonLink';
import DocumentIcon from '../../resources/DocumentIcon';
import Box from '@material-ui/core/Box';
import Progressing from '../controls/Progressing';
import BlockIcon from '@material-ui/icons/Block';
import FixedFooter from '../layout/FixedFooter';
import DownloadIcon from '../../resources/DownloadIcon';
import BodyTextIcon from '../../resources/BodyTextIcon';
import DeleteOutlinedIcon from '../../resources/DeleteOutlinedIcon';
import { Palette } from '../../common/constants/palette';

export interface IThumbnailProps {
    theme: any;
    classes: any;
    base64?: string;
    base64Type?: string;
    previewPath: string;
    displayPath: string;
    isImage: boolean;
    alwaysShowText?: boolean;
    text: string;
    fullText?: string;
    title?: string;
    borderColor?: string;
    isLoading?: boolean;
    isDanger?: boolean;
    isAbsolulePath?: boolean;
    isBlocked?: boolean;
    onPreview?: Function;
    onDownload?: Function;
    hideDownload?: boolean;
    onRemove?: Function;
    style?: any;
    small?: boolean;
    tiny?: boolean;
    hasImageText?: boolean;
}

class Thumbnail extends React.PureComponent<IThumbnailProps, any> {
    constructor(props: IThumbnailProps) {
        super(props)
        this.onPreview = this.onPreview.bind(this);
        this.onDownload = this.onDownload.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
    }

    onPreview(path) {
        if (this.props.onPreview) {
            if (this.props.base64) {
                this.props.onPreview(`data:${this.props.base64Type}/jpeg;base64,${this.props.base64}`, this.props.fullText);
            } else {
                this.props.onPreview(path, this.props.fullText);
            }
        }
    }


    onDownload(path) {
        if (this.props.onDownload) {
            this.props.onDownload(path)
        }
    }

    downloadFile(path) {
        const xmlHttp = new XMLHttpRequest();
        xmlHttp.onreadystatechange = () => {
            if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
                const blobUrl = window.URL.createObjectURL(xmlHttp.response);
                const e = document.createElement('a');
                e.href = blobUrl;
                e.download = blobUrl.substr(blobUrl.lastIndexOf('/') + 1);
                document.body.appendChild(e);
                e.click();
                document.body.removeChild(e);
            }
        };
        xmlHttp.responseType = 'blob';
        xmlHttp.open('GET', path, true);
        xmlHttp.send(null);
    }

    onRemove() {
        if (this.props.onRemove) {
            this.props.onRemove()
        }
    }

    render() {
        const { theme } = this.props;

        let base = appSource.getBaseImagesPath();
        if (base === '/') {
            base = "";
        }
        let display = base + this.props.displayPath;
        let view = base + this.props.previewPath;
        if (this.props.isAbsolulePath) {
            display = this.props.displayPath;
            view = this.props.previewPath;
        }

        let cursor = "default";
        if (this.props.onPreview || this.props.onDownload) {
            cursor = "pointer";
        }

        let preview;
        let downloadIcon;
        if (this.props.base64) {
            preview = (
                <img src={`data:${this.props.base64Type}/jpeg;base64,${this.props.base64}`}
                    style={this.props.tiny ? theme.custom.fileUploaderImageTiny : this.props.small ? theme.custom.fileUploaderImageSmall : theme.custom.fileUploaderImage}
                    alt="photo"
                    onClick={() => this.onPreview(display)} />
            );
        } else if (this.props.isBlocked) {
            if (this.props.tiny) {
                preview = (
                    <BlockIcon
                        style={{
                            color: Palette.ControlBorder,
                            width: '23px',
                            height: '23px',
                            margin: this.props.tiny ? '0 0 0 3px' : '15px 17px 22px'
                        }}
                    />
                );
            } else if (this.props.small) {
                preview = (
                    <BlockIcon
                        style={{
                            color: Palette.ControlBorder,
                            width: '23px',
                            height: '23px',
                            margin: '15px 17px 22px'
                        }}
                    />
                );
            } else {
                preview = (
                    <BlockIcon
                        style={{
                            color: Palette.ControlBorder,
                            width: '46px',
                            height: '46px',
                            margin: '15px 17px 22px'
                        }}
                    />
                );
            }
        } else if (this.props.isImage) {
            preview = (
                <img src={view}
                    style={this.props.tiny ? theme.custom.fileUploaderImageTiny : this.props.small ? theme.custom.fileUploaderImageSmall : theme.custom.fileUploaderImage}
                    alt="photo"
                    onClick={() => this.onPreview(display)} />
            );
            let downloadLink = display;
            if (downloadLink.indexOf("-1?downloadtoken"))
            {
                downloadLink = downloadLink.replace("-1?downloadtoken","-3?downloadtoken");
            }
            downloadIcon = (
                <ButtonLink onClick={() => this.downloadFile(downloadLink)}
                    style={{
                        minWidth: '20px',
                        position: 'absolute',
                        bottom: '3px',
                        right: '3px',
                        width: '20px',
                        height: '20px',
                        padding: 0,
                    }}>
                    <div style={{ borderRadius: '12px', backgroundColor: 'black', width: '24px', height: '24px', padding: 0 }}>
                        <DownloadIcon fill={Palette.ControlBorder} />
                    </div>
                </ButtonLink>
            );
        } else if (view.indexOf("api/file") > -1) {
            preview = (
                <img src={view}
                    style={this.props.tiny ? theme.custom.fileUploaderImageTiny : this.props.small ? theme.custom.fileUploaderImageSmall : theme.custom.fileUploaderImage}
                    alt="document"
                    onClick={() => this.onDownload(display)} />
            );
            downloadIcon = (
                <ButtonLink onClick={() => this.downloadFile(display)}
                    style={{
                        minWidth: '20px',
                        position: 'absolute',
                        bottom: '3px',
                        right: '3px',
                        width: '20px',
                        height: '20px',
                        padding: 0,
                    }}>
                    <div style={{ borderRadius: '12px', backgroundColor: 'black', width: '24px', height: '24px', padding: 0 }}>
                        <DownloadIcon fill={Palette.ControlBorder} />
                    </div>
                </ButtonLink>
            );
        } else {
            preview = (
                <div onClick={() => this.onDownload(display)}>
                    <DocumentIcon style={{
                        color: Palette.ControlBorder,
                        width: this.props.tiny ? "23px" : '50px',
                        height: this.props.tiny ? "23px" : '50px',
                        margin: this.props.tiny ? '0 0 0 3px' : '10 15px 20px'
                    }}
                    />
                </div>
            );
            downloadIcon = (
                <ButtonLink onClick={() => this.downloadFile(display)}
                    style={{
                        minWidth: '20px',
                        position: 'absolute',
                        bottom: '3px',
                        right: '3px',
                        width: '20px',
                        height: '20px',
                        padding: 0,
                    }}>
                    <div style={{ borderRadius: '12px', backgroundColor: 'black', width: '24px', height: '24px', padding: 0 }}>
                        <DownloadIcon fill={Palette.ControlBorder} />
                    </div>
                </ButtonLink>
            );
        }
        if (this.props.tiny) {
            return (
                <Box display="flex" flexDirection="column" justifyContent="center">
                    <Box p={0} flexGrow={1} style={{ cursor: cursor }}>
                        <div style={{
                            ...theme.custom.fileUploaderTinyContainer,
                            position: 'relative',
                            ...this.props.style
                        }}>
                            {preview}
                        </div>
                    </Box>
                </Box>
            );
        }
        let ocr;
        if (this.props.isImage && this.props.hasImageText) {
            ocr = <div style={{
                position: 'absolute',
                bottom: '1px',
                left: '1px',
                borderRadius: '12px',
                borderColor: 'black',
                borderWidth: '2px',
                backgroundColor: Palette.ControlBorder,
                width: '24px',
                height: '24px',
                borderStyle: 'solid',
                padding: 0,
                pointerEvents: 'none'
            }}>
                <BodyTextIcon fill={'black'} />
            </div>
        }
        var borderColor = this.props.borderColor ? this.props.borderColor : this.props.isDanger ? Palette.Error : Palette.ControlBorder;
        return (
            <Box display="flex" flexDirection="column" justifyContent="center">
                <Box p={1} flexGrow={1} style={{ cursor: cursor }}>
                    <div style={{
                        ...theme.custom.fileUploaderContainer,
                        position: 'relative',
                        ...this.props.style,
                        borderColor: borderColor
                    }}>
                        {preview}
                        {this.props.title && (
                            <p style={{ ...theme.custom.fileUploaderText, fontSize: '8px', top: '2px', background: 'black', opacity: 0.7, bottom: 'auto' }}>{this.props.title}</p>
                        )}
                        {(!this.props.isImage || this.props.alwaysShowText) && (
                            <p style={theme.custom.fileUploaderText}>{this.props.text}</p>
                        )}
                        {this.props.isLoading && (
                            <Progressing style={{ position: 'absolute', bottom: '-8px', left: '2px', right: '2px' }} />
                        )}
                        {!this.props.hideDownload && (<>{downloadIcon}</>)}
                        {ocr}
                    </div>
                </Box>
                <Box p={0} style={{ textAlign: 'center' }}>
                    {this.props.onRemove && (
                        <ButtonLink onClick={this.onRemove} style={{ padding: '0' }} >
                            <DeleteOutlinedIcon style={{
                                color: Palette.ControlBorder,
                                width: '24px',
                                height: '24px'
                            }} />
                        </ButtonLink>
                    )}
                </Box>
            </Box>
        );
    }
}

export default withStyles(styles, { withTheme: true })(Thumbnail);