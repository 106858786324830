import { connect } from 'react-redux'
import IncidentView from '../components/incidents/IncidentView'
import * as editIncidentActions from '../common/actions/editIncident'
import { withRouter } from "react-router";
import { printShow } from '../common/actions/print';
import { permissionsLogic } from '../common/logic/permissionsLogic';
import { notificationShow } from '../common/actions/notification';

const mapStateToProps = (state, props) => {
    return {
        incidentId: props.match.params.incidentid,
        incident: state.editIncident.incident,
        incidentOptions: state.editIncident.incidentOptions,
        isLoading: state.editIncident.isLoading,
        isSaving: state.editIncident.isSaving,
        error: state.editIncident.error,
        canViewUniqueIncidentID: permissionsLogic.hasPermissionForState(state,"IncidentUniqueIDView"),
        canCreateUniqueIncidentID: permissionsLogic.hasPermissionForState(state,"IncidentUniqueIDCreate"),
        isGeneratingUniqueID: state.editIncident.isGeneratingUniqueID,
        canShowVersions: permissionsLogic.hasAnyPermissionForState(state, ["IncidentSecurityFirmVersionsView", "IncidentVenueVersionsView"])
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoadIncident: (incidentId) => {
            dispatch(editIncidentActions.editIncidentLoad(incidentId))
        },
        onClear: () => {
            dispatch(editIncidentActions.editIncidentClear())
        },
        onClearError: () => {
            dispatch(editIncidentActions.editIncidentClearError())
        },
        onApprove: () => {
            dispatch(editIncidentActions.editIncidentSave(false, true))
        },
        onPrint: () => {
            dispatch(printShow())
        },
        onErrorNotification: (message) => {
            dispatch(notificationShow(message));
        },
        onCreateUniqueID: (incidentId) => {
            dispatch(editIncidentActions.editIncidentGenerateUniqueIDRequest(incidentId));
        }
    }
}

const IncidentViewContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(IncidentView))

export default IncidentViewContainer