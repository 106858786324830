
import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { venueServiceApi } from '../services/venueService';
import { userComplianceDocumentServiceApi } from '../services/userComplianceDocumentService';

import {
    USER_COMPLIANCE_DOCUMENTS_EXPIRING_REQUEST,
    userComplianceDocumentsExpiringRequestSuccess,
    userComplianceDocumentsExpiringRequestFailure,

    USER_COMPLIANCE_DOCUMENTS_MISSING_REQUEST,
    userComplianceDocumentsMissingRequestSuccess,
    userComplianceDocumentsMissingRequestFailure,

    USER_COMPLIANCE_DOCUMENTS_EXPIRING_EXPORT_REQUEST,
    USER_COMPLIANCE_DOCUMENTS_EXPIRING_EXPORT_REQUEST_SUCCESS,
    userComplianceDocumentsExpiringExportRequestSuccess,
    userComplianceDocumentsExpiringExportRequestFailure,

    USER_COMPLIANCE_DOCUMENTS_MISSING_EXPORT_REQUEST,
    USER_COMPLIANCE_DOCUMENTS_MISSING_EXPORT_REQUEST_SUCCESS,
    userComplianceDocumentsMissingExportRequestSuccess,
    userComplianceDocumentsMissingExportRequestFailure
    
} from '../actions/userComplianceDocument';
import {
    printQueueRequest
} from '../actions/print';
import {
    notifyError, notifyErrorMessage
} from './common';

const userComplianceDocumentExpiringRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_COMPLIANCE_DOCUMENTS_EXPIRING_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userComplianceDocumentServiceApi.getExpiringSummary(
                action["venueId"],
                action["securityFirmId"],
                state.userComplianceDocument.expiring.paging.page,
                state.userComplianceDocument.expiring.paging.pageSize,
                state.userComplianceDocument.expiring.filter)
                .pipe(
                    map(response => userComplianceDocumentsExpiringRequestSuccess(response.userComplianceDocuments, response.paging, response.filter)),
                    catchError(error => notifyError(error, "userComplianceDocumentExpiringRequestEpic.getExpiringSummary", userComplianceDocumentsExpiringRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "userComplianceDocumentExpiringRequestEpic", userComplianceDocumentsExpiringRequestFailure))
    );

const userComplianceDocumentMissingRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_COMPLIANCE_DOCUMENTS_MISSING_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userComplianceDocumentServiceApi.getMissingSummary(
                action["venueId"],
                action["securityFirmId"],
                state.userComplianceDocument.missing.paging.page,
                state.userComplianceDocument.missing.paging.pageSize,
                state.userComplianceDocument.missing.filter)
                .pipe(
                    map(response => userComplianceDocumentsMissingRequestSuccess(response.userComplianceDocuments, response.paging, response.filter)),
                    catchError(error => notifyError(error, "userComplianceDocumentMissingRequestEpic.getMissingSummary", userComplianceDocumentsMissingRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "userComplianceDocumentMissingRequestEpic", userComplianceDocumentsMissingRequestFailure))
    );

const userComplianceDocumentExpiringExportRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_COMPLIANCE_DOCUMENTS_EXPIRING_EXPORT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userComplianceDocumentServiceApi.exportExpiring(
                action["venueId"],
                action["securityFirmId"],
                state.userComplianceDocument.expiring.filter)
                .pipe(
                    map(response => userComplianceDocumentsExpiringExportRequestSuccess()),
                    catchError(error => notifyError(error, "userComplianceDocumentExpiringExportRequestEpic.exportExpiring", userComplianceDocumentsExpiringExportRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "userComplianceDocumentExpiringExportRequestEpic", userComplianceDocumentsExpiringExportRequestFailure))
    );

const userComplianceDocumentExpiringExportRequestSuccessEpic = action$ =>
    action$.pipe(
        ofType(USER_COMPLIANCE_DOCUMENTS_EXPIRING_EXPORT_REQUEST_SUCCESS),
        map(action => printQueueRequest())
    );


const userComplianceDocumentMissingExportRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_COMPLIANCE_DOCUMENTS_MISSING_EXPORT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userComplianceDocumentServiceApi.exportMissing(
                action["venueId"],
                action["securityFirmId"],
                state.userComplianceDocument.missing.filter)
                .pipe(
                    map(response => userComplianceDocumentsMissingExportRequestSuccess()),
                    catchError(error => notifyError(error, "userComplianceDocumentMissingExportRequestEpic.exportMissing", userComplianceDocumentsMissingExportRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "userComplianceDocumentMissingExportRequestEpic", userComplianceDocumentsMissingExportRequestFailure))
    );

const userComplianceDocumentMissingExportRequestSuccessEpic = action$ =>
    action$.pipe(
        ofType(USER_COMPLIANCE_DOCUMENTS_MISSING_EXPORT_REQUEST_SUCCESS),
        map(action => printQueueRequest())
    );

export const userComplianceDocumentEpics = combineEpics(
    userComplianceDocumentExpiringRequestEpic,
    userComplianceDocumentMissingRequestEpic,
    userComplianceDocumentExpiringExportRequestEpic,
    userComplianceDocumentMissingExportRequestEpic,
    userComplianceDocumentExpiringExportRequestSuccessEpic,
    userComplianceDocumentMissingExportRequestSuccessEpic
);