import React, { Component } from 'react';
import { Incident as IncidentDTO, IncidentDocument, File as FileDTO } from "../../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import SubTitle from '../../common/SubTitle';
import FileUpload from '../../common/FileUpload';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import { appSource } from '../../../utilities/constants';
import PersonIcon from '../../../resources/PersonIcon';
import IdCardIcon from '../../../resources/IdCardIcon';
import TickIcon from '../../../resources/TickIcon';
import DocumentIcon from '../../../resources/DocumentIcon';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorButton from '../../controls/ErrorButton';
import TransparentButton from '../../controls/TransparentButton';
import ImagePreview from '../../common/ImagePreview';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import SwipeContainer from '../../layout/SwipeContainer';
import Thumbnail from '../../common/Thumbnail';
import Box from '@material-ui/core/Box';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";

export interface IIDScanDocumentsProps {
    incident: IncidentDTO;
    isUploading: boolean;
    onUploadFiles: Function;
    onUpdateIncident: Function;
    onSwipedRight?: Function;
    onSwipedLeft?: Function;
    onCompressing?: Function;
    theme: any;
}

interface IIDScanDocumentsState {
    selectedFilePath: string;
    selectedFileName: string;
}

class IDScanDocuments extends Component<IIDScanDocumentsProps, IIDScanDocumentsState> {
    constructor(props: IIDScanDocumentsProps) {
        super(props)
        this.state = {
            selectedFilePath: "",
            selectedFileName: ""
        };
        this.onSelectFilePath = this.onSelectFilePath.bind(this);
        this.onDownload = this.onDownload.bind(this);
        this.removeDocument = this.removeDocument.bind(this);
        this.fileWasUploaded = this.fileWasUploaded.bind(this);
    }

    onSelectFilePath(filePath, text) {
        this.setState({
            selectedFilePath: filePath,
            selectedFileName: text
        });
    }

    onDownload(filePath) {
        // fake server request, getting the file url as response
        setTimeout(() => {
            const response = {
                file: filePath,
            };
            // server sent the url to the file!
            // now, let's download:
            window.open(response.file);
            // you could also do:
            // window.location.href = response.file;
        }, 100);
    }


    removeDocument(document: IncidentDocument) {
        let newIncident = { ...this.props.incident };
        if (document.incidentDocumentId === 0) {
            newIncident.incidentDocuments = newIncident.incidentDocuments.filter(item => item.fileDisplayPath !== document.fileDisplayPath);
        } else {
            newIncident.incidentDocuments.forEach(item => {
                if (item.fileDisplayPath === document.fileDisplayPath) {
                    item.hasChanged = true;
                    item.obsolete = true;
                }
            });
        }
        this.props.onUpdateIncident(newIncident);
    }

    expandImage(document: IncidentDocument) {

    }

    fileWasUploaded(temporaryFiles: FileDTO[], imagePrefix: string) {
        let newIncident = { ...this.props.incident };
        temporaryFiles.forEach(item => {
            let document = new IncidentDocument();
            document.obsolete = false;
            document.originalFilename = imagePrefix + item.originalName;
            document.isImage = item.isImage;
            document.incidentId = this.props.incident.incidentId;
            document.canvasRescaled = false;
            document.filePath = item.path;
            document.filePreviewPath = item.filePreviewPath;
            document.fileDisplayPath = item.fileDisplayPath;
            document.extension = item.extension;
            document.incidentDocumentId = 0;
            document.filePreviewText = item.filePreviewText;
            document.hasChanged = true;
            document.canvasRescaled = item.canvasRescaled;
            document.storageFileName = item.storageFileName;
            newIncident.incidentDocuments.push(document);
        });
        this.props.onUpdateIncident(newIncident);
    }

    render() {
        const { theme } = this.props;

        let idScan;
        let personScan;
        let covidScan;

        idScan = (
            <Box p={0} key={"file-box-id"}>
                <FileUpload
                    onFileWasUploaded={f => this.fileWasUploaded(f, "id_")}
                    isUploading={this.props.isUploading}
                    requestCamera={true}
                    imagePrefix="id_"
                    entityType={AusComplyDtos.ngtEntityType.Incident}
                    storageFolder={this.props.incident.storageFolder}
                    alternateContent={
                        <Box display="flex" flexDirection="column" justifyContent="center" p={0}
                            style={{
                                position: 'relative',
                                textAlign: 'center',
                                width: '75px',
                                margin: '0 8px',
                                height: '84px',
                                borderRadius: '3px',
                                border: 'none' //'1px solid #636364'
                            }}>
                            <Box p={0}></Box>
                            <Box p={0}>
                                <IdCardIcon style={{ fontSize: '2.5em' }} />
                            </Box>
                            <Box p={0}>
                                <span style={{ fontSize: '10px' }}>ID</span>
                            </Box>
                            <Box p={0}></Box>
                        </Box>}
                    hasFiles={false} />
            </Box>
        );

        personScan = (
            <Box p={0} key={"file-box-person"}>
                <FileUpload
                    onFileWasUploaded={f => this.fileWasUploaded(f, "person_")}
                    isUploading={this.props.isUploading}
                    // onUploadFiles={this.props.onUploadFiles}
                    imagePrefix="person_"
                    entityType={AusComplyDtos.ngtEntityType.Incident}
                    storageFolder={this.props.incident.storageFolder}
                    requestCamera={true}
                    alternateContent={<Box display="flex" flexDirection="column" justifyContent="center" p={0}
                        style={{
                            position: 'relative',
                            textAlign: 'center',
                            width: '75px',
                            margin: '0',
                            height: '84px',
                            borderRadius: '3px',
                            border: 'none' //border: '1px solid #636364'
                        }}>
                        <Box p={0}></Box>
                        <Box p={0}>
                            <PersonIcon style={{ fontSize: '2.5em' }} />
                        </Box>
                        <Box p={0}>
                            <span style={{ fontSize: '10px' }}>Person</span>
                        </Box>
                        <Box p={0}></Box>
                    </Box>}
                    hasFiles={false} />
            </Box>
        );

        /*
        covidScan = (
            <Box p={0} key={"file-box-covid"}>
                <FileUpload
                    onFileWasUploaded={f => this.fileWasUploaded(f, "covid_")}
                    isUploading={this.props.isUploading}
                    imagePrefix="covid_"
                    requestCamera={true}
                    alternateContent={<Box display="flex" flexDirection="column" justifyContent="center" p={0}
                        style={{
                            position: 'relative',
                            textAlign: 'center',
                            width: '75px',
                            margin: '0 8px',
                            height: '84px',
                            borderRadius: '3px',
                            border: '1px solid #636364'
                        }}>
                        <Box p={0}></Box>
                        <Box p={0}>
                            <DocumentIcon style={{ fontSize: '2.5em' }} />
                        </Box>
                        <Box p={0}>
                            <span style={{ fontSize: '10px' }}>COVID Safe</span>
                        </Box>
                        <Box p={0}></Box>
                    </Box>}
                    hasFiles={false} />
            </Box>
        );
        */

        if (this.props.incident && this.props.incident.incidentDocuments) {

            this.props.incident.incidentDocuments.filter(item => !item.obsolete).forEach((item, index) => {
                if (item.originalFilename.startsWith("id_")) {
                    idScan = <Box p={0} key={"file-box-" + item.originalFilename + "_" + index.toString()}>
                        <Thumbnail
                            key={"file-" + item.originalFilename + "_" + index.toString()}
                            previewPath={item.filePreviewPath}
                            displayPath={item.fileDisplayPath}
                            isImage={item.isImage}
                            text={item.filePreviewText}
                            fullText={item.originalFilename}
                            onPreview={(path, text) => this.onSelectFilePath(path, text)}
                            onDownload={path => this.onDownload(path)}
                            onRemove={() => this.removeDocument(item)}
                        />
                    </Box>
                }
                if (item.originalFilename.startsWith("person_")) {
                    personScan = <Box p={0} key={"file-box-" + item.originalFilename + "_" + index.toString()}>
                        <Thumbnail
                            key={"file-" + item.originalFilename + "_" + index.toString()}
                            previewPath={item.filePreviewPath}
                            displayPath={item.fileDisplayPath}
                            isImage={item.isImage}
                            text={item.filePreviewText}
                            fullText={item.originalFilename}
                            onPreview={(path, text) => this.onSelectFilePath(path, text)}
                            onDownload={path => this.onDownload(path)}
                            onRemove={() => this.removeDocument(item)}
                        />
                    </Box>
                }
                if (item.originalFilename.startsWith("covid_")) {
                    covidScan = <Box p={0} key={"file-box-" + item.originalFilename + "_" + index.toString()}>
                        <Thumbnail
                            key={"file-" + item.originalFilename + "_" + index.toString()}
                            previewPath={item.filePreviewPath}
                            displayPath={item.fileDisplayPath}
                            isImage={item.isImage}
                            text={item.filePreviewText}
                            fullText={item.originalFilename}
                            onPreview={(path, text) => this.onSelectFilePath(path, text)}
                            onDownload={path => this.onDownload(path)}
                            onRemove={() => this.removeDocument(item)}
                        />
                    </Box>
                }

            });
        }

        return (
            <CardContainer
                title={"Photos"}>
                <CardContent>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <Box display="flex" flexDirection="row" justifyContent="center" flexWrap="wrap">
                                        {idScan}
                                        {covidScan}
                                        {personScan}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <ImagePreview filePath={this.state.selectedFilePath} fileName={this.state.selectedFileName} onDismissed={() => this.onSelectFilePath("", "")} />
                    </Grid>
                </CardContent>
            </CardContainer>
        );
    }
}

export default withStyles(styles, { withTheme: true })(IDScanDocuments);