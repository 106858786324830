import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom, filter } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { userServiceApi } from '../services/userService';
import { venueServiceApi } from '../services/venueService';
import { industryCategoryApi } from '../services/industryCategory';
import { incidentTypeApi } from '../services/incidentType';
import { incidentFlagTypeApi } from '../services/incidentFlagType';
import { incidentCategoryTypeApi } from '../services/incidentCategoryType';
import { userActionAuditServiceApi } from '../services/userActionAuditService';
import { websitePropertiesApi } from '../services/websiteProperties';
import { venueLocationServiceApi } from '../services/venueLocation';
import { documentTypeServiceApi } from '../services/documentType';
import { venueSubscriptionServiceApi } from '../services/venueSubscriptionService';
import { securityFirmSubscriptionServiceApi } from '../services/securityFirmSubscriptionService';
import { securityFirmServiceApi } from '../services/securityFirmService';
import { loginServiceApi } from '../services/loginService';
import { notificationServiceApi } from '../services/notificationService';
import { websiteAdminApi } from '../services/websiteAdmin';

import * as adminActions from "../actions/admin";

import {
    userLogoutRequest,
    userDetailsRefresh
} from "../actions/userLogin";

import {
    userVenueSecurityFirmLoadStatistics
} from "../actions/userVenueSecurityFirm"

import {
    USER_ROLE_ADD_SAVE_SUCCESS,
    USER_ROLE_REQUEST_DISMISS_REQUEST_SUCCESS,
    USER_ROLE_ENABLE_REQUEST_SUCCESS
} from '../actions/userRole';
import {
    printQueueRequest
} from '../actions/print';
import {
    notifyError, notifyErrorMessage
} from './common';
import { notificationSuccessShow, notificationShow } from "../actions/notification";
import { incidentServiceApi } from '../services/incidentService';

const adminUserRoleEnableSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_ROLE_ENABLE_REQUEST_SUCCESS),
        withLatestFrom(state$),
        filter(([action, state]) => {
            if (state.admin.user && state.admin.user.user && state.admin.user.user.userId > 0) {
                return true;
            }
            return false;
        }),
        map((action: any) => adminActions.adminUserRefresh())
    );

const adminUserRoleAddSaveSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_ROLE_ADD_SAVE_SUCCESS),
        withLatestFrom(state$),
        filter(([action, state]) => {
            if (state.admin.user && state.admin.user.user) {
                let userRole = action['userRole'];
                if (userRole) {
                    if (userRole.userId == state.admin.user.user.userId) {
                        return true;
                    }
                }
            }
            return false;
        }),
        map((action: any) => adminActions.adminUserRefresh())
    );

const adminUserRoleRemoveSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_ROLE_REQUEST_DISMISS_REQUEST_SUCCESS),
        withLatestFrom(state$),
        filter(([action, state]) => {
            if (state.admin.user && state.admin.user.user && state.admin.user.user.userId > 0) {
                return true;
            }
            return false;
        }),
        map((action: any) => adminActions.adminUserRefresh())
    );

const adminUserRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_USER_REFRESH),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.getUserWithObsoleteUserRoles(state.admin.user.user.userId)
                .pipe(
                    map(response => adminActions.adminUserRequestSuccess(response.user,
                        response.states,
                        response.genders,
                        response.userOtherDocuments,
                        response.userOtherDocumentTypes,
                        response.userComplianceDocuments,
                        response.userComplianceDocumentTypes,
                        response.avatar)),
                    catchError(error => notifyError(error, "adminUserRefreshEpic.getUserWithObsoleteUserRoles", adminActions.adminUserRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminUserRefreshEpic", adminActions.adminUserRequestFailure))
    );

const adminDuplicateUserMobilesRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_DUPLICATE_USER_MOBILES_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.getDuplicateUserMobiles(action['page'], action['pageSize'], action['search'])
                .pipe(
                    map(response => adminActions.adminDuplicateUserMobilesSuccess(response.users, response.paging, response.search)),
                    catchError(error => notifyError(error, "adminDuplicateUserMobilesRequestEpic.getDuplicateUserMobiles", adminActions.adminDuplicateUserMobilesFailure))
                )
        ),
        catchError(error => notifyError(error, "adminDuplicateUserMobilesRequestEpic", adminActions.adminDuplicateUserMobilesFailure))
    );

const adminDuplicateUserMobileUpdateEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_DUPLICATE_USER_MOBILE_UPDATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.updateDuplicateUserMobile(action['userId'], action['mobileNo'])
                .pipe(
                    map(response => adminActions.adminDuplicateUserMobileUpdateSuccess()),
                    catchError(error => notifyError(error, "adminDuplicateUserMobileUpdateEpic.updateDuplicateUserMobile", adminActions.adminDuplicateUserMobileUpdateFailure))
                )
        ),
        catchError(error => notifyError(error, "adminDuplicateUserMobileUpdateEpic", adminActions.adminDuplicateUserMobileUpdateFailure))
    );

const adminUserRolesRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_USER_ROLES_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.getAdminUserRoles(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId, action['page'], action['pageSize'], action['filter'])
                .pipe(
                    map(response => adminActions.adminUserRolesRequestSuccess(response.users, response.paging, response.filter)),
                    catchError(error => notifyError(error, "adminUserRolesRequestEpic.getAdminUserRoles", adminActions.adminUserRolesRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminUserRolesRequestEpic", adminActions.adminUserRolesRequestFailure))
    );

const adminUserActionAuditRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_USER_ACTION_AUDITS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userActionAuditServiceApi.getForUser(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId, action['userId'], action['page'], action['pageSize'])
                .pipe(
                    map(response => adminActions.adminUserActionAuditSuccess(response.items, response.paging)),
                    catchError(error => notifyError(error, "adminUserActionAuditRequestEpic.getForUser", adminActions.adminUserActionAuditFailure))
                )
        ),
        catchError(error => notifyError(error, "adminUserActionAuditRequestEpic", adminActions.adminUserActionAuditFailure))
    );

const adminUsersRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_USERS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.getAdminUsers(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId, action['page'], action['pageSize'], action['filter'])
                .pipe(
                    map(response => adminActions.adminUsersRequestSuccess(response.users, response.paging, response.filter)),
                    catchError(error => notifyError(error, "adminUsersRequestEpic.getAdminUsers", adminActions.adminUsersRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminUsersRequestEpic", adminActions.adminUsersRequestFailure))
    );

const adminVenuesRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_VENUES_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueServiceApi.getAdminVenues(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId, action['page'], action['pageSize'], action['filter'])
                .pipe(
                    map(response => adminActions.adminVenuesRequestSuccess(response.venues, response.paging, response.filter)),
                    catchError(error => notifyError(error, "adminVenuesRequestEpic.getAdminVenues", adminActions.adminVenuesRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminVenuesRequestEpic", adminActions.adminVenuesRequestFailure))
    );

const adminVenuesRefreshRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_VENUES_REFRESH_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueServiceApi.getAdminVenues(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.admin.venues.paging.page,
                state.admin.venues.paging.pageSize,
                state.admin.venues.filter)
                .pipe(
                    map(response => adminActions.adminVenuesRequestSuccess(response.venues, response.paging, response.filter)),
                    catchError(error => notifyError(error, "adminVenuesRefreshRequestEpic.getAdminVenues", adminActions.adminVenuesRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminVenuesRefreshRequestEpic", adminActions.adminVenuesRequestFailure))
    );

const adminVenueAssociationsRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_VENUE_ASSOCIATIONS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueServiceApi.getAdminVenueAssociations(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId, action['venueId'])
                .pipe(
                    map(response => adminActions.adminVenueAssociationsRequestSuccess(response.venueSecurityFirms, response.securityFirmDescendants)),
                    catchError(error => notifyError(error, "adminVenueAssociationsRequestEpic.getAdminVenueAssociations", adminActions.adminVenueAssociationsRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminVenueAssociationsRequestEpic", adminActions.adminVenueAssociationsRequestFailure))
    );

const adminVenueSecurityFirmRemoveRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_VENUESECURITYFIRM_REMOVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueServiceApi.removeVenueSecurityFirm(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId, action['venueSecurityFirmId'])
                .pipe(
                    map(response => adminActions.adminVenueSecurityFirmRemoveRequestSuccess()),
                    catchError(error => notifyError(error, "adminVenueSecurityFirmRemoveRequestEpic.removeVenueSecurityFirm", adminActions.adminVenueSecurityFirmRemoveRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminVenueSecurityFirmRemoveRequestEpic", adminActions.adminVenueSecurityFirmRemoveRequestFailure))
    );

const adminVenueSecurityFirmRemoveRequestSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_VENUESECURITYFIRM_REMOVE_REQUEST_SUCCESS),
        withLatestFrom(state$),
        mergeMap(([action, state]) => of(adminActions.adminVenueAssociationsRequest(state.admin.venues.venueId)))
    );

const adminVenueSecurityFirmDescendantRemoveRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_VENUESECURITYFIRMDESCENDANT_REMOVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueServiceApi.removeSecurityFirmDescendant(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId, action['securityFirmDescendantId'])
                .pipe(
                    map(response => adminActions.adminVenueSecurityFirmDescendantRemoveRequestSuccess()),
                    catchError(error => notifyError(error, "adminVenueSecurityFirmDescendantRemoveRequestEpic.removeSecurityFirmDescendant", adminActions.adminVenueSecurityFirmDescendantRemoveRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminVenueSecurityFirmDescendantRemoveRequestEpic", adminActions.adminVenueSecurityFirmDescendantRemoveRequestFailure))
    );

const adminVenueSecurityFirmDescendantRemoveRequestSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_VENUESECURITYFIRMDESCENDANT_REMOVE_REQUEST_SUCCESS),
        withLatestFrom(state$),
        mergeMap(([action, state]) => of(adminActions.adminVenueAssociationsRequest(state.admin.venues.venueId)))
    );

const adminWebsitePropertiesRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_WEBSITE_PROPERTIES_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            websitePropertiesApi.get(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId)
                .pipe(
                    map(response => adminActions.adminWebsitePropertiesRequestSuccess(response.websiteProperties)),
                    catchError(error => notifyError(error, "adminWebsitePropertiesRequestEpic.get", adminActions.adminWebsitePropertiesRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminWebsitePropertiesRequestEpic", adminActions.adminWebsitePropertiesRequestFailure))
    );

const adminWebsitePropertiesSaveRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_WEBSITE_PROPERTIES_SAVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            websitePropertiesApi.save(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId, action['websiteProperties'])
                .pipe(
                    map(response => adminActions.adminWebsitePropertiesSaveRequestSuccess()),
                    catchError(error => notifyError(error, "adminWebsitePropertiesSaveRequestEpic.save", adminActions.adminWebsitePropertiesSaveRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminWebsitePropertiesSaveRequestEpic", adminActions.adminWebsitePropertiesSaveRequestFailure))
    );

const adminUserChangePasswordRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_USER_CHANGE_PASSWORD_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.changePassword(action['userId'], "", action['password'], action['confirmPassword'])
                .pipe(
                    map(response => adminActions.adminUserChangePasswordRequestSuccess()),
                    catchError(error => notifyError(error, "adminUserChangePasswordRequestEpic.changePassword", adminActions.adminUserChangePasswordRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminUserChangePasswordRequestEpic", adminActions.adminUserChangePasswordRequestFailure))
    );

const adminUserChangePasswordRequestSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_USER_CHANGE_PASSWORD_REQUEST_SUCCESS),
        map((action: any) => notificationSuccessShow("User password changed"))
    );

const adminUserRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_USER_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.getUserWithObsoleteUserRolesAndDocuments(action['userId'])
                .pipe(
                    map(response => adminActions.adminUserRequestSuccess(response.user,
                        response.states,
                        response.genders,
                        response.userOtherDocuments,
                        response.userOtherDocumentTypes,
                        response.userComplianceDocuments,
                        response.userComplianceDocumentTypes,
                        response.avatar)),
                    catchError(error => notifyError(error, "adminUserRequestEpic.getUserWithObsoleteUserRolesAndDocuments", adminActions.adminUserRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminUserRequestEpic", adminActions.adminUserRequestFailure))
    );

const adminOtherDocumentsSaveEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_OTHER_DOCUMENTS_SAVE),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.saveUserOtherDocuments(action['userId'], action['documentTypeId'], action['files'])
                .pipe(
                    map(response => adminActions.adminSaveOtherDocumentsSuccess(response.userOtherDocumentsIncludingObsolete)),
                    catchError(error => notifyErrorMessage(error, "Failed to save user other documents", adminActions.adminSaveOtherDocumentsFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to save user other documents", adminActions.adminSaveOtherDocumentsFailure))
    );

const adminOtherDocumentRemoveEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_OTHER_DOCUMENT_REMOVE),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.removeUserOtherDocuments(action['userId'], action['otherDocument'])
                .pipe(
                    map(response => adminActions.adminRemoveOtherDocumentSuccess(response.userOtherDocumentsIncludingObsolete)),
                    catchError(error => notifyErrorMessage(error, "Failed to remove user other document", adminActions.adminRemoveOtherDocumentFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to remove user other document", adminActions.adminRemoveOtherDocumentFailure))
    );

const adminComplianceDocumentsSaveEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_COMPLIANCE_DOCUMENTS_SAVE),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.saveUserComplianceDocuments(action['userId'], action['documentTypeId'], action['expiryDate'], action['files'])
                .pipe(
                    map(response => adminActions.adminSaveComplianceDocumentsSuccess(response.userComplianceDocumentsIncludingObsolete)),
                    catchError(error => notifyErrorMessage(error, "Failed to save user compliance documents", adminActions.adminSaveComplianceDocumentsFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to save user compliance documents", adminActions.adminSaveComplianceDocumentsFailure))
    );

const adminComplianceDocumentRemoveEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_COMPLIANCE_DOCUMENT_REMOVE),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.removeUserComplianceDocuments(action['userId'], action['complianceDocument'])
                .pipe(
                    map(response => adminActions.adminRemoveComplianceDocumentSuccess(response.userComplianceDocumentsIncludingObsolete)),
                    catchError(error => notifyErrorMessage(error, "Failed to remove user compliance document", adminActions.adminRemoveComplianceDocumentFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to remove user compliance document", adminActions.adminRemoveComplianceDocumentFailure))
    );

const adminUserSaveEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_USER_SAVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.updateUser(action['user'])
                .pipe(
                    map(response => adminActions.adminUserSaveRequestSuccess(response.user)),
                    catchError(error => notifyErrorMessage(error, "Failed to save user", adminActions.adminUserSaveRequestFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to save user", adminActions.adminUserSaveRequestFailure))
    );

const adminUserSledCheckEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_USER_SLED_CHECK_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.sledCheck(action['user'])
                .pipe(
                    map(response => adminActions.adminUserSledCheckRequestSuccess(response.user)),
                    catchError(error => notifyErrorMessage(error, "Failed to check license number", adminActions.adminUserSledCheckRequestFailure))
                )
        ),
        catchError(error => notifyErrorMessage(error, "Failed to check license number", adminActions.adminUserSledCheckRequestFailure))
    );

const adminIndustryCategoriesRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_INDUSTRY_CATEGORIES_REQUEST,
            adminActions.ADMIN_INDUSTRY_CATEGORY_CREATE_REQUEST_SUCCESS,
            adminActions.ADMIN_INDUSTRY_CATEGORY_UPDATE_REQUEST_SUCCESS),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            industryCategoryApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.admin.industryCategories.paging.page,
                state.admin.industryCategories.paging.pageSize,
                state.admin.industryCategories.filter)
                .pipe(
                    map(response => adminActions.adminIndustryCategoriesSuccess(response.data, response.paging, response.filter)),
                    catchError(error => notifyError(error, "adminIndustryCategoriesRequestEpic.find", adminActions.adminIndustryCategoriesFailure))
                )
        ),
        catchError(error => notifyError(error, "adminIndustryCategoriesRequestEpic", adminActions.adminIndustryCategoriesFailure))
    );

const adminIndustryCategoryCreateRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_INDUSTRY_CATEGORY_CREATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            industryCategoryApi.create(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId, action['industryCategory'])
                .pipe(
                    map(response => adminActions.adminIndustryCategoryCreateRequestSuccess(response.industryCategory)),
                    catchError(error => notifyError(error, "adminIndustryCategoryCreateRequestEpic.create", adminActions.adminIndustryCategoryCreateRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminIndustryCategoryCreateRequestEpic", adminActions.adminIndustryCategoryCreateRequestFailure))
    );

const adminIndustryCategoryUpdateRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_INDUSTRY_CATEGORY_UPDATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            industryCategoryApi.update(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId, action['industryCategory'])
                .pipe(
                    map(response => adminActions.adminIndustryCategoryUpdateRequestSuccess(response.industryCategory)),
                    catchError(error => notifyError(error, "adminIndustryCategoryUpdateRequestEpic.update", adminActions.adminIndustryCategoryUpdateRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminIndustryCategoryUpdateRequestEpic", adminActions.adminIndustryCategoryUpdateRequestFailure))
    );

const adminIncidentTypesRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_INCIDENT_TYPES_REQUEST, adminActions.ADMIN_INCIDENT_TYPES_REFRESH),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentTypeApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.admin.incidentTypes.paging.page,
                state.admin.incidentTypes.paging.pageSize,
                state.admin.incidentTypes.filter)
                .pipe(
                    map(response => adminActions.adminIncidentTypesRequestSuccess(response.data, response.paging, response.filter)),
                    catchError(error => notifyError(error, "adminIncidentTypesRequestEpic.find", adminActions.adminIncidentTypesRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminIncidentTypesRequestEpic", adminActions.adminIncidentTypesRequestFailure))
    );


const adminIncidentTypeNewRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_INCIDENT_TYPE_CREATE_NEW_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentTypeApi.newEmpty(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId)
                .pipe(
                    map(response => adminActions.adminIncidentTypeCreateNewRequestSuccess(response.incidentType)),
                    catchError(error => notifyError(error, "adminIncidentTypeNewRequestEpic.newEmpty", adminActions.adminIncidentTypeCreateNewRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminIncidentTypeNewRequestEpic", adminActions.adminIncidentTypeCreateNewRequestFailure))
    );

const adminIncidentTypeUpsertRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_INCIDENT_TYPE_UPSERT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentTypeApi.addOrUpdate(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId, action['incidentType'])
                .pipe(
                    map(response => adminActions.adminIncidentTypeUpdateRequestSuccess(response.incidentType)),
                    catchError(error => notifyError(error, "adminIncidentTypeUpsertRequestEpic.addOrUpdate", adminActions.adminIncidentTypeUpdateRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminIncidentTypeUpsertRequestEpic", adminActions.adminIncidentTypeUpdateRequestFailure))
    );

const adminIncidentTypeUpsertRequestSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_INCIDENT_TYPE_UPSERT_REQUEST_SUCCESS),
        withLatestFrom(state$),
        map((action: any) => adminActions.adminIncidentTypesRefresh())
    );

const adminIncidentFlagTypesRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_INCIDENT_FLAG_TYPES_REQUEST,
            adminActions.ADMIN_INCIDENT_FLAG_TYPE_UPSERT_REQUEST_SUCCESS),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentFlagTypeApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.admin.incidentFlagTypes.paging.page,
                state.admin.incidentFlagTypes.paging.pageSize,
                state.admin.incidentFlagTypes.filter)
                .pipe(
                    map(response => adminActions.adminIncidentFlagTypesRequestSuccess(response.data, response.paging, response.filter)),
                    catchError(error => notifyError(error, "adminIncidentFlagTypesRequestEpic.find", adminActions.adminIncidentFlagTypesRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminIncidentFlagTypesRequestEpic", adminActions.adminIncidentFlagTypesRequestFailure))
    );


const adminIncidentFlagTypeNewRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_INCIDENT_FLAG_TYPE_CREATE_NEW_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentFlagTypeApi.newEmpty(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId)
                .pipe(
                    map(response => adminActions.adminIncidentFlagTypeCreateNewRequestSuccess(response.incidentFlagType)),
                    catchError(error => notifyError(error, "adminIncidentFlagTypeNewRequestEpic.newEmpty", adminActions.adminIncidentFlagTypeCreateNewRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminIncidentFlagTypeNewRequestEpic", adminActions.adminIncidentFlagTypeCreateNewRequestFailure))
    );

const adminIncidentFlagTypeUpsertRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_INCIDENT_FLAG_TYPE_UPSERT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentFlagTypeApi.addOrUpdate(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId, action['incidentFlagType'])
                .pipe(
                    map(response => adminActions.adminIncidentFlagTypeUpdateRequestSuccess(response.incidentFlagType)),
                    catchError(error => notifyError(error, "adminIncidentFlagTypeUpsertRequestEpic.addOrUpdate", adminActions.adminIncidentFlagTypeUpdateRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminIncidentFlagTypeUpsertRequestEpic", adminActions.adminIncidentFlagTypeUpdateRequestFailure))
    );

const adminIncidentCategoryTypesRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_INCIDENT_CATEGORY_TYPES_REQUEST,
            adminActions.ADMIN_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST_SUCCESS
        ),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentCategoryTypeApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.admin.incidentCategoryTypes.paging.page,
                state.admin.incidentCategoryTypes.paging.pageSize,
                state.admin.incidentCategoryTypes.filter)
                .pipe(
                    map(response => adminActions.adminIncidentCategoryTypesRequestSuccess(response.data, response.paging, response.filter, response.states)),
                    catchError(error => notifyError(error, "adminIncidentCategoryTypesRequestEpic.find", adminActions.adminIncidentCategoryTypesRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminIncidentCategoryTypesRequestEpic", adminActions.adminIncidentCategoryTypesRequestFailure))
    );

const adminIncidentCategoryTypeNewRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_INCIDENT_CATEGORY_TYPE_CREATE_NEW_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentCategoryTypeApi.newEmpty(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId, 0, 0)
                .pipe(
                    map(response => adminActions.adminIncidentCategoryTypeCreateNewRequestSuccess(response.incidentCategoryType)),
                    catchError(error => notifyError(error, "adminIncidentCategoryTypeNewRequestEpic.newEmpty", adminActions.adminIncidentCategoryTypeCreateNewRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminIncidentCategoryTypeNewRequestEpic", adminActions.adminIncidentCategoryTypeCreateNewRequestFailure))
    );

const adminIncidentCategoryTypeUpsertRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_INCIDENT_CATEGORY_TYPE_UPSERT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentCategoryTypeApi.addOrUpdate(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId, action['incidentCategoryType'])
                .pipe(
                    map(response => adminActions.adminIncidentCategoryTypeUpdateRequestSuccess(response.incidentCategoryType)),
                    catchError(error => notifyError(error, "adminIncidentCategoryTypeUpsertRequestEpic.addOrUpdate", adminActions.adminIncidentCategoryTypeUpdateRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminIncidentCategoryTypeUpsertRequestEpic", adminActions.adminIncidentCategoryTypeUpdateRequestFailure))
    );

const adminVenueLocationsRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_VENUE_LOCATIONS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueLocationServiceApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['page'],
                action['pageSize'],
                action['filter'])
                .pipe(
                    map(response => adminActions.adminVenueLocationsRequestSuccess(response.data, response.paging, response.filter)),
                    catchError(error => notifyError(error, "adminVenueLocationsRequestEpic.find", adminActions.adminVenueLocationsRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminVenueLocationsRequestEpic", adminActions.adminVenueLocationsRequestFailure))
    );

const adminVenueLocationsRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_VENUE_LOCATIONS_REFRESH),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueLocationServiceApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.admin.venueLocations.paging.page,
                state.admin.venueLocations.paging.pageSize,
                state.admin.venueLocations.filter)
                .pipe(
                    map(response => adminActions.adminVenueLocationsRequestSuccess(response.data, response.paging, response.filter)),
                    catchError(error => notifyError(error, "adminVenueLocationsRefreshEpic.find", adminActions.adminVenueLocationsRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminVenueLocationsRefreshEpic", adminActions.adminVenueLocationsRequestFailure))
    );

const adminVenueLocationRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_VENUE_LOCATION_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueLocationServiceApi.get(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['venueLocationId'])
                .pipe(
                    map(response => adminActions.adminVenueLocationRequestSuccess(response.venueLocation)),
                    catchError(error => notifyError(error, "adminVenueLocationRequestEpic.get", adminActions.adminVenueLocationRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminVenueLocationRequestEpic", adminActions.adminVenueLocationRequestFailure))
    );

const adminVenueLocationCreateEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_VENUE_LOCATION_CREATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueLocationServiceApi.create(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                null)
                .pipe(
                    map(response => adminActions.adminVenueLocationCreateRequestSuccess(response.venueLocation)),
                    catchError(error => notifyError(error, "adminVenueLocationCreateEpic.create", adminActions.adminVenueLocationCreateRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminVenueLocationCreateEpic", adminActions.adminVenueLocationCreateRequestFailure))
    );

const adminVenueLocationUpsertEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_VENUE_LOCATION_UPSERT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueLocationServiceApi.upsert(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.admin.venueLocation.venueLocation)
                .pipe(
                    map(response => adminActions.adminVenueLocationUpsertRequestSuccess(response.venueLocation)),
                    catchError(error => notifyError(error, "adminVenueLocationUpsertEpic.upsert", adminActions.adminVenueLocationUpsertRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminVenueLocationUpsertEpic", adminActions.adminVenueLocationUpsertRequestFailure))
    );

const adminVenueLocationUpsertSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_VENUE_LOCATION_UPSERT_REQUEST_SUCCESS),
        map((action: any) => adminActions.adminVenueLocationReset())
    );

const adminVenueLocationUpsertSuccessRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_VENUE_LOCATION_UPSERT_REQUEST_SUCCESS),
        map((action: any) => adminActions.adminVenueLocationsRefresh())
    );

const adminVenueObsoleteRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_VENUE_OBSOLETE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueServiceApi.obsolete(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['venueToRemoveId'])
                .pipe(
                    map(response => adminActions.adminVenueObsoleteRequestSuccess()),
                    catchError(error => notifyError(error, "adminVenueObsoleteRequestEpic.obsolete", adminActions.adminVenueObsoleteRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminVenueObsoleteRequestEpic", adminActions.adminVenueObsoleteRequestFailure))
    );

const adminVenueObsoleteRequestSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_VENUE_OBSOLETE_REQUEST_SUCCESS),
        map((action: any) => adminActions.adminVenuesRefreshRequest())
    );

const adminVenueDeleteRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_VENUE_DELETE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueServiceApi.delete(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['venueToDeleteId'])
                .pipe(
                    map(response => adminActions.adminVenueDeleteRequestSuccess()),
                    catchError(error => notifyError(error, "adminVenueDeleteRequestEpic.delete", adminActions.adminVenueDeleteRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminVenueDeleteRequestEpic", adminActions.adminVenueDeleteRequestFailure))
    );

const adminVenueDeleteRequestSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_VENUE_DELETE_REQUEST_SUCCESS),
        map((action: any) => adminActions.adminVenuesRefreshRequest())
    );

const adminVenueExportRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_VENUE_EXPORT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueServiceApi.export(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.admin.venues.filter)
                .pipe(
                    map(response => adminActions.adminVenueExportRequestSuccess()),
                    catchError(error => notifyError(error, "adminVenueExportRequestEpic.export", adminActions.adminVenueExportRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminVenueExportRequestEpic", adminActions.adminVenueExportRequestFailure))
    );

const adminVenueExportRequestSuccessEpic = action$ =>
    action$.pipe(
        ofType(adminActions.ADMIN_VENUE_EXPORT_REQUEST_SUCCESS),
        map(action => printQueueRequest())
    );

const adminVenueSubscriptionRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_VENUE_SUBSCRIPTION_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueSubscriptionServiceApi.get(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['venueId'])
                .pipe(
                    map(response => adminActions.adminVenueSubscriptionRequestSuccess(response.venueSubscription, response.industryCategories)),
                    catchError(error => notifyError(error, "adminVenueSubscriptionRequestEpic.get", adminActions.adminVenueSubscriptionRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminVenueSubscriptionRequestEpic", adminActions.adminVenueSubscriptionRequestFailure))
    );

const adminVenueSubscriptionSaveRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_VENUE_SUBSCRIPTION_SAVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueSubscriptionServiceApi.save(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.admin.venueSubscription.venueSubscription)
                .pipe(
                    map(response => adminActions.adminVenueSubscriptionSaveRequestSuccess(response.venueSubscription)),
                    catchError(error => notifyError(error, "adminVenueSubscriptionSaveRequestEpic.save", adminActions.adminVenueSubscriptionSaveRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminVenueSubscriptionSaveRequestEpic", adminActions.adminVenueSubscriptionSaveRequestFailure))
    );

const adminDocumentTypesRequestAndRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_DOCUMENT_TYPES_REQUEST, adminActions.ADMIN_DOCUMENT_TYPES_REFRESH),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            documentTypeServiceApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.admin.documentTypes.paging.page,
                state.admin.documentTypes.paging.pageSize,
                state.admin.documentTypes.filter)
                .pipe(
                    map(response => adminActions.adminDocumentTypesRequestSuccess(response.data, response.paging, response.filter)),
                    catchError(error => notifyError(error, "adminDocumentTypesRequestAndRefreshEpic.find", adminActions.adminDocumentTypesRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminDocumentTypesRequestAndRefreshEpic", adminActions.adminDocumentTypesRequestFailure))
    );

const adminDocumentTypeRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_DOCUMENT_TYPE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            documentTypeServiceApi.get(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['documentTypeId'])
                .pipe(
                    map(response => adminActions.adminDocumentTypeRequestSuccess(response.documentType, response.documentCategories, response.entitieTypes)),
                    catchError(error => notifyError(error, "adminDocumentTypeRequestEpic.get", adminActions.adminDocumentTypeRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminDocumentTypeRequestEpic", adminActions.adminDocumentTypeRequestFailure))
    );

const adminDocumentTypeCreateEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_DOCUMENT_TYPE_CREATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            documentTypeServiceApi.create(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId)
                .pipe(
                    map(response => adminActions.adminDocumentTypeCreateRequestSuccess(response.documentType, response.documentCategories, response.entitieTypes)),
                    catchError(error => notifyError(error, "adminDocumentTypeCreateEpic.create", adminActions.adminDocumentTypeCreateRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminDocumentTypeCreateEpic", adminActions.adminDocumentTypeCreateRequestFailure))
    );

const adminDocumentTypeUpsertEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_DOCUMENT_TYPE_UPSERT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            documentTypeServiceApi.upsert(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.admin.documentType.documentType)
                .pipe(
                    map(response => adminActions.adminDocumentTypeUpsertRequestSuccess(response.documentType)),
                    catchError(error => notifyError(error, "adminDocumentTypeUpsertEpic.upsert", adminActions.adminDocumentTypeUpsertRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminDocumentTypeUpsertEpic", adminActions.adminDocumentTypeUpsertRequestFailure))
    );

const adminDocumentTypeUpsertSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_DOCUMENT_TYPE_UPSERT_REQUEST_SUCCESS),
        map((action: any) => adminActions.adminDocumentTypeReset())
    );

const adminDocumentTypeUpsertSuccessRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_DOCUMENT_TYPE_UPSERT_REQUEST_SUCCESS),
        map((action: any) => adminActions.adminDocumentTypesRefresh())
    );

const adminSecurityFirmsRefreshRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_SECURITY_FIRMS_REQUEST, adminActions.ADMIN_SECURITY_FIRMS_REFRESH_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            securityFirmServiceApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.admin.securityFirms.paging.page,
                state.admin.securityFirms.paging.pageSize,
                state.admin.securityFirms.filter)
                .pipe(
                    map(response => adminActions.adminSecurityFirmsRequestSuccess(response.securityFirms, response.paging, response.filter)),
                    catchError(error => notifyError(error, "adminSecurityFirmsRefreshRequestEpic.find", adminActions.adminSecurityFirmsRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminSecurityFirmsRefreshRequestEpic", adminActions.adminSecurityFirmsRequestFailure))
    );

const adminSecurityFirmSubscriptionRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_SECURITY_FIRM_SUBSCRIPTION_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            securityFirmSubscriptionServiceApi.get(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['securityFirmId'])
                .pipe(
                    map(response => adminActions.adminSecurityFirmSubscriptionRequestSuccess(response.securityFirmSubscription)),
                    catchError(error => notifyError(error, "adminSecurityFirmSubscriptionRequestEpic.get", adminActions.adminSecurityFirmSubscriptionRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminSecurityFirmSubscriptionRequestEpic", adminActions.adminSecurityFirmSubscriptionRequestFailure))
    );

const adminSecurityFirmSubscriptionSaveRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_SECURITY_FIRM_SUBSCRIPTION_SAVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            securityFirmSubscriptionServiceApi.save(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.admin.securityFirmSubscription.securityFirmSubscription)
                .pipe(
                    map(response => adminActions.adminSecurityFirmSubscriptionSaveRequestSuccess(response.securityFirmSubscription)),
                    catchError(error => notifyError(error, "adminSecurityFirmSubscriptionSaveRequestEpic.save", adminActions.adminSecurityFirmSubscriptionSaveRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminSecurityFirmSubscriptionSaveRequestEpic", adminActions.adminSecurityFirmSubscriptionSaveRequestFailure))
    );

const userImpersonateEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_USER_IMPERSONATE),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            loginServiceApi.impersonate(state.location.found, state.location.latitude, state.location.longitude, action['userId'])
                .pipe(
                    map(response => adminActions.adminUserImpersonateSuccess(response.userDetailSession)),
                    catchError(error => of(
                        userLogoutRequest(),
                        notificationShow("Impersonate failed")
                    ))
                )
        ),
        catchError(error => of(
            userLogoutRequest(),
            notificationShow("Impersonate failed")
        ))
    );

const userImpersonateSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_USER_IMPERSONATE_SUCCESS),
        map((action: any) => userDetailsRefresh())
    );

const userImpersonateSuccessStatisticsEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_USER_IMPERSONATE_SUCCESS),
        map((action: any) => userVenueSecurityFirmLoadStatistics())
    );

const adminSecurityFirmExportRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_SECURITY_FIRM_EXPORT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            securityFirmServiceApi.export(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.admin.securityFirms.filter)
                .pipe(
                    map(response => adminActions.adminSecurityFirmExportRequestSuccess()),
                    catchError(error => notifyError(error, "adminSecurityFirmExportRequestEpic.export", adminActions.adminSecurityFirmExportRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminSecurityFirmExportRequestEpic", adminActions.adminSecurityFirmExportRequestFailure))
    );

const adminSecurityFirmExportRequestSuccessEpic = action$ =>
    action$.pipe(
        ofType(adminActions.ADMIN_SECURITY_FIRM_EXPORT_REQUEST_SUCCESS),
        map(action => printQueueRequest())
    );

const adminSecurityFirmObsoleteRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_SECURITY_FIRM_OBSOLETE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            securityFirmServiceApi.obsolete(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['securityFirmToRemoveId'])
                .pipe(
                    map(response => adminActions.adminSecurityFirmObsoleteRequestSuccess()),
                    catchError(error => notifyError(error, "adminSecurityFirmObsoleteRequestEpic.obsolete", adminActions.adminSecurityFirmObsoleteRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminSecurityFirmObsoleteRequestEpic", adminActions.adminSecurityFirmObsoleteRequestFailure))
    );

const adminSecurityFirmObsoleteRequestSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_SECURITY_FIRM_OBSOLETE_REQUEST_SUCCESS),
        map((action: any) => adminActions.adminSecurityFirmsRefreshRequest())
    );

const adminSecurityFirmDeleteRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_SECURITY_FIRM_DELETE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            securityFirmServiceApi.delete(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['securityFirmToDeleteId'])
                .pipe(
                    map(response => adminActions.adminSecurityFirmDeleteRequestSuccess()),
                    catchError(error => notifyError(error, "adminSecurityFirmDeleteRequestEpic.delete", adminActions.adminSecurityFirmDeleteRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminSecurityFirmDeleteRequestEpic", adminActions.adminSecurityFirmDeleteRequestFailure))
    );

const adminSecurityFirmDeleteRequestSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_SECURITY_FIRM_DELETE_REQUEST_SUCCESS),
        map((action: any) => adminActions.adminSecurityFirmsRefreshRequest())
    );

const adminUserPushTestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_USER_PUSH_TEST_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            userServiceApi.pushTest(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['userId'],
                action['incidentId'])
                .pipe(
                    map(response => adminActions.adminUserPushTestRequestSuccess()),
                    catchError(error => notifyError(error, "adminUserPustTestEpic.pushTest", adminActions.adminUserPushTestRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminUserPustTestEpic", adminActions.adminUserPushTestRequestFailure))
    );

const adminUserPustTestSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_USER_PUSH_TEST_REQUEST_SUCCESS),
        map((action: any) => notificationSuccessShow('Message Sent'))
    );

const adminVenueNotificationsClearRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_NOTIFICATIONS_VENUE_CLEAR),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            notificationServiceApi.venueClear(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['forVenueId'],
                action['simpleDate'])
                .pipe(
                    map(response => notificationSuccessShow("Removed " + response.count + " notification(s)")),
                    catchError(error => notifyError(error, "adminVenueNotificationsClearRequestEpic.venueClear"))
                )
        ),
        catchError(error => notifyError(error, "adminVenueNotificationsClearRequestEpic"))
    );

const adminSecurityFirmNotificationsClearRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_NOTIFICATIONS_SECURITY_FIRM_CLEAR),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            notificationServiceApi.securityFirmClear(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['forSecurityFirmId'],
                action['simpleDate'])
                .pipe(
                    map(response => notificationSuccessShow("Removed " + response.count + " notification(s)")),
                    catchError(error => notifyError(error, "adminSecurityFirmNotificationsClearRequestEpic.securityFirmClear"))
                )
        ),
        catchError(error => notifyError(error, "adminSecurityFirmNotificationsClearRequestEpic"))
    );

const adminVenueIncidentsClearRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_VENUE_INCIDENT_APPROVALS_CLEAR),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentServiceApi.incidentsClearApprovals(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId, 
                state.user.details.userSession.user.venueEventId, 
                action['forVenueId'],
                action['simpleDate'])
                .pipe(
                    map(response => notificationSuccessShow("Processing incident approvals in the background...")),
                    catchError(error => notifyError(error, "adminVenueIncidentsClearRequestEpic.incidentsClearApprovals"))
                )
        ),
        catchError(error => notifyError(error, "adminVenueIncidentsClearRequestEpic"))
    );

const adminVenueRegistrationDismissReqeustEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_VENUE_REGISTRATION_DISMISS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            venueServiceApi.adminDismissRegistration(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action.forVenueId)
                .pipe(
                    map(response => adminActions.adminVenueRegistratoinDismissRequestSuccess(response.venue)),
                    catchError(error => notifyError(error, "adminVenueRegistrationDismissReqeustEpic.adminDismissRegistration", adminActions.adminVenueRegistratoinDismissRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminVenueRegistrationDismissReqeustEpic", adminActions.adminVenueRegistratoinDismissRequestFailure))
    );

const adminVenueRegistrationDismissReqeustSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_VENUE_REGISTRATION_DISMISS_REQUEST_SUCCESS),
        map((action: any) => adminActions.adminVenuesRefreshRequest())
    );

const adminSecurityFirmRegistrationDismissReqeustEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_SECURITY_FIRM_REGISTRATION_DISMISS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            securityFirmServiceApi.adminDismissRegistration(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action.forSecurityFirmId)
                .pipe(
                    map(response => adminActions.adminSecurityFirmRegistratoinDismissRequestSuccess(response.securityFirm)),
                    catchError(error => notifyError(error, "adminSecurityFirmRegistrationDismissReqeustEpic.adminDismissRegistration", adminActions.adminSecurityFirmRegistratoinDismissRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminSecurityFirmRegistrationDismissReqeustEpic", adminActions.adminSecurityFirmRegistratoinDismissRequestFailure))
    );

const adminSecurityFirmRegistrationDismissReqeustSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_SECURITY_FIRM_REGISTRATION_DISMISS_REQUEST_SUCCESS),
        map((action: any) => adminActions.adminSecurityFirmsRefreshRequest())
    );

const adminWebsiteResetCacheRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_WEBSITE_RESET_CACHE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            websiteAdminApi.resetCache(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId)
                .pipe(
                    map(response => adminActions.adminWebsiteResetCacheRequestSuccess()),
                    catchError(error => notifyError(error, "adminWebsiteResetCacheRequestEpic.get", adminActions.adminWebsiteResetCacheRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminWebsiteResetCacheRequestEpic", adminActions.adminWebsiteResetCacheRequestFailure))
    );

const adminWebsiteResetCacheRequestSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_WEBSITE_RESET_CACHE_REQUEST_SUCCESS),
        map((action: any) => notificationSuccessShow('Cache reset'))
    );

const adminWebsiteImportRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_WEBSITE_IMPORT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            websiteAdminApi.import(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId)
                .pipe(
                    map(response => adminActions.adminWebsiteImportRequestSuccess()),
                    catchError(error => notifyError(error, "adminWebsiteImportRequestEpic.get", adminActions.adminWebsiteImportRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminWebsiteImportRequestEpic", adminActions.adminWebsiteImportRequestFailure))
    );

const adminWebsiteImportRequestSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_WEBSITE_IMPORT_REQUEST_SUCCESS),
        map((action: any) => notificationSuccessShow('Completed'))
    );

const adminWebsiteResetPoolRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_WEBSITE_RESET_POOL_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            websiteAdminApi.resetConnectionPool(state.user.details.userSession.user.venueId, state.user.details.userSession.user.securityFirmId)
                .pipe(
                    map(response => adminActions.adminWebsiteResetPoolRequestSuccess()),
                    catchError(error => notifyError(error, "adminWebsiteResetPoolRequestEpic.get", adminActions.adminWebsiteResetPoolRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "adminWebsiteResetCacheRequestEpic", adminActions.adminWebsiteResetCacheRequestFailure))
    );

const adminWebsiteResetPoolRequestSuccessEpic = (action$, state$) =>
    action$.pipe(
        ofType(adminActions.ADMIN_WEBSITE_RESET_POOL_REQUEST_SUCCESS),
        map((action: any) => notificationSuccessShow('Connection Pool reset'))
    );

export const adminEpics = combineEpics(
    userImpersonateEpic,
    userImpersonateSuccessEpic,
    userImpersonateSuccessStatisticsEpic,
    adminDuplicateUserMobilesRequestEpic,
    adminDuplicateUserMobileUpdateEpic,
    adminUserRolesRequestEpic,
    adminIndustryCategoriesRequestEpic,
    adminIndustryCategoryCreateRequestEpic,
    adminIndustryCategoryUpdateRequestEpic,
    adminUserActionAuditRequestEpic,
    adminUsersRequestEpic,
    adminVenuesRequestEpic,
    adminVenueAssociationsRequestEpic,
    adminVenueSecurityFirmRemoveRequestEpic,
    adminVenueSecurityFirmRemoveRequestSuccessEpic,
    adminVenueSecurityFirmDescendantRemoveRequestEpic,
    adminVenueSecurityFirmDescendantRemoveRequestSuccessEpic,
    adminWebsitePropertiesRequestEpic,
    adminWebsitePropertiesSaveRequestEpic,
    adminUserChangePasswordRequestEpic,
    adminUserChangePasswordRequestSuccessEpic,
    adminUserRequestEpic,
    adminOtherDocumentsSaveEpic,
    adminOtherDocumentRemoveEpic,
    adminComplianceDocumentsSaveEpic,
    adminComplianceDocumentRemoveEpic,
    adminUserSaveEpic,
    adminUserSledCheckEpic,
    adminUserRoleAddSaveSuccessEpic,
    adminUserRefreshEpic,
    adminUserRoleRemoveSuccessEpic,
    adminUserRoleEnableSuccessEpic,
    adminIncidentTypesRequestEpic,
    adminIncidentTypeNewRequestEpic,
    adminIncidentTypeUpsertRequestEpic,
    adminIncidentTypeUpsertRequestSuccessEpic,
    adminIncidentFlagTypesRequestEpic,
    adminIncidentFlagTypeNewRequestEpic,
    adminIncidentFlagTypeUpsertRequestEpic,
    adminIncidentCategoryTypesRequestEpic,
    adminIncidentCategoryTypeNewRequestEpic,
    adminIncidentCategoryTypeUpsertRequestEpic,
    adminVenueLocationsRefreshEpic,
    adminVenueLocationsRequestEpic,
    adminVenueLocationRequestEpic,
    adminVenueLocationCreateEpic,
    adminVenueLocationUpsertEpic,
    adminVenueLocationUpsertSuccessEpic,
    adminVenueLocationUpsertSuccessRefreshEpic,
    adminVenuesRefreshRequestEpic,
    adminVenueObsoleteRequestEpic,
    adminVenueObsoleteRequestSuccessEpic,
    adminVenueDeleteRequestEpic,
    adminVenueDeleteRequestSuccessEpic,
    adminVenueExportRequestEpic,
    adminVenueExportRequestSuccessEpic,
    adminVenueSubscriptionRequestEpic,
    adminVenueSubscriptionSaveRequestEpic,
    adminDocumentTypesRequestAndRefreshEpic,
    adminDocumentTypeRequestEpic,
    adminDocumentTypeCreateEpic,
    adminDocumentTypeUpsertEpic,
    adminDocumentTypeUpsertSuccessEpic,
    adminDocumentTypeUpsertSuccessRefreshEpic,
    adminSecurityFirmsRefreshRequestEpic,
    adminSecurityFirmSubscriptionRequestEpic,
    adminSecurityFirmSubscriptionSaveRequestEpic,
    adminSecurityFirmExportRequestEpic,
    adminSecurityFirmExportRequestSuccessEpic,
    adminSecurityFirmObsoleteRequestEpic,
    adminSecurityFirmObsoleteRequestSuccessEpic,
    adminSecurityFirmDeleteRequestEpic,
    adminSecurityFirmDeleteRequestSuccessEpic,
    adminUserPushTestEpic,
    adminUserPustTestSuccessEpic,
    adminVenueNotificationsClearRequestEpic,
    adminSecurityFirmNotificationsClearRequestEpic,
    adminVenueIncidentsClearRequestEpic,
    adminVenueRegistrationDismissReqeustEpic,
    adminVenueRegistrationDismissReqeustSuccessEpic,
    adminSecurityFirmRegistrationDismissReqeustEpic,
    adminSecurityFirmRegistrationDismissReqeustSuccessEpic,
    adminWebsiteResetCacheRequestEpic,
    adminWebsiteResetCacheRequestSuccessEpic,
    adminWebsiteResetPoolRequestEpic,
    adminWebsiteResetPoolRequestSuccessEpic,
    adminWebsiteImportRequestEpic,
    adminWebsiteImportRequestSuccessEpic
);