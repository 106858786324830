import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import FacialRecognitionPOIsContainer from '../../../containers/FacialRecognitionPOIsContainer';

export interface IVenueFacialRecognitionNotificationsWatchlistsProps {
    venueId: number;
    history: any;
    location: any;
}

class VenueFacialRecognitionPOIs extends Component<IVenueFacialRecognitionNotificationsWatchlistsProps, any> {
    
    render() {
        
        return <FacialRecognitionPOIsContainer venueId={this.props.venueId} />
    }
}

export default withStyles(styles, { withTheme: true })(VenueFacialRecognitionPOIs);