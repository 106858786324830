import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import ResponsiveList from '../../common/ResponsiveList';
import View from '../../common/View';
import FullPageDialog from '../../layout/FullPageDialog';
import ConfirmDialog from '../../controls/ConfirmDialog';
import { KeyName } from '../../../common/dto/common';
import PageLayout from '../../layout/PageLayout';
import TransparentButton from '../../controls/TransparentButton';
import SimpleFilter from '../../common/SimpleFilter';
import SimpleFilterSummary from '../../common/SimpleFilterSummary';
import ContainedParagraph from '../../common/ContainedParagraph';
import VenueEventContainer from '../../../containers/VenueEventContainer';

export interface IVenueEventsProps {
    isLoading: boolean;
    venueEvents: AusComplyDtos.VenueEvent[];
    venueEventId: number;
    paging: AusComplyDtos.Paging;
    filter: AusComplyDtos.SimpleFilter;
    canEdit: boolean;
    onLoad: Function;
    onLoadItem: Function;
    onCreate: Function;
    onResetItem: Function;
    onUpdate: Function;
    onSave: Function;
    onSelectVenueSecurityFirm: Function;
    history: any;
    location: any;
}

export default class VenueEvents extends Component<IVenueEventsProps, any> {
    constructor(props: IVenueEventsProps) {
        super(props)

        this.state = {
            filterExpanded: false
        };
        this.onCommand = this.onCommand.bind(this);
        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.removeCancel = this.removeCancel.bind(this);
        this.removeConfirmed = this.removeConfirmed.bind(this);
        this.getCommands = this.getCommands.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.filterExpanded = this.filterExpanded.bind(this);
        this.onResetSearch = this.onResetSearch.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.paging.pageSize, this.props.paging.page, this.prepareFilter(this.props.filter));
    }

    prepareFilter(filter: AusComplyDtos.SimpleFilter) {
        let preparedFilter = { ...filter };
        return preparedFilter;
    }

    handlePageSelected(page) {
        this.props.onLoad(this.props.paging.pageSize, page, this.prepareFilter(this.props.filter));
    }

    getCommands(venueEvent: AusComplyDtos.VenueEvent) {
        var commands: KeyName[] = [];
        if(this.props.canEdit) {
            commands.push({ key: "edit", name: "Edit" });
            commands.push({ key: "login", name: "Login to Event" });
        }
        return commands;
    }

    onCommand(command: string, venueEvent: AusComplyDtos.VenueEvent) {
        switch (command) {
            case "add":
                this.props.onCreate();
                break;
            case "edit":
                this.props.onLoadItem(venueEvent.venueEventId);
                break;
            case "login":
                this.props.onSelectVenueSecurityFirm(venueEvent.venueId, null, venueEvent.venueEventId)
                this.props.history.push('/');
                break;
            case "delete":
                var updated = { ...venueEvent };
                updated.obsolete = true;
                this.setState({
                    confirmRemove: true,
                    toRemove: updated
                });
                break;
            default:
                break;
        }
    }

    removeCancel() {
        this.setState({
            confirmRemove: false,
            toRemove: {}
        });
    }

    removeConfirmed() {
        if (this.props.onSave) {
            let venueEvent = { ...this.state.toRemove };
            this.props.onUpdate(venueEvent);
            this.props.onSave();
            this.setState({
                confirmRemove: false,
                toRemove: {}
            });
        }
    }

    filterExpanded(value: boolean) {
        if (this.state.filterExpanded && !value) {
            this.props.onLoad(this.props.paging.pageSize, 1, this.prepareFilter(this.props.filter));
        }
        this.setState({
            filterExpanded: value
        })
    }

    onSearch(filter: AusComplyDtos.SimpleFilter) {  
        this.props.onLoad(this.props.paging.pageSize, 1, this.prepareFilter({...filter}));     
        this.setState({
            filterExpanded: false
        });
    }

    onResetSearch() {
        this.props.onLoad(this.props.paging.pageSize, 1, this.prepareFilter(new AusComplyDtos.SimpleFilter()));
        this.setState({ filterExpanded: false });
    }

    render() {
        let self = this;
        
        let create;
        if (this.props.canEdit) {
            create = (<TransparentButton
                    onClick={() => this.onCommand("add", new AusComplyDtos.VenueEvent())}
                    text={"Add"} />);
        }

        let filter;
        let filterSummary;
        if (this.state.filterExpanded) {
            filter = <SimpleFilter
                filter={this.props.filter}
                onReset={() => self.onResetSearch()}
                onSearch={(filter) => self.onSearch(filter)}
            />;
        } else if (this.props.isLoading) {
            let simpleFilter = new AusComplyDtos.SimpleFilter();
            simpleFilter.display = "Searching...";
            filterSummary = <View style={{ marginBottom: 10 }}>
                <SimpleFilterSummary filter={simpleFilter} onClick={() => this.filterExpanded(true)} />
            </View>;
        } else {
            filterSummary = <View style={{ marginBottom: 10 }}>
                <SimpleFilterSummary filter={this.props.filter} onClick={() => this.filterExpanded(true)} />
            </View>;
        }

        return (<PageLayout
            headerText={"Events"}
            loading={this.props.isLoading}
            footerLeftContent={create}
        >
            {filterSummary}
            <ResponsiveList
                loading={this.props.isLoading}
                data={this.props.venueEvents}
                paging={this.props.paging}
                onPageSelected={(page) => this.props.onLoad(this.props.paging.pageSize, page, this.props.filter)}
                onPageSizeSelected={(page, pageSize) => this.props.onLoad(pageSize, page, this.props.filter)}
                headers={["Name", "Description"]}
                columns={["name", "description"]}
                getCommands={item => this.getCommands(item)}
                onCommand={(command, item) => this.onCommand(command, item)}
                isObsolete={item => item.obsolete}
                canEdit={true}
                onFormatValue={(column, value, data, row) => {
                    if (column == "description") {
                        return <ContainedParagraph maxLines={3} text={value} />
                    } 
                    return value;
                }}
            />
            <FullPageDialog open={this.state.filterExpanded} title="Search" onDismissed={() => this.filterExpanded(false)}>
                {filter}
            </FullPageDialog>
            <VenueEventContainer />
            <ConfirmDialog
                title={"Confirm remove"}
                text={"Are you sure you wish to remove this event?"}
                show={this.state.confirmRemove}
                onCancel={this.removeCancel}
                onConfirm={this.removeConfirmed} />
        </PageLayout>);
        
    }
}