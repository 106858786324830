import React, { Component } from 'react';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import TextAreaControlGroup from '../../controls/TextAreaControlGroup';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import RadioControlGroup from '../../controls/RadioControlGroup';
import NumericUpDownControlGroup from '../../controls/NumericUpDownControlGroup';
import ControlGroup from '../../controls/ControlGroup';
import { RadioOption } from '../../../common/dto/common';
import ChecklistTemplateItemOptions from './ChecklistTemplateItemOptions';
import ChecklistTemplateAttachmentFile from './ChecklistTemplateAttachmentFile';
import Grid from '@material-ui/core/Grid';
import { MulitSelectOption } from '../../../common/dto/common';
import NotificationTrigger from '../../notifications/NotificationTrigger';
import ChecklistTemplateItemNotificationFilter from './ChecklistTemplateItemNotificationFilter';
import Information from '../../common/Information';
import ChecklistTemplateItemFilters from './ChecklistTemplateItemFilters';


export interface IChecklistTemplateItemProps {
    venueId?: number;
    checklistTemplateItem: AusComplyDTOs.ChecklistTemplateItem;
    checklistTemplateItems: AusComplyDTOs.ChecklistTemplateItem[];
    notificationTrigger?: AusComplyDTOs.NotificationTrigger;
    options: AusComplyDTOs.ChecklistTemplateItemOption[];
    isIncidentAction?: boolean;
    onUpdate: Function;
    onUpdateNotificationTrigger?: Function;
}

export default class ChecklistTemplateItem extends Component<IChecklistTemplateItemProps, any> {
    constructor(props: IChecklistTemplateItemProps) {
        super(props)
        this.state = {
            userRoleTypes: []
        }
        this.onChange = this.onChange.bind(this);
        this.onChangeAttachment = this.onChangeAttachment.bind(this);
        this.onRadioChanged = this.onRadioChanged.bind(this);
        this.onUserRoleTypesChanged = this.onUserRoleTypesChanged.bind(this);
        this.setUserRoleTypes = this.setUserRoleTypes.bind(this);
        this.onChangeFile = this.onChangeFile.bind(this);
    }

    componentDidMount() {
        if (this.props.checklistTemplateItem && this.props.checklistTemplateItem.userRoleTypeSelections) {
            let includeSecurity = true;
            if (!this.props.checklistTemplateItem.includeAssociatedUsers) {
                includeSecurity = false;
            }
            this.setUserRoleTypes(includeSecurity);
        }
    }

    componentDidUpdate(prevProps) {
    }

    setUserRoleTypes(includeSecurity: boolean) {
        let excludeSecurity = false;
        if (this.props.venueId && this.props.venueId > 0 && !includeSecurity) {
            excludeSecurity = true;
        }
        let userRoleTypes: MulitSelectOption[] = [];
        if (this.props.checklistTemplateItem.userRoleTypeSelections) {
            this.props.checklistTemplateItem.userRoleTypeSelections.forEach((item, index) => {
                if (item.security && excludeSecurity) {
                    // dont include it
                } else {
                    let userRoleType = new MulitSelectOption();
                    userRoleType.id = item.userRoleTypeId;
                    userRoleType.name = item.name;
                    userRoleType.selected = item.selected;
                    userRoleType.isCommon = false;
                    userRoleTypes.push(userRoleType);
                }
            });
        }

        this.setState({
            userRoleTypes
        });
    }

    onOptionsChanged(options) {
        let checklistTemplateItem = { ...this.props.checklistTemplateItem };
        checklistTemplateItem.checklistTemplateItemOptions = [...options];
        // console .log('onOptionsChanged', checklistTemplateItem);
        this.props.onUpdate(checklistTemplateItem);
    }

    onChange(fieldname, value) {
        // console .log('onChange', fieldname, value);
        let checklistTemplateItem = { ...this.props.checklistTemplateItem };
        checklistTemplateItem[fieldname] = value;
        if (fieldname == "includeAssociatedUsers" && value == true) {
            checklistTemplateItem.onlySignedOn = true;
        }
        if (fieldname == "enableInstanceAttachments" && value == false) {
            checklistTemplateItem.requiredAttachmentCount = 0;
        }
        this.props.onUpdate(checklistTemplateItem);
        if (fieldname == "includeAssociatedUsers") {
            this.setUserRoleTypes(value);
        }
    }

    onChangeAttachment(file: AusComplyDTOs.File, attachment: AusComplyDTOs.Attachment) {
        let checklistTemplateItem = { ...this.props.checklistTemplateItem };
        //checklistTemplateItem.file = file;
        checklistTemplateItem.attachment = attachment;
        this.props.onUpdate(checklistTemplateItem);
    }

    onChangeFile(file: AusComplyDTOs.File) {
        let checklistTemplateItem = { ...this.props.checklistTemplateItem };
        checklistTemplateItem.file = file;
        this.props.onUpdate(checklistTemplateItem);
    }


    onChangeMulti(fields: { fieldName: string, value: any }[]) {
        let checklistTemplateItem = { ...this.props.checklistTemplateItem };
        fields.forEach(field => {
            checklistTemplateItem[field.fieldName] = field.value;
        })
        this.props.onUpdate(checklistTemplateItem);
    }

    onRadioChanged(value) {
        if (value && value.value) {
            const fieldValue = value.value;

            if (fieldValue == 'EDIT') {
                this.onChangeMulti([
                    { fieldName: 'onCreate', value: false },
                    { fieldName: 'onEdit', value: true }
                ]);
            } else if (fieldValue == 'BOTH') {
                this.onChangeMulti([
                    { fieldName: 'onCreate', value: true },
                    { fieldName: 'onEdit', value: true }
                ]);
            }
        }
    }

    onUserRoleTypesChanged(value) {
        let userRoleTypes = [...this.state.userRoleTypes];
        userRoleTypes.forEach((item, index) => {
            item.selected = value.filter(s => s.id === item.id).length > 0;
        });

        this.setState({
            userRoleTypes
        });

        let checklistTemplateItem = { ...this.props.checklistTemplateItem };
        if (checklistTemplateItem.userRoleTypeSelections) {
            checklistTemplateItem.userRoleTypeSelections.forEach((item, index) => {
                item.selected = this.state.userRoleTypes.filter(s => s.id === item.userRoleTypeId && s.selected).length > 0;
            });
        }
        this.props.onUpdate(checklistTemplateItem)
    }

    render() {
        const radioOptions: RadioOption[] = [
            { id: "EDIT", name: "Question on follow-up only" },
            { id: "BOTH", name: "Question on creation" }
        ];

        // For questions that are still set to 'CREATE', make BOTH by default
        const selectedValue =
            this.props.checklistTemplateItem.onCreate && !this.props.checklistTemplateItem.onEdit ? 'BOTH'
                : !this.props.checklistTemplateItem.onCreate && this.props.checklistTemplateItem.onEdit ? 'EDIT'
                    : this.props.checklistTemplateItem.onCreate && this.props.checklistTemplateItem.onEdit ? 'BOTH'
                        : 'ANY';

        let showNotification: boolean = false;
        let notificationFilter: any;
        if (this.props.notificationTrigger && this.props.checklistTemplateItem.checklistItemType !== undefined) {
            showNotification = true;
            if (this.props.checklistTemplateItem.checklistItemType === AusComplyDTOs.ChecklistItemType.Checkbox ||
                this.props.checklistTemplateItem.checklistItemType === AusComplyDTOs.ChecklistItemType.Question ||
                this.props.checklistTemplateItem.checklistItemType === AusComplyDTOs.ChecklistItemType.Selection ||
                this.props.checklistTemplateItem.checklistItemType === AusComplyDTOs.ChecklistItemType.MultipleChoice) {
                notificationFilter = <ChecklistTemplateItemNotificationFilter
                    checklistTemplateItem={this.props.checklistTemplateItem}
                    filter={this.props.notificationTrigger.filter}
                    options={this.props.checklistTemplateItem.checklistTemplateItemOptions}
                    onUpdate={(value) => {
                        let notificationTrigger = { ...this.props.notificationTrigger };
                        notificationTrigger.filter = value;
                        if (this.props.onUpdateNotificationTrigger) {
                            this.props.onUpdateNotificationTrigger(notificationTrigger);
                        }
                    }}
                />
            } else {
                notificationFilter = <Information message={"Notification will trigger on any value entered"} />
            }
        }

        let requiredAttachmentCount = 0;
        if (this.props.checklistTemplateItem.requiredAttachmentCount && this.props.checklistTemplateItem.requiredAttachmentCount > 0) {
            requiredAttachmentCount = this.props.checklistTemplateItem.requiredAttachmentCount;
        }

        let readonlyControl = false;
        if (this.props.checklistTemplateItem.checklistItemType == AusComplyDTOs.ChecklistItemType.Group ||
            this.props.checklistTemplateItem.checklistItemType == AusComplyDTOs.ChecklistItemType.Information
        ) {
            readonlyControl = true;
        }

        return (
            <>
                <CardContainer
                    title={"Edit " + this.props.checklistTemplateItem.checklistItemType.toString()}>
                    <CardContent>
                        <Grid container>
                            <Grid item xs={9}>
                                <TextAreaControlGroup
                                    text={"Text"}
                                    defaultValue={this.props.checklistTemplateItem.name}
                                    onBlur={(value) => this.onChange("name", value)} />
                                <TextAreaControlGroup
                                    text={"Description"}
                                    defaultValue={this.props.checklistTemplateItem.description}
                                    onBlur={(value) => this.onChange("description", value)} />
                                {!readonlyControl && (<>
                                    <CheckboxControlGroup
                                        label={" "}
                                        text={"Make this field mandatory"}
                                        defaultValue={this.props.checklistTemplateItem.mandatory}
                                        onChanged={(value) => this.onChange("mandatory", value)} />
                                    <CheckboxControlGroup
                                        label={" "}
                                        text={"Allow attachments when filling out checklist"}
                                        defaultValue={this.props.checklistTemplateItem.enableInstanceAttachments}
                                        onChanged={(value) => this.onChange("enableInstanceAttachments", value)} />
                                    {this.props.checklistTemplateItem.enableInstanceAttachments && <>
                                        <NumericUpDownControlGroup
                                            text={"Required number of attachments"}
                                            value={requiredAttachmentCount}
                                            onChange={(value) => this.onChange("requiredAttachmentCount", value)} />
                                    </>}
                                </>)}
                                {this.props.isIncidentAction && (
                                    <>
                                        <RadioControlGroup
                                            selected={selectedValue}
                                            text={"Visibility"}
                                            name="incidentAction"
                                            options={radioOptions}
                                            onChanged={this.onRadioChanged} />
                                    </>
                                )}
                            </Grid>
                            <Grid item xs={3} style={{ textAlign: 'right' }}>
                                <ChecklistTemplateAttachmentFile
                                    onUpdate={this.onChangeFile}
                                    file={this.props.checklistTemplateItem.file} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardContainer>
                <ChecklistTemplateItemOptions
                    checklistTemplateItem={this.props.checklistTemplateItem}
                    options={this.props.options}
                    onUpdate={options => this.onOptionsChanged(options)}
                />
                <ChecklistTemplateItemFilters
                    checklistTemplateItem={this.props.checklistTemplateItem}
                    checklistTemplateItems={this.props.checklistTemplateItems}
                    onUpdate={value => this.props.onUpdate(value)}
                />
                {
                    (!readonlyControl && this.props.notificationTrigger && showNotification) && <NotificationTrigger
                        notificationTrigger={this.props.notificationTrigger}
                        onUpdate={(value) => {
                            if (this.props.onUpdateNotificationTrigger) {
                                this.props.onUpdateNotificationTrigger(value);
                            }
                        }}
                        filter={notificationFilter}
                    />
                }
            </>
        );
    }
}