import React from 'react';
import { AusComplyJsonServiceClient } from "../../common/services/AusComplyJsonServiceClient";
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import { fileServiceApi } from '../../common/services/fileService';
import { map, catchError, mergeMap, withLatestFrom, filter, debounce } from 'rxjs/operators';
import Error from '../common/Error';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import Progressing from '../controls/Progressing';
import Saving from '../common/Saving';
import TransparentButton from '../controls/TransparentButton';
import UploadIcon from '../../resources/UploadIcon';
import PaperclipIcon from '../../resources/PaperclipIcon';
import CameraIcon from '../../resources/CameraIcon';
import { FILE_UPLOAD_MAX_SIZE_MB } from '../../utilities/constants';
import Thumbnail from '../common/Thumbnail';
import Box from '@material-ui/core/Box';
import CameraAltIcon from '../../resources/CameraAltIcon';
import NavigationButton from '../controls/NavigationButton';

interface IFileUploadProps {
    theme: any;
    parentTypeId?: number;
    parentId?: number;
    isUploading?: boolean;
    onUploadFiles?: Function;
    onFileWasUploaded?: Function;
    buttonModeIcon?: any;
    hasFiles?: boolean;
    alternateIcons?: boolean;
    alternateContent?: any;
    imageIcon?: boolean;
    altText?: string;
    imagePrefix?: string;
    requestCamera?: boolean;
    justifyContent?: string;
    navigationButton?: boolean;
    storageFolder?: string;
    entityType?: AusComplyDtos.ngtEntityType;
}

class FileUpload extends React.Component<IFileUploadProps, any> {

    constructor(props: IFileUploadProps) {
        super(props);
        this.state = {
            uploading: false,
            selectedfiles: [],
            uploadingFiles: [],
            files: [],
            error: "",
            count: 0
        };
        this.onChange = this.onChange.bind(this);
        this.onDragEnter = this.onDragEnter.bind(this);
        this.onDragLeave = this.onDragLeave.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.removeFile = this.removeFile.bind(this);
        this.isImage = this.isImage.bind(this);
        this.onUpload = this.onUpload.bind(this);
        this.uploadSuccess = this.uploadSuccess.bind(this);
        this.uploadError = this.uploadError.bind(this);
        this.removeSelectedImage = this.removeSelectedImage.bind(this);
        this.triggerInputFile = this.triggerInputFile.bind(this);
        this.checkFileSize = this.checkFileSize.bind(this);
        this.orientate = this.orientate.bind(this);
        this.onProcessFiles = this.onProcessFiles.bind(this);
    }

    fileInput: any;

    componentDidMount() {
        this.setState({
            files: []
        })
    }

    onDragEnter(e) {
        this.setState({ active: true });
    }

    onDragLeave(e) {
        this.setState({ active: false });
    }

    onDragOver(e) {
        e.preventDefault();
    }

    onDrop(e) {
        e.preventDefault();
        const files = Array.from(e.dataTransfer.files);
        this.onProcessFiles(files);
    }

    onChange(e) {
        const files = Array.from(e.target.files);
        this.onProcessFiles(files);
        e.target.value = '';
    }

    onProcessFiles(files) {
        let newFiles = [...this.state.selectedfiles];
        let self = this;
        this.setState({ count: files.length, error: "" });
        files.forEach((file, i) => {
            if (this.checkFileSize(file)) {
                newFiles.push(file);
            }
        });
        self.setState({ selectedfiles: newFiles }, () => {
            self.onUpload();
        })
        return;
    }

    checkFileSize(file: any) {
        var filesize = ((file.size / 1024) / 1024);

        if (filesize > FILE_UPLOAD_MAX_SIZE_MB) {
            this.setState({ error: "Files must be less than " + FILE_UPLOAD_MAX_SIZE_MB + " MB" });
            return false;
        }
        return true;
    }

    orientate(file: any) {
        return file;
    }

    onUpload() {
        var self = this;
        // Move the selected files to the upload queue if not already uploading
        if (this.state.uploading || this.state.selectedfiles.length === 0) return;
        let uploadingFiles = this.state.selectedfiles;
        if (this.props.onUploadFiles) {
            // external support for uploading files, handled by the state manager

            this.props.onUploadFiles(uploadingFiles, self.props.storageFolder, self.props.entityType);
            // Clear the selected list
            this.setState({ selectedfiles: [] });
            return;
        }
        this.setState({ uploading: true, selectedfiles: [], uploadingFiles: uploadingFiles, error: "" }, () => {
            let storageFolder = "";
            let entityType = AusComplyDtos.ngtEntityType.None;
            if(self.props.storageFolder) {
                storageFolder = self.props.storageFolder;
            }
            if(self.props.entityType) {
                entityType = self.props.entityType;
            }
            fileServiceApi.uploadFiles(uploadingFiles, storageFolder, entityType).pipe(
                map(response => response.files)
            ).subscribe(
                uploadedFiles => {
                    self.uploadSuccess(uploadedFiles);
                },
                error => {
                    self.uploadError(uploadingFiles, error.responseStatus.message);
                }
            );
        });
    }

    uploadSuccess(temporaryFiles: AusComplyDtos.File[]) {
        let newFiles = this.state.files;
        if (this.props.onFileWasUploaded) {
            this.props.onFileWasUploaded(temporaryFiles);
        } else {
            temporaryFiles.forEach((file, i) => { newFiles.push(file) });
        }
        this.setState({ uploading: false, files: newFiles, uploadingFiles: [] }, () => {
            this.onUpload(); // incase files were added to the queue after uploads
        });
    }

    uploadError(uploadingFiles, errorMessage) {
        // move the files back to the selected queue - append
        this.setState({ uploading: false, selectedfiles: uploadingFiles, uploadingFiles: [], error: errorMessage });
    }

    removeFile = fileName => {
        this.setState({
            files: this.state.files.filter((file, i) => file.fileName !== fileName)
        })
    }

    removeSelectedImage = index => {
        this.setState({
            selectedfiles: this.state.selectedfiles.filter((file, i) => i !== index)
        })
    }

    isImage = file => {
        return file.type.indexOf("image") > -1 || file.name.toLowerCase().endsWith(".heic");
    }

    triggerInputFile = () => this.fileInput.click()


    render() {
        const { theme } = this.props;

        if (this.props.navigationButton) {
            return (
                <div style={{position: 'relative'}}>
                    {this.props.requestCamera && (
                        <input ref={fileInput => this.fileInput = fileInput} type="file" id="fileupload" name="fileupload" accept="image/*" capture="environment" multiple onChange={this.onChange} style={{ display: 'none' }} />
                    )}
                    {!this.props.requestCamera && (
                        <input ref={fileInput => this.fileInput = fileInput} type="file" id="fileupload" name="fileupload" multiple onChange={this.onChange} style={{ display: 'none' }} />
                    )}
                    <NavigationButton
                        onClick={this.triggerInputFile}
                        text={"Media"}
                        active={true}
                        Icon={CameraAltIcon} />
                    {(this.props.isUploading || this.state.selectedfiles.length > 0 || this.state.uploadingFiles.length > 0) && (
                        <Progressing style={{ position: 'absolute', top: '38px', left: '4px', right: '4px' }} />
                    )}
                </div>
            );
        }

        if (this.props.buttonModeIcon) {
            return (
                <>
                    {this.props.requestCamera && (
                        <input ref={fileInput => this.fileInput = fileInput} type="file" id="fileupload" name="fileupload" accept="image/*" capture="environment" multiple onChange={this.onChange} style={{ display: 'none' }} />
                    )}
                    {!this.props.requestCamera && (
                        <input ref={fileInput => this.fileInput = fileInput} type="file" id="fileupload" name="fileupload" multiple onChange={this.onChange} style={{ display: 'none' }} />
                    )}
                    <TransparentButton style={{ width: '100%', height: '100%' }} onClick={this.triggerInputFile}>
                        {this.props.buttonModeIcon}
                    </TransparentButton>
                    {(this.props.isUploading || this.state.selectedfiles.length > 0 || this.state.uploadingFiles.length > 0) && (
                        <Progressing style={{ position: 'absolute', top: '38px', left: '4px', right: '4px' }} />
                    )}
                </>
            );
        }

        let filesPreview = this.state.files.map((item, index) => {
            return (
                <Box p={0} key={"file-box-" + item.fileName + "_" + index.toString()}>
                    <Thumbnail
                        key={"file-" + item.fileName + "_" + index.toString()}
                        previewPath={item.path}
                        displayPath={item.path}
                        isImage={item.isImage}
                        text={item.originalName}
                        onRemove={() => this.removeFile(item.fileName)}
                    />
                </Box>
            );
        });

        let filesSelectedPreview = this.state.selectedfiles.map((item, index) => {
            try {
                let display = URL.createObjectURL(item);

                return (
                    <Box p={0} key={"file-box-" + item.lastModified.toString() + "_" + index.toString()}>
                        <Thumbnail
                            key={"file-" + item.lastModified.toString() + "_" + index.toString()}
                            previewPath={display}
                            displayPath={display}
                            isAbsolulePath={true}
                            isImage={this.isImage(item)}
                            text={item.originalName}
                            onRemove={() => this.removeSelectedImage(index)}
                            style={{ borderColor: '#f99a1d' }}
                        />
                    </Box>
                );
            } catch (error) {

            }
        });

        let filesUploadingPreview = this.state.uploadingFiles.map((item, index) => {
            try {
                let display = URL.createObjectURL(item);

                return (
                    <Box p={0} key={"file-box-" + item.lastModified.toString() + "_" + index.toString()}>
                        <Thumbnail
                            key={"file-" + item.lastModified.toString() + "_" + index.toString()}
                            previewPath={display}
                            displayPath={display}
                            isAbsolulePath={true}
                            isImage={this.isImage(item)}
                            text={item.originalName}
                            isLoading={true}
                            style={{ borderColor: '#42c23b' }}
                        />
                    </Box>
                );
            } catch (error) {

            }

        });

        let fileUploadingPreview;
        if (this.state.uploadingFiles && this.state.uploadingFiles.length == 1) {
            var item = this.state.uploadingFiles[0];
            let display = URL.createObjectURL(item);
            fileUploadingPreview = <Thumbnail
                key={"file-" + item.lastModified.toString() + "_" + "0".toString()}
                previewPath={display}
                displayPath={display}
                isAbsolulePath={true}
                isImage={this.isImage(item)}
                text={item.originalName}
                isLoading={true}
                style={{ borderColor: '#42c23b' }}
            />;
        }

        let dragDropStyle = theme.custom.fileUploaderDragDrop;
        if (this.state.active) {
            dragDropStyle = theme.custom.fileUploaderDragDropActive;
        }
        let display = "";
        if (this.state.count == 1) {
            display = "1 file";
        } else if (this.state.count > 1) {
            display = this.state.count.toString() + " files";
        }

        return (
            <div>
                {!this.props.alternateContent && (
                    <Box display="flex" flexDirection="row" justifyContent={this.props.justifyContent || "left"} flexWrap="wrap">
                        {filesPreview}
                        {filesUploadingPreview}
                        {filesSelectedPreview}
                    </Box>
                )}
                {(!this.props.isUploading && !this.state.isCompressing) && (
                    <label style={
                        dragDropStyle
                    }
                        onDragEnter={this.onDragEnter}
                        onDragLeave={this.onDragLeave}
                        onDragOver={this.onDragOver}
                        onDrop={this.onDrop}
                    >

                        {this.props.alternateContent && !fileUploadingPreview && (
                            <>
                                {this.props.requestCamera && (
                                    <input type="file" id="fileupload" name="fileupload" accept="image/*" capture="environment" onChange={this.onChange} ref="input" style={{ display: 'none' }} />
                                )}
                                {!this.props.requestCamera && (
                                    <input type="file" id="fileupload" name="fileupload" onChange={this.onChange} ref="input" style={{ display: 'none' }} />
                                )}
                                {this.props.alternateContent}
                            </>
                        )}
                        {this.props.alternateContent && fileUploadingPreview && (
                            <>
                                {fileUploadingPreview}
                            </>
                        )}
                        {!this.props.alternateContent && (
                            <>
                                {this.props.requestCamera && (
                                    <input type="file" id="fileupload" name="fileupload" accept="image/*" capture="environment" multiple onChange={this.onChange} ref="input" style={{ display: 'none' }} />
                                )}
                                {!this.props.requestCamera && (
                                    <input type="file" id="fileupload" name="fileupload" multiple onChange={this.onChange} ref="input" style={{ display: 'none' }} />
                                )}
                                <p style={{
                                    pointerEvents: 'none',
                                    textAlign: 'center',
                                    textTransform: 'uppercase',
                                    padding: '0',
                                    margin: '0',
                                    fontWeight: 500
                                }}>
                                    {(!this.props.alternateContent && this.state.uploadingFiles.length === 0 && this.state.selectedfiles.length === 0 && this.state.files.length === 0 && !this.props.hasFiles) && (
                                        <>
                                            {this.props.imageIcon && (
                                                <>
                                                    <CameraIcon style={{ width: '60px', height: '60px', marginBottom: '10px' }} /> <br />
                                                </>
                                            )}
                                            {(!this.props.alternateIcons && !this.props.imageIcon) && (
                                                <>
                                                    <UploadIcon style={{ width: '60px', height: '60px', marginBottom: '10px' }} /> <br />
                                                </>
                                            )}
                                            {this.props.alternateIcons && (
                                                <>
                                                    <CameraIcon style={{ width: '40px', height: '40px', marginBottom: '10px', marginRight: '30px' }} />
                                                    <PaperclipIcon style={{ width: '40px', height: '40px', marginBottom: '10px' }} />
                                                    <br />
                                                </>
                                            )}
                                            {this.props.alternateContent && (
                                                <>
                                                    {this.props.alternateContent}
                                                </>
                                            )}
                                        </>
                                    )}
                                    {!this.props.altText ? "Click or drop files here to upload" : this.props.altText}
                                </p></>)}
                    </label>
                )}
                {(this.props.isUploading) && (
                    <Progressing style={{ margin: '10px' }} message={"Uploading " + display + "..."} />
                )}
                {this.state.error && (
                    <Error message={this.state.error} />
                )}
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(FileUpload);