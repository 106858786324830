import React from 'react';
import View from '../../common/View';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDTOs from "../../../common/dto/AusComply.dtos";
import TextControlGroup from '../../controls/TextControlGroup';
import TransparentButton from '../../controls/TransparentButton';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import LayoutForm from '../../layout/LayoutForm';
import DefaultButton from '../../controls/DefaultButton';
import Box from '@material-ui/core/Box';
import PersonIcon from '../../../resources/PersonIcon';
import ListIcon from '../../../resources/ListIcon';
import SelectControlGroup from '../../controls/SelectControlGroup';
import IdCardIcon from '../../../resources/IdCardIcon';
import Loading from '../../common/Loading';
import Thumbnail from '../../common/Thumbnail';
import ImagePreviewWithProcessingContainer from '../../../containers/ImagePreviewWithProcessingContainer';

export interface IFacialRecognitionPOIScannedIDProps {
    venueId: number;
    poiGuid: string;
    poiIdScan: AusComplyDTOs.PoiIdScan;
    isLoading: boolean;
    onLoad: Function;
}

export default class FacialRecognitionPOIScannedID extends React.Component<IFacialRecognitionPOIScannedIDProps, any> {
    constructor(props: IFacialRecognitionPOIScannedIDProps) {
        super(props);

        this.state = {
            incidentDocumentId: 0,
            selectedFilePath: "",
            selectedFileName: "",
            imageText: "",
        };
        this.onSelectFilePath = this.onSelectFilePath.bind(this);
        this.onDownload = this.onDownload.bind(this);
    }

    componentDidMount() {
        this.props.onLoad(this.props.venueId, this.props.poiGuid);
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isLoading && this.props.poiGuid != prevProps.poiGuid) {
            this.props.onLoad(this.props.venueId, this.props.poiGuid);
        }
    }

    onSelectFilePath(incidentDocumentId, filePath, text) {
        this.setState({
            incidentDocumentId,
            selectedFilePath: filePath,
            selectedFileName: text
        });
    }

    onDownload(filePath) {
        // fake server request, getting the file url as response
        setTimeout(() => {
            const response = {
                file: filePath,
            };
            // server sent the url to the file!
            // now, let's download:
            window.open(response.file);
            // you could also do:
            // window.location.href = response.file;
        }, 100);
    }

    render() {
        let idScan;
        let imageText;

        if (this.props.isLoading) {
            idScan = <View>
                <Loading />
            </View>
        } else {
            idScan = <Box display="flex" flexDirection="column" justifyContent="center" p={0}
                style={{
                    position: 'relative',
                    textAlign: 'center',
                    width: '75px',
                    margin: '0 8px',
                    height: '84px',
                    borderRadius: '3px',
                    border: 'none'
                }}>
                <Box p={0}>
                </Box>
                <Box p={0}>
                    <IdCardIcon style={{ fontSize: '2.5em' }} />
                </Box>
                <Box p={0}>
                    <span style={{ fontSize: '10px' }}>Not found</span>
                </Box>
                <Box p={0}></Box>
            </Box>;
            if (this.props.poiIdScan && this.props.poiIdScan.idScan) {
                let item = this.props.poiIdScan.idScan;
                imageText = item.imageText;
                let imageTextLength = 0;
                if (item.imageText != undefined && item.imageText !== "") {
                    imageTextLength = item.imageText.length;
                }
                idScan = <Box p={0} key={"file-box-" + item.originalFilename}>
                    <Thumbnail
                        key={"file-" + item.originalFilename}
                        previewPath={item.filePreviewPath}
                        displayPath={item.fileDisplayPath}
                        isImage={item.isImage}
                        text={item.filePreviewText}
                        fullText={item.originalFilename}
                        hasImageText={imageTextLength > 0}
                        onPreview={(path, text) => this.onSelectFilePath(item.incidentDocumentId, path, text)}
                        onDownload={path => this.onDownload(path)}
                    />
                </Box>
            }
        }

        return (
            <Box display="flex" flexDirection="row" justifyContent="center">
                <Box p={0} flex={0}>
                </Box>
                <Box p={0}>
                    {idScan}
                    <ImagePreviewWithProcessingContainer
                        incidentDocumentId={this.state.incidentDocumentId}
                        filePath={this.state.selectedFilePath}
                        fileName={this.state.selectedFileName}
                        imageText={imageText}
                        saveToDocument={true}
                        onDismissed={() => this.onSelectFilePath(0, "", "")} />
                </Box>
                <Box p={0} flex={1}>
                </Box>
            </Box>
        );


    }
}