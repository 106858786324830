import EXIF from 'exif-js';

export const images = {
    compressIfImage(file){
        return new Promise((resolve, reject) => {
            if (file.type.indexOf("image") > -1) {
                try {
                    images.compress(file, 1024, 1024, (newFile) => {
                        newFile.lastModifiedDate = new Date();
                        newFile.name = file.name;
                        resolve(newFile);
                    }, () => {
                        resolve(file);
                    });
                } catch (e) {
                    resolve(file);
                }
            } else if (file.name.toLowerCase().endsWith(".heic")) {
                try {
                    images.compress(file, 1024, 1024, (newFile) => {
                        newFile.lastModifiedDate = new Date();
                        newFile.name = file.name;
                        resolve(newFile);
                    }, () => {
                        resolve(file);
                    });
                } catch (e) {
                    resolve(file);
                }
            } else {
                resolve(file);
            }
        });
    },
    compress(file, pnMaxWidth, pnMaxHeight, resolve, onError) {
        if (typeof (FileReader) !== "undefined") {
            var reader = new FileReader();
            reader.onloadend = function () {
                var tempImg = new Image();
                tempImg.src = reader.result;
                tempImg.onerror = function(error) {
                    console.log(error);
                    onError();
                }
                tempImg.onload = function () {
                    var canvas = images.getImageCanvas(this, pnMaxWidth, pnMaxHeight);
                    if (canvas) {
                        canvas.toBlob(function(blob){
                            resolve(blob);
                        }, 'image/png')
                    } else {
                        resolve(file);
                    }
                }
            }
            reader.readAsDataURL(file);
        } else {
            return resolve(file);
        }
    },
    getOrientation(poImg) {
        var orientation = 0;
        EXIF.getData(poImg, function () {
            orientation = EXIF.getTag(this, "Orientation");
        });
        return orientation;
    },
    getImageCanvas(poImg, pnMaxWidth, pnMaxHeight) {
        var canvas = null;

        try {
            var tempW = poImg.width;
            var tempH = poImg.height;

            /* Resize */
            if (tempW > tempH) {
                if (tempW > pnMaxWidth) {
                    tempH *= pnMaxWidth / tempW;
                    tempW = pnMaxWidth;
                }
            } else {
                if (tempH > pnMaxHeight) {
                    tempW *= pnMaxHeight / tempH;
                    tempH = pnMaxHeight;
                }
            }

            // Create a canvas
            canvas = document.createElement('canvas');

            // Render image to canvas
            var ctx = canvas.getContext("2d");

            var orientation = 0;
            EXIF.getData(poImg, function () {
                orientation = EXIF.getTag(this, "Orientation");
            });

            switch (orientation) {
                case 5:
                case 6:
                case 7:
                case 8:
                    canvas.width = tempH;
                    canvas.height = tempW;
                    break;
                default:
                    canvas.width = tempW;
                    canvas.height = tempH;
            }


            switch (orientation) {
                case 1:
                    // nothing
                    break;
                case 2:
                    // horizontal flip
                    ctx.translate(tempW, 0);
                    ctx.scale(-1, 1);
                    break;
                case 3:
                    // 180 rotate left
                    ctx.translate(tempW, tempH);
                    ctx.rotate(Math.PI);
                    break;
                case 4:
                    // vertical flip
                    ctx.translate(0, tempH);
                    ctx.scale(1, -1);
                    break;
                case 5:
                    // vertical flip + 90 rotate right
                    ctx.rotate(0.5 * Math.PI);
                    ctx.scale(1, -1);
                    break;
                case 6:
                    // 90 rotate right
                    ctx.rotate(0.5 * Math.PI);
                    ctx.translate(0, -tempH);
                    break;
                case 7:
                    // horizontal flip + 90 rotate right
                    ctx.rotate(0.5 * Math.PI);
                    ctx.translate(tempW, -tempH);
                    ctx.scale(-1, 1);
                    break;
                case 8:
                    // 90 rotate left
                    ctx.rotate(-0.5 * Math.PI);
                    ctx.translate(-tempW, 0);
                    break;
                default:
                    break;
            }

            ctx.drawImage(poImg, 0, 0, tempW, tempH);
        }
        catch (ex) {
            //console .log(ex.message);
        }

        // Return the resulting canvas
        return canvas;
    }
}