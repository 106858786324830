import React, { Component } from 'react';
import { styles } from '../../styles';
import { Palette } from '../../common/constants/palette';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

export interface ISectionDividerProps {
    theme: any;
    style?: any;
    text: string;
    primary?: boolean;
}

class SectionDivider extends React.PureComponent<ISectionDividerProps, any> {
    constructor(props: ISectionDividerProps) {
        super(props)
    }
    render() {
        const { theme } = this.props;
        const color:string = this.props.primary ? Palette.Primary : Palette.ControlBorder;
        const margin:string = this.props.text == "" ? "10px 0 10px 0" : "0";

        return (
            <Box p={0} display="flex" flexDirection={'row'} style={{margin: margin}}>
                <Box flexGrow={0} p={0}>
                    <p
                        style={{ ...this.props.style, textTransform: 'none', color: color, padding: 0, margin: 0 }}
                    >{this.props.text}</p>
                </Box>
                <Box flexGrow={1} p={0}>
                    <Box p={0} display="flex" flexDirection={'column'} style={{height: '100%', paddingLeft: '20px'}}>
                        <Box flexGrow={1} p={0}>

                        </Box>
                        <Box flexGrow={0} p={0} style={{borderBottom: '1px', borderBottomStyle: 'dotted', borderBottomColor: color}}>
                            
                        </Box>
                        <Box flexGrow={1} p={0}>

                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    }
}

export default withStyles(styles, { withTheme: true })(SectionDivider);