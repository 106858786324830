import { makeActionCreator } from './helpers';

export const
    FACIAL_RECOGNITION_WATCHLISTS_REQUEST = "FACIAL_RECOGNITION_WATCHLISTS_REQUEST",
    FACIAL_RECOGNITION_WATCHLISTS_REQUEST_FAILURE = "FACIAL_RECOGNITION_WATCHLISTS_REQUEST_FAILURE",
    FACIAL_RECOGNITION_WATCHLISTS_REQUEST_SUCCESS = "FACIAL_RECOGNITION_WATCHLISTS_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_WATCHLIST_REQUEST = "FACIAL_RECOGNITION_WATCHLIST_REQUEST",
    FACIAL_RECOGNITION_WATCHLIST_REQUEST_FAILURE = "FACIAL_RECOGNITION_WATCHLIST_REQUEST_FAILURE",
    FACIAL_RECOGNITION_WATCHLIST_REQUEST_SUCCESS = "FACIAL_RECOGNITION_WATCHLIST_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_WATCHLIST_CREATE = "FACIAL_RECOGNITION_WATCHLIST_CREATE",
    FACIAL_RECOGNITION_WATCHLIST_CREATE_FAILURE = "FACIAL_RECOGNITION_WATCHLIST_CREATE_FAILURE",
    FACIAL_RECOGNITION_WATCHLIST_CREATE_SUCCESS = "FACIAL_RECOGNITION_WATCHLIST_CREATE_SUCCESS",
    FACIAL_RECOGNITION_WATCHLIST_SET = "FACIAL_RECOGNITION_WATCHLIST_SET",
    FACIAL_RECOGNITION_WATCHLIST_RESET = "FACIAL_RECOGNITION_WATCHLIST_RESET",
    FACIAL_RECOGNITION_WATCHLIST_SAVE_REQUEST = "FACIAL_RECOGNITION_WATCHLIST_SAVE_REQUEST",
    FACIAL_RECOGNITION_WATCHLIST_SAVE_REQUEST_FAILURE = "FACIAL_RECOGNITION_WATCHLIST_SAVE_REQUEST_FAILURE",
    FACIAL_RECOGNITION_WATCHLIST_SAVE_REQUEST_SUCCESS = "FACIAL_RECOGNITION_WATCHLIST_SAVE_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_WATCHLIST_SYNC_REQUEST = "FACIAL_RECOGNITION_WATCHLIST_SYNC_REQUEST",
    FACIAL_RECOGNITION_WATCHLIST_SYNC_REQUEST_FAILURE = "FACIAL_RECOGNITION_WATCHLIST_SYNC_REQUEST_FAILURE",
    FACIAL_RECOGNITION_WATCHLIST_SYNC_REQUEST_SUCCESS = "FACIAL_RECOGNITION_WATCHLIST_SYNC_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_WATCHLIST_POI_SAVE_REQUEST = "FACIAL_RECOGNITION_WATCHLIST_POI_SAVE_REQUEST",
    FACIAL_RECOGNITION_WATCHLIST_POI_SAVE_REQUEST_SUCCESS = "FACIAL_RECOGNITION_WATCHLIST_POI_SAVE_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_WATCHLIST_POI_SAVE_REQUEST_FAILURE = "FACIAL_RECOGNITION_WATCHLIST_POI_SAVE_REQUEST_FAILURE",
    FACIAL_RECOGNITION_WATCHLIST_POI_SET = "FACIAL_RECOGNITION_WATCHLIST_POI_SET",
    FACIAL_RECOGNITION_WATCHLIST_POIS_RESET = "FACIAL_RECOGNITION_WATCHLIST_POIS_RESET",
    FACIAL_RECOGNITION_WATCHLIST_POIS_REQUEST = "FACIAL_RECOGNITION_WATCHLIST_POIS_REQUEST",
    FACIAL_RECOGNITION_WATCHLIST_POIS_REQUEST_FAILURE = "FACIAL_RECOGNITION_WATCHLIST_POIS_REQUEST_FAILURE",
    FACIAL_RECOGNITION_WATCHLIST_POIS_REQUEST_SUCCESS = "FACIAL_RECOGNITION_WATCHLIST_POIS_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_WATCHLIST_POIS_THUMBNAILS = "FACIAL_RECOGNITION_WATCHLIST_POIS_THUMBNAILS",
    FACIAL_RECOGNITION_WATCHLIST_POIS_REMOVE_REQUEST = "FACIAL_RECOGNITION_WATCHLIST_POIS_REMOVE_REQUEST",
    FACIAL_RECOGNITION_WATCHLIST_POIS_REMOVE_REQUEST_FAILURE = "FACIAL_RECOGNITION_WATCHLIST_POIS_REMOVE_REQUEST_FAILURE",
    FACIAL_RECOGNITION_WATCHLIST_POIS_REMOVE_REQUEST_SUCCESS = "FACIAL_RECOGNITION_WATCHLIST_POIS_REMOVE_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_WATCHLIST_NEW_IMPORT_REQUEST = "FACIAL_RECOGNITION_WATCHLIST_NEW_IMPORT_REQUEST",
    FACIAL_RECOGNITION_WATCHLIST_NEW_IMPORT_REQUEST_FAILURE = "FACIAL_RECOGNITION_WATCHLIST_NEW_IMPORT_REQUEST_FAILURE",
    FACIAL_RECOGNITION_WATCHLIST_NEW_IMPORT_REQUEST_SUCCESS = "FACIAL_RECOGNITION_WATCHLIST_NEW_IMPORT_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_WATCHLIST_IMPORT_SET = "FACIAL_RECOGNITION_WATCHLIST_IMPORT_SET",
    FACIAL_RECOGNITION_WATCHLIST_IMPORT_REQUEST = "FACIAL_RECOGNITION_WATCHLIST_IMPORT_REQUEST",
    FACIAL_RECOGNITION_WATCHLIST_IMPORT_REQUEST_FAILURE = "FACIAL_RECOGNITION_WATCHLIST_IMPORT_REQUEST_FAILURE",
    FACIAL_RECOGNITION_WATCHLIST_IMPORT_REQUEST_SUCCESS = "FACIAL_RECOGNITION_WATCHLIST_IMPORT_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_WATCHLIST_NEW_MIGRATION_REQUEST = "FACIAL_RECOGNITION_WATCHLIST_NEW_MIGRATION_REQUEST",
    FACIAL_RECOGNITION_WATCHLIST_NEW_MIGRATION_REQUEST_FAILURE = "FACIAL_RECOGNITION_WATCHLIST_NEW_MIGRATION_REQUEST_FAILURE",
    FACIAL_RECOGNITION_WATCHLIST_NEW_MIGRATION_REQUEST_SUCCESS = "FACIAL_RECOGNITION_WATCHLIST_NEW_MIGRATION_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_WATCHLIST_MIGRATION_SET = "FACIAL_RECOGNITION_WATCHLIST_MIGRATION_SET",
    FACIAL_RECOGNITION_WATCHLIST_MIGRATION_REQUEST = "FACIAL_RECOGNITION_WATCHLIST_MIGRATION_REQUEST",
    FACIAL_RECOGNITION_WATCHLIST_MIGRATION_REQUEST_FAILURE = "FACIAL_RECOGNITION_WATCHLIST_MIGRATION_REQUEST_FAILURE",
    FACIAL_RECOGNITION_WATCHLIST_MIGRATION_REQUEST_SUCCESS = "FACIAL_RECOGNITION_WATCHLIST_MIGRATION_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_WATCHLIST_MIGRATION_CANCEL_REQUEST = "FACIAL_RECOGNITION_WATCHLIST_MIGRATION_CANCEL_REQUEST",
    FACIAL_RECOGNITION_WATCHLIST_MIGRATION_CANCEL_REQUEST_FAILURE = "FACIAL_RECOGNITION_WATCHLIST_MIGRATION_CANCEL_REQUEST_FAILURE",
    FACIAL_RECOGNITION_WATCHLIST_MIGRATION_CANCEL_REQUEST_SUCCESS = "FACIAL_RECOGNITION_WATCHLIST_MIGRATION_CANCEL_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_WATCHLIST_MIGRATION_PROCESS_REQUEST = "FACIAL_RECOGNITION_WATCHLIST_MIGRATION_PROCESS_REQUEST",
    FACIAL_RECOGNITION_WATCHLIST_NEW_MOVE_REQUEST = "FACIAL_RECOGNITION_WATCHLIST_NEW_MOVE_REQUEST",
    FACIAL_RECOGNITION_WATCHLIST_NEW_MOVE_REQUEST_FAILURE = "FACIAL_RECOGNITION_WATCHLIST_NEW_MOVE_REQUEST_FAILURE",
    FACIAL_RECOGNITION_WATCHLIST_NEW_MOVE_REQUEST_SUCCESS = "FACIAL_RECOGNITION_WATCHLIST_NEW_MOVE_REQUEST_SUCCESS",
    FACIAL_RECOGNITION_WATCHLIST_MOVE_SET = "FACIAL_RECOGNITION_WATCHLIST_MOVE_SET",
    FACIAL_RECOGNITION_WATCHLIST_MOVE_REQUEST = "FACIAL_RECOGNITION_WATCHLIST_MOVE_REQUEST",
    FACIAL_RECOGNITION_WATCHLIST_MOVE_REQUEST_FAILURE = "FACIAL_RECOGNITION_WATCHLIST_MOVE_REQUEST_FAILURE",
    FACIAL_RECOGNITION_WATCHLIST_MOVE_REQUEST_SUCCESS = "FACIAL_RECOGNITION_WATCHLIST_MOVE_REQUEST_SUCCESS"
    ;

export const facialRecognitionWatchlistPoisReset = makeActionCreator(FACIAL_RECOGNITION_WATCHLIST_POIS_RESET);
export const facialRecognitionWatchlistPoisRequest = makeActionCreator(FACIAL_RECOGNITION_WATCHLIST_POIS_REQUEST, 'page', 'pageSize', 'filter');
export const facialRecognitionWatchlistPoisRequestSuccess = makeActionCreator(FACIAL_RECOGNITION_WATCHLIST_POIS_REQUEST_SUCCESS, 'facialRecognitionWatchlistPois', 'paging', 'filter', 'cameras');
export const facialRecognitionWatchlistPoisRequestFailure = makeActionCreator(FACIAL_RECOGNITION_WATCHLIST_POIS_REQUEST_FAILURE, 'error');
export const facialRecognitionWatchlistPoisRemoveRequest = makeActionCreator(FACIAL_RECOGNITION_WATCHLIST_POIS_REMOVE_REQUEST, 'facialRecognitionWatchlistId', 'poiGuid');
export const facialRecognitionWatchlistPoisRemoveRequestSuccess = makeActionCreator(FACIAL_RECOGNITION_WATCHLIST_POIS_REMOVE_REQUEST_SUCCESS);
export const facialRecognitionWatchlistPoisRemoveRequestFailure = makeActionCreator(FACIAL_RECOGNITION_WATCHLIST_POIS_REMOVE_REQUEST_FAILURE, 'error');
export const facialRecognitionWatchlistPoisThumbnails = makeActionCreator(FACIAL_RECOGNITION_WATCHLIST_POIS_THUMBNAILS, 'thumbnails');
export const facialRecognitionWatchlistPoiSaveRequest = makeActionCreator(FACIAL_RECOGNITION_WATCHLIST_POI_SAVE_REQUEST, 'page', 'pageSize', 'filter');
export const facialRecognitionWatchlistPoiSaveRequestSuccess = makeActionCreator(FACIAL_RECOGNITION_WATCHLIST_POI_SAVE_REQUEST_SUCCESS, 'facialRecognitionWatchlistPois', 'paging', 'filter');
// import
export const facialRecognitionWatchlistNewImportRequest = makeActionCreator(FACIAL_RECOGNITION_WATCHLIST_NEW_IMPORT_REQUEST, 'forVenueId', 'forGroupId');
export const facialRecognitionWatchlistNewImportRequestFailure = makeActionCreator(FACIAL_RECOGNITION_WATCHLIST_NEW_IMPORT_REQUEST_FAILURE, 'error');
export const facialRecognitionWatchlistNewImportRequestSuccess = makeActionCreator(FACIAL_RECOGNITION_WATCHLIST_NEW_IMPORT_REQUEST_SUCCESS, 'facialRecognitionWatchlistImports');
export const facialRecognitionWatchlistImportSet = makeActionCreator(FACIAL_RECOGNITION_WATCHLIST_IMPORT_SET, 'facialRecognitionWatchlistImports');
export const facialRecognitionWatchlistImportRequest = makeActionCreator(FACIAL_RECOGNITION_WATCHLIST_IMPORT_REQUEST);
export const facialRecognitionWatchlistImportRequestFailure = makeActionCreator(FACIAL_RECOGNITION_WATCHLIST_IMPORT_REQUEST_FAILURE, 'error');
export const facialRecognitionWatchlistImportRequestSuccess = makeActionCreator(FACIAL_RECOGNITION_WATCHLIST_IMPORT_REQUEST_SUCCESS, 'facialRecognitionWatchlistImports');
// migration
export const facialRecognitionWatchlistNewMigrationRequest = makeActionCreator(FACIAL_RECOGNITION_WATCHLIST_NEW_MIGRATION_REQUEST, 'forVenueId', 'facialRecognitionWatchlistId');
export const facialRecognitionWatchlistNewMigrationRequestFailure = makeActionCreator(FACIAL_RECOGNITION_WATCHLIST_NEW_MIGRATION_REQUEST_FAILURE, 'error');
export const facialRecognitionWatchlistNewMigrationRequestSuccess = makeActionCreator(FACIAL_RECOGNITION_WATCHLIST_NEW_MIGRATION_REQUEST_SUCCESS, 'facialRecognitionWatchlistMigration');
export const facialRecognitionWatchlistMigrationSet = makeActionCreator(FACIAL_RECOGNITION_WATCHLIST_MIGRATION_SET, 'facialRecognitionWatchlistMigration');
export const facialRecognitionWatchlistMigrationRequest = makeActionCreator(FACIAL_RECOGNITION_WATCHLIST_MIGRATION_REQUEST);
export const facialRecognitionWatchlistMigrationRequestFailure = makeActionCreator(FACIAL_RECOGNITION_WATCHLIST_MIGRATION_REQUEST_FAILURE, 'error');
export const facialRecognitionWatchlistMigrationRequestSuccess = makeActionCreator(FACIAL_RECOGNITION_WATCHLIST_MIGRATION_REQUEST_SUCCESS, 'facialRecognitionWatchlistMigration');
export const facialRecognitionWatchlistMigrationCancelRequest = makeActionCreator(FACIAL_RECOGNITION_WATCHLIST_MIGRATION_CANCEL_REQUEST, 'facialRecognitionWatchlistMigrationId');
export const facialRecognitionWatchlistMigrationCancelRequestFailure = makeActionCreator(FACIAL_RECOGNITION_WATCHLIST_MIGRATION_CANCEL_REQUEST_FAILURE, 'error');
export const facialRecognitionWatchlistMigrationCancelRequestSuccess = makeActionCreator(FACIAL_RECOGNITION_WATCHLIST_MIGRATION_CANCEL_REQUEST_SUCCESS, 'facialRecognitionWatchlistMigration');
export const facialRecognitionWatchlistMigrationProcessRequest = makeActionCreator(FACIAL_RECOGNITION_WATCHLIST_MIGRATION_PROCESS_REQUEST);
// move
export const facialRecognitionWatchlistNewMoveRequest = makeActionCreator(FACIAL_RECOGNITION_WATCHLIST_NEW_MOVE_REQUEST, 'forVenueId', 'facialRecognitionWatchlistId');
export const facialRecognitionWatchlistNewMoveRequestFailure = makeActionCreator(FACIAL_RECOGNITION_WATCHLIST_NEW_MOVE_REQUEST_FAILURE, 'error');
export const facialRecognitionWatchlistNewMoveRequestSuccess = makeActionCreator(FACIAL_RECOGNITION_WATCHLIST_NEW_MOVE_REQUEST_SUCCESS, 'facialRecognitionWatchlistMove');
export const facialRecognitionWatchlistMoveSet = makeActionCreator(FACIAL_RECOGNITION_WATCHLIST_MOVE_SET, 'facialRecognitionWatchlistMove');
export const facialRecognitionWatchlistMoveRequest = makeActionCreator(FACIAL_RECOGNITION_WATCHLIST_MOVE_REQUEST);
export const facialRecognitionWatchlistMoveRequestFailure = makeActionCreator(FACIAL_RECOGNITION_WATCHLIST_MOVE_REQUEST_FAILURE, 'error');
export const facialRecognitionWatchlistMoveRequestSuccess = makeActionCreator(FACIAL_RECOGNITION_WATCHLIST_MOVE_REQUEST_SUCCESS, 'facialRecognitionWatchlistMove');


export function facialRecognitionWatchlistPoiSaveRequestFailure(error) {
    return {
        type: FACIAL_RECOGNITION_WATCHLIST_POI_SAVE_REQUEST_FAILURE,
        error
    };
}

export function facialRecognitionWatchlistPoiSet(facialRecognitionWatchlistPoi) {
    return {
        type: FACIAL_RECOGNITION_WATCHLIST_POI_SET,
        facialRecognitionWatchlistPoi
    };
}

export function facialRecognitionWatchlistsRequest(forVenueId, forGroupId) {
    return {
        type: FACIAL_RECOGNITION_WATCHLISTS_REQUEST,
        forVenueId,
        forGroupId
    };
}

export function facialRecognitionWatchlistsRequestFailure(error) {
    return {
        type: FACIAL_RECOGNITION_WATCHLISTS_REQUEST_FAILURE,
        error
    };
}

export function facialRecognitionWatchlistsRequestSuccess(facialRecognitionWatchlists) {
    return {
        type: FACIAL_RECOGNITION_WATCHLISTS_REQUEST_SUCCESS,
        facialRecognitionWatchlists
    };
}

export function facialRecognitionWatchlistRequest(facialRecognitionWatchlistsId) {
    return {
        type: FACIAL_RECOGNITION_WATCHLIST_REQUEST,
        facialRecognitionWatchlistsId
    };
}

export function facialRecognitionWatchlistRequestFailure(error) {
    return {
        type: FACIAL_RECOGNITION_WATCHLIST_REQUEST_FAILURE,
        error
    };
}

export function facialRecognitionWatchlistRequestSuccess(facialRecognitionWatchlist, facialRecognitionWatchlistTypes, facialRecognitionServers) {
    return {
        type: FACIAL_RECOGNITION_WATCHLIST_REQUEST_SUCCESS,
        facialRecognitionWatchlist,
        facialRecognitionWatchlistTypes,
        facialRecognitionServers
    };
}

export function facialRecognitionWatchlistCreate(forVenueId, forGroupId) {
    return {
        type: FACIAL_RECOGNITION_WATCHLIST_CREATE,
        forVenueId,
        forGroupId

    };
}

export function facialRecognitionWatchlistCreateFailure(error) {
    return {
        type: FACIAL_RECOGNITION_WATCHLIST_CREATE_FAILURE,
        error
    };
}

export function facialRecognitionWatchlistCreateSuccess(facialRecognitionWatchlist, facialRecognitionWatchlistTypes, facialRecognitionServers) {
    return {
        type: FACIAL_RECOGNITION_WATCHLIST_CREATE_SUCCESS,
        facialRecognitionWatchlist,
        facialRecognitionWatchlistTypes,
        facialRecognitionServers
    };
}

export function facialRecognitionWatchlistSet(facialRecognitionWatchlist) {
    return {
        type: FACIAL_RECOGNITION_WATCHLIST_SET,
        facialRecognitionWatchlist
    };
}

export function facialRecognitionWatchlistReset() {
    return {
        type: FACIAL_RECOGNITION_WATCHLIST_RESET,

    };
}

export function facialRecognitionWatchlistSaveRequest() {
    return {
        type: FACIAL_RECOGNITION_WATCHLIST_SAVE_REQUEST
    };
}

export function facialRecognitionWatchlistSaveRequestFailure(error) {
    return {
        type: FACIAL_RECOGNITION_WATCHLIST_SAVE_REQUEST_FAILURE,
        error
    };
}

export function facialRecognitionWatchlistSaveRequestSuccess(facialRecognitionWatchlists, facialRecognitionWatchlist) {
    return {
        type: FACIAL_RECOGNITION_WATCHLIST_SAVE_REQUEST_SUCCESS,
        facialRecognitionWatchlists,
        facialRecognitionWatchlist

    };
}

export function facialRecognitionWatchlistSyncRequest(forVenueId, forGroupId) {
    return {
        type: FACIAL_RECOGNITION_WATCHLIST_SYNC_REQUEST,
        forVenueId,
        forGroupId
    };
}

export function facialRecognitionWatchlistSyncRequestFailure(error) {
    return {
        type: FACIAL_RECOGNITION_WATCHLIST_SYNC_REQUEST_FAILURE,
        error
    };
}

export function facialRecognitionWatchlistSyncRequestSuccess(facialRecognitionWatchlists) {
    return {
        type: FACIAL_RECOGNITION_WATCHLIST_SYNC_REQUEST_SUCCESS,
        facialRecognitionWatchlists
    };
}