import { connect } from 'react-redux' 
import CashTraxEdit from '../components/incidents/cashtrax/CashTraxEdit'
import { editIncidentLoad, editIncidentClear, editIncidentUpdate, editIncidentClearError, 
    editIncidentSave,editIncidentCheckReportingPeriodDate, editIncidentSetStep, editIncidentUploadRequest,
    editIncidentPatronUploadRequest, editIncidentChecklistItemUploadRequest, editIncidentChecklistsRequest
 } from '../common/actions/editIncident'
 import * as printActions from "../common/actions/print";
import { withRouter } from "react-router";
import { locationRequest } from '../common/actions/location';
import { notificationShow } from '../common/actions/notification';
import { incidentLogic } from '../common/logic/incidentLogic';
import { permissionsLogic } from '../common/logic/permissionsLogic';

const mapStateToProps = (state, props)=> {
    return {
        incidentId: props.match.params.incidentid,
        incident: state.editIncident.incident,
        incidentOptions: state.editIncident.incidentOptions,
        isLoading: state.editIncident.isLoading,
        isSaving: state.editIncident.isSaving,
        error: state.editIncident.error,
        isLocationLoading: state.location.isLoading,
        latitude: state.location.latitude,
        longitude: state.location.longitude,
        locationFound: state.location.found,
        locationError: state.location.error,
        step: state.editIncident.step,
        isUploading: state.editIncident.isUploading,
        hasChecklists: state.editIncident.hasChecklists,
        isLoadingChecklists: state.editIncident.isLoadingChecklists,
        isCashTrax: incidentLogic.incidentTypeEntryPage(state.editIncident.incident, state.editIncident.incidentOptions) === "CashTraxEdit",
        canView: permissionsLogic.hasPermissionForState(state, "CashTraxIncidentView"),
        canEdit: permissionsLogic.hasPermissionForState(state, "CashTraxIncidentEdit"),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoadIncident: (incidentId) => {
            dispatch(editIncidentLoad(incidentId))
        },
        onClear: () => {
            dispatch(editIncidentClear())
        },
        onUpdateIncident: (incident) => {
            dispatch(editIncidentUpdate(incident))
        },
        onClearError: () => {
            dispatch(editIncidentClearError())
        },
        onSaveDraft: () => {
            dispatch(editIncidentSave(true, false))
        },
        onSaveFinal: () => {
            dispatch(editIncidentSave(false, false))
        },
        onSaveFinalAndApprove: () => {
            dispatch(editIncidentSave(false, true))
        },
        onCheckIncidentDateReportingPeriod: (incidentDate, venueEventId) => {
            dispatch(editIncidentCheckReportingPeriodDate(incidentDate, venueEventId))
        },
        onRefreshLocation: () => {
            dispatch(locationRequest())
        },
        onErrorNotification: (message) => {
            dispatch(notificationShow(message));
        },
        onSetStep: (step) => {
            dispatch(editIncidentSetStep(step));
        },
        onUploadFiles:(files, storageFolder) => {
            dispatch(editIncidentUploadRequest(files, storageFolder))
        },
        onUploadPatronFiles: (patronIndex, files, storageFolder) => {
            dispatch(editIncidentPatronUploadRequest(patronIndex ,files, storageFolder))
        },
        onUploadAttachment: (files, checklistTemplateItemId, storageFolder) => {
            dispatch(editIncidentChecklistItemUploadRequest(files, checklistTemplateItemId, storageFolder))
        },
        onLoadChecklists: () => {
            dispatch(editIncidentChecklistsRequest())
        },
        onPrint: (incidentId, incidentCategoryTypeDocumentId) => {
            dispatch(printActions.printIncidentCategoryTypeDocumentRequest("DOWNLOAD", incidentId, incidentCategoryTypeDocumentId,[], [], []));
        }
    }
}

const CashTraxIncidentContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CashTraxEdit))

export default CashTraxIncidentContainer