import React, { Component } from 'react';
import * as AusComplyDtos from "../../../../common/dto/AusComply.dtos";
import { CheckListDisplayItem } from "../../../../common/dto/AusComply.dtos";
import Thumbnail from '../../../common/Thumbnail';
import Box from '@material-ui/core/Box';
import ImagePreview from '../../../common/ImagePreview';
import Error from '../../../common/Error';
import FileUpload from '../../../common/FileUpload';
import { styles } from '../../../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface ICheckListItemAttachmentsProps {
    checkListDisplayItem: CheckListDisplayItem;
    onUpdate?: Function;
    onUploadAttachment: Function;
    isUploading: boolean;
    isReadonly?: boolean;
    theme: any;
}

interface ICheckListItemAttachmentsState {
    selectedFilePath: string;
    selectedFileName: string;
}

class CheckListItemAttachments extends Component<ICheckListItemAttachmentsProps, ICheckListItemAttachmentsState> {
    constructor(props: ICheckListItemAttachmentsProps) {
        super(props)
        this.state = {
            selectedFilePath: "",
            selectedFileName: ""
        }
        this.onSelectFilePath = this.onSelectFilePath.bind(this);
        this.onDownload = this.onDownload.bind(this);
        this.removeDocument = this.removeDocument.bind(this);
        this.fileWasUploaded = this.fileWasUploaded.bind(this);
        this.onUploadFiles = this.onUploadFiles.bind(this);
    }

    onSelectFilePath(filePath, text) {
        this.setState({
            selectedFilePath: filePath,
            selectedFileName: text
        });
    }

    onDownload(filePath) {
        // fake server request, getting the file url as response
        setTimeout(() => {
            const response = {
                file: filePath,
            };
            // server sent the url to the file!
            // now, let's download:
            window.open(response.file);
            // you could also do:
            // window.location.href = response.file;
        }, 100);
    }

    removeDocument(file: AusComplyDtos.File) {
        let checkListItem = { ...this.props.checkListDisplayItem.item };
        if (this.props.checkListDisplayItem.isOnIncident && file.isTemporary) {
            let index = checkListItem.files.indexOf(file);
            if (index > -1) {
                checkListItem.files.splice(index, 1);
                if (this.props.onUpdate) {
                    this.props.onUpdate(checkListItem)
                }
            }

        } else if (!file.isTemporary) {
            checkListItem.files.forEach(f => {
                if (file.attachmentId == f.attachmentId) {
                    f.obsolete = true;
                }
            });
            if (this.props.onUpdate) {
                this.props.onUpdate(checkListItem)
            }
        }
    }

    onUploadFiles(files: any[]) {
        let self = this;
        if (self.props.onUploadAttachment) {
            self.props.onUploadAttachment(
                files,
                self.props.checkListDisplayItem.item.checklistTemplateItemId
            )
        }
    }

    fileWasUploaded(temporaryFiles: AusComplyDtos.File[]) {

    }

    render() {
        const { theme } = this.props;
        let attachments;
        let count = 0;
        if (this.props.checkListDisplayItem.item && this.props.checkListDisplayItem.item.files) {
            count = this.props.checkListDisplayItem.item.files.filter(item => !item.obsolete).length;
            attachments = this.props.checkListDisplayItem.item.files.filter(item => !item.obsolete).map((item, index) => {
                if ((item.isTemporary && !this.props.checkListDisplayItem.isOnIncident) || this.props.isReadonly) {
                    return (
                        <Box p={0} key={"file-box-" + item.fileName + "_" + index.toString()}>
                            <Thumbnail
                                key={"file-" + item.fileName + "_" + index.toString()}
                                previewPath={item.filePreviewPath}
                                displayPath={item.fileDisplayPath}
                                alwaysShowText={true}
                                isImage={item.isImage}
                                text={item.filePreviewText}
                                fullText={item.fileName}
                                onPreview={(path, text) => this.onSelectFilePath(path, text)}
                                onDownload={path => this.onDownload(path)}
                            />
                        </Box>
                    )
                }
                return (
                    <Box p={0} key={"file-box-" + item.fileName + "_" + index.toString()}>
                        <Thumbnail
                            key={"file-" + item.fileName + "_" + index.toString()}
                            previewPath={item.filePreviewPath}
                            displayPath={item.fileDisplayPath}
                            isImage={item.isImage}
                            alwaysShowText={true}
                            text={item.filePreviewText}
                            fullText={item.fileName}
                            onPreview={(path, text) => this.onSelectFilePath(path, text)}
                            onDownload={path => this.onDownload(path)}
                            onRemove={() => this.removeDocument(item)}
                        />
                    </Box>
                )
            });
        }
        let fileUpload;
        if (!this.props.isReadonly) {
            fileUpload = (
                <FileUpload
                    onFileWasUploaded={this.fileWasUploaded}
                    isUploading={this.props.isUploading}
                    onUploadFiles={this.onUploadFiles}
                    alternateIcons={true}
                    hasFiles={false}
                    entityType={AusComplyDtos.ngtEntityType.Attachment} />
            );
        }
        let required;
        if (this.props.checkListDisplayItem && this.props.checkListDisplayItem.template.requiredAttachmentCount > 0) {
            if (count < this.props.checkListDisplayItem.template.requiredAttachmentCount) {
                required = this.props.checkListDisplayItem.template.requiredAttachmentCount  == 1 ? 
                    <Error message={"At least one file is required"} style={{margin: "0 -10px -10px", padding: '3px', borderRadius: 0}} />
                    : <Error message={"At least " + this.props.checkListDisplayItem.template.requiredAttachmentCount.toString() + " files are required"}
                        style={{margin: "0 -10px -10px", padding: '3px', borderRadius: 0}} />;
            }
        }
        return (
            <div >
                <Box display="flex" flexDirection="row" justifyContent="left" flexWrap="wrap">
                    {attachments}
                </Box>
                {fileUpload}
                <ImagePreview filePath={this.state.selectedFilePath} fileName={this.state.selectedFileName} onDismissed={() => this.onSelectFilePath("", "")} />
                {required}
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(CheckListItemAttachments);