import update from "immutability-helper";
import * as facialRecognitionPOIActions from "../actions/facialRecognitionPOI";

const initialState = {
    poiRegistration: {
        isLoading: false,
        isSaving: false,
        isScanning: false,
        isSearching: false,
        poiRegistration: { incidentId: 0 },
    },
    poiWatchlistRegistration: {
        isLoading: false,
        isSaving: false,
        poiRegistration: { poiGuid: "" },
    },
    poiIncidents: [],
    poiScannedIds: [],
    pois: {
        isLoading: false,
        poiIncidents: [],
        error: ""
    },
    poiSearch: {
        isLoading: false,
        poiScan: {}
    },
    poiExpiryFailures: {
        isLoading: false,
        isSaving: false,
        filter: {},
        data: [],
        paging: { pageSize: 0, page: 1 }
    }
};

function registrationIsLoading(state, isLoading) {
    let newState = update(state, {
        poiRegistration: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function registrationIsSaving(state, isSaving) {
    let newState = update(state, {
        poiRegistration: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function registrationIsScanning(state, isScanning) {
    let newState = update(state, {
        poiRegistration: {
            isScanning: { $set: isScanning }
        }
    });
    return newState;
}

function registrationIsSearching(state, isSearching) {
    let newState = update(state, {
        poiRegistration: {
            isSearching: { $set: isSearching }
        }
    });
    return newState;
}

function setRegistration(state, poiRegistration) {
    let newState = update(state, {
        poiRegistration: {
            poiRegistration: { $set: poiRegistration }
        }
    });
    return newState;
}

function poiIncidentsIsLoading(state, isLoading, facialRecognitionPOIIncidentId, poiGuid, data, facialRecognitionWatchlistId) {
    let poiIncidents = [...state.poiIncidents];

    let index = facialRecognitionPOIIncidentId > 0 ?
        poiIncidents.findIndex(p => p.facialRecognitionPOIIncidentId == facialRecognitionPOIIncidentId) :
        poiIncidents.findIndex(p => p.poiGuid == poiGuid);

    if (index > -1) {
        //poiIncidents.splice(index, 1);
        poiIncidents[index] = {
            facialRecognitionPOIIncidentId: facialRecognitionPOIIncidentId,
            poiGuid: poiGuid,
            isLoading: isLoading,
            poiIncidents: { ...data }
        };
    }
    else {
        poiIncidents.push({
            facialRecognitionPOIIncidentId: facialRecognitionPOIIncidentId,
            poiGuid: poiGuid,
            isLoading: isLoading,
            poiIncidents: data
        })
    }



    let newState = update(state, {
        poiIncidents: { $set: poiIncidents }
    });

    return newState;
}

function poiIncidentsFailed(state) {
    let poiIncidents = [...state.poiIncidents];
    poiIncidents.forEach(p => {
        if (p.isLoading) {
            p.isLoading = false;
            p.poiIncidents = {};
        }
    });
    let newState = update(state, {
        poiIncidents: { $set: poiIncidents }
    });

    return newState;
}

function poisIsLoading(state, isLoading, data) {
    let newState = update(state, {
        pois: {
            isLoading: { $set: isLoading },
            poiIncidents: { $set: data },
            error: { $set: "" }
        }
    });

    return newState;
}

function poisFailed(state, error) {
    let newState = update(state, {
        pois: {
            isLoading: { $set: false },
            poiIncidents: { $set: [] },
            error: { $set: error }
        }
    });

    return newState;
}

function poiSearchIsLoading(state, isLoading) {
    let newState = update(state, {
        poiSearch: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setPoiSearch(state, poiScan) {
    let newState = update(state, {
        poiSearch: {
            poiScan: { $set: poiScan }
        }
    });
    return newState;
}

function poiExpiryFailuresIsLoading(state, isLoading) {
    let newState = update(state, {
        poiExpiryFailures: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function poiExpiryFailuresIsSaving(state, isSaving) {
    let newState = update(state, {
        poiExpiryFailures: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function poiExpiryFailures(state, data, paging, filter) {
    let newState = update(state, {
        poiExpiryFailures: {
            data: { $set: data },
            paging: { $set: paging },
            filter: { $set: filter }
        }
    });
    return newState;
}

function watchlistRegistrationIsLoading(state, isLoading) {
    let newState = update(state, {
        poiWatchlistRegistration: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function watchlistRegistrationIsSaving(state, isSaving) {
    let newState = update(state, {
        poiWatchlistRegistration: {
            isSaving: { $set: isSaving }
        }
    });
    return newState;
}

function setWatchlistRegistration(state, poiRegistration) {
    let newState = update(state, {
        poiWatchlistRegistration: {
            poiRegistration: { $set: poiRegistration }
        }
    });
    return newState;
}

function poiScannedIdsIsLoading(state, isLoading, poiGuid, data) {
    let poiScannedIds = [...state.poiScannedIds];

    let index = poiScannedIds.findIndex(p => p.poiGuid == poiGuid);

    if (index > -1) {
        poiScannedIds[index] = {
            poiGuid: poiGuid,
            isLoading: isLoading,
            poiIdScan: { ...data }
        };
    }
    else {
        poiScannedIds.push({
            poiGuid: poiGuid,
            isLoading: isLoading,
            poiIdScan: { ...data }
        })
    }



    let newState = update(state, {
        poiScannedIds: { $set: poiScannedIds }
    });

    return newState;
}

export default function facialRecognitionPOI(state = initialState, action) {
    switch (action.type) {
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_REGISTRATION_REQUEST:
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_VENUE_REGISTRATION_REQUEST:
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_GROUP_REGISTRATION_REQUEST:
            return registrationIsLoading(registrationIsSaving(state, false), true);
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_REGISTRATION_REQUEST_SUCCESS:
            return registrationIsLoading(setRegistration(state, action.poiRegistration), false);
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_REGISTRATION_REQUEST_FAILURE:
            return registrationIsLoading(state, false);
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_REGISTRATION_SET:
            return setRegistration(state, action.poiRegistration);
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_REGISTRATION_RESET:
            return setRegistration(state, { incidentId: 0, venueId: 0 });
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_REGISTRATION_SCAN_FACES_REQUEST:
            return registrationIsScanning(state, true);
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_REGISTRATION_SCAN_FACES_REQUEST_SUCCESS:
            return registrationIsScanning(setRegistration(state, action.poiRegistration), false);
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_REGISTRATION_SCAN_FACES_REQUEST_FAILURE:
            return registrationIsScanning(state, false);
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_REGISTRATION_SEARCH_FACE_REQUEST:
            return registrationIsSearching(state, true);
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_REGISTRATION_SEARCH_FACE_REQUEST_SUCCESS:
            return registrationIsSearching(setRegistration(state, action.poiRegistration), false);
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_REGISTRATION_SEARCH_FACE_REQUEST_FAILURE:
            return registrationIsSearching(state, false);
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_REGISTRATION_SAVE_REQUEST:
            return registrationIsSaving(state, true);
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_REGISTRATION_SAVE_REQUEST_SUCCESS:
            return registrationIsSaving(setRegistration(state, { incidentId: 0, venueId: 0 }), false);
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_REGISTRATION_SAVE_REQUEST_FAILURE:
            return registrationIsSaving(state, false);
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_INCIDENTS_REQUEST:
            return poiIncidentsIsLoading(state, true, action.facialRecognitionPOIIncidentId, action.poiGuid, {}, action.facialRecognitionWatchlistId);
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_INCIDENTS_REQUEST_SUCCESS:
            return poiIncidentsIsLoading(state, false, action.facialRecognitionPOIIncidentId, action.poiGuid, action.poiIncidents, action.facialRecognitionWatchlistId);
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_INCIDENTS_REQUEST_FAILURE:
            return poiIncidentsFailed(state);
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POIS_INCIDENTS_REQUEST:
            return poisIsLoading(state, true, []);
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POIS_INCIDENTS_REQUEST_SUCCESS:
            return poisIsLoading(state, false, action.data);
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POIS_INCIDENTS_REQUEST_FAILURE:
            return poisFailed(state, action.error);
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_SEARCH_REQUEST:
            return poiSearchIsLoading(state, true);
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_SEARCH_REQUEST_SUCCESS:
            return poiSearchIsLoading(setPoiSearch(state, action.poiScan), false);
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_SEARCH_REQUEST_FAILURE:
            return poiSearchIsLoading(state, false);

        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_EXPIRY_FAILURES_REQUEST:
            return poiExpiryFailuresIsLoading(state, true);
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_EXPIRY_FAILURES_REQUEST_SUCCESS:
            return poiExpiryFailuresIsLoading(poiExpiryFailures(state, action.data, action.paging, action.filter), false);
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_EXPIRY_FAILURES_REQUEST_FAILURE:
            return poiExpiryFailuresIsLoading(state, false);

        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_EXPIRY_DISMISS_REQUEST:
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_EXPIRY_RETRY_REQUEST:
            return poiExpiryFailuresIsSaving(state, true);
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_EXPIRY_DISMISS_REQUEST_SUCCESS:
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_EXPIRY_DISMISS_REQUEST_FAILURE:
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_EXPIRY_RETRY_REQUEST_SUCCESS:
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_EXPIRY_RETRY_REQUEST_FAILURE:
            return poiExpiryFailuresIsSaving(state, false);

        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_WATCHLIST_REGISTRATION_REQUEST:
            return watchlistRegistrationIsLoading(watchlistRegistrationIsSaving(state, false), true);
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_WATCHLIST_REGISTRATION_REQUEST_SUCCESS:
            return watchlistRegistrationIsLoading(setWatchlistRegistration(state, action.poiRegistration), false);
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_WATCHLIST_REGISTRATION_REQUEST_FAILURE:
            return watchlistRegistrationIsLoading(state, false);
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_WATCHLIST_REGISTRATION_SET:
            return setWatchlistRegistration(state, action.poiRegistration);
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_WATCHLIST_REGISTRATION_RESET:
            return setWatchlistRegistration(state, { poiGuid: "" });
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_WATCHLIST_REGISTRATION_SAVE_REQUEST:
            return watchlistRegistrationIsSaving(state, true);
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_WATCHLIST_REGISTRATION_SAVE_REQUEST_SUCCESS:
            return watchlistRegistrationIsSaving(setWatchlistRegistration(state, { poiGuid: "" }), false);
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_WATCHLIST_REGISTRATION_SAVE_REQUEST_FAILURE:
            return watchlistRegistrationIsSaving(state, false);

        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_SCANNED_ID_REQUEST:
            return poiScannedIdsIsLoading(state, true,  action.poiGuid, {});
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_SCANNED_ID_REQUEST_SUCCESS:
            return poiScannedIdsIsLoading(state, false, action.data.poiGuid, action.data);
        case facialRecognitionPOIActions.FACIAL_RECOGNITION_POI_SCANNED_ID_REQUEST_FAILURE:
            return poiScannedIdsIsLoading(state, false, action.originalAction.poiGuid, {});
    }
    return state;
}