import React, { Component } from 'react';
import { Incident as IncidentDTO, IncidentDocument, File as FileDTO } from "../../../common/dto/AusComply.dtos";
import FileUpload from '../../common/FileUpload';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import BadgeWrapper from '../../common/BadgeWrapper';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";

export interface IIncidentWizardAttachButtonProps {
    incident: IncidentDTO;
    onUpdateIncident: Function;
    theme: any;
    classes: any;
}

class IncidentWizardAttachButton extends Component<IIncidentWizardAttachButtonProps, any> {
    constructor(props: IIncidentWizardAttachButtonProps) {
        super(props)
        this.state = {

        }
        this.fileWasUploaded = this.fileWasUploaded.bind(this);
    }

    fileWasUploaded(temporaryFiles: FileDTO[]) {
        let newIncident = { ...this.props.incident };
        temporaryFiles.forEach(item => {
            let document = new IncidentDocument();
            document.obsolete = false;
            document.originalFilename = item.originalName;
            document.isImage = item.isImage;
            document.incidentId = this.props.incident.incidentId;
            document.canvasRescaled = false;
            document.filePath = item.path;
            document.filePreviewPath = item.filePreviewPath;
            document.fileDisplayPath = item.fileDisplayPath;
            document.extension = item.extension;
            document.incidentDocumentId = 0;
            document.filePreviewText = item.filePreviewText;
            document.hasChanged = true;
            document.canvasRescaled = item.canvasRescaled;
            document.storageFileName = item.storageFileName;
            if (!newIncident.incidentDocuments) {
                newIncident.incidentDocuments = [];
            }
            newIncident.incidentDocuments.push(document);
        });
        this.props.onUpdateIncident(newIncident);
    }

    render() {
        const { classes } = this.props;

        let badgeStyle = {
            margin: '0',
            width: 'auto'
        };

        let count = 0;
        if (this.props.incident && this.props.incident.incidentDocuments && this.props.incident.incidentDocuments.length > 0) {
            count = this.props.incident.incidentDocuments.filter(item => !item.obsolete).length;
        }

        return <BadgeWrapper count={count} style={badgeStyle} classes={{ badge: classes.badgeTop }}>
            <FileUpload navigationButton={true}
             onFileWasUploaded={this.fileWasUploaded}
             entityType={AusComplyDtos.ngtEntityType.Incident}
             storageFolder={this.props.incident.storageFolder}
             ></FileUpload>
        </BadgeWrapper>
    }

}

export default withStyles(styles, { withTheme: true })(IncidentWizardAttachButton);